export const LOGO = require('./images/CDC_logo.svg');

export const LOGOTAKSIM = require('./images/TAKSIM_logo.svg');

export const FUNDO_LOGIN = 'linear-gradient(87deg, #2405F2 0, #1171ef 100%)'; // `url(${require('./images/login-register.png')})`

export const COLORS = {
  red: '#dc3545',
  orange: '#f60',
  yellow: '#ffc107',
  blue: '#1a8bd2',
  green: '#28a745',
  darkRed: '#8c000d',
};

export const STYLES = (custom) => ({
  form: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'baseline',
    padding: 10,
    ...custom,
  },
  pesquisar: {
    flexDirection: 'row',
    display: 'flex',
    marginRight: 46,
    ...custom,
  },
  buscar: {
    display: 'flex',
    padding: 10,
    marginLeft: 32,
  },
  input: {
    height: '54%',
  },
  pesquisar_2: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'baseline',
    ...custom,
  },
});
