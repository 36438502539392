import api from '../../../service/api';

export const sendDataUser = async (params) => {
  try {
    const response = await api.post('coban/inserir', params);
    return {
      error: false,
      mensagem: response.data.mensagem,
      status: response.status,
    };
  } catch (error) {
    return {
      error: true,
      mensagem: error.response.data.mensagem,
      status: error.response.status,
    };
  }
};

export const editDataUser = async (params) => {
  try {
    const response = await api.post('coban/atualizar', params);
    return {
      error: false,
      mensagem: response.data.mensagem,
      status: response.status,
    };
  } catch (error) {
    return {
      error: true,
      mensagem: error.response.data.mensagem,
      status: error.response.status,
    };
  }
};

export const getServicosDigitais = async (params) => {
  try {
    const response = await api.get(`coban/taxaServicos/listar?ID_COBAN=${params}`);
    return {
      error: false,
      mensagem: response.data.mensagem,
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    return {
      error: true,
      mensagem: error.response.data.mensagem,
      status: error.response.status,
    };
  }
};

export const alteraServicosDigitais = async (data) => {
  try {
    const response = await api.put(`coban/taxaServicos/atualizar`, data);
    return {
      error: false,
      mensagem: response.data.mensagem,
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    return {
      error: true,
      mensagem: error.response.data.mensagem,
      status: error.response.status,
    };
  }
};

export const getMenusConfigs = async (params) => {
  try {
    const response = await api.get(`/coban/listar/menus?ID_COBAN=${params}`);
    return {
      error: false,
      mensagem: response.data.mensagem,
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    return {
      error: true,
      mensagem: error.response.data.mensagem,
      status: error.response.status,
    };
  }
};

export const alteraMenus = async (data) => {
  try {
    const response = await api.post(`coban/menus/atualizar`, data);
    return {
      error: false,
      mensagem: response.data.mensagem,
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    return {
      error: true,
      mensagem: error.response.data.mensagem,
      status: error.response.status,
    };
  }
};

export const changePasswordOfPermission = async (data) => {};
