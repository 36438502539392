import styled from 'styled-components';
import { MdPersonSearch, MdOutlineAccountBalanceWallet } from 'react-icons/md';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Input } from 'reactstrap';

export const UserEditIcon = styled(MdPersonSearch)`
  font-size: 1.7vw;
  color: rgba(17, 205, 239, 1);
  cursor: pointer;
`;
export const DigitalAccountIcon = styled(MdOutlineAccountBalanceWallet)`
  font-size: 1.7vw;
  color: rgba(17, 205, 239, 1);
  cursor: pointer;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Spinner = styled(CircularProgress)`
  color: #2405f2;
`;

export const LoadingModalTitle = styled.h3`
  font-size: 1vw;
  margin: 1vw 0;
  color: linear-gradient(87deg, #2405f2 0, #1171ef 100%) !important;
`;
export const LoadingModalContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1vw;
  margin-top: 1vw;
`;

export const RevertButton = styled(Button)`
  background-color: #0768f9;
  color: white;
`;

export const infoInput = styled(Input)`
  width: auto;
  float: right;
  border: 0;
`;

export const ModalSubtitle = styled.h3`
  color: #32325d;
`;
