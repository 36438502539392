import styled from "styled-components";

export const Text = styled.h3`

    margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline; 
    color: black;
  	font-size: 17px;
  	font-weight: 600;
  	font-family: 'Montserrat', sans-serif;

`