import React from 'react';

export default function BadgeTipoConta({ tipoConta, styles }) {
  function chooseAccount() {
    if (tipoConta == 1) {
      return 'Cartão despesa';
    }
    if (tipoConta == 3) {
      return 'Cartão social';
    }
    return 'Cartão benefício';
  }

  return (
    <h3 style={styles} className="badge badge-primary">
      {chooseAccount(tipoConta)}
    </h3>
  );
}
