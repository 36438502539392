import {
  SWAP_ADD_PRODUCT,
  SWAP_ADD_PRODUCT_SUCCESS,
  SWAP_ADD_PRODUCT_FAIL,
  SWAP_EDIT_PRODUCT,
  SWAP_EDIT_PRODUCT_SUCCESS,
  SWAP_EDIT_PRODUCT_FAIL,
  SWAP_LIST_SEGMENTS,
  SWAP_LIST_SEGMENTS_SUCCESS,
  SWAP_LIST_SEGMENTS_FAIL,
  SWAP_LIST_PRODUCT,
  SWAP_LIST_PRODUCT_SUCCESS,
  SWAP_LIST_PRODUCT_FAIL,
  SWAP_LIST_PRODUCT_SEGMENT_SUCCESS,
  SWAP_LIST_PRODUCT_SEGMENT,
  SWAP_ADD_SEGMENTS_PRODUCT,
  SWAP_ADD_SEGMENTS_PRODUCT_SUCCESS,
  SWAP_ADD_SEGMENTS_PRODUCT_FAIL,
  SWAP_LIST,
  SWAP_LIST_SUCCESS,
  SWAP_LIST_FAIL,
  DEFAULT_TYPE,
} from './types';

const INITIAL_STATE = {
  loadingProduct: true,
  loadingProductList: true,
  loadingDeleteProduct: false,
  card_user: {
    loading: false
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SWAP_ADD_PRODUCT:
      return {
        ...state,
        loading: true,
        erro: false,
        erro_message: null,
      };
    case SWAP_ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        erro: false,
        erro_message: null,
      };
    case SWAP_ADD_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        erro: true,
        erro_message: action.error,
      };

    case SWAP_EDIT_PRODUCT:
      return {
        ...state,
        loading: true,
        erro: false,
        erro_message: null,
      };
    case SWAP_EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        erro: false,
        erro_message: null,
      };
    case SWAP_EDIT_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        erro: true,
        erro_message: action.error,
      };

    case SWAP_LIST_SEGMENTS:
      return {
        ...state,
        loading: true,
        erro: false,
        erro_message: null,
      };
    case SWAP_LIST_SEGMENTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        erro: false,
        erro_message: null,
      };
    case SWAP_LIST_SEGMENTS_FAIL:
      return {
        ...state,
        loading: false,
        erro: true,
        erro_message: action.error,
      };

    case SWAP_LIST_PRODUCT:
      return {
        ...state,
        loadingProduct: true,
        loadingDeleteProduct: true,
        erro: false,
        erro_message: null,
      };
    case SWAP_LIST_PRODUCT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loadingProduct: false,
        loadingDeleteProduct: false,
        erro: false,
        erro_message: null,
      };
    case SWAP_LIST_PRODUCT_FAIL:
      return {
        ...state,
        loadingProduct: false,
        loadingDeleteProduct: false,
        erro: true,
        erro_message: action.error,
      };

    case SWAP_ADD_SEGMENTS_PRODUCT:
      return {
        ...state,
        loadingSegment: true,
        erro: false,
        erro_message: null,
      };
    case SWAP_ADD_SEGMENTS_PRODUCT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loadingSegment: false,
        erro: false,
        erro_message: null,
      };
    case SWAP_ADD_SEGMENTS_PRODUCT_FAIL:
      return {
        ...state,
        loadingSegment: false,
        erro: true,
        erro_message: action.error,
      };

    case SWAP_LIST:
      return {
        ...state,
        loadingProductList: true,
        erro: false,
        erro_message: null,
      };
    case SWAP_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loadingProductList: false,
        erro: false,
        erro_message: null,
      };
    case SWAP_LIST_FAIL:
      return {
        ...state,
        loadingProductList: false,
        erro: true,
        erro_message: action.error,
      };

    case SWAP_LIST_PRODUCT_SEGMENT:
      return {
        ...state,
        loadingProduct: true,
        erro: false,
        erro_message: null,
      };
    case SWAP_LIST_PRODUCT_SEGMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loadingProduct: false,
        erro: false,
        erro_message: null,
      };

    case DEFAULT_TYPE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
