import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import formatMoney from '../../utils/formatMoneyBRL';

export default function SaqueBar() {
  const {
    saques: { transacaoDebito, transacaoCredito },
  } = useSelector((state) => state.accounting);

  let dadosDefaultChart = [0, 0, 0, 0, 0, 0, 0];

  const formatDatas = (datas) => {
    var dataFormatada = [];
    dataFormatada = datas.map((num) => {
      var value = [];
      value = moment(num).isValid() ? moment(num).format('DD/MM/YYYY') : num;
      return value;
    });

    return dataFormatada;
  };

  return (
    <Card sx={{ maxWidth: '50vw', height: '100%' }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mb={2}>
          <Typography fontSize={14}>Transações</Typography>
          <Typography fontSize={14}>(ÚLTIMOS 7 DIAS)</Typography>
        </Box>
        <Box sx={{ height: '50vh' }}>
          <Bar
            data={{
              labels: formatDatas(Object.keys(transacaoDebito)),
              datasets: [
                {
                  label: 'Crédito',
                  data: transacaoCredito != undefined ? Object.values(transacaoCredito) : dadosDefaultChart,
                  backgroundColor: '#0CB42A',
                  borderColor: '#0CB42A',
                },
                {
                  label: 'Débito',
                  data: transacaoDebito != undefined ? Object.values(transacaoDebito) : dadosDefaultChart,
                  backgroundColor: '#EA6852',
                  borderColor: '#EA6852',
                },
              ],
            }}
            options={{
              scales: {
                y: {
                  ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value) {
                      return 'R$ ' + formatMoney(value);
                    },
                  },
                },
              },
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: true,
                  position: 'bottom',

                  labels: {
                    usePointStyle: true,
                  },
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      var currentDataValue = context.raw;
                      return 'R$ ' + formatMoney(currentDataValue);
                    },
                  },
                },
              },
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
