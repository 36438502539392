import React, { useState } from 'react';
import { Modal, Button, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import CustomForm from '../Form/CustomForm';
import Loading from '../Loader/Loading';
import FormCoban from '../../views/Cobans/FormCoban';

const ModalForm = (props) => {
  const [modalTaxas, setModalTaxas] = useState(true);

  const {
    modal,
    toggle,
    form,
    txtSubmit,
    reducer,
    variable,
    onSubmit,
    typeReducer,
    load,
    modoEdicao,
    tipoUser,
    changePermissionPassword,
    togglePersmissionPassword,
  } = props;

  const { listLevels } = props.coban;

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      autoFocus={false}
      onKeyDown={(e) => e.key === 'Enter' && onSubmit && onSubmit()}
    >
      <ModalBody style={tipoUser === '1' ? { padding: '15px' } : { padding: '0 0 0 0' }}>
        {tipoUser === '1' ? (
          <CustomForm
            data={form}
            reducer={reducer}
            variable={variable}
            typeReducer={typeReducer}
            dataLevels={listLevels}
            modoEdicao={modoEdicao}
            tipoUser={tipoUser}
            toggle={toggle}
            // open={modalTaxas}
            close={() => setModalTaxas(!modalTaxas)}
          />
        ) : (
          <FormCoban
            dataUser={props.props}
            open={modalTaxas}
            changePermissionPassword={changePermissionPassword}
            togglePersmissionPassword={togglePersmissionPassword}
            toggle={toggle}
            close={() => setModalTaxas(!modalTaxas)}
          />
        )}

        {props.children}
      </ModalBody>

      {tipoUser === '1' ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
            width: '100%',
            padding: 15,
          }}
        >
          {load ? (
            <Loading color="#11cdef" style={{ margin: '0px auto', fontSize: 25 }} />
          ) : (
            tipoUser === '1' && (
              <Button color="success" size="sm" block onClick={() => (onSubmit ? onSubmit() : null)}>
                {txtSubmit || 'Enviar'}
              </Button>
            )
          )}
        </div>
      ) : (
        <div></div>
      )}
    </Modal>
  );
};

const mapStateToProps = ({ user, coban }) => {
  return { user, coban };
};

export default connect(mapStateToProps, {})(ModalForm);
