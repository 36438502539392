import { InputLabel, Select, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { ContainerLegalRepresentative } from './ModalRepresentantesLegais.styles';
import { FormControl, FormControlLabel, MenuItem } from '@mui/material';
import ReactInputMask from 'react-input-mask';
import api from '../../../../service/api';
import ModalMensagemSemReloadNew from '../../../Transaction/ModalMensagemSemReloadNew';
import isCpf from '../../../../utils/isCpf';
import formatCNPJ from '../../../../utils/formatCNPJ';
import formatCPF from '../../../../utils/formatCPF';

export default function ModalEditarRepresentanteLegal({ modal, toggle, representante, user, callBack }) {
  const [modalMensagem, setModalMensagem] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('');
  const [representative, setRepresentative] = useState({
    CNPJ_EMPRESA: user.CPFCNPJ,
    CPFCNPJ: '',
    CELULAR: '',
    EMAIL: '',
    CODTIPOREPRESENTANTE: '',
    ENDERECO: '',
    NUMENDERECO: '',
    COMPLEMENTO: '',
    BAIRRO: '',
    CIDADE: '',
    UF: '',
    CEP: '',
    NASCIMENTO: '',
    NOME_MAE: '',
    SIGNATARIO: '',
    NOME: '',
  });
  const [representativeTypes, setRepresentativeTypes] = useState([]);

  function updateRepresentativeTypes() {
    api
      .get('/cliente/listar/tipos-representante')
      .then(({ data: { data } }) => setRepresentativeTypes(data));
  }

  useEffect(() => {
    if (modal && representante) {
      setRepresentative({
        CNPJ_EMPRESA: user.CPFCNPJ,
        CPFCNPJ: representante.CPF.length > 11 ? formatCNPJ(representante.CPF) : formatCPF(representante.CPF),
        CELULAR: representante.CELULAR,
        EMAIL: representante.EMAIL,
        CODTIPOREPRESENTANTE: +representante.CODTIPOREPRESENTANTE,
        ENDERECO: representante.ENDERECO,
        NUMENDERECO: representante.NUMENDERECO,
        COMPLEMENTO: representante.COMPLEMENTO,
        BAIRRO: representante.BAIRRO,
        CIDADE: representante.CIDADE,
        UF: representante.UF,
        CEP: representante.CEP,
        NASCIMENTO: representante.DATA_FUND_NASC,
        NOME_MAE: representante.NOME_MAE,
        SIGNATARIO: representante.SIGNATARIO,
        NOME: representante.NOME,
      });
      updateRepresentativeTypes();
    }
  }, [modal]);

  function handleSubmit(e) {
    e.preventDefault();
    api
      .post('usuario/representante/atualizar', {
        REPRESENTANTES: [
          {
            ...representative,
          },
        ],
      })
      .then(({ data }) => {
        setSucesso(data.retorno === 'sucesso' ? true : false);
        setMensagem(data.mensagem);
        setModalMensagem(true);
      })
      .catch(() => {
        setMensagem('Algo inesperado aconteceu');
        setModalMensagem(true);
      });
  }

  return (
    <>
      <Modal
        isOpen={modal}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={() => {
          toggle();
        }}
        size="lg"
        style={{ maxWidth: '800px' }}
      >
        <ModalHeader
          toggle={() => {
            toggle();
          }}
        >
          <h2>Editar representante</h2>
        </ModalHeader>
        <ModalBody>
          <ContainerLegalRepresentative full={true}>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div>
                <div className="card">
                  <div className="box-description"></div>

                  <div className="box-legal-representative-data">
                    <TextField
                      name="nome"
                      label="NOME"
                      id="nome"
                      value={representative.NOME}
                      required
                      fullWidth
                      onChange={(e) => setRepresentative({ ...representative, NOME: e.target.value })}
                    />
                  </div>
                  <div className="box-legal-representative-data">
                    <TextField
                      name="nome_mae"
                      label="NOME MÃE"
                      id="nome_mae"
                      required={representative.CPFCNPJ && isCpf(representative.CPFCNPJ)}
                      value={representative.NOME_MAE}
                      onChange={(e) => setRepresentative({ ...representative, NOME_MAE: e.target.value })}
                      fullWidth
                    />
                  </div>
                  <div className="box-legal-representative-data">
                    <TextField
                      name="email"
                      label="E-MAIL"
                      id="email"
                      type="email"
                      required
                      value={representative.EMAIL}
                      onChange={(e) => setRepresentative({ ...representative, EMAIL: e.target.value })}
                      fullWidth
                    />
                  </div>
                  <div className="box-legal-representative-data">
                    <FormControl sx={{ marginTop: 1, maxWidth: 250 }}>
                      <InputLabel>Tipo Representante</InputLabel>
                      <Select
                        name="Tipo Representante"
                        label="Tipo Representante"
                        id="CODTIPOREPRESENTANTE"
                        required
                        value={representative.CODTIPOREPRESENTANTE}
                        onChange={(e) => setRepresentative({ ...representative, CODTIPOREPRESENTANTE: e.target.value })}
                        fullWidth
                      >
                        {representativeTypes.map((tipo) => (
                          <MenuItem value={tipo.CODTIPOREPRESENTANTE}>{tipo.DESCRICAO}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="box-legal-representative-data" style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField
                      name="NASCIMENTO"
                      label="DATA NASC"
                      InputLabelProps={{ shrink: true }}
                      id="NASCIMENTO"
                      type="date"
                      required
                      value={representative.NASCIMENTO}
                      onChange={(e) => setRepresentative({ ...representative, NASCIMENTO: e.target.value })}
                    />
                    <FormControlLabel
                      labelPlacement="top"
                      label="CPF/CNPJ"
                      disabled
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        cursor: 'context-menu',
                      }}
                      control={
                        <ReactInputMask
                          value={representative.CPFCNPJ}
                          label="CPF/CNPJ"
                          disabled
                          onChange={(e) => setRepresentative({ ...representative, CPFCNPJ: e.target.value })}
                          className="Mask"
                          mask={
                            representative.CPFCNPJ && representative.CPFCNPJ.length < 15
                              ? '999.999.999-999'
                              : '99.999.999/9999-99'
                          }
                          maskChar=""
                          control={
                            <TextField
                              name="CPFCNPJ"
                              label="CPF/CNPJ"
                              required
                              disabled
                              onChange={(e) => setRepresentative({ ...representative, CPFCNPJ: e.target.value })}
                              id="CPFCNPJ"
                            />
                          }
                        />
                      }
                    />
                  </div>
                  <div className="box-legal-representative-data" style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField
                      name="bairro"
                      label="BAIRRO"
                      id="bairro"
                      required
                      onChange={(e) => setRepresentative({ ...representative, BAIRRO: e.target.value })}
                      value={representative.BAIRRO}
                    />
                    <TextField
                      name="cidade"
                      label="CIDADE"
                      id="cidade"
                      required
                      value={representative.CIDADE}
                      onChange={(e) => setRepresentative({ ...representative, CIDADE: e.target.value })}
                    />
                  </div>
                  <div className="box-legal-representative-data">
                    <TextField
                      name="endereco"
                      label="ENDERECO"
                      id="endereco"
                      required
                      value={representative.ENDERECO}
                      onChange={(e) => setRepresentative({ ...representative, ENDERECO: e.target.value })}
                      fullWidth
                    />
                  </div>
                  <div className="box-legal-representative-data" style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField
                      onChange={(e) => setRepresentative({ ...representative, NUMENDERECO: e.target.value })}
                      name="numero"
                      label="Nº ENDERECO"
                      id="numero"
                      value={representative.NUMENDERECO}
                    />
                    <TextField
                      name="complemento"
                      label="COMPLEMENTO"
                      id="complemento"
                      value={representative.COMPLEMENTO}
                      onChange={(e) => setRepresentative({ ...representative, COMPLEMENTO: e.target.value })}
                    />
                    <TextField
                      onChange={(e) => setRepresentative({ ...representative, UF: e.target.value })}
                      name="uf"
                      label="UF"
                      id="uf"
                      value={representative.UF}
                    />
                  </div>
                  <div
                    className="box-legal-representative-data-phone"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <TextField
                      name="cep"
                      label="CEP"
                      id="cep"
                      type="number"
                      required
                      value={representative.CEP}
                      onChange={(e) => setRepresentative({ ...representative, CEP: e.target.value })}
                    />

                    <FormControlLabel
                      labelPlacement="top"
                      label="Celular"
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        cursor: 'context-menu',
                      }}
                      control={
                        <ReactInputMask
                          value={representative.CELULAR}
                          label="CELULAR"
                          onChange={(e) => setRepresentative({ ...representative, CELULAR: e.target.value })}
                          className="Mask"
                          mask={'(99) 99999-9999'}
                          maskChar=""
                          control={
                            <TextField
                              name="celular"
                              label="CELULAR"
                              id="celular"
                              required
                              value={representative.CELULAR}
                              onChange={(e) => setRepresentative({ ...representative, CELULAR: e.target.value })}
                              fullWidth
                            />
                          }
                        />
                      }
                    />
                  </div>
                </div>
              </div>
              <div style={{ width: '100%', justifyContent: 'flex-end', marginTop: '1rem' }}>
                <Button color={'info'} type="submit">
                  Salvar
                </Button>
              </div>
            </form>
          </ContainerLegalRepresentative>
        </ModalBody>
      </Modal>

      {/*     <ModalMensagem
        modal={modalMensagem}
        mensagem={mensagem}
        sucesso={sucesso}
        toggle={(e) => {
          setModalMensagem(false);
        }}
      /> */}

      <ModalMensagemSemReloadNew
        open={modalMensagem}
        close={() => setModalMensagem(false)}
        callback={() => {
          callBack();
          setModalMensagem(false);
        }}
        sucesso={sucesso}
        mensagem={mensagem}
        title={mensagem && 'Sucesso'}
      />
    </>
  );
}
