import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Form } from 'reactstrap';
import { validationPassword, updateCoban, updatePerfil } from '../../../store/coban/actions';
import { toggleModalSolicitarCartaoNominal } from '../../../store/user/actions';
import {
  deleteIdDispositivo,
  alteraUsuarioByUUID,
  alteraRepresentante,
  deleteAccounting,
  recuperaSenhaUsuarioByUUID,
  solicitarContaDigitalByUUID,
  liberarAcessoDispositivoByUUID,
  taxaAtualizar,
  revertUser,
  solicitarCartaoUsuario,
  atualizaSolicitacaoCartao,
} from '../../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import ModalMensagem from '../../../views/Transaction/ModalMensagem';
import AnimatedLoading from '../../../components/Loader/AnimatedLoading';

const ModalSenha = ({
  close,
  modal,
  nextFunction,
  operation,
  dados,
  allFields,
  setModalDoc,
  setModalAlterarCoban,
  setModalQrCode,
  modalTrocaSenha,
  setModalBlock,
  setModalAlteraConta,
  setModalSaldo,
  setModalBloquearSaldo,
  setModalJustificativa,
  setModalStatusUsuario,
}) => {
  const dispatch = useDispatch();
  const {
    coban,
    user: { user },
  } = useSelector((state) => state);

  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [modalMessage, setModalMessage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loaded, setLoaded] = useState(false);

  function openModalMessage(res) {
    const { mensagem, erro, retorno } = res;
    setMessage(mensagem);
    setModalMessage(true);
    if ((retorno && retorno == 'sucesso') || !erro) setSuccess(true);
    if ((retorno && retorno == 'erro') || erro) setSuccess(false);
  }

  async function clickButton() {
    await dispatch(
      validationPassword({ SENHA: password }, (res) => {
        const { erro } = res;
        if (erro == true) {
          openModalMessage(res);
        } else if (operation == 'alterarTaxa') {
          const cpfcnpj = user.CPFCNPJ;
          const valor = dados;

          dispatch(
            taxaAtualizar(cpfcnpj, valor, (res) => {
              openModalMessage(res);
            }),
          );
        } else if (operation == 'alterarCadastro') {
          const fields = allFields;

          dispatch(
            alteraUsuarioByUUID(user.UUID, fields, (res) => {
              openModalMessage(res);
            }),
          );
        } else if (operation == 'removerPerfil') {
          dispatch(
            updatePerfil(dados, (res) => {
              openModalMessage(res);
            }),
          );
        } else if (operation == 'removerCoban') {
          dispatch(
            updateCoban(dados, (res) => {
              openModalMessage(res);
            }),
          );
        } else if (operation == 'alterarRepresentante') {
          const fields = user.REPRESENTANTES;

          dispatch(
            alteraRepresentante({ REPRESENTANTES: fields }, (res) => {
              openModalMessage(res);
            }),
          );
        } else if (operation == 'criarContaDigital') {
          const id_coban = user.ID_COBAN;

          dispatch(
            solicitarContaDigitalByUUID(user.UUID, { ID_COBAN: id_coban }, (res) => {
              openModalMessage(res);
            }),
          );
        } else if (operation == 'recuperarSenha') {
          dispatch(
            recuperaSenhaUsuarioByUUID(user.UUID, (res) => {
              openModalMessage(res);
            }),
          );
        } else if (operation == 'liberarAcessoDispositivo') {
          dispatch(
            liberarAcessoDispositivoByUUID(user.UUID, (res) => {
              openModalMessage(res);
            }),
          );
        } else if (operation == 'atualizarSolicitacaoCartao') {
          dispatch(
            atualizaSolicitacaoCartao(user.UUID, (res) => {
              openModalMessage(res);
            }),
          );
        } else if (operation == 'excluirConta') {
          const { CPFCNPJ, ID } = dados;
          dispatch(
            deleteAccounting(CPFCNPJ, ID, (res) => {
              openModalMessage(res);
            }),
          );
        } else if (operation == 'removeDispositivo') {
          const { CPFCNPJ } = user;

          dispatch(
            deleteIdDispositivo(CPFCNPJ, (res) => {
              openModalMessage(res);
            }),
          );
        } else if (operation == 'reverterUsuario') {
          const CPFCNPJ = dados;

          dispatch(
            revertUser({ CPFUSUARIO: CPFCNPJ }, (res) => {
              openModalMessage(res);
            }),
          );
        } else if (operation == 'sendDocument') {
          close();
          setModalDoc(true);
        } else if (operation == 'changeProfile') {
          close();

          setModalAlterarCoban({
            open: true,
            title: 'Alterar Perfil',
          });
        } else if (operation == 'cartaoNaoNominal') {
          close();
          setModalQrCode(true);
        } else if (operation == 'trocaSenha') {
          modalTrocaSenha();
        } else if (operation == 'block') {
          close();
          setModalBlock(true);
        } else if (operation == 'solicitarCartao') {
          setLoaded(true);

          dispatch(toggleModalSolicitarCartaoNominal());

          setLoaded(false);
          close();
        } else if (operation == 'maisContaBancaria') {
          setModalAlteraConta(true);
        } else if (operation == 'operarSaldoCliente') {
          setModalSaldo(true);
        } else if (operation == 'bloquearSaldoCliente') {
          setModalBloquearSaldo(true);
        } else if (operation == 'encerrarConta') {
          setModalJustificativa(true);
        } else if (operation == 'statusUsuario') {
          setModalStatusUsuario(true);
        } else {
          close();
          nextFunction();
        }
      }),
    );
    setPassword('');
  }

  return (
    <Modal isOpen={modal} toggle={close} autoFocus={false}>
      <ModalHeader toggle={close}>Por favor, digite a senha antes de continuar: </ModalHeader>
      <Form onSubmit={(e) => e.preventDefault()}>
        <ModalBody>
          <Input
            autoFocus={true}
            size="md"
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          ></Input>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            disabled={password == '' ? true : false}
            size="sm"
            color="success"
            onClick={() => {
              clickButton();
            }}
            block
          >
            Confirmar
          </Button>
        </ModalFooter>
      </Form>
      <ModalMensagem
        modal={modalMessage}
        toggle={() => {
          setModalMessage(false);
        }}
        mensagem={message}
        sucesso={success}
      />
      <AnimatedLoading isActive={loaded} />
    </Modal>
  );
};

export default ModalSenha;
