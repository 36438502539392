import React, { useMemo } from 'react';
import CustomDataTable from '../../../../components/DataTable/DataTable';
import Loading from '../../../../components/Loader/Loading';
import { formatCNPJ, formatCPF, formatDateBR, isCpf } from '../../../../utils';
import StatusContainer from '../../../../components/StatusContainer/StatusContainer';

function LimitsUsers({ search, loading, limits, pagination, getValues }) {
  useMemo(() => {
    search(getValues());
  }, []);

  return (
    <>
      <div style={{ marginTop: '1rem' }}>
        {loading ? (
          <Loading color="#11cdef" />
        ) : (
          <CustomDataTable
            loading={loading}
            pagination={pagination}
            previousPage={{ onClick: () => search({ ...getValues(), PAGE: pagination.prevPage }) }}
            nowPage={{ page: pagination.page, onClick: () => search({ ...getValues(), PAGE: pagination.page }) }}
            nextPage={{ onClick: () => search({ ...getValues(), PAGE: pagination.nextPage }) }}
            data={limits}
            headers={[
              {
                name: 'Data',
                selector: (limit) => formatDateBR(limit.DTHR_INSERT),
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'Nome',
                selector: (limit) => limit.NOME,
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'CPF/CNPJ',
                selector: (limit) =>
                  limit.CPFCNPJ && (isCpf(limit.CPFCNPJ) ? formatCPF(limit.CPFCNPJ) : formatCNPJ(limit.CPFCNPJ)),
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'CONTA',
                selector: (limit) => `${limit.CONTA}-${limit.DIGITO}`,
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'TIPO LIMITE',
                selector: (limit) => limit.TIPO_LIMITE,
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'TIPO TURNO',
                selector: (limit) => limit.TIPO_TURNO,
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'VALOR ATUAL',
                selector: (limit) =>
                  limit.VALOR_LIMITE
                    ? limit.VALOR_LIMITE.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                    : '',
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'Parceiro',
                selector: (limit) => (
                  <StatusContainer code={limit.TIPO_USUARIO.toUpperCase()} description={limit.TIPO_USUARIO} />
                ),
                style: { justifyContent: 'start' },
                sortable: true,
              },
            ]}
            customStyles={{
              cells: {
                headCells: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                  },
                },
              },
            }}
          />
        )}
      </div>
    </>
  );
}

export default LimitsUsers;