import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import GenericButton from '../Buttons/Button.js';
import Icon from '../Icons/IconsButton.js';
// Transferências

export default function FilterWithdrawals({
  resetFilters,
  reset,
  identificador,
  filter,
  setFilter,
  filtrar,
  handleChange,
}) {
  const { parceiro, statusTransacao } = useSelector((state) => state.transactions);

  return (
    <Form className="form" onSubmit={(e) => e.preventDefault()}>
      <FormGroup className="mt-3">
        <Row xs="12" style={{ padding: '15px' }}>
          <Col xs="4" style={{ minWidth: '380px', marginLeft: '-15px' }}>
            <Label size="sm">Pesquisar</Label>
            <Input
              onChange={handleChange}
              autoFocus
              type="text"
              name="search"
              id="search"
              value={filter.search}
              size="sm"
              placeholder="Nome ou CPF/CNPJ"
            />
          </Col>

          <Col
            xs="1"
            style={{
              minWidth: '130px',
              paddingLeft: '0',
              paddingRight: '10px',
            }}
          >
            <Label size="sm">Tipo Pagamento</Label>
            <Input
              value={filter.tipoTransacao}
              onChange={handleChange}
              type="select"
              name="tipoTransacao"
              id="tipoTransacao"
              size="sm"
            >
              <option value="">Todas</option>
              <option value={1}>Débito</option>
              <option value={2}>Crédito</option>
            </Input>
          </Col>

          <Col
            xs="1"
            style={{
              minWidth: '175px',
              paddingLeft: '0',
              paddingRight: '10px',
            }}
          >
            <Label size="sm">Status</Label>
            <Input value={filter.status} onChange={handleChange} type="select" name="status" id="status" size="sm">
              <option value="">Todas</option>
              {statusTransacao &&
                statusTransacao.length > 0 &&
                statusTransacao.map((status) => <option value={status.ID}>{status.DESCRICAO}</option>)}
            </Input>
          </Col>
          <Col
            xs="1"
            style={{
              minWidth: '175px',
              paddingLeft: '0',
              paddingRight: '10px',
            }}
          >
            <Label size="sm">Parceiro</Label>
            <Input
              value={filter.idParceiro}
              onChange={handleChange}
              type="select"
              name="idParceiro"
              id="idParceiro"
              size="sm"
            >
              <option value="">Todos</option>
              {parceiro &&
                parceiro.length > 0 &&
                parceiro.map((parceiro) => <option value={parceiro.ID}>{parceiro.DESCRICAO}</option>)}
            </Input>
          </Col>
          <Col
            xs="2"
            style={{
              minWidth: '163px',
              paddingLeft: '0',
              paddingRight: '10px',
            }}
          >
            <Label size="sm">Data Inicial</Label>
            <Input
              value={filter.dataInicial}
              name="dataInicial"
              type="date"
              size="sm"
              max="9999-12-31"
              onChange={handleChange}
            />
          </Col>
          <Col
            xs="2"
            style={{
              paddingLeft: '0',
              paddingRight: '10px',
            }}
          >
            <Label size="sm">Data Final</Label>
            <Input
              value={filter.dataFinal}
              name="dataFinal"
              type="date"
              size="sm"
              max="9999-12-31"
              onChange={handleChange}
            />
          </Col>
          <Col
            xs="1.5"
            style={{
              paddingLeft: '0',
              paddingRight: '10px',
            }}
          >
            <Label size="sm">Aprovação Automática</Label>
            <Input
              value={filter.aprovacaoAuto}
              name="aprovacaoAuto"
              id="aprovacaoAuto"
              type="select"
              size="sm"
              onChange={handleChange}
            >
              <option value="">Todas</option>
              <option value="S">Sim</option>
              <option value="N">Não</option>
            </Input>
          </Col>
          <div
            style={{
              alignSelf: 'flex-end',
            }}
          >
            <GenericButton
              marginTop={'25px !important'}
              width={'120px'}
              heigth={'35px'}
              onClick={filtrar}
              color="primary"
              size="sm"
              type="submit"
            >
              <Icon className="fas fa-search"></Icon>Buscar
            </GenericButton>
          </div>
          <Col
            style={{
              paddingRight: '10px',
              alignSelf: 'flex-end',
              paddingLeft: '0',
            }}
          >
            <GenericButton
              onClick={() => resetFilters()}
              marginTop={'25px !important'}
              backgroundColor={'#ffffff'}
              borderColor={'#2405F2'}
              width={'140px'}
              heigth={'35px'}
              marginLeft={'10px'}
              color={'#2405F2'}
            >
              <Icon className="fas fa-file-export"></Icon>Limpar filtros
            </GenericButton>
          </Col>
        </Row>
        <div
          className="mt-2"
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            justifyContent: 'flex-start',
          }}
        >
          <Input
            onChange={handleChange}
            type="select"
            name="limit"
            id="limit"
            value={filter.limit}
            style={{ maxWidth: 80, marginRight: 10 }}
            size="sm"
          >
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>1000</option>
            <option value={''}>Todos</option>
          </Input>
          <Label style={{ width: 'inherent' }}>Resultados por página</Label>
        </div>
      </FormGroup>
    </Form>
  );
}