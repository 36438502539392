import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'reactstrap';
import api from '../../service/api';
import ModalTermo from '../ModalTermo/ModalTermo';
import CustomDataTable from '../../components/DataTable/DataTable';
import valueEmpty from '../../utils/valueEmpty';
import InputSearch from '../../components/Filter/components/InputSearch';
import GenericButton from '../../components/Buttons/Button';
import { Icon } from '@mui/material';
import ModalTermoPDF from '../ModalTermoPDF/ModalTermoPDF';

export default function TermosTab() {
  const [modalTermo, setModalTermo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contracts, setContracts] = useState(false);
  const [contractName, setContractName] = useState('');
  const [idContract, setIdContract] = useState(false);
  const [modalTermoPDF, setModalTermoPDF] = useState(false);
  const [alert, setAlert] = useState(false);

  function getTerms() {
    setLoading(true);
    api
      .get(`contrato/termos-editaveis?NOME_CONTRATO=${contractName}`)
      .then((res) => {
        setContracts(res.data.data);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getTerms();
  }, []);

  const headers = [
    {
      name: 'ID',
      style: { maxWidth: '50px' },
      selector: (row) => valueEmpty(row.ID),
      sortable: true,
    },
    {
      name: 'Nome',
      style: { textAlign: 'center' },
      selector: (row) => valueEmpty(row.NOME_CONTRATO),
      sortable: true,
    },
    {
      name: 'Sistema',

      selector: (row) => row.NOME_SISTEMA,
      sortable: true,
    },

    {
      name: 'Ações',
      style: { maxWidth: '400px !important' },
      selector: (row) => (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '0.5rem' }}>
          <GenericButton
            onClick={() => {
              setIdContract(row.ID);
              setModalTermoPDF(true);
            }}
            type="submit"
            width={'140px'}
            heigth={'35px'}
          >
            <Icon style={{ marginRight: '.4rem', fontSize: '1rem' }} className="fas fa-eye"></Icon>Ver
          </GenericButton>
          <GenericButton
            onClick={() => {
              setIdContract(row.ID);
              setModalTermo(true);
            }}
            type="submit"
            width={'140px'}
            heigth={'35px'}
          >
            <Icon style={{ marginRight: '.4rem', fontSize: '1rem' }} className="fas fa-edit"></Icon>Editar
          </GenericButton>
        </div>
      ),
      sortable: true,
    },
  ];

  const customStyles = {
    cells: {
      tableCells: {
        maxWidth: '400px',
      },
      headCells: {
        style: {
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
        },
      },
    },
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem 0' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <InputSearch
            placeholder="Nome do contrato"
            width="20vw"
            value={contractName}
            setValue={(value) => setContractName(value)}
          />
          <GenericButton
            onClick={() => getTerms()}
            type="submit"
            marginTop={'40px !important'}
            width={'140px'}
            heigth={'35px'}
          >
            <Icon style={{ marginRight: '.4rem', fontSize: '1rem' }} className="fas fa-search"></Icon>Buscar
          </GenericButton>
        </div>
        <Button style={{ width: '140px', height: '35px' }} onClick={() => setModalTermo(true)}>
          Novo Termo
        </Button>
      </div>

      <div style={{ marginTop: '1rem' }}>
        <CustomDataTable loading={loading} data={contracts} headers={headers} customStyles={customStyles} />
      </div>

      <ModalTermo
        idContract={idContract}
        open={modalTermo}
        getTerms={getTerms}
        toggle={() => {
          setModalTermo(false);
          setIdContract(false);
        }}
      />

      <ModalTermoPDF
        idContract={idContract}
        open={modalTermoPDF}
        toggle={() => {
          setIdContract(false);
          setModalTermoPDF(false);
        }}
      />
    </div>
  );
}
