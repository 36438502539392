import React, { useEffect, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import api from '../../../service/api';
import { tedRejected } from '../../../store/transactions/actions';
import AnimatedLoading from '../../../components/Loader/AnimatedLoading';
import { useDispatch } from 'react-redux';
import ModalMensagem from './ModalMensagem';

const ModalInfoReversalTransfer = ({ open, toggle, callback, id_usuario, cod_estorno }) => {
  const dispatch = useDispatch();
  const [mensagens, setMensagens] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [codeBacen, setCodeBacen] = useState(false);
  const [modalMensagem, setModalMensagem] = useState({
    open: false,
    mensagem: '',
    tipo: '',
    sucesso: true,
  });

  useEffect(() => {
    api.get('/estorno/mensagens').then(({ data }) => setMensagens(data.data));
    setCodeBacen(cod_estorno);
  }, [open]);

  const openModalMessage = (res, tipo) => {
    const { mensagem, retorno } = res;

    setModalMensagem({
      open: true,
      mensagem,
      tipo,
      sucesso: retorno && retorno == 'sucesso',
    });
  };

  async function rejected() {
    await dispatch(
      tedRejected(
        {
          ID: id_usuario,
          COD_ESTORNO: codeBacen,
          CPFCNPJ_COBAN: localStorage.getItem('CPFCNPJ'),
        },
        (res) => {
          setLoaded(false);
          callback();
          openModalMessage(res.data, 'Reprovado');
        },
      ),
    );
  }

  return (
    <>
      <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h3>Motivo da devolução</h3>
        </ModalHeader>
        <ModalBody>
          <Select
            style={{ maxWidth: '100%' }}
            value={codeBacen}
            label="Motivo"
            required
            placeholder="Motivo"
            onChange={(event) => setCodeBacen(event.target.value)}
          >
            {mensagens &&
              mensagens.length > 0 &&
              mensagens.map((mensagem) => (
                <MenuItem value={mensagem.CODE_BACEN}>{`${mensagem.CODE_BACEN} - ${mensagem.DESCRICAO}`}</MenuItem>
              ))}
          </Select>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" size="sm" block onClick={toggle}>
            Cancelar
          </Button>
          <Button
            color="success"
            size="sm"
            block
            onClick={() => {
              rejected();
              setLoaded(true);
            }}
          >
            Devolver
          </Button>
        </ModalFooter>
      </Modal>
      <AnimatedLoading isActive={loaded} />

      <ModalMensagem
        modal={modalMensagem.open}
        mensagem={modalMensagem.mensagem}
        sucesso={modalMensagem.sucesso}
        tipo={modalMensagem.tipo}
        toggle={() => setModalMensagem({ open: false, mensagem: '', tipo: '', sucesso: true })}
      />
    </>
  );
};

export default ModalInfoReversalTransfer;
