import React, { useState } from 'react';
import AccordionInfoBankAccount from './components/AccordionInfoBankAccount/AccordionInfoBankAccount';
import { useDispatch, useSelector } from 'react-redux';
import { getBalanceHistoryByUUID } from '../../store/user/actions';
import moment from 'moment';
import ExtractTableContainer from './ExtractTableContainer';

export default function CardBankAccount(props) {
  const dispatch = useDispatch();
  const {
    user: { CONTA_BACEN },
  } = useSelector((state) => state.user);

  const [accountVisible, setAccountVisible] = useState(CONTA_BACEN[0]);

  function searchWithFilter(TIPO) {
    if (accountVisible && accountVisible.UUID) {
      dispatch(getBalanceHistoryByUUID(accountVisible.UUID, {...filter, TIPO}));
    }
  }

  const [filter, setFilter] = useState({
    LIMIT: '',
    DATA_INICIO: moment().add(-7, 'days').format('YYYY-MM-DD'),
    DATA_FIM: moment().format('YYYY-MM-DD'),
    PAGE: 1,
    SIZE: 10,
  });

  return (
    <>
      <AccordionInfoBankAccount
        informacoesPessoais={props.informacoesPessoais}
        user={props.user}
        setModalSenhaUnica={props.setModalSenhaUnica}
        modalSenhaUnica={props.modalSenhaUnica}
        infoPessoaisRedux={props.infoPessoaisRedux}
        setOperation={props.setOperation}
        accountVisible={accountVisible}
        setAccountVisible={setAccountVisible}
        searchWithFilter={searchWithFilter}
      />
      <ExtractTableContainer
        accountVisible={accountVisible}
        filter={filter}
        setFilter={setFilter}
        searchWithFilter={searchWithFilter}
      />
    </>
  );
}
