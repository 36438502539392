import { LOADING, SUCCESS, ERROR } from './types';

const INITIAL_STATE = {
  errorExtract: false,
  loadingExtract: false,
  extract: [],
  messageExtract: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loadingExtract: true,
      };
    case SUCCESS:
      const { data } = action.payload;

      return {
        ...state,
        errorExtract: false,
        loadingExtract: false,
        extract: data,
      };
    case ERROR:
      return {
        ...state,
        errorExtract: true,
        loadingExtract: false,
        extract: [],
        messageExtract: 'Algo inesperado aconteceu!',
      };
    default:
      return state;
  }
};
