export function formatDateKyc(date) {
  const splitted = date.split('/');
  return splitted[2] + '-' + splitted[1] + '-' + splitted[0];
}

export function unformatDateKyc(date) {
  if (!date) return '--/--/--';
  if (date.length == 0) return '--/--/--';
  const splitted = date.split('-');
  return splitted[2] + '/' + splitted[1] + '/' + splitted[0];
}

export function dateTimeFormat(date) {
  const split = date.split(' ');
  const formatSplit = unformatDateKyc(split[0]);
  return formatSplit + ' ' + split[1];
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function formatDateBR(str) {
  let date = new Date(str);

  return (
    [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/') +
    ' ' +
    [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(':')
  );
}
