import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Container } from 'reactstrap'
import { approveSolicitationLimit } from '../../../../../../store/transactions/actions';
import Loading from '../../../../../../components/Loader/Loading';
import ModalMensagemSemReload from '../../../../../Transaction/ModalMensagemSemReload';

import { InputAdornment, TextField } from '@mui/material';

export default function ModalReasonLimit({ open, toggle, title, limit, search, success }) {
    const dispatch = useDispatch();
    const { handleSubmit, reset, control } = useForm();
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ open: false, success: false, message: '' });

    const onSubmit = ({ message, approve_amount }) => {
        setLoading(true);
        const { ID_SOLICITACAO } = limit;

        dispatch(approveSolicitationLimit({ ID_SOLICITACAO, VALOR_APROVADO: +approve_amount, APROVADO: success ? 'S' : 'N', CPFCNPJ_ANALISE: localStorage.getItem('CPFCNPJ'), MOTIVO: message }, () => {
            setLoading(false);
            setModalMessage({ open: true, message: 'Sucesso!', success: true });
            handleToggle();
            search();

            return null
        }));
    }

    const handleToggle = () => {
        toggle();
        reset();
    }

    return (
        <>
            <Modal isOpen={open} toggle={handleToggle}>
                {loading ? <Loading color="#11cdef" /> : (
                    <>
                        <ModalHeader toggle={handleToggle}>
                            {title}
                        </ModalHeader>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ModalBody>
                                <Container>
                                    {success && (
                                        <Row style={{ marginBottom: '1rem' }}>
                                            <Controller
                                                name='approve_amount'
                                                control={control}
                                                defaultValue={limit.VALOR_SOLICITADO}
                                                rules={{ required: true }}
                                                render={({ field }) => <TextField label="Valor Aprovado" InputProps={{ startAdornment: <InputAdornment position='start'>R$</InputAdornment> }} type='number' {...field} />}
                                            />
                                        </Row>
                                    )}
                                    <Row>
                                        <Controller
                                            name='message'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => <TextField label="Motivo" fullWidth multiline maxRows={4} {...field} />}
                                        />
                                    </Row>
                                </Container>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="success" type="submit">
                                    Confirmar
                                </Button>
                            </ModalFooter>
                        </form>
                    </>
                )}
            </Modal>
            <ModalMensagemSemReload modal={modalMessage.open} toggle={() => setModalMessage({ open: false, message: '', success: false })} mensagem={modalMessage.message} sucesso={modalMessage.success} />
        </>
    )
}