import React, { useState } from 'react';
import { Row, Button, Col, Input } from 'reactstrap';
import { addDocCoban } from '../../store/coban/actions';
import { getBase64 } from '../../utils/functions';
const DocumentsForm = (props) => {
  const [documentsQuantity, setDocumentsQuantity] = useState(new Array(0).fill(1));
  const [selectedFile, setSelectedFile] = useState(null);

  var onChangeHandler = async (event) => {
    //setSelectedFile(event.target.files[0])
    const convert = await getBase64(event.target.files[0]);
    var newbie = convert.replace('data:image/png;');
  };
  const alterDocumentsQuantity = (type, index = 0) => {
    switch (type) {
      case 'add':
        setDocumentsQuantity([...documentsQuantity, 1]);
        break;
      case 'rm':
        setDocumentsQuantity(documentsQuantity.filter((d, i) => i != index));
        break;
    }
  };
  return (
    <div>
      {/* <Row className="mb-3" style={{ justifyContent: 'space-between', paddingLeft: 10, paddingRight: 10}}>
                <h4>Documentos</h4>
                <Button size='sm' color='info'
                    onClick={() => alterDocumentsQuantity('add')}
                >+ Adicionar</Button>
            </Row> */}

      <Col>
        {documentsQuantity.map((value, index) => {
          return (
            <Row key={'docRow' + index} style={{ alignItems: 'center' }}>
              {/* <Input placeholder='Tipo do documento' size='sm'  style={{ maxWidth: 150 }} autoFocus={index == 0}/> */}

              <Button style={{ marginRight: 10, marginLeft: 10 }} color="primary" size="sm">
                <Input
                  onChange={onChangeHandler}
                  style={{ background: 'transparent' }}
                  type="file"
                  accept=".png, .jpg"
                ></Input>
              </Button>
              <Button color="danger" onClick={() => alterDocumentsQuantity('rm', index)} size="sm">
                Excluir
              </Button>
              <Button color="primary" onClick={addDocCoban(selectedFile)} size="sm">
                Enviar
              </Button>
            </Row>
          );
        })}
      </Col>
    </div>
  );
};

export default DocumentsForm;
