import React, { useEffect, useState } from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import Loading from '../../../../../../components/Loader/Loading';
import { MainContainer, TrackingHeadContainer } from './styles';
import api from '../../../../../../service/api';
import { formatDateBR } from '../../../../../../utils/dateKyc';
import CustomDataTable from '../../../../../../components/DataTable/DataTable';

export default function ModalRastreioCartao({ toggle, open, callback, choosenCard }) {
  const [tracking, setTracking] = useState(false);
  const [loading, setLoading] = useState(false);

  function listarRastreio() {
    setLoading(true);
    api
      .get(`/banco-parceiro/rastrear/cartao?CARD_ID=${choosenCard.TRACKING_CODE}`)
      .then((res) => {
        if (res.data.retorno === 'sucesso') {
          setTracking(res.data.data);
        }
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (choosenCard.CARD_ID) {
      listarRastreio();
    }
  }, [choosenCard]);

  const headers = [
    {
      name: 'Data',
      selector: (row) => formatDateBR(row.occurred_at),
      style: { textAlign: 'start' },
    },
    {
      name: 'Status',
      selector: (row) => row.tracking_status_name,
      style: { textAlign: 'center' },
    },
  ];

  return (
    <>
      <Modal isOpen={open} toggle={toggle} size="lg" style={{ maxWidth: '600px' }}>
        <ModalHeader toggle={toggle}>
          <h2>Rastreamento de cartão</h2>

          {tracking.delivered_at && (
            <TrackingHeadContainer>
              <span>Entregue em: </span>
              <span>{formatDateBR(tracking.delivered_at)}</span>
            </TrackingHeadContainer>
          )}

          {tracking.receiver && (
            <TrackingHeadContainer>
              <span>Recebido por: </span>
              <span>{tracking.receiver}</span>
            </TrackingHeadContainer>
          )}

          {tracking.receiver_document && (
            <TrackingHeadContainer>
              <span>Documento recebedor: </span>
              <span>{tracking.receiver_document}</span>
            </TrackingHeadContainer>
          )}
        </ModalHeader>
        {!loading ? (
          <ModalBody>
            <CustomDataTable loading={loading} data={tracking && tracking.events} headers={headers} />
          </ModalBody>
        ) : (
          <Loading color="blue" />
        )}
        <ModalFooter></ModalFooter>
      </Modal>
    </>
  );
}
