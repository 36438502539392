import { Box, Button, FormControlLabel, Radio, RadioGroup} from "@mui/material"
import React, { useEffect, useState } from "react"
import GenericButton from "../../components/Buttons/Button"
import  ModalAdmins from "../Companies/Modals/ModalAdmins/ModalAdmins"
import api from "../../service/api"
import { Text } from "./style"
import { FaPix } from "react-icons/fa6";
import { MdPayments } from "react-icons/md";
import Loading from "../../components/Loader/Loading"


export default function AuthTab( ){
    const [open, setOpen] = useState(false)
    const [response, setResponse] = useState([])
    const [loading, setLoading ] = useState(true )
    const [renderScreen, setRenderScreen] = useState(false)


    const handleChangeScreenStatus = async( status, id_screen)=> { 
        try {
            await api.put('/telas-pj/alterar-status',
                {
                    ID_TELA: id_screen,
                    STATUS: status?"S":"N"
                }
            )
            setRenderScreen(!renderScreen); 
        } catch (error) {
            console.log(error)
        }
    }

    
    
    const getTelas = async ( ) => { 

        setLoading(true)
        try {
            const res = await api.get("/telas-pj")
            setResponse(res.data.data)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    
    
    useEffect(()=>{
        getTelas()
    }, [renderScreen]);

    return( 
    <Box width={"100%"}  boxSizing={"border-box"}> 
        <Box p={"2rem 0rem" }  display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <h1>Telas</h1>
            <GenericButton  style={{ width: 'max-content', height: '35px'}} onClick={ ()=> setOpen(true)}>
                Usuários administradores
            </GenericButton>
        </Box>

        <Box width={"100%"} bgcolor={"white"} display={"flex"} flexDirection={"column"} border={"solid 1px #e6e7e9"} gap={'0.7rem'} padding={"0.7rem"} borderRadius={"0.4rem"} >

      
            {!loading ? response.map((s)=> { 
                return(
                    <Box key={s.ID_TELA} display={"flex"} bgcolor={"#fcfcfc"} border={"1px solid #e6e7e9"} justifyContent="space-between" gap={'0.7rem'} alignItems={"center"} padding={'0.7rem'} borderRadius={"0.4rem"}  >
                        <Box display={"flex"} gap={"1rem"}>
                            <Box padding={"1rem"} borderRadius={"50%"} color={"white"} bgcolor={"#00c2e0"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                {s.ICONE_REACT== "pix"&&<FaPix />}
                                {s.ICONE_REACT == "pay"&&<MdPayments />}
                                {<i className={`fas ${s.ICONE_REACT}`} />}
                            </Box>
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} >
                                <Text>{s.DESCRICAO}</Text>
                            </Box>
                        </Box>

                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} margin={"0"} p={"0"}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-form-control-label-placement"
                                name="position"
                            >
                                <FormControlLabel
                                    sx={{
                                        margin:"0",
                                        padding:"0",
                                        '.MuiFormControlLabel-label':{
                                            fontFamily: "Montserrat, sans-serif",
                                            fontWeight: 400,
                                            color: "black",
                                            fontSize: "16px"
                                        }
                                        }}
                                    control={ <Radio
                                        checked={s.ATIVO === 'S'}
                                        onChange={(e)=>handleChangeScreenStatus(e.target.checked, s.ID_TELA)}
                                        Value="S"
                                        sx={{
                                            color: "grey",
                                            '&.Mui-checked': {
                                                color: "#2dce89",
                                            }
                                        }}
                                    />}
                                    value="S"
                                    label="ATIVO"
                                />
                                <FormControlLabel
                                    sx={{
                                        margin:"0",
                                        padding:"0",
                                        '.MuiFormControlLabel-label':{
                                            fontFamily: "Montserrat, sans-serif",
                                            fontWeight: 400,
                                            color: "black",
                                            fontSize: "16px"
                                        }
                                    }}
                                   control={<Radio
                                        checked={s.ATIVO === 'N'}
                                        onChange={(e)=>handleChangeScreenStatus(!e.target.checked, s.ID_TELA)}
                                        value="N"
                                        sx={{
                                            color: "grey",
                                            '&.Mui-checked': {
                                                color: "#ae604e",
                                            }
                                        }}
                                        />}
                                    value="N"
                                    label="INATIVO"
                                />
                            </RadioGroup>
                        </Box>
                    </Box>
                )
            }):(<Loading color={"#2405f2"}/>)}
        </Box>

        <ModalAdmins open={open} toggle={()=> setOpen(!open)} />
        
    </Box>
    )

}