import { Box } from '@mui/system';
import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Tab, Tabs, Typography } from '@mui/material';
import ExtractCCThirdparty from './components/ExtractCCThirdparty/ExtractCCThirdparty';
import ExtractAccountCard from './components/ExtractBankAccount/ExtractBankAccount';
import { ExpandMore } from '@mui/icons-material';


export default function ExtractTableContainer({ accountVisible, filter, setFilter, searchWithFilter }) {
  const [expanded, setExpanded] = useState(true);
  const [tabNumber, setTabNumber] = useState(0);

    const components = [
        <ExtractAccountCard
            accountVisible={accountVisible}
            filter={filter}
            setFilter={setFilter}
            searchWithFilter={searchWithFilter}
        />,
        <ExtractCCThirdparty
            accountVisible={accountVisible}
            filter={filter}
            setFilter={setFilter}
            searchWithFilter={searchWithFilter}
        />
    ];

    return (
        <Box>
            <Accordion expanded={expanded}>
                <AccordionSummary
                    onClick={() => setExpanded(!expanded)}
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-content"
                >
                    <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
                        Extrato
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Tabs value={tabNumber} onChange={(event, number) => setTabNumber(number)}>
                        <Tab
                            sx={{
                                bgcolor: 'white',
                                borderTopLeftRadius: 10,
                                borderTopRightRadius: 10,
                            }}
                            label="CDC"
                        />
                        <Tab
                            sx={{
                                bgcolor: 'white',
                                borderTopLeftRadius: 10,
                                borderTopRightRadius: 10,
                            }}
                            label="API PIX"
                        />
                    </Tabs>
                    {components[tabNumber]}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}
