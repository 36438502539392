import {
  DEFAULT_TYPE,
  GET_ACESSOBIO_ERROR,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  KYC,
  USERS_RESPONSE,
  USER_ADD_STATE,
  USER_RESPONSE_DATATRUST,
  GET_STATUS_USUARIO,
  TOGGLE_MODAL_SOLICITAR_CARTAO_NOMINAL,
} from './types';

const INITIAL_STATE = {
  users: [],
  user: null,
  userTotal: null,
  type: 'espera',
  ACESSOBIO: [],
  ConflictSubject: {},
  mensagem: '',
  messageSolicitation: '',
  saldo: null,
  typeCard: [],
  typeFlag: [],
  loading: false,
  erro: false,
  erro_message: null,
  loading_acessobio: false,
  erro_bio: false,
  loading_user: false,
  erro_user: false,
  erro_message_user: null,
  solicitation: [],
  pagination: {},
  filtro: {
    nome: '',
    orderBy: '',
    DATAINICIAL: '',
    DATAFINAL: '',
    limit: 10,
    page: 1,
  },
  banks: [],
  sucesso: '',
  objCard: {},
  typeDoc: [],
  objInfosUser: {},
  detalheUserNegado: '',
  detalheUserBloqueado: '',
  solicitacaoContaDigital: '',
  cartaoContaDigital: {},
  idStatusContaDigital: '',
  infoCartaoFisico: {},
  loadingStatusUsuario: false,
  status_usuario: [],
  motivo: '',
  modalSolicitarCartaoNominal: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: [],
        type: action.userType,
        loading: true,
        erro: false,
        erro_message: null,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        erro: false,
        erro_message: null,
        pagination: action.pagination || {},
        nivelPrioridade: action.nivelPrioridade,
      };
    case GET_USERS_FAIL:
      return {
        ...state,
        users: action.payload,
        type: action.userType,
        loading: false,
        erro: true,
        erro_message: action.error,
      };
    case USERS_RESPONSE:
      return {
        ...state,
        ...action.payload,
        mensagem: action.payload.mensagem,
      };
    case USER_RESPONSE_DATATRUST:
      return {
        ...state,
        loading_user: false,
        user: { ...state.user, ...action.payload },
      };
    case USER_ADD_STATE:
      return { ...state, [action.key]: action.value };
    case GET_ACESSOBIO_ERROR:
      return {
        ...state,
        erro_bio: true,
        mensagem: action.payload,
      };
    case DEFAULT_TYPE:
      return {
        ...state,
        ...action.payload,
      };
    case KYC:
      return {
        ...state,
        consultas: null,
        ...action.payload,
      };

    case GET_STATUS_USUARIO:
      return {
        ...state,
        ...action.payload,
      };
    case TOGGLE_MODAL_SOLICITAR_CARTAO_NOMINAL:
      return {
        ...state,
        modalSolicitarCartaoNominal: !state.modalSolicitarCartaoNominal,
      };
    default:
      return state;
  }
};
