import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, Button, Grid, Paper, Skeleton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ReloadButtonStyle } from "../User/components/DigitalAccountBalaceContainer/style";
import { FiRefreshCw } from "react-icons/fi";
import { formatDateBR, formatMoneyBRL } from "../../utils";
import { getCardTransactions, saldoAporteCartao } from "../../store/transactions/actions";
import CardBalance from "../../components/CardBalance/CardBalance";
import Loading from "../../components/Loader/Loading";
import StatusContainer from "../../components/StatusContainer/StatusContainer";
import { Line } from "react-chartjs-2";
import { getCardSalesByInterval } from "../../store/accounting/action.js";
import ModalQrCode from "./Modal/ModalQrCode.jsx";
import CustomDataTable from "../../components/DataTable/DataTable.jsx";


export default function CardsDashboard() {
    const dispatch = useDispatch();
    const dadosDefaultChart = [0, 0, 0, 0, 0, 0, 0];

    const [loading, setLoading] = useState(true);
    const [modalQrCode, setModalQrCode] = useState({ open: false});
    const [modalDeposit, setModalDeposit] = useState({ open: false});

    const {
        listCardSales: { PAGINATION, RETORNO },
        loading_transaction,
        aporte: {
            loading_aporte,
            cartao: {
                pre_pago, pos_pago, limite_aporte
            }
        }
    } = useSelector((state) => state.transactions);

    const {
        saques: { transacaoDebito, transacaoCredito, loading_accounting },
    } = useSelector((state) => state.accounting);

    useEffect(() => {
        handleReload();
    }, []);

    useEffect(() => {
        if (PAGINATION && PAGINATION.page && RETORNO) {
            setLoading(false);
        }
    }, [PAGINATION]);

    function handleReload() {
        dispatch(saldoAporteCartao());
        dispatch(getCardSalesByInterval(15));
        getTransactions(1);
    }

    function getTransactions(PAGE) {
        dispatch(getCardTransactions({
            PAGE,
            LIMIT: 10,
            DATA_INICIAL: moment().format('YYYY-MM-DD'),
            DATA_FINAL: moment().format('YYYY-MM-DD'),
        }));
    }

    const formatDatas = (datas) => {
        var dataFormatada = [];
        dataFormatada = datas.map((num) => {
            var value = [];
            value = moment(num).isValid() ? moment(num).format('DD/MM/YYYY') : num;
            return value;
        });

        return dataFormatada;
    };

    return (
        <Box mt={1}>
            <Box display={'flex'} justifyContent={'end'} alignItems={'start'} mt={1} sx={{ gap: '1rem' }}>
                <Button variant="contained" size='small' onClick={() => setModalQrCode({open: true})}>
                    Depositar Saldo
                </Button>
                <Button variant="contained" size='small' onClick={() => setModalDeposit({open: true})}>
                    Transferir Saldo
                </Button>
            </Box>
            <Paper elevation={3} sx={{ marginTop: '1rem', padding: '1rem' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box display={'flex'} justifyContent={'end'} >
                            <ReloadButtonStyle onClick={() => handleReload()}>
                                <FiRefreshCw />
                            </ReloadButtonStyle>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardBalance
                            title={"Saldo Pré-pago"}
                            balance={pre_pago ? (+pre_pago).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : "R$ 0,00"}
                            type='saldoPrePago'
                            colors={['36', '5', '242']}
                            loading={loading_aporte}
                            description={
                                !loading_aporte ? (
                                    <Typography fontSize="small">Limite: R$ {formatMoneyBRL(limite_aporte)}</Typography>
                                ) : (
                                    <Skeleton animation="wave" sx={{ width: '10rem', fontSize: 'small' }} />
                                )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardBalance
                            title={"Saldo Pós-pago"}
                            balance={pos_pago ? (+pos_pago).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : "R$ 0,00"}
                            type='saldoPosPago'
                            colors={['234', '168', '0']}
                            loading={loading_aporte}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mb={2}>
                            <Typography fontSize={14}>Movimentações do dia</Typography>
                            <Typography fontSize={14}>Quantidade: {PAGINATION && PAGINATION.totalResult}</Typography>
                        </Box>
                        {
                            loading || loading_transaction ? (
                                <Loading color="blue" />
                            ) : (
                                <CustomDataTable
                                    loading={loading}
                                    pagination={PAGINATION}
                                    previousPage={{ onClick: () => getTransactions(PAGINATION.prevPage) }}
                                    nowPage={{ PAGE: PAGINATION.page, onClick: () => getTransactions(PAGINATION.page) }}
                                    nextPage={{ onClick: () => getTransactions(PAGINATION.nextPage) }}
                                    data={RETORNO}
                                    headers={[
                                        {
                                            name: 'DATA/HORA',
                                            selector: (sale) => formatDateBR(sale.DATA),
                                            style: { textAlign: 'center' },
                                        },
                                        {
                                            name: 'NOME',
                                            selector: (sale) => sale.NOME ? sale.NOME : '----',
                                            style: { textAlign: 'left' },
                                        },
                                        {
                                            name: 'CARTÃO',
                                            selector: (sale) => sale.PAN_MASKED,
                                            style: { textAlign: 'center' },
                                        },
                                        {
                                            name: 'PARCEIRO',
                                            selector: (sale) => sale.PARCEIRO ? (
                                                <StatusContainer code={sale.PARCEIRO} description={sale.PARCEIRO} />
                                            ) : (
                                                '----'
                                            ),
                                            style: { textAlign: 'center' },
                                        },
                                        {
                                            name: 'STATUS',
                                            selector: (sale) => sale.STATUS ? (
                                                <StatusContainer code={sale.STATUSTRANSACAO} description={sale.STATUS} />
                                            ) : null,
                                            style: { textAlign: 'center' },
                                        },
                                        {
                                            name: 'VALOR',
                                            selector: (sale) => (+sale.VALOR_TOTAL).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
                                            style: { textAlign: 'center' },
                                        },
                                    ]}
                                />
                            )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mb={2}>
                            <Typography fontSize={14}>Transações</Typography>
                            <Typography fontSize={14}>(Últimos 15 dias)</Typography>
                        </Box>
                        {
                            loading || loading_accounting ? (
                                <Loading color="blue" />
                            ) : (
                                    <Line
                                        data={{
                                            labels: transacaoDebito.length > 0 ? formatDatas(transacaoDebito.map((transacao) => transacao.DATA)) : dadosDefaultChart,
                                            datasets: [
                                                {
                                                    label: 'Crédito',
                                                    data: transacaoCredito.length > 0 ? transacaoCredito.map((transacao) => transacao.VALOR) : dadosDefaultChart,
                                                    backgroundColor: 'rgba(12, 180, 42, 0.2)',
                                                    borderColor: '#0CB42A',
                                                    fill: true
                                                },
                                                {
                                                    label: 'Débito',
                                                    data: transacaoDebito.length > 0 ? transacaoDebito.map((transacao) => transacao.VALOR) : dadosDefaultChart,
                                                    backgroundColor: 'rgba(234, 104, 82, 0.2)',
                                                    borderColor: '#EA6852',
                                                    fill: true
                                                },
                                            ],
                                        }}
                                        options={{
                                            aspectRatio: 1,
                                            plugins: {
                                                legend: {
                                                    display: true,
                                                    position: 'bottom',
    
                                                    labels: {
                                                        usePointStyle: true,
                                                    },
                                                },
                                                tooltip: {
                                                    callbacks: {
                                                        label: function (context) {
                                                            var currentDataValue = context.raw;
                                                            return (+currentDataValue).toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
                                                        },
                                                    },
                                                },
                                            },
                                        }}
                                    />
                            )}
                    </Grid>
                </Grid>
            </Paper>
            <ModalQrCode
                modal={modalDeposit.open}
                toggle={() => setModalDeposit({open: false})}
            />
            <ModalQrCode
                modal={modalQrCode.open}
                toggle={() => setModalQrCode({open: false})}
            />
        </Box>
    );
}