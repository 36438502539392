import React from 'react';
import { Button, Card, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Loading from '../../components/Loader/Loading';
import { formatDateBR } from '../../utils/dateKyc';

import { useState } from 'react';
import ModalBase64 from '../User/components/modals/ModalBase64';
import { CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';

const styles = {
  Card: { border: '1px solid #f0f0f0', borderRadius: 10 },
};

export default function ModalContratoConsentimento({ modal, toggle, contract, loading }) {
  const [modalImg, setModalImg] = useState(false);
  const [img, setImg] = useState(false);

  function openModalImg(e) {
    setModalImg(true);
    setImg(e);
  }

  function closeModalImg() {
    setModalImg(false);
    setImg(false);
  }
  return (
    <>
      <Modal style={{ maxWidth: 800 }} isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h3>Detalhes do contrato</h3>
        </ModalHeader>
        {!loading ? (
          <>
            {contract && (
              <ModalBody>
                <Row xs="2">
                  <Col>
                    <h5>Código</h5>
                    <p>{contract.uuid}</p>
                  </Col>
                  <Col>
                    <h5>Data aceite</h5>
                    <p>{formatDateBR(contract.date_accept_contract)}</p>
                  </Col>
                </Row>
                <Row xs="2">
                  <Col>
                    <h5>Latitude</h5>
                    <p>{contract.latitude}</p>
                  </Col>
                  <Col>
                    <h5>Longitude</h5>
                    <p>{contract.longitude}</p>
                  </Col>
                </Row>
                <Row xs="2">
                  <Col>
                    <h5>IP</h5>
                    <p>{contract.ip}</p>
                  </Col>
                  <Col>
                    <h5>Parceiro</h5>
                    <p> {contract.partner_name}</p>
                  </Col>
                </Row>

                <Row xs="2">
                  <Col>
                    <h5>Selfie</h5>
                    <Card sx={{ width: 250 }}>
                      <CardActionArea
                        onClick={() => openModalImg(contract.selfie.image)}
                      >
                        <CardMedia
                          component="img"
                          sx={{ padding: '1em 1em 0 1em', objectFit: 'cover' }}
                          height="100"
                          image={contract.selfie.image}
                          alt="Selfie"
                          title="Selfie"
                        />
                        <CardContent>
                          <Typography component="p" variant="p" sx={{ fontWeight: 'bold' }}>
                            Selfie
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Col>
                  <Col>
                    <h5>Contrato</h5>
                    <Card sx={{ width: 250 }}>
                      <CardActionArea
                        onClick={() => openModalImg(contract.contract.image)}
                      >
                        <CardMedia
                          component="img"
                          sx={{ padding: '1em 1em 0 1em', objectFit: 'cover' }}
                          height="100"
                          image={contract.contract.image}
                          alt="Contract"
                          title="Contract"
                        />
                        <CardContent>
                          <Typography component="p" variant="p" sx={{ fontWeight: 'bold' }}>
                            Contract
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Col>
                </Row>
                <Row xs="2">
                  {
                    contract && contract.signature && (
                      <Col>
                        <h5>Assinatura</h5>
                        <Card sx={{ width: 250 }}>
                          <CardActionArea
                            onClick={() => openModalImg(contract.signature.image)}
                          >
                            <CardMedia
                              component="img"
                              sx={{ padding: '1em 1em 0 1em', objectFit: 'cover' }}
                              height="100"
                              image={contract.signature.image}
                              alt="Signature"
                              title="Signature"
                            />
                            <CardContent>
                              <Typography component="p" variant="p" sx={{ fontWeight: 'bold' }}>
                                Signature
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Col>
                    )
                  }
                </Row>
              </ModalBody>
            )}
          </>
        ) : (
          <Loading color="blue" />
        )}

        <ModalBase64 toggle={() => closeModalImg()} open={modalImg} img={img}></ModalBase64>
      </Modal>
    </>
  );
}
