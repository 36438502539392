const formatCEP = (string) => {
  try {
    if (typeof string != 'string') {
      string = string + '';
    }
    const rawNumber = string.replace('/', '').replace('-', '').replace(/\./g, '');
    const firstDigits = rawNumber.substr(0, 2);
    const secondDigits = rawNumber.substr(2, 3);
    const lastDigits = rawNumber.substr(5, 3);
    const maskedString = `${firstDigits}` + (secondDigits && `${secondDigits}`) + (lastDigits && `-${lastDigits}`);
    return maskedString;
  } catch (e) {
    return '';
  }
};

export default formatCEP;
