import React, { useEffect, useState } from 'react';

import { Alert, Badge, Button, Input, Label, Modal, ModalBody, ModalHeader, Tooltip } from 'reactstrap';
import Snackbar from '@mui/material/Snackbar';
import { enqueueSnackbar } from 'notistack';
import { MenuItem, Select } from '@mui/material';

import JoditEditor from 'jodit-react';
import Loading from '../../components/Loader/Loading';
import api from '../../service/api';
import ModalMensagemSemReloadNew from '../Transaction/ModalMensagemSemReloadNew';
import { MainContainer, ParamsContainer } from './styles';

const copyStringToClipboard = function (str) {
  var el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style = { position: 'absolute', left: '-9999px' };
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const facilityMergeFields = [
  'FacilityNumber',
  'FacilityName',
  'Address',
  'MapCategory',
  'Latitude',
  'Longitude',
  'ReceivingPlant',
  'TrunkLine',
  'SiteElevation',
];
const inspectionMergeFields = ['InspectionCompleteDate', 'InspectionEventType'];
const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
  let optionGroupElement = document.createElement('optgroup');
  optionGroupElement.setAttribute('label', optionGrouplabel);
  for (let index = 0; index < mergeFields.length; index++) {
    let optionElement = document.createElement('option');
    optionElement.setAttribute('class', 'merge-field-select-option');
    optionElement.setAttribute('value', mergeFields[index]);
    optionElement.text = mergeFields[index];
    optionGroupElement.appendChild(optionElement);
  }
  return optionGroupElement;
};
const buttons = [
  'undo',
  'redo',
  '|',
  'bold',
  'strikethrough',
  'underline',
  'italic',
  '|',
  'superscript',
  'subscript',
  '|',
  'align',
  '|',
  'ul',
  'ol',
  'outdent',
  'indent',
  '|',
  'font',
  'fontsize',
  'brush',
  'paragraph',
  '|',
  'image',
  'link',
  'table',
  '|',
  'hr',
  'eraser',
  'copyformat',
  '|',
  'selectall',
  'print',
  '|',
  'source',
  '|',
  {
    name: 'insertMergeField',
    tooltip: 'Insert Merge Field',
    iconURL: 'images/merge.png',
    popup: (editor, current, self, close) => {
      function onSelected(e) {
        let mergeField = e.target.value;
        if (mergeField) {
          editor.selection.insertNode(editor.create.inside.fromHTML('{{' + mergeField + '}}'));
        }
      }
      let divElement = editor.create.div('merge-field-popup');

      let labelElement = document.createElement('label');
      labelElement.setAttribute('class', 'merge-field-label');
      labelElement.text = 'Merge field: ';
      divElement.appendChild(labelElement);

      let selectElement = document.createElement('select');
      selectElement.setAttribute('class', 'merge-field-select');
      selectElement.appendChild(createOptionGroupElement(facilityMergeFields, 'Facility'));
      selectElement.appendChild(createOptionGroupElement(inspectionMergeFields, 'Inspection'));
      selectElement.onchange = onSelected;
      divElement.appendChild(selectElement);

      return divElement;
    },
  },
  {
    name: 'copyContent',
    tooltip: 'Copy HTML to Clipboard',
    iconURL: 'images/copy.png',
    exec: function (editor) {
      let html = editor.value;
      copyStringToClipboard(html);
    },
  },
];

const editorConfig = {
  readonly: false,
  toolbar: true,
  spellcheck: true,
  language: 'en',
  toolbarButtonSize: 'medium',
  toolbarAdaptive: false,
  showCharsCounter: true,
  showWordsCounter: true,
  showXPathInStatusbar: false,
  askBeforePasteHTML: true,
  askBeforePasteFromWord: true,
  //defaultActionOnPaste: "insert_clear_html",
  buttons: buttons,
  uploader: {
    insertImageAsBase64URI: true,
  },
  height: 700,
};

export default function ModalTermo({ toggle, open, callback, idContract, getTerms }) {
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [name, setName] = useState('');
  const [modalFeedback, setModalFeedback] = useState(false);
  const [alert, setAlert] = useState(false);
  const [systems, setSystems] = useState(false);
  const [choosenSystem, setChoosenSystem] = useState(false);
  const [params, setParams] = useState(false);
  const [toast, setToast] = useState(false);
  const [hasParams, setHasParams] = useState(false);
  const [loadingParams, setLoadingParams] = useState(false);

  const handleClick = () => {
    setToast(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToast(false);
  };

  function getHtmlContract() {
    setLoading(true);
    api
      .get(`contrato/termo?id_contract=${idContract}`)
      .then((res) => {
        if (res.data.data) {
          setName(res.data.data.NOME_CONTRATO);
          setData(res.data.data.CORPO_CONTRATO);
          setChoosenSystem(res.data.data.FK_SISTEMA);
          if (res.data.data.TAG && res.data.data.TAG.length > 0) {
            setHasParams(res.data.data.TAG);
          }
        }
      })
      .finally(() => setLoading(false));
  }

  function getParams(tag) {
    setLoadingParams(true);
    api
      .get(`contrato/params?TAG_REFERENCIA=${tag}`)
      .then((response) => {
        setParams(response.data.data);
      })
      .finally(() => setLoadingParams(false));
  }

  useEffect(() => {
    if (hasParams) {
      getParams(hasParams);
    }
  }, [hasParams]);

  function getSystems() {
    setLoading(true);
    api
      .get(`contrato/sistemas`)
      .then((res) => {
        if (res.data.data) {
          setSystems(res.data.data);
        }
      })
      .finally(() => setLoading(false));
  }

  function editTerm(dados) {
    setLoading(true);
    api
      .put(`contrato/termo`, { ...dados })
      .then((res) => {
        if (res.data.retorno == 'sucesso') {
          setModalFeedback(true);
        } else {
          setAlert(true);
        }
      })
      .catch(() => {
        setAlert(true);
      })
      .finally(() => setLoading(false));
  }

  function createTerm(dados) {
    setLoading(true);
    api
      .post(`contrato/termo`, { ...dados })
      .then((res) => {
        if (res.data.retorno == 'sucesso') {
          setModalFeedback(true);
        } else {
          setAlert(true);
        }
      })
      .catch(() => {
        setAlert(true);
      })
      .finally(() => setLoading(false));
  }

  function save() {
    const encodedString = Buffer.from(data).toString('base64');

    let dados = {
      NOME_CONTRATO: name,
      CORPO_CONTRATO: encodedString,
      FK_SISTEMA: choosenSystem,
    };
    if (idContract) {
      editTerm({ ...dados, ID_TERMO: idContract });
    } else {
      createTerm(dados);
    }
  }

  function restore(tag) {
    setLoading(true);
    api
      .get(`contrato/padrao?TAG_REFERENCIA=${tag}`)
      .then((res) => {
        if (res.status == 200) {
          setData(res.data.data.CORPO_CONTRATO);
        }
      })
      .catch()
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (idContract) {
      getHtmlContract();
    }
    if (open) {
      getSystems();
    }
  }, [open]);

  function close() {
    setModalFeedback(false);
    setName('');
    setData('');

    setHasParams(false);
    toggle();
  }

  function reload() {
    setModalFeedback(false);
    setName('');
    setData('');
    getTerms();
    setHasParams(false);
    toggle();
  }

  function checkDisabled() {
    if (!choosenSystem) return true;
    if (choosenSystem == 0) return true;
    return false;
  }

  return (
    <>
      <Modal isOpen={open} toggle={() => close()} size="xl" style={{ maxWidth: '1100px' }}>
        <ModalHeader toggle={() => close()}>{idContract ? 'Editar contrato' : 'Novo contrato'}</ModalHeader>
        {!loading ? (
          <ModalBody>
            <Alert color={'danger'} isOpen={alert} toggle={() => setAlert(false)}>
              {'Erro ao salvar'}
            </Alert>

            <MainContainer>
              <div
                style={{
                  marginBottom: '1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '0.5rem',
                }}
              >
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Label>Nome do contrato</Label>
                    <Input
                      autoFocus
                      type="text"
                      style={{ width: '400px', marginRight: '1rem', height: '40px' }}
                      value={name}
                      placeholder={'Nome do contrato'}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Label>Sistema</Label>
                    <Select
                      id="selectSystem"
                      size="small"
                      name="Sistema"
                      value={choosenSystem}
                      onChange={(event) => setChoosenSystem(event.target.value)}
                    >
                      <MenuItem value={0}>Escolha</MenuItem>
                      {systems &&
                        systems.map((system) => (
                          <MenuItem key={system.ID} value={system.ID}>
                            {system.NOME_SISTEMA}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                </div>
                <div>
                  <Button
                    style={{ width: '150px', height: '40px', marginTop: '30px' }}
                    size="sm"
                    color="secondary"
                    onClick={() => restore(hasParams)}
                  >
                    Restaurar padrão
                  </Button>

                  <Button
                    style={{ width: '80px', height: '40px', marginTop: '30px' }}
                    disabled={checkDisabled() ? true : false}
                    size="sm"
                    color="primary"
                    onClick={() => save()}
                  >
                    Salvar
                  </Button>
                </div>
              </div>{' '}
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {hasParams && (
                  <ParamsContainer>
                    {!loadingParams ? (
                      <>
                        <h2>Parâmetros</h2>

                        {params && params.length > 0 ? (
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                            {params.map((param, i) => (
                              <>
                                <Badge
                                  onClick={() => {
                                    navigator.clipboard.writeText(param.PARAMETRO);

                                    enqueueSnackbar('Copiado para área de transfefência');
                                  }}
                                  color="primary"
                                  style={{ marginBottom: '.5rem', cursor: 'pointer', whiteSpace: 'wrap' }}
                                  key={i}
                                  id={'Tooltip-' + i}
                                  title={param.DESCRICAO}
                                >
                                  {param.DESCRICAO}
                                </Badge>
                              </>
                            ))}
                          </div>
                        ) : (
                          <h4>Sem parâmetros</h4>
                        )}
                      </>
                    ) : (
                      <Loading color="blue"></Loading>
                    )}
                  </ParamsContainer>
                )}
                <div
                  className="App"
                  style={{ maxWidth: editorConfig.width, margin: '0 auto', maxWidth: hasParams ? '800px' : '1100px' }}
                >
                  <JoditEditor value={data} config={editorConfig} onBlur={(value) => setData(value)} />
                </div>
              </div>
            </MainContainer>
          </ModalBody>
        ) : (
          <Loading color="blue" />
        )}

        <ModalMensagemSemReloadNew
          open={modalFeedback}
          close={() => {
            close();
          }}
          callback={() => {
            reload();
          }}
          sucesso={true}
          mensagem={'Sucesso!'}
          title={'Sucesso'}
        />
      </Modal>
    </>
  );
}
