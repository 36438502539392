import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import formatCPF from '../../../../utils/formatCPF';
import { chooseColor, chooseColorString, getPercentage } from '../../../../utils/functions';
import { getAddress, thereIsSanctions } from '../../../../utils/kycGetHighest';
import * as Styled from './AccordionVerifications.styles';
import './style.css';
import formatCEP from '../../../../utils/formatCEP';
import { unformatDateKyc } from '../../../../utils/dateKyc';

export default function KycPf({ consultas, setKycModal, setModalProcesses, loading, uniqueColumn }) {
  const [client, setClient] = useState(consultas);

  useEffect(() => {
    setClient(consultas);
  }, [consultas]);

  function verifyFields() {
    if (!client) return false;
    if (!client.basic_data) return false;
    if (!client.basic_data[0]) return false;

    return true;
  }

  function render() {
    const cpf = client.basic_data[0].cpf ? client.basic_data[0].cpf : null;
    const estado_civil = client.basic_data[0].estado_civil ? client.basic_data[0].estado_civil : null;
    const genero = client.basic_data[0].genero ? client.basic_data[0].genero : null;
    const nacionalidade = client.basic_data[0].nacionalidade ? client.basic_data[0].nacionalidade : null;
    const nascimento = client.basic_data[0].nascimento ? client.basic_data[0].nascimento : null;
    const nome = client.basic_data[0].nome ? client.basic_data[0].nome : null;
    const nome_mae = client.basic_data[0].nome_mae ? client.basic_data[0].nome_mae : null;

    const { mensagem } = client.emails[0];
    const { financial_data } = client;
    const { PEP } = client.kyc[0];

    /*  const { kyc_dtec_flex_news } = client; */
    const { processes } = client;

    const address = getAddress(client.addresses[0]);

    return (
      <>
        <h2>
          <span className="blue-badge">
            Média :
            <span className={chooseColor(parseFloat(client.media_global))}>{getPercentage(client.media_global)}</span>
          </span>
        </h2>
        <Styled.container uniqueColumn={uniqueColumn} className="borderBottom">
          <Styled.block uniqueColumn={uniqueColumn}>
            <Styled.column>
              {nome && (
                <Styled.row>
                  <span className="bold">Nome:</span> {nome.dado_encontrado}
                </Styled.row>
              )}
              {cpf && (
                <Styled.row>
                  <span className="bold">CPF: </span>
                  {cpf[0].dado_encontrado && formatCPF(cpf[0].dado_encontrado)}
                </Styled.row>
              )}
              {nascimento && (
                <Styled.row>
                  <span className="bold">Nascimento: </span>
                  {unformatDateKyc(nascimento.dado_encontrado)}
                </Styled.row>
              )}
              {nacionalidade && (
                <Styled.row>
                  <span className="bold">Nacionalidade: </span>
                  {nacionalidade.dado_encontrado}
                </Styled.row>
              )}
              {estado_civil && (
                <Styled.row>
                  <span className="bold">Estado Civil: </span>
                  {estado_civil.dado_encontrado}
                </Styled.row>
              )}
              {genero && (
                <Styled.row>
                  <span className="bold">Gênero: </span>
                  {genero.dado_encontrado}
                </Styled.row>
              )}
              {nome_mae && (
                <Styled.row>
                  <span className="bold">Nome da mãe: </span>
                  {nome_mae.dado_encontrado}
                </Styled.row>
              )}

              <Styled.row>
                <span className="bold">Telefone:</span>
                <span className="green"> Telefone válido</span>
              </Styled.row>
            </Styled.column>
            <Styled.column>
              {nome && (
                <Styled.row>
                  Match:
                  <span className={chooseColor(parseFloat(nome.match_rate))}>{getPercentage(nome.match_rate)}</span>
                </Styled.row>
              )}
              {cpf && (
                <Styled.row>
                  Match:
                  <span className={chooseColor(parseFloat(cpf[0].match_rate))}>{getPercentage(cpf[0].match_rate)}</span>
                </Styled.row>
              )}
              {nascimento && (
                <Styled.row>
                  Match:
                  <span className={chooseColor(parseFloat(nascimento.match_rate))}>
                    {getPercentage(nascimento.match_rate)}
                  </span>
                </Styled.row>
              )}
              {nacionalidade && (
                <Styled.row>
                  Match:
                  <span className={chooseColor(parseFloat(nacionalidade.match_rate))}>
                    {getPercentage(nacionalidade.match_rate)}
                  </span>
                </Styled.row>
              )}
              {estado_civil && (
                <Styled.row>
                  Match:
                  <span className={chooseColor(parseFloat(estado_civil.match_rate))}>
                    {getPercentage(estado_civil.match_rate)}
                  </span>
                </Styled.row>
              )}
              {genero && (
                <Styled.row>
                  Match:
                  <span className={chooseColor(parseFloat(genero.match_rate))}>{getPercentage(genero.match_rate)}</span>
                </Styled.row>
              )}
              {nome_mae && (
                <Styled.row>
                  Match:
                  <span className={chooseColor(parseFloat(nome_mae.match_rate))}>
                    {getPercentage(nome_mae.match_rate)}
                  </span>
                </Styled.row>
              )}
              {<Styled.invisibleRow>-</Styled.invisibleRow>}
            </Styled.column>
          </Styled.block>

          {address ? (
            <Styled.block uniqueColumn={uniqueColumn}>
              <Styled.column>
                {address.CEP && (
                  <Styled.row>
                    <span className="bold">CEP:</span> {formatCEP(address.CEP.dado_encontrado)}
                  </Styled.row>
                )}
                {address.numero && (
                  <Styled.row>
                    <span className="bold">Número:</span>
                    {address.numero.dado_encontrado}
                  </Styled.row>
                )}
                {address.bairro && (
                  <Styled.row>
                    <span className="bold">Bairro: </span>
                    {address.bairro.dado_encontrado}
                  </Styled.row>
                )}
                {address.cidade && (
                  <Styled.row>
                    <span className="bold">Cidade: </span>
                    {address.cidade.dado_encontrado}
                  </Styled.row>
                )}
                {address.endereco && (
                  <Styled.row>
                    <span className="bold">Endereço: </span>
                    {address.endereco.dado_encontrado}
                  </Styled.row>
                )}
              </Styled.column>
              <Styled.column>
                {address.CEP && (
                  <Styled.row>
                    Match{' '}
                    {address.CEP.match_rate === '100,00' ? (
                      <span className="green">100%</span>
                    ) : (
                      <span className="red">0%</span>
                    )}
                  </Styled.row>
                )}
                {address.numero && (
                  <Styled.row>
                    Match:
                    {address.numero.match_rate === '100,00' ? (
                      <span className="green">100%</span>
                    ) : (
                      <span className="red">0%</span>
                    )}
                  </Styled.row>
                )}
                {address.bairro && (
                  <Styled.invisibleRow>
                    {/*  Match:
                    {getPercentage(address.bairro.match_rate)} */}
                    -
                  </Styled.invisibleRow>
                )}
                {address.cidade && (
                  <Styled.invisibleRow>
                    {/* Match:
                    {getPercentage(address.cidade.match_rate)} */}
                    -
                  </Styled.invisibleRow>
                )}
                {address.endereco && (
                  <Styled.invisibleRow>
                    {/*     Match:
                    {getPercentage(address.endereco.match_rate)} */}
                    -
                  </Styled.invisibleRow>
                )}
              </Styled.column>
            </Styled.block>
          ) : (
            <Styled.block>
              <Styled.column>
                <Styled.row>
                  <span className="red"> Endereço não encontrado </span>
                </Styled.row>
              </Styled.column>
            </Styled.block>
          )}
        </Styled.container>
        <h2>Informações complementares</h2>
        <Styled.container uniqueColumn={uniqueColumn}>
          <Styled.block uniqueColumn={uniqueColumn}>
            <Styled.column>
              {mensagem && (
                <Styled.row>
                  <span className="bold">Email: </span>
                  <span className={chooseColorString(mensagem, 'Email nao encontrado')}>{mensagem}</span>
                </Styled.row>
              )}
              {financial_data && (
                <Styled.row>
                  <span className="bold">Financial Data:</span> {financial_data}
                </Styled.row>
              )}
              {
                <Styled.row>
                  <span className="bold">PEP:</span>
                  <span className={PEP ? 'green' : 'red'}>
                    {PEP ? 'Informações encontradas' : 'Não foram encontradas informações'}
                  </span>
                </Styled.row>
              }
            </Styled.column>
          </Styled.block>

          <Styled.block uniqueColumn={uniqueColumn}>
            <Styled.column>
              <Styled.row>
                <span className="bold">KYC:</span>
                {thereIsSanctions(client.kyc[0].SANCOES) ? (
                  <span className="green">Sanções encontradas </span>
                ) : (
                  <span className="red">Não foram encontradas sanções</span>
                )}
              </Styled.row>
              {/*  {kyc_dtec_flex_news ? (
                <Styled.row>
                  <span className="bold">KYC Notícias:</span>
                  <span className="red">{kyc_dtec_flex_news}</span>
                </Styled.row>
              ) : (
                <Styled.row> - </Styled.row>
              )} */}
              <Styled.row>
                <span className="bold">Processos:</span>
                {processes[0].subject ? (
                  <span className="green">Processos encontrados </span>
                ) : (
                  <span className="red"> Não foram encontrados processos</span>
                )}
              </Styled.row>
            </Styled.column>
            <Styled.column>
              {thereIsSanctions(client.kyc[0].SANCOES) ? (
                <Styled.row>
                  <Button
                    size="sm"
                    color="primary"
                    style={{
                      marginTop: '-.5rem',
                    }}
                    onClick={() => setKycModal(true)}
                  >
                    Ver Sanções
                  </Button>
                </Styled.row>
              ) : (
                <Styled.row> - </Styled.row>
              )}
              {/* {kyc_dtec_flex_news ? (
                <Styled.row>
                  <Button
                    size="sm"
                    color="primary"
                                 onClick={() => setModalProcesses(true)}
                    style={{
                      marginTop: '-.5rem',
                    }}
                  >
                    Ver Notícias
                  </Button>
                </Styled.row>
              ) : (
                <Styled.row> - </Styled.row>
              )} */}

              {processes[0].subject ? (
                <Styled.row>
                  <Button
                    size="sm"
                    color="primary"
                    onClick={() => setModalProcesses(true)}
                    style={{
                      marginTop: '-.5rem',
                    }}
                  >
                    Ver Processos
                  </Button>
                </Styled.row>
              ) : (
                <Styled.row> - </Styled.row>
              )}
            </Styled.column>
          </Styled.block>
        </Styled.container>
      </>
    );
  }

  return <>{verifyFields() && render()}</>;
}
