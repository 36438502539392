import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useEffect } from 'react';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loading from '../../../../../../components/Loader/Loading';
import api from '../../../../../../service/api';
import formatMoney from '../../../../../../utils/formatMoneyBRL';
import ModalMensagemSemReloadNew from '../../../../../Transaction/ModalMensagemSemReloadNew';
import { AccountInfo, BalanceInfo, CardContainer, MainContainer } from './ModalCardUser.styles';

export default function ModalBindAccount({
  toggle,
  open,
  informacoesPessoais,
  cartao,
  loadingModal,
  setLoadingModal,
  reloadCard,
  cardAccount,
}) {
  const [selected, setSelected] = useState(false);
  const [observation, setObservation] = useState('');
  const [message, setMessage] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const { SALDO } = useSelector((state) => state.coban);

  useEffect(() => {
    setObservation('');
    if (cartao.TIPO_VINCULO == 1) {
      setSelected(1);
    } else if (cartao.TIPO_VINCULO == 2) {
      setSelected(2);
    } else {
      setSelected(false);
    }
  }, [open]);

  function closeModalMessage() {
    toggle();
    setModalMessage(false);
    setMessage(false);
    reloadCard();
  }

  function bindCardAccount() {
    setLoadingModal(true);
    const data = {
      OBSERVACAO: observation,
      ID_SWAP_CARTAO: +cartao.CODCARTAO,
      ID_CONTA_SWAP: selected === 1 ? null : +cardAccount[0].id,
      ID_CONTA_BACEN: selected === 1 ? +informacoesPessoais.conta_bacen[0].ID : null,
      ID_SWAP_CARTAO_TIPO_CONTA: selected === 1 ? 1 : 2,
    };

    api
      .post(`/admin/conta-cartao/vincula-cartao`, data)
      .then(({ data }) => {
        if (data) {
          setModalMessage(true);
          setMessage('Sucesso');
          setLoadingModal(false);
        }
      })
      .catch(() => setLoadingModal(false));
  }

  return (
    <>
      <Modal isOpen={open} toggle={toggle} size="lg" style={{ maxWidth: '600px' }}>
        <ModalHeader toggle={toggle}>
          <h2>Vincular conta</h2>
        </ModalHeader>
        {!loadingModal ? (
          <ModalBody>
            <MainContainer>
              {informacoesPessoais &&
                informacoesPessoais.conta_bacen &&
                informacoesPessoais.conta_bacen.map((account) => (
                  <CardContainer selected={selected === 1} onClick={() => setSelected(1)}>
                    <h2>Conta bancária</h2>
                    <AccountInfo>
                      <span>{account.CONTA}</span>
                      <span>{account.DIGITO}</span>
                    </AccountInfo>
                    <BalanceInfo>
                      <span>Saldo</span>
                      <span>{formatMoney(SALDO.SALDO_ATUAL)}</span>
                    </BalanceInfo>
                  </CardContainer>
                ))}

              {cardAccount &&
                cardAccount.map((account) => (
                  <CardContainer selected={selected === 2} onClick={() => setSelected(2)}>
                    <h2>Conta Cartão</h2>
                    <AccountInfo>
                      <span>{account.conta}</span>
                      <span>{account.digito ? account.digito : ''}</span>
                    </AccountInfo>
                    <BalanceInfo>
                      <span>Saldo</span>
                      <span>{formatMoney(account.saldo)}</span>
                    </BalanceInfo>
                  </CardContainer>
                ))}
            </MainContainer>
            <div style={{ marginTop: '2rem' }}>
              <Label for="exampleText" sm={2}>
                Observação
              </Label>
              <FormGroup row>
                <Col sm={12}>
                  <Input
                    id="exampleText"
                    name="text"
                    type="textarea"
                    value={observation}
                    onChange={(e) => setObservation(e.target.value)}
                  />
                </Col>
              </FormGroup>
            </div>
          </ModalBody>
        ) : (
          <Loading color="blue" />
        )}
        <ModalFooter>
          <Button disabled={!selected} color="primary" onClick={() => bindCardAccount()}>
            Vincular
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <ModalMensagemSemReloadNew
        open={modalMessage}
        close={() => closeModalMessage()}
        callback={() => closeModalMessage()}
        sucesso={message && true}
        mensagem={message}
        title={message && 'Sucesso'}
      />
    </>
  );
}
