import { combineReducers } from 'redux';
import accounting from './accounting/reducers';
import auth from './auth/reducers';
import user from './user/reducers';
import coban from './coban/reducers';
import transactions from './transactions/reducers';
import dashboard from './dashboard/reducers';
import riskInfos from './riskInfos/reducers';
import cancellation from './cancellation/reducers';
import config from './config/reducers';
import extract from './extract/reducers';
import notifications from './notifications/reducers';
import swap from './swap/reducers';

export default combineReducers({
  accounting,
  auth,
  user,
  coban,
  transactions,
  config,
  dashboard,
  riskInfos,
  cancellation,
  extract,
  notifications,
  swap,
});
