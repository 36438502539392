import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormControl, MenuItem, Select } from '@mui/material';
import Loading from '../../../../components/Loader/Loading';
import { updateStatusUsuarioByUUID } from '../../../../store/user/actions';
import ModalMensagem from '../../../Transaction/ModalMensagem';

/* eslint-disable */

const ModalStatusUsuario = ({ isOpen, toggle, informacoesPessoais, setReloadUser, reloadUser }) => {
  const { status_usuario, user } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const [statusUsuario, setStatusUsuario] = useState({
    statusUsuario: informacoesPessoais.status_usuario,
    mensagem: '',
  });

  const [modalMensagem, setModalMensagem] = useState({
    modal: false,
    mensagem: '',
    sucesso: true,
  });

  const [loaded, setLoaded] = useState(false);

  const handleChange = ({ name, value }) => {
    setStatusUsuario((prev) => ({ ...prev, [name]: value }));
  };

  const handleClick = async () => {
    setLoaded(true);
    await dispatch(
      updateStatusUsuarioByUUID(
        user.UUID,
        {
          status: statusUsuario.statusUsuario,
          motivo: statusUsuario.mensagem,
        },
        (bool, mensagem) => {
          if (bool === true) {
            setModalMensagem({ modal: true, mensagem, sucesso: true });
            toggle();
            setStatusUsuario((prev) => ({ ...prev, mensagem: '' }));
            setReloadUser(!reloadUser);
          } else {
            setModalMensagem({ modal: true, mensagem, sucesso: false });
          }
          setLoaded(false);
        },
      ),
    );
    setLoaded(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          toggle();
          setStatusUsuario((prev) => ({ ...prev, mensagem: '' }));
        }}
      >
        {loaded ? (
          <>
            <Loading color="#11cdef" />
          </>
        ) : (
          <>
            <ModalHeader
              toggle={() => {
                toggle();
                setStatusUsuario((prev) => ({ ...prev, mensagem: '' }));
              }}
            >
              Alterar Status Usuário
            </ModalHeader>
            <ModalBody
              style={{
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                minWidth: '100%',
              }}
            >
              <FormControl sx={{ mb: 2, minWidth: 120 }} size="small">
                <label>Status:</label>
                <Select
                  value={statusUsuario.statusUsuario}
                  sx
                  name="statusUsuario"
                  onChange={(e) => handleChange(e.target)}
                >
                  {status_usuario &&
                    status_usuario.length > 0 &&
                    status_usuario.map((status) => <MenuItem value={status.ID}>{status.DESCRICAO}</MenuItem>)}
                </Select>
              </FormControl>
              <label>Motivo:</label>
              <textarea
                onChange={(e) => handleChange(e.target)}
                placeholder="Escreva o motivo..."
                name="mensagem"
                maxLength="120"
                value={statusUsuario.mensagem}
                style={{ resize: 'none' }}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleClick}>
                Alterar
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
      <ModalMensagem
        modal={modalMensagem.modal}
        toggle={() => setModalMensagem({ modal: false, mensagem: '', sucesso: true })}
        mensagem={modalMensagem.mensagem}
        sucesso={modalMensagem.sucesso}
      />
    </>
  );
};

export default ModalStatusUsuario;
