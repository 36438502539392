import './assets/vendor/nucleo/css/nucleo.css';
import './assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import './assets/css/argon-dashboard-react.css';
import './assets/css/styles.css';

import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
// Redux
import { applyMiddleware, compose, createStore } from 'redux';

import AdminLayout from './layouts/Admin.jsx';
import Login from './views/Login/Login.jsx';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import ReduxThunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import reducers from './store';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { Close } from '@material-ui/icons';
import { IconButton } from '@mui/material';

export const history = createBrowserHistory();

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(ReduxThunk));

const store = createStore(reducers, enhancer);

ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider
      maxSnack={5}
      action={(snackbarId) => (
        <IconButton onClick={() => closeSnackbar(snackbarId)}>
          <Close>Fechar</Close>
        </IconButton>
      )}
    >
      <Router history={history}>
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/login" render={(props) => <Login {...props} />} />
          <Redirect from="/" to="/login" />
        </Switch>
      </Router>
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root'),
);
