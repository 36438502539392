import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './TextInputMoney.styles';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

export default function TextInputMoney({
  label,
  name,
  width,
  height,
  variant = 'standard',
  multiline,
  control,
  margin,
  type,
  inputProps,
  maxRows,
  minRows,
  required = false,
  value,
  error,
}) {
  function NumberFormatComponent(props) {
    const max_money = 999999999;
    const withValueLimit = ({ floatValue }) => floatValue <= max_money;

    function currencyFormatter(value) {
      if (!Number(value)) return 'R$ 0,00';
      const amount = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value / 100);

      return `${amount}`;
    }

    const { inputRef, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        allowEmptyFormatting={true}
        thousandSeparator={'.'}
        decimalSeparator={','}
        fixedDecimalScale={true}
        isAllowed={withValueLimit}
        format={currencyFormatter}
      />
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      render={({ field: { onChange, value } }) => (
        <Styled.Text
          type={type ? type : 'text'}
          label={label}
          width={width}
          height={height}
          variant={variant}
          multiline={multiline}
          maxRows={maxRows}
          minRows={minRows}
          defaultValue={value}
          onChange={onChange}
          required={required}
          margin={margin}
          error={error}
          inputProps={inputProps}
          InputProps={{ inputComponent: NumberFormatComponent }}
        />
      )}
    />
  );
}

TextInputMoney.propTypes = {
  setFilter: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  filter: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  variant: PropTypes.string,
  multiline: PropTypes.bool,
  control: PropTypes.any,
  margin: PropTypes.string,
  type: PropTypes.string,
  inputProps: PropTypes.object,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};
