import styled from 'styled-components';
import TextInputMask from 'react-masked-text';
import NumberFormat from 'react-number-format';

export const Container = styled.div`
  display: block;
  justify-content: space-around;
`;

export const SearchInput = styled.input`
  border: solid 1px #c5c5c5;
  border-radius: 5px;
  width: 25vw;
  height: 49%;
  font-size: 15px;
  padding-left: 15px;

  @media (max-width: 1700px) {
    width: 24vw;
  }

  @media (max-width: 1530px) {
    width: 23vw;
  }

  @media (max-width: 1200px) {
    width: 18vw;
  }
`;

export const DefaultInput = styled.input`
  border: solid 1px #c5c5c5;
  border-radius: 5px;
  width: 12vw;
  height: 49%;
  font-size: 15px;
  font-color: #373737;
  padding-left: 15px;

  @media (max-width: 1700px) {
    width: 11vw;
  }

  @media (max-width: 1530px) {
    width: 10vw;
  }

  @media (max-width: 1200px) {
    width: 9vw;
  }

  @media (max-width: 1080px) {
    width: 8vw;
  }
`;

export const DefaultLabel = styled.label`
  max-width: 100%;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MaskInput = styled(TextInputMask)`
  border: solid 1px #c5c5c5;
  border-radius: 5px;
  width: 12vw;
  height: 49%;
  font-size: 15px;
  font-color: #373737;
  padding-left: 15px;

  @media (max-width: 1700px) {
    width: 11vw;
  }

  @media (max-width: 1530px) {
    width: 10vw;
  }

  @media (max-width: 1200px) {
    width: 9vw;
  }

  @media (max-width: 1080px) {
    width: 8vw;
  }
`;
export const MaskInputMoney = styled(NumberFormat)`
  border: solid 1px #c5c5c5;
  border-radius: 5px;
  width: 12vw;
  height: 49%;
  font-size: 15px;
  font-color: #373737;
  padding-left: 15px;

  @media (max-width: 1700px) {
    width: 11vw;
  }

  @media (max-width: 1530px) {
    width: 10vw;
  }

  @media (max-width: 1200px) {
    width: 9vw;
  }

  @media (max-width: 1080px) {
    width: 8vw;
  }
`;
