import styled from 'styled-components';

export const MainText = styled.div`
  display: flex;
  justify-content: center;
  background: #f2f2f2;
  color: #525f7f;
  font-weight: 600;
  padding: 1rem;
  margin-bottom: 1.5rem;
`;

export const mainRow = styled.div`
  display: flex;
`;

export const block = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 2rem;
  width: ${(props) => (props.uniqueColumn ? '' : '50%')};
`;

export const row = styled.div`
  display: block;
  font-size: 0.8rem;
  margin-bottom: 0.8rem;
  color: rgb(136, 152, 170);
`;

export const container = styled.div`
  display: ${(props) => (props.uniqueColumn ? '' : 'flex')};
  justify-content: ${(props) => (props.uniqueColumn ? '' : 'space-between')};
  padding: 1rem 0;
  width: ${(props) => (props.uniqueColumn ? '' : '100%')};
`;

export const column = styled.div`
  margin-right: 2rem;
  min-width: 90px;
`;

export const column_match = styled.div`
  position: absolute;
  right: 2rem;
`;

export const profilePicture = styled.div`
  justify-self: flex-end;
  margin-top: -4rem;
`;
export const invisibleRow = styled.div`
  display: block;
  font-size: 0.8rem;
  margin-bottom: 0.8rem;
  color: transparent;
`;
