import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { baseIMG } from '../../../../service/api';

const ModalBase64 = ({ open, img, toggle }) => {
  function downloadBase64File(contentBase64, fileName) {
    /*     const linkSource = `data:application/pdf;base64,${contentBase64}`; */
    const linkSource = contentBase64;
    let mimeType2 = contentBase64.match(/[^:/]\w+(?=;|,)/)[0];
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = fileName + '.' + mimeType2;
    downloadLink.click();
  }

  return (
    <Modal
      style={{ maxWidth: '1000px', width: '100%' }}
      className="modal-dialog-centered"
      isOpen={open}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}> </ModalHeader>

      <ModalBody>
        <a
          href={img}
          rel="noopener noreferrer"
          onClick={() => downloadBase64File(img, 'download')}
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            paddingBottom: '1rem',
          }}
        >
          <Button>
            <i style={{ paddingRight: '1rem' }} className="fas fa-download" />
            Download
          </Button>
        </a>
        <object data={img} width="100%"></object>
      </ModalBody>
    </Modal>
  );
};

export default ModalBase64;
