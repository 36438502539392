import styled from 'styled-components';
import { borderColor, lighten } from 'polished';

export const ContainerLegalRepresentative = styled.div`
  margin: 10px 0;
  font-style: normal;

  & > h2 {
    margin: 0.764vw 0;
    font-weight: 600;
    font-size: 1.389vw;
  }

  & > form {
    & > div {
      min-width: 300px;
      border-radius: 10px;
      padding-bottom: 10px;
      display: grid;
      grid-template-columns: ${(props) => (props.full ? '' : 'repeat(2, 1fr)')};

      grid-column-gap: 10px;
      grid-row-gap: 10px;

      & h4 {
        margin: 20px 0 0 20px;
        font-size: 20px;
        font-weight: bold;
      }

      & .card {
        /* background-color: ${lighten('0.1', '#DCDCDC')}; */
        padding: 0.5rem;
        border-radius: 0.8rem;
      }
    }
    & > footer {
      display: flex;
      align-items: stretch;
      justify-content: center;
      & .btn-save {
        color: white;
        background-color: green;
      }
    }
  }

  .box-legal-representative-data {
    display: flex;
    justify-content: space-between;
    margin: 15px 20px 10px 10px;
    align-items: center;
    height: 45px;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.6rem 0 0.4rem 0;
    .MuiFilledInput-input {
      padding: 9px 2px 12px 10px;
    }
    .Mask {
      background-color: #ffffff;
      font-size: 16px;
      padding: 15.1px 20px 8px 10px;
      border: none;
      border-bottom: 0.1rem solid;
      border-color: #b4b4b4;
      color: #1d1d1d;
      &:focus {
        outline: none;
      }
      &:disabled {
        border-color: #cacaca;
        color: #c2c2c2;
      }
    }
  }

  .box-legal-representative-data-phone {
    display: flex;
    justify-content: space-between;
    margin: 15px 20px 10px 10px;
    align-items: center;
    height: 45px;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.6rem 0 0.4rem 0;
    .MuiFilledInput-input {
      padding: 9px 2px 12px 10px;
    }
    .Mask {
      background-color: #ffffff;
      font-size: 16px;
      /* padding: 15.1px 20px 8px 10px; */
      border: none;
      border-bottom: 0.1rem solid;
      border-color: #b4b4b4;
      color: #1d1d1d;
      &:focus {
        outline: none;
      }
      &:disabled {
        border-color: #cacaca;
        color: #c2c2c2;
      }
    }
  }

  .box-legal-representative-data > p {
    font-size: 1.111vw;
  }

  /* .box-legal-representative-title-data {
    text-transform: uppercase;
    font-weight: 600;
  } */

  .box-legal-representative-actions {
    width: 60%;
    display: flex;
    justify-content: space-between;
    margin: 30px 20px 10px;
  }

  .box-description {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .icon-edit {
    display: flex;
    justify-content: flex-end;
  }
`;
