import { Modal, Select } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { VscChromeClose } from 'react-icons/vsc';

import { CloseStyle, Footer, HeaderStyle, TitleStyle } from './style';
import { Button, Input, Label, ModalFooter } from 'reactstrap';
import api from '../../service/api';

export default function ModalStatus({ open, close, id, status, setFeedback, setFeedbackMessage }) {
  const [newStatus, setNewStatus] = useState(status);
  const [loading, setLoading] = useState(false);

  function updateStatus() {
    setLoading(true);
    api
      .put(`/solicitacoes/segunda-via-cartao/status`, {
        data: {
          ID_SOLICITACAO: id,
          STATUS: newStatus,
        },
      })
      .then((res) => {
        if (res.data.retorno && res.data.retorno == 'sucesso') {
          close();
          setFeedback(true);
          setFeedbackMessage('Sucesso!');
        } else {
          close();
          setFeedback(true);
          setFeedbackMessage(res.data.mensagem);
        }
      })
      .catch(() => {
        close();
        setFeedback(true);
        setFeedbackMessage('Algo inesperado aconteceu.');
      })
      .finally(() => setLoading(false));
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#fff',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    ovewflowX: 'scroll',
  };

  return (
    <Modal open={open} onClose={close} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <HeaderStyle>
          <TitleStyle>Alterar Status</TitleStyle>
          <CloseStyle onClick={close}>
            <div style={{ display: 'flex' }}>
              <VscChromeClose />
            </div>
          </CloseStyle>
        </HeaderStyle>
        <div style={{ marginBottom: '1.5rem' }}>
          <Input
            onChange={(e) => setNewStatus(e.target.value)}
            type="select"
            name="status"
            id="status"
            style={{ width: '100%' }}
            value={newStatus}
          >
            <option key={'1'} value={'1'}>
              Aguardando
            </option>
            <option key={'2'} value={'2'}>
              Enviado
            </option>
            <option key={'3'} value={'3'}>
              Entregue
            </option>
            <option key={'4'} value={'4'}>
              Cancelado
            </option>
            <option key={'5'} value={'5'}>
              Em trãnsito
            </option>
          </Input>
        </div>

        <Footer>
          <Button color="danger" onClick={() => close()}>
            Cancelar
          </Button>
          <Button disabled={status == newStatus} onClick={() => updateStatus()} color="success">
            Salvar
          </Button>
        </Footer>
      </Box>
    </Modal>
  );
}
