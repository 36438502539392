import React, { useState, useMemo } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { kycUserLog } from '../../../../../../store/user/actions';
import StatusContainer from '../../../../../StatusContainer/StatusContainer';
import { useDispatch, useSelector } from 'react-redux';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

function AccordionKYC() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const [kycUser, setKycUser] = useState([]);

  useMemo(() => {
    dispatch(
      kycUserLog(user.UUID, function (req) {
        if (req.retorno === 'sucesso') {
          setKycUser(req.data);
        }
      }),
    );
  }, []);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h5" sx={{ width: '80%', fontWeight: '600', fontSize: '18px', flexShrink: 0 }}>
          KYC
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {kycUser.length > 0 ? (
            <>
              <Box>
                <Typography mb={1}>Tentativas: {kycUser.length}</Typography>
              </Box>
              <Box>
                {kycUser.map((kyc, index) => (
                  <Box key={index} mb={1}>
                    <Typography sx={{ display: 'flex' }}>
                      <b>Status anterior: </b>{' '}
                      <StatusContainer code={kyc.OLD_STATUS_CODE} description={kyc.OLD_STATUS} />
                    </Typography>
                    <Typography sx={{ display: 'flex' }}>
                      <b>Status Atual: </b> <StatusContainer code={kyc.STATUS_CODE} description={kyc.STATUS} />
                    </Typography>
                    <Typography>
                      <b>Motivo:</b> {kyc.REASON}
                    </Typography>
                    <Typography>
                      <b>Data:</b> {kyc.CREATED_AT}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography>Não validado pelo kyc</Typography>
            </Box>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionKYC;
