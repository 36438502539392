import React, { useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import formatCPF from '../../../../../utils/formatCPF';
import formatCNPJ from '../../../../../utils/formatCNPJ';
import { Box, Card, Typography } from '@mui/material';
import ModalDocs from '../ModalDocs/ModalDocs';

function ModalRepresentatives({ open, toggle, representatives }) {
  const [modalDocs, setModalDocs] = useState({
    open: false,
    documents: [],
  });

  return (
    <>
      <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <Typography>Representantes</Typography>
        </ModalHeader>
        <ModalBody>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            {representatives && representatives.length > 0 ? (
              representatives.map((representative, index) => {
                return (
                  <Card key={index} sx={{ padding: '0 1rem' }}>
                    <Box>
                      <Typography variant="p" component="p">
                        <strong>Nome:</strong> {representative.NOME}
                      </Typography>

                      <Typography variant="p" component="p">
                        <strong>CPF:</strong> {formatCPF(representative.CPF)}
                      </Typography>

                      <Typography variant="p" component="p">
                        <strong>Celular:</strong> {representative.CELULAR}
                      </Typography>

                      <Typography variant="p" component="p">
                        <strong>CNPJ empresa:</strong>
                        {formatCNPJ(representative.CNPJ_EMPRESA)}
                      </Typography>

                      <Typography variant="p" component="p">
                        <strong>Principal:</strong>
                        {representative.PRINCIPAL == 'S' ? 'Sim' : 'Não'}
                      </Typography>
                    </Box>
                    <Button
                      size="sm"
                      color="primary"
                      style={{ marginBottom: '1rem' }}
                      onClick={() => setModalDocs({ open: true, documents: representative.DOCUMENTOS })}
                    >
                      Documentos
                    </Button>
                  </Card>
                );
              })
            ) : (
              <Typography variant="p">Não existe representantes</Typography>
            )}
          </Box>
        </ModalBody>
      </Modal>
      <ModalDocs
        open={modalDocs.open}
        toggle={() => setModalDocs({ open: false, documents: [] })}
        documents={modalDocs.documents}
      />
    </>
  );
}

export default ModalRepresentatives;
