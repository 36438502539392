import styled from 'styled-components';
import { FaSync } from 'react-icons/fa';
import { DropdownItem } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
export const RevertIcon = styled(FaSync)`
  color: #27b8e7;
  font-size: 15px;

  &:hover {
    font-size: 22px;
    cursor: pointer;
    position: relative;
    transition: 1s;
  }
`;

export const DropdownOption = styled(DropdownItem)`
  &:hover {
    background: #2405f2 !important;
    color: white;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    border-radius: 7px;
  }
`;

export const Spinner = styled(CircularProgress)`
  color: #2405f2;
`;

export const LoadingModalTitle = styled.h3`
  font-size: 1vw;
  margin: 1vw 0;
  color: linear-gradient(87deg, #2405f2 0, #1171ef 100%) !important;
`;

export const LoadingModalContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1vw;
  margin-top: 1vw;
`;

export const SaldoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SaldoStyle = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 3%;
`;

export const SaldoUtilizadoColor = styled.div`
  color: #dc2d06;
  font-weight: 600;
`;

export const SaldoDisponivelColor = styled.div`
  color: #1ba305;
  font-weight: 600;
`;

export const SaldoColor = styled.div`
  font-weight: 600;
`
export const CustomBadge = styled.span`
  margin: 0 1rem !important;
  padding: 0.3rem 0.8rem;
  font-size: 1rem;
  text-align: center;
  background-color: #0058e1;
  margin: 0.3rem;
  border-radius: 4px;
  color: #fff;
  font-weight: 100;
  letter-spacing: 0.1rem;
`;
