import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material';
import { Button as ButtonReactStrap, Col, ModalFooter } from 'reactstrap';
import { Row } from 'reactstrap';
import Loading from '../../../../components/Loader/Loading';
import Table from '../../../../components/Table/Table';
import ProductCardsCard from '../ProductsCardsCard/ProductCardsCard';
import { EditCard } from '../../../../assets/icons';
import { ButonContainer } from '../DigitalAccountBalaceContainer/style';
import DigitalAccountBalaceContainer from '../DigitalAccountBalaceContainer/DigitalAccountBalaceContainer';
import ModalLote from '../modals/components/ModalLote/ModalLote';
import { AdicionarProduto } from '../modals';
import {
  createCarduser,
  findCardUser,
  listarProdutosCartaoVerificarMCCEmLoteByUUID,
  selectAdicionarProdutoByUUID,
  selectOperarSaldoByUUID,
  updateCardUser,
} from '../../../../store/swap/actions';
import {
  listarCartaoByUUID,
  listarCartaoPorProdutoByUUID,
  listarTodosProdutos,
} from '../../../../store/coban/actions';
import {
  msBaasApproveCardUser,
  toggleModalSolicitarCartaoNominal,
} from '../../../../store/user/actions';
import InputSelectAmountResults from '../../../../components/Filter/components/InputSelectAmountResults';

import { useDispatch, useSelector } from 'react-redux';

import api from '../../../../service/api';
import InputWithSearch from '../../../../components/Filter/components/inputWithSearch';
import ModalBindAccount from '../modals/components/ModalBindAccount/ModalBindAccount';
import { BsArrowLeftRight } from 'react-icons/bs';
import { FaTruck } from 'react-icons/fa';
import ModalRastreioCartao from '../modals/components/ModalRastreioCartao/ModalRastreioCartao';
import { ModalSolicitarCartaoNominal } from '../../../../components/Modais/ModalSolicitarCartaoNominal/ModalSolicitarCartaoNominal';
import ModalInfoCardUser from './ModalInfoCardUser';
import ModalMensagemSemReloadNew from '../../../Transaction/ModalMensagemSemReloadNew';
import { Reply } from '@mui/icons-material';
import GenericModal from '../../../../components/Modais/GenericModal';
import ModalEnviarParaBaas from '../../../../components/Modais/ModalEnviarParaBaas/ModalEnviarParaBaas';

function AccordionCardsUser({
  allCards: { CARTOES, pagination },
  saldoGeral,
  setCard,
  setInfoCard,
  user,
  searchWithFilter,
  handleClick,
  informacoesPessoais,
  filterCards,
  forceReload,
  setFilterCards,
}) {
  const {
    coban: { loading_coban, PRODUTOS_EMPRESA },
    user: { modalSolicitarCartaoNominal },
    swap: {
      card_user: { loading: loading_card_user },
    },
  } = useSelector((state) => state);

  const [modalAddProduto, setModalAddProduto] = useState(false);
  const [modalSuccessCards, setModalSuccessCards] = useState(false);
  const [availableCards, setAvailableCards] = useState(false);
  const [modalOperate, setModalOperate] = useState(false);
  const [loadingAvailableCards, setLoadingAvailableCards] = useState(false)
  const [modalAddProductBatch, setModalAddProductBatch] = useState(false);
  const [modalOperateBalanceProductBatch, setModalOperateBalanceProductBatch] = useState(false);
  const [alertError, setAlertError] = useState('');
  const dispatch = useDispatch();
  const [checkedCards, setCheckedCards] = useState([]);
  const [selectValue, setSelectValue] = useState('');
  const [select2, setSelect2] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [modalBindAccount, setModalBindAccount] = useState(false);
  const [choosenCard, setChoosenCard] = useState(false);
  const [cardAccount, setCardAccount] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [modalRastreio, setModalRastreio] = useState(false);
  const [cardUser, setCardUser] = useState({});
  const [infoCardUser, setInfoCardUser] = useState({ open: false, title: '', message: '' });
  const [modalBaas, setModalBaas] = useState({
    open: false,
    header: '',
    body: '',
    type: 'approve card user',
    requestBody: {}
  });

  useEffect(() => {
    dispatch(selectOperarSaldoByUUID(user.UUID));
    dispatch(selectAdicionarProdutoByUUID(user.UUID));
    dispatch(listarTodosProdutos());
    getCardAccount();
    listAllCards();
    getCardUser();
  }, []);

  useEffect(() => {
    if (selectValue == '') {
      setAvailableCards([]);
    }
  }, [selectValue])

  function getCardAccount() {
    setLoadingModal(true);
    if (informacoesPessoais) {
      api
        .get(`/admin/conta-cartao/${user.UUID}`)
        .then(({ data }) => {
          if (data) {
            setCardAccount(data.data);
            setLoadingModal(false);
          }
        })
        .catch(() => setLoadingModal(false));
    }
  }

  function getCardUser() {
    dispatch(findCardUser(user.UUID, ({ data }) => setCardUser(data)));
  }

  function checkMcc(codProduto) {
    setLoadingAvailableCards(true)
    setAvailableCards(false);
    setCheckedCards([]);
    dispatch(
      listarProdutosCartaoVerificarMCCEmLoteByUUID(user.UUID, codProduto, (res) => {
        if (res.retorno === 'erro') {
          setAlertError(res.mensagem);
        } else {
          setAlertError('');
          setAvailableCards(res.data);
        }
      }),
    ).finally(() => setLoadingAvailableCards(false));
  }

  function handleOpenModalBalance() {
    setModalOperateBalanceProductBatch(true);
    dispatch(listarCartaoPorProdutoByUUID(user.UUID, selectValue));
  }

  function closeModalOperateBalance() {
    setModalOperateBalanceProductBatch(false);
    dispatch(listarCartaoByUUID(user.UUID, filterCards));
    forceReload();
  }

  function handleAddProductInBatch() {
    setLoadingAvailableCards(true)
    let produtos = checkedCards.map((card) => {
      return { CODCARTAO: card, CODPRODUTO: selectValue };
    });

    api
      .post('conveniada/vincula-produto', {
        PRODUTOS: produtos,
      })
      .then((res) => {
        if (res.data.retorno == 'sucesso') {
          setModalSuccessCards(true);
        } else {
          setAlertError(res.data.mensagem);
        }
      })
      .catch(() => {
        setAlertError('Erro ao vincular produto');
      }).finally(() => setLoadingAvailableCards(false));

  }

  function searchCardWithFilter() {
    dispatch(listarCartaoByUUID(user.UUID, filterCards));
  }

  function closeModalAdd() {
    setModalAddProductBatch(false);
    setAvailableCards(false);
    setSelectValue('');
    setAlertError('');
  }

  function closeModalOperate() {
    setModalOperate(false);
    setSelectValue('');
    setAlertError('');
    setCheckedCards([]);
  }

  function openModalBind(card) {
    setChoosenCard(card);
    setModalBindAccount(true);
  }

  function closeModalBind() {
    setModalBindAccount(false);
    setChoosenCard(false);
  }

  async function listAllCards() {
    api.post(`cartoes/numeros`, { CPFCNPJ_CLIENTE: user.CPFCNPJ }).then(({ data }) => {
      if (data) {
        const cardNumbers = data.data.map((card) => card.PAN_MASKED);
        setSelect2(cardNumbers);
      }
    });
  }

  let { page } = filterCards;

  const tablePagination = async (type = 'atual', pageClick) => {
    switch (type) {
      case 'prev':
        page = pagination.prevPage;
        break;
      case 'next':
        page = pagination.nextPage;
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }
    await setFilterCards({ ...filterCards, page });
  };

  useEffect(() => {
    dispatch(listarCartaoByUUID(user.UUID, filterCards));
  }, [filterCards.page, filterCards.limit, filterCards.numCartao]);

  function callbackMsBass(callback) {
    setModalBaas({ open: false, header: '', body: '', footer: '' });
    setModalBaas({
      open: true,
      header: <h1>{callback.retorno}</h1>,
      body: <span>{callback.mensagem}</span>,
      footer: (
        <ModalFooter>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <ButtonReactStrap
              style={{ marginTop: '8px' }}
              color={callback.retorno == 'erro' ? 'danger' : 'success'}
              size="sm"
              onClick={() => setModalBaas({ open: false, header: '', body: '', footer: '' })}
              block
            >
              Ok
            </ButtonReactStrap>
          </div>
        </ModalFooter>
      ),
    });
  }

  function handleModalBaasCardUser() {
    setModalBaas({
      open: true,
      header: "Disparo de Usuário Cartão",
      body: "Deseja enviar para o microserviço terceiros?",
      type: 'approve card user',
      requestBody: {
        id_digital_account: user.ID_TIPO_USUARIO,
        user: {
          document: user.CPFCNPJ,
          status: cardUser.KYC_STATUS
        }
      }
    });
  }

  function reloadCard() {
    dispatch(listarCartaoByUUID(user.UUID, filterCards));
  }
  return (
    <>
      <Box sx={{ display: 'flex', mr: 5 }}></Box>
      <Accordion expanded={expanded}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-content">
          {!loading_card_user && (
            <>
              {cardUser && cardUser.ACCOUNT_HOLDER_ID ? (
                <>
                  {cardUser.status !== 'approved' && (
                    <ButtonReactStrap
                      color="primary"
                      size="sm"
                      style={{ borderRadius: '5px' }}
                      onClick={async () => {
                        await dispatch(updateCardUser(cardUser.ACCOUNT_HOLDER_ID));
                        getCardUser();
                      }}
                    >
                      Atualizar Usuário Cartão
                    </ButtonReactStrap>
                  )}
                  <ButtonReactStrap
                    color="primary"
                    size="sm"
                    style={{ borderRadius: '5px' }}
                    onClick={() => handleClick('solicitarCartao')}
                  >
                    Solicitar Cartão
                  </ButtonReactStrap>

                  {localStorage.getItem('nivelAcesso') == 1 && user.STATUS == 7 && (
                    <>
                      <ButtonReactStrap
                        color="primary"
                        size="sm"
                        style={{ borderRadius: '5px' }}
                        onClick={() => handleClick('cartaoNaoNominal')}
                      >
                        Cadastrar Cartão Não Nominal
                      </ButtonReactStrap>
                    </>
                  )}
                </>
              ) : (
                <ButtonReactStrap
                  color="primary"
                  size="sm"
                  style={{ borderRadius: '5px' }}
                  onClick={() => {
                    dispatch(
                      createCarduser(user.UUID, (res) => {
                        if (res.retorno == 'erro') {
                          if (res.errors && res.errors.shareholders) {
                            let shareholderMessage = '';

                            if (typeof res.errors.shareholders == 'object') {
                              for (let shareholder in res.errors.shareholders) {
                                let shareMessage = '';
                                if (typeof res.errors.shareholders[shareholder] == 'object') {
                                  for (let share in res.errors.shareholders[shareholder]) {
                                    const nameShareholder = res.request.shareholders[shareholder]['legal_name']
                                      ? res.request.shareholders[shareholder]['legal_name']
                                      : res.request.shareholders[shareholder]['first_name'];
                                    shareMessage += `${nameShareholder} ${share}: ${res.errors.shareholders[shareholder][share]}, `;
                                  }
                                  shareholderMessage += shareMessage;
                                } else {
                                  shareholderMessage += `${res.errors.shareholders[shareholder]}`;
                                }
                              }
                            } else {
                              shareholderMessage += `${res.errors.shareholders}`;
                            }

                            setInfoCardUser({
                              open: true,
                              title: 'Erro na solicitação do Usuário Cartão',
                              message: (
                                <Box>
                                  <Typography>shareholders:</Typography> {shareholderMessage}
                                </Box>
                              ),
                            });
                          } else {
                            setInfoCardUser({
                              open: true,
                              title: 'Erro na solicitação do Usuário Cartão',
                              message: 'Tente novamente mais tarde',
                            });
                          }
                        } else {
                          getCardUser();
                        }
                      }),
                    );
                  }}
                >
                  Cadastrar Usuário Cartão
                </ButtonReactStrap>
              )}
            </>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
            Usuário Cartões
          </Typography>
          {loading_card_user ? (
            <Loading color="#2405f2" />
          ) : (
            <>
              {cardUser && cardUser.ACCOUNT_HOLDER_ID ? (
                <>
                  <Box sx={{ mt: '1rem' }}>
                    <Row>
                      <Col>
                        <b>Nome:</b> {user.NOME}
                      </Col>
                      <Col>
                        <b>Cpf/Cnpj:</b> {user.CPFCNPJ}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <b>Ativo:</b> {cardUser.ATIVO ? cardUser.ATIVO : '---'}
                      </Col>
                      <Col>
                        <b>Status:</b> {cardUser.KYC_STATUS ? cardUser.KYC_STATUS : '---'}
                        {cardUser.KYC_STATUS && (
                            <Reply style={{ cursor: 'pointer', marginLeft: '0.5rem' }} onClick={handleModalBaasCardUser} />
                        )}
                      </Col>
                    </Row>
                  </Box>
                  <Box>
                    {cardUser.HISTORY && cardUser.HISTORY.length > 0 ? (
                      <div id="toggler" className="mt-3" style={{ background: '#F2F2F2', padding: '0 0 0.8rem 0' }}>
                        <Table
                          size="sm"
                          headers={[
                            {
                              info: 'data da criação',
                              style: { textAlign: 'center' },
                            },
                            {
                              info: 'analysis_id',
                              style: { textAlign: 'center' },
                            },
                            {
                              info: 'event_type',
                              style: { textAlign: 'center' },
                            },
                            {
                              info: 'status',
                              style: { textAlign: 'center' },
                            },
                            {
                              info: 'motivo',
                              style: { textAlign: 'center' },
                            },
                          ]}
                          data={cardUser.HISTORY.map((history) => {
                            return {
                              data: [
                                {
                                  info: new Date(history.DATA_INSERT).toLocaleString('pt-BR'),
                                  style: { textAlign: 'center' },
                                },
                                {
                                  info: history.analysis_id,
                                  style: { textAlign: 'center' },
                                },
                                {
                                  info: history.event_type,
                                  style: { textAlign: 'center' },
                                },
                                {
                                  info: history.kyc_status,
                                  style: { textAlign: 'center' },
                                },
                                {
                                  info: history.kyc_analysis_results ? history.kyc_analysis_results.join(',') : '---',
                                  style: { textAlign: 'center' },
                                },
                              ],
                            };
                          })}
                        />
                      </div>
                    ) : (
                      <div id="toggler" className="mt-3" style={{ background: '#F2F2F2', padding: '0 0 0.8rem 0' }}>
                        <Table
                          size="sm"
                          headers={[
                            {
                              info: 'analysis_id',
                              style: { textAlign: 'center' },
                            },
                            {
                              info: 'event_type',
                              style: { textAlign: 'center' },
                            },
                            {
                              info: 'status',
                              style: { textAlign: 'center' },
                            },
                            {
                              info: 'motivo',
                              style: { textAlign: 'center' },
                            },
                            {
                              info: 'data da criação',
                              style: { textAlign: 'center' },
                            },
                            {
                              info: 'data da atualização',
                              style: { textAlign: 'center' },
                            },
                          ]}
                        />
                        <Row
                          className="mb-2"
                          style={{
                            justifyContent: 'center',
                          }}
                        >
                          <div style={{ color: '#525F7F', fontWeight: '600' }}>O CLIENTE NÃO POSSUI LOGS</div>
                        </Row>
                      </div>
                    )}
                  </Box>
                </>
              ) : (
                <Row
                  className="mb-2"
                  style={{
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ color: '#525F7F', fontWeight: '600' }}>SOLICITAÇÃO NÃO REALIZADA</div>
                </Row>
              )}
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-content">
          <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
            Cartões
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <ButonContainer>
              <Button
                variant="contained"
                sx={{ marginBottom: '1rem', backgroundColor: '#0245AE' }}
                onClick={() => setModalAddProductBatch(true)}
              >
                Adicionar Produto
              </Button>
              <Button
                variant="contained"
                sx={{ marginBottom: '1rem', backgroundColor: '#0092D4' }}
                onClick={() => setModalOperate(true)}
                style={{ marginLeft: '1%' }}
              >
                Operar Saldo
              </Button>
            </ButonContainer>

            {CARTOES && CARTOES.length != 0 ? (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                      fontFamily: 'sans-serif !important',
                      fontSize: '0.8rem !important',
                      color: 'black',
                      fontWeight: '400',
                    }}
                  >
                    <InputSelectAmountResults
                      options={['5', '10', '25', '50', '100']}
                      column={true}
                      value={filterCards.limit}
                      width={'10rem'}
                      setValue={(value) => setFilterCards({ ...filterCards, limit: value })}
                    />

                    <InputWithSearch
                      style={{ marginRight: '2rem' }}
                      setValue={(value) => setFilterCards({ ...filterCards, numCartao: value })}
                      options={select2 && ['Todos', ...select2]}
                    />
                  </div>

                  <DigitalAccountBalaceContainer user={user} saldoGeral={saldoGeral} filterCards={filterCards} />
                </div>
                {loading_coban ? (
                  <Loading color="#2405f2" />
                ) : (
                  <div id="toggler" className="mt-3">
                    <Table
                      size="sm"
                      headers={[
                        {
                          info: 'Tipo',
                          style: { textAlign: 'left' },
                        },
                        {
                          info: 'Número',
                          style: { textAlign: 'left' },
                        },

                        {
                          info: 'PRODUTOS',
                          style: { textAlign: 'center' },
                        },
                        {
                          info: 'SALDO PRODUTOS',
                          style: { textAlign: 'center' },
                        },
                        {
                          info: 'VÍNCULO',
                          style: { textAlign: 'center' },
                        },

                        {
                          info: 'VER MAIS',
                          style: { textAlign: 'center' },
                        },
                      ]}
                      data={
                        CARTOES &&
                        CARTOES.map((cartao, index) => {
                          return {
                            data: [
                              {
                                info: cartao.ROTULO ? cartao.ROTULO : cartao.TIPO,
                                style: { textAlign: 'left' },
                              },

                              {
                                info: cartao.NUMERO,
                                style: { textAlign: 'left' },
                              },

                              {
                                info: (
                                  <ProductCardsCard
                                    allCards={cartao.PRODUTOS}
                                    setInfoCard={setInfoCard}
                                    cartao={cartao}
                                  />
                                ),
                                style: { textAlign: 'center' },
                              },

                              {
                                info: cartao.SALDO_GERAL ? 'R$ ' + cartao.SALDO_GERAL : 'R$ 0,00',
                                style: { textAlign: 'center' },
                              },

                              {
                                info: cartao.TIPO_VINCULO == 2 ? 'CONTA CARTÃO' : 'CONTA BANCÁRIA',
                                style: { textAlign: 'center' },
                              },

                              {
                                info: (
                                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div onClick={() => setInfoCard({ open: true, cartao: cartao, SALDO: saldoGeral })}>
                                      <EditCard />
                                    </div>
                                    <div style={{ marginLeft: '.3rem' }} onClick={() => openModalBind(cartao)}>
                                      {informacoesPessoais.conta_bacen &&
                                        informacoesPessoais.conta_bacen.length > 0 &&
                                        cardAccount &&
                                        cardAccount.length > 0 && (
                                          <BsArrowLeftRight title="Vincular conta" style={{ fontSize: '1rem' }} />
                                        )}
                                    </div>
                                    {cartao.TRACKING_CODE && (
                                      <div
                                        style={{ marginLeft: '.3rem' }}
                                        onClick={() => {
                                          setChoosenCard(cartao);
                                          setModalRastreio(true);
                                        }}
                                      >
                                        <FaTruck title="Rastreio" style={{ fontSize: '1rem' }}></FaTruck>
                                      </div>
                                    )}
                                  </div>
                                ),
                                style: { textAlign: 'center', cursor: 'pointer' },
                              },
                            ],
                          };
                        })
                      }
                      pagination={pagination}
                      previousPage={{ onClick: () => tablePagination('prev') }}
                      nowPage={{ page, onClick: pagination }}
                      nextPage={{ onClick: () => tablePagination('next') }}
                    />
                  </div>
                )}
              </>
            ) : (
              <div id="toggler" className="mt-3" style={{ background: '#F2F2F2', padding: '0 0 0.8rem 0' }}>
                <Table
                  size="sm"
                  headers={[
                    {
                      info: 'Tipo',
                      style: { textAlign: 'left' },
                    },
                    {
                      info: 'Número',
                      style: { textAlign: 'left' },
                    },

                    {
                      info: 'PRODUTOS',
                      style: { textAlign: 'center' },
                    },
                    {
                      info: 'SALDO PRODUTOS',
                      style: { textAlign: 'center' },
                    },

                    {
                      info: 'VER MAIS',
                      style: { textAlign: 'center' },
                    },
                  ]}
                />
                <Row
                  className="mb-2"
                  style={{
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ color: '#525F7F', fontWeight: '600' }}>O CLIENTE NÃO POSSUI CARTÕES</div>
                </Row>
              </div>
            )}
          </div>
        </AccordionDetails>

        <AdicionarProduto open={modalAddProduto} close={(e) => setModalAddProduto(false)} />

        <ModalSolicitarCartaoNominal
          toggle={() => dispatch(toggleModalSolicitarCartaoNominal())}
          isOpen={modalSolicitarCartaoNominal}
        />

        <ModalMensagemSemReloadNew
          open={modalSuccessCards}
          close={() => {
            setModalSuccessCards(false);
            setModalAddProductBatch(false);
            setSelectValue('');
            dispatch(listarCartaoByUUID(user.UUID, filterCards));
          }}
          callback={() => {
            setModalSuccessCards(false);
            setModalAddProductBatch(false);
            setSelectValue('');
            dispatch(listarCartaoByUUID(user.UUID, filterCards));
          }}
          sucesso={true}
          mensagem={'Sucesso ao vincular cartões!'}
          title={'Sucesso'}
        />

        <ModalLote
          allCards={CARTOES}
          saldoGeral={saldoGeral}
          openPL={modalOperate}
          closePL={() => closeModalOperate()}
          title={'Operar saldo em lote'}
          buttonName={'Continuar'}
          setSelectValue={setSelectValue}
          modalOperateBalanceProductBatch={modalOperateBalanceProductBatch}
          handleClick={handleOpenModalBalance}
          setModalOperateBalanceProductBatch={setModalOperateBalanceProductBatch}
          listagemProduto={PRODUTOS_EMPRESA}
          selectValue={selectValue}
          setAlertError={setAlertError}
          searchCardWithFilter={searchCardWithFilter}
          closeModalOperateBalance={closeModalOperateBalance}
          forceReload={forceReload}
        />
        <ModalLote
          setAlertError={setAlertError}
          selectValue={selectValue}
          alertError={alertError}
          checkMcc={checkMcc}
          allCards={CARTOES}
          availableCards={availableCards}
          saldoGeral={saldoGeral}
          searchCardWithFilter={searchCardWithFilter}
          openPL={modalAddProductBatch}
          closePL={() => closeModalAdd()}
          title={'Produto em lote'}
          loadingAvailableCards={loadingAvailableCards}
          setCheckedCards={setCheckedCards}
          checkedCards={checkedCards}
          buttonName={'Adicionar'}
          setSelectValue={setSelectValue}
          handleClick={handleAddProductInBatch}
          setModalOperateBalanceProductBatch={setModalOperateBalanceProductBatch}
          listagemProduto={PRODUTOS_EMPRESA}
          forceReload={forceReload}
          closeModalOperateBalance={closeModalOperateBalance}
        />

        <ModalRastreioCartao
          open={modalRastreio}
          choosenCard={choosenCard}
          toggle={() => {
            setChoosenCard(false);
            setModalRastreio(false);
          }}
        />

        <ModalBindAccount
          informacoesPessoais={informacoesPessoais}
          open={modalBindAccount}
          cardAccount={cardAccount}
          loadingModal={loadingModal}
          setLoadingModal={setLoadingModal}
          cartao={choosenCard}
          reloadCard={() => reloadCard()}
          toggle={() => closeModalBind()}
          forceReload={forceReload}
        />

        <ModalInfoCardUser
          open={infoCardUser.open}
          toggle={() => setInfoCardUser({ open: false, title: '', message: '' })}
          title={infoCardUser.title}
          message={infoCardUser.message}
        />

        <ModalEnviarParaBaas
          toggle={() => setModalBaas({
            open: false,
            header: '',
            body: '',
            type: '',
            requestBody: {}
          })}
          {...modalBaas}
        />
      </Accordion>
    </>
  );
}

export default AccordionCardsUser;
