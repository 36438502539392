const formatDate = (string) => {
  try {
    if (typeof string != 'string') {
      string = string + '';
    }
    const rawNumber = string.replace('/', '').replace('/', '').replace('/', '');
    const firstDigits = rawNumber.substr(0, 2);
    const secondDigits = rawNumber.substr(2, 2);
    const lastDigits = rawNumber.substr(4, 4);
    const maskedString = `${firstDigits}` + (secondDigits && `/${secondDigits}`) + (lastDigits && `/${lastDigits}`);
    return maskedString;
  } catch (e) {
    return '';
  }
};

export default formatDate;
