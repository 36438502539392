import { RESPONSE_CONFIG } from './types';
import api from '../../service/api';

export const getConfigs = (callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: RESPONSE_CONFIG, payload: { load_config: true } });
    try {
      const { status, data } = await api.get('configuracoes');
      dispatch({
        type: RESPONSE_CONFIG,
        payload: { load_config: false, listConfigurations: data },
      });
      if (callback) callback(true);
    } catch (err) {
      dispatch({ type: RESPONSE_CONFIG, payload: { load_config: false } });
      if (callback) callback(false);
    }
  };
};

export const getTaxaConfigs = (callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: RESPONSE_CONFIG, payload: { load_config: true } });
    try {
      const { status, data } = await api.get('configuracoes/custo-operacional');
      dispatch({
        type: RESPONSE_CONFIG,
        payload: {
          load_config: false,
          TAXA: data.TAXA,
          FINANCIAMENTO: data.FINANCIAMENTO,
        },
      });
      callback(data);
    } catch (err) {
      dispatch({ type: RESPONSE_CONFIG, payload: { load_config: false } });
      if (callback) callback(false);
    }
  };
};

export const updateConfig = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: RESPONSE_CONFIG, payload: { loading_transaction: true } });
    try {
      const { status, data } = await api.put(`/configuracoes`, params);
      dispatch({
        type: RESPONSE_CONFIG,
        payload: {
          loading_transaction: false,
          mensagemConfig: data.mensagem,
          mensagemComValor: true,
          color: data.retorno == 'error' ? 'danger' : 'success',
        },
      });
      callback(data, true);
    } catch (error) {
      dispatch({
        type: RESPONSE_CONFIG,
        payload: { loading_transaction: false },
      });
      const res = error.response ? error.response.data : { menssage: 'Erro ao inserir' };
      callback(res, false);
    }
  };
};

export const updateTaxaConfigs = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: RESPONSE_CONFIG, payload: { load_config: true } });

    api
      .post('configuracoes/atualizar-operacional', params)
      .then((res) => {
        dispatch({
          type: RESPONSE_CONFIG,
          payload: { load_config: false, mensagemTaxa: res.data.mensagem },
        });
        if (callback) callback(true, res);
      })
      .catch((err) => {
        dispatch({ type: RESPONSE_CONFIG, payload: { load_config: false } });
        if (callback) callback(false, err);
      });
  };
};

export const updateFinanciamentoConfigs = (params, ID_COBAN, PARCELAS, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: RESPONSE_CONFIG, payload: { load_config: true } });

    api
      .post('configuracoes/atualizar-financiamento', params)
      .then((res) => {
        dispatch({
          type: RESPONSE_CONFIG,
          payload: { load_config: false, mensagemTaxa: res.data.mensagem },
        });
        if (callback) callback(true, res);
      })
      .catch((err) => {
        dispatch({ type: RESPONSE_CONFIG, payload: { load_config: false } });
        if (callback) callback(false, err);
      });
  };
};
