import React, { useEffect, useState } from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../Loader/Loading';
import { approval } from '../../../store/user/actions';
import AlertModal from '../../../components/AlertModal/AlertModal';
import ModalMensagem from '../../../views/Transaction/ModalMensagem';
import ModalReprovacao from './ModalReprovacao';
import StatusContainer from '../../StatusContainer/StatusContainer';
import { Alert, Box, Tab, Tabs } from '@mui/material';
import TabVerifications from './components/Tabs/TabVerifications/TabVerifications';
import TabInformacoesPessoais from './components/Tabs/TabInformacoesPessoais/TabInformacoesPessoais';
import TabDocumentos from './components/Tabs/TabDocumentos/TabDocumentos';
import TabContratos from './components/Tabs/TabContratos/TabContratos';
import TabHistorico from './components/Tabs/TabHistorico';
import { addSTATE } from '../../../store/globals';
import { AccountCircle, DocumentScanner, History, HistoryEdu, PersonSearch } from '@mui/icons-material';
import ModalBloquear from '../Bloquear/ModalBloquear';

const ModalCliente = ({ modal, toggle }) => {
  const dispatch = useDispatch();
  const { loading_user, user } = useSelector((state) => state.user);

  const [denyClient, setDenyClient] = useState(false);
  const [renderButton, setRenderButton] = useState(true);
  const [ERRO_STATUS, setERRO_STATUS] = useState('');
  const [messageModal, setModalMessage] = useState({ open: false, message: '', success: false });
  const [messageAlert, setMessageAlert] = useState('');
  const [tabNumber, setTabNumber] = useState(0);
  const [modalUnblockUser, setModalUnblockUser] = useState(false);

  let aShow;
  if (localStorage.getItem('nivelAcesso') != '6' && user) {
    aShow = true;
  }

  /*   useEffect(() => {
    if (user) {
      let newuser = user;

      newuser.ID_COBAN = 0;
      dispatch(addSTATE('USER_ADD_STATE', 'user', newuser));
    }
  }, []); */

  useEffect(() => {
    if (messageAlert) {
      setTimeout(() => setMessageAlert(''), 10000);
    }
  }, [messageAlert]);

  const action = (type, params) => {
    setRenderButton(!renderButton);
    if (!denyClient && type == 'recusar') setDenyClient(true);
    else
      dispatch(
        approval(type, params, (res) => {
          const { mensagem, retorno } = res;
          const success = retorno && retorno === 'sucesso';

          setModalMessage({ message: mensagem, open: true, success: success });
        }),
      );
  };

  const close = async () => {
    toggle();
  };

  const back = () => {
    setDenyClient(!denyClient);
    setRenderButton(!renderButton);
  };

  const validateUser = () => {
    if (user.BIN != user.confirmarBIN) {
      setMessageAlert('Os dados do cartão não correspondem.');
    }

    if (!user.ID_COBAN || user.ID_COBAN == 0) {
      setMessageAlert('Selecione o correspondente bancário.');
    } else {
      setMessageAlert('');
      switch (+user.STATUS) {
        case 1:
          return action('aprovar', {
            CPFUSUARIO: user.CPFCNPJ,
            ID: user.ID,
            ID_COBAN: user.ID_COBAN,
            BIN: user.BIN,
            CVV: user.CVV,
            VALIDADE: user.VALIDADE,
            PDV: user.PDV,
            RISCO_PLD: user.RISCO_PLD,
          });
        case 6:
          return action('aprovar-conta-digital', {
            CPFUSUARIO: user.CPFCNPJ,
            ID_COBAN: user.ID_COBAN,
            BIN: user.BIN,
            CVV: user.CVV,
            VALIDADE: user.VALIDADE,
            PDV: user.PDV,
            RISCO_PLD: user.RISCO_PLD,
          });
      }
    }
  };

  const components = [
    <TabInformacoesPessoais user={user} />,
    <TabDocumentos />,
    <TabContratos parceiro={user && +user.ID_TIPO_USUARIO} />,
    <TabVerifications user={user} uniqueColumn={true} />,
    <TabHistorico />,
  ];

  return (
    <>
      <Modal
        size="lg"
        isOpen={modal}
        toggle={close}
        onOpened={() => {
          setERRO_STATUS('');
        }}
      >
        <ModalHeader toggle={close}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
            <h2>Dados Cliente</h2>
            <StatusContainer code={user && user.STATUS} description={user && user.STATUS_USUARIO_DESCRICAO} />
          </Box>
        </ModalHeader>

        <ModalBody>
          {loading_user ? (
            <Loading color="#11cdef" />
          ) : (
            <>
              {messageAlert && <Alert severity="error">{messageAlert}</Alert>}
              <Box>
                <Tabs value={tabNumber} onChange={(index, value) => setTabNumber(value)}>
                  <Tab
                    icon={<AccountCircle />}
                    sx={{
                      bgcolor: 'white',
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                    label="Informações Pessoais"
                  />
                  <Tab
                    icon={<DocumentScanner />}
                    sx={{
                      bgcolor: 'white',
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                    label="Documentos"
                  />
                  <Tab
                    icon={<HistoryEdu />}
                    sx={{
                      bgcolor: 'white',
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                    label="Contratos"
                  />
                  <Tab
                    icon={<PersonSearch />}
                    sx={{
                      bgcolor: 'white',
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                    label="Verificações"
                  />
                  <Tab
                    icon={<History />}
                    sx={{
                      bgcolor: 'white',
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                    label="Histórico"
                  />
                </Tabs>
              </Box>
              <Box mt={1}>{components[tabNumber]}</Box>
            </>
          )}
        </ModalBody>
        <ModalFooter style={{ display: 'flex', alignItems: 'baseline' }}>
          {!loading_user && (
            <>
              {renderButton && user && (user.STATUS === '3' || user.STATUS === '4') && (
                <>
                  <Button disabled={loading_user} size="sm" block onClick={toggle}>
                    Cancelar
                  </Button>
                  <Button
                    disabled={loading_user}
                    style={{ backgroundColor: '#0768f9', color: 'white' }}
                    size="sm"
                    onClick={() => setModalUnblockUser(true)}
                    block
                  >
                    Reverter usuário
                  </Button>
                </>
              )}
              {renderButton && user && user.STATUS === '1' && (
                <>
                  {aShow && (
                    <>
                      <Button
                        color="danger"
                        size="sm"
                        block
                        disabled={loading_user}
                        onClick={() => action('recusar', { CPFUSUARIO: user.CPFCNPJ, ERRO_STATUS })}
                      >
                        Recusar
                      </Button>
                      <Button
                        color="success"
                        size="sm"
                        block
                        disabled={loading_user}
                        onClick={() => {
                          validateUser();
                        }}
                      >
                        Aceitar
                      </Button>
                    </>
                  )}
                </>
              )}
              {!renderButton && (
                <>
                  <Button color="secondary" size="sm" block disabled={loading_user} onClick={() => back()}>
                    Voltar
                  </Button>
                  <Button
                    color="success"
                    size="sm"
                    block
                    disabled={loading_user}
                    onClick={() => action('recusar', { CPFUSUARIO: user.CPFCNPJ, ERRO_STATUS })}
                  >
                    Confirmar
                  </Button>
                </>
              )}
            </>
          )}

          <ModalMensagem
            modal={messageModal.open}
            toggle={() => setModalMessage({ open: false, message: '' })}
            sucesso={messageModal.success}
            mensagem={messageModal.message}
          />
        </ModalFooter>
      </Modal>
      <ModalReprovacao
        open={denyClient}
        toggle={() => {
          setDenyClient(false);
          setRenderButton(true);
        }}
      />

      <ModalBloquear
        isOpen={modalUnblockUser}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={() => setModalUnblockUser(false)}
        userData={user}
      />
    </>
  );
};

export default ModalCliente;
