import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import api from '../../../service/api';
import AnimatedLoading from '../../../components/Loader/AnimatedLoading';
import { useDispatch } from 'react-redux';
import ModalMensagem from '../component/ModalMensagem';
import { tedNegaded } from '../../../store/transactions/actions';

const ModalReproveTed = ({ open, toggle, callback, id_ted }) => {
  const dispatch = useDispatch();
  const [mensagens, setMensagens] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [codeBacen, setCodeBacen] = useState({ label: '', id: null });
  const [modalMensagem, setModalMensagem] = useState({
    open: false,
    mensagem: '',
    tipo: '',
    sucesso: true,
  });

  useEffect(() => {
    setCodeBacen({ label: '', id: null });
    api.get('/estorno/mensagens').then(({ data }) => setMensagens(data.data));
  }, [open]);

  const openModalMessage = (res, tipo) => {
    const { mensagem, retorno } = res;

    setModalMensagem({
      open: true,
      mensagem,
      tipo,
      sucesso: retorno && retorno == 'sucesso',
    });
  };

  async function deny() {
    await dispatch(
      tedNegaded(
        {
          ID: id_ted,
          COD_ESTORNO: codeBacen.id,
          CPFCNPJ_COBAN: localStorage.getItem('CPFCNPJ'),
        },
        (res) => {
          setLoaded(false);
          callback();
          openModalMessage(res.data, 'Reprovado');
        },
      ),
    );
  }

  function handleToggle() {
    toggle();
    setCodeBacen({ label: '', id: null });
  }

  return (
    <>
      <Modal isOpen={open} toggle={handleToggle}>
        <ModalHeader toggle={handleToggle}>
          <h3>Motivo da reprovação</h3>
        </ModalHeader>
        <ModalBody>
          <Autocomplete
            disablePortal
            sx={{ maxWidth: '100%' }}
            options={
              mensagens &&
              mensagens.length > 0 &&
              mensagens.map((mensagem) => ({
                label: `${mensagem.CODE_BACEN} - ${mensagem.DESCRICAO}`,
                id: +mensagem.CODE_BACEN,
              }))
            }
            label="Motivo"
            value={codeBacen ? codeBacen.label : ''}
            onChange={(event, newValue) => setCodeBacen(newValue)}
            renderInput={(params) => <TextField {...params} label="Motivo" />}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" size="sm" block onClick={handleToggle}>
            Cancelar
          </Button>
          <Button
            color="success"
            size="sm"
            block
            onClick={() => {
              deny();
              setLoaded(true);
            }}
          >
            Reprovar
          </Button>
        </ModalFooter>
      </Modal>
      <AnimatedLoading isActive={loaded} />

      <ModalMensagem
        modal={modalMensagem.open}
        mensagem={modalMensagem.mensagem}
        sucesso={modalMensagem.sucesso}
        tipo={modalMensagem.tipo}
        toggle={() => setModalMensagem({ open: false, mensagem: '', tipo: '', sucesso: true })}
      />
    </>
  );
};

export default ModalReproveTed;
