import { Icon } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Input, Label } from 'reactstrap';
import GenericButton from '../../../components/Buttons/Button';
import InputDate from '../../../components/Filter/components/InputDate';
import InputSearch from '../../../components/Filter/components/InputSearch';
import InputSelectAmountResults from '../../../components/Filter/components/InputSelectAmountResults';
import Loading from '../../../components/Loader/Loading';
import CustomTable from '../../../components/Table/Table';
import api from '../../../service/api';
import { formatCNPJ, formatCPF } from '../../../utils';
import { formatDateBR } from '../../../utils/dateKyc';
import { moneyMask } from '../../../utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import InputWithSearch from '../../../components/Filter/components/inputWithSearch';
import { getTransactionsType } from '../../../store/transactions/actions';
import isCpf from '../../../utils/isCpf';

export default function Accounts() {
  const [accounts, setAccounts] = useState(false);
  const [pagination, setPagination] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState(1);
  const [select2, setSelect2] = useState(false);
  const { parceiro } = useSelector((state) => state.transactions);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTransactionsType());
  }, []);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    cpfcnpj: '',
    nome: '',
    descricao: '',
    conta: '',
    data_inicial: moment().format('YYYY-MM-DD'),
    data_final: moment().format('YYYY-MM-DD'),
  });

  function getValues(filter) {
    let queryParams = '';
    for (const [key, value] of Object.entries(filter)) {
      queryParams += `&${key}=${value}`;
    }
    return queryParams.replace('&', '?');
  }

  useEffect(() => {
    search();
  }, []);

  function search() {
    let changeFilter = filter;
    changeFilter.page = 1;

    changeFilter.data_inicial_abertura = '';
    changeFilter.data_final_abertura = '';
    changeFilter.data_inicial_bloqueio = '';
    changeFilter.data_final_bloqueio = '';
    changeFilter.data_inicial_encerramento = '';
    changeFilter.data_final_encerramento = '';

    if (dateFilter == 1) {
      changeFilter.data_inicial_abertura = filter.data_inicial;
      changeFilter.data_final_abertura = filter.data_final;
    }

    if (dateFilter == 2) {
      changeFilter.data_inicial_bloqueio = filter.data_inicial;
      changeFilter.data_final_bloqueio = filter.data_final;
    }

    if (dateFilter == 3) {
      changeFilter.data_inicial_encerramento = filter.data_inicial;
      changeFilter.data_final_encerramento = filter.data_final;
    }

    getAccounts(changeFilter);
  }

  function chooseDateFilter(val) {
    setDateFilter(val);
  }

  let { page } = filter;

  const tablePagination = async (type = 'atual', pageClick) => {
    switch (type) {
      case 'prev':
        page = pagination.prevPage;
        break;
      case 'next':
        page = pagination.nextPage;
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }
    setFilter({ ...filter, page });
  };

  function getAccounts() {
    setLoading(true);
    let params = getValues(filter);
    api
      .get(`/contabil/contas${params}`)
      .then(({ data }) => {
        if (data.retorno === 'sucesso') {
          setAccounts(data.data);
          setPagination(data.pagination);
        }
      })
      .finally(() => setLoading(false));
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          alignItems: 'flex-end',
          gap: '1rem',
          marginTop: '4rem',
        }}
      >
        <div>
          <Label size="sm">Por período</Label>
          <Input
            onChange={(e) => chooseDateFilter(e.target.value)}
            type="select"
            name="date"
            id="date-account"
            size="sm"
            value={filter.company}
            style={{ width: '8rem' }}
          >
            <option value={1}>Data abertura</option>
            <option value={2}>Data bloqueio</option>
            <option value={3}>Data encerramento</option>
          </Input>
        </div>

        {dateFilter == 1 && (
          <>
            <InputDate
              label="Data Inicial Abertura"
              name="DATA INICIO"
              value={filter.data_inicial}
              setValue={(value) => setFilter({ ...filter, data_inicial: value })}
            />

            <InputDate
              label="Data Final Abertura"
              name="DATA FINAL"
              value={filter.data_final}
              setValue={(value) => setFilter({ ...filter, data_final: value })}
            />
          </>
        )}

        {dateFilter == 2 && (
          <>
            <InputDate
              label="Data Inicial Bloqueio"
              name="DATA INICIO"
              value={filter.data_inicial}
              setValue={(value) => setFilter({ ...filter, data_inicial: value })}
            />

            <InputDate
              label="Data Final Bloqueio"
              name="DATA FINAL"
              value={filter.data_final}
              setValue={(value) => setFilter({ ...filter, data_final: value })}
            />
          </>
        )}

        {dateFilter == 3 && (
          <>
            <InputDate
              label="Data Inicial Encerramento"
              name="DATA INICIO"
              value={filter.data_inicial}
              setValue={(value) => setFilter({ ...filter, data_inicial: value })}
            />

            <InputDate
              label="Data Final Encerramento"
              name="DATA FINAL"
              value={filter.data_final}
              setValue={(value) => setFilter({ ...filter, data_final: value })}
            />
          </>
        )}

        <InputSearch
          label={'Pesquisa'}
          placeholder="Nome ou CPF/CNPJ"
          width="10rem"
          value={filter.cpf}
          setValue={(value) => setFilter({ ...filter, cpfcnpj: value })}
        />

        <InputWithSearch
          label={'Parceiro'}
          removeLabel={true}
          setValue={(value) => setFilter({ ...filter, descricao: value })}
          options={parceiro && ['Todos', ...parceiro.map((part) => part.DESCRICAO)]}
        />

        <InputSearch
          label={'Conta'}
          placeholder="Conta"
          width="10rem"
          value={filter.conta}
          setValue={(value) => setFilter({ ...filter, conta: value })}
        />

        {/*   <InputSelectAmountResults
          options={['5', '10', '25', '50', '100', '1000']}
          column={true}
          value={filter.limit}

          setValue={(value) => setFilter({ ...filter, limit: value })}
        /> */}

        <GenericButton onClick={(e) => search()} height="30px" className="mt-3" size="sm" type="submit">
          <Icon style={{ marginRight: '.4rem', fontSize: '1rem' }} className="fas fa-search"></Icon>Buscar
        </GenericButton>

        <ReactHTMLTableToExcel
          id="test-table-xls-button-accounts"
          className="export-to-excel-button button-height-30px"
          table="table-to-xls"
          filename="accounts"
          sheet="tablexls"
          buttonText="Export"
        />
      </div>

      <div style={{ display: 'block', marginTop: '1rem' }}>
        <InputSelectAmountResults
          options={['5', '10', '25', '50', '100', '1000']}
          column={true}
          value={filter.limit}
          setValue={(value) => setFilter({ ...filter, limit: value })}
        />
      </div>

      <div style={{ marginTop: '1rem' }}>
        {!loading ? (
          <CustomTable
            headers={[
              {
                info: 'DATA ABERTURA',
                style: { textAlign: 'center' },
              },
              {
                info: 'DATA BLOQUEIO',
                style: { textAlign: 'center' },
              },
              {
                info: 'DATA ENCERRAMENTO',
                style: { textAlign: 'center' },
              },
              {
                info: 'CPF/CNPJ',
                style: { textAlign: 'center' },
              },
              {
                info: 'NOME',
                style: { textAlign: 'center' },
              },
              {
                info: 'PARCEIRO',
                style: { textAlign: 'center' },
              },
              {
                info: 'CONTA',
                style: { textAlign: 'center' },
              },
              {
                info: 'SALDO ATUAL',
                style: { textAlign: 'center' },
              },
              {
                info: 'SALDO BLOQUEADO',
                style: { textAlign: 'center' },
              },
              {
                info: 'MONTANTE ENTRADA',
                style: { textAlign: 'center' },
              },
              {
                info: 'MONTANTE SAÍDA',
                style: { textAlign: 'center' },
              },
              {
                info: 'MONTANTE GASTO',
                style: { textAlign: 'center' },
              },
              {
                info: 'DIFERENÇA',
                style: { textAlign: 'center' },
              },
            ]}
            data={
              accounts &&
              accounts.map((item, index) => {
                return {
                  data: [
                    {
                      info: formatDateBR(item.DATA_ABERTURA),
                      style: { textAlign: 'center' },
                    },
                    {
                      info: item.DT_BLOQUEIO ? formatDateBR(item.DT_BLOQUEIO) : 'Não bloqueada',
                      style: { textAlign: 'center' },
                    },
                    {
                      info: item.DATA_ENCERRAMENTO ? formatDateBR(item.DATA_ENCERRAMENTO) : 'Não encerrada',
                      style: { textAlign: 'center' },
                    },
                    {
                      info: item.CPFCNPJ
                        ? isCpf(item.CPFCNPJ)
                          ? formatCPF(item.CPFCNPJ)
                          : formatCNPJ(item.CPFCNPJ)
                        : null,
                      style: { textAlign: 'center' },
                    },
                    {
                      info: item.NOME,
                      style: { textAlign: 'center' },
                    },
                    {
                      info: item.DESCRICAO,
                      style: { textAlign: 'center' },
                    },
                    {
                      info: item.CONTA,
                      style: { textAlign: 'center' },
                    },
                    {
                      info: moneyMask(item.SALDO_ATUAL),
                      style: { textAlign: 'center' },
                    },
                    {
                      info: moneyMask(item.SALDO_BLOQUEADO),
                      style: { textAlign: 'center' },
                    },
                    {
                      info: item.MONTANTE_ENTRADA ? moneyMask(item.MONTANTE_ENTRADA) : 'R$0,00',
                      style: { textAlign: 'center' },
                    },
                    {
                      info: item.MONTANTE_SAIDA ? item.MONTANTE_SAIDA : 'R$0,00',
                      style: { textAlign: 'center' },
                    },
                    {
                      info: item.MONTANTE_GASTO ? moneyMask(item.MONTANTE_GASTO) : 'R$0,00',
                      style: { textAlign: 'center' },
                    },
                    {
                      info: item.DIFERENCA ? moneyMask(item.DIFERENCA) : 'R$0,00',
                      style: { textAlign: 'center' },
                    },
                  ],
                };
              })
            }
            pagination={pagination}
            previousPage={{ onClick: () => tablePagination('prev') }}
            nowPage={{ page, onClick: pagination }}
            nextPage={{ onClick: () => tablePagination('next') }}
          />
        ) : (
          <Loading color="blue" />
        )}
      </div>
    </div>
  );
}
