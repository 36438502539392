import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { ModalBody } from 'reactstrap';
import Button from '../../../components/Button/Button.style';
import DateInput from '../../../components/DateInput/DateInput';
import api from '../../../service/api';
import * as Styled from './ModalSaldo.styles';
import operateBalanceValidation from './validation/operateBalance.validation';

const ModalSaldoContaCartao = (props) => {
  const [valor, setValor] = useState('');
  const [description, setDescription] = useState('');

  const {
    modal,
    toggle,
    dados,
    option,
    CPFCNPJ_USUARIO,
    dadoCPFCNPJ,
    cardAccount,
    setModalMensagemRecarga,
    setMensagemRecarga,
    setReload,
    reload,
  } = props;

  const {
    handleSubmit: handleOperateBalance,
    control: control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(operateBalanceValidation),
  });

  useEffect(() => {
    setValor('');
    setDescription('');
  }, [toggle]);

  function changePaymentValue(value) {
    setValor(value.replaceAll('.', '').replace(',', '.').replace('R$\u00a0', ''));
  }

  function currencyFormatter(value) {
    if (!Number(value)) return '';
    const amount = new Intl.NumberFormat('pt-br', {
      style: 'currency',
      currency: 'BRL',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(value / 100);

    return `${amount}`;
  }

  const getDate = () => {
    let date = new Date().toISOString().slice(0, 10);
    return date;
  };

  function getdoubleValue(value) {
    value = value.replaceAll('R$', '').trim();
    value = value.replaceAll(',', '.').trim();
    return parseFloat(value);
  }

  const openModalPassword = (data) => {
    data.DESCRICAO = description;

    data.ID_CONTA_CARTAO = +cardAccount[0].uuid;

    data.OPERATE = true;
    /*     setIsModalPasswordOpen(true); */
  };

  function credito() {
    const data = {
      DESCRICAO: description,
      VALOR: getdoubleValue(valor),
      ID_CONTA_CARTAO: cardAccount[0].uuid,
    };

    api.post(`admin/conta-cartao/operar-saldo/credito`, data).then((res) => {
      if (res.data.retorno == 'sucesso') {
        setModalMensagemRecarga(true);
        setMensagemRecarga('Crédito realizado com sucesso');
        toggle();
        setReload(!reload);
      } else {
        setModalMensagemRecarga(true);
      }
    });
  }

  function debito() {
    const data = {
      DESCRICAO: description,
      VALOR: getdoubleValue(valor),
      ID_CONTA_CARTAO: cardAccount[0].uuid,
    };

    api.post(`admin/conta-cartao/operar-saldo/debito`, data).then((res) => {
      if (res.data.retorno == 'sucesso') {
        setModalMensagemRecarga(true);
        setMensagemRecarga('Débito realizado com sucesso');
        toggle();
        setReload(!reload);
      } else {
        setModalMensagemRecarga(true);
      }
    });
  }

  return (
    <Styled.ModalSaldo isOpen={modal}>
      <ModalBody>
        <Styled.Container>
          <Styled.ContainerBox>
            <div className="titleBox">Operar Saldo Conta Cartão</div>
            <Styled.Form onSubmit={handleOperateBalance(openModalPassword)}>
              <NumberFormat
                label="Valor"
                decimalSeparator=","
                thousandsSeparator="."
                fixedDecimalScale={true}
                name="valorMin"
                value={valor}
                variant="standard"
                customInput={TextField}
                onValueChange={(event) => {
                  const { formattedValue } = event;
                  changePaymentValue(formattedValue);
                }}
                format={currencyFormatter}
              />
              <DateInput
                label="Data"
                name={'DATA'}
                margin={'1vw 0 1vw 0'}
                width={'29.706vw'}
                disabled={true}
                value={getDate()}
                control={control}
              />
              <TextField
                label="Descrição"
                name={'DESCRICAO'}
                margin={'0 0 1vw 0'}
                multiline
                variant="standard"
                type={'text'}
                control={control}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
              <div className="bottons">
                <Button
                  backgroundColor={'#0CB42A'}
                  width="9.306vw"
                  margin={'2vw 0 0 0'}
                  borderColor={'#0CB42A'}
                  color={'white'}
                  type="submit"
                  disabled={description.length === 0}
                  onClick={() => {
                    credito();
                  }}
                >
                  {'Adicionar'}
                </Button>
                <Button
                  backgroundColor={'#EA6852'}
                  width="9.306vw"
                  margin={'2vw 0 0 0'}
                  borderColor={'#EA6852'}
                  color={'white'}
                  type="submit"
                  disabled={description.length === 0}
                  onClick={() => {
                    debito();
                  }}
                >
                  {'Debitar'}
                </Button>
                <Button
                  backgroundColor={'#2405F2'}
                  width="9.306vw"
                  margin={'2vw 0 0 0'}
                  borderColor={'#2405F2'}
                  color={'white'}
                  onClick={() => {
                    toggle();
                  }}
                  block
                >
                  {'Voltar'}
                </Button>
              </div>
            </Styled.Form>

            {/* <ModalSms
                modal={isModalPasswordOpen}
                toggle={() => closeModalPassword()}
                valor={operateBalanceData.VALOR}
                dadoCPFCNPJ={dadoCPFCNPJ}
                transacao={operateBalanceData.TIPO}
                descricao={operateBalanceData.DESCRICAO}
                marginTop={'27.2315vh'}
              /> */}
            {/*       <ModalPassword
              modal={isModalPasswordOpen}
              toggle={() => closeModalPassword()}
              valor={operateBalanceData.VALOR}
              dadoCPFCNPJ={dadoCPFCNPJ}
              transacao={operateBalanceData.TIPO}
              descricao={operateBalanceData.DESCRICAO}
              marginTop={'27.2315vh'}
            /> */}
          </Styled.ContainerBox>
        </Styled.Container>
      </ModalBody>
    </Styled.ModalSaldo>
  );
};

export default ModalSaldoContaCartao;
