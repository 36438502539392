import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button, ModalHeader, ModalFooter, Input } from 'reactstrap';
import { bloqueio, credito, debito } from '../../../store/transactions/actions';
import ModalMensagem from '../../../views/Transfer/component/ModalMensagem';
import { useDispatch } from 'react-redux';
import { arredondarCasasDecimaisString } from '../../../utils/arredondarCasasDecimais';
import { getBalanceHistoryByUUID } from '../../../store/user/actions';
import moment from 'moment';
import { Box, CircularProgress } from '@material-ui/core';

const PassWordConfirmation = ({ modal, toggle, colorButton, fontColorButton, textTransform, fontWeightButton, marginTop, balance }) => {
  const dispatch = useDispatch();
  const [code, setCode] = useState('');
  const [modals, setModals] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [codigoErro, setCodigoErro] = useState(false);
  const [sucesso, setSucesso] = useState(false);
  const [loading, setLoading] = useState(false);

  const openModalMensagem = (res) => {
    const { mensagem, retorno } = res;
    retorno == 'erro' ? setSucesso(false) : setSucesso(true);
    setModals(true);
    setMensagem(mensagem);
  };

  const operateFunctions = {
    Adicionar: (param) =>
      dispatch(
        credito(param, (res) => {
          openModalMensagem(res);
        }),
      ),
    Debitar: (param) =>
      dispatch(
        debito(param, (res) => {
          openModalMensagem(res);
        }),
      ),
    Bloquear: (param) =>
      dispatch(
        bloqueio(param, (res) => {
          openModalMensagem(res);
        }),
      ),
    Desbloquear: (param) =>
      dispatch(
        bloqueio(param, (res) => {
          openModalMensagem(res);
        }),
      )
  };

  const operator = (transacao, objTransaction) => {
    setLoading(true);
    operateFunctions[transacao](objTransaction);
  };

  const objTransaction = {
    ...balance,
    VALOR: arredondarCasasDecimaisString(balance.VALOR),
    SENHA: code,
  };

  return (
    <div>
      <ModalMensagem
        modal={modals}
        toggle={() => {
          setModals(!modals);
          dispatch(
            getBalanceHistoryByUUID(objTransaction.CONTA, {
              LIMIT: '',
              DATA_INICIO: moment().add(-7, 'days').format('YYYY-MM-DD'),
              DATA_FIM: moment().format('YYYY-MM-DD'),
              PAGE: 1,
              SIZE: 10,
            }),
          );
          toggle();
        }}
        mensagem={mensagem}
        sucesso={sucesso}
        marginTop={'27.2315vh'}
        marginLeft={'12rem'}
        callback={() => {
          setLoading(false);
          toggle();
        }}
      />
      <Modal isOpen={modal} style={{ marginTop: marginTop }}>
        <ModalHeader toggle={toggle}>
          <h3>Digite a sua senha:</h3>
        </ModalHeader>
        <ModalFooter style={{ display: 'flex', flexDirection: 'column' }}>
          <Input
            type="password"
            placeholder={'Senha: '}
            onChange={(e) => {
              setCode(e.target.value);
              setCodigoErro(false);
            }}
            style={({ marginBottom: '16px' }, codigoErro ? { borderColor: 'red' } : {})}
          ></Input>
          {codigoErro && (
            <span style={{ fontSize: '12px', color: 'red', textAlign: 'center' }}>Digite a sua senha</span>
          )}
          {loading ? (
            <Box mt={"10%"}>
              <CircularProgress />
            </Box>
          ) : (
            <Button
              color="success"
              size="sm"
              block
              onClick={() => operator(balance.TIPO, objTransaction)}
              style={{
                background: colorButton && colorButton,
                borderColor: colorButton && colorButton,
                color: fontColorButton && fontColorButton,
                textTransform: textTransform && textTransform,
                fontWeight: fontWeightButton && fontWeightButton,
                marginTop: '10%',
              }}
            >
              Continuar
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PassWordConfirmation;
