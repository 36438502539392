import React, { useEffect, useState } from "react";
import { Box, Icon, MenuItem, TextField } from "@mui/material";
import GenericButton from "../../components/Buttons/Button";
import Loading from "../../components/Loader/Loading";
import { formatCNPJ, formatCPF, formatDateBR, isCpf } from "../../utils";
import Table from '../../components/Table/Table.jsx';
import { useForm } from "react-hook-form";
import moment from "moment";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import api from "../../service/api";
import ModalDetailedCard from "./Modal/ModalDetailedCard.jsx";

export default function IssuedCards() {

    const [loading, setLoading] = useState(false);
    const [cards, setCards] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState({});
    const [modal, setModal] = useState({ open: false, id: '' });

    const {
        handleSubmit,
        getValues,
        register,
        reset
    } = useForm({
        defaultValues: {
            PAGE: 1,
            LIMIT: 10,
            SEARCH: '',
            STATUS: 'Todos',
            ACTIVE: 'Todos',
            NOMINAL: 'Todos',
            INITIAL_DATE: moment().subtract(1, 'M').format('YYYY-MM-DD'),
            FINAL_DATE: moment().format('YYYY-MM-DD'),
        }
    });

    useEffect(() => {
        setLoading(true);
        getCards();
        return () => reset();
    }, []);

    function cleanFilters(filters) {
        Object.entries(filters).forEach((filter) => {
            filter[1] === 'Todos' && delete filters[filter[0]];
        });
        return filters;
    }

    function getCards() {
        setLoading(true);
        api
            .post('/admin/cartao/listar', cleanFilters(getValues()))
            .then(({ data: { data, pagination } }) => {
                setCards(data);
                setPaginationInfo(pagination);
            })
            .finally(() => setLoading(false));
    }

    return (
        <Box mt={1}>
            <Box mb={2}>
                <form
                    onSubmit={handleSubmit(getCards)}
                    style={{
                        display: 'flex',
                        flexFlow: 'column',
                        width: '100%',
                        justifyContent: 'flex-start',
                        gap: '1rem',
                        margin: '2rem 0',
                    }}
                >
                    <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} sx={{ gap: '10px' }}>
                        <TextField
                            {...register("SEARCH")}
                            autoFocus
                            label="Pesquisa"
                            placeholder="Nome, Conta Bancária ou CPF/CNPJ"
                            variant="outlined"
                            size='small'
                            sx={{ width: '20rem' }}
                        />
                        <TextField
                            {...register("STATUS")}
                            select
                            label="Status"
                            defaultValue='Todos'
                            variant="outlined"
                            size='small'
                            sx={{ width: '15rem' }}
                        >
                            <MenuItem key={'todos'} value={'Todos'}>
                                Todos
                            </MenuItem>
                            <MenuItem key={'active'} value={'active'}>
                                Ativo
                            </MenuItem>
                            <MenuItem key={'blocked'} value={'blocked'}>
                                Bloqueado
                            </MenuItem>
                        </TextField>

                        <TextField
                            {...register("ACTIVE")}
                            select
                            label="Ativo"
                            defaultValue='Todos'
                            variant="outlined"
                            size='small'
                            sx={{ width: '15rem' }}
                        >
                            <MenuItem key={'todos'} value={'Todos'}>
                                Todos
                            </MenuItem>
                            <MenuItem key={'active'} value={'S'}>
                                Ativo
                            </MenuItem>
                            <MenuItem key={'blocked'} value={'N'}>
                                Desativado
                            </MenuItem>
                        </TextField>

                        <TextField
                            {...register("NOMINAL")}
                            select
                            label="Nominal"
                            defaultValue='Todos'
                            variant="outlined"
                            size='small'
                            sx={{ width: '15rem' }}
                        >
                            <MenuItem key={'todos'} value={'Todos'}>
                                Todos
                            </MenuItem>
                            <MenuItem key={'active'} value={'S'}>
                                Nominal
                            </MenuItem>
                            <MenuItem key={'blocked'} value={'N'}>
                                Não Nominal
                            </MenuItem>
                        </TextField>

                        <TextField
                            label="Data Inicial"
                            {...register("INITIAL_DATE")}
                            type='date'
                            variant="outlined"
                            size='small'
                            sx={{ width: '15rem' }}
                        />

                        <TextField
                            label="Data Final"
                            {...register("FINAL_DATE")}
                            type='date'
                            variant="outlined"
                            size='small'
                            sx={{ width: '15rem' }}
                        />

                        <GenericButton height="40px" width="140px" type="submit">
                            <Icon style={{ marginRight: '.4rem', fontSize: '1rem' }} className="fas fa-search" />
                            Buscar
                        </GenericButton>

                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="custom-export-to-excel-button"
                            table="table-to-xls"
                            filename="tablexls"
                            sheet="tablexls"
                            buttonText="Export"
                        />
                    </Box>

                    <Box display={'flex'} flexDirection={'row'} sx={{ gap: '1rem' }}>
                        <TextField
                            {...register("LIMIT")}
                            select
                            label="Limite"
                            variant="outlined"
                            size='small'
                            defaultValue="10"
                            sx={{ width: '10rem' }}
                        >
                            {['5', '10', '25', '50', '100', '1000'].map((label, index) => (
                                <MenuItem key={index} value={label}>
                                    {label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <span style={{ fontSize: 'small' }}>Resultados por página</span>
                    </Box>
                </form>
            </Box >
            {
                loading ? (
                    <Loading color="blue" />
                ) : (
                    <Table
                        headers={[
                            {
                                info: 'DATA/HORA',
                                style: { textAlign: 'left' },
                            },
                            {
                                info: 'CPF/CNPJ',
                                style: { textAlign: 'left' },
                            },
                            {
                                info: 'NOME',
                                style: { textAlign: 'left' },
                            },
                            {
                                info: 'PAN MASKED',
                                style: { textAlign: 'left' },
                            },
                            {
                                info: 'NOMINAL',
                                style: { textAlign: 'left' },
                            },
                            {
                                info: 'STATUS',
                                style: { textAlign: 'left' },
                            },
                            {
                                info: 'ATIVO',
                                style: { textAlign: 'left' },
                            }
                        ]}
                        data={
                            cards &&
                            cards.map((sale) => {
                                return {
                                    data: [
                                        {
                                            info: formatDateBR(sale.ISSUANCE_DATE),
                                            style: { textAlign: 'left' },
                                        },
                                        {
                                            info:
                                                sale.CPFCNPJ && isCpf(sale.CPFCNPJ)
                                                    ? formatCPF(sale.CPFCNPJ)
                                                    : formatCNPJ(sale.CPFCNPJ),
                                            style: { textAlign: 'left' },
                                        },
                                        {
                                            info: sale.NAME,
                                            style: { textAlign: 'left' },
                                        },
                                        {
                                            info: sale.PAN_MASKED,
                                            style: { textAlign: 'left' },
                                        },
                                        {
                                            info: sale.NOMINAL === 'S' ? 'NOMINAL' : 'NÃO NOMINAL',
                                            style: { textAlign: 'left' },
                                        },
                                        {
                                            info: sale.STATUS,
                                            style: { textAlign: 'left' },
                                        },
                                        {
                                            info: sale.ACTIVE === 'S' ? 'ATIVO' : 'DESATIVADO',
                                            style: { textAlign: 'left' },
                                        }
                                    ],
                                    onClick: () => setModal({ open: true, uuid: sale.UUID })
                                };
                            })
                        }
                        pagination={paginationInfo}
                        previousPage={{ onClick: () => getCards({ ...getValues(), PAGE: paginationInfo.prevPage }) }}
                        nowPage={{ PAGE: paginationInfo.page, onClick: () => getCards({ ...getValues(), PAGE: paginationInfo.page }) }}
                        nextPage={{ onClick: () => getCards({ ...getValues(), PAGE: paginationInfo.nextPage }) }}
                    />
                )}
            <ModalDetailedCard
                toggle={() => setModal({ open: !modal.open, id: '' })}
                modal={modal.open}
                title={'Dados do cartão'}
                code={modal.uuid}
            />
        </Box >
    );
}