import React, { useEffect, useRef } from 'react';
import { Input, Label } from 'reactstrap';
import * as Styled from '../styles/Filter.style';

const InputSearch = ({ width, setValue, value, placeholder = '', label, disabled = false }) => {
  const inputElement = useRef(null);
  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  return (
    <Styled.Search width={width}>
      <Label id="label" size="sm">
        {label ? label : 'Pesquisa'}
      </Label>
      <Input
        ref={inputElement}
        id="input"
        autoFocus
        type="text"
        name="search"
        size="sm"
        value={value}
        placeholder={placeholder ? placeholder : 'Nome, Conta Bancária ou CPF/CNPJ'}
        onChange={(event) => setValue(event.target.value)}
        disabled={disabled}
      />
    </Styled.Search>
  );
};

export default InputSearch;
