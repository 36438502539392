import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ModalMensagemSemReload from '../../../views/Transaction/ModalMensagemSemReload';
import { msBaasAccount, msBaasApproveCardUser, msBaasReceiverBoleto, msBaasStatusAccount } from '../../../store/user/actions';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';

function ModalEnviarParaBaas({ open, toggle, header, body, type, requestBody }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [modalMensagem, setModalMensagem] = useState({
    modal: false,
    mensagem: '',
    sucesso: false
  });

  function callbackMsBass({retorno, mensagem}) {
    setModalMensagem({
      modal: true,
      mensagem,
      sucesso: retorno === "sucesso" ? true : false
    });
  }

  function onConfirm() {
    setLoading(true);
    switch(type) {
      case "account":
        dispatch(msBaasAccount(requestBody, callbackMsBass));
        break;
      case "receiver boleto":
        dispatch(msBaasReceiverBoleto(requestBody, callbackMsBass));
        break;
      case "status account":
        dispatch(msBaasStatusAccount(requestBody, callbackMsBass));
        break;
      case "approve card user":
        dispatch(msBaasApproveCardUser(requestBody, callbackMsBass));
        break;
      default:
        break;    
    }
  }

  return (
    <div>
      <Modal isOpen={open} toggle={toggle} >
        <ModalHeader toggle={toggle}>
          <h1>{header}</h1>
        </ModalHeader>
        <ModalBody>
          <span>{body}</span>
        </ModalBody>
        <ModalFooter>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Button
                  style={{ marginTop: '8px' }}
                  color="secondary"
                  size="sm"
                  onClick={toggle}
                  block
                >
                  Cancelar
                </Button>
                <Button
                  color="success"
                  size="sm"
                  onClick={onConfirm}
                  block
                >
                  Confirmar
                </Button>
              </>
            )}
          </div>
        </ModalFooter>
      </Modal>

      <ModalMensagemSemReload
        toggle={() => {
          toggle();
          setModalMensagem({...modalMensagem, modal: false});
        }}
        {...modalMensagem}
      />
    </div>
  );
};

export default ModalEnviarParaBaas;
