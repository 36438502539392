import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Label, Form } from 'reactstrap';
import Loading from '../../components/Loader/Loading.jsx';
import Table from '../../components/Table/Table.jsx';
import CustomContainer from '../../components/Custom/CustomContainer';
import formatCPF from '../../utils/formatCPF';
import { formatMoney } from '../../utils/formatMoneyBRL';
import { getDigitalService, getStatusTransacao } from '../../store/transactions/actions';
import valueEmpty from '../../utils/valueEmpty';
import StatusContainer from '../../components/StatusContainer/StatusContainer';
import ModalDigitalServices from './components/ModalDigitalServices';
import InputDate from '../../components/Filter/components/InputDate.js';
import Filter from '../../components/Filter/Filter.js';
import * as Styled from '../../components/Filter/styles/Filter.style';
import InputSelectAmountResults from '../../components/Filter/components/InputSelectAmountResults.js';
import InputSearch from '../../components/Filter/components/InputSearch.js';
import moment from 'moment';
import addStatusStyle from './utils/verifyStatus';
import InputStatus from '../../components/Filter/components/InputStatus.js';
import formatTextCamelCase from '../../utils/formatTextCamelCase.js';
import GenericButton from '../../components/Button/Button.style.js';
import Icon from '../../components/Icon/Icon.styles.js';

export default function DigitalServices() {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [serviceData, setServiceData] = useState({});

  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
    search: '',
    dataFinal: moment().format('YYYY-MM-DD'),
    dataInicial: '',
    status: '',
    transacao: '',
    servicos_digitais: '',
    tipo_pagamento: '',
  });

  function clickInfo(transaction) {
    setModalOpen(true);
    setServiceData(transaction);
  }

  function close() {
    setModalOpen(false);
  }

  useEffect(() => {
    dispatch(getDigitalService(filter));
    dispatch(getStatusTransacao());
  }, [filter.page]);

  const {
    digitalServices: { data, pagination, VALOR_TOTAL },
    loading_transaction,
    status_transaction,
    idCodes,
  } = useSelector((state) => state.transactions);

  let { page } = filter;

  const tablePagination = async (type = 'atual', pageClick) => {
    switch (type) {
      case 'prev':
        page = pagination.prevPage;
        break;
      case 'next':
        page = pagination.nextPage;
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }
    await setFilter({ ...filter, page });
  };

  return (
    <div>
      <CustomContainer>
        <Filter>
          <Form className="form" onSubmit={(e) => e.preventDefault()}>
            <Styled.InputsContainer>
              <InputSearch
                width="25vw"
                value={filter.search}
                setValue={(value) => setFilter({ ...filter, search: value })}
              />

              <InputStatus
                value={filter.status}
                setValue={(value) => setFilter({ ...filter, status: value })}
                options={status_transaction}
                codeValue={idCodes}
              />

              <div>
                <Label size="sm">{'Serviços Digitais'}</Label>
                <Input
                  onChange={(event) =>
                    setFilter({
                      ...filter,
                      servicos_digitais: event.target.value,
                    })
                  }
                  type="select"
                  name="servicos_digitais"
                  id="servicos_digitais"
                  size="sm"
                >
                  <option value="">Todos</option>
                  <option value="7">Recarga De Celular</option>
                  <option value="8">Serviços Digitais</option>
                  <option value="9">IFood</option>
                </Input>
              </div>

              <div>
                <Label size="sm">{'Forma Pagamento'}</Label>
                <Input
                  onChange={(event) =>
                    setFilter({
                      ...filter,
                      tipo_pagamento: event.target.value,
                    })
                  }
                  type="select"
                  name="tipo_pagamento"
                  id="tipo_pagamento"
                  size="sm"
                >
                  <option value="">Todos</option>
                  <option value="1">Cartão</option>
                  <option value="2">Conta Digital</option>
                </Input>
              </div>
              <InputDate
                label="Data Inicial"
                name="dataInicial"
                value={filter.dataInicial}
                setValue={(value) => setFilter({ ...filter, dataInicial: value })}
              />

              <InputDate
                label="Data Final"
                name="dataFinal"
                value={filter.dataFinal}
                setValue={(value) => setFilter({ ...filter, dataFinal: value })}
              />

              <div style={{ alignSelf: 'flex-end', marginTop: '10px' }}>
                <GenericButton
                  style={{ marginTop: '25px !important', width: '140px', heigth: '35px' }}
                  size="sm"
                  type="submit"
                  onClick={() => {
                    dispatch(getDigitalService(filter));
                    filter.page = '1';
                  }}
                >
                  <Icon className="fas fa-search" /> Buscar
                </GenericButton>
              </div>
            </Styled.InputsContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <InputSelectAmountResults
                options={['10', '25', '50', '100', '1000']}
                type="resultsPerPage"
                value={filter.limit}
                setValue={(value) => setFilter({ ...filter, limit: value })}
                width={'25rem'}
              />
              <h4>{'TOTAL PAGAMENTOS: R$ ' + formatMoney(VALOR_TOTAL)} </h4>
            </div>
          </Form>
        </Filter>

        {loading_transaction ? (
          <Loading color="#11cdef" />
        ) : (
          <Table
            style={{ textTransform: 'uppercase' }}
            headers={[
              {
                info: 'Data/Hora',
                style: { textAlign: 'center' },
              },
              {
                info: 'CPF/CNPJ',
                style: { textAlign: 'left' },
              },
              {
                info: 'NOME',
                style: { textAlign: 'left' },
              },
              {
                info: 'TIPO',
                style: { textAlign: 'left' },
              },
              {
                info: 'FORMA PAGAMENTO',
                style: { textAlign: 'left' },
              },
              {
                info: 'PARCEIRO',
                style: { textAlign: 'center' },
              },
              {
                info: 'VALOR PAGO',
                style: { textAlign: 'right' },
              },
              {
                info: 'produto',
                style: { textAlign: 'left' },
              },
              {
                info: 'status',
                style: { textAlign: 'left' },
              },
            ]}
            data={
              data &&
              data.map((transaction) => {
                return {
                  data: [
                    {
                      info: valueEmpty(transaction.DATA),
                      style: { textAlign: 'center' },
                    },
                    {
                      info: formatCPF(valueEmpty(transaction.CPFCNPJ)),
                      style: { textAlign: 'left' },
                    },
                    {
                      info: valueEmpty(transaction.NOME),
                      style: { textAlign: 'left' },
                    },
                    {
                      info: valueEmpty(transaction.CARACTERISTICA),
                      style: { textAlign: 'left' },
                    },
                    {
                      info: valueEmpty(transaction.TIPO_PAGAMENTO),
                      style: { textAlign: 'left' },
                    },
                    {
                      info: transaction.TIPO_USUARIO ? (
                        <StatusContainer
                          code={transaction.TIPO_USUARIO.toUpperCase()}
                          description={transaction.TIPO_USUARIO}
                        ></StatusContainer>
                      ) : (
                        '-----'
                      ),
                      style: { textAlign: 'center' },
                    },
                    {
                      info: 'R$ ' + formatMoney(valueEmpty(transaction.VALOR)),
                      style: { textAlign: 'right' },
                    },
                    {
                      info: valueEmpty(transaction.NOME_PRODUTO),
                      style: { textAlign: 'left' },
                    },
                    {
                      info: addStatusStyle(transaction.DESCRICAO_STATUS),
                      style: { textAlign: 'left' },
                    },
                  ],
                  onClick: () => clickInfo(transaction),
                };
              })
            }
            pagination={pagination}
            previousPage={{ onClick: () => tablePagination('prev') }}
            nowPage={{ page, onClick: pagination }}
            nextPage={{ onClick: () => tablePagination('next') }}
          />
        )}
        <ModalDigitalServices
          toggle={close}
          modal={modalOpen}
          title={'Dados do Pagamento'}
          data={serviceData}
        ></ModalDigitalServices>
      </CustomContainer>
    </div>
  ); //
}
