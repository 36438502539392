import React from 'react';
import AccordionContratos from './Accordions/AccordionsContratos';
import AccordionsConsentimento from './Accordions/AccordionsConsentimento';

const TabContratos = ({ contratos, dispatchContract, parceiro }) => {
  return (
    <>
      <AccordionContratos contratos={contratos} dispatchContract={dispatchContract} parceiro={parceiro} />
      <AccordionsConsentimento />
    </>
  );
};

export default TabContratos;
