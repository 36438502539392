import styled from 'styled-components';

export const DivTitleContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #9aa0a6;
  margin-top: 1rem;
`;

export const SpanTitle = styled.span`
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  color: #32325d;
  font-size: 0.8rem;
`;

export const DivCheckbox = styled.div`
  display: flex;
  gap: 1rem;
`;

export const ButtonTransparent = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
`;
