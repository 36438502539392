export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const GET_QRCODE_LOGIN = 'GET_QRCODE_LOGIN';
export const GET_QRCODE_LOGIN_SUCCESSS = 'GET_QRCODE_LOGIN_SUCCESS';
export const GET_QRCODE_LOGIN_FAIL = 'GET_QRCODE_LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const RESPONSE_AUTH = 'RESPONSE_AUTH';
export const RENOVATION_LOADING = 'RENOVATION_LOADING';
export const RENOVATION_SUCCESS = 'RENOVATION_SUCCESS';
export const RENOVATION_FAIL = 'RENOVATION_FAIL';
