import styled from 'styled-components';

export const HeaderStyleContainer = styled.div`
  display: flex;
  background-color: #f2f2f2;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;

export const HeaderTitle = styled.h1``;

export const HeaderSubTitle = styled.h2`
  display: flex;
  flex-direction: row;

  height: 13px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 112%;
  /* or 13px */

  letter-spacing: 0.25px;
  text-transform: uppercase;

  color: #262424;
`;

export const HeaderProduct = styled.div`
  color: #525f7f;
`;

export const SaldoUtilizadoColor = styled.div`
  color: #dc2d06;
  font-weight: 600;
  font-size: 1rem;
`;

export const SaldoDisponivelColor = styled.div`
  color: #1ba305;
  font-weight: 600;
  font-size: 1rem;
`;
