import Styled from 'styled-components';

export const Conteiner = Styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const SelectConteiner = Styled.div`
    padding-top: 1rem;
`;

export const CheckConteiner = Styled.div`
    padding-top: 1.3rem;

`;

export const ColorLeftContainer = Styled.div`
    padding-top: 1.3rem;
    width: 12rem;
    
`;

export const ColorRightContainer = Styled.div`
    padding-top: 1.3rem;
    
`;

export const Card = Styled.div` 
    background-color:${(props) => (props.color ? props.color : 'white')};
    height: 13rem;
    width: 12rem;
    border-radius: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
`;

export const CardUpside = Styled.div`
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const CardDownside = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 1rem;
`;

export const CardProduct = Styled.span`
       color:${(props) => (props.color ? props.color : 'blue')};
    font-size: 0.6rem;
    font-weight: 600;
    margin-top: 0.4rem;
 
`;

export const CardDownSideText = Styled.span`
    font-size: .7rem;
    color:${(props) => (props.color ? props.color : 'blue')};
`;

export const Column = Styled.div`
    display: flex;
    flex-direction: column;
    font-size: .8rem;
`;

export const CardHeader = Styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: -2rem;
    margin-bottom: 2rem;
    padding: 0 0.5rem 0 1rem;

`;
