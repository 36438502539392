import { TabPanel } from '@material-ui/lab';
import { Box, Card, Paper, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import Modelo4111 from '../../../components/Modais/Modelo4111/Modelo4111';
import Modelo1201 from '../../../components/Modais/Modelo1201/Modelo1201';

export default function GenerateFiles() {
  const components = [null, <Modelo4111 />, <Modelo1201 />];
  const [tabNumber, setTabNumber] = useState();

  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '30rem' }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={tabNumber}
        onChange={(event, number) => setTabNumber(number)}
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label="Modelos" disabled sx={{ borderBottom: 1, borderColor: 'divider' }} />

        <Tab label="Modelo 4111" />

        <Tab label="Modelo 1201" />
      </Tabs>
      <Card sx={{ margin: 2, width: '100%' }}>{components[tabNumber]}</Card>
    </Box>
  );
}
