import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap';

export default function NestedModalLinkProduct({ isOpen, toggle }) {
  const { PRODUTOS_EMPRESA_RESPONSE } = useSelector((state) => state.coban);
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      {PRODUTOS_EMPRESA_RESPONSE && (
        <>
          <ModalHeader>
            {PRODUTOS_EMPRESA_RESPONSE === 'error' ? <h5>Algo deu errado</h5> : <h5>Sucesso !</h5>}
          </ModalHeader>
          <ModalFooter>
            <Button color={PRODUTOS_EMPRESA_RESPONSE === 'error' ? 'danger' : 'success'} onClick={toggle}>
              Fechar
            </Button>{' '}
          </ModalFooter>
        </>
      )}
    </Modal>
  );
}
