export const sendMensagemLoading = 'SEND_MENSAGEM_LOADING';
export const sendMensagemSuccess = 'SEND_MENSAGEM_SUCCESS';
export const sendMensagemError = 'SEND_MENSAGEM_ERROR';
export const sendMensagemPerfilLoading = 'SEND_MENSAGEM_PERFIL_LOADING';
export const sendMensagemPerfilSuccess = 'SEND_MENSAGEM_PERFIL_SUCCESS';
export const sendMensagemPerfilError = 'SEND_MENSAGEM_PERFIL_ERROR';
export const sendMensagemTopicLoading = 'SEND_MENSAGEM_TOPIC_LOADING';
export const sendMensagemTopicSuccess = 'SEND_MENSAGEM_TOPIC_SUCCESS';
export const sendMensagemTopicError = 'SEND_MENSAGEM_TOPIC_ERROR';
export const sendMensagemUniqueLoading = 'SEND_MENSAGEM_UNIQUE_LOADING';
export const sendMensagemUniqueSuccess = 'SEND_MENSAGEM_UNIQUE_SUCCESS';
export const sendMensagemUniqueError = 'SEND_MENSAGEM_UNIQUE_ERROR';
export const sendMensagemMultipleLoading = 'SEND_MENSAGEM_MULTIPLE_LOADING';
export const sendMensagemMultipleSuccess = 'SEND_MENSAGEM_MULTIPLE_SUCCESS';
export const sendMensagemMultipleError = 'SEND_MENSAGEM_MULTIPLE_ERROR';
export const listMensagemlLoading = 'LIST_MENSAGEM_LOADING';
export const listMensagemSuccess = 'LIST_MENSAGEM_SUCCESS';
export const listMensagemError = 'LIST_MENSAGEM_ERROR';
