import React from 'react';
import { connect } from 'react-redux';
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';
import { Pix as PixIcon } from '@mui/icons-material/';
// nodejs library to set properties for components
import { PropTypes } from 'prop-types';

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Badge,
  Dropdown,
} from 'reactstrap';
import * as Styled from './SidebarStyle';

import './sidebar.css';
import { logout } from '../../store/auth/actions';
import { getAllUsers, drawerToogle, listInformations } from '../../store/dashboard/actions';
import ModalConfirmation from '../../components/Modais/Confirmar/ModalConfirmar';

import moment from 'moment';
class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
    title: '',
    modalConfirmation: false,
  };

  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }

  componentDidMount() {
    this.props.listInformations();
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };
  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes) => {
    const { drawerToogle } = this.props.dashboard;

    return routes.map((prop, key) => {
      var menu;
      if (this.props.auth.menu && this.props.auth.menu.length > 0)
        menu = this.props.auth.menu.find((x) => x.ID == prop.id);
      if (menu && !prop.invisible)
        return (
          <NavItem key={key}>
            {true ? (
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={this.closeCollapse}
                activeClassName="active"
              >
                {prop.id == 44 ? <PixIcon style={{ marginRight: '0.8rem' }} /> : <i className={`fas ${menu.ICONE}`} />}
                <div onClick={() => this.props.getAllUsers()} className={!drawerToogle && 'hideContent'}>
                  {menu.DESCRICAO.replace('Contabil', 'Contábil')}
                </div>
                {drawerToogle && this.notification(menu.ID)}
              </NavLink>
            ) : (
              <Dropdown
                nav
                className="nav-link"
                direction="right"
                isOpen={this.state.btnDropright}
                toggle={() => {
                  this.setState({ btnDropright: !this.state.btnDropright });
                }}
              >
                <i className={`fas ${menu.ICONE}`} />
                <DropdownToggle style={{ padding: 0, width: '100%' }} nav className="nav-link" caret>
                  {menu.DESCRICAO}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem href={prop.layout + '/contabil/razonete'}>Razonete</DropdownItem>
                  <DropdownItem href={prop.layout + '/contabil/balancete'}>Balancete</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}

            <ModalConfirmation
              open={this.state.modalConfirmation}
              close={() => this.setState({ modalConfirmation: false })}
              title={this.state.title}
              buttonTexts={[
                { name: 'Sair', color: '#1171ef' },
                { name: 'Voltar', color: '#C0C0C0' },
              ]}
              callFunction={() => this.logoutSystem()}
            />
          </NavItem>
        );
    });
  };

  notification = (ID) => {
    const { infos, drawerToogle } = this.props.dashboard;
    var value = null;
    let filter = {
      limit: 10,
      search: '',
      page: 1,
      perfil: '',
      status: '1',
      tipo_usuario: '',
      dataInicial: '',
      dataFinal: moment().format('YYYY-MM-DD'),
    };
    switch (ID) {
      case '2':
        value = infos.usuariosPendentes > 0 && infos.usuariosPendentes;
        break;
    }
    return value ? (
      <Badge
        style={{
          width: 26,
          height: 26,
          borderRadius: '100%',
          position: 'absolute',
          right: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        color="primary"
        title="Usuários Pendentes"
        onClick={() => {
          this.props.getAllUsers(filter, false, true);
        }}
      >
        {value}
      </Badge>
    ) : null;
  };

  logoutSystem = () => {
    this.props.logout(() => this.props.history.push('/login'));
  };

  logoutConfirmation = () => {
    this.setState({ title: 'Deseja mesmo sair?' });
    this.setState({ modalConfirmation: true });
  };

  render() {
    const { routes, logo } = this.props;
    const { drawerToogle } = this.props.dashboard;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: '_blank',
      };
    }
    return (
      <>
        <Navbar
          className={`navbar-vertical ${!drawerToogle && 'smallNavbar'} fixed-left navbar-light bg-white`}
          expand="md"
          id="sidenav-main"
        >
          <Container fluid>
            <button className="navbar-toggler" type="button" onClick={this.toggleCollapse}>
              <span className="navbar-toggler-icon" />
            </button>
            {logo ? (
              <NavbarBrand className="pt-0" {...navbarBrandProps}>
                <img alt={logo.imgAlt} className="navbar-brand-img" style={{ maxHeight: 100 }} src={logo.imgSrc} />
              </NavbarBrand>
            ) : null}
            {/* User */}
            <Nav className="align-items-center d-md-none">
              <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <i className="ni ni-single-02"></i>
                    </span>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Bem-vindo!</h6>
                  </DropdownItem>
                  <Styled.DropdownOption to="/admin/configuracoes" tag={Link}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Configuração</span>
                  </Styled.DropdownOption>
                  <DropdownItem divider />
                  <Styled.DropdownOption onClick={() => this.logoutConfirmation()}>
                    <i className="ni ni-user-run" />
                    <span>Sair</span>
                  </Styled.DropdownOption>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            {/* Collapse */}
            <Collapse navbar isOpen={this.state.collapseOpen}>
              {/* Collapse header */}
              <div className="navbar-collapse-header d-md-none">
                <Row>
                  {logo ? (
                    <Col className="collapse-brand" xs="6">
                      {logo.innerLink ? (
                        <Link to={logo.innerLink}>
                          <img alt={logo.imgAlt} src={logo.imgSrc} />
                        </Link>
                      ) : (
                        <a href={logo.outterLink}>
                          <img alt={logo.imgAlt} src={logo.imgSrc} />
                        </a>
                      )}
                    </Col>
                  ) : null}
                  <Col className="collapse-close" xs="6">
                    <button className="navbar-toggler" type="button" onClick={this.toggleCollapse}>
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              {/* Form */}
              <Form className="mt-4 mb-3 d-md-none">
                <InputGroup className="input-group-rounded input-group-merge">
                  <Input
                    aria-label="Search"
                    className="form-control-rounded form-control-prepended"
                    placeholder="Search"
                    type="search"
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <span className="fa fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Form>
              {/* Navigation */}

              <Nav navbar>{this.createLinks(routes)}</Nav>
              {/* Divider */}
              <hr className="my-3" />
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = ({ auth, dashboard }) => {
  return { auth, dashboard };
};
export default connect(mapStateToProps, {
  logout,
  listInformations,
  getAllUsers,
  drawerToogle,
})(Sidebar);
