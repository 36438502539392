import React from 'react';
import { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Badge, Card, CardBody, Button } from 'reactstrap';
import { connect } from 'react-redux';
import valueEmpty from '../../utils/valueEmpty.js';
import TableModal from '../../components/Table/TableModal.jsx';
import { formatMoney } from '../../utils/formatMoneyBRL.js';
import moment from 'moment';
import { colorStatus } from '../../utils/functions';
import formatCPF from '../../utils/formatCPF';
import StatusContainer from '../../components/StatusContainer/StatusContainer.js';

import {
  getCardInnTransactions,
  getCardInnTransactionsID,
  updateConciliation,
} from '../../store/transactions/actions.js';
import formatCNPJ from '../../utils/formatCNPJ.js';
import isCpf from '../../utils/isCpf.js';
class ModalInformations extends Component {
  componentDidMount() {
    getCardInnTransactionsID(this.props.ID);
  }

  render() {
    const { modal, toggle, title, data, ID } = this.props;
    const {
      DATA_HORA,
      STATUS,
      VALOR,
      CPFCNPJ,
      CPFCNPJ_ESTABELECIMENTO,
      NOME,
      ESTABELECIMENTO,
      TID,
      TIPO_TRANSACAO,
      PARCEIRO,
      DESCRICAO_STATUS_CIP,
      MCC,
    } = this.props.transactions.dadosCardID[0] ? this.props.transactions.dadosCardID[0] : '';

    return (
      <Modal style={{ maxWidth: 800 }} isOpen={modal} toggle={toggle} ID={ID}>
        <ModalHeader toggle={toggle}>
          <h3>{title}</h3>
        </ModalHeader>
        <ModalBody>
          <Row xs="2">
            <Col>
              <h5>Codigo da transação</h5>
              <p>{ID}</p>
            </Col>
            <Col>
              <h5>Status Transação</h5>
              {STATUS == 'AGUARDANDO' && <Badge color="info">{STATUS}</Badge>}
              {STATUS == 'APROVADO' && <Badge color="success">{STATUS}</Badge>}
              {STATUS == 'CANCELADO' && <Badge color="danger">{STATUS}</Badge>}
              {STATUS == 'VENCIDO' && <Badge color="secondary">{STATUS}</Badge>}
            </Col>
          </Row>

          <Row xs="2">
            <Col>
              <h5>Nome</h5>
              <p>{valueEmpty(NOME)}</p>
            </Col>
            <Col>
              <h5>Tipo de Transação</h5>
              <p>{TIPO_TRANSACAO}</p>
            </Col>
          </Row>
          <Row xs="2">
            <Col>
              <h5>CPF/CNPJ</h5>
              <p>{CPFCNPJ && isCpf(CPFCNPJ) ? formatCPF(CPFCNPJ) : formatCNPJ(CPFCNPJ)}</p>
            </Col>
            <Col>
              <h5>Parceiro</h5>
              <p>
                {' '}
                <StatusContainer code={PARCEIRO} description={PARCEIRO}></StatusContainer>
              </p>
            </Col>
          </Row>

          <Row xs="2">
            <Col>
              <h5>Estabelecimento</h5>
              <p>{ESTABELECIMENTO}</p>
            </Col>
            <Col>
              <h5>CPF/CNPJ do estabelecimento</h5>
              <p>
                {CPFCNPJ_ESTABELECIMENTO
                  ? CPFCNPJ_ESTABELECIMENTO.length == 11
                    ? formatCPF(CPFCNPJ_ESTABELECIMENTO)
                    : formatCNPJ(CPFCNPJ_ESTABELECIMENTO)
                  : '----'}
              </p>
            </Col>
          </Row>
          <Row xs="2">
            <Col>
              <h5>Data/Hora</h5>
              <p>{DATA_HORA}</p>
            </Col>
            {VALOR && (
              <Col>
                <h5>Valor</h5>
                <p>R$ {formatMoney(VALOR)}</p>
              </Col>
            )}
          </Row>
          {/* <Row xs="2">
            <Col>
              <h5>TID</h5>
              <p>{TID}</p>
            </Col>
            <Col>
              <h5>MCC</h5>
              <p>{valueEmpty(MCC)}</p>
            </Col>
          </Row> */}
          <Row xs="2">
            <Col>
              <h5>Descrição</h5>
              <p>{valueEmpty(DESCRICAO_STATUS_CIP)}</p>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

const styles = {
  Card: { border: '1px solid #f0f0f0', borderRadius: 10 },
};

const mapStateToProps = ({ user, transactions }) => {
  return { user, transactions };
};

export default connect(mapStateToProps, {
  getCardInnTransactionsID,
  updateConciliation,
})(ModalInformations);
