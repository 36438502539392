import {
  sendMensagemLoading,
  sendMensagemSuccess,
  sendMensagemError,
  sendMensagemPerfilLoading,
  sendMensagemPerfilSuccess,
  sendMensagemPerfilError,
  sendMensagemTopicLoading,
  sendMensagemTopicSuccess,
  sendMensagemTopicError,
  sendMensagemUniqueLoading,
  sendMensagemUniqueSuccess,
  sendMensagemUniqueError,
  sendMensagemMultipleLoading,
  sendMensagemMultipleSuccess,
  sendMensagemMultipleError,
  listMensagemlLoading,
  listMensagemSuccess,
  listMensagemError,
} from './types';

const INITIAL_STATE = {
  approvedUsersData: [],
  paginationMensagem: { totalResult: 0 },
  errorMenssagem: '',
  loadingSendMensagem: true,
  loadingSendMensagemPerfil: true,
  paginationListMensagem: { totalResult: 0 },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case sendMensagemLoading:
      return {
        ...state,
        loadingSendMensagem: true,
        error: false,
      };
    case sendMensagemSuccess:
      return {
        ...state,
        loadingSendMensagem: false,
        error: false,
      };
    case sendMensagemError:
      return {
        ...state,
        loadingSendMensagem: false,
        error: true,
        errorMenssagem: action.payload.mensagem,
      };
    case sendMensagemPerfilLoading:
      return {
        ...state,
        loadingSendMensagemPerfil: true,
        errorPerfil: false,
      };
    case sendMensagemPerfilSuccess:
      return {
        ...state,
        loadingSendMensagemPerfil: false,
        errorPerfil: false,
        topicosMensagem: action.payload.data,
      };
    case sendMensagemPerfilError:
      return {
        ...state,
        loadingSendMensagemPerfil: false,
        errorPerfil: true,
        errorMenssagemPerfil: action.payload.mensagem,
      };
    case sendMensagemTopicLoading:
      return {
        ...state,
        loadingSendMensagemTopic: true,
        errorSendMensagemTopic: false,
      };
    case sendMensagemTopicSuccess:
      return {
        ...state,
        loadingSendMensagemTopic: false,
        errorSendMensagemTopic: false,
        topicosMensagem: action.payload.data,
      };
    case sendMensagemTopicError:
      return {
        ...state,
        loadingSendMensagemTopic: false,
        errorSendMensagemTopic: true,
        errorMenssagemTopic: action.payload.mensagem,
      };
    case sendMensagemUniqueLoading:
      return {
        ...state,
        loadingSendMensagemUnique: true,
        errorSendMensagemUnique: false,
      };
    case sendMensagemUniqueSuccess:
      return {
        ...state,
        loadingSendMensagemUnique: false,
        errorSendMensagemUnique: false,
        uniqueMensagem: action.payload.data,
      };
    case sendMensagemUniqueError:
      return {
        ...state,
        loadingSendMensagemUnique: false,
        errorSendMensagemUnique: true,
        errorMenssagemUnique: action.payload.mensagem,
      };
    case sendMensagemMultipleLoading:
      return {
        ...state,
        loadingSendMensagemMultiple: true,
        errorSendMensagemMultiple: false,
      };
    case sendMensagemMultipleSuccess:
      return {
        ...state,
        loadingSendMensagemMultiple: false,
        errorSendMensagemMultiple: false,
        multipleMensagem: action.payload.data,
      };
    case sendMensagemMultipleError:
      return {
        ...state,
        loadingSendMensagemMultiple: false,
        errorSendMensagemMultiple: true,
        errorMenssagemMultiple: action.payload,
      };
    case listMensagemlLoading:
      return {
        ...state,
        loadingListMensagem: true,
        errorListMensagem: false,
        paginationListMensagem: {},
      };
    case listMensagemSuccess:
      return {
        ...state,
        loadingListMensagem: false,
        errorListMensagem: false,
        listMensagem: action.payload.data,
        paginationListMensagem: action.payload.pagination,
      };
    case listMensagemError:
      return {
        ...state,
        loadingListMensagem: false,
        errorListMensagem: true,
        errorMenssagemList: action.payload.mensagem,
        paginationListMensagem: {},
      };
    default:
      return state;
  }
};
