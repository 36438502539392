import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listarCartaoByUUID, listarSaldoGeralByUUID } from '../../../../store/coban/actions';
import { FiRefreshCw } from 'react-icons/fi';
import formatMoney from '../../../../utils/formatMoneyBRL';
import {
  Container,
  ReloadButtonStyle,
  SaldoColor,
  SaldoContainer,
  SaldoDisponivelColor,
  SaldoStyle,
  SaldoUtilizadoColor,
} from './style';

function DigitalAccountBalaceContainer({ user, saldoGeral, filterCards }) {
  const dispatch = useDispatch();
  const { SALDO_CONTA_CARTAO } = useSelector((state) => state.coban);

  function handleClick() {
    dispatch(listarCartaoByUUID(user.UUID, filterCards));
    dispatch(listarSaldoGeralByUUID(user.UUID));
  }

  return (
    <Container>
      <SaldoContainer style={{ marginTop: '1rem' }}>
        <SaldoStyle>
          Saldo Conta Digital: <SaldoColor> R$ {formatMoney(saldoGeral.SALDO_ATUAL)}</SaldoColor>
        </SaldoStyle>
        <SaldoStyle>
          Saldo utilizado:
          <SaldoUtilizadoColor> R$ {formatMoney(saldoGeral.SALDO_UTILIZADO)}</SaldoUtilizadoColor>
        </SaldoStyle>
        <SaldoStyle>
          Saldo disponível:
          <SaldoDisponivelColor> R$ {formatMoney(saldoGeral.SALDO_DISPONIVEL)}</SaldoDisponivelColor>
        </SaldoStyle>
      </SaldoContainer>

      <SaldoContainer>
        <SaldoStyle>
          Saldo Conta Cartão: <SaldoColor> R$ {formatMoney(SALDO_CONTA_CARTAO.SALDO_ATUAL)}</SaldoColor>
        </SaldoStyle>
        <SaldoStyle>
          Saldo utilizado conta cartão:
          <SaldoUtilizadoColor> R$ {formatMoney(SALDO_CONTA_CARTAO.SALDO_UTILIZADO)}</SaldoUtilizadoColor>
        </SaldoStyle>
        <SaldoStyle>
          Saldo disponível conta cartão:
          <SaldoDisponivelColor> R$ {formatMoney(SALDO_CONTA_CARTAO.SALDO_DISPONIVEL)}</SaldoDisponivelColor>
        </SaldoStyle>
      </SaldoContainer>

      <ReloadButtonStyle onClick={() => handleClick()}>
        <FiRefreshCw />
      </ReloadButtonStyle>
    </Container>
  );
}

export default DigitalAccountBalaceContainer;
