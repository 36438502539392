import React from 'react';
import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap';

export default function NestedModalDeleteProduct({ isOpen, toggle, name, deleteProduct }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <>
        <ModalHeader>
          <h2>Deletar produto {name}</h2>
        </ModalHeader>
        <ModalFooter>
          <Button color="danger" onClick={deleteProduct}>
            Deletar
          </Button>{' '}
          <Button onClick={toggle}>Cancelar</Button>{' '}
        </ModalFooter>
      </>
    </Modal>
  );
}
