import React, { useState } from 'react';
import AccordionInfoCardAccount from './components/AccordionInfoCardAccount/AccordionInfoCardAccount';
import ExtractAccountCard from './components/ExtractAccountCard/ExtractAccountCard';
import api from '../../service/api';
import { useSelector } from 'react-redux';

export default function CardsAccount({ informacoesPessoais }) {
  const [reload, setReload] = useState(false);
  const [cardAccount, setCardAccount] = useState(false);
  const [loadingAccount, setLoadingAccount] = useState(false);
  const { user } = useSelector((state) => state.user);

  function getContaCartao() {
    setLoadingAccount(true);
    if (user) {
      api
        .get(`/admin/conta-cartao/${user.UUID}`)
        .then(({ data }) => {
          if (data) {
            setCardAccount(data.data);
            setLoadingAccount(false);
          }
        })
        .catch(() => setLoadingAccount(false));
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <AccordionInfoCardAccount
        getContaCartao={getContaCartao}
        reload={reload}
        setReload={setReload}
        informacoesPessoais={informacoesPessoais}
        cardAccount={cardAccount}
        setCardAccount={setCardAccount}
        loadingAccount={loadingAccount}
        setLoadingAccount={setLoadingAccount}
      />
      {cardAccount && <ExtractAccountCard cardAccount={cardAccount} reload={reload} />}
    </div>
  );
}
