import AntecipationID from './views/Anticipation/AntecipationID.jsx';
import BilletSales from './views/BilletSales/BilletSales.jsx';
import CardSales from './views/CardSales/CardSales.jsx';
import CardSalesInn from './views/CardSalesInn/CardSalesInn.jsx';
import Cobans from './views/Cobans/Cobans.jsx';
import Configurations from './views/Configs/Configurations.jsx';
import Dashboard from './views/Dashboard/Dashboard.jsx';
import DigitalServices from './views/DigitalServices/DigitalServices.jsx';
import Loan from './views/Loan/Loan';
import Log from './views/Log/Log';
import Boleto from './views/Payment/Boleto.jsx';
import Withdrawals from './views/Transfer/Transfer';
import TransferBetweenAccounts from './views/TransferBetweenAccounts/TransferBetweenAccounts';
import GerenciarClientes from './views/User/GerenciarClientes.js';
import ViewUser from './views/User/ViewUser.jsx';
// import Notifications from './views/Notifications/index.js';
import Accounting from './views/Accounting/Accounting.jsx';
import Companies from './views/Companies/Companies.jsx';
import PedidosRH from './views/PedidosRH/PedidosRH.jsx';
import PortalRh from './views/PortalRh/PortalRh.jsx';
import Products from './views/Products/Products.jsx';
import Report from './views/Report/Report';
import UsuariosRH from './views/UsuariosRH/UsuariosRH.jsx';
import SegundaViaRH from './views/SegundaViaRH/SegundaViaRH.jsx';
import Pix from './views/Pix/Pix.jsx';
import Cards from './views/Cards/Cards.jsx';

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'fas fa-th-large',
    component: Dashboard,
    layout: '/admin',
    id: '1',
  },
  {
    path: '/beneficios',

    name: 'Produtos/benefícios',
    icon: 'fas fa-th-large',
    component: Products,
    layout: '/admin',
    id: '38',
  },
  {
    path: '/pedidos-rh',
    name: 'pedidos RH',
    icon: 'fas fa-th-large',
    component: PedidosRH,
    invisible: true,
    layout: '/admin',
    id: '42',
  },
  {
    path: '/portal-rh',
    name: 'Portal RH',
    icon: 'fas fa-th-large',
    component: PortalRh,
    layout: '/admin',
    id: '39',
  },
  {
    path: '/gerenciar-usuarios',
    name: 'Gerenciar Clientes',
    icon: 'fas fa-th-large',
    component: GerenciarClientes,
    layout: '/admin',
    id: '2',
  },

  {
    path: '/boletos',
    name: 'Boletos',
    icon: 'fas fa-th-large',
    component: Boleto,
    layout: '/admin',
    id: '23',
  },

  {
    path: '/relatorios-parametrizados',
    name: 'Relatórios Parametrizados',
    icon: 'fas fa-th-large',
    component: Report,
    layout: '/admin',
    id: '35',
  },

  {
    path: '/checkouts',
    name: 'Transferências P2P',
    icon: 'fas fa-th-large',
    component: TransferBetweenAccounts,
    layout: '/admin',
    id: '7',
  },

  {
    path: '/transferencias',
    name: 'Transferências',
    icon: 'fas fa-th-large',
    component: Withdrawals,
    layout: '/admin',
    id: '34',
  },

  {
    path: '/cartao',
    name: 'Transações Cartões',
    icon: 'fas fa-th-large',
    component: Cards,
    layout: '/admin',
    id: '18',
  },

  {
    path: '/transacao/pix',
    name: 'Transferências Pix',
    icon: 'fas fa-th-large',
    component: Pix,
    layout: '/admin',
    id: '44',
  },

  {
    path: '/contabil',
    name: 'Contábil',
    icon: 'fas fa-th-large',
    component: Accounting,
    layout: '/admin',
    id: '22',
  },

  {
    path: '/transacao/Recebimento-Cartao',
    name: 'Recebimentos de Cartões',
    icon: 'fas fa-th-large',
    component: CardSalesInn,
    layout: '/admin',
    id: '36',
  },

  {
    invisible: false,
    path: '/solicita/emprestimo',
    name: 'Empréstimos',
    icon: 'fas fa-th-large',
    component: Loan,
    layout: '/admin',
    id: '27',
  },
  {
    path: '/Perfis',
    name: 'Perfis/Usuários',
    icon: 'fas fa-th-large',
    component: Cobans,
    layout: '/admin',
    id: '3',
  },
  {
    invisible: true,
    path: '/gerenciar-usuarios/view',
    name: 'Usuário',
    icon: 'fas fa-th-large',
    component: ViewUser,
    layout: '/admin',
    id: '2',
  },

  {
    path: '/log',
    name: 'Logs',
    icon: 'fas fa-th-large',
    component: Log,
    layout: '/admin',
    id: '17',
  },

  {
    path: '/configuracoes',
    name: 'Configurações',
    icon: 'fas fa-th-large',
    component: Configurations,
    layout: '/admin',
    id: '15',
  },

  {
    path: '/servicos-digitais',
    name: 'Serviços Digitais',
    icon: 'fas fa-th-large',
    component: DigitalServices,
    layout: '/admin',
    id: '30',
  },

  {
    invisible: true,
    path: '/antecipacoes/view',
    name: 'Antecipação',
    icon: 'fas fa-th-large',
    component: AntecipationID,
    layout: '/admin',
    id: '21',
  },

  {
    invisible: false,
    path: '/contabil/razonete',
    name: 'Razonete',
    icon: 'fas fa-th-large',
    component: AntecipationID,
    layout: '/admin',
    id: '31',
  },

  /*   {
    invisible: false,
    path: '/notificacoes',
    name: 'Notificações',
    component: Notifications,
    layout: '/admin',
    id: '33',
  }, */
  {
    invisible: true,
    path: '/companies',
    name: 'Empresas',
    icon: 'fas fa-th-large',
    component: Companies,
    layout: '/admin',
    id: '40',
  },
  {
    invisible: true,
    path: '/rh-usuarios',
    name: 'Usuários Portal RH',
    icon: 'fas fa-th-large',
    component: UsuariosRH,
    layout: '/admin',
    id: '41',
  },
  {
    invisible: true,
    path: '/rh-segunda-via',
    name: 'Solicitações Segunda Via',
    icon: 'fas fa-th-large',
    component: SegundaViaRH,
    layout: '/admin',
    id: '43',
  },
];
export default routes;
