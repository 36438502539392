import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';


const styleSpanTitle = { 
  display: "block",
  fontSize: "2em",
  marginTop: "0.67em",
  marginBottom: "0.67em",
  marginLeft: "0",
  marginRight: "0",
  fontWeight: "bold"
}
const ModalMensagemSemReloadNew = (props) => {
  const { title, open, close, mensagem, sucesso, callback } = props;

  return (
    <div>
      <Modal isOpen={open} toggle={() => close()}>
        <ModalHeader toggle={() => close()}>
          <span style={styleSpanTitle}>{title ? title : 'Erro'}</span>
        </ModalHeader>

        <ModalBody>{mensagem ? mensagem : 'Algo inesperado aconteceu'}</ModalBody>
        <ModalFooter>
          <Button
            color={sucesso == true ? 'success' : 'danger'}
            onClick={() => callback()}
            style={styles.button}
            size="sm"
          >
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const styles = {
  button: {
    width: '100%',
  },
};

export default ModalMensagemSemReloadNew;
