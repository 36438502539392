import React from 'react';
import { Box, CardContent, Typography, Card } from '@mui/material';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import formatCPF from '../../../../../../utils/formatCPF';

function ModalContratoAssinaturaAssinantes({ contrato, reenviarContrato, modal, toggle }) {
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Assinantes</ModalHeader>
      <ModalBody>
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          {contrato.ASSINANTES &&
            contrato.ASSINANTES.length > 0 &&
            contrato.ASSINANTES.map((assinante, index) => (
              <Card>
                <CardContent>
                  <Box mb={1}>
                    <Typography>
                      <b>Documento:</b> {formatCPF(assinante.CPFCNPJ)}
                    </Typography>
                    <Typography>
                      <b>Status:</b> {assinante.STATUS}
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => reenviarContrato(contrato.UUID, assinante.CPFCNPJ)}
                    >
                      Reenviar contrato
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            ))}
        </Box>
      </ModalBody>
    </Modal>
  );
}

export default ModalContratoAssinaturaAssinantes;
