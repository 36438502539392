import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCardTransactions, getTransactionsType, saldoAporteCartao } from '../../store/transactions/actions';
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Table from '../../components/Table/Table.jsx';
import GenericButton from '../../components/Buttons/Button.js';
import Icon from '../../components/Icons/IconsButton.js';
import { Autocomplete, Box, MenuItem, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { formatCNPJ, formatCPF, formatDateBR, isCpf } from '../../utils/index.js';
import StatusContainer from '../../components/StatusContainer/StatusContainer.js';
import Loading from '../../components/Loader/Loading.jsx';
import ModalDetailedCardTransaction from './Modal/ModalDetailedCardTransaction.jsx';

export default function Transactions() {
    const dispatch = useDispatch();

    const [cards, setCards] = useState([{ PAN_MASKED: 'Todos' }]);
    const [modal, setModal] = useState({ open: false, id: '' });
    const [validStatusTypes, setValidStatusTypes] = useState([{ ID: 0, DESCRICAO: "Todos" }]);
    const [loading, setLoading] = useState(true);

    const {
        listCardSales: { PAGINATION, RETORNO },
        statusTransacao, loading_transaction,
    } = useSelector((state) => state.transactions);

    const {
        handleSubmit,
        getValues,
        register,
    } = useForm({
        defaultValues: {
            PAGE: 1,
            LIMIT: 10,
            SEARCH: '',
            STATUS: 'Todos',
            DATA_INICIAL: moment().subtract(1, 'M').format('YYYY-MM-DD'),
            DATA_FINAL: moment().format('YYYY-MM-DD'),
            ID_TIPO_PAGAMENTO: 'Todos',
            ESTABELECIMENTO: '',
            NUM_CARTAO: 'Todos'
        }
    });


    useEffect(() => {
        dispatch(getCardTransactions(cleanFilters(getValues())));
        dispatch(saldoAporteCartao());
        dispatch(getTransactionsType());
    }, []);

    useEffect(() => {
        if (PAGINATION && PAGINATION.page && RETORNO) {
            setLoading(false);
            console.log('RETORNO', RETORNO);
            setCards(['Todos'].concat(Array.from(new Set(RETORNO.map((card) => card.PAN_MASKED)))));
        }
    }, [PAGINATION]);

    useEffect(() => {
        if (statusTransacao && statusTransacao.length > 0) {
            setValidStatusTypes([{ ID: 0, DESCRICAO: "TODOS" }].concat(statusTransacao.filter(status => status.ID <= 4)));
        }
    }, [statusTransacao]);

    function cleanFilters(filters) {
        Object.entries(filters).forEach((filter) => {
            filter[1] === 'Todos' && delete filters[filter[0]];
        });
        return filters;
    }


    function getTransactions(filter) {
        dispatch(getCardTransactions(cleanFilters(filter)));
        dispatch(saldoAporteCartao());
    }

    return (
        <Box mt={1}>
            <form
                onSubmit={handleSubmit(getTransactions)}
                style={{
                    display: 'flex',
                    flexFlow: 'column',
                    width: '100%',
                    justifyContent: 'flex-start',
                    gap: '1rem',
                    margin: '2rem 0',
                }}
            >
                <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} style={{ gap: '10px' }} >
                    <TextField
                        {...register("SEARCH")}
                        autoFocus
                        label="Pesquisa"
                        placeholder="Nome, Conta Bancária ou CPF/CNPJ"
                        variant="outlined"
                        size='small'
                        sx={{ width: '20rem' }}
                    />
                    <TextField
                        {...register("STATUS")}
                        select
                        label="Status"
                        defaultValue={0}
                        variant="outlined"
                        size='small'
                        sx={{ width: '15rem' }}
                    >
                        {validStatusTypes.map(({ ID, DESCRICAO }) => (
                            <MenuItem key={DESCRICAO} value={ID}>
                                {DESCRICAO}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        {...register("ID_TIPO_PAGAMENTO")}
                        select
                        label="Forma Pagamento"
                        defaultValue={0}
                        variant="outlined"
                        size='small'
                        sx={{ width: '15rem' }}
                    >
                        {['Todos', 'Cartão', 'Conta Digital'].map((label, index) => (
                            <MenuItem key={label} value={index}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        label="Data Inicial"
                        {...register("DATA_INICIAL")}
                        type='date'
                        variant="outlined"
                        size='small'
                        sx={{ width: '15rem' }}
                    />

                    <TextField
                        label="Data Final"
                        {...register("DATA_FINAL")}
                        type='date'
                        variant="outlined"
                        size='small'
                        sx={{ width: '15rem' }}
                    />
                    <TextField
                        {...register("ESTABELECIMENTO")}
                        autoFocus
                        label="Estabelecimento"
                        placeholder="Estabelecimento"
                        variant="outlined"
                        size='small'
                        sx={{ width: '15rem' }}
                    />

                    <Autocomplete
                        defaultValue="Todos"
                        options={cards}
                        renderInput={(params) => <TextField {...params} {...register("NUM_CARTAO")} label="Cartão" />}
                        noOptionsText="Cartão não encontrado"
                        size='small'
                        sx={{ width: '15rem' }}
                    />
                    <GenericButton height="40px" width="140px" type="submit">
                        <Icon style={{ marginRight: '.4rem', fontSize: '1rem' }} className="fas fa-search" />
                        Buscar
                    </GenericButton>

                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="custom-export-to-excel-button"
                        table="table-to-xls"
                        filename="tablexls"
                        sheet="tablexls"
                        buttonText="Export"
                    />
                </Box>

                <Box display={'flex'} flexDirection={'row'} mt={1} alignItems={'center'} sx={{ gap: '1rem' }}>
                    <TextField
                        {...register("LIMIT")}
                        select
                        label="Limite"
                        variant="outlined"
                        size='small'
                        defaultValue="10"
                        sx={{ width: '10rem' }}
                    >
                        {['5', '10', '25', '50', '100', '1000'].map((label, index) => (
                            <MenuItem key={index} value={label}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <span style={{ fontSize: 'small' }}>Resultados por página</span>
                </Box>
            </form>
            {
                loading || loading_transaction ? (
                    <Loading color="blue" />
                ) : (
                    <Table
                        headers={[
                            {
                                info: 'DATA/HORA',
                                style: { textAlign: 'center' },
                            },
                            {
                                info: 'CPF/CNPJ',
                                style: { textAlign: 'left' },
                            },
                            {
                                info: 'NOME',
                                style: { textAlign: 'left' },
                            },
                            {
                                info: 'CARTÃO',
                                style: { textAlign: 'center' },
                            },
                            {
                                info: 'ESTABELECIMENTO',
                                style: { textAlign: 'left' },
                            },
                            {
                                info: 'PRODUTO',
                                style: { textAlign: 'left' },
                            },
                            {
                                info: 'PARCEIRO',
                                style: { textAlign: 'center' },
                            },

                            {
                                info: 'STATUS',
                                style: { textAlign: 'center' },
                            },
                            {
                                info: 'VALOR',
                                style: { textAlign: 'center' },
                            },
                        ]}
                        data={
                            RETORNO &&
                            RETORNO.map((sale) => {
                                return {
                                    data: [
                                        {
                                            info: formatDateBR(sale.DATA),
                                            style: { textAlign: 'center' },
                                        },
                                        {
                                            info:
                                                sale.CPFCNPJ && isCpf(sale.CPFCNPJ)
                                                    ? formatCPF(sale.CPFCNPJ)
                                                    : formatCNPJ(sale.CPFCNPJ),
                                            style: { textAlign: 'left' },
                                        },
                                        {
                                            info: sale.NOME ? sale.NOME : '----',
                                            style: { textAlign: 'left' },
                                        },
                                        {
                                            info: sale.PAN_MASKED,
                                        },
                                        {
                                            info: sale.ESTABELECIMENTO ? sale.ESTABELECIMENTO : '----',
                                            style: { textAlign: 'left' },
                                        },
                                        {
                                            info: sale.PRODUTO ? sale.PRODUTO : '----',
                                            style: { textAlign: 'left' },
                                        },
                                        {
                                            info: sale.PARCEIRO ? (
                                                <StatusContainer code={sale.PARCEIRO} description={sale.PARCEIRO} />
                                            ) : (
                                                '----'
                                            ),
                                            style: { textAlign: 'center' },
                                        },
                                        {
                                            info: sale.STATUS ? (
                                                <StatusContainer code={sale.STATUSTRANSACAO} description={sale.STATUS} />
                                            ) : null,
                                            style: { textAlign: 'center' },
                                        },
                                        {
                                            info: (+sale.VALOR_TOTAL).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
                                            style: { justifyContent: 'start' },
                                        }
                                    ],
                                    onClick: () => setModal({ open: true, id: sale.TID })
                                };
                            })
                        }
                        pagination={PAGINATION}
                        previousPage={{ onClick: () => getTransactions({ ...getValues(), PAGE: PAGINATION.prevPage }) }}
                        nowPage={{ PAGE: PAGINATION.page, onClick: () => getTransactions({ ...getValues(), PAGE: PAGINATION.page }) }}
                        nextPage={{ onClick: () => getTransactions({ ...getValues(), PAGE: PAGINATION.nextPage }) }}
                    />
                )}
            <ModalDetailedCardTransaction
                toggle={() => setModal({ open: !modal.open, id: '' })}
                modal={modal.open}
                title={'Dados'}
                transactionId={modal.id}
            />

        </Box >
    );
}
