import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './DateInput.styles';
import { Controller } from 'react-hook-form';

const DateInput = ({ label, name, control, margin, variant, width, error, disabled, value }) => {
  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Styled.DateField
              {...field}
              value={value}
              label={label}
              type="date"
              variant={variant}
              margin={margin}
              error={error}
              disabled={disabled}
              width={width}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: '9999-12-31',
                'data-testid': 'dateField',
              }}
            />
          );
        }}
      ></Controller>
    </div>
  );
};

DateInput.defaultProps = {
  label: ' ',
  name: ' ',
  margin: '0',
  width: '17.778vw',
  variant: 'standard',
};

export default DateInput;
DateInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  setFilter: PropTypes.func,
  filter: PropTypes.object,
  control: PropTypes.any,
};
