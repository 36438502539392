import React, { useEffect, useState } from 'react';
import { listInformations, getSaldoContaDigital } from '../../store/dashboard/actions';
import imagem from '../../assets/images/backgroundImage2.png';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import InputDate from '../../components/Filter/components/InputDate';
import Card from '../CardsDashboard/Card';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
/* import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'; */
import { AiOutlineUser } from 'react-icons/ai';
import { MdOutlineAccountBalanceWallet } from 'react-icons/md';
import { lighten } from 'polished';
import * as Styled from './styles/Card.styles';
import api from '../../service/api';

const Cards = (props) => {
  const dispatch = useDispatch();
  const initialDate = moment().format('YYYY-MM-DD');
  const [date, setDate] = useState({
    dataInicial: initialDate,
  });
  const [isExpanded, setIsExpanded] = useState(false);
  const [saldos, setSaldos] = useState(false);

  function getSaldosDiarios() {
    api.get('/saldos-atuais').then(({ data }) => setSaldos(data.data));
  }

  useEffect(() => {
    dispatch(listInformations());
    dispatch(getSaldoContaDigital(date.dataInicial));
  }, [date.dataInicial]);

  useEffect(() => {
    getSaldosDiarios();
  }, []);

  const {
    infos: { qtdTotalEmprestimos, usuariosAtivos, usuarioPorSemana },
    retorno,
    saldoBanco,
    saldoContaDigital,
    movimentacaoDashboard,
    loading_dashboard_cards,
  } = useSelector((state) => state.dashboard);
  const userEachWeek = props.dashboard.infos.usuarioPorSemana;
  const newUsers = userEachWeek && userEachWeek > 0 ? userEachWeek : 0;

  const extendedHeight = '34vw';
  const halfExtendedHeight = '16vw';
  const contractedHeight = '11vw';
  const cardWidth = '23.5vw';
  const { cashIn, cashOut } = movimentacaoDashboard;

  return (
    <Styled.CardContainer>
      <Styled.CardElement>
        <Card
          icon={<AiOutlineUser style={{ width: '60%', height: '60%' }} />}
          icon2={<AttachMoneyIcon />}
          iconColor={'#262424'}
          backgroundColorContainer={'rgba(38, 36, 36, 0.1)'}
          expandedHeight={extendedHeight}
          height={contractedHeight}
          name="Usuários"
          name2="Empréstimos"
          text="Total de Usuários"
          text2="Total solicitado"
          value={usuariosAtivos}
          value1={newUsers}
          value2={qtdTotalEmprestimos}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          width={cardWidth}
          color={'#262424'}
        ></Card>
      </Styled.CardElement>
      <Styled.CardElement>
        {loading_dashboard_cards === false ? (
          <Card
            icon={<ArrowDownwardIcon />}
            iconColor={'#0CB42A'}
            backgroundColorContainer={'#3333331A'}
            expandedHeight={extendedHeight}
            height={contractedHeight}
            name="Crédito"
            text="Valor total"
            value={cashIn.total}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            color={'#0CB42A'}
            width={cardWidth}
            data={[
              {
                name: 'Boletos',
                value: cashIn.boletos,
              },
              // {
              //   name: 'Empréstimos',
              //   value: cashIn.emprestimos,
              // },
              {
                name: 'Crédito Avulso',
                value: cashIn.avulso,
              },
              {
                name: 'Tarifa',
                value: cashIn.tarifa,
              },
              {
                name: 'TED',
                value: cashIn.ted,
              },
              {
                name: 'Transferência entre Contas',
                value: cashIn.P2P,
              },
              {
                name: 'Pix',
                value: cashIn.pix,
              },
            ]}
          ></Card>
        ) : (
          <Card />
        )}
      </Styled.CardElement>
      <Styled.CardElement>
        {loading_dashboard_cards === false ? (
          <Card
            icon={<ArrowUpwardIcon />}
            iconColor={'#EA6852'}
            backgroundColorContainer={lighten(0.3, '#EA6852')}
            expandedHeight={extendedHeight}
            height={contractedHeight}
            name="Débito"
            text="Valor total"
            value={cashOut.total}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            color={'#EA6852'}
            width={cardWidth}
            data={[
              {
                name: 'Cartões',
                value: cashOut.cartoes,
              },
              {
                name: 'Pagamentos',
                value: cashOut.pagamentos,
              },
              {
                name: 'TED Efetivadas',
                value: cashOut.ted,
              },
              {
                name: 'TED em Processamento',
                value: cashOut.tedPendente,
              },
              {
                name: 'Débito Avulso',
                value: cashOut.avulso,
              },
              {
                name: 'Transferência entre Contas',
                value: cashOut.P2P,
              },
              {
                name: 'Serviços Digitais',
                value: cashOut.recargaCelular,
              },
              {
                name: 'Ifood',
                value: cashOut.ifood,
              },
              {
                name: 'Pix',
                value: cashOut.pix,
              },
            ]}
          ></Card>
        ) : (
          <Card />
        )}
      </Styled.CardElement>
      <Styled.CardElement>
        <Card
          icon={<MdOutlineAccountBalanceWallet style={{ width: '60%', height: '60%' }} />}
          iconColor={'#2405F2'}
          backgroundColorContainer={'rgba(36, 5, 242, 0.1)'}
          expandedHeight={halfExtendedHeight}
          height={contractedHeight}
          name="Saldo Conta Digital"
          text="Saldo do dia"
          value={saldoContaDigital}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          color={'#2405F2'}
          width={cardWidth}
        >
          <InputDate
            label="Data  "
            name="dataInicial"
            value={date.dataInicial}
            setValue={(value) => {
              setDate({ ...date, dataInicial: value });
            }}
            style={{
              div: {
                display: 'flex',
                marginTop: '0.4rem',
                borderBottom: '1px solid rgba(144, 145, 148, 0.2)',
              },
              input: {
                width: 'auto',
                marginLeft: '0.8rem',
                border: 'none',
                fontSize: '0.8rem',
                color: '#909194',
              },
            }}
          />
        </Card>
      </Styled.CardElement>
      <Styled.CardElement>
        {saldos && (
          <Card
            icon={<MdOutlineAccountBalanceWallet style={{ width: '60%', height: '60%' }} />}
            iconColor={'#02792f'}
            backgroundColorContainer={'rgba(36, 5, 242, 0.1)'}
            expandedHeight={halfExtendedHeight}
            height={contractedHeight}
            name="Saldo Atual Conta Digital"
            text="Valor total"
            value={saldos.saldoContaDigital}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            color={'#02792f'}
            width={cardWidth}
          ></Card>
        )}
      </Styled.CardElement>
      <Styled.CardElement>
        {saldos && (
          <Card
            icon={<MdOutlineAccountBalanceWallet style={{ width: '60%', height: '60%' }} />}
            iconColor={'#470196'}
            backgroundColorContainer={'rgba(36, 5, 242, 0.1)'}
            expandedHeight={halfExtendedHeight}
            height={contractedHeight}
            name="Saldo Conta Cartão"
            text="Valor total"
            value={saldos.saldoContaCartao}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            color={'#470196'}
            width={cardWidth}
          ></Card>
        )}
      </Styled.CardElement>
    </Styled.CardContainer>
  );
};

const styles = {
  CardStyles: {
    backgroundImage: 'url(' + imagem + ')',
    image: 'no-repeat',
    height: '100%',
    width: '100%',
    borderRadius: 14,
    border: '1px solid transparent',
    backgroundColor: 'transparent',
    backgroundSize: '100% 100%',
  },
  Image: {
    background: '#93E9FD',
    borderRadius: '50%',
    textAlign: 'center',
    alignContent: 'center',
  },
};

const mapStateToProps = ({ auth, dashboard }) => {
  return { auth, dashboard };
};

export default connect(mapStateToProps, { listInformations })(Cards);
