import React, { useState } from 'react';
import CustomContainer from '../../components/Custom/CustomContainer';
import './styles.css';
import { Tab, Tabs } from '@mui/material';
import PedidosRH from '../PedidosRH/PedidosRH';
import Companies from '../Companies/Companies';
import SegundaViaRH from '../SegundaViaRH/SegundaViaRH';
import UsuariosRH from '../UsuariosRH/UsuariosRH';

export default function PortalRh() {
  const components = [<PedidosRH />, <Companies />, <UsuariosRH />, <SegundaViaRH />];
  const [tabNumber, setTabNumber] = useState(0);

  return (
    <CustomContainer style={{ top: 20 }}>
      <Tabs sx={{ marginTop: -12 }} value={tabNumber} onChange={(event, number) => setTabNumber(number)}>
        <Tab
          icon={<i className="fas fa-dollar-sign" />}
          sx={{
            bgcolor: 'white',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
          label="Pedidos"
        />
        <Tab
          icon={<i className="fas fa-building" />}
          sx={{
            bgcolor: 'white',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
          label="Empresas"
        />
        <Tab
          icon={<i className="fas fa-users" />}
          sx={{
            bgcolor: 'white',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
          label="Usuarios RH"
        />
        <Tab
          icon={<i className="fas fa-list" />}
          sx={{
            bgcolor: 'white',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
          label="Segunda Via"
        />
      </Tabs>

      {components[tabNumber]}
    </CustomContainer>
  );
}
