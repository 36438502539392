import React from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import AccordionDocumentos from './AccordionDocumentos';

function AccordionRepresentativesDocuments({ REPRESENTANTES }) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h5" sx={{ width: '80%', fontWeight: '600', fontSize: '18px', flexShrink: 0 }}>
          Representantes
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {REPRESENTANTES &&
          REPRESENTANTES.length > 0 &&
          REPRESENTANTES.map((REPRESENTANTE) => (
            <>
              <Accordion>
                <AccordionSummary>{`${REPRESENTANTE.NOME} - ${REPRESENTANTE.CPF}`}</AccordionSummary>
                <AccordionDetails>
                  <AccordionDocumentos expanded={true} title="" DOCUMENTOS={REPRESENTANTE.DOCUMENTOS} />
                  {REPRESENTANTE.REPRESENTANTES && REPRESENTANTE.REPRESENTANTES.length > 0 && (
                    <AccordionRepresentativesDocuments REPRESENTANTES={REPRESENTANTE.REPRESENTANTES} />
                  )}
                </AccordionDetails>
              </Accordion>
            </>
          ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionRepresentativesDocuments;
