import React, { useEffect, useState } from 'react';
import CustomDataTable from '../../../components/DataTable/DataTable';
import { formatCNPJ, formatCPF, formatDateBR, formatMoneyBRL, isCpf } from '../../../utils';
import StatusContainer from '../../../components/StatusContainer/StatusContainer';
import { useSelector } from 'react-redux';
import Loading from '../../../components/Loader/Loading';
import { useForm } from 'react-hook-form';
import { Button } from 'reactstrap';
import ModalSenha from '../../../components/Modais/Confirmar/ModalSenha';
import ModalMensagemSemReload from '../../Transaction/ModalMensagemSemReload';
import { Typography } from '@mui/material';
import api from '../../../service/api';

export default function Pending({ search, getValues, handleClick, callback }) {
  const { pagamentos, pagination, loading_transaction } = useSelector((state) => state.transactions);
  const [modalPassword, setModalPassword] = useState({ open: false, callback: () => {}, submitFields: {} });
  const [modalMensagem, setModalMensagem] = useState({ open: false, message: '', success: false });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (pagination && pagination.page && pagamentos) {
      setLoading(false);
    }
  }, [pagination]);

  const {
    handleSubmit: handleSubmitCheckBox,
    getValues: getValuesCheckBox,
    watch: watchCheckBox,
    setValue: setValueCheckBox,
  } = useForm();

  function handleSubmitBatch(data) {
    setModalPassword({ open: true, callback: submitBatch });
  }

  function submitBatch() {
    setLoading(true);
    const entriesValues = Object.entries(getValuesCheckBox());
    const filterValues = entriesValues.map((entry) => entry[1] && { UUID: entry[0] });
    api
      .post('admin/boleto/aprovar_solicitacao_pagamento', filterValues)
      .then(({ data }) => {
        setLoading(false);
        setModalMensagem({ open: true, message: data.mensagem, success: true });
        callback();
      })
      .catch(({ response: { data } }) => {
        setLoading(false);
        setModalMensagem({ open: true, message: data.mensagem, success: false });
        callback();
      });
  }

  return (
    <>
      {loading ? (
        <Loading color="blue" />
      ) : (
        <form onSubmit={handleSubmitCheckBox(handleSubmitBatch)}>
          <div style={{ display: 'flex', gap: '1rem', margin: '0 0 1rem 0' }}>
            <div>
              <input
                type="checkbox"
                name="all"
                onChange={({ target: { checked } }) =>
                  pagamentos.map((pagamento) => setValueCheckBox(pagamento.UUID, checked))
                }
              />
              <label style={{ margin: '0 0 0 1rem' }}>Selecionar todos</label>
            </div>
            {(() => {
              const valuesCheckBox = Object.values(getValuesCheckBox());
              const validateCheckBoxIsTrue = valuesCheckBox.some((value) => value);
              return (
                validateCheckBoxIsTrue && (
                  <div style={{ display: 'flex' }}>
                    <Button size="sm" color="primary" type="submit">
                      Pagar selecionados
                    </Button>
                    <Typography sx={{ display: 'flex', gap: '0.5rem' }}>
                      <b>Total:</b>{' '}
                      <Typography>
                        {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                          pagamentos.reduce((acc, curr) => {
                            const validateActive = getValuesCheckBox(curr.UUID);
                            if (validateActive) {
                              return (acc += +curr.VALOR_TOTAL);
                            }
                            return (acc += 0);
                          }, 0),
                        )}
                      </Typography>
                    </Typography>
                  </div>
                )
              );
            })()}
          </div>
          <CustomDataTable
            loading={loading_transaction}
            pagination={pagination}
            action={(row) => handleClick(row.UUID)}
            previousPage={{ onClick: () => search({ ...getValues(), PAGE: pagination.prevPage }) }}
            nowPage={{ page: pagination.page, onClick: () => search({ ...getValues(), PAGE: pagination.page }) }}
            nextPage={{ onClick: () => search({ ...getValues(), PAGE: pagination.nextPage }) }}
            data={pagamentos ? pagamentos : []}
            headers={[
              {
                selector: (row) => (
                  <input
                    type="checkbox"
                    name={row.UUID}
                    onChange={({ target: { name, checked } }) => setValueCheckBox(name, checked)}
                    checked={watchCheckBox(row.UUID)}
                  />
                ),
                style: { width: '1rem' },
              },
              {
                name: 'DATA/HORA',
                selector: (row) => formatDateBR(row.CREATED_AT),
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'CPF/CNPJ',
                selector: (row) =>
                  row.CPFCNPJ_PAGADOR &&
                  (isCpf(row.CPFCNPJ_PAGADOR) ? formatCPF(row.CPFCNPJ_PAGADOR) : formatCNPJ(row.CPFCNPJ_PAGADOR)),
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'NOME',
                selector: (row) => row.NOME_PAGADOR,
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'CONTA',
                selector: (row) => row.CONTA_PAGADOR,
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'FORMA PAGAMENTO',
                selector: (row) => row.TIPO_PAGAMENTO_DESCRICAO,
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'RECEBEDOR',
                selector: (row) => row.NOME_RECEBEDOR,
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'PARCEIRO',
                selector: (row) => <StatusContainer code={row.TIPO_USUARIO} description={row.TIPO_USUARIO_DESCRICAO} />,
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'VALOR PAGO',
                selector: (row) => `R$ ${formatMoneyBRL(row.VALOR_TOTAL)}`,
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'STATUS',
                selector: (row) => (
                  <StatusContainer code={row.STATUS_TRANSACAO} description={row.STATUS_TRANSACAO_DESCRICAO} />
                ),
                style: { justifyContent: 'start' },
                sortable: true,
              },
            ]}
            customStyles={{
              cells: {
                style: {
                  cursor: 'pointer',
                },
                headCells: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                  },
                },
              },
            }}
          />
        </form>
      )}
      <ModalMensagemSemReload
        modal={modalMensagem.open}
        toggle={() => setModalMensagem({ open: false, message: '', success: false })}
        mensagem={modalMensagem.message}
        sucesso={modalMensagem.success}
      />
      <ModalSenha
        modal={modalPassword.open}
        close={() => setModalPassword((prev) => ({ ...prev, open: false, callback: () => {} }))}
        nextFunction={modalPassword.callback}
      />
    </>
  );
}
