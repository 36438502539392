import React, { useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableCell,
  TableHead,
  Typography,
  TableBody,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import StatusContainer from '../../../../StatusContainer/StatusContainer';
import ModalVisualizarReprovacao from '../../ModalVisualizarReprovacao';
import ModalSenha from '../../../Confirmar/ModalSenha';
import Loading from '../../../../Loader/Loading';
import { revertUser } from '../../../../../store/user/actions';
import ModalMensagem from '../../../../../views/Transaction/ModalMensagem';
import api from '../../../../../service/api';
import { shortenString } from '../../../../../utils/functions';

function TabHistorico() {
  const dispatch = useDispatch();
  const {
    user,
    user: { HISTORICO },
  } = useSelector((state) => state.user);
  const [modalVisualizarReprovacao, setModalVisualizarReprovacao] = useState({ open: false, details: [] });
  const [modalPasswordDenyUser, setModalPasswordDenyUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ open: false, message: '', success: false });

  function openModalMessage(res) {
    const { mensagem, erro, retorno } = res;
    const openModal = { open: true, message: mensagem, success: false };

    if (!erro || (retorno && retorno == 'sucesso')) openModal.success = true;
  }

  const revertUserToPendingStatus = () => {
    setLoading(true);
    dispatch(
      revertUser({ CPFUSUARIO: user.CPFCNPJ }, (res) => {
        openModalMessage(res);
        setLoading(false);
      }),
    );
  };

  return (
    <Box>
      <>
        {loading ? (
          <Loading color="blue" />
        ) : (
          <>
            {HISTORICO && HISTORICO.length > 0 ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Data</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell align="left">Motivo</TableCell>
                      <TableCell align="left">Operador</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {HISTORICO.map((historico) => (
                      <TableRow
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (historico.status === '3' || historico.status === '4') {
                            api.get(`/usuario/detalhes_negado/${user.UUID}/${historico.id}`).then(({ data }) => {
                              setModalVisualizarReprovacao({ open: true, details: data.reprovacao });
                            });
                          }
                        }}
                      >
                        <TableCell>{new Date(historico.data).toLocaleString('pt-BR')}</TableCell>
                        <TableCell>
                          <StatusContainer code={historico.status} description={historico.descricao.toUpperCase()} />
                        </TableCell>
                        <TableCell>{historico.motivo ? shortenString(historico.motivo) : '---'}</TableCell>
                        <TableCell>{historico.cpfcnpj_coban ? historico.cpfcnpj_coban : '---'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography>Não existe histórico para esse usuário</Typography>
              </Box>
            )}
          </>
        )}
        <ModalVisualizarReprovacao
          open={modalVisualizarReprovacao.open}
          details={modalVisualizarReprovacao.details}
          toggle={() => setModalVisualizarReprovacao({ open: false, details: [] })}
        />

        <ModalMensagem
          modal={modalMessage.open}
          toggle={() => setModalMessage({ open: false, message: '', success: false })}
          mensagem={modalMessage.message}
          sucesso={modalMessage.success}
        />

        <ModalSenha
          modal={modalPasswordDenyUser}
          close={() => setModalPasswordDenyUser(false)}
          dados={user.CPFCNPJ}
          nextFunction={revertUserToPendingStatus}
        />
      </>
    </Box>
  );
}

export default TabHistorico;
