import styled from 'styled-components';
import { DropdownItem } from 'reactstrap';

export const DropdownOption = styled(DropdownItem)`
  &:hover {
    background: #1171ef !important;
    color: white;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    border-radius: 7px;
  }
`;
