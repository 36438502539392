export const RESPONSE_CANCELLATION = 'RESPONSE_CANCELLATION';

//Cancelamentos
export const GET_CANCELLATION = 'GET_CANCELLATION';
export const GET_CANCELLATION_SUCCESS = 'GET_CANCELLATION_SUCCESS';
export const GET_CANCELLATION_FAIL = 'GET_CANCELLATION_FAIL';

export const GET_INFO_CANCELLATION = 'GET_INFO_CANCELLATION';
export const GET_INFO_CANCELLATION_SUCCESS = 'GET_INFO_CANCELLATION_SUCCESS';
export const GET_INFO_CANCELLATION_FAIL = 'GET_INFO_CANCELLATION_FAIL';
