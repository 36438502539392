export default function arredondarCasasDecimais(numero) {
  const numeroArredondado = (Math.round(numero * 100) / 100).toFixed(2);
  return numeroArredondado;
}

export function arredondarCasasDecimaisString(string) {
  const numeroString = string.replaceAll('R$', '').replaceAll(' ', '').replaceAll('.', '').replaceAll(',', '.');

  return parseFloat(numeroString);
}
