import React, { useState } from 'react';
import { blockUserByUUID, unlockByUUID } from '../../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Alert } from 'reactstrap';
import ModalMensagem from '../../../views/Transaction/ModalMensagem';

const ModalBloquear = ({ isOpen, modalTransition, backdropTransition, toggle }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const [mensagem, setMensagem] = useState('');
  const [modalMensagem, setModalMensagem] = useState(false);
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [visible, setVisible] = useState(false);
  const [sucesso, setSucesso] = useState(false);

  const onDismiss = () => setVisible(!visible);

  const params = { MOTIVO: mensagem };
  const handle = (target) => {
    setMensagem(target);
  };

  const openModalMessage = (res) => {
    const { mensagem, retorno } = res;

    retorno && retorno == 'sucesso' ? setSucesso(true) : setSucesso(false);

    setMensagem(mensagem);
    setModalMensagem(true);
  };

  const click = () => {
    if (mensagem == '') {
      setVisible(!visible);
      setMensagemAlerta('Preencha os campos');
    } else if (mensagem != '' && (user.STATUS == 2 || user.STATUS == 5 || user.STATUS == 6 || user.STATUS == 7)) {
      dispatch(
        blockUserByUUID(user.UUID, params, (res) => {
          openModalMessage(res);
        }),
      );
    } else {
      dispatch(
        unlockByUUID(user.UUID, params, (res) => {
          openModalMessage(res);
        }),
      );
    }
  };

  const alert = () => {
    return (
      <Alert color="danger" isOpen={visible} toggle={() => onDismiss()}>
        {mensagemAlerta}
      </Alert>
    );
  };

  return (
    <Modal isOpen={isOpen} modalTransition={modalTransition} backdropTransition={backdropTransition} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {user && (
          <>
            {user.STATUS === '2' || user.STATUS === '5' || user.STATUS === '6' || user.STATUS === '7'
              ? ' Bloquear '
              : ' Desbloquear '}
            Usuário
          </>
        )}
      </ModalHeader>
      <FormGroup>
        <ModalBody>
          <span>
            Motivo do
            {user && (
              <>
                {user.STATUS === '2' || user.STATUS === '5' || user.STATUS === '6' || user.STATUS === '7'
                  ? ' bloqueio'
                  : ' desbloqueio'}
              </>
            )}
          </span>
          <Input placeholder="Descreva..." size="sm" onChange={(e) => handle(e.target.value)}></Input>
          {alert()}
        </ModalBody>
      </FormGroup>
      <ModalFooter>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Button style={{ marginTop: '8px' }} color="secondary" size="sm" onClick={toggle} block>
            Cancelar
          </Button>
          <Button color="success" size="sm" onClick={() => click()} block>
            Confirmar
          </Button>
        </div>
      </ModalFooter>

      <ModalMensagem modal={modalMensagem} mensagem={mensagem} sucesso={sucesso} toggle={() => setModalMensagem} />
    </Modal>
  );
};

export default ModalBloquear;
