import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { formatCPF, formatCNPJ, formatDateBR, isCpf } from '../../../../utils/index.js';
import { Button } from 'reactstrap';
import ModalReasonLimit from './modais/ModalReasonLimit/ModalReasonLimit.jsx';
import Loading from '../../../../components/Loader/Loading.jsx';
import StatusContainer from '../../../../components/StatusContainer/StatusContainer.js';
import CustomDataTable from '../../../../components/DataTable/DataTable.jsx';
import CustomTable from '../../../../components/Table/Table.jsx';

function RequestLimit({ search, loading, limits, pagination, getValues }) {
  const [modalReason, setModalReason] = useState({ open: false, limit: {}, success: false, title: '' });

  useEffect(() => {
    search(getValues());
  }, []);

  return (
    <>
      <div style={{ marginTop: '1rem' }}>
        {loading ? (
          <Loading color="#11cdef" />
        ) : (
          <CustomTable
                headers={[
                  {
                    info: 'DATA',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: "NOME",
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'CPF/CNPJ',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'TIPO LIMITE',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'TIPO TURNO',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'VALOR ATUAL',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: "VALOR SOLICITADO",
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'PARCEIRO',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'AÇÕES',
                    style: { textAlign: 'center' },
                  }
                ]}
                data={
                  limits &&
                  limits.map((limit) => {
                    return {
                      data: [
                        {
                          info: formatDateBR(limit.DTHR_SOLICITACAO),
                          style: { textAlign: 'center' },
                        },
                        {
                          info: limit.NOME_SOLICITANTE[0].toUpperCase() + limit.NOME_SOLICITANTE.substring(1),
                          style: { textAlign: 'center' },
                        },
                        {
                          info: limit.CPFCNPJ_SOLICITANTE && isCpf(limit.CPFCNPJ_SOLICITANTE) ? formatCPF(limit.CPFCNPJ_SOLICITANTE) : formatCNPJ(limit.CPFCNPJ_SOLICITANTE),
                          style: { textAlign: 'center' },
                        },
                        {
                          info: limit.TIPO_LIMITE,
                          style: { textAlign: 'center' },
                        },
                        {
                          info: limit.TIPO_TURNO,
                          style: { textAlign: 'center' },
                        },
                        {
                          info: limit.VALOR_ATUAL ? limit.VALOR_ATUAL.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '',
                          style: { textAlign: 'center' },
                        },
                        {
                          info: limit.VALOR_SOLICITADO ? limit.VALOR_SOLICITADO.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '----',
                          style: { textAlign: 'center' },
                        },
                        {
                          info: <StatusContainer code={limit.TIPO_USUARIO.toUpperCase()} description={limit.TIPO_USUARIO} />,
                          style: { textAlign: 'center' },
                        },
                        {
                          info: (
                          <Box display={'flex'} flexDirection={'row'} flexWrap={'nowrap'}>
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() =>
                                setModalReason({ open: true, limit: limit, success: false, title: 'Motivo da Reprovação' })
                              }
                            >
                              Reprovar
                            </Button>
                            <Button
                              color="success"
                              size="sm"
                              onClick={() =>
                                setModalReason({ open: true, limit: limit, success: true, title: 'Motivo da Aprovação' })
                              }
                            >
                              Aprovar
                            </Button>
                          </Box>
                          ),
                          style: { textAlign: 'center' },
                        }
                      ]
                    };
                  })
                }
                pagination={pagination}
                previousPage={{ onClick: () => search({ PAGE: pagination.prevPage }) }}
                nowPage={{ page: pagination.page, onClick: () => search({ PAGE: pagination.page }) }}
                nextPage={{ onClick: () => search({ PAGE: pagination.nextPage }) }}
              />
        )}
      </div>
      <ModalReasonLimit
        open={modalReason.open}
        title={modalReason.title}
        search={search}
        toggle={() => setModalReason({ open: false })}
        limit={modalReason.limit}
        success={modalReason.success}
      />
    </>
  );
}

export default RequestLimit;