import React from 'react';
import AccordionDocumentos from './AccordionDocumentos';
import { useSelector } from 'react-redux';
import AccordionRepresentativesDocuments from './AccordionRepresentativesDocuments';
import { isCnpj } from '../../../../../../utils/isCpf';

const TabDocumentos = () => {
  const {
    user: { CPFCNPJ, DOCUMENTOS, REPRESENTANTES },
  } = useSelector((state) => state.user);
  return (
    <>
      <AccordionDocumentos expanded={true} title="Documentos" DOCUMENTOS={DOCUMENTOS} />

      {isCnpj(CPFCNPJ) && <AccordionRepresentativesDocuments REPRESENTANTES={REPRESENTANTES} />}
    </>
  );
};

export default TabDocumentos;
