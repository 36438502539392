import React from 'react';
import AccordionCardsUser from './components/AccordionCardsUser/AccordionCardsUser';

export default function Cards(props) {
  function handleClick(operation) {
    props.setModalSenhaUnica(true);
    props.setOperation(operation);
  }

  return (
    <AccordionCardsUser
      allCards={props.allCards}
      saldoGeral={props.saldoGeral}
      filter={props.filter}
      setFilter={props.setFilter}
      setCard={props.setCard}
      setInfoCard={props.setInfoCard}
      user={props.user}
      setReloadProdutos={props.setReloadProdutos}
      reloadProdutos={props.reloadProdutos}
      handleClick={handleClick}
      informacoesPessoais={props.informacoesPessoais}
      filterCards={props.filterCards}
      setFilterCards={props.setFilterCards}
      forceReload={props.forceReload}
    />
  );
}
