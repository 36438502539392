import * as React from 'react';
import * as Styled from './Styles/styleModal';
import DialogActions from '@mui/material/DialogActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { TextField } from '@mui/material';

export default function ModalReprovaPedido(props) {
  const { open, close, buttonTexts, callFunction, title, observation, setObservation } = props;

  React.useEffect(() => {
    setObservation('');
  }, [open]);

  return (
    <div>
      <Styled.DialogContent open={open} aria-labelledby="responsive-dialog-title">
        <Styled.Title id="responsive-dialog-title">{title}</Styled.Title>

        <>
          <Styled.ObservationContainer>
            <TextField
              id="outlined-multiline-static"
              label="Observação"
              multiline
              onChange={(e) => setObservation(e.target.value)}
              rows={4}
              value={observation}
              variant="outlined"
              sx={{ width: '100%' }}
            />
          </Styled.ObservationContainer>
          <Styled.ActionsContainer>
            <DialogActions>
              {buttonTexts &&
                buttonTexts.map(({ name, color }, index) => {
                  return (
                    <Styled.CustomButton
                      disabled={index === 0 && observation === ''}
                      onClick={index === 0 ? callFunction : close}
                      index={index}
                      colorButton={color}
                    >
                      {name}
                    </Styled.CustomButton>
                  );
                })}
            </DialogActions>
          </Styled.ActionsContainer>
        </>
      </Styled.DialogContent>
    </div>
  );
}
