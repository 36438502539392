import React from 'react';
import { Row, Col } from 'reactstrap';
import { ButtonTab } from './TabsPage.styles';

const LogTabs = ({ tabs, selectedTab, setSelectedTab }) => {
  return (
    <Row className="clearfix">
      {tabs.map((tab, index) => {
        return (
          <Col>
            <ButtonTab
              block
              onClick={() => setSelectedTab(index)}
              color="secondary"
              outline
              borderBottomColor={selectedTab === index ? '#11cdef' : 'transparent'}
            >
              {tab}
            </ButtonTab>
          </Col>
        );
      })}
    </Row>
  );
};

export default LogTabs;
