import React, { useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import {  Col, Button } from 'reactstrap';
import { CustomBadge } from '../../User.style';
import InfosUser from '../InfosUser';
import StatusContainer from '../../../../components/StatusContainer/StatusContainer';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../../service/api';
import { listaUserDetalhesWithoutLoading } from '../../../../store/user/actions';
import ModalMensagemSemReload from '../../../Transaction/ModalMensagemSemReload';

function AccordionInfoUser({
  informacoesPessoais,
  isConvened,
  validate,
  alterAlert,
  infoPessoaisRedux,
  renderTaxasUsuario,
  setInformacoesPessoais,
  alterValue,
  risco
}) {
  const dispatch = useDispatch();

  const [openEditUser, setOpenEditUser] = useState(false);
  const [openAccordionInfo, setOpenAccordionInfo] = useState(true);
  const [modalMessage, setModalMessage] = useState({ open: false, message: '', success: false });

  const { user: {status_usuario, user} } = useSelector((state) => state);

  async function handleMigrateUser() {
    api
      .post('/usuario/migrar/terceiros', { ID: user.ID })
      .then((response) => {
        dispatch(listaUserDetalhesWithoutLoading(user.UUID));
        setModalMessage({
          open: true,
          message: response.data.mensagem,
          success: true,
        });
      });
  }

  return (
    <Accordion expanded={openAccordionInfo || openEditUser} id="informacoesPessoais">
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-content"
        onClick={() => informacoesPessoais.alteraCliente && !openEditUser && setOpenAccordionInfo(!openAccordionInfo)}
      >
        <div style={{  display: 'flex', justifyContent: 'space-between', width: '100%', padding: '0 1rem'}}>
          <div style={{  display: 'flex', gap: '1rem', alignItems: 'center', alignContent: 'center'}}>
            <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px', flexShrink: 0 }}>
              Informações pessoais 
            </Typography>
            {status_usuario.length > 0 && <StatusContainer code={informacoesPessoais.status_usuario} description={status_usuario.find((({ID}) => +ID === +informacoesPessoais.status_usuario)).DESCRICAO}/> }
            {isConvened === 'S' && <CustomBadge>Conveniada</CustomBadge>}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            {user.CADASTRADO_TERCEIRO !== "S" && (
              <>
                <Col>
                  <Button
                    color="primary"
                    size="sm"
                    style={{ borderRadius: '5px'}}
                    onClick={handleMigrateUser}
                  >
                    Enviar para Sinqia
                  </Button>
                </Col>
              </>
            )}

            {!informacoesPessoais.alteraCliente ? (
              <>
              <Col xs={1}>
              {!informacoesPessoais.alteraCliente && (
                <Button
                  type="submit"
                  size="sm"
                  style={{ background: '#0245AE', color:'#FFF' }}
                  onClick={() => {
                    !informacoesPessoais.alteraCliente ? validate() : alterAlert();
                  }}
                >
                  Salvar
                </Button>
              )}
            </Col>

            <Col xs={5}>
              {!informacoesPessoais.alteraCliente && (
                <Button
                  size="sm"
                  style={{ border: '1px solid #525F7F', color: '#525F7F' }}
                  onClick={() => {
                    setInformacoesPessoais((prev) => ({
                      ...prev,
                      ...infoPessoaisRedux,
                      alteraCliente: !prev.alteraCliente,
                    }));
                  
                  }}
                >
                  Cancelar
                </Button>
              )}
            </Col>
            </>
            ) : (
              <Button
              size='sm'
              color="primary"
              style={{ borderRadius: '5px'}}
              onClick={() => {
                setOpenEditUser(true);
                setInformacoesPessoais({
                  ...informacoesPessoais,
                  ...infoPessoaisRedux,
                  alteraCliente: !informacoesPessoais.alteraCliente,
                });
                    
                window.scrollTo({
                  top: 200,
                  behavior: 'smooth',
                });
              }}
            >
              Alterar Cadastro
            </Button>
            )}
            
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <InfosUser
          renderTaxasUsuarioCallback={(el) => renderTaxasUsuario(el)}
          user={infoPessoaisRedux}
          state={informacoesPessoais}
          setState={(el) => setInformacoesPessoais(el)}
          risco={risco}
          /* toggleConfirmacaoCallback={(el) => this.toggleConfirmacao()}
          openModalPasswordBlockDigitalCardCallback={(el) => this.openModalPasswordBlockDigitalCard()}
          openModalSegundaViaCallback={(el) => this.openModalSegundaVia()} */
          alterValueCallback={(el) => alterValue(el)}
        />
      </AccordionDetails>
      <ModalMensagemSemReload
        modal={modalMessage.open}
        toggle={() => setModalMessage({ open: false, message: '', success: false })}
        mensagem={modalMessage.message}
        sucesso={modalMessage.success}
      />
    </Accordion>
  );
}

export default AccordionInfoUser;
