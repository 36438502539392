import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ModalDifferenceValue = ({ open, toggle, body }) => {
  return (
    <Modal isOpen={open} toggle={() => toggle()} size="md">
      <ModalHeader toggle={() => toggle()}>
        <h2>Valores diferentes</h2>
      </ModalHeader>
      <ModalBody>
        {body.length > 0 &&
          body.map((corpo) => (
            <>
              <h4>{corpo}</h4>
              <hr />
            </>
          ))}
      </ModalBody>
      <ModalFooter>
        <Button id="success-button" color={'primary'} size="sm" onClick={() => toggle()}>
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalDifferenceValue;
