import { Box, Modal, Typography } from '@material-ui/core';
import React from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 500,
  bgcolor: 'background.paper',
  border: '1px solid #00000068',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
  overflowX: 'hidden',
};

export default function ModalPartnerLawSuits({ isOpen, setOwnersModal, owners_lawsuits }) {
  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => setOwnersModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h1>Processos Sócios </h1>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {owners_lawsuits.map((owner_lawsuit) => {
              const owner = owner_lawsuit[0];
              const processos = owner_lawsuit[1].processos;
              return (
                <>
                  <h2>Sócio: {owner}</h2>
                  {processos.map((lawsuit) => (
                    <p>{lawsuit}</p>
                  ))}
                </>
              );
            })}
          </Typography>
        </Box>
      </Modal>{' '}
    </>
  );
}
