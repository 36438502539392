import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listarContasSaldo } from '../../../store/user/actions';
import { modelo4111XML } from '../../../store/accounting/action';
import Loading from '../../Loader/Loading';
import Table from '../../Table/Table';
import { Alert, Box, MenuItem, Snackbar, TextField, Typography, Button, Fade, Icon, Paper } from '@mui/material';
import moment from 'moment';
import { KeyboardArrowUp } from '@material-ui/icons';

export default function Modelo4111() {
  const { loading_user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [contasSaldo, setContasSaldo] = useState([]);
  const [error, setError] = useState('');

  const [xmlRequest, setXmlRequest] = useState({
    contas: [],
    saldo: 0,
    contaContabil: '',
    data: '',
    tipo_remessa: 'I',
  });

  const totalValue = xmlRequest.contas.length > 0 ? xmlRequest.contas.reduce((acc, curr) => acc + +curr.SALDO, 0) : 0;

  function handleCheckBox({ target }) {
    let newArray = xmlRequest.contas;

    if (target.checked) {
      newArray.push({ CONTA: target.name, SALDO: target.value });
      setXmlRequest((prev) => ({ ...prev, contas: newArray }));
    } else {
      let filterArray = newArray.filter((conta) => conta.CONTA != target.name);
      setXmlRequest((prev) => ({ ...prev, contas: filterArray }));
    }
  }

  function populateContas(contas) {
    const dateNow = moment().format('YYYY-MM-DD');
    const date = xmlRequest.data ? xmlRequest.data : dateNow;
    setContasSaldo(contas);
    let contasMap = contas && contas.length > 0 && contas.map((conta) => ({ CONTA: conta.CONTA, SALDO: conta.SALDO }));
    setXmlRequest((prev) => ({ ...prev, contas: contasMap, data: date }));
  }
  function fetchContasSaldo() {
    const dateNow = moment().format('YYYY-MM-DD');
    const date = xmlRequest.data ? xmlRequest.data : dateNow;
    if (date > dateNow) {
      setError('Não é possível trazer dados com data superior que hoje');
    } else {
      dispatch(listarContasSaldo(xmlRequest.data, populateContas));
    }
  }

  function handleDispatch() {
    const { contaContabil, saldo, data, tipo_remessa } = xmlRequest;

    if (!contaContabil) {
      setError('É necessário colocar a conta');
    } else if (!data) {
      setError('É necessário ter data inferior que hoje');
    } else if (!tipo_remessa) {
      setError('É necessário colocar o tipo da remessa');
    }

    if (contaContabil && saldo && data && tipo_remessa)
      dispatch(modelo4111XML(xmlRequest.contaContabil, xmlRequest.saldo, xmlRequest.data, xmlRequest.tipo_remessa));
  }

  useEffect(() => {
    fetchContasSaldo();
  }, []);

  useEffect(() => {
    const value = totalValue.toFixed(2);
    setXmlRequest((prev) => ({ ...prev, saldo: value }));
  }, [xmlRequest.contas]);

  return (
    <Box sx={{ overflowY: 'scroll', maxHeight: '100%' }}>
      {loading_user ? (
        <Loading color="#11cdef" />
      ) : (
        <>
          <Box mr="1rem" id="container">
            <Snackbar
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
              open={error}
              autoHideDuration={6000}
              onClose={() => setError('')}
            >
              <Alert onClose={() => setError('')} severity="error" sx={{ marginBottom: '10px' }}>
                {error}
              </Alert>
            </Snackbar>

            <Typography display="flex" justifyContent="end">
              Total: {totalValue && totalValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            </Typography>
            <Box mb={2} mt={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <TextField
                  type="date"
                  size="small"
                  value={xmlRequest.data}
                  onChange={(e) => setXmlRequest({ ...xmlRequest, data: e.target.value })}
                  required
                  sx={{ marginRight: '10px' }}
                />
                <Button onClick={fetchContasSaldo} color="primary" variant="contained">
                  Buscar
                </Button>
              </Box>
              {xmlRequest.contas.length > 0 && (
                <Box>
                  <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <Box>
                      <TextField
                        size="small"
                        select
                        labelId="select_tipo_remessa"
                        id="tipo_remessa"
                        value={xmlRequest.tipo_remessa}
                        label="Tipo Remessa"
                        onChange={(event) => setXmlRequest({ ...xmlRequest, tipo_remessa: event.target.value })}
                      >
                        <MenuItem value="I">Inserção</MenuItem>
                        <MenuItem value="S">Substituição</MenuItem>
                      </TextField>
                    </Box>
                    <Box>
                      <TextField
                        size="small"
                        label="Conta contábil"
                        value={xmlRequest.contaContabil}
                        onChange={(event) => setXmlRequest({ ...xmlRequest, contaContabil: event.target.value })}
                      />
                    </Box>
                    <Button color="primary" size="small" variant="contained" onClick={handleDispatch}>
                      Gerar XML
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
            <Table
              headers={[
                {
                  info: 'Selecionar',
                  style: { textAlign: 'left' },
                },
                {
                  info: 'Data/Hora',
                  style: { textAlign: 'center' },
                },
                {
                  info: 'CPF/CNPJ',
                  style: { textAlign: 'center' },
                },
                {
                  info: 'Conta',
                  style: { textAlign: 'center' },
                },

                {
                  info: 'Saldo',
                  style: { textAlign: 'center' },
                },
              ]}
              data={
                contasSaldo &&
                contasSaldo.length &&
                contasSaldo.map((conta) => {
                  return {
                    data: [
                      {
                        info: (
                          <input
                            type="checkbox"
                            checked={
                              xmlRequest.contas.length > 0 &&
                              xmlRequest.contas.some((contaRequest) => contaRequest.CONTA == conta.CONTA)
                            }
                            name={conta.CONTA}
                            value={conta.SALDO}
                            onClick={handleCheckBox}
                          />
                        ),
                        style: { textAlign: 'left' },
                      },
                      {
                        info: conta.DATAHORA,
                        style: { textAlign: 'center' },
                      },
                      {
                        info: conta.CPFCNPJ,
                        style: { textAlign: 'center' },
                      },
                      {
                        info: conta.CONTA,
                        style: { textAlign: 'center' },
                      },
                      {
                        info: conta.SALDO,
                        style: { textAlign: 'center' },
                      },
                    ],
                  };
                })
              }
            />

            {contasSaldo.length == 0 && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography>Faça sua consulta para gerar a tabela</Typography>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}
