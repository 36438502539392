import React from 'react';

import { Badge, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Loading from '../../../../../../components/Loader/Loading';
import { MainContainer } from './styles';
import { formatDateBR } from '../../../../../../utils/dateKyc';
import { formatCNPJ, formatCPF, isCpf, valueEmpty } from '../../../../../../utils';
import StatusContainerValue from '../../../../../../components/StatusContainer/StatusContainerValue';

const styles = {
  metade: { width: '49%' },
  cheio: { width: '100%' },
};

export default function ModalReivindicacaoPix({ toggle, open, loading, claim, chooseBadge }) {
  return (
    <>
      <Modal isOpen={open} toggle={toggle} size="lg" style={{ maxWidth: '600px' }}>
        <ModalHeader toggle={toggle}>
          <h2>Detalhes da reivindicação</h2>
        </ModalHeader>
        {claim ? (
          <ModalBody
            style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', wordBreak: 'break-word' }}
          >
            <Row style={styles.cheio}>
              <Col>
                <h5>Data</h5>
                <p>{formatDateBR(claim.DATA_INSERT)}</p>
              </Col>
            </Row>

            <Row style={styles.metade}>
              <Col>
                <h5>CPF/CNPJ Doador</h5>
                <p>
                  {claim.DOADOR_CPFCNPJ
                    ? isCpf(claim.DOADOR_CPFCNPJ)
                      ? formatCPF(claim.DOADOR_CPFCNPJ)
                      : formatCNPJ(claim.DOADOR_CPFCNPJ)
                    : null}
                </p>
              </Col>
            </Row>

            <Row style={styles.metade}>
              <Col>
                <h5>CPF/CNPJ Reivindicador</h5>
                <p>
                  {claim.REIVINDICADOR_CPFCNPJ
                    ? isCpf(claim.REIVINDICADOR_CPFCNPJ)
                      ? formatCPF(claim.REIVINDICADOR_CPFCNPJ)
                      : formatCNPJ(claim.REIVINDICADOR_CPFCNPJ)
                    : null}
                </p>
              </Col>
            </Row>

            <Row style={styles.metade}>
              <Col>
                <h5>Conta Doador</h5>
                <p>{claim.DOADOR_CONTA ? claim.DOADOR_CONTA : '----'}</p>
              </Col>
            </Row>
            <Row style={styles.metade}>
              <Col>
                <h5>Conta Reivindicador</h5>
                <p>{claim.REIVINDICADOR_CONTA ? claim.REIVINDICADOR_CONTA : '----'}</p>
              </Col>
            </Row>

            <Row style={styles.metade}>
              <Col>
                <h5>Nome doador</h5>
                <p>{claim.DOADOR_NOME ? claim.DOADOR_NOME : '----'}</p>
              </Col>
            </Row>
            <Row style={styles.metade}>
              <Col>
                <h5>Nome reivindicador</h5>
                <p>{claim.REIVINDICADOR_NOME ? claim.REIVINDICADOR_NOME : '----'}</p>
              </Col>
            </Row>

            <Row style={styles.metade}>
              <Col>
                <h5>ISPB Doador</h5>
                <p>{claim.DOADOR_ISPB ? claim.DOADOR_ISPB : '----'}</p>
              </Col>
            </Row>
            <Row style={styles.metade}>
              <Col>
                <h5>ISPB reivindicador</h5>
                <p>{claim.ISPB ? claim.ISPB : '----'}</p>
              </Col>
            </Row>

            <Row style={styles.metade}>
              <Col>
                <h5>ID Reivindicação</h5>
                <p>{claim.ID_REIVINDICACAO ? claim.ID_REIVINDICACAO : '----'}</p>
              </Col>
            </Row>

            <Row style={styles.metade}>
              <Col>
                <h5>Data limite</h5>
                <p>{claim.DATE_LIMITE_RESOLUCAO ? formatDateBR(claim.DATE_LIMITE_RESOLUCAO) : '----'}</p>
              </Col>
            </Row>
          </ModalBody>
        ) : (
          <Loading color="blue" />
        )}
      </Modal>
    </>
  );
}
