import React, { useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Paper, Switch, Typography } from '@mui/material';

import ButtonGroupCliente from './components/ButtonGroupCliente';
import { Col } from 'reactstrap';
import api from '../../service/api';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Loading from '../../components/Loader/Loading';

export default function ConfigUser(props) {
	const [apipixLoading, setApipixLoading] = useState(false);

	const {
		getValues,
		watch,
		setValue
	} = useForm({
	  defaultValues: () => api.get(`pix-parametrizacoes/${UUID}`).then(({data: {data}}) => data).catch((_) => ({
		"ATIVO":"N",
		"SALDO_FORNECEDOR":"N",
		"EXTRATO_FORNECEDOR":"N",
		"TRANSACIONAR_FORNECEDOR":"N"
	  }))
	});

	const { UUID } = useSelector((state) => state.user.user);

	function handleActivatePix(state) {
		setApipixLoading(true);
		if (state) {
			api.post(`pix-parametrizacoes/${UUID}`)
				.then((data) => setValue('ATIVO', 'S'))
				.catch((data) => setValue('ATIVO', 'N'))
				.finally(() => setApipixLoading(false));
		} else {
			api.delete(`pix-parametrizacoes/${UUID}`)
			.then((data) => setValue('ATIVO', 'N'))
			.catch((data) => setValue('ATIVO', 'S'))
			.finally(() => setApipixLoading(false));
		}
	}

	function handlePixUpdate({target: {name, checked}}) {
		setApipixLoading(true);
		setValue(name, checked ? 'S' : 'N');
		const body = getValues();
		delete body.ATIVO;
		api.put(`pix-parametrizacoes/${UUID}`, body)
			.finally(() => setApipixLoading(false));
	}

	return (
		<>
			<Accordion defaultExpanded>
				<AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-content">
					<Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
						Cliente
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Col style={{ display: 'flex', alignItems: 'center' }}>
						<ButtonGroupCliente
							setOpenAccordionTaxes={(e) => props.setOpenAccordionTaxes(e)}
							setState={(el) => props.setInformacoesPessoais(el)}
							setTabNumber={props.setTabNumber}
							user={props.user}
							setModalSenhaUnica={props.setModalSenhaUnica}
							state={props.informacoesPessoais}
							setOperation={props.setOperation}
							infoPessoaisRedux={props.infoPessoaisRedux}
							setOpenAccordionInfo={(bool) => props.setOpenAccordionInfo(bool)}
							alteraCliente={props.informacoesPessoais.alteraCliente}
						/>
					</Col>

					<Grid container p={2} spacing={2}>
						<Grid item xs={12}>
							<Paper
								variant='outlined'
								sx={{ padding: '2rem' }}
							>
								<Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
									<h4>API PIX</h4>
									<Switch checked={watch('ATIVO') === 'S'} onChange={(e) => handleActivatePix(e.target.checked)} disabled={apipixLoading} />
								</Box>
								{watch('ATIVO') ? (
									<Box display={'flex'} flexDirection={'column'}>
										<Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
											<h5>Usar Saldo do Fornecedor</h5>
											<Switch name='SALDO_FORNECEDOR' checked={watch('SALDO_FORNECEDOR') === 'S'} onChange={handlePixUpdate} disabled={apipixLoading || watch('ATIVO') === 'N'} />
										</Box>
										<Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
											<h5>Usar Extrato do Fornecedor</h5>
											<Switch name='EXTRATO_FORNECEDOR' checked={watch('EXTRATO_FORNECEDOR') === 'S'} onChange={handlePixUpdate} disabled={apipixLoading || watch('ATIVO') === 'N'} />
										</Box>
										<Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
											<h5>Transacionar pelo Fornecedor</h5>
											<Switch name='TRANSACIONAR_FORNECEDOR' checked={watch('TRANSACIONAR_FORNECEDOR') === 'S'} onChange={handlePixUpdate} disabled={apipixLoading || watch('ATIVO') === 'N'} />
										</Box>
									</Box>
								): (
									<Loading color='blue' />
								)}
							</Paper>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
		</>
	);
}
