import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import Loading from '../../../../components/Loader/Loading';
import { getKyc } from '../../../../store/user/actions';
import { formatDateKyc } from '../../../../utils/dateKyc';
import isCpf, { isCnpj } from '../../../../utils/isCpf';
import { removerAcentos } from '../../../../utils/removeSpecialChars';
import RiskPLD from '../RiskPLD/RiskPLD';
import * as Styled from './AccordionVerifications.styles';
import KycPf from './KycPf';
import KycPj from './KycPj';
import ModalKYC from './Modals/ModalKYC';
import ModalPartnerLawSuits from './Modals/ModalPartnerLawSuits';
import ModalProcesses from './Modals/ModalProcesses';

function AccordionVerifications({ user, uniqueColumn, riscoPLD }) {
  const dispatch = useDispatch();
  const kyc = useSelector((state) => state.user);
  const [kycModal, setKycModal] = useState(false);
  const [kycOwnersModal, setKycOwnersModal] = useState(false);
  const [modalProcesses, setModalProcesses] = useState(false);
  const [ownersModal, setOwnersModal] = useState(false);

  const owners_lawsuits =
    kyc.consultas && kyc.consultas.owners_lawsuits && kyc.consultas.owners_lawsuits[0]
      ? Object.entries(kyc.consultas.owners_lawsuits[0][0])
      : null;

/*   useEffect(() => {
    if (isCpf(user.CPFCNPJ)) {
      dispatch(getKyc(objectPF));
    }
    if (isCnpj(user.CPFCNPJ)) {
      dispatch(getKyc(objectPJ));
    }
  }, []); */

  let objectPJ = {
    ids: ['1', '3', '4', '5', '6', '7', '8'],
    data: user.CPFCNPJ,
    return_type: 'compliance',
    product: 'ContaPagamento',
    distributionchannel: 'Proprio',
    check_previous_data: true,
    data_to_validate: {
      cnpj: user.CPFCNPJ,
      cnae: '0000000',
      data_fundacao: formatDateKyc(user.NASCIMENTO),
      nome: removerAcentos(user.NOME),
      email: user.EMAIL,
      telefone: user.TELEFONE,
      rua: user.ENDERECO,
      cep: user.CEP,
      numero: user.NUMERO,
      cidade: user.CIDADE,
      uf: user.UF,
      bairro: user.BAIRRO,
      socios: user.REPRESENTANTES.map((representante) => {
        return {
          cpf: representante.CPF,
          nome: representante.NOME,
          email: representante.EMAIL,
          telefone: representante.CELULAR,
        };
      }),
    },
  };

  let objectPF = {
    ids: ['1', '2', '4', '5', '7', '8'],
    data: user.CPFCNPJ,
    return_type: 'compliance',
    product: 'ContaPagamento',
    distributionchannel: 'Proprio',
    check_previous_data: true,
    data_to_validate: {
      cpf_cnpj: user.CPFCNPJ,
      nascimento: formatDateKyc(user.NASCIMENTO),
      nome: removerAcentos(user.NOME),
      nome_mae: user.NOME_MAE ? user.NOME_MAE : '',
      email: user.EMAIL,
      telefone: user.TELEFONE,
      rua: user.ENDERECO,
      cep: user.CEP,
      numero: user.NUMERO,
      cidade: user.CIDADE,
      uf: user.UF,
      bairro: user.BAIRRO,
    },
  };

  function searchKyc() {
    if (isCpf(user.CPFCNPJ)) {
      objectPF = { ...objectPF, check_previous_data: false };
      dispatch(getKyc(objectPF));
    }
    if (isCnpj(user.CPFCNPJ)) {
      objectPJ = { ...objectPJ, check_previous_data: false };
      dispatch(getKyc(objectPJ));
    }
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-content">
        <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
          Verificações
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Button
          size="sm"
          color="primary"
          onClick={() => searchKyc()}
          style={{
            alignContent: 'flex-end',
            margin: '1rem 0',
            padding: '.7rem',
          }}
        >
          Consultar Compliance
        </Button>
        {kyc.consultas && kyc.riscoPLD && <Styled.MainText> Risco PLD </Styled.MainText>}
        {kyc.riscoPLD && kyc.consultas && <RiskPLD risco={kyc.riscoPLD} />}
        {kyc.riscoPLD && kyc.consultas && <hr style={{ widh: '97%' }} />}
        {kyc.consultas && <Styled.MainText> Resultado </Styled.MainText>}

        {kyc.loading_kyc && <Loading color="blue" />}
        {!kyc.loading_kyc && !kyc.consultas && <Styled.MainText>Consultas não encontradas</Styled.MainText>}

        {!kyc.loading_kyc && kyc.consultas && isCpf(user.CPFCNPJ) && (
          <KycPf
            risco={kyc.riscoPLD}
            consultas={kyc.consultas}
            setKycModal={setKycModal}
            setModalProcesses={setModalProcesses}
            uniqueColumn={uniqueColumn}
          />
        )}

        {!kyc.loading_kyc && kyc.consultas && isCnpj(user.CPFCNPJ) && (
          <KycPj
            risco={kyc.riscoPLD}
            consultas={kyc.consultas}
            ownersModal={ownersModal}
            setOwnersModal={setOwnersModal}
            setModalProcesses={setModalProcesses}
            owners_lawsuits={owners_lawsuits}
            uniqueColumn={uniqueColumn}
            setKycModal={setKycModal}
            setKycOwnersModal={setKycOwnersModal}
          />
        )}
      </AccordionDetails>
      {kyc.consultas &&
        kyc.consultas.kyc &&
        kyc.consultas.kyc[0] &&
        kyc.consultas.kyc[0].SANCOES &&
        kyc.consultas.kyc[0].SANCOES.length > 0 && (
          <ModalKYC isOpen={kycModal} setKycModal={setKycModal} sancoes={kyc.consultas.kyc[0].SANCOES} />
        )}{' '}
      {kyc.consultas &&
        kyc.consultas.owners_kyc &&
        kyc.consultas.owners_kyc[0] &&
        kyc.consultas.owners_kyc[0][0] &&
        kyc.consultas.owners_kyc[0][0].SANCOES &&
        kyc.consultas.owners_kyc[0][0].SANCOES.length > 0 && (
          <ModalKYC
            isOpen={kycOwnersModal}
            setKycModal={setKycOwnersModal}
            sancoes={kyc.consultas.owners_kyc[0][0].SANCOES}
          />
        )}{' '}
      {kyc.consultas &&
        kyc.consultas.processes &&
        kyc.consultas.processes[0].subject &&
        kyc.consultas.processes[0].subject.length > 0 && (
          <ModalProcesses
            isOpen={modalProcesses}
            setModalProcesses={setModalProcesses}
            processes={kyc.consultas.processes[0].subject}
          />
        )}
      {kyc.consultas && owners_lawsuits && (
        <ModalPartnerLawSuits isOpen={ownersModal} setOwnersModal={setOwnersModal} owners_lawsuits={owners_lawsuits} />
      )}
    </Accordion>
  );
}

export default AccordionVerifications;
