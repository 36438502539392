import React, { useState } from 'react';

import CompaniesTable from '../../components/CompaniesTable/CompaniesTable';
import ModalLinkProduct from './Modals/ModalLinkProduct';
import './styles.css';
import ModalCompanyTaxes from './Modals/ModalCompanyTaxes';
import ModalUsuariosRH from './Modals/ModalUsuariosRH';
import ModalWhiteLabel from '../User/components/modals/components/ModalWhiteLabel/ModalWhiteLabel';
import { ModalEdit } from './Modals/ModalEdit/ModalEdit';

function Companies(props) {
  const [modalLinkProduct, setModalLinkProduct] = useState(false);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [choosenUser, setChoosenUser] = useState(false);
  const [reload, setReload] = useState(false);
  const [alertError, setAlertError] = useState('');
  const [modalTaxes, setModalTaxes] = useState(false);
  const [modalWhiteLabel, setModalWhiteLabel] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  function closeModalLink() {
    setModalLinkProduct(false);
    setAlertError('');
  }

  return (
    <>
      <ModalCompanyTaxes open={modalTaxes} toggle={() => setModalTaxes(false)} choosenUser={choosenUser} />

      <ModalCompanyTaxes open={modalTaxes} toggle={() => setModalTaxes(false)} choosenUser={choosenUser} />

      <CompaniesTable
        setModalVinculProduto={setModalLinkProduct}
        setCreateUserModal={setCreateUserModal}
        setChoosenUser={setChoosenUser}
        reload={reload}
        setReload={setReload}
        setModalTaxes={setModalTaxes}
        setModalWhiteLabel={setModalWhiteLabel}
        setModalEdit={setModalEdit}
      />

      <ModalWhiteLabel
        user={choosenUser}
        setUser={setChoosenUser}
        open={modalWhiteLabel}
        toggle={() => setModalWhiteLabel(false)}
      />

      <ModalLinkProduct
        setAlertError={setAlertError}
        alertError={alertError}
        open={modalLinkProduct}
        toggle={() => closeModalLink()}
        user={choosenUser}
      />

      <ModalEdit
        open={modalEdit}
        toggle={() => setModalEdit(!modalEdit)}
        user={choosenUser}
        reload={reload}
        setReload={setReload}
      />

      <ModalUsuariosRH
        open={createUserModal}
        toggle={() => setCreateUserModal(!createUserModal)}
        user={choosenUser}
        reload={reload}
        setReload={setReload}
      />

      {/*       <ModalCreateUser open={createUserModal} toggle={() => setCreateUserModal(!createUserModal)} user={choosenUser} /> */}
    </>
  );
}

export default Companies;
