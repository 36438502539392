import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { DocumentFuncional as ModalDocument } from '../../../../../../views/User/components/modals';
import { baseIMG } from '../../../../../../service/api';
import imagePDF from '../../../../../../assets/images/imagePDF.png';

function AccordionDocumentos({ expanded, title, DOCUMENTOS }) {
  const [modalImagem, setModalImagem] = useState({
    openDocument: false,
    documentLabel: '',
    urlImage: '',
  });

  return (
    <>
      <Accordion expanded={expanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5" sx={{ width: '80%', fontWeight: '600', fontSize: '18px', flexShrink: 0 }}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ display: 'flex', gap: '1rem' }}>
          {DOCUMENTOS && DOCUMENTOS.length > 0 ? (
            DOCUMENTOS.map((docs) => {
              const mimeType = docs.NOMEARQUIVO.split('.')[1];
              const image = mimeType === 'pdf' ? imagePDF : baseIMG + docs.CAMINHO_ARQUIVO;
              return (
                <Card key={docs.ID} sx={{ width: 250 }}>
                  <CardActionArea
                    onClick={() =>
                      setModalImagem({
                        openDocument: true,
                        documentLabel: docs.DESCRICAO_CATEGORIA,
                        urlImage: docs.CAMINHO_ARQUIVO,
                      })
                    }
                  >
                    <CardMedia
                      component="img"
                      sx={{ padding: '1em 1em 0 1em', objectFit: 'cover' }}
                      height="100"
                      image={image}
                      alt={docs.DESCRICAO_CATEGORIA}
                      title={docs.DESCRICAO_CATEGORIA}
                    />
                    <CardContent>
                      <Typography component="p" variant="p" sx={{ fontWeight: 'bold' }}>
                        {docs.DESCRICAO_CATEGORIA}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
            })
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} mb={1}>
              <Typography>Documento não encontrado</Typography>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
      <ModalDocument
        state={modalImagem}
        toggle={() => setModalImagem({ openDocument: false, documentLabel: '', urlImage: '' })}
      />
    </>
  );
}

export default AccordionDocumentos;
