import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../../components/Loader/Loading';
import MovimentacaoDiaTable from '../../../components/MovimentacaoDiaTable/MovimentacaoDiaTable';
import SaqueBar from '../../../components/SaqueBar/SaqueBar';
import { getSaques, listarTransacaoDia } from '../../../store/accounting/action';
import { useEffect } from 'react';
import CardBalance from '../../../components/CardBalance/CardBalance';
import api from '../../../service/api';
import moment from 'moment';

export default function Dashboard() {
  const dispatch = useDispatch();

  const { loading_accounting } = useSelector((state) => state.accounting);
  const [loadingTotalBalance, setLoadingTotalBalance] = useState(false);
  const [totalBalance, setTotalBalance] = useState({
    data: moment().format("YYYY-MM-DD"),
    balance: "R$ 0,00"
  });

  useEffect(() => {
    dispatch(listarTransacaoDia());
    dispatch(getSaques());
    handleTotalBalanceDayChange(totalBalance.data);
  }, []);

  const handleTotalBalanceDayChange = (value) => {
    setLoadingTotalBalance(true);
    api
      .get(`/contabil/saldo/usuario?DATA=${value}`)
      .then((res) => {
        if (res.data.retorno === "sucesso") {
          const balance = res.data.data.reduce((total, cliente) => total + parseFloat(cliente.SALDO), 0);
          const frozenBalance = res.data.data.reduce((total, cliente) => total + parseFloat(cliente.SALDO_BLOQUEADO), 0);
          setTotalBalance({
            data: value,
            balance: balance.toLocaleString("pt-BR", {style:"currency", currency:"BRL"}),
            frozenBalance: frozenBalance.toLocaleString("pt-BR", {style:"currency", currency:"BRL"}),
          });
        }
      })
      .catch(() => {
        setTotalBalance((prev) => ({
          data: value,
          balance: "----",
          frozenBalance: "----",
        }));
      }).finally(() => setLoadingTotalBalance(false));
  }

  return (
    <>
      {loading_accounting ? (
        <Loading color="#11cdef" />
      ) : (
        <Grid container spacing={2} mt={2}>
          <Grid item md={6} xs={12}>
              <CardBalance
                title={"Total Saldo"}
                date={totalBalance.data}
                setDate={handleTotalBalanceDayChange}
                balance={totalBalance.balance}
                colors={['36', '5', '242']}
                loading={loadingTotalBalance}
              />
            </Grid>
          <Grid item md={6} xs={12}>
          <CardBalance
                title={"Total Saldo Bloqueado"}
                date={totalBalance.data}
                setDate={handleTotalBalanceDayChange}
                balance={totalBalance.frozenBalance}
                type='blockedBalance'
                colors={['207', '35', '6']}
                loading={loadingTotalBalance}
              />
            </Grid>
          <Grid item md={6} xs={12}>
            <MovimentacaoDiaTable />
          </Grid>
          <Grid item md={6} xs={12}>
            <SaqueBar />
          </Grid>
        </Grid>
      )}
    </>
  );
}
