import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import api from '../../../../../../../service/api';
import ContratosConsentimentoTable from '../../../../../../ContratosConsentimentoTable/ContratosConsentimentoTable';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import ModalContratoConsentimento from '../../../../../../../views/ModalContratoConsentimento/ModalContratoConsentimento';

const AccordionsConsentimento = () => {
  const { user } = useSelector((state) => state.user);
  const [contracts, setContracts] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDetailedContract, setLoadingDetailedContract] = useState(false);
  const [detailedContract, setDetailedContract] = useState(false);
  const [detailedModal, setDetailedModal] = useState(false);

  function getDetailedContract(uuid) {
    setDetailedModal(true);
    setLoadingDetailedContract(true);
    api
      .get(`contrato/consentimento/${uuid}`)
      .then(({ data }) => {
        setDetailedContract(data.data);

        setLoadingDetailedContract(false);
      })
      .catch(() => setLoadingDetailedContract(false));
  }

  function getContracts() {
    setLoading(true);
    api
      .get(`contrato/consentimento?document=${user.CPFCNPJ}&id_company=${user.ID_TIPO_USUARIO}`)
      .then(({ data }) => {
        setContracts(data.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }

  useEffect(() => {
    getContracts();
  }, []);
  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
            Consentimento
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ContratosConsentimentoTable
            getDetailedContract={getDetailedContract}
            loading={loading}
            contracts={contracts}
          />
        </AccordionDetails>
      </Accordion>
      <ModalContratoConsentimento
        toggle={() => setDetailedModal(!detailedModal)}
        modal={detailedModal}
        contract={detailedContract}
        loading={loadingDetailedContract}
      />
    </>
  );
};

export default AccordionsConsentimento;
