import { BackHand, BackHandOutlined, Key, MoneyOffOutlined, Pix as PixIcon, ReceiptLong } from '@mui/icons-material/';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Box, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import CustomContainer from '../../components/Custom/CustomContainer';
import LimitsTab from './LimitsTab';
import TermosTab from './TermosTab';
import AuthTab from './AuthTab';


export default function Configurations() {
  const components = [<LimitsTab />, <TermosTab />, <AuthTab />];

  const [tabNumber, setTabNumber] = useState(0);

  return (
    <Box>
      <CustomContainer style={{ top: 20 }}>
        <Tabs sx={{ marginTop: -12 }} value={tabNumber} onChange={(event, number) => setTabNumber(number)}>
          <Tab
            icon={<MoneyOffOutlined />}
            sx={{
              bgcolor: 'white',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            label="Limites"
          />
          <Tab
            icon={<ReceiptLong />}
            sx={{
              bgcolor: 'white',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            label="Termos"
          />
          <Tab
            icon={<AdminPanelSettingsIcon />}
            sx={{
              bgcolor: 'white',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            label="Telas WEBPJ"
          />
        </Tabs>
        {components[tabNumber]}
      </CustomContainer>
    </Box>
  );
}
