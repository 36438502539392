import { Box, Button, Icon, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Form } from 'reactstrap';
import Table from '../../components/Table/Table';
import { listarCoban, listarProdutosPorEmpresaByUUID } from '../../store/coban/actions';
import { getAllUsersConvenied } from '../../store/dashboard/actions';
import GenericButton from '../Buttons/Button';
import InputSearch from '../Filter/components/InputSearch';
import { LocalAtm, Palette } from '@material-ui/icons';
import BuildIcon from '@mui/icons-material/Build';
import { AccountBalance, GroupAdd, Handshake, ReceiptLong, Redeem, SwitchAccount } from '@mui/icons-material';
import { selectAdicionarProdutoByUUID } from '../../store/swap/actions';
import * as Styled from '../../views/User/GerenciarClientes.styles';
import BadgeTipoConta from '../BadgeTipoConta/BadgeTipoConta';
import Filter from '../Filter/Filter';
import InputSelectAmountResults from '../Filter/components/InputSelectAmountResults';
import { InputsContainer } from '../Filter/styles/Filter.style';
import ModalAdicionarAoPortalRH from '../Modais/AdicionarAoPortalRh/ModalAdicionarAoPortalRH';

function CompaniesTable({
  setModalVinculProduto,
  setChoosenUser,
  reload,
  setReload,
  setCreateUserModal,
  setModalTaxes,
  setModalWhiteLabel,
  setModalEdit,
}) {
  const dispatch = useDispatch();
  const { userPagination, listUsersConvenied, loading_dashboard } = useSelector((state) => state.dashboard);



  const [filter, setFilter] = useState({
    limit: 10,
    search: '',
    page: 1,
    perfil: '',
    status: '',
    tipo_usuario: '',
    dataInicial: '',
    dataFinal: moment().format('YYYY-MM-DD'),
    onlyCnpj: true,
    PROFILES_RH: true,
  });
  const [error, setError] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);

  let { page } = filter;

  const tablePagination = async (type = 'atual', pageClick) => {
    switch (type) {
      case 'prev':
        page = userPagination.prevPage || '';
        break;
      case 'next':
        page = userPagination.nextPage || '';
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }

    await setFilter({ ...filter, page });
  };

  useEffect(() => {
    dispatch(getAllUsersConvenied(filter));
    dispatch(listarCoban());
  }, [filter, reload]);

  function openLinkProduct(user) {
    setChoosenUser(user);
    dispatch(listarProdutosPorEmpresaByUUID(user.UUID));
    dispatch(selectAdicionarProdutoByUUID(user.UUID));
    setModalVinculProduto(true);
  }

  function openCreateUser(user) {
    if (!user.TIPOS_CONTA || user.TIPOS_CONTA.length == 0) {
      setError('Defina o perfil da empresa');
    } else {
      setChoosenUser(user);
      setCreateUserModal(true);
    }
  }

  function openModalTaxes(user) {
    setChoosenUser(user);
    setModalTaxes(true);
  }

  function openModalEdit(user) {
    setChoosenUser(user);
    setModalEdit(true);
  }

  function openModalWhiteLabel(user) {
    setChoosenUser(user);
    setModalWhiteLabel(true);
  }

  return (
    <>
      <Filter>
        <Form className="form" onSubmit={(e) => e.preventDefault()}>
          <InputsContainer style={{ flexWrap: 'nowrap' }}>
            <InputSearch
              width="20vw"
              value={filter.search}
              setValue={(value) => setFilter({ ...filter, search: value })}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '0.6rem',
                width: '100%',
              }}
            >
              <GenericButton
                type="submit"
                onClick={() => {
                  dispatch(getAllUsersConvenied(filter));
                  filter.page = '1';
                }}
                marginTop={'25px !important'}
                width={'140px'}
                heigth={'35px'}
              >
                <Icon style={{ marginRight: '.4rem', fontSize: '1rem' }} className="fas fa-search"></Icon>Buscar
              </GenericButton>
              <Box width={'100%'} display={'flex'} justifyContent={'end'}>
                <GenericButton
                  onClick={() => setOpenCreate(true)}
                  marginTop={'25px !important'}
                  width={'max-content'}
                  heigth={'35px'}
                >
                  <Icon style={{ marginRight: '.4rem', fontSize: '1rem' }} className="fas fa-plus"></Icon>Adicionar ao
                  portal RH
                </GenericButton>
              </Box>
            </div>
          </InputsContainer>
        </Form>
        <Styled.infoInput readOnly value={'Total de Usuários ' + userPagination.totalResult}></Styled.infoInput>
        <div>
          <InputSelectAmountResults
            options={['10', '25', '50', '100', '1000']}
            value={filter.limit}
            setValue={(value) => setFilter({ ...filter, limit: value })}
          />
        </div>
      </Filter>

      {error && (
        <Alert color="danger" variant="filled" toggle={() => setError(false)}>
          <strong>{error}</strong>
        </Alert>
      )}

      <Table
        style={{ marginTop: '3rem' }}
        size="sm"
        headers={[
          {
            info: 'CNPJ',
            style: { textAlign: 'center' },
          },
          {
            info: 'Nome',
            style: { textAlign: 'center' },
          },
          {
            info: 'Conveniada',
            style: { textAlign: 'center' },
          },
          {
            info: 'Pagamento boleto pwa',
            style: { textAlign: 'center' },
          },
          {
            info: 'Perfis cartões',
            style: { textAlign: 'center' },
          },
          {
            info: 'Ações',
            style: { textAlign: 'center' },
          },
        ]}
        data={listUsersConvenied.map((user, index) => {
          return {
            data: [
              {
                info: user.CPFCNPJ,
                style: { textAlign: 'center' },
              },
              {
                info: user.NOME,
                style: { textAlign: 'center' },
              },
              user.CONVENIADA === 'S'
                ? {
                    info: 'Sim',
                    style: { textAlign: 'center' },
                  }
                : {
                    info: 'Não',
                    style: { textAlign: 'center' },
                  },
              user.PAGAMENTO_BOLETO === 'S'
                ? {
                    info: 'Sim',
                    style: { textAlign: 'center' },
                  }
                : {
                    info: 'Não',
                    style: { textAlign: 'center' },
                  },
              {
                info: (
                  <div style={{ display: 'flex', gap: '0.1rem', justifyContent: 'center', flexDirection: 'column' }}>
                    {user.TIPOS_CONTA && user.TIPOS_CONTA.length > 0 ? (
                      user.TIPOS_CONTA.map((tipo) => (
                        <BadgeTipoConta tipoConta={tipo} styles={{ fontSize: '0.6rem' }} />
                      ))
                    ) : (
                      <h3 style={{ fontSize: '0.6rem' }} className="badge badge-primary">
                        Não definido
                      </h3>
                    )}
                  </div>
                ),
                style: { textAlign: 'center' },
              },
              {
                info: (
                  <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                    <GenericButton
                      width={'40px'}
                      backgroundColor="#83e9ca"
                      borderColor="#83e9ca"
                      size="m"
                      onClick={() => openModalWhiteLabel(user)}
                    >
                      <Tooltip title="Cores">
                        <Palette />
                      </Tooltip>
                    </GenericButton>

                    <GenericButton
                      width={'40px'}
                      backgroundColor="#4f4f4f"
                      borderColor="#4f4f4f"
                      size="m"
                      onClick={() => openModalEdit(user)}
                    >
                      <Tooltip title="Atualização">
                        <BuildIcon />
                      </Tooltip>
                    </GenericButton>

                    <GenericButton
                      width={'40px'}
                      backgroundColor="#03085f"
                      borderColor="#03085f"
                      size="m"
                      onClick={() => openModalTaxes(user)}
                    >
                      <Tooltip title="Taxas aplicadas">
                        <LocalAtm />
                      </Tooltip>
                    </GenericButton>
                    <GenericButton
                      backgroundColor={'#5c3e84'}
                      borderColor="#5c3e84"
                      width={'40px'}
                      color="info "
                      size="m"
                      onClick={() => openLinkProduct(user)}
                    >
                      <Tooltip title="Vincular Produtos/benefícios">
                        <Redeem />
                      </Tooltip>
                    </GenericButton>
                    {
                      <GenericButton
                        disabled={user.CONVENIADA !== 'S' && true}
                        width={'40px'}
                        color="info "
                        size="m"
                        borderColor="#ffa87b"
                        backgroundColor="#ffa87b"
                        onClick={() => openCreateUser(user)}
                      >
                        <Tooltip title="Usuários">
                          <GroupAdd />
                        </Tooltip>
                      </GenericButton>
                    }
                  </div>
                ),
                /*   style: { justifyContent: 'center' }, */
              },
            ],
          };
        })}
        pagination={userPagination}
        previousPage={{ onClick: () => tablePagination('prev') }}
        nowPage={{ page, onClick: userPagination }}
        nextPage={{ onClick: () => tablePagination('next') }}
        loading={loading_dashboard}
      />

      <ModalAdicionarAoPortalRH
        open={openCreate}
        handleClose={() => setOpenCreate(false)}
        reload={reload}
        setReload={setReload}
      />

      <ModalAdicionarAoPortalRH
        open={openCreate}
        handleClose={() => setOpenCreate(false)}
        reload={reload}
        setReload={setReload}
      />
    </>
  );
}

export default CompaniesTable;
