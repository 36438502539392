import React, { useEffect } from 'react';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loading from '../../../components/Loader/Loading';
import { listaUserDetalhes } from '../../../store/user/actions';
import { renderTaxas, renderTaxasUsuario } from '../../../components/Taxes/renderTaxas';

export default function ModalCompanyTaxes({ open, toggle, choosenUser }) {
  const [loading, setLoading] = useState(false);
  const { user, loading_user_list } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (choosenUser) {
      dispatch(listaUserDetalhes(choosenUser.UUID));
    }
  }, [choosenUser]);

  return (
    <Modal className="modal-lg" isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <span style={{ fontSize: '1rem' }}>Taxas Aplicadas </span>
      </ModalHeader>
      {loading_user_list ? (
        <Loading color="blue" />
      ) : (
        <ModalBody>
          <div style={{ width: '100%' }}>
            {user && renderTaxasUsuario(user.TAXAS, user.FINANCIAMENTO, user.BENEFICIOS)}
          </div>
        </ModalBody>
      )}
      <ModalFooter></ModalFooter>
    </Modal>
  );
}
