import React, { useEffect, useState } from 'react';
import CustomContainer from '../../components/Custom/CustomContainer';
import { Box, Tab, Tabs } from '@mui/material';
import { SpaceDashboard, AutoStories } from '@mui/icons-material/';
import Dashboard from './Dashboard/Dashboard';
import GenerateFiles from './GenerateFiles/GenerateFiles';
import { AccountBalance } from '@material-ui/icons';
import Accounts from './Accounts/Accounts';
import { useDispatch } from 'react-redux';
import { getTransactionsType } from '../../store/transactions/actions';

export default function Accounting() {
  const components = [<Dashboard />, <GenerateFiles />, <Accounts />];

  const [tabNumber, setTabNumber] = useState(0);

  return (
    <Box>
      <CustomContainer style={{ top: 20 }}>
        <Tabs sx={{ marginTop: -12 }} value={tabNumber} onChange={(event, number) => setTabNumber(number)}>
          <Tab
            icon={<SpaceDashboard />}
            sx={{
              bgcolor: 'white',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            label="Dashboard"
          />
          <Tab
            icon={<AutoStories />}
            sx={{
              bgcolor: 'white',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            label="Geração de arquivo"
          />
          <Tab
            icon={<AccountBalance />}
            sx={{
              bgcolor: 'white',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            label="Contas"
          />
        </Tabs>
        {components[tabNumber]}
      </CustomContainer>
    </Box>
  );
}
