import styled from 'styled-components';
import { Label, Input, FormText } from 'reactstrap';

export const Search = styled.div`
  width: ${(props) => props.width || '100%'};
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5vw;

  div {
    padding-right: 1vw;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #d4d4d4;
  flex-direction: column;
`;

export const InputSelectAmountResults = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1rem;
`;

export const InputSelectAmountResultsColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const InputSelectAmountResultsLabel = styled(Label)`
  width: 50%;
  margin-left: 10px;
`;

export const InputSelect = styled(Input)`
  max-width: 80px;
  margin-right: 0px;
  margin-top: 0px;
`;

export const InputSelectM = styled(Input)`
  max-width: 160px;
  margin-right: 0px;
  margin-top: 0px;
`;

export const FormTextInput = styled(FormText)`
  color: red !important;
`;
