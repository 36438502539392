import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Button, Label, Input, Form } from 'reactstrap';
import Loading from '../../components/Loader/Loading.jsx';
import ModalEmprestimo from '../../components/Modais/Emprestimos/modalEmprestimo';
import Table from '../../components/Table/Table.jsx';
import { formatMoney } from '../../utils/formatMoneyBRL';
import CustomContainer from '../../components/Custom/CustomContainer.jsx';
import InputDate from '../../components/Filter/components/InputDate.js';
import Filter from '../../components/Filter/Filter.js';
import { Info } from '../Payment/styles/Payment.style';
import * as Styled from '../../components/Filter/styles/Filter.style';
import InputSelectAmountResults from '../../components/Filter/components/InputSelectAmountResults.js';
import InputSearch from '../../components/Filter/components/InputSearch.js';
import moment from 'moment';
import Icon from '../../components/Icons/IconsButton';
import GenericButton from '../../components/Buttons/Button';

import { getLoans, getLoanSchedule, getStatusEmprestimo } from '../../store/transactions/actions';
import formatTextCamelCase from '../../utils/formatTextCamelCase.js';
export default function Loan() {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState('');
  const [filter, setFilter] = useState({
    search: '',
    DATAINICIAL: '',
    DATAFINAL: moment().format('YYYY-MM-DD'),
    limit: 10,
    status: 0,
    page: 1,
  });

  useEffect(() => {
    dispatch(getLoans(filter));
    dispatch(getStatusEmprestimo());
  }, [filter.page]);

  const {
    emprestimos: { data, pagination },
    loading_transaction,
    filtro_emprestimo,
  } = useSelector((state) => state.transactions);

  const close = () => {
    setModal(!modal);
  };
  let { page } = filter;

  const clickInfo = (data) => {
    dispatch(getLoanSchedule(data.CODOPERACAO));
    setModal(true);
    setModalData(data);
  };
  const tablePagination = async (type = 'atual', pageClick) => {
    switch (type) {
      case 'prev':
        page = pagination.prevPage;
        break;
      case 'next':
        page = pagination.nextPage;
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }
    await setFilter({ ...filter, page });
  };

  const headersContent = [
    {
      info: 'DATA/HORA',
      style: { textAlign: 'center' },
    },
    {
      info: 'NOME',
      style: { textAlign: 'left' },
    },
    {
      info: 'NÚMERO PARCELAS',
      style: { textAlign: 'left' },
    },
    {
      info: 'VALOR',
      style: { textAlign: 'right' },
    },
    {
      info: 'Status',
      style: { textAlign: 'left', width: '200px' },
    },
  ];

  return (
    <div>
      <CustomContainer>
        <Filter>
          <Form className="form" onSubmit={(e) => e.preventDefault()}>
            <Styled.InputsContainer>
              <InputSearch
                width="22vw"
                value={filter.search}
                setValue={(value) => setFilter({ ...filter, search: value })}
              />
              <div>
                <Label size="sm">Status</Label>
                <Input
                  setValue={(value) => setFilter({ ...filter, status: value })}
                  type="select"
                  name="status"
                  id="status"
                  size="sm"
                >
                  <option value={''}>Todos</option>
                  {filtro_emprestimo &&
                    filtro_emprestimo.map((status, index) => (
                      <option value={status.CODSTATUS}>{formatTextCamelCase(status.DESCRICAO)}</option>
                    ))}
                </Input>
              </div>
              <InputDate
                label="Data Inicial"
                name="dataInicial"
                value={filter.dataInicial}
                setValue={(value) => setFilter({ ...filter, dataInicial: value })}
              />

              <InputDate
                label="Data Final"
                name="dataFinal"
                value={filter.dataFinal}
                setValue={(value) => setFilter({ ...filter, dataFinal: value })}
              />

              <div style={{ alignSelf: 'flex-end' }}>
                <GenericButton
                  className="mt-3"
                  size="sm"
                  type="submit"
                  onClick={() => {
                    dispatch(getLoans(filter));
                    filter.page = '1';
                  }}
                >
                  <Icon className="fas fa-search"></Icon>Buscar
                </GenericButton>
              </div>
            </Styled.InputsContainer>
            <div>
              <InputSelectAmountResults
                options={['10', '25', '50', '100', '1000']}
                value={filter.limit}
                setValue={(value) => setFilter({ ...filter, limit: value })}
              />
            </div>
          </Form>
        </Filter>

        <Row>
          <div className="col">
            <div>
              {loading_transaction ? (
                <Loading color="#11cdef" />
              ) : data ? (
                <Table
                  headers={headersContent}
                  data={
                    data &&
                    data.map((loan) => {
                      return {
                        data: [
                          {
                            info: loan.DATA_HORA,
                            style: { textAlign: 'center' },
                          },
                          {
                            info: loan.NOME,
                            style: { textAlign: 'left' },
                          },
                          {
                            info: loan.NUMERO_PARCELA + 'x' + ' R$ ' + formatMoney(loan.VL_PARCELA),
                            style: { textAlign: 'left' },
                          },
                          {
                            info: 'R$' + formatMoney(loan.VALOR),
                            style: { textAlign: 'right' },
                          },
                          {
                            info: loan.DESCRICAO_STATUS,
                            style: { textAlign: 'left' },
                          },
                        ],
                        onClick: () => clickInfo(loan),
                      };
                    })
                  }
                  pagination={pagination}
                  previousPage={{ onClick: () => tablePagination('prev') }}
                  nowPage={{ page, onClick: pagination }}
                  nextPage={{ onClick: () => tablePagination('next') }}
                />
              ) : (
                <Info>Nenhum registro encontrado</Info>
              )}
            </div>
          </div>
        </Row>
      </CustomContainer>

      <ModalEmprestimo title={'Dados do empréstimo'} modal={modal} toggle={close} dataInfo={modalData} />
    </div>
  );
}
