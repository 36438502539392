import { LOGIN, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, RESPONSE_AUTH } from './types';

import api from '../../service/api';


const INITIAL_STATE = {
  is_authenticate: localStorage.getItem('is_authenticate'),
  token: localStorage.getItem('is_authenticate'),
  menu: localStorage.getItem('menu') ? JSON.parse(localStorage.getItem('menu')) : {},
  role: 'admin',
  loading: false,
  erro: false,
  erro_message: null,
};


export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loading: true,
        erro: false,
        erro_message: null,
        nivelAcesso: null,
      };
    case LOGIN_SUCCESS:
      const time = process.env.REACT_APP_TIMER;
      if (time) {
        const timeSplit = time.split('-');
        const jwtDateTime = new Date();
        jwtDateTime.setHours(
          jwtDateTime.getHours() + +timeSplit[0],
          jwtDateTime.getMinutes() + +timeSplit[1],
          jwtDateTime.getSeconds() + +timeSplit[2],
        );
        localStorage.setItem('timer', jwtDateTime.getTime());
      }

      localStorage.setItem('is_authenticate', action.payload.jwt);
      localStorage.setItem('menu', JSON.stringify(action.payload.menu));
      localStorage.setItem('nivelAcesso', action.payload.nivelAcesso);
      api.defaults.headers.common['jwt'] = action.payload.jwt;

      return {
        ...state,
        loading: false,
        erro: false,
        erro_message: null,
        is_authenticate: action.payload.jwt,
        token: action.payload.jwt,
        nivelAcesso: action.payload.nivelAcesso,
        menu: action.payload.menu,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        erro: true,
        erro_message: action.payload,
      };
    case LOGOUT:
      localStorage.clear();

      return {
        is_authenticate: null,
        token: null,
        menu: null,
        role: 'admin',
        loading: false,
        erro: false,
        erro_message: null,
        nivelAcesso: null,
      };

    case RESPONSE_AUTH:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
