import Axios from 'axios';

export const baseIMG = process.env.REACT_APP_BASE_IMG;

const api = Axios.create({ baseURL: process.env.REACT_APP_API });
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('is_authenticate');
  config.headers.Authorization = `Bearer ${token}`;
  config.headers.jwt = `${token}`;
  config.headers.Application = 2;
  return config;
});

export const apiKYC = Axios.create({
  baseURL: process.env.REACT_APP_KYC_API,
});

export const apiRH = Axios.create({
  baseURL: process.env.REACT_APP_RH_API,
});

const onResponseSuccess = (response) => {
  return response;
};

const onResponseFail = (error) => {
  if (error.response.config.url !== '/admin/refresh/session' && error.response.status === 401 && window.location.pathname !== '/login') {
    window.location.replace('/login');
    localStorage.clear();
  }
  return Promise.reject(error);
};
api.interceptors.response.use(onResponseSuccess, onResponseFail);

export const baseURL = process.env.REACT_APP_API;
export default api;
