import React from 'react';
import { Alert } from 'reactstrap';

function AlertaCustomizado(props) {
  const { isOpen, mensagem, toggle, toggleControl } = props;

  return (
    <Alert isOpen={isOpen} color="info" toggle={toggle}>
      {mensagem}
    </Alert>
  );
}

export default AlertaCustomizado;
