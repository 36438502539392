import React, { useEffect } from "react";
import { Badge, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { formatCNPJ, formatCPF, formatDateBR, formatMoneyBRL, isCpf } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { getCardTransactionsID } from "../../../store/transactions/actions";
import StatusContainer from "../../../components/StatusContainer/StatusContainer";
import Loading from "../../../components/Loader/Loading";
import { Grid } from "@mui/material";

export default function ModalDetailedCardTransaction({ modal, toggle, title, transactionId }) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (modal) {
            dispatch(getCardTransactionsID(transactionId));
        }
    }, [modal]);

    const {
        loading_transaction,
        dadosCardID: {
            DATA,
            STATUS_INTERNO,
            VALOR,
            PRODUTO,
            SPREAD,
            VALOR_TOTAL,
            CPFCNPJ,
            CPFCNPJ_ESTABELECIMENTO,
            NOME,
            ESTABELECIMENTO,
            TID,
            PARCEIRO,
            MCC,
            STATUS_PARCEIRO,
            STATUS_PARCEIRO_DESCRICAO
        }
    } = useSelector((state) => state.transactions);

    return (
        <Modal style={{ maxWidth: 800 }} isOpen={modal} toggle={toggle} id={transactionId}>
            <ModalHeader toggle={toggle}>
                <h3>{title}</h3>
            </ModalHeader>
            {loading_transaction ? <Loading color='blue' /> : (
                <ModalBody>
                    <Grid container  spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <h5>Codigo da transação</h5>
                            <p>{TID}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h5>Status Transação</h5>
                            {STATUS_INTERNO === 'AGUARDANDO' && <Badge color="info">{STATUS_INTERNO}</Badge>}
                            {STATUS_INTERNO === 'APROVADO' && <Badge color="success">{STATUS_INTERNO}</Badge>}
                            {STATUS_INTERNO === 'CANCELADO' && <Badge color="danger">{STATUS_INTERNO}</Badge>}
                            {STATUS_INTERNO === 'VENCIDO' && <Badge color="secondary">{STATUS_INTERNO}</Badge>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h5>Nome</h5>
                            <p>{NOME}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h5>CPF/CNPJ</h5>
                            <p>{CPFCNPJ && isCpf(CPFCNPJ) ? formatCPF(CPFCNPJ) : formatCNPJ(CPFCNPJ)}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h5>Parceiro</h5>
                            <p>
                                <StatusContainer code={PARCEIRO} description={PARCEIRO}></StatusContainer>
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h5>Estabelecimento</h5>
                            <p>{ESTABELECIMENTO}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h5>CPF/CNPJ do estabelecimento</h5>
                            <p>
                                {CPFCNPJ_ESTABELECIMENTO && CPFCNPJ_ESTABELECIMENTO.length == 11
                                    ? formatCPF(CPFCNPJ_ESTABELECIMENTO)
                                    : formatCNPJ(CPFCNPJ_ESTABELECIMENTO)}
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h5>Data/Hora</h5>
                            <p>{formatDateBR(DATA)}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h5>Produto</h5>
                            <p>{PRODUTO}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h5>TID</h5>
                            <p>{TID}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h5>MCC</h5>
                            <p>{MCC ? MCC : '----'}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h5>Valor</h5>
                            <p>{(+VALOR).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h5>Taxa</h5>
                            <p>{(+SPREAD).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h5>Valor Total</h5>
                            <p>{(+VALOR_TOTAL).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <h4>Resposta do Terceiro</h4>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h5>Status</h5>
                            <p>{STATUS_PARCEIRO}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h5>Descrição</h5>
                            <p>{STATUS_PARCEIRO_DESCRICAO}</p>
                        </Grid>
                    </Grid>
                </ModalBody>
            )}
        </Modal>
    )
}