import styled from 'styled-components';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

export const ButtonMessage = styled(Button)`
  opacity: 1 !important;
  background: ${(props) => (props.colorButton ? props.colorButton : '#A9A9A9')} !important;
  color: white !important;
  width: 40% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  border-radius: 7px !important;
  &:hover {
    background: ${(props) => (props.colorButton ? props.colorButton : '#A9A9A9')} !important;
    opacity: 0.5 !important;
  }
`;

export const CustomButton = styled(Button)`
  opacity: 1 !important;
  background: ${(props) => (props.colorButton ? props.colorButton : '#A9A9A9')} !important;
  color: white !important;

  border-radius: 7px !important;
  &:hover {
    background: ${(props) => (props.colorButton ? props.colorButton : '#A9A9A9')} !important;
    opacity: 0.5 !important;
  }

  &:disabled {
    opacity: 0.5 !important;
  }
`;

export const Title = styled(DialogTitle)`
  align-self: start;
  color: #000000;
`;

export const DialogMessage = styled(Dialog)`
  .MuiPaper-root {
    background: #f8f8f8;
    width: 20%;
    height: 170px;
    margin-bottom: 15.416238437821171vh;
    height: 05.416238437821171vh;
    min-width: 300px;
    min-height: 130px;
    border-radius: 10px;
  }
`;

export const DialogContent = styled(Dialog)`
  .MuiPaper-root {
    background: #f8f8f8;
    display: flex;
    width: 600px;
    height: 310px;
    height: 05.416238437821171vh;
    min-width: 600px;
    min-height: 310px;
    border-radius: 10px;
    justify-content: space-between;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 600px;
  padding: 1rem;
`;

export const ObservationContainer = styled.div`
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: -2rem;
`;
