import styled from 'styled-components';

export const ColorsContainer = styled.div`
  display: flex;
  width: 15rem;
  min-height: 90vh;
  flex-direction: column;
  margin-top: -3rem;
  padding: 1rem;
  h2 {
    font-size: 1rem;
    padding: 0;
    margin: 0;
  }
`;

export const ColorCard = styled.div`
  box-sizing: border-box;
  padding: 0.3rem 0.5rem 0.5rem 0.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  margin-bottom: 0.6rem;
  cursor: pointer;

  &:hover {
    border: 1px solid #36b8f5;
  }

  span {
    margin-bottom: -6px;
  }
`;
