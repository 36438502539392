import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { modelo1201XML } from '../../../store/accounting/action';
import { Alert, Box, MenuItem, Snackbar, TextField, Button, Typography } from '@mui/material';
import Loading from '../../Loader/Loading';
import api from '../../../service/api';

export default function Modelo1201() {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(null);
  const [xmlRequest, setXmlRequest] = useState({
    data: '',
    tipo_remessa: 'I',
  });

  const [fileData, setFileData] = useState({
    content: '',
    data: '',
  });

  const handleDispatch = async () => {
    setLoading(true);
    setFileData({ content: '', data: '' });
    const { data, tipo_remessa } = xmlRequest;

    if (!data) {
      setError('A data é obrigatória.');
      setLoading(false);
      return;
    } else if (!tipo_remessa) {
      setError('O tipo de remessa é obrigatório.');
      setLoading(false);
      return;
    }

    setError('');

    try {
      const { xmlContentBase64, dataBase } = await dispatch(modelo1201XML(data));

      setFileData({ content: xmlContentBase64, data: dataBase });
      setLoading(false);
    } catch (error) {
      setError('Erro ao gerar o XML. Tente novamente.');
      setLoading(false);
    }
  };

  const generate1201File = async () => {
    try {
      const response = await api.post('/pix/arquivo/1201', {
        DATA_REFERENCIA: fileData.data,
        ARQUIVO_BASE64: fileData.content,
      });

      handleDownload(atob(response.data.xml_base64), response.data.nome_arquivo);
    } catch (error) {
      setError('Erro ao fazer a requisição do arquivo 1201');
    }
  };

  const handleDownload = (content, fileName) => {
    if (content && fileName) {
      const blob = new Blob([content], { type: 'text/xml' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Box sx={{ overflowY: 'scroll', maxHeight: '100%' }}>
      <Box mr="1rem" id="container">
        <Snackbar
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          open={Boolean(error)}
          autoHideDuration={6000}
          onClose={() => setError('')}
        >
          <Alert onClose={() => setError('')} severity="error" sx={{ marginBottom: '10px' }}>
            {error}
          </Alert>
        </Snackbar>

        <Box mb={2} mt={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TextField
              type="month"
              size="small"
              value={xmlRequest.data}
              onChange={(e) => setXmlRequest({ ...xmlRequest, data: e.target.value })}
              required
              sx={{ marginRight: '10px' }}
            />
            <Button onClick={handleDispatch} color="primary" variant="contained">
              BUSCAR
            </Button>
          </Box>

          {fileData.content && (
            <Box>
              <Button onClick={generate1201File} variant="contained" color="primary">
                GERAR XML
              </Button>
            </Box>
          )}
        </Box>

        {loading ? (
          <Loading color="#11cdef" />
        ) : fileData.content ? (
          <Box mt={3} sx={{ userSelect: 'none', WebkitUserSelect: 'none', msUserSelect: 'none' }}>
            <h3>Prévia do Arquivo XML:</h3>
            <pre>{atob(fileData.content)}</pre>
          </Box>
        ) : (
          loading === null && (
            <Typography
              variant="h5"
              sx={{
                width: '100%',
                fontWeight: '600',
                fontSize: '18px',
                flexShrink: 0,
                marginTop: '20px',
                marginX: 'auto',
                textAlign: 'center',
              }}
            >
              Realize uma nova busca para visualizar
            </Typography>
          )
        )}
      </Box>
    </Box>
  );
}
