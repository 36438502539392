import React, { useState, useEffect } from 'react';
import { Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { editarProduto, listarProduto, atualizarSegmento, listarSegmento } from '../../../../store/swap/actions';
import TextField from '@material-ui/core/TextField';
import Select from '../../../../components/Select/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import * as Styled from './AdicionarProduto.styles';
import ModalMensagem from '../../../../views/Transaction/ModalMensagemSemReload';
import LogoSvg from '../LogoSvg/LogoSvg';
import WifiSvg from '../WifiSvg/WifiSvg';
import MasterCardSvg from '../MasterCardSvg/MasterCardSvg';

const EditSingleProduct = (props) => {
  const dispatch = useDispatch();
  const initial = { SEGMENTOS: [] };
  const [segmentos, setSegmentos] = useState([{ ...initial }]);
  const [nome, setNome] = useState('');
  const [check, setCheck] = useState('');
  const [modalMensagem, setModalMensagem] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('');
  const [color, setColor] = useState('#ffffff');
  const [titleColor, setTitleColor] = useState('#020fc4');

  const { listagemProduto, loadingProduct, listagemSegmentos } = useSelector((seg) => seg.swap);

  useEffect(() => {
    dispatch(listarProduto());
    dispatch(listarSegmento());

    /*    setSegmentos('teste'); */
  }, []);

  useEffect(() => {
    if (loadingProduct === false && props.edit > 0) {
      /*       const lProduto = listagemProduto.filter((e) => e.ID === segmentos[0].SEGMENTOS); */
      const lProduto = listagemProduto.filter((e) => e.ID === props.edit);
      const getSegments = props.listagemProdutoSegmentos.find((seg) => seg.ID === props.edit);
      const loadSegments = getSegments.SEGMENTO.map((item) => item.ID);

      lProduto.length > 0 && setNome(lProduto[0].DESCRICAO);
      lProduto.length > 0 && setColor(lProduto[0].COR_PRIMARIA);
      lProduto.length > 0 && setTitleColor(lProduto[0].COR_SECUNDARIA);

      lProduto.length > 0 && lProduto[0].ATIVO === 'S' ? setCheck(true) : setCheck(false);
      setSegmentos([{ SEGMENTOS: loadSegments }]);
    }
  }, [props.open]);

  const changeSegmentos = (e, ix) => {
    const { name, value } = e.target;
    let values = [...segmentos];
    values[0][name] = value;
    setSegmentos((state) => [...values]);
  };

  const reset = () => {
    setNome('');
    setCheck('');

    setSegmentos([{ SEGMENTOS: [] }]);
  };

  const vincSegs = () => {
    dispatch(
      atualizarSegmento(
        {
          ID_SEGMENTO: segmentos[0].SEGMENTOS,
          ATIVO: check ? 'S' : 'N',
          ID_PRODUTO: props.edit,
        },
        (res) => {
          setModalMensagem(true);
          setMensagem(res.mensagem);
          res.retorno === 'sucesso' ? setSucesso(true) : setSucesso(false);
        },
      ),
    );
  };

  const edit = () => {
    const param = {
      ATIVO: check === true ? 'S' : 'N',
      DESCRICAO: nome,
      COR_PRIMARIA: color,
      COR_SECUNDARIA: titleColor,
    };

    dispatch(
      editarProduto(props.edit, param, (res) => {
        setModalMensagem(true);
        setMensagem(res.mensagem);
        res.retorno === 'sucesso' ? setSucesso(true) : setSucesso(false);
        vincSegs();
        dispatch(listarProduto());
        reset();
      }),
    );
  };

  return (
    <>
      <Modal
        isOpen={props.open}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={() => {
          props.close();
          reset();
        }}
      >
        <ModalHeader
          toggle={() => {
            props.close();
          }}
        >
          <h2>Editar o produto </h2>
        </ModalHeader>
        <FormGroup>
          <ModalBody>
            <Styled.Conteiner>
              <Styled.SelectConteiner>
                <Select
                  inputName={'Segmentos'}
                  name="SEGMENTOS"
                  handleChange={(e) => changeSegmentos(e)}
                  selects={segmentos[0].SEGMENTOS}
                  options={listagemSegmentos}
                  error={false}
                  multiple={true}
                />
              </Styled.SelectConteiner>
              {
                <TextField
                  label="Nome"
                  name="NOME"
                  multiline
                  fullWidth
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  error={false}
                />
              }
              <Styled.CheckConteiner>
                <FormControlLabel
                  control={<Checkbox name="ATIVO" checked={check} onChange={(e) => setCheck(e.target.checked)} />}
                  label="Ativo"
                />
              </Styled.CheckConteiner>
            </Styled.Conteiner>
            <div>
              <h3>Cor do produto</h3>
              <Styled.Conteiner>
                <Styled.ColorLeftContainer>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Styled.Column>
                      Cor primária
                      <label htmlFor="cor_primaria">
                        <input
                          id="cor_primaria"
                          className="style1"
                          type="color"
                          value={color}
                          onChange={(e) => setColor(e.target.value)}
                        />
                      </label>
                    </Styled.Column>
                    <Styled.Column>
                      Cor secundária
                      <label>
                        <input
                          className="style1"
                          id="cor_secundaria"
                          type="color"
                          value={titleColor}
                          onChange={(e) => setTitleColor(e.target.value)}
                        />
                      </label>
                    </Styled.Column>
                  </div>
                </Styled.ColorLeftContainer>
                <Styled.ColorRightContainer>
                  <Styled.Card color={color}>
                    <Styled.CardUpside>
                      <Styled.CardHeader>
                        <MasterCardSvg />
                        <WifiSvg color={titleColor} />
                      </Styled.CardHeader>
                      <LogoSvg color={titleColor} />
                      <Styled.CardProduct color={titleColor}>{nome ? nome : 'Benefício CDC'}</Styled.CardProduct>
                    </Styled.CardUpside>
                    <Styled.CardDownside>
                      <Styled.CardDownSideText color={titleColor}>**** **** **** ****</Styled.CardDownSideText>
                      <Styled.CardDownSideText color={titleColor}>Válido até 10/2026</Styled.CardDownSideText>
                    </Styled.CardDownside>
                  </Styled.Card>
                </Styled.ColorRightContainer>
              </Styled.Conteiner>
            </div>
          </ModalBody>
        </FormGroup>
        <ModalFooter>
          <Button
            color="secondary"
            size="sm"
            onClick={() => {
              props.close();
              reset();
            }}
            block
          >
            Cancelar
          </Button>
          <Button
            color="success"
            size="sm"
            disabled={segmentos[0].SEGMENTOS.length === 0}
            onClick={(e) => {
              edit();
            }}
            block
          >
            Confirmar
          </Button>
        </ModalFooter>
      </Modal>

      <ModalMensagem
        modal={modalMensagem}
        mensagem={mensagem}
        sucesso={sucesso}
        toggle={(e) => {
          setModalMensagem(false);
          props.close();
          props.setReload(!props.reload);
        }}
      />
    </>
  );
};

export default EditSingleProduct;
