import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const ModalGeneric = (props) => {
  const { open, close, condition, message } = props;

  return (
    <Modal isOpen={open} toggle={close}>
      {message && (
        <>
          <ModalHeader toggle={() => close()}>{condition ? 'Sucesso' : 'Algo deu errado'}</ModalHeader>
          {message && (
            <ModalBody>
              <span style={{ fontSize: '2rem' }}>{message}</span>
            </ModalBody>
          )}
          {
            <ModalFooter>
              <Button color="primary" onClick={close}>
                Ok
              </Button>{' '}
            </ModalFooter>
          }
        </>
      )}
    </Modal>
  );
};

export default ModalGeneric;
