import { ExpandMore } from '@mui/icons-material';
import React, { useState } from 'react';
import { Button, CardFooter } from 'reactstrap';
import { Document as ModalDocument } from '../modals/index';
import isCpf, { isCnpj } from '../../../../utils/isCpf';
import AccordionRepresentativesDocuments from '../../../../components/Modais/Cliente/components/Tabs/TabDocumentos/AccordionRepresentativesDocuments';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from '@mui/material';
import { useSelector } from 'react-redux';
import AccordionDocumentos from '../../../../components/Modais/Cliente/components/Tabs/TabDocumentos/AccordionDocumentos';
import imagePDF from '../../../../assets/images/imagePDF.png'
import { baseIMG } from '../../../../service/api';

const AccordionDocumentsUser = ({ setOpenDocument, openDocument, accept, handleClick }) => {
  const {
    user: { DOCUMENTOS, CPFCNPJ, REPRESENTANTES },
  } = useSelector((state) => state.user);

  const [modalImagem, setModalImagem] = useState({
    openDocument: false,
    documentLabel: '',
    urlImage: '',
  });
  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-content">
          <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
            Documentos
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button color="primary" size="sm" style={{ borderRadius: '5px' }} onClick={() => handleClick('sendDocument')}>
            Enviar Documento
          </Button>
          <Box sx={{ mt: 2 }}>
            <Typography>
              <CardFooter >
                <Box sx={{ display: 'flex', gap: '1rem' }}>
              {DOCUMENTOS && DOCUMENTOS.length > 0 ? (
            DOCUMENTOS.map((docs) => {
              const mimeType = docs.NOMEARQUIVO.split('.')[1];
              const image = mimeType === 'pdf' ? imagePDF : baseIMG + docs.CAMINHO_ARQUIVO;
              return (
                <Card key={docs.ID} sx={{ width: 250 }}>
                  <CardActionArea
                    onClick={() =>
                      setModalImagem({
                        openDocument: true,
                        documentLabel: docs.DESCRICAO_CATEGORIA,
                        urlImage: docs.CAMINHO_ARQUIVO,
                      })
                    }
                  >
                    <CardMedia
                      component="img"
                      sx={{ padding: '1em 1em 0 1em', objectFit: 'cover' }}
                      height="100"
                      image={image}
                      alt={docs.DESCRICAO_CATEGORIA}
                      title={docs.DESCRICAO_CATEGORIA}
                    />
                    <CardContent>
                      <Typography component="p" variant="p" sx={{ fontWeight: 'bold' }}>
                        {docs.DESCRICAO_CATEGORIA}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
            })
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} mb={1}>
              <Typography>Documento não encontrado</Typography>
            </Box>
          )}
          </Box>
              </CardFooter>
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      <ModalDocument
      state={modalImagem}
      toggle={() => setModalImagem({ openDocument: false, documentLabel: '', urlImage: '' })}
    />
    </>
  );
};

export default AccordionDocumentsUser;
