import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Badge, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { formatMoney } from '../../../utils/formatMoneyBRL';
import formatCPF from '../../../utils/formatCPF';
import formatCNPJ from '../../../utils/formatCNPJ';
import moment from 'moment';
import { colorStatus } from '../../../utils/functions';
import { consultaBoleto } from '../../../store/transactions/actions.js';
import valueEmpty from '../../../utils/valueEmpty';
import isCpf from '../../../utils/isCpf';
import { Grid } from '@material-ui/core';

const ModalInformationsPayments = (props) => {
  const { modal, toggle, title, dataInfo } = props;

  const {
    CPFCNPJ,
    DATA,
    DESCRICAO,
    ID,
    ID_DISPOSITIVO,
    NOME,
    SPREAD,
    STATUSDESCRICAO,
    STATUSTRANSACAO,
    TAXA,
    VALOR,
    CODIGO_BOLETO,
    MODPAGAMENTODESCRICAO,
    VALOR_PAGO,
  } = dataInfo;

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <h3>{title}</h3>
      </ModalHeader>
      <ModalBody>
        <Grid container>
          <Grid item xs={12} md={6}>
            <h5>Código da Transação</h5>
            <p>{ID}</p>
          </Grid>
          <Grid item xs={12} md={6}>
            <h5>Status Transação</h5>
            {STATUSTRANSACAO == 1 && <Badge color="info">AGUARDANDO</Badge>}
            {STATUSTRANSACAO == 2 && <Badge color="success">APROVADO</Badge>}
            {STATUSTRANSACAO == 3 && <Badge color="danger">CANCELADO</Badge>}
            {STATUSTRANSACAO == 4 && <Badge color="secondary">VENCIDO</Badge>}
          </Grid>
          <Grid item xs={12} md={6}>
            <h5>Nome</h5>
            <p>{NOME}</p>
          </Grid>
          <Grid item xs={12} md={6}>
            <h5>CPF/CNPJ</h5>
            <p>{CPFCNPJ && isCpf(CPFCNPJ) ? formatCPF(valueEmpty(CPFCNPJ)) : formatCNPJ(valueEmpty(CPFCNPJ))}</p>
          </Grid>
          <Grid item xs={12} md={6}>
            <h5>Data / Hora:</h5>
            <p>{DATA}</p>
          </Grid>
          <Grid item xs={12} md={6}>
            <h5>Valor do Boleto</h5>
            <p>{(+VALOR).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
          </Grid>
          <Grid item xs={12} md={6}>
            <h5>Taxa</h5>
            <p>{(+SPREAD).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
          </Grid>
          <Grid item xs={12} md={6}>
            <h5>Tipo do Pagamento</h5>
            <p>{MODPAGAMENTODESCRICAO}</p>
          </Grid>
          <Grid item xs={12} md={6}>
            <h5>Valor Pago</h5>
            <p>{(+VALOR_PAGO).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
          </Grid>
          <Grid item xs={12}>
            <h5>Descrição do Pagamento</h5>
            <p>{DESCRICAO}</p>
          </Grid>
          <Grid item xs={12}>
            {STATUSTRANSACAO === '2' ? (
              <Row style={styles.cheio}>
                <Col>
                  <h5>Código do Boleto</h5>
                  <p>{CODIGO_BOLETO}</p>
                </Col>
              </Row>
            ) : null}
          </Grid>
        </Grid>
      </ModalBody>
    </Modal>
  );
};

const styles = {
  metade: { width: '49%' },
  cheio: { width: '100%' },
};

export default ModalInformationsPayments;
