import React from 'react';
import { connect } from 'react-redux';
// reactstrap components
import { Row, Button, UncontrolledAlert } from 'reactstrap';
// core components
import Table from '../../components/Table/Table.jsx';
import CustomContainer from '../../components/Custom/CustomContainer.jsx';

import Loading from '../../components/Loader/Loading.jsx';
import { STYLES } from '../../assets/EnvStyles.js';
import { getConfigs } from '../../store/config/actions.js';
import ModalConfiguracao from '../../components/Modais/Configuracao/ModalConfiguracao.jsx';
import ModalTaxasOperacionais from '../../components/Modais/Taxas/ModalTaxasOperacionais.jsx';
import formatCPF from '../../utils/formatCPF.js';
import { formatMoney } from '../../utils/formatMoneyBRL.js';
import AlertConfig from '../../components/Alertas/AlertaConfig.jsx';
import ModalSenha from '../../components/Modais/Confirmar/ModalSenha.jsx';
import valueEmpty from '../../utils/valueEmpty.js';
import GenericButton from '../../components/Buttons/Button.js';
import Icon from '../../components/Icon/Icon.styles.js';
class LimitsTab extends React.Component {
  state = {
    alert: false,
    modal: false,
    modalOperacional: false,
    dadosModal: [],
    title: 'Configuração',
    modalSenha: false,
    nextFunction: () => {},
  };

  componentDidMount() {
    this.props.getConfigs();
  }

  render() {
    const { listConfigurations, load_config, mensagemConfig, mensagemComValor, color } = this.props.config;
    const valores = [{ ID: '3', DESCRICAO: 'CUSTOS OPERACIONAIS' }];

    const toggle = () => this.setState({ modal: !this.state.modal });
    const alterAlert = () => this.setState({ alert: true });
    const toggleAlert = () => this.setState({ alert: !this.state.alert });
    const toggleOperacional = () => this.setState({ modalOperacional: !this.state.modalOperacional });

    return (
      <div>
        <AlertConfig
          isOpen={this.state.alert}
          toggle={toggleAlert}
          mensagem={mensagemConfig}
          color={color}
        ></AlertConfig>

        <Row>
          <div className="col">
            {load_config ? (
              <Loading color="#11cdef" />
            ) : (
              <div style={{ margin: 20 }}>
                <Table
                  headers={[
                    {
                      info: 'Descrição',
                      style: { textAlign: 'left' },
                    },

                    {
                      info: 'Valor',
                      style: { textAlign: 'right' },
                    },
                    {
                      info: 'CPF Editor',
                      style: { textAlign: 'left' },
                    },
                    {
                      info: 'Data Modificação',
                      style: { textAlign: 'center' },
                    },
                    {
                      info: '',
                      style: { textAlign: 'center' },
                    },
                  ]}
                  data={
                    listConfigurations.length ? (
                      listConfigurations.map((item, index) => {
                        return {
                          data: [
                            {
                              info: valueEmpty(item.DESCRICAO),
                              style: { textAlign: 'left' },
                            },
                            {
                              info: item.VALOR,

                              style: { textAlign: 'right' },
                            },
                            {
                              info: formatCPF(item.ADMIN),
                              style: { textAlign: 'left' },
                            },
                            {
                              info: item.DATAHORA == '00/00/0000 00:00:00' || null ? 'Não modificado' : item.DATAHORA,
                              style: { textAlign: 'center' },
                            },
                            {
                              info: (
                                <Row
                                  style={{
                                    justifyContent: 'flex-end',
                                    marginRight: 10,
                                  }}
                                >
                                  <GenericButton
                                    onClick={() => {
                                      this.setState({
                                        nextFunction: () => this.setState({ modal: true }),
                                        dadosModal: {
                                          DESCRICAO: item.DESCRICAO,
                                          ID: item.ID,
                                          VALOR: item.VALOR,
                                          DIAS: item.DIAS,
                                        },
                                        title: 'Configuração',
                                        modalSenha: true,
                                      });
                                    }}
                                    color="info "
                                    size="sm"
                                  >
                                    <Icon className="fas fa-edit"></Icon>
                                    Editar
                                  </GenericButton>
                                </Row>
                              ),
                              style: { textAlign: 'center' },
                            },
                          ],
                        };
                      })
                    ) : (
                      <h3>Nenhum registro encontrado</h3>
                    )
                  }
                />
              </div>
            )}
          </div>
        </Row>

        <div>
          <ModalConfiguracao
            alerta={alterAlert}
            title={this.state.title}
            modal={this.state.modal}
            toggle={toggle}
            lista={listConfigurations}
            dadosModal={this.state.dadosModal}
          />
          <ModalTaxasOperacionais modal={this.state.modalOperacional} toggle={toggleOperacional} />
          <ModalSenha
            modal={this.state.modalSenha}
            close={() => {
              this.setState({ modalSenha: false });
            }}
            nextFunction={this.state.nextFunction}
          />
        </div>
      </div>
    );
  }
}

const styles = {
  inteiro: { width: '100%' },
  meio: { height: '50%' },
  inputStyle: { border: '1px solid', borderColor: '#d8d9da' },
  textStyle: { marginLeft: 10 },
};

const mapStateToProps = ({ config, transactions }) => {
  return { config, transactions };
};
export default connect(mapStateToProps, {
  getConfigs,
})(LimitsTab);
