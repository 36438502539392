import React from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import { connect } from 'react-redux';
import { updateConfig } from '../../../store/config/actions';
import { updateTaxaConfigs } from '../../../store/config/actions';
import { getConfigs } from '../../../store/config/actions';
import ModalMensagem from '../../../views/Transaction/ModalMensagem';
import TextInputMask from 'react-masked-text';
import formatTextCamelCase from '../../../utils/formatTextCamelCase';
class ModalConfiguracao extends React.Component {
  state = {
    valor: '',
    modal: false,
    mensagem: '',
    modalMensagem: false,
    sucesso: false,
  };

  handle(e) {
    if (e.length < 17) {
      this.props.dadosModal.VALOR = e;
      this.setState({ valor: e });
    }
    return this.props;
  }

  callUpdateConfig = () => {
    if (this.state.valor) {
      const { DESCRICAO, ID, VALOR } = this.props.dadosModal;

      const obj = {
        DESCRICAO: DESCRICAO,
        ID: ID,
        VALOR: VALOR,
      };

      this.props.updateConfig(obj, (res) => {
        const { mensagem, retorno } = res;

        this.setState({
          sucesso: retorno == 'error' ? false : true,
          mensagem: mensagem,
          modalMensagem: true,
        });
      });
    }
  };

  onChange = (value, index) => {
    const { listTaxas } = this.props.auth;
    listTaxas[index].TAXA = value;
    this.setState({ listTaxas });
  };

  comparaButton(status) {
    return (
      <Button color="success" size="sm" block onClick={() => this.callUpdateConfig()}>
        Confirmar
      </Button>
    );
  }

  renderForm() {
    return (
      <Form style={{ width: '100%' }} onSubmit={(e) => e.preventDefault()}>
        {' '}
        <section style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
          <h4 style={{ margin: '1rem 0' }}> {formatTextCamelCase(this.props.dadosModal.DESCRICAO)}</h4>
          <TextInputMask
            id="maskedInput"
            autoFocus
            /*          kind={this.props.dadosModal.VALOR && this.props.dadosModal.VALOR.includes('R$') ? 'money' : 'datetime'} */
            kind={this.props.dadosModal.VALOR && 'money'}
            options={{
              format: 'HH:mm',
            }}
            ref={'rawValue'}
            style={{
              border: '1px solid rgb(206, 212, 218)',
              borderRadius: '0.25rem',
              color: '#8898aa',
              margin: 'auto',
            }}
            value={this.props.dadosModal.VALOR}
            onChangeText={(e) => {
              this.handle(e);
            }}
          />
        </section>
      </Form>
    );
  }

  componentDidUpdate() {
    this.manageInputsFocus();
  }

  async manageInputsFocus() {
    const okButton = document.getElementById('success-button');
    let input = document.getElementById('maskedInput');
    if ((await this.props.modal) && input && !okButton) {
      setTimeout(() => {
        input.focus();
      }, 500);
    }
    if (okButton) {
      okButton.focus();
    }
  }

  handleKeyDown(e) {
    if (e.key === 'Enter') this.callUpdateConfig();
  }

  render() {
    const { modal, toggle, dadosModal, title } = this.props;
    const toggle1 = () => this.setState({ modal: !this.state.modal });
    const { listTaxas } = this.props.auth;

    return (
      <div onKeyDown={(e) => this.handleKeyDown(e)}>
        <Modal isOpen={modal} toggle={toggle} dadosModal={dadosModal} title={title} autoFocus={false}>
          <ModalHeader toggle={toggle}>
            <h2>{title}</h2>
          </ModalHeader>
          <ModalBody
            onKeyDown={(e) => (e.key === 'Enter' ? this.callUpdateConfig() : null)}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              wordBreak: 'break-word',
            }}
          >
            {this.renderForm(dadosModal)}
          </ModalBody>
          <ModalFooter style={{ display: 'flex', alignItems: 'baseline' }}>
            <Button
              color="secondary"
              size="sm"
              block
              onClick={() => {
                toggle();
                this.props.getConfigs();
              }}
            >
              Cancelar
            </Button>
            <Button color="success" size="sm" block onClick={() => this.callUpdateConfig()}>
              Confirmar
            </Button>
          </ModalFooter>

          <ModalMensagem
            mensagem={this.state.mensagem}
            modal={this.state.modalMensagem}
            sucesso={this.state.sucesso}
            toggle={() => this.setState({ modalMensagem: true })}
          />
        </Modal>
      </div>
    );
  }
}

const styles = {
  inteiro: { width: '100%' },
  meio: { height: '50%' },
  inputStyle: {
    border: '1px solid',
    alignSelf: 'right',
    borderColor: '#d8d9da',
  },
  textStyle: { marginLeft: 10 },
};

const mapStateToProps = ({ user, auth, config }) => {
  return { user, auth, config };
};

export default connect(mapStateToProps, {
  updateConfig,
  updateTaxaConfigs,
  getConfigs,
})(ModalConfiguracao);
