import styled, { css } from 'styled-components';
import { Modal } from 'reactstrap';

export const ModalSaldo = styled(Modal)`
  .modal-content {
    width: fit-content;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1vw;

  & span {
    text-transform: initial;
    font-size: 0.9rem;
  }
`;

export const ContainerBox = styled.div`
  padding: 1.4rem;

  & .titleBox {
    display: flex;
    justify-content: flex-start;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1.2rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  .MuiTextField-root {
    background: rgba(51, 51, 51, 0.05);
    margin-bottom: 0.6rem;
  }
  .MuiFormLabel-root {
    margin-left: 1.389vw;
    padding-top: 0.2vw;
    height: 3.333vw;
  }
  .MuiInputBase-input {
    margin-left: 1.389vw;
    margin-right: 1vw;
  }
  .buttons {
    display: flex;
    padding-top: 2rem;
  }
`;

export const ContainerOperateBalanceBoxes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
