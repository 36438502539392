import React, { useEffect, useState } from 'react';
import './styles.css';
import { AiOutlineHome } from 'react-icons/ai';
import { Alert, Button, CustomInput, Input, Modal, ModalBody } from 'reactstrap';
import Loading from '../../../../../../components/Loader/Loading';
import {
  ColorsContainer,
  MainContainer,
  PostButton,
  PreviewButtonSendDiv,
  ModalCloser,
  PreviewContainer,
} from './styles';

import { FaRegMoneyBillAlt, FaUser } from 'react-icons/fa';
import { ColorCard } from './styles';
import api from '../../../../../../service/api';
import ModalMensagemSemReloadNew from '../../../../../Transaction/ModalMensagemSemReloadNew';
import LogoSvg from '../../../LogoSvg/LogoSvg';
import { getBase64 } from '../../../../../../utils/functions';
import { PreviewWhiteLabel } from '../../../PreviewWhiteLabel/PreviewWhiteLabel';
import { PreviewSelectorColor } from '../../../PreviewSelectColor';

export default function ModalWhiteLabel({ toggle, open, callback, user, setUser }) {
  const [logo, setLogo] = useState(false);
  const defaultColors = {
    primary: '#c6d4e9',
    secondary: '#FFFFFF',
    primary_text: '#FFFFFF',
    secondary_text: '#556CD6',
    icon_menu: '#e8e8f0',
    icon_menu_text: '#7fc8e9',
    icon: '#7fc8e9',
    icon_text: '#FFFFFF',
    table: '#878dac',
    table_text: '#e8e8f0',
  };

  const [colors, setColors] = useState(defaultColors);

  const [modalSuccess, setModalSuccess] = useState(false);
  const [message, setMessage] = useState(false);
  const [succes, setSucces] = useState(false);
  const [userColors, setUserColors] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingChange, setLoadingChange] = useState(false);

  useEffect(() => {
    if (user) {
      getColors(user);
      /*  getLogo(user); */
    }
  }, [user]);

  const getColors = async (user) => {
    try {
      setLoading(true);
      const data = await api.get(`/rh/cores-white-label?company_uuid=${user.UUID}`);

      if (data && data.data && data.data.return) {
        const colors = data.data.data;
        setColors({
          primary: colors.primary_bg_color,
          secondary: colors.secondary_bg_color,
          primary_text: colors.primary_text_color,
          secondary_text: colors.secondary_text_color,
          icon_menu: colors.menu_icon_bg_color,
          icon_menu_text: colors.menu_icon_text_color,
          icon: colors.icon_bg_color,
          icon_text: colors.icon_text_color,
          table: colors.table_bg_color,
          table_text: colors.table_text_color,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  /*  function getLogo() {
    setLoading(true);
    api
      .get(`/rh/logo-white-label?company_uuid=${user.UUID}`)
      .then((res) => {
        if (res.data.data && res.data.return) {
          setLogo(res.data.data);
        }
      })
      .finally(() => setLoading(false));
  } */

  /*  function saveLogoAndColors(colors, user, logo) {
    setLoading(true);
    api
      .post(`/rh/logo-white-label`, {
        uuid_company: user.UUID,
        logo,
      })
      .then((res) => {
        if (res.data.data && res.data.return) {
          handleColors(colors, user);
        } else {
          setSucces(false);
          setModalSuccess(true);
          setMessage('Falha ao salvar logo');
        }
      })
      .catch(() => {
        setSucces(false);
        setModalSuccess(true);
        setMessage('Algo inesperado aconteceu');
      })
      .finally(() => setLoading(false));
  } */

  const handleColors = (colors, user) => {
    setLoading(true);
    try {
      api.post('/rh/cores-white-label', { uuid_companie: user.UUID, ...colors }).then((res) => {
        if (res.data.return) {
          setModalSuccess(true);
          setMessage('White label salvo com sucesso!');
          setSucces(true);
          setLoading(false);
          closeModal();
        } else {
          setSucces(false);
          setModalSuccess(true);
          setLoading(false);
          setMessage('Algo inesperado aconteceu');
        }
      });
    } catch (error) {
      setSucces(false);
      setModalSuccess(true);
      setSucces(false);
      setMessage('Algo inesperado aconteceu');
      setLoading(false);
    }
  };

  function handleSave(colors, user, logo = null) {
    /*  if (logo) {
      saveLogoAndColors(colors, user, logo);
    } else { */
    handleColors(colors, user);
    /*   } */
  }

  const closeModal = () => {
    toggle();
    /* setLogo(false); */
    setColors(defaultColors);
    setUser(false);
  };

  /*   var onChangeHandler = async (event) => {
    //setSelectedFile(event.target.files[0])
    const convert = await getBase64(event.target.files[0]);

    setLogo(convert);
  }; */

  return (
    <>
      <Modal isOpen={open} toggle={toggle} size="xl" style={{ maxWidth: '90%' }}>
        {/*     <ModalHeader style={{ padding: '0.25rem' }} toggle={toggle}></ModalHeader> */}
        {!loading ? (
          <ModalBody style={{ padding: 0 }}>
            <ModalCloser>
              <button onClick={() => closeModal()}>x</button>
            </ModalCloser>
            <MainContainer>
              <PreviewSelectorColor colors={colors} setColors={setColors} />

              <PreviewContainer>
                <div style={{ width: '100%' }}>
                  {/*  <Button
                    style={{ margin: '10px 0px 10px 25px', background: 'rgb(85, 108, 214)', color: '#fff' }}
                    size="sm"
                  >
                    <Input
                      onChange={onChangeHandler}
                      style={{ background: 'transparent' }}
                      type="file"
                      accept=".png, .jpg"
                    ></Input>
                  </Button> */}
                </div>
                <PreviewWhiteLabel
                  /* onChangeHandler={onChangeHandler} */ logo={logo}
                  setLogo={setLogo}
                  colors={colors}
                />

                <PreviewButtonSendDiv>
                  <PostButton onClick={() => handleSave(colors, user, logo)}>salvar</PostButton>
                </PreviewButtonSendDiv>
              </PreviewContainer>
            </MainContainer>
          </ModalBody>
        ) : (
          <Loading color="blue" />
        )}
      </Modal>

      <ModalMensagemSemReloadNew
        open={modalSuccess}
        close={() => setModalSuccess(false)}
        callback={() => setModalSuccess(false)}
        sucesso={succes}
        mensagem={message}
        title={succes && 'Sucesso'}
      />
    </>
  );
}
