import React from 'react';
import Table from '../Table/Table';

import Loading from '../Loader/Loading';
import { Box } from '@mui/material';

function UsuariosRHTable({ users, loading }) {
  return (
    <Box width={'100%'}>
      {!loading ? (
        <>
          {users && users.length > 0 ? (
            <Table
              style={{ marginTop: '3rem' }}
              size="sm"
              headers={[
                {
                  info: 'Nome',
                  style: { textAlign: 'center' },
                },
                {
                  info: 'E-mail',
                  style: { textAlign: 'center' },
                },

                {
                  info: 'Empresa',
                  style: { textAlign: 'center' },
                },
              ]}
              data={users.map((user, index) => {
                return {
                  data: [
                    {
                      info: user.name,
                      style: { textAlign: 'center' },
                    },
                    {
                      info: user.email,
                      style: { textAlign: 'center' },
                    },

                    {
                      info: user.company_name,
                      style: { textAlign: 'center' },
                    },
                  ],
                };
              })}
            />
          ) : (
            <Box display="flex" flexDirection="column" width="100%">
              <Table
                style={{ marginTop: '3rem' }}
                size="sm"
                headers={[
                  {
                    info: 'Nome',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'E-mail',
                    style: { textAlign: 'center' },
                  },

                  {
                    info: 'Empresa',
                    style: { textAlign: 'center' },
                  },
                ]}
              />
              <h3 style={{ textAlign: 'center', marginTop: '1rem' }}>Nenhum registro encontrado</h3>
            </Box>
          )}
        </>
      ) : (
        <Box display="flex" flexDirection="column" width="100%">
          <Loading color="blue" />
        </Box>
      )}
    </Box>
  );
}

export default UsuariosRHTable;
