import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
// reactstrap components
import { Row, Button, Form } from 'reactstrap';
// core components
import Table from '../../components/Table/Table.jsx';
import CustomContainer from '../../components/Custom/CustomContainer.jsx';
import Loading from '../../components/Loader/Loading';
import { formatMoney } from '../../utils/formatMoneyBRL';
import { getTotalTransactions, getTotalTransfer } from '../../store/transactions/actions.js';
import ModalInfoTransfer from './ModalInfoTransferBetweenAccounts';
import * as Styled from '../../components/Filter/styles/Filter.style';
import InputDate from '../../components/Filter/components/InputDate';
import InputSelectAmountResults from '../../components/Filter/components/InputSelectAmountResults';
import InputSearch from '../../components/Filter/components/InputSearch';
import Filter from '../../components/Filter/Filter';
import valueEmpty from '../../utils/valueEmpty.js';
import formatCPF from '../../utils/formatCPF';
import formatCNPJ from '../../utils/formatCNPJ.js';

import moment from 'moment';
import GenericButton from '../../components/Buttons/Button.js';
import Icon from '../../components/Icons/IconsButton.js';
import isCpf from '../../utils/isCpf.js';

export default function Transferencias() {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    search: '',
    DATAINICIAL: '',
    DATAFINAL: moment().format('YYYY-MM-DD'),
    limit: 10,
    page: 1,
    transferenciaInterna: true,
  });
  const [modal, setModal] = useState(false);
  const [dataLoan, setDataLoan] = useState([]);

  useEffect(() => {
    dispatch(getTotalTransfer(filter));
  }, [filter.page]);

  let { page } = filter;
  const { totalTransfer, pagination, loading_log } = useSelector((state) => state.transactions);
  const tablePagination = async (type = 'atual', pageClick) => {
    switch (type) {
      case 'prev':
        page = pagination.prevPage;
        break;
      case 'next':
        page = pagination.nextPage;
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }
    await setFilter({ ...filter, page });
  };

  const closeModal = () => setModal(!modal);

  const openModalLoan = (item) => {
    setDataLoan(item);
    setModal(true);
  };

  return (
    <div>
      <CustomContainer>
        <Filter>
          <Form className="form" onSubmit={(e) => e.preventDefault()}>
            <Styled.InputsContainer>
              <InputSearch
                width="30vw"
                value={filter.search}
                setValue={(value) => setFilter({ ...filter, search: value })}
              />

              <InputDate
                label="Data Inicial"
                name="DATAINICIAL"
                value={filter.DATAINICIAL}
                setValue={(value) => setFilter({ ...filter, DATAINICIAL: value })}
              />

              <InputDate
                label="Data Final"
                name="DATAFINAL"
                value={filter.DATAFINAL}
                setValue={(value) => setFilter({ ...filter, DATAFINAL: value })}
              />

              <div style={{ alignSelf: 'flex-end' }}>
                <GenericButton
                  marginTop={'25px !important'}
                  width={'140px'}
                  heigth={'35px'}
                  type="submit"
                  size="sm"
                  onClick={() => {
                    dispatch(getTotalTransfer(filter));
                    filter.page = '1';
                  }}
                >
                  <Icon className="fas fa-search"></Icon>Buscar
                </GenericButton>
              </div>
            </Styled.InputsContainer>
            <div>
              <InputSelectAmountResults
                options={['10', '25', '50', '100', '1000']}
                value={filter.limit}
                setValue={(value) => setFilter({ ...filter, limit: value })}
              />
            </div>
          </Form>
        </Filter>
        <Row>
          <div className="col">
            {totalTransfer === null || totalTransfer.length === 0 || !totalTransfer.PAGINATION ? (
              <h4 style={{ textAlign: 'center' }}>Carregando Transações</h4>
            ) : (
              <div>
                {loading_log ? (
                  <Loading color="#11cdef" />
                ) : (
                  <Table
                    headers={[
                      {
                        info: 'Data/Hora',
                        style: { textAlign: 'center' },
                      },
                      {
                        info: 'CPF/CNPJ Remetente',
                        style: { textAlign: 'left' },
                      },
                      {
                        info: 'Remetente',
                        style: { textAlign: 'left' },
                      },
                      {
                        info: 'Destinatário',
                        style: { textAlign: 'left' },
                      },
                      {
                        info: 'Valor',
                        style: { textAlign: 'center' },
                      },
                    ]}
                    data={
                      totalTransfer.TRANSACAO &&
                      totalTransfer.TRANSACAO.map((item) => {
                        return {
                          data: [
                            {
                              info: valueEmpty(item.DATA),
                              style: { textAlign: 'center' },
                            },
                            {
                              info: isCpf(item.CPFCNPJ)
                                ? formatCPF(valueEmpty(item.CPFCNPJ))
                                : formatCNPJ(valueEmpty(item.CPFCNPJ)),
                              style: { textAlign: 'left' },
                            },
                            {
                              info: valueEmpty(item.REMETENTE),
                              style: { textAlign: 'left' },
                            },
                            {
                              info: valueEmpty(item.DESTINATARIO),
                              style: { textAlign: 'left' },
                            },

                            {
                              info: valueEmpty('R$ ' + formatMoney(item.VALOR)),
                              style: { textAlign: 'center' },
                            },
                          ],
                          onClick: () => openModalLoan(item),
                        };
                      })
                    }
                    pagination={pagination}
                    previousPage={{ onClick: () => tablePagination('prev') }}
                    nowPage={{ page, onClick: pagination }}
                    nextPage={{ onClick: () => tablePagination('next') }}
                  />
                )}
              </div>
            )}
          </div>
        </Row>
      </CustomContainer>

      <ModalInfoTransfer modal={modal} toggle={closeModal} dadosModal={dataLoan} title={'Dados da transferência'} />
    </div>
  );
}

const styles = {
  form: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'baseline',
  },
  label: {
    width: 'auto',
  },
  form_1: {
    flexDirection: 'row',
    display: 'flex',
    marginLeft: -14,
    marginBottom: 13,
  },
  input: {
    marginLeft: 10,
    width: 300,
  },
  form_2: {
    flexDirection: 'row',
    display: 'flex',
  },
  buscar: {
    display: 'flex',
    padding: 10,
    marginLeft: 21,
  },
};
