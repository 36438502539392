import React, { useEffect, useState } from 'react';

import { Icon } from '@mui/material';
import { Form, Input, Label } from 'reactstrap';
import GenericButton from '../../components/Buttons/Button';
import CustomContainer from '../../components/Custom/CustomContainer';
import InputDate from '../../components/Filter/components/InputDate';
import InputSearch from '../../components/Filter/components/InputSearch';
import PedidosRHTable from '../../components/PedidosRHTable/PedidosRHTable';
import './styles.css';
import moment from 'moment';
import api from '../../service/api';
import ModalPedidos from './ModalPedidos';
import ModalMensagemSemReloadNew from '../Transaction/ModalMensagemSemReloadNew';
import InputSelectAmountResults from '../../components/Filter/components/InputSelectAmountResults';
import { Filter } from '@mui/icons-material';

function PedidosRH(props) {
  const [orders, setOrders] = useState(false);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [modalOrder, setModalOrder] = useState(false);
  const [observation, setObservation] = useState('');
  const [detailedOrder, setDetailedOrder] = useState(false);
  const [orderPagination, setOrderPagination] = useState(false);
  const [selectCompanies, setSelectCompanies] = useState(false);
  const [orderBy, setOrderBy] = useState(false);
  const [orderByDetails, setOrderByDetails] = useState(false);
  const [filter, setFilter] = useState({
    status: '',
    uuid_empresa: '',
    data_inicio: moment().add(-30, 'days').format('YYYY-MM-DD'),
    data_fim: moment().format('YYYY-MM-DD'),
    limit: 10,
    page: 1,
    modo_pagamento: '',
    order_by: '',
    status_variant: 6,
    asc_desc_variant: 'DESC',
  });

  function openModalOrder(order) {
    setDetailedOrder(order);
    setModalOrder(true);
  }
  function closeModalOrder() {
    setModalOrder(false);
    setDetailedOrder(false);
  }

  function getOrdersRH() {
    setLoading(true);
    api
      .get(
        `/conveniada/listar-pedidos?status=${filter.status}&uuid_empresa=${filter.uuid_empresa}&data_inicio=${filter.data_inicio}&data_fim=${filter.data_fim}&limit=${filter.limit}&page=${filter.page}&modo_pagamento=${filter.modo_pagamento}&order_by=${filter.order_by}&status_variant=${filter.status_variant}&asc_desc_variant=${filter.asc_desc_variant}
        `,
      )
      .then(({ data }) => {
        if (data) {
          setOrders(data.data);
          setOrderPagination(data.pagination);
          setLoading(false);
        }
      })
      .catch(() => setLoading(false));
  }

  function selectCompaniesOrders() {
    /* setLoading(true); */
    api.get(`/conveniada/pedidos/select`).then(({ data }) => {
      if (data) {
        setSelectCompanies(data.data);

        /*  setLoading(false); */
      }
    });
  }

  function approveOrder(uuid) {
    setLoading(true);
    setModalOrder(false);
    api
      .patch(`/conveniada/aprova-pedido/${uuid}`)
      .then(({ data }) => {
        if (data) {
          setFeedback(true);
          setFeedbackMessage(data.mensagem);
          getOrdersRH(false);
        }
      })
      .catch((error) => {
        setFeedback(true);
        setFeedbackMessage(error.response.data.mensagem);
        setLoading(false);
      });
  }

  function reproveOrder(uuid, observation) {
    setLoading(true);
    setModalOrder(false);
    api
      .patch(`/conveniada/reprova-pedido/${uuid}`, { observation })
      .then(({ data }) => {
        if (data) {
          setFeedback(true);
          setFeedbackMessage(data.mensagem);

          getOrdersRH();
        }
      })
      .catch((error) => {
        setFeedback(error);
        setFeedbackMessage(error.response.data.mensagem);
        setLoading(false);
      });
  }

  useEffect(() => {
    getOrdersRH();
    selectCompaniesOrders();
  }, []);

  function submitForm(e) {
    e.preventDefault();
    setFilter({ ...filter, page: 1 });
    getOrdersRH();
  }

  useEffect(() => {
    getOrdersRH();
  }, [filter.page]);

  useEffect(() => {
    if (filter.page === 1) {
      getOrdersRH();
    } else {
      setFilter({ ...filter, page: 1 });
    }
  }, [filter.limit]);

  useEffect(() => {
    getOrdersRH();
  }, [filter.status_variant]);

  useEffect(() => {
    getOrdersRH();
  }, [filter.asc_desc_variant]);

  useEffect(() => {
    getOrdersRH();
  }, [filter.order_by]);

  return (
    <>
      <Form className="form">
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
          <div style={{ marginRight: '1rem' }}>
            <InputDate
              label="Data Inicial"
              name="DATA_INICIO"
              value={filter.data_inicio}
              setValue={(value) => setFilter({ ...filter, data_inicio: value })}
            />
          </div>
          <div style={{ marginRight: '1rem' }}>
            <InputDate
              label="Data Final"
              name="DATA_FIM"
              value={filter.data_fim}
              setValue={(value) => setFilter({ ...filter, data_fim: value })}
            />
          </div>

          <div style={{ marginRight: '1rem', width: '10rem' }}>
            <Label size="sm">Empresa</Label>
            <Input
              onChange={(e) => setFilter({ ...filter, uuid_empresa: e.target.value })}
              type="select"
              name="status"
              id="status"
              size="sm"
              value={filter.company}
              /*     style={width && { width }} */
            >
              <option value={''}>Todos</option>
              {selectCompanies &&
                selectCompanies.map((option) => {
                  return (
                    <option key={option.UUID_EMPRESA} value={option.UUID_EMPRESA}>
                      {option.NOME}
                    </option>
                  );
                })}
            </Input>
          </div>
          <div style={{ marginRight: '1rem' }}>
            <Label size="sm">Status</Label>
            <Input
              onChange={(e) => setFilter({ ...filter, status: e.target.value })}
              type="select"
              name="status"
              id="status"
              size="sm"
              value={filter.company}
              style={{ width: '8rem' }}
            >
              <option value={''}>Todos</option>
              <option value={6}>Pendente</option>
              <option value={2}>Aprovado</option>
              <option value={3}>Cancelado</option>
            </Input>
          </div>
          <div style={{ marginRight: '1rem' }}>
            <Label size="sm">Forma de pagamento</Label>
            <Input
              onChange={(e) => setFilter({ ...filter, modo_pagamento: e.target.value })}
              type="select"
              name="modo_pagamento"
              id="modo_pagamento"
              size="sm"
              value={filter.modo_pagamento}
              style={{ width: '8rem' }}
            >
              <option value={''}>Todos</option>
              <option value={2}>Boleto</option>
              <option value={18}>Débito em conta</option>
            </Input>
          </div>

          <div style={{ marginRight: '1rem' }}>
            <Label size="sm">Ordenar por</Label>
            <Input
              onChange={(e) => setFilter({ ...filter, order_by: e.target.value })}
              type="select"
              name="modo_pagamento"
              id="modo_pagamento"
              size="sm"
              value={filter.order_by}
              style={{ width: '8rem' }}
            >
              <option selected value={''}>
                Padrão
              </option>
              <option selected value={'status'}>
                Status
              </option>
              <option value={'data'}>Data</option>
              <option value={'valor'}>Valor</option>
            </Input>
          </div>

          {filter.order_by && (
            <div style={{ marginRight: '1rem' }}>
              <>
                {filter.order_by === 'status' ? (
                  <>
                    <Label size="sm">Ordem status</Label>
                    <Input
                      onChange={(e) => setFilter({ ...filter, status_variant: e.target.value })}
                      type="select"
                      name="order_variant"
                      id="order_variant"
                      size="sm"
                      value={filter.status_variant}
                      style={{ width: '8rem' }}
                    >
                      <option value={6}>Pendente</option>
                      <option value={2}>Aprovado</option>
                      <option value={3}>Cancelado</option>
                    </Input>{' '}
                  </>
                ) : (
                  <>
                    <Label size="sm">Crescente/Decrescente</Label>
                    <Input
                      onChange={(e) => setFilter({ ...filter, asc_desc_variant: e.target.value })}
                      type="select"
                      name="order_variant"
                      id="order_variant"
                      size="sm"
                      value={filter.order_variant}
                      style={{ width: '8rem' }}
                    >
                      <option selected="selected" value={'DESC'}>
                        Decrescente
                      </option>
                      <option value={'ASC'}>Crescente</option>
                    </Input>{' '}
                  </>
                )}
              </>
            </div>
          )}

          <div style={{ alignSelf: 'flex-end' }}>
            <GenericButton onClick={(e) => submitForm(e)} height="30px" className="mt-3" size="sm" type="submit">
              <Icon style={{ marginRight: '.4rem', fontSize: '1rem' }} className="fas fa-search"></Icon>Buscar
            </GenericButton>
          </div>
        </div>
        <div
          style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', flexWrap: 'wrap', marginTop: '1rem' }}
        >
          <InputSelectAmountResults
            options={['10', '25', '50', '100', '1000']}
            value={filter.limit}
            setValue={(value) => setFilter({ ...filter, limit: value })}
            width={'10rem'}
          />
        </div>
      </Form>
      <PedidosRHTable
        orderPagination={orderPagination}
        orders={orders}
        loading={loading}
        openModalOrder={openModalOrder}
        page={filter.page}
        setFilter={setFilter}
        filter={filter}
      />

      <ModalPedidos
        approveOrder={approveOrder}
        reproveOrder={reproveOrder}
        modal={modalOrder}
        orderId={detailedOrder}
        loading={loading}
        toggle={() => closeModalOrder()}
        observation={observation}
        setObservation={setObservation}
      />
      <ModalMensagemSemReloadNew
        open={feedback}
        close={() => setFeedback(false)}
        callback={() => setFeedback(false)}
        sucesso={feedbackMessage === 'Sucesso!'}
        mensagem={feedbackMessage}
        title={feedbackMessage === 'Sucesso!' ? 'Sucesso' : 'Erro'}
      />
    </>
  );
}

export default PedidosRH;
