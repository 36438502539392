import { Modal, TableCell } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { VscChromeClose } from 'react-icons/vsc';

import Loading from '../../components/Loader/Loading';
import { CloseStyle, HeaderStyle, PostButton, TitleStyle } from './style';
import api from '../../service/api';
import Table from '../../components/Table/Table';
import { valueEmpty } from '../../utils';
import { formatDateBR } from '../../utils/dateKyc';

export default function ModalHistorico({ open, close, chooseBadge, id }) {
  const [requests, setRequests] = useState(false);
  const [loading, setLoading] = useState(false);

  function getHistory() {
    setLoading(true);
    api
      .get(`/solicitacoes/segunda-via-cartao/historico?ID_SOLICITCAO=${id}`)
      .then(({ data }) => {
        setRequests(data.data);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (id) {
      getHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#fff',
    borderRadius: '15px',
    boxShadow: 24,
    minWidth: 900,
    p: 4,
    ovewflowX: 'scroll',
  };

  return (
    <Modal open={open} onClose={close} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <HeaderStyle>
          <TitleStyle>Histórico da solicitação</TitleStyle>
          <CloseStyle onClick={close}>
            <VscChromeClose />
          </CloseStyle>
        </HeaderStyle>
        <>
          {!loading ? (
            <>
              <Table
                size="sm"
                headers={[
                  {
                    info: 'ID',
                    style: { textAlign: 'start' },
                  },
                  {
                    info: 'Data',
                    style: { textAlign: 'start' },
                  },
                  {
                    info: 'Observação',
                    style: { textAlign: 'center' },
                  },

                  {
                    info: 'Status',
                    style: { textAlign: 'center' },
                  },
                ]}
                data={
                  requests &&
                  requests.map((request, index) => {
                    return {
                      data: [
                        {
                          info: request.ID,
                          style: { textAlign: 'start' },
                        },
                        {
                          info: formatDateBR(request.DATA_INSERT),
                          style: { textAlign: 'start' },
                        },
                        {
                          info: request.OBSERVACAO,
                          style: { textAlign: 'center' },
                        },
                        {
                          info: valueEmpty(request.FK_STATUS_SEGUNDA_VIA && chooseBadge(request.FK_STATUS_SEGUNDA_VIA)),
                          style: { textAlign: 'center' },
                        },
                      ],
                    };
                  })
                }
              ></Table>
            </>
          ) : (
            <Loading color="blue"></Loading>
          )}
        </>
      </Box>
    </Modal>
  );
}
