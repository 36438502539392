// reactstrap components
import { Button, Card, CardBody, Col, Container, Input, Row } from 'reactstrap';
import { FUNDO_LOGIN, LOGO } from '../../assets/EnvStyles.js';
// Actions
import { login, validateLogin } from '../../store/auth/actions';

import Loading from '../../components/Loader/Loading.jsx';
import React from 'react';
// import Recaptcha from 'react-recaptcha';
import { connect } from 'react-redux';
import removeMask from '../../utils/removeMask.js';

// core components

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CPFCNPJ: '',
      FONECEL: '',
      SENHA: '',
      TOKEN: '',
      QRCODE: '',
      step: 1,
      errorSenha: 0,
      lockField: true,
      isVerified: false,
      modal: false,
      recaptchaLoaded: false,
    };
    this.login = this.login.bind(this);
    this.validate = this.validate.bind(this);
    // this.onRecaptchaSuccess = this.onRecaptchaSuccess.bind(this);
    // this.onRecaptchExpired = this.onRecaptchExpired.bind(this);
    // this.handleRecaptchaLoad = this.handleRecaptchaLoad.bind(this);
  }

  componentDidMount() {
    document.body.classList.add('bg-default');
    if (this.props.auth.is_authenticate) this.props.history.push('/admin/dashboard');
  }
  componentWillUnmount() {
    document.body.classList.remove('bg-default');
  }

  componentDidUpdate() {
    return true;
  }

  login() {
    const { CPFCNPJ, FONECEL, SENHA } = this.state;

    if (CPFCNPJ == '') {
      return this.setState({ message: 'É necessário inserir o CPF ou CNPJ' });
    }
    if (FONECEL == '') {
      return this.setState({ message: 'É necessário inserir o telefone' });
    }
    if (SENHA == '') {
      return this.setState({ message: 'É necessário inserir a senha' });
    }
    // if (!this.state.isVerified && this.state.recaptchaLoaded) {
    //   return this.setState({ message: 'É necessário completar o captcha' });
    // }

    this.props.login({ CPFCNPJ, FONECEL, SENHA }, (e, res) => {
      if (e) this.setState({ QRCODE: res.data.QRCODE, step: 2 });
      else {
        if (res.response) this.setState({ message: res.response.data.mensagem });
        else this.setState({ message: 'Sem conexão com a internet' });
      }
    });
  }

  validate() {
    const { CPFCNPJ, FONECEL, SENHA, TOKEN, QRCODE } = this.state;

    if (TOKEN == '') return this.setState({ message: 'É necessário inserir o Token' });
    this.props.validateLogin({ CPFCNPJ, FONECEL, SENHA, TOKEN, QRCODE }, (e, res) => {
      if (e) {
        this.props.history.push('/admin/dashboard');
        localStorage.setItem('CPFCNPJ', removeMask(CPFCNPJ));
      } else {
        if (res.response) this.setState({ message: res.response.data.mensagem });
        else this.setState({ message: 'Sem conexão com a internet' });
      }
    });
  }

  // async onRecaptchaSuccess(token) {
  //   this.setState({
  //     isVerified: token,
  //   });
  // }

  // onRecaptchExpired() {
  //   this.setState({ isVerified: undefined });
  // }

  // handleRecaptchaLoad() {
  //   this.setState({ recaptchaLoaded: true });
  // }

  render() {
    return (
      <>
        <div className="main-content" style={{ background: FUNDO_LOGIN }}>
          <div className="header py-7 py-lg-8">
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                  {this.props.auth.loading ? (
                    <Loading />
                  ) : (
                    <Col lg="4" md="4">
                      <Card>
                        {this.state.step == 1 ? (
                          <CardBody>
                            <img
                              style={{ width: 'auto', maxHeight: '60px' }}
                              alt="logo-troco-na-conta"
                              className="login-img"
                              src={LOGO}
                            />
                            <div className="mt-4 text-left">
                              <label className="form-control-label" htmlFor="input-cpf-cnpj">
                                CPF/CNPJ
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-cpf-cnpj"
                                type="text"
                                value={this.state.CPFCNPJ}
                                onKeyPress={(e) => (e.key == 'Enter' && document.getElementById('input-phone').focus())}
                                onChange={(e) => this.setState({ CPFCNPJ: removeMask(e.target.value) })}
                              />
                              <label className="form-control-label" htmlFor="input-phone">
                                Telefone
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-phone"
                                type="text"
                                value={this.state.FONECEL}
                                onKeyPress={(e) => (e.key == 'Enter' && document.getElementById('input-password').focus())}
                                onChange={(e) => this.setState({ FONECEL: e.target.value })}
                              />
                              <label className="form-control-label" htmlFor="input-password">
                                Senha
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-password"
                                type="password"
                                value={this.state.SENHA}
                                onKeyPress={(e) => (e.key == 'Enter' && this.login())}
                                onChange={(e) => this.setState({ SENHA: e.target.value })}
                              />
                              {/* <Recaptcha
                                onloadCallback={this.handleRecaptchaLoad}
                                sitekey="6LclEjAkAAAAAJEyCvMw5dvmZndjI7Z73toxbd0h"
                                render="onload"
                                verifyCallback={this.onRecaptchaSuccess}
                                className="recaptcha--container"
                                hl="pt-BR"
                                expiredCallback={this.onRecaptchExpired}
                              /> */}
                            </div>
                            <Button
                              color="success"
                              onClick={this.login}
                              style={{
                                width: '100%',
                                marginTop: '1.5em',
                                marginBottom: '10px',
                              }}
                            >
                              Entrar
                            </Button>

                            <small style={{ color: 'red' }}>{this.state.message}</small>
                          </CardBody>
                        ) : (
                          <CardBody>
                            <img
                              style={{ width: 'auto', maxHeight: '60px' }}
                              alt="logo-troco-na-conta"
                              className="login-img"
                              src={LOGO}
                            />
                            <div className="mt-4 text-left">
                              <label className="form-control-label" htmlFor="input-token">
                                Token
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-token"
                                type="text"
                                value={this.state.TOKEN}
                                onKeyPress={(e) => (e.key == 'Enter' && this.validate())}
                                onChange={(e) => this.setState({ TOKEN: e.target.value })}
                              />
                            </div>
                            <Button
                              color="success"
                              onClick={() => this.validate()}
                              style={{ width: '100%', marginTop: '1.5em' }}
                            >
                              Confirmar
                            </Button>
                            <small style={{ color: 'red' }}>{this.state.message}</small>
                          </CardBody>
                        )}
                      </Card>
                    </Col>
                  )}
                </Row>
              </div>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

const mapStatToProps = ({ auth }) => {
  return { auth };
};

export default connect(mapStatToProps, {
  login,
  validateLogin,
})(Login);
