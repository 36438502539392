import React, { useState } from 'react';
import { solicitarTrocaSenhaByUUID } from '../../../../store/user/actions';
import { connect, useDispatch } from 'react-redux';
import { Button, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Alert } from 'reactstrap';
import ModalMensagem from '../../../../views/Transaction/ModalMensagem';
import AnimatedLoading from '../../../../components/Loader/AnimatedLoading';
import validateSenha from '../../../../utils/validatePassword';

const ModalTrocarSenha = (props) => {
  const { isOpen, modalTransition, backdropTransition, toggle } = props;
  const dispatch = useDispatch;
  const [senha, setSenha] = useState('');
  const [senhaConfirma, setSenhaConfirma] = useState('');
  const [modalMensagem, setModalMensagem] = useState(false);
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [visible, setVisible] = useState(false);
  const [sucesso, setSucesso] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const onDismiss = () => setVisible(!visible);

  const user = props.user.user || props.userData;
  const params = {
    ID_CARTAO: props.id,
    NOVA_SENHA: senha,
  };
  const valideSenha = (target) => {
    setSenha(target);
  };
  const confirmValideSenha = (target) => {
    setSenhaConfirma(target);
  };

  const openModalMessage = (res) => {
    const { mensagem, retorno } = res;

    retorno && retorno == 'sucesso' ? setSucesso(true) : setSucesso(false);
    setLoaded(false);
    setMensagem(mensagem);
    setModalMensagem(true);
  };

  const click = () => {
    if (senha == '' || senhaConfirma == '') {
      setVisible(!visible);
      setMensagemAlerta('Preencha os campos');
      setLoaded(false);
    } else if (senha != senhaConfirma) {
      setVisible(!visible);
      setMensagemAlerta('As senhas devem ser Identicas');
      setLoaded(false);
    } else if (validateSenha(senha)) {
      setVisible(!visible);
      setMensagemAlerta('A senha não pode ter números sequenciais Ex:1111, 1234, 1121, 4321!');
      setLoaded(false);
    } else {
      setLoaded(true);
      props.solicitarTrocaSenhaByUUID(user.UUID, params, (res) => {
        openModalMessage(res);
      });
    }
  };

  const alert = () => {
    return (
      <Alert
        style={{ backgroundColor: 'red', margin: '2rem 0rem -2rem 0rem' }}
        isOpen={visible}
        toggle={() => onDismiss()}
      >
        {mensagemAlerta}
      </Alert>
    );
  };

  return (
    <>
      <Modal isOpen={isOpen} modalTransition={modalTransition} backdropTransition={backdropTransition} toggle={toggle}>
        <ModalHeader toggle={() => toggle()}>Digite a senha do cartão</ModalHeader>
        <FormGroup>
          <ModalBody>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignContent: 'center',
                alignItens: 'baseline',
                paddingTop: '1rem',
              }}
            >
              <div>
                <span>Digite a senha</span>
                <Input
                  type="password"
                  placeholder="****"
                  maxLength="4"
                  size="sm"
                  onClick={(e) => setVisible(false)}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => valideSenha(e.target.value)}
                ></Input>
              </div>
              <div>
                <span>Confirme a senha</span>
                <Input
                  type="password"
                  placeholder="****"
                  maxLength="4"
                  size="sm"
                  onClick={(e) => setVisible(false)}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => confirmValideSenha(e.target.value)}
                ></Input>
              </div>{' '}
            </div>
            {alert()}
          </ModalBody>
        </FormGroup>
        <AnimatedLoading isActive={loaded} />

        <ModalFooter>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Button style={{ marginTop: '8px' }} color="secondary" size="sm" onClick={() => toggle()} block>
              Cancelar
            </Button>
            <Button
              color="success"
              size="sm"
              onClick={() => {
                click();
              }}
              block
            >
              Confirmar
            </Button>
          </div>
        </ModalFooter>

        <ModalMensagem
          modal={modalMensagem}
          mensagem={mensagem}
          sucesso={sucesso}
          toggle={() => setModalMensagem(false)}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, {
  solicitarTrocaSenhaByUUID,
})(ModalTrocarSenha);
