import { AccountBalanceOutlined, CreditScore, Equalizer, PriceChange } from '@mui/icons-material/';
import { Box, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import CustomContainer from '../../components/Custom/CustomContainer';
import Transactions from './Transactions';
import IssuedCards from './IssuedCards';
import CardsDashboard from './CardsDashboard';


export default function Cards() {
    const components = [<CardsDashboard />, <Transactions />, <IssuedCards />];

    const [tabNumber, setTabNumber] = useState(0);

    return (
        <Box>
            <CustomContainer style={{ top: 20 }}>
                <Tabs sx={{ marginTop: -12 }} value={tabNumber} onChange={(event, number) => setTabNumber(number)}>
                    <Tab
                        icon={<Equalizer />}
                        sx={{
                            bgcolor: 'white',
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                        }}
                        label="Dashboard"
                    />
                    <Tab
                        icon={<PriceChange />}
                        sx={{
                            bgcolor: 'white',
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                        }}
                        label="Transações"
                    />
                    <Tab
                        icon={<CreditScore />}
                        sx={{
                            bgcolor: 'white',
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                        }}
                        label="Cartões Emitidos"
                    />
                </Tabs>
                {components[tabNumber]}
            </CustomContainer>
        </Box>
    );
}
