import styled from 'styled-components';

export const InfoCard = styled.div`
  width: 100%;
  background: #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
`;

export const Table = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
  width: 50%;
`;

export const TableContent = styled.div`
  display: flex;
  flex-direction: column;

  & > *:first-child {
    font-size: 14;
    font-weight: 400;
    color: #262424;
  }
  & > *:last-child {
    font-size: 16;
    font-weight: 600;
    color: #525f7f;
  }
`;

export const ButtonGroup = styled.div``;

export const ProductContent = styled.div``;

export const ProductTitleAndBalance = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  color: #32325d;

  & > *:first-child {
    font-size: 18;
    font-weight: 600;
  }
`;

export const ProductCardsType = styled.div`
  padding: 1rem;
  background: #fcfcfc;
  border: 1px solid #e6e7e9;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  width: 32%;

  #cardType__title {
    color: #212121;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 16px;
  }

  #cardType__balance {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 7px;
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
  }

  #cardType__balanceTitle {
    font-weight: 600;
    font-size: 14px;
    display: flex;
  }

  #cardType__balanceTitle__input {
    border: solid 1px #d3d3d3;
    margin: -5px 2px;
    font-weight: bold;
  }

  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  #cardType__balanceTitle__label {
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: -5px 0;
    font-weight: bold;
  }

  #cardType__novoSaldo {
    display: flex;
    font-weight: 400;
    font-size: 14px;
  }
`;

export const SaldoContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

export const SaldoStyle = styled.div`
  display: flex;
`;

export const SaldoUtilizadoColor = styled.div`
  color: #dc2d06;
  font-weight: 600;
`;

export const SaldoDisponivelColor = styled.div`
  color: ${(props) => (props.color == 'negativo' ? '#DC2D06' : (props.color = 'positivo' ? '#1ba305' : '#32325D'))};
  font-weight: 600;
`;

export const SaldoColor = styled.div`
  font-weight: 600;
`;
