import Styled from 'styled-components';
import { Input } from 'reactstrap';

export const Produts = Styled.div`
    display: flex;
    justify-content: space-around;
    padding: 2rem 0 0.8rem 0;
`;

export const InputSaldo = Styled(Input)`
  &&::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
