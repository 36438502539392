import { Box, Modal, Typography } from '@material-ui/core';
import { Button } from 'reactstrap';
import * as Styled from '../AccordionVerifications.styles';

import React, { useState } from 'react';
import '../style.css';

/* eslint-disable */
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid #00000068',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
  overflowX: 'hidden',
};

export default function ModalKYC({ isOpen, setKycModal, sancoes }) {
  const [sancoesNum, setSancoesNum] = useState(0);

  function thereIsNext() {
    return sancoes.length - (sancoesNum + 1) === 0;
  }

  function thereIsBefore() {
    return sancoesNum === 0;
  }

  const client = sancoes[sancoesNum];
  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => setKycModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h1>Sanções </h1>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Styled.container className="container">
              <Styled.block>
                <Styled.column>
                  {client.Details.SanctionName && (
                    <Styled.row>Nome da Sanção: {client.Details.SanctionName} </Styled.row>
                  )}
                  {client.Source && <Styled.row>Fonte: {client.Source}</Styled.row>}
                  {client.Details.BirthDate && <Styled.row>Data de Nascimento: {client.Details.BirthDate}</Styled.row>}
                  {client.Details.Nationalities && (
                    <Styled.row>Nacionalidade: {client.Details.Nationalities}</Styled.row>
                  )}
                  {client.Details.language_spoken && <Styled.row>Idioma: {client.Details.language_spoken}</Styled.row>}
                  {client.Details.charges && <Styled.row>Acusações: {client.Details.charges}</Styled.row>}
                </Styled.column>
              </Styled.block>

              <Styled.profilePicture>
                <img className="img" src={client.Details.image} />
              </Styled.profilePicture>
            </Styled.container>
            <Styled.container>
              <Button color="primary" disabled={thereIsBefore()} onClick={() => setSancoesNum(sancoesNum - 1)}>
                Voltar
              </Button>
              <Button color="primary" disabled={thereIsNext()} onClick={() => setSancoesNum(sancoesNum + 1)}>
                Próximo
              </Button>
            </Styled.container>
          </Typography>
        </Box>
      </Modal>{' '}
    </>
  );
}
