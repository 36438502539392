import React from 'react';

export default function LogoSvg({ color }) {
  return (
    <div>
      <svg width="100" height="34" viewBox="0 0 142 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M63.8107 0.35376C77.3905 0.35376 88.5653 10.7861 89.9537 24.172H89.9519C91.3403 10.7861 102.515 0.35376 116.095 0.35376C128.355 0.35376 138.654 8.85631 141.562 20.3587L127.497 20.3588C125.229 16.3621 120.972 13.6709 116.095 13.6709C108.836 13.6709 102.951 19.6332 102.951 26.988C102.951 34.3429 108.836 40.3051 116.095 40.3051C120.948 40.3051 125.187 37.6404 127.464 33.6762L141.547 33.6764C138.619 45.1488 128.334 53.6223 116.095 53.6223C102.515 53.6223 91.3403 43.1899 89.9519 29.804H89.9537C88.5653 43.1899 77.3905 53.6223 63.8107 53.6223H50.6666V50.059L50.667 40.305L76.9548 27.0139L50.667 13.671L50.6666 3.91695V0.35376H63.8107ZM26.6568 0.35376C37.3569 0.35376 46.5638 6.8307 50.6667 16.126V20.359L38.0008 20.359C35.7323 16.3622 31.4756 13.6709 26.5984 13.6709C19.3391 13.6709 13.4543 19.6332 13.4543 26.988C13.4543 34.3429 19.3391 40.3051 26.5984 40.3051C31.4514 40.3051 35.6902 37.6404 37.967 33.6763L50.6667 33.6762V37.85L50.5425 38.127C46.3802 47.2737 37.2503 53.6223 26.6568 53.6223C12.1383 53.6223 0.368652 41.6977 0.368652 26.988C0.368652 12.2783 12.1383 0.35376 26.6568 0.35376Z"
          fill={color ? color : 'blue'}
        />
      </svg>
    </div>
  );
}
