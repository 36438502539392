// User
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const USERS_RESPONSE = 'USERS_RESPONSE';
export const USER_RESPONSE_DATATRUST = 'USERS_RESPONSE_DATATRUST';
export const GET_STATUS_USUARIO = 'GET_STATUS_USUARIO';
export const USER_ADD_STATE = 'USERS_RESUSER_ADD_STATEPONSE_DATATRUST';
export const GET_ACESSOBIO_ERROR = 'GET_ACESSOBIO_ERROR';
export const CONTADIGITAL_RESPONSE = 'CONTADIGITAL_RESPONSE';
export const SOLICITACAO_CARTAO_RESPONSE = 'SOLICITACAO_CARTAO_RESPONSE';
export const SOLICITAR_TROCA_SENHA_RESPONSE = 'SOLICITAR_TROCA_SENHA_RESPONSE';
export const EDITAR_REPRESENTANTES_RESPONSE = 'EDITAR_REPRESENTANTES_RESPONSE';
export const ACESSO_DISPOSITIVO_RESPONSE = 'ACESSO_DISPOSITIVO_RESPONSE';
export const DEFAULT_TYPE = 'DEFAULT_TYPE';
export const KYC = 'KYC';
export const GET_KYC = 'GET_KYC';
export const TOGGLE_MODAL_SOLICITAR_CARTAO_NOMINAL = 'TOGGLE_MODAL_SOLICITAR_CARTAO_NOMINAL';
