import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPayments, getBaasBalance, getTransactionsType, getPendingPayments } from '../../store/transactions/actions';
import ModalInformationsPayments from '../../components/Modais/Pagamento/ModalInformationsPayments';
import { getOnlyOnePayment } from '../../store/transactions/actions';
import moment from 'moment';
import GenericButton from '../../components/Buttons/Button.js';
import Icon from '../../components/Icons/IconsButton.js';
import { Autocomplete, Box, Grid, ListItemText, MenuItem, Tab, Tabs, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import Completed from './Completed/Completed.jsx';
import Pending from './Pending/Pending.jsx';
import ModalPagamentoPendente from '../../components/Modais/Pagamento/ModalPagamentoPendente.jsx';
import CardBalance from '../../components/CardBalance/CardBalance.jsx';
import api from '../../service/api.js';
import { formatMoneyBRL } from '../../utils/index.js';

export default function Payment() {
  const dispatch = useDispatch();

  const [tab, setTab] = useState(0);
  const [modal, setModal] = useState({ open: false, id: '' });
  const [validStatusTypes, setValidStatusTypes] = useState([{ ID: 0, DESCRICAO: "Todos" }]);
  const [validPartners, setValidPartners] = useState([{ ID: 0, DESCRICAO: "Todos" }]);
  const [totalPagamentos, setTotalPagamentos] = useState('');
  const [totalPendente, setTotalPendente] = useState('');

  const {
    pagamento, statusTransacao, loading_transaction,
    saldoAtual, parceiro
  } = useSelector((state) => state.transactions);

  const {
    handleSubmit,
    getValues,
    setValue,
    register,
  } = useForm({
    defaultValues: {
      PAGE: 1,
      LIMIT: 10,
      SEARCH: '',
      STATUS: 0,
      TIPO_PAGAMENTO: 0,
      PARCEIRO: 'Todos',
      DATA_INICIAL: moment().subtract(1, 'M').format('YYYY-MM-DD'),
      DATA_FINAL: moment().format('YYYY-MM-DD')
    }
  });

  useEffect(() => {
    fillCards();
    dispatch(getTransactionsType());
  }, []);

  useEffect(() => {
    if (statusTransacao && statusTransacao.length > 0) {
      setValidStatusTypes([{ ID: 0, DESCRICAO: "TODOS" }].concat(statusTransacao.filter(status => status.ID <= 4)));
    }
  }, [statusTransacao]);

  useEffect(() => {
    if (parceiro && parceiro.length > 0) {
      setValidPartners([{ id: 0, label: "Todos" }].concat(parceiro.map(({ID, DESCRICAO}) => ({id: ID, label:DESCRICAO}))));
    }
  }, [parceiro]);

  useEffect(() => {
    if (tab) {
      setValue('STATUS', 0);
      dispatch(getPayments(cleanFilters(getValues())));
    } else {
      setValue('STATUS', 1);
      dispatch(getPendingPayments(cleanFilters(getValues())));
    }

    dispatch(getBaasBalance());
    setModal({ open: false, id: '' });
  }, [tab]);

  function cleanFilters(filters) {
    const newFilter = filters;
    !newFilter.STATUS && delete newFilter.STATUS;
    newFilter.PARCEIRO === 'Todos' && delete newFilter.PARCEIRO;
    newFilter.TIPO_PAGAMENTO === 0 && delete newFilter.TIPO_PAGAMENTO;
    return newFilter;
  }

  function searchPending(filters) {
    dispatch(getPendingPayments(cleanFilters(filters)));
    fillCards();
  }

  function searchCompleted(filters) {
    dispatch(getPayments(cleanFilters(filters)));
    fillCards();
  }

  function fillCards() {
    dispatch(getBaasBalance());
    api
      .get('/admin/boleto/buscar_total_pagamento_dia')
      .then(({ data }) => setTotalPagamentos(data.data));

    api
      .get('/admin/boleto/buscar_total_pagamento_pendente')
      .then(({ data }) => setTotalPendente(data.data));
  }

  function handleClick(ID) {
    if (tab) {
      setModal({ open: true, id: ID });
      dispatch(getOnlyOnePayment(ID));
    } else {
      setModal({ open: true, id: ID });
    }
  }

  const components = [
    <Pending
      search={searchPending}
      getValues={getValues}
      handleClick={handleClick}
      callback={() => searchPending(getValues())}
    />,
    <Completed
      search={searchCompleted}
      getValues={getValues}
      handleClick={handleClick}
    />
  ];

  const modalList = [
    <ModalPagamentoPendente
      toggle={() => setModal({ open: false, id: '' })}
      modal={modal.open}
      title={'Dados do Pagamento'}
      idSolicitation={modal.id}
      callback={() => searchPending(getValues())}
    />,
    <ModalInformationsPayments
      toggle={() => setModal({ open: false, id: '' })}
      modal={modal.open}
      title={'Dados do Pagamento'}
      dataInfo={pagamento}
    />
  ]

  return (
    <Box mt={1}>
        <Grid container spacing={2} mt={2} mb={2}>
          <Grid item xs={12} md={4}>
            <CardBalance
              title={"Saldo Atual"}
              balance={saldoAtual ? `R$ ${formatMoneyBRL(saldoAtual)}` : "R$ 0,00"}
              type='celcoinBalance'
              colors={['36', '5', '242']}
              loading={loading_transaction}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CardBalance
              title={"Total Pendente"}
              balance={totalPendente ? `R$ ${formatMoneyBRL(totalPendente)}` : "R$ 0,00"}
              type='totalPendente'
              colors={['234', '168', '0']}
              loading={loading_transaction}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CardBalance
              title={"Total Pagamentos do Dia"}
              balance={totalPagamentos ? `R$ ${formatMoneyBRL(totalPagamentos)}` : "R$ 0,00"}
              type='totalPagamentosDia'
              colors={['2', '121', '47']}
              loading={loading_transaction}
            />
          </Grid>
          <Grid item xs={12}>
          <form
            onSubmit={handleSubmit(tab ? searchCompleted : searchPending)}
            style={{
              display: 'flex',
              flexFlow: 'column',
              width: '100%',
              justifyContent: 'flex-start',
              gap: '1rem',
              margin: '2rem 0',
            }}
          >
            <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} sx={{gap: '10px'}} >
              <TextField
                {...register("SEARCH")}
                autoFocus
                label="Pesquisa"
                placeholder="Nome, Conta ou CPF/CNPJ"
                variant="outlined"
                size='small'
                sx={{ width: '20rem' }}
              />
              <TextField
                {...register("STATUS")}
                select
                label="Status"
                defaultValue={1}
                disabled={!tab}
                variant="outlined"
                value={getValues('STATUS')}
                size='small'
                sx={{ width: '15rem' }}
              >
                {validStatusTypes.map(({ ID, DESCRICAO }, index) => (
                  <MenuItem key={index} value={ID}>
                    {DESCRICAO}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                {...register("TIPO_PAGAMENTO")}
                select
                label="Forma Pagamento"
                defaultValue={0}
                variant="outlined"
                size='small'
                sx={{ width: '15rem' }}
              >
                {['Todos', 'Cartão', 'Conta Digital'].map((label, index) => (
                  <MenuItem key={index} value={index}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>

              <Autocomplete
                defaultValue="Todos"
                onChange={(event, value) => setValue('PARCEIRO', value ? value.id : 'Todos')}
                options={validPartners}
                renderOption={(params, item) => (
                  <li {...params} key={item.id}>
                      <ListItemText>{item.label}</ListItemText>
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label="Parceiro" />}
                noOptionsText="Parceiro não encontrado"
                size='small'
                sx={{ width: '15rem' }}
              />
              <TextField
                label="Data Inicial"
                {...register("DATA_INICIAL")}
                type='date'
                variant="outlined"
                size='small'
                sx={{ width: '15rem' }}
              />

              <TextField
                label="Data Final"
                {...register("DATA_FINAL")}
                type='date'
                variant="outlined"
                size='small'
                sx={{ width: '15rem' }}
              />
              <GenericButton height="40px" width="140px" type="submit">
                <Icon style={{ marginRight: '.4rem', fontSize: '1rem' }} className="fas fa-search" />
                Buscar
              </GenericButton>
            </Box>

            <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} sx={{gap: '10px'}} >
              <TextField
                {...register("LIMIT")}
                select
                label="Limite"
                variant="outlined"
                size='small'
                defaultValue="10"
                sx={{ width: '10rem' }}
              >
                {['5', '10', '25', '50', '100', '1000'].map((label, index) => (
                  <MenuItem key={index} value={label}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
              <span style={{ fontSize: 'small' }}>Resultados por página</span>
            </Box>
          </form>
          </Grid>
          <Tabs value={tab} onChange={(e, newValue) => {
            setTab(newValue);
          }}>
            <Tab label="Pendentes" sx={{ fontSize: '12px' }} />
            <Tab label="Concluídos" sx={{ fontSize: '12px' }} />
          </Tabs>
        </Grid>
        {components[tab]}
        {modalList[tab]}

    </Box>
  );
}
