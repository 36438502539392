import React from 'react';

function Icon(parans) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      style={{
        cursor: 'pointer',
      }}
    >
      <path
        fill="#303545"
        d="M1.75 10.185v1.773c0 .164.128.292.292.292h1.773c.076 0 .152-.03.204-.088l6.37-6.364-2.187-2.187-6.364 6.364a.286.286 0 00-.088.21zm10.33-6.078a.58.58 0 000-.823L10.717 1.92a.58.58 0 00-.823 0L8.826 2.987l2.187 2.187 1.068-1.067z"
      ></path>
    </svg>
  );
}

export default Icon;
