import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Grid, Paper, Skeleton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ReloadButtonStyle } from '../../User/components/DigitalAccountBalaceContainer/style';
import { FiRefreshCw } from 'react-icons/fi';
import { Line } from 'react-chartjs-2';
import api from '../../../service/api.js';
import valueEmpty from '../../../utils/valueEmpty.js';
import StatusContainerValue from '../../../components/StatusContainer/StatusContainerValue.js';
import Loading from '../../../components/Loader/Loading.jsx';
import StatusContainer from '../../../components/StatusContainer/StatusContainer.js';
import { formatDateBR } from '../../../utils/dateKyc.js';
import isCpf from '../../../utils/isCpf.js';
import formatCPF from '../../../utils/formatCPF.js';
import formatCNPJ from '../../../utils/formatCNPJ.js';
import CustomDataTable from '../../../components/DataTable/DataTable.jsx';
import { getPixSalesByInterval } from '../../../store/accounting/action.js';
import CardBalance from '../../../components/CardBalance/CardBalance.jsx';
import { saldoAportePix } from '../../../store/transactions/actions.js';

export default function Dashboard() {
  const dispatch = useDispatch();
  const dadosDefaultChart = [0, 0, 0, 0, 0, 0, 0, 0];

  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState(false);
  const [pagination, setPagination] = useState(false);
  const [dailySchedules, setDailySchedules] = useState(null);
  const [loadingDailySchedules, setLoadingDailySchedules] = useState(true);
  const [today, setToday] = useState(null);

  const { loading_transaction } = useSelector((state) => state.transactions);

  const {
    saques: { transacaoDebito, transacaoCredito, loading_accounting },
  } = useSelector((state) => state.accounting);

  const {
    pix: { saldo, limite_aporte, loading_aporte },
  } = useSelector((state) => state.transactions.aporte);

  useEffect(() => {
    handleReload(moment().format('YYYY-MM-DD'));
  }, []);

  function handleReload(selectedData) {
    setToday(selectedData);

    setLoadingDailySchedules(true);

    dispatch(saldoAportePix());
    getTransactions(1, selectedData);
    dispatch(getPixSalesByInterval(selectedData, 15));
    getTotalDailySchedules(selectedData);
  }

  function getTotalDailySchedules(selectedDate) {
    setLoadingDailySchedules(true);
    return api
      .get(`/pix-agendamentos/buscar-total-dia?DATA_PAGAMENTO=${selectedDate}`)
      .then(({ data }) => {
        setDailySchedules(data.valor_total_agendado);
      })
      .catch((error) => {
        setDailySchedules(null);
      })
      .finally(() => {
        setLoadingDailySchedules(false);
      });
  }

  function getTransactions(page, selectedDate) {
    setLoading(true);
    const params = new URLSearchParams({
      page,
      limit: 10,
      data_inicial: selectedDate,
      data_final: selectedDate,
    });
    api
      .get(`/transacoes-pix?${params.toString()}`)
      .then(({ data }) => {
        if (data.retorno === 'sucesso') {
          setTransactions(data.data);
          setPagination(data.pagination);
        }
      })
      .finally(() => setLoading(false));
  }

  const formatDatas = (datas) => {
    var dataFormatada = [];
    dataFormatada = datas.map((num) => {
      var value = [];
      value = moment(num).isValid() ? moment(num).format('DD/MM/YYYY') : num;
      return value;
    });

    return dataFormatada;
  };

  function findTransactionByDay(day, transactions) {
    if (transactions.length) {
      const transactionOfTheDay = transactions.find((transaction) => transaction.DATA === day);
      if (transactionOfTheDay) {
        return parseFloat(transactionOfTheDay.VALOR).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      }
    }
    return 'R$ 0,00';
  }

  return (
    <Box mt={1}>
      <Paper elevation={3} sx={{ marginTop: '1rem', padding: '0', boxShadow: 'none' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display={'flex'} justifyContent={'end'}>
              <ReloadButtonStyle onClick={() => handleReload(today)}>
                <FiRefreshCw />
              </ReloadButtonStyle>
            </Box>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            sx={{
              flexWrap: { xs: 'wrap', lg: 'nowrap' },
              margin: '0',
            }}
          >
            <Grid item xs={12} sm={6} lg={7}>
              <CardBalance
                title={'Saldo Conta Pix'}
                balance={
                  loading || loading_aporte ? (
                    <Skeleton animation="wave" sx={{ width: '10rem', fontSize: 'larger' }} />
                  ) : saldo ? (
                    parseFloat(saldo).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                  ) : (
                    'R$ -'
                  )
                }
                type="saldoPrePago"
                colors={['36', '5', '242']}
                loading={loading_aporte}
                description={
                  loading || loading_aporte ? (
                    <Skeleton animation="wave" sx={{ width: '4rem', fontSize: 'small' }} />
                  ) : (
                    <Typography fontSize="small">
                      Limite:{' '}
                      {limite_aporte
                        ? limite_aporte.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                        : 'R$ -'}
                    </Typography>
                  )
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={7}>
              <CardBalance
                title={'Saldo Transações Agendadas'}
                date={today}
                setDate={(selectedData) => {
                  handleReload(selectedData);
                }}
                balance={
                  loading || loadingDailySchedules ? (
                    <Skeleton animation="wave" sx={{ width: '10rem', fontSize: 'larger' }} />
                  ) : dailySchedules ? (
                    dailySchedules.replace(/\u00a0/g, ' ')
                  ) : (
                    'R$ -'
                  )
                }
                type="totalPendente"
                colors={['234', '168', '0']}
                loading={loadingDailySchedules}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={7}>
              <CardBalance
                title={'Total Créditos do Dia'}
                date={today}
                setDate={(selectedData) => {
                  handleReload(selectedData);
                }}
                balance={
                  loading || loading_accounting ? (
                    <Skeleton animation="wave" sx={{ width: '10rem', fontSize: 'larger' }} />
                  ) : (
                    findTransactionByDay(today, transacaoCredito)
                  )
                }
                colors={['2', '121', '47']}
                type="credito"
                loading={loading_accounting}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={7}>
              <CardBalance
                title={'Total Débitos do Dia'}
                date={today}
                setDate={(selectedData) => {
                  handleReload(selectedData);
                }}
                balance={
                  loading || loading_accounting ? (
                    <Skeleton animation="wave" sx={{ width: '10rem', fontSize: 'larger' }} />
                  ) : (
                    findTransactionByDay(today, transacaoDebito)
                  )
                }
                colors={['237', '28', '36']}
                type="debito"
                loading={loading_accounting}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} mb={2}>
              <Typography sx={{ fontSize: 24, fontWeight: 700 }}>Movimentações do dia</Typography>
              <Typography fontSize={14}>Quantidade: {pagination && pagination.totalResult}</Typography>
            </Box>
            {loading || loading_transaction ? (
              <Loading color="blue" />
            ) : (
              <CustomDataTable
                loading={loading}
                pagination={pagination}
                previousPage={{ onClick: () => getTransactions(pagination.prevPage, today) }}
                nowPage={{ page: pagination.page, onClick: () => getTransactions(pagination.page, today) }}
                nextPage={{ onClick: () => getTransactions(pagination.nextPage, today) }}
                data={transactions}
                headers={[
                  {
                    name: 'DATA',
                    selector: (row) => formatDateBR(row.DATA),
                    style: { textAlign: 'center' },
                  },
                  {
                    name: 'CPF/CNPJ',
                    selector: (row) =>
                      row.CPFCNPJ && isCpf(row.CPFCNPJ) ? formatCPF(row.CPFCNPJ) : formatCNPJ(row.CPFCNPJ),
                    style: { textAlign: 'center' },
                  },
                  {
                    name: 'CONTA PAGADOR',
                    selector: (row) => row.CONTA,
                    style: { textAlign: 'center' },
                  },
                  {
                    name: 'CONTA RECEBEDOR',
                    selector: (row) => (row.CONTA_DA_TRANSFERENCIA ? row.CONTA_DA_TRANSFERENCIA : '----'),
                    style: { textAlign: 'center' },
                  },
                  {
                    name: 'VALOR',
                    selector: (row) =>
                      valueEmpty(
                        row.VALOR ? <StatusContainerValue code={row.TIPO_TRANSACAO} description={row.VALOR} /> : null,
                      ),
                    style: { textAlign: 'center' },
                  },
                  {
                    name: 'PARCEIRO',
                    selector: (row) =>
                      row.PARCEIRO ? (
                        <StatusContainer code={row.PARCEIRO.toUpperCase()} description={row.PARCEIRO}></StatusContainer>
                      ) : (
                        '-----'
                      ),
                    style: { textAlign: 'center' },
                  },
                  {
                    name: 'CANAL',
                    selector: (row) => row.CANAL,
                    style: { textAlign: 'center' },
                  },
                ]}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} mb={2}>
              <Typography sx={{ fontSize: 24, fontWeight: 700 }}>Transações</Typography>
              <Typography fontSize={14}>(Últimos 15 dias)</Typography>
            </Box>
            {loading || loading_accounting ? (
              <Loading color="blue" />
            ) : (
              <Line
                data={{
                  labels:
                    transacaoDebito.length > 0
                      ? formatDatas(transacaoDebito.map((transacao) => transacao.DATA))
                      : dadosDefaultChart,
                  datasets: [
                    {
                      label: 'Crédito',
                      data:
                        transacaoCredito.length > 0
                          ? transacaoCredito.map((transacao) => transacao.VALOR)
                          : dadosDefaultChart,
                      backgroundColor: 'rgba(12, 180, 42, 0.2)',
                      borderColor: '#0CB42A',
                      fill: true,
                    },
                    {
                      label: 'Débito',
                      data:
                        transacaoDebito.length > 0
                          ? transacaoDebito.map((transacao) => transacao.VALOR)
                          : dadosDefaultChart,
                      backgroundColor: 'rgba(234, 104, 82, 0.2)',
                      borderColor: '#EA6852',
                      fill: true,
                    },
                  ],
                }}
                options={{
                  aspectRatio: 1,
                  plugins: {
                    legend: {
                      display: true,
                      position: 'bottom',

                      labels: {
                        usePointStyle: true,
                      },
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          var currentDataValue = context.raw;
                          return (+currentDataValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                        },
                      },
                    },
                  },
                }}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
