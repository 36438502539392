import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { ButtonTransparent } from '../ModalEdit/styled';
import api from '../../../../service/api';
import Select from 'react-select';
import Table from '../../../../components/Table/Table';
import GenericButton from '../../../../components/Buttons/Button';
import ModalMessageGeneric from '../../../../components/Modais/Mensagem/ModalMessageGeneric';

export default function ModalUsersToAdmin({ open, toggle, reset }) {
  const [users, setUsers] = useState([]);
  const [UUID, setUUID] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [addResponse, setAddResponse] = useState(false);

  const add = async () => {
    try {
      const res = await api.post('/telas-pj/usuarios-admin', { UUID: UUID });
      setAddResponse(res.data.retorno);
    } catch (error) {
      setAddResponse('error');
      console.log(error);
    } finally {
      setModalSuccess(true);
      reset();
    }
  };

  const close = () => {
    setModalSuccess(!modalSuccess);
    toggle();
    setUUID(false);
  };

  const handleSelect = (UUID) => {
    setUUID(UUID.value);
  };

  const getUsers = async () => {
    try {
      const res = await api.get('/telas-pj/usuarios-nao-admin');
      setUsers(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (open) {
      getUsers();
    }
  }, [open]);

  return (
    <Modal className="modal-md" isOpen={open} toggle={toggle}>
      <Box
        bgcolor={'rgb(25, 118, 210)'}
        width={'100%'}
        height={'4rem'}
        borderRadius={'6px 6px 0 0'}
        pl={'2rem'}
        pr={'2rem'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box mt={'0.2rem'}>
          <span style={{ fontSize: '1rem', color: '#ffffff', lineHeight: '0.1' }}>Usuários</span>
        </Box>
        <Box>
          <ButtonTransparent type="button" onClick={() => toggle()}>
            <IoClose />
          </ButtonTransparent>
        </Box>
      </Box>

      <Box width={'100%'} p={'1.5rem 1rem 1.5rem 1rem'}>
        <Select
          options={users.map((user) => ({
            label: user.NOME,
            value: user.UUID,
          }))}
          onChange={handleSelect}
        />
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'} p={'1rem'}>
        <Button variant="outlined" onClick={() => toggle()} color="error">
          Cancelar
        </Button>
        <Button variant="outlined" onClick={() => add()} disabled={!UUID}>
          Adicionar
        </Button>
      </Box>

      <ModalMessageGeneric open={modalSuccess} toggle={() => close()} retorno={addResponse} />
    </Modal>
  );
}
