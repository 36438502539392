import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

export default function ModalQrCode({modal, toggle}) {
    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader>Em desenvolvimento</ModalHeader>
            <ModalBody>
                Em desenvolvimento
            </ModalBody>
        </Modal>
    )
}