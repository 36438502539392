import {
  SWAP_ADD_PRODUCT,
  SWAP_ADD_PRODUCT_SUCCESS,
  SWAP_ADD_PRODUCT_FAIL,
  SWAP_EDIT_PRODUCT,
  SWAP_EDIT_PRODUCT_SUCCESS,
  SWAP_EDIT_PRODUCT_FAIL,
  SWAP_ADD_SEGMENTS_PRODUCT,
  SWAP_ADD_SEGMENTS_PRODUCT_SUCCESS,
  SWAP_ADD_SEGMENTS_PRODUCT_FAIL,
  SWAP_LIST_SEGMENTS,
  SWAP_LIST_SEGMENTS_SUCCESS,
  SWAP_LIST_SEGMENTS_FAIL,
  SWAP_LIST_PRODUCT,
  SWAP_LIST_PRODUCT_SUCCESS,
  SWAP_LIST_PRODUCT_FAIL,
  SWAP_LIST,
  SWAP_LIST_SUCCESS,
  SWAP_LIST_FAIL,
  SWAP_LIST_PRODUCT_SEGMENT,
  SWAP_LIST_PRODUCT_SEGMENT_SUCCESS,
  DEFAULT_TYPE,
} from './types';
import api from '../../service/api';

export const cadastrarProduto = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: SWAP_ADD_PRODUCT });
    try {
      const { data } = await api.post(`/produto`, params);
      dispatch({
        type: SWAP_ADD_PRODUCT_SUCCESS,
        payload: {
          mensagemProduct: data.mensagem,
        },
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: SWAP_ADD_PRODUCT_FAIL,
        payload: {
          mensagemProduct: error.response.data,
        },
      });
      callback(error.response.data);
    }
  };
};

export const listarProduto = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: SWAP_LIST_PRODUCT });
    try {
      const { data } = await api.get(`/produto`);
      dispatch({
        type: SWAP_LIST_PRODUCT_SUCCESS,
        payload: {
          listagemProduto: data.data,
        },
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: SWAP_LIST_PRODUCT_FAIL,
        payload: {
          mensagemListProduct: error.response.data,
        },
      });
      callback(error.response.data);
    }
  };
};

export const listarProdutosCartao = (CPFCNPJ, CODCARTAO, callback = () => null) => {
  return async (dispatch) => {
    try {
      const { data } = await api.get(`/conveniada/vincula-produto?CPFCNPJ_CLIENTE=${CPFCNPJ}&CODCARTAO=${CODCARTAO}`);
      dispatch({
        type: SWAP_LIST_PRODUCT_SUCCESS,
        payload: {
          CARTAO_PRODUTOS: data.data,
        },
      });
      callback(data);
    } catch (err) {
      callback(err.response.data);
    }
  };
};

export const listarProdutosCartaoByUUID = (UUID, CODCARTAO, callback = () => null) => {
  return async (dispatch) => {
    try {
      const { data } = await api.get(`/conveniada/vincula-produto/${UUID}?CODCARTAO=${CODCARTAO}`);
      dispatch({
        type: SWAP_LIST_PRODUCT_SUCCESS,
        payload: {
          CARTAO_PRODUTOS: data.data,
        },
      });
      callback(data);
    } catch (err) {
      callback(err.response.data);
    }
  };
};

export const deletarProdutosCartao = (ID, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: SWAP_LIST_PRODUCT });
    try {
      const { data } = await api.delete(`conveniada/vincula-produto/${ID}`);
      dispatch({
        type: SWAP_LIST_PRODUCT_SUCCESS,
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: SWAP_LIST_PRODUCT_FAIL,
        payload: {
          mensagemListProductCard: error,
        },
      });
      callback(error);
    }
  };
};

export const editarProduto = (id, params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: SWAP_EDIT_PRODUCT });
    try {
      const { data } = await api.put(`/produto/${id}`, params);
      dispatch({
        type: SWAP_EDIT_PRODUCT_SUCCESS,
        payload: {
          mensagemEditProduct: data.mensagem,
        },
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: SWAP_EDIT_PRODUCT_FAIL,
        payload: {
          mensagemEditProduct: error.response.data,
        },
      });
      callback(error.response.data);
    }
  };
};

export const vincularProduto = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: SWAP_ADD_PRODUCT });
    try {
      const { data } = await api.post(`/banco-parceiro/cartao/vincular`, params);
      dispatch({
        type: SWAP_ADD_PRODUCT_SUCCESS,
        payload: {
          mensagemVincularProduct: data.mensagem,
        },
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: SWAP_ADD_PRODUCT_FAIL,
        payload: {
          mensagemVincularProduct: error.response.data,
        },
      });
      callback(error.response.data);
    }
  };
};

export const vincularSegmento = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: SWAP_ADD_SEGMENTS_PRODUCT });
    try {
      const { data } = await api.post(`/produto-segmento`, params);
      dispatch({
        type: SWAP_ADD_SEGMENTS_PRODUCT_SUCCESS,
        payload: {
          mensagemVinculoSegmento: data.mensagem,
        },
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: SWAP_ADD_SEGMENTS_PRODUCT_FAIL,
        payload: {
          mensagemVinculoSegmento: error.response.data,
        },
      });
      callback(error.response.data);
    }
  };
};

export const atualizarSegmento = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: SWAP_ADD_SEGMENTS_PRODUCT });
    try {
      const { data } = await api.put(`/produto-segmento`, params);
      dispatch({
        type: SWAP_ADD_SEGMENTS_PRODUCT_SUCCESS,
        payload: {
          mensagemVinculoSegmento: data.mensagem,
        },
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: SWAP_ADD_SEGMENTS_PRODUCT_FAIL,
        payload: {
          mensagemVinculoSegmento: error.response.data,
        },
      });
      callback(error.response.data);
    }
  };
};

export const listarSegmento = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: SWAP_LIST_SEGMENTS });
    try {
      const { data } = await api.get(`/segmento`);
      dispatch({
        type: SWAP_LIST_SEGMENTS_SUCCESS,
        payload: {
          listagemSegmentos: data.data,
        },
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: SWAP_LIST_SEGMENTS_FAIL,
        payload: {
          mensagemListagemSegmentos: error.response.data,
        },
      });
      callback(error.response.data);
    }
  };
};

export const produtosVinculados = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: SWAP_LIST });
    try {
      const { data } = await api.get(`/banco-parceiro/cartao/vincular?ID_CARTAO_SWAP=${params}`);
      dispatch({
        type: SWAP_LIST_SUCCESS,
        payload: {
          listSwap: data.data,
          mensagemVincularProduct: data.mensagem,
        },
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: SWAP_LIST_FAIL,
        payload: {
          mensagemVincularProduct: error,
        },
      });
      callback(error);
    }
  };
};

export const listarProdutosESegmentos = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: SWAP_LIST_PRODUCT_SEGMENT });
    try {
      const { data } = await api.post(`/produto-segmento/agrupado`, params);
      dispatch({
        type: SWAP_LIST_PRODUCT_SEGMENT_SUCCESS,
        payload: {
          listagemProdutoSegmentos: data,
        },
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: SWAP_LIST_PRODUCT_FAIL,
        payload: {
          mensagemListProduct: error.response.data,
        },
      });
      callback(error.response.data);
    }
  };
};

export const listarProdutosCartaoVerificarMCCEmLote = (CPFCNPJ_CLIENTE, CODPRODUTO, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: SWAP_LIST_PRODUCT });
    try {
      const { data } = await api.post('/conveniada/verifica-produto/lote', { CPFCNPJ_CLIENTE, CODPRODUTO });
      dispatch({
        type: SWAP_LIST_PRODUCT_SUCCESS,
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: SWAP_LIST_PRODUCT_FAIL,
      });
      callback(error.response.data);
    }
  };
};

export const listarProdutosCartaoVerificarMCCEmLoteByUUID = (UUID, CODPRODUTO, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: SWAP_LIST_PRODUCT });
    try {
      const { data } = await api.post(`/conveniada/verifica-produto/lote/${UUID}`, { CODPRODUTO });
      dispatch({
        type: SWAP_LIST_PRODUCT_SUCCESS,
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: SWAP_LIST_PRODUCT_FAIL,
      });
      callback(error.response.data);
    }
  };
};

export const listarProdutosCartaoVerificarMCCVinculacao = (CPFCNPJ_CLIENTE, CODPRODUTO, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: SWAP_LIST_PRODUCT });
    try {
      const { data } = await api.post('/conveniada/verifica-produto/vinculacao', { CPFCNPJ_CLIENTE, CODPRODUTO });
      dispatch({
        type: SWAP_LIST_PRODUCT_SUCCESS,
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: SWAP_LIST_PRODUCT_FAIL,
      });
      callback(error.response.data);
    }
  };
};

export const selectAdicionarProduto = (CPFCNPJ, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: SWAP_LIST_PRODUCT, loadingProduct: true });
    try {
      const { data } = await api.get(`/conveniada/select/adicionar?CPFCNPJ_CLIENTE=${CPFCNPJ}`);
      dispatch({
        type: SWAP_LIST_PRODUCT_SUCCESS,
        payload: {
          selectAddProduct: data.data,
          loadingProduct: false,
        },
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: SWAP_LIST_PRODUCT_FAIL,
        payload: {
          mensagemListSelectProduct: error.response.data,
          loadingProduct: false,
        },
      });
      callback(error.response.data);
    }
  };
};

export const selectAdicionarProdutoByUUID = (UUID, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: SWAP_LIST_PRODUCT, loadingProduct: true });
    try {
      const { data } = await api.get(`/conveniada/select/adicionar/${UUID}`);
      dispatch({
        type: SWAP_LIST_PRODUCT_SUCCESS,
        payload: {
          selectAddProduct: data.data,
          loadingProduct: false,
        },
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: SWAP_LIST_PRODUCT_FAIL,
        payload: {
          mensagemListSelectProduct: error.response.data,
          loadingProduct: false,
        },
      });
      callback(error.response.data);
    }
  };
};

export const listarProdutosCartaoVerificarMCC = (COD_CARTAO, COD_PRODUTO, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: SWAP_LIST_PRODUCT });
    try {
      const { data } = await api.get(`/conveniada/verifica-produto?CODCARTAO=${COD_CARTAO}&CODPRODUTO=${COD_PRODUTO}`);
      dispatch({
        type: SWAP_LIST_PRODUCT_SUCCESS,
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: SWAP_LIST_PRODUCT_FAIL,
      });
      callback(error.response.data);
    }
  };
};

export const selectOperarSaldo = (CPFCNPJ, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: SWAP_LIST_PRODUCT, loadingProduct: true });
    try {
      const { data } = await api.get(`/conveniada/select/operar?CPFCNPJ_CLIENTE=${CPFCNPJ}`);
      dispatch({
        type: SWAP_LIST_PRODUCT_SUCCESS,
        payload: {
          selectOperate: data.data,
          loadingProduct: false,
        },
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: SWAP_LIST_PRODUCT_FAIL,
        payload: {
          mensagemListSelectProduct: error.response.data,
          loadingProduct: false,
        },
      });
      callback(error.response.data);
    }
  };
};

export const createCarduser = (client_uuid, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: DEFAULT_TYPE,
      payload: {
        card_user: {
        loading: true
      }
      }
    })
    await api.post(`/cartao/usuario/${client_uuid}`).then(({ data }) => {
      callback(data);
      dispatch({
        type: DEFAULT_TYPE,
        payload: {
          card_user: {
            loading: false
          }
        }
      })
    }).catch(error => {
      callback(error.response.data)
      dispatch({
        type: DEFAULT_TYPE,
        payload: {
          card_user: {
            loading: false
          }
        }
      })
    });
  }
}

export const updateCardUser = (account_holder_id, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: DEFAULT_TYPE,
      payload: {
        card_user: {
        loading: true
      }
      }
    })
    const { data } = await api.put(`/cartao/usuario/${account_holder_id}`);
    callback(data);
    dispatch({
      type: DEFAULT_TYPE,
      payload:
      {
        card_user: {
        loading: false
      }
      }
    })
  }
}

export const findCardUser = (client_uuid, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: DEFAULT_TYPE,
      payload: {
        card_user: {
        loading: true
      }
      }
    })
    const { data } = await api.get(`/cartao/usuario/${client_uuid}`);
    callback(data);
    dispatch({
      type: DEFAULT_TYPE,
      payload: {
        card_user: {
        loading: false
      }
      }
    })
  }
}

export const selectOperarSaldoByUUID = (UUID, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: SWAP_LIST_PRODUCT, loadingProduct: true });
    try {
      const { data } = await api.get(`/conveniada/select/operar/${UUID}`);
      dispatch({
        type: SWAP_LIST_PRODUCT_SUCCESS,
        payload: {
          selectOperate: data.data,
          loadingProduct: false,
        },
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: SWAP_LIST_PRODUCT_FAIL,
        payload: {
          mensagemListSelectProduct: error.response.data,
          loadingProduct: false,
        },
      });
      callback(error.response.data);
    }
  };
};
