import React, { useEffect } from 'react';
import { Icon } from '@mui/material';
import { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import BadgeTipoConta from '../../../components/BadgeTipoConta/BadgeTipoConta';
import GenericButton from '../../../components/Buttons/Button';
import Loading from '../../../components/Loader/Loading';
import Table from '../../../components/Table/Table';
import api from '../../../service/api';
import ModalMensagemSemReloadNew from '../../Transaction/ModalMensagemSemReloadNew';
import ModalTipoContaUsuario from './ModalTipoContaUsuario';
import ModalCreateUser from './ModalCreateUser';

export default function ModalUsuariosRH({ open, toggle, user, setReload, reload, choosenUser }) {
  const [loading, setLoading] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [message, setMessage] = useState(false);
  const [users, setUsers] = useState([]);
  const [alert, setAlert] = useState(false);
  const [modalEditUser, setModalEditUser] = useState(false);
  const [userRH, setUserRH] = useState(false);
  const [createUserModal, setCreateUserModal] = useState(false);

  useEffect(() => {
    if (open && user) {
      getUsersRH();
    }
  }, [open, user]);

  function getUsersRH() {
    setLoading(true);
    api
      .get(`/rh/usuarios-perfis?company_uuid=${user.UUID}`)
      .then((res) => {
        setUsers(res.data.data);
      })
      .finally(() => setLoading(false));
  }

  return (
    <Modal style={{ width: '1200px !important', maxWidth: '1200px' }} isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <span style={{ fontSize: '1rem' }}>Usuários {user.NOME}</span>
      </ModalHeader>
      {loading ? (
        <Loading color="blue" />
      ) : (
        <ModalBody style={{ padding: '1rem' }}>
          <ModalTipoContaUsuario
            open={modalEditUser}
            toggle={() => {
              setModalEditUser(false);
            }}
            user={user}
            userRH={userRH}
            reload={reload}
            setReload={setReload}
            getUsersRH={getUsersRH}
          />

          <ModalCreateUser
            open={createUserModal}
            toggle={() => setCreateUserModal(!createUserModal)}
            user={user}
            reload={reload}
            setReload={setReload}
            getUsersRH={getUsersRH}
          />

          <ModalMensagemSemReloadNew
            open={modalSuccess}
            /*     close={() => close()}
            callback={() => close()} */
            sucesso={true}
            mensagem={message}
            title={message && 'Sucesso'}
          />

          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
            <GenericButton
              onClick={() => {
                setCreateUserModal(true);
              }}
              width={'160px'}
              heigth={'35px'}
            >
              {' '}
              Criar novo usuário
            </GenericButton>
          </div>

          {users && users.length > 0 ? (
            <>
              <Table
                size="sm"
                headers={[
                  {
                    info: 'CPF',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'Nome',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'Email',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'Telefone',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'Perfis',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'Ações',
                    style: { textAlign: 'center' },
                  },
                ]}
                data={users.map((user, index) => {
                  return {
                    data: [
                      {
                        info: user.cpfcnpj,
                        style: { textAlign: 'center' },
                      },
                      {
                        info: user.name,
                        style: { textAlign: 'center' },
                      },
                      {
                        info: user.email,
                        style: { textAlign: 'center' },
                      },
                      {
                        info: user.phone_number,
                        style: { textAlign: 'center' },
                      },

                      {
                        info: (
                          <div style={{ display: 'flex', gap: '0.5rem', justifyContent: 'center' }}>
                            {user.profiles && user.profiles.length > 0 ? (
                              user.profiles.map((tipo) => (
                                <BadgeTipoConta tipoConta={tipo} styles={{ fontSize: '0.8rem' }} />
                              ))
                            ) : (
                              <h3 style={{ fontSize: '0.8rem' }} className="badge badge-primary">
                                Não definido
                              </h3>
                            )}
                          </div>
                        ),
                        style: { textAlign: 'center' },
                      },
                      {
                        info: (
                          <div>
                            <GenericButton
                              onClick={() => {
                                setUserRH(false);
                                setUserRH(user);
                                setModalEditUser(true);
                              }}
                              width={'100px'}
                              heigth={'35px'}
                            >
                              <Icon style={{ marginRight: '.4rem', fontSize: '1rem' }} className="fas fa-edit"></Icon>
                              Editar
                            </GenericButton>
                          </div>
                        ),
                        style: { textAlign: 'center' },
                      },
                    ],
                  };
                })}
              />
            </>
          ) : (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '1rem' }}>
              <h2>Sem registros</h2>
            </div>
          )}
        </ModalBody>
      )}
    </Modal>
  );
}
