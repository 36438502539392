import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBilletTransactions, getBilletTransactionsID } from '../../store/transactions/actions';
import moment from 'moment';
import GenericButton from '../../components/Buttons/Button.js';
import Icon from '../../components/Icons/IconsButton.js';
import { Box, MenuItem, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { formatCNPJ, formatCPF, formatDateBR, formatMoneyBRL, isCpf } from '../../utils/index.js';
import CustomDataTable from '../../components/DataTable/DataTable.jsx';
import StatusContainer from '../../components/StatusContainer/StatusContainer.js';
import Loading from '../../components/Loader/Loading.jsx';
import ModalInformationsBillet from '../BilletSales/ModalInformationsBillet.jsx';

export default function Charge() {
    const dispatch = useDispatch();

    const [modal, setModal] = useState({ open: false, id: '' });
    const [validStatusTypes, setValidStatusTypes] = useState([{ ID: 0, DESCRICAO: "Todos" }]);
    const [loading, setLoading] = useState(true);

    const {
        statusTransacao, loading_transaction,
        listBilletSales: { PAGINATION, RETORNO },
        detailBilletSales
    } = useSelector((state) => state.transactions);

    const {
        handleSubmit,
        getValues,
        register,
    } = useForm({
        defaultValues: {
            page: 1,
            limit: 10,
            search: '',
            status: 0,
            dataInicial: moment().subtract(1, 'M').format('YYYY-MM-DD'),
            dataFinal: moment().format('YYYY-MM-DD')
        }
    });


    useEffect(() => {
        dispatch(getBilletTransactions(getValues()));
    }, []);

    useEffect(() => {
        if (PAGINATION && PAGINATION.page && RETORNO) {
            setLoading(false);
        }
    }, [PAGINATION]);

    useEffect(() => {
        if (statusTransacao && statusTransacao.length > 0) {
            setValidStatusTypes([{ ID: 0, DESCRICAO: "TODOS" }].concat(statusTransacao.filter(status => status.ID <= 4)));
        }
    }, [statusTransacao]);

    function cleanFilters(filters) {
        const newFilter = filters;
        !newFilter.STATUS && delete newFilter.STATUS;
        return newFilter;
    }


    function getBoletos() {
        dispatch(getBilletTransactions(cleanFilters(getValues())));
    }

    function handleClick(ID) {
        dispatch(getBilletTransactionsID(ID));
        setModal({ open: true, id: ID});
    };

    return (
        <div style={{ marginTop: '1rem' }}>
            <Box
                sx={{ margin: "0 0 2rem 0" }}
            >
                <form
                    onSubmit={handleSubmit(getBoletos)}
                    style={{
                        display: 'flex',
                        flexFlow: 'column',
                        width: '100%',
                        justifyContent: 'flex-start',
                        gap: '1rem',
                        margin: '2rem 0',
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexFlow: "row",
                            flexWrap: "wrap",
                            gap: '10px',
                        }}
                    >
                        <TextField
                            {...register("search")}
                            autoFocus
                            label="Pesquisa"
                            placeholder="Nome, Conta ou CPF/CNPJ"
                            variant="outlined"
                            size='small'
                            sx={{ width: '20rem' }}
                        />
                        <TextField
                            {...register("status")}
                            select
                            label="Status"
                            defaultValue={0}
                            variant="outlined"
                            size='small'
                            sx={{ width: '15rem' }}
                        >
                            {validStatusTypes.map(({ ID, DESCRICAO }, index) => (
                                <MenuItem key={index} value={ID}>
                                    {DESCRICAO}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            label="Data Inicial"
                            {...register("dataInicial")}
                            type='date'
                            variant="outlined"
                            size='small'
                            sx={{ width: '15rem' }}
                        />

                        <TextField
                            label="Data Final"
                            {...register("dataFinal")}
                            type='date'
                            variant="outlined"
                            size='small'
                            sx={{ width: '15rem' }}
                        />
                        <GenericButton height="40px" width="140px" type="submit">
                            <Icon style={{ marginRight: '.4rem', fontSize: '1rem' }} className="fas fa-search" />
                            Buscar
                        </GenericButton>
                    </div>

                    <div style={{
                        marginTop: '1rem',
                        display: 'flex',
                        flexFlow: 'row',
                        gap: '1rem',
                        alignItems: 'center'
                    }}>
                        <TextField
                            {...register("limit")}
                            select
                            label="Limite"
                            variant="outlined"
                            size='small'
                            defaultValue="10"
                            sx={{ width: '10rem' }}
                        >
                            {['5', '10', '25', '50', '100', '1000'].map((label, index) => (
                                <MenuItem key={index} value={label}>
                                    {label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <span style={{ fontSize: 'small' }}>Resultados por página</span>
                    </div>
                </form>
            </Box>
            {loading ? (
                <Loading color="blue" />
            ) : (
                <CustomDataTable
                    loading={loading_transaction}
                    pagination={PAGINATION}
                    action={(row) => handleClick(row.ID)}
                    previousPage={{ onClick: () => getBoletos({ ...getValues(), PAGE: PAGINATION.prevPage }) }}
                    nowPage={{ page: PAGINATION.page, onClick: () => getBoletos({ ...getValues(), PAGE: PAGINATION.page }) }}
                    nextPage={{ onClick: () => getBoletos({ ...getValues(), PAGE: PAGINATION.nextPage }) }}
                    data={RETORNO ? RETORNO : []}
                    headers={[
                        {
                            name: 'DATA/HORA',
                            selector: (row) => formatDateBR(row.DATAREGISTRO),
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'NOME',
                            selector: (row) => row.NOME,
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'CPF/CNPJ',
                            selector: (row) =>
                                row.CPFCNPJ && (isCpf(row.CPFCNPJ) ? formatCPF(row.CPFCNPJ) : formatCNPJ(row.CPFCNPJ)),
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'BENEFICIÁRIO',
                            selector: (row) => row.NOMEBENEFICIARIO,
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'CPF/CNPJ BENEFICIÁRIO',
                            selector: (row) =>
                                row.CPFCNPJBENEFICIARIO && (isCpf(row.CPFCNPJBENEFICIARIO) ? formatCPF(row.CPFCNPJBENEFICIARIO) : formatCNPJ(row.CPFCNPJBENEFICIARIO)),
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'VENCIMENTO',
                            selector: (row) => `R$ ${formatMoneyBRL(row.DATAVENCIMENTO)}`,
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'VALOR',
                            selector: (row) => `R$ ${formatMoneyBRL(row.VALORDOCUMENTO)}`,
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'STATUS',
                            selector: (row) => (
                                <StatusContainer code={row.STATUSID} description={row.STATUS} />
                            ),
                            style: { justifyContent: 'start' },
                            sortable: true,
                        }
                    ]}
                    customStyles={{
                        cells: {
                            style: {
                                cursor: 'pointer',
                            },
                            headCells: {
                                style: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    textAlign: 'center'
                                },
                            },
                        },
                    }}
                />
            )}
            <ModalInformationsBillet
                toggle={() => setModal({ open: !modal.open, id: ''})}
                modal={modal.open}
                title={'Dados do boleto'}
                data={detailBilletSales}
                ID={modal.id}
            />
        </div>
    );
}
