import { Box, fontSize } from '@mui/system';
import { Modal } from 'reactstrap';
import { ButtonTransparent } from '../../../views/Companies/Modals/ModalEdit/styled';
import { Button } from '@mui/material';
import React from 'react';
import { IoClose } from 'react-icons/io5';

export default function ModalConfirmGeneric({ open, toggle, message, title, action, headerBgColor }) {
  return (
    <Modal className="modal-sm" isOpen={open} toggle={toggle}>
      <Box
        bgcolor={headerBgColor ? headerBgColor : 'rgb(25, 118, 210)'}
        width={'100%'}
        height={'4rem'}
        borderRadius={'6px 6px 0 0'}
        pl={'2rem'}
        pr={'2rem'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box mt={'0.2rem'}>
          <span style={{ fontSize: '1rem', color: '#ffffff', lineHeight: '0.1' }}>
            {title ? title : 'Tem certeza que deseja continuar?'}
          </span>
        </Box>
        <Box>
          <ButtonTransparent type="button" onClick={() => toggle()}>
            <IoClose />
          </ButtonTransparent>
        </Box>
      </Box>

      <Box width={'100%'} p={'1rem 1rem 1rem 1rem'}>
        <p style={{ fontSize: '0.9rem' }}>{message}</p>
      </Box>

      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'} p={'1rem'}>
        <Button size="small" variant="outlined" onClick={() => toggle()} color="error">
          Cancelar
        </Button>
        <Button size="small" variant="outlined" onClick={() => action()}>
          Confirmar
        </Button>
      </Box>
    </Modal>
  );
}
