import formatMoney from './formatMoneyBRL';

function formatTaxaCoban(value, id_tipo_valor) {
  if (id_tipo_valor == 1) {
    return `R$ ${formatMoney(value)}`;
  } else if (id_tipo_valor == 2) {
    return `${formatMoney(value)} %`;
  }
}

export default formatTaxaCoban;
