import React from 'react';
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  Label,
  Col,
  Row,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { connect } from 'react-redux';
import { Component } from 'react';
import { addSTATE } from '../../../store/globals';
import { getTaxaConfigs, updateTaxaConfigs, updateFinanciamentoConfigs } from '../../../store/config/actions';
import { formatMoney } from '../../../utils/formatMoneyBRL';

class ModalTaxasOperacionais extends Component {
  state = {
    dadosTaxas: [],
    dadosFinanciamento: [],
  };

  componentDidMount() {
    this.props.getTaxaConfigs();
  }

  onChange = (event) => {
    const { name, value } = event.target;
    var { config } = this.props;

    if (config.TAXA) {
      const dadosTaxas =
        config.TAXA &&
        config.TAXA.map((t) => {
          if (t.ID == name) t.TAXA = value;
          return t;
        });
      this.props.addSTATE('CONFIG_ADD_STATE', 'config', config);
      this.setState({ dadosTaxas });
    }
  };

  onChangeFinanciamento = (event, parcela_min, parcela_max) => {
    const { value } = event.target;
    var { config } = this.props;

    if (config.FINANCIAMENTO) {
      const dadosFinanciamento = config.FINANCIAMENTO.map((t) => {
        if (t.QNTD_PARCELAS >= parcela_min && t.QNTD_PARCELAS <= parcela_max) t.TAXA = value;

        return t;
      });

      this.props.addSTATE('CONFIG_ADD_STATE', 'config', config);
      this.setState({ dadosFinanciamento });
    }
  };

  getValue(ID) {
    var { config } = this.props;
    var value = '';

    if (config.TAXA) {
      value = config.TAXA.find((taxa) => taxa.ID == ID) ? config.TAXA.find((taxa) => taxa.ID == ID).TAXA : '';
    }

    if (ID == 15) {
      return parseInt(value);
    }

    return value;
  }

  getValueFinanciamento(ID) {
    var { config } = this.props;

    var value = '';
    if (config.FINANCIAMENTO) {
      value = config.FINANCIAMENTO.find((financiamento) => financiamento.QNTD_PARCELAS == ID)
        ? config.FINANCIAMENTO.find((financiamento) => financiamento.QNTD_PARCELAS == ID).TAXA
        : 0;
    }

    return value;
  }

  clickButton() {
    const ID_COBAN = this.props.config.FINANCIAMENTO.find((i) => i.ID_COBAN);
    const PARCELAS = this.state.dadosFinanciamento.map((i) => {
      return { PARCELA: i.QNTD_PARCELAS, VALOR: i.TAXA };
    });

    this.props.updateTaxaConfigs(this.state.dadosTaxas);
    this.props.updateFinanciamentoConfigs(this.state.dadosFinanciamento, PARCELAS, ID_COBAN);
    this.props.toggle();
  }

  render() {
    const { modal, toggle } = this.props;

    return (
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h3>Definir Taxas Operacionais</h3>
        </ModalHeader>

        <ModalBody
          style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', wordBreak: 'break-word' }}
        >
          <Form>
            <FormGroup>
              <Row xs="">
                <Col>
                  {' '}
                  <h5 style={styles.textStyle}>Transferências: </h5>
                </Col>
                <InputGroupAddon addonType="prepend">
                  <Col>
                    <Input
                      type="number"
                      style={styles.inputStyle}
                      value={this.getValue('3')}
                      name="3"
                      placeholder={formatMoney(this.getValue('3'))}
                      onChange={(event) => this.onChange(event)}
                      min="0"
                    />
                  </Col>
                  <InputGroupText>R$</InputGroupText>
                </InputGroupAddon>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row xs="2">
                <Col>
                  <h5 style={styles.textStyle}>Boleto: </h5>
                </Col>
                <InputGroupAddon addonType="prepend">
                  <Col>
                    {' '}
                    <Input
                      type="number"
                      style={styles.inputStyle}
                      value={this.getValue('1')}
                      placeholder={'R$'}
                      name="1"
                      onChange={(event) => this.onChange(event)}
                      min="0"
                    />
                  </Col>
                  <InputGroupText>R$</InputGroupText>
                </InputGroupAddon>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row xs="2">
                <Col>
                  {' '}
                  <h5 style={styles.textStyle}>Recebimento QR Code: </h5>
                </Col>
                <InputGroupAddon addonType="prepend">
                  <Col>
                    {' '}
                    <Input
                      type="number"
                      style={styles.inputStyle}
                      value={this.getValue('6')}
                      placeholder={'%'}
                      name="6"
                      onChange={(event) => this.onChange(event)}
                      min="0"
                    />
                  </Col>
                  <InputGroupText>%</InputGroupText>
                </InputGroupAddon>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row xs="2">
                <Col>
                  {' '}
                  <h5 style={styles.textStyle}>Juros empréstimos: </h5>
                </Col>
                <InputGroupAddon addonType="prepend">
                  <Col>
                    {' '}
                    <Input
                      type="number"
                      style={styles.inputStyle}
                      value={this.getValue('14')}
                      placeholder={'R$'}
                      name="14"
                      onChange={(event) => this.onChange(event)}
                    />
                  </Col>
                  <InputGroupText>%</InputGroupText>
                </InputGroupAddon>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row xs="2">
                <Col>
                  {' '}
                  <h5 style={styles.textStyle}>Prazo máximo empréstimo: </h5>
                </Col>
                <InputGroupAddon addonType="prepend">
                  <Col>
                    {' '}
                    <Input
                      type="number"
                      style={styles.inputStyle}
                      value={this.getValue('15')}
                      placeholder={''}
                      name="15"
                      onChange={(event) => this.onChange(event)}
                    />
                  </Col>
                  <InputGroupText>
                    <span style={{ fontSize: 10, fontWeight: 600 }}>DIAS</span>
                  </InputGroupText>
                </InputGroupAddon>
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter style={{ display: 'flex', alignItems: 'baseline' }}>
          <Button color="success" size="sm" block onClick={() => this.clickButton()}>
            {' '}
            Salvar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const styles = {
  inteiro: { width: '100%' },
  meio: { height: '50%' },

  inputStyle: { border: '1px solid', borderColor: '#d8d9da' },
  textStyle: { marginLeft: 10 },
};

const mapStateToProps = ({ user, config, coban }) => {
  return { user, config, coban };
};

export default connect(mapStateToProps, { addSTATE, getTaxaConfigs, updateTaxaConfigs, updateFinanciamentoConfigs })(
  ModalTaxasOperacionais,
);
