import React, { useEffect } from 'react';

import { useState } from 'react';
import { Alert, Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loading from '../../../components/Loader/Loading';
import api from '../../../service/api';
import ModalMensagemSemReloadNew from '../../Transaction/ModalMensagemSemReloadNew';
import { LabelContainer } from '../style';

export default function ModalTipoContaUsuario({ open, toggle, user, setReload, reload, userRH, getUsersRH }) {
  const [status, setStatus] = useState(userRH.profiles);
  const [loading, setLoading] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [message, setMessage] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    setMessage(false);
    if (open) {
      setProfiles(userRH.profiles);
    }
  }, [open]);

  function save() {
    setLoading(true);
    const dados = {
      UUID: user.UUID,
      USER_ID: userRH.id_user,
      TIPOS: profiles,
    };
    api
      .post('/conveniada/alterar-tipo-conta-usuario', dados)
      .then(({ data }) => {
        if (data) {
          if (data.retorno) {
            setMessage('Sucesso');
            /*          getUsersRH(); */
            setLoading(false);
            setModalSuccess(true);
          } else {
            setAlert(true);
          }
        }
      })
      .catch(() => setAlert(true))
      .finally(() => setLoading(false));
  }

  function close() {
    setReload(!reload);
    toggle();
    getUsersRH();
    setModalSuccess(false);
  }

  function handleCheck(e) {
    if (!e.target.checked) {
      setProfiles(profiles.filter((profile) => profile !== e.target.value));
    } else {
      setProfiles([...profiles, e.target.value]);
    }
  }

  function onDismiss() {
    setAlert(!alert);
  }
  return (
    <Modal className="modal-lg" isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <span style={{ fontSize: '1rem' }}>ALTERAR TIPO CONTA {userRH.name}</span>
      </ModalHeader>
      {loading ? (
        <Loading color="blue" />
      ) : (
        <ModalBody style={{ padding: '1rem' }}>
          <Alert color="danger" isOpen={alert} toggle={() => onDismiss()}>
            {'Algo inesperado aconteceu'}
          </Alert>
          <ModalMensagemSemReloadNew
            open={modalSuccess}
            close={() => close()}
            callback={() => close()}
            sucesso={true}
            mensagem={message}
            title={message && 'Sucesso'}
          />

          {profiles && (
            <div style={{ padding: '0 1.5rem', display: 'flex', gap: '1rem' }}>
              {user.TIPOS_CONTA.some((item) => item == '1') && (
                <LabelContainer>
                  <Label check>
                    <Input
                      type="checkbox"
                      onClick={(e) => handleCheck(e)}
                      value="1"
                      name="radio1"
                      checked={profiles.some((item) => item == '1')}
                    />
                    Cartão despesa
                  </Label>
                </LabelContainer>
              )}

              {user.TIPOS_CONTA.some((item) => item == '2') && (
                <LabelContainer>
                  <Label check>
                    <Input
                      type="checkbox"
                      onClick={(e) => handleCheck(e)}
                      name="radio1"
                      value="2"
                      checked={profiles.some((item) => item == '2')}
                    />
                    Cartão benefício
                  </Label>
                </LabelContainer>
              )}

              {user.TIPOS_CONTA.some((item) => item == '3') && (
                <LabelContainer>
                  <Label check>
                    <Input
                      type="checkbox"
                      onClick={(e) => handleCheck(e)}
                      name="radio1"
                      value="3"
                      checked={profiles.some((item) => item == '3')}
                    />
                    Cartão social
                  </Label>
                </LabelContainer>
              )}
            </div>
          )}
        </ModalBody>
      )}
      <ModalFooter>
        <Button color="success" onClick={() => save()}>
          Salvar
        </Button>
        <Button onClick={toggle}>Cancelar</Button>
      </ModalFooter>
    </Modal>
  );
}
