import React, { useEffect, useState } from 'react';
import { Alert, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { DualField, LabelTitle, Value } from './style';

import formatCPF from '../../../../../../utils/formatCPF';
import api from '../../../../../../service/api';
import Loading from '../../../../../../components/Loader/Loading';
import { cpfMask, dateMask, moneyMask } from '../../../../../../utils/functions';

export default function ModalBoletoDetails({ open, toggle, boletoId }) {
  const [voucher, setVoucher] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (boletoId) {
      setLoading(true);
      api
        .get(`boleto/comprovante?id=${boletoId}`)
        .then(({ data }) => {
          setVoucher(data.data);
        })
        .finally(() => setLoading(false));
    }
  }, [boletoId]);
  return (
    <Modal isOpen={open} toggle={toggle} size="lg" style={{ maxWidth: '600px' }}>
      <ModalHeader toggle={toggle}>
        <h2>Detalhes pagamento</h2>
      </ModalHeader>

      <ModalBody>
        {!loading ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {voucher ? (
              <div>
                <DualField>
                  <LabelTitle>Beneficiário</LabelTitle>
                  <Value>{voucher.cedente}</Value>
                </DualField>
                {voucher.nomeBeneficiario && (
                  <DualField>
                    <LabelTitle>Beneficiário final</LabelTitle>
                    <Value>{voucher.nomeBeneficiario}</Value>
                  </DualField>
                )}

                {voucher.docBeneficiario && (
                  <DualField>
                    <LabelTitle>CPF/CNPJ Beneficiário</LabelTitle>
                    <Value>{voucher.docBeneficiario}</Value>
                  </DualField>
                )}

                {voucher.pagadorRH && (
                  <DualField>
                    <LabelTitle>Pagador</LabelTitle>
                    <Value>{voucher.pagadorRH}</Value>
                  </DualField>
                )}

                {voucher.cpfcnpjPagadorRh && (
                  <DualField>
                    <LabelTitle>CPF/CNPJ Pagador</LabelTitle>
                    <Value>{cpfMask(voucher.cpfcnpjPagadorRh)}</Value>
                  </DualField>
                )}

                {voucher.codbarras && voucher.codbarras.length > 0 && (
                  <DualField>
                    <LabelTitle>Código de barras</LabelTitle>
                    <Value>{voucher.codbarras}</Value>
                  </DualField>
                )}

                {voucher.linhadigitavel && voucher.linhadigitavel.length > 0 && (
                  <DualField>
                    <LabelTitle>Linha digitável</LabelTitle>
                    <Value>{voucher.linhadigitavel}</Value>
                  </DualField>
                )}

                {voucher.dataVencimento && (
                  <DualField>
                    <LabelTitle>Vencimento</LabelTitle>
                    <Value>{dateMask(voucher.dataVencimento)}</Value>
                  </DualField>
                )}
                {voucher.dataLiquidacaoPagamento && (
                  <DualField>
                    <LabelTitle>Pago em</LabelTitle>
                    <Value>{voucher.dataLiquidacaoPagamento}</Value>
                  </DualField>
                )}
                <DualField>
                  <LabelTitle>Multa</LabelTitle>
                  <Value>{voucher.valorMulta ? moneyMask(voucher.valorMulta) : 'R$0,00'}</Value>
                </DualField>
                <DualField>
                  <LabelTitle>Juros</LabelTitle>
                  <Value>{voucher.valorJuros ? moneyMask(voucher.valorJuros) : 'R$0,00'}</Value>
                </DualField>

                <DualField>
                  <LabelTitle>Taxa</LabelTitle>
                  <Value>{moneyMask(voucher.taxa)}</Value>
                </DualField>

                <DualField>
                  <LabelTitle>Valor</LabelTitle>
                  <Value>{moneyMask(voucher.valor)}</Value>
                </DualField>

                <DualField noline={true}>
                  <LabelTitle>Valor total</LabelTitle>
                  <Value>{voucher.totalPago ? moneyMask(voucher.totalPago) : moneyMask(voucher.valor)}</Value>
                </DualField>

                {/*    <Alert sx={{ margin: '1rem 0', width: '90%' }} severity="error">
                Saldo insuficiente
              </Alert> */}
              </div>
            ) : (
              <Alert>Não encontrado</Alert>
            )}
          </div>
        ) : (
          <Loading color="blue"></Loading>
        )}
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
}
