import React, { useEffect, useState } from 'react';
import RequestLimit from './components/RequestLimit.jsx';
import LimitsUsers from './components/LimitsUsers.jsx';
import { Autocomplete, Box, Icon, ListItemText, MenuItem, Tab, Tabs, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getLimits, getRequestLimits, getTransactionsType } from '../../../store/transactions/actions.js';
import { useForm } from 'react-hook-form';
import GenericButton from '../../../components/Buttons/Button.js';
import api from '../../../service/api.js';


function Limits() {
    const dispatch = useDispatch();

    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [limitTypes, setLimitTypes] = useState([{ TIPO: "Todos", DESCRICAO: "Todos" }]);
    const [limits, setLimits] = useState({ limits: [], pagination: {} });
    const [partners, setPartners] = useState([{ id: "Todos", label: "Todos" }]);

    const { transactions: { parceiro } } = useSelector((state) => state);

    useEffect(() => {
        api
            .get("/limites-pix/buscar/tipo")
            .then(({ data: { data } }) => setLimitTypes((prev) => prev.concat(data)));
        dispatch(getTransactionsType());
    }, []);

    useEffect(() => {
        if (parceiro) {
            setPartners(
                [{ id: "Todos", label: "Todos" }].concat(parceiro.map(({ ID, DESCRICAO }) => ({ id: ID, label: DESCRICAO })))
            );
        }
    }, [parceiro]);

    const {
        handleSubmit,
        getValues,
        setValue,
        register,
        reset
    } = useForm({
        defaultValues: {
            PAGE: 1,
            LIMITE: 10,
            SEARCH: '',
            TIPO_LIMITE: '',
            TURNO: '',
            TIPO_USUARIO: '',
            PARCEIRO: ''
        }
    });

    const components = [
        <RequestLimit
            search={searchRequestLimits}
            loading={loading}
            limits={limits.limits}
            pagination={limits.pagination}
            getValues={getValues}
        />,
        <LimitsUsers
            search={searchLimits}
            loading={loading}
            limits={limits.limits}
            pagination={limits.pagination}
            getValues={getValues}
        />
    ];

    async function searchLimits(data) {
        setLoading(true);
        await dispatch(
            getLimits({ ...data }, (dados) => {
                if (dados.retorno === 'sucesso') {
                    setLimits({ limits: dados.limites.data, pagination: dados.limites.pagination });
                }
                setLoading(false);
            }),
        );
    }

    async function searchRequestLimits(data) {
        setLoading(true);
        await dispatch(
            getRequestLimits({ ...data }, (dados) => {
                if (dados.retorno === 'sucesso') {
                    setLimits({ limits: dados.limites.data, pagination: dados.limites.pagination });
                }

                setLoading(false);
            }),
        );
    }

    return (
        <>
            <Box>
                <form
                    onSubmit={handleSubmit(tab ? searchLimits : searchRequestLimits)}
                    style={{
                        display: 'flex',
                        flexFlow: 'column',
                        width: '100%',
                        justifyContent: 'flex-start',
                        gap: '1rem',
                        margin: '2rem 0',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexFlow: 'row',
                            gap: '1rem'
                        }}
                    >
                        <TextField
                            {...register("SEARCH")}
                            autoFocus
                            label="Pesquisa"
                            placeholder="Nome, Conta ou CPF/CNPJ"
                            variant="outlined"
                            size='small'
                            sx={{ width: '20rem' }}
                        />

                        <TextField
                            {...register("TIPO_LIMITE")}
                            select
                            label="Tipo Limite"
                            defaultValue="Todos"
                            size='small'
                            sx={{ width: '15rem' }}
                        >
                            {limitTypes.map(({ TIPO, DESCRICAO }) => (
                                <MenuItem key={TIPO} value={TIPO}>
                                    {DESCRICAO}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            {...register("TURNO")}
                            select
                            label="Turno"
                            defaultValue="Todos"
                            size='small'
                            sx={{ width: '10rem' }}
                        >
                            {['Todos', 'D', 'N'].map((label, index) => (
                                <MenuItem key={index} value={label}>
                                    {label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <Autocomplete
                            defaultValue="Todos"
                            onChange={(event, value) => value ? setValue('PARCEIRO', value.id) : 'Todos'}
                            options={partners}
                            renderOption={(params, item) => (
                                <li {...params} key={item.key} value={item.label}>
                                    <ListItemText>{item.label}</ListItemText>
                                </li>
                            )}
                            renderInput={(params) => <TextField {...params} label="Parceiro" />}
                            noOptionsText="Parceiro não encontrado"
                            size='small'
                            sx={{ width: '10rem' }}
                        />

                        <GenericButton height="40px" width="140px" type="submit">
                            <Icon style={{ marginRight: '.4rem', fontSize: '1rem' }} className="fas fa-search" />
                            Buscar
                        </GenericButton>
                    </div>
                    <div style={{
                        marginTop: '1rem',
                        display: 'flex',
                        flexFlow: 'row',
                        gap: '1rem',
                        alignItems: 'center'
                    }}>
                        <TextField
                            {...register("LIMITE")}
                            select
                            label="Limite"
                            size='small'
                            defaultValue="10"
                            sx={{ width: '10rem' }}
                        >
                            {['5', '10', '25', '50', '100', '1000'].map((label, index) => (
                                <MenuItem key={index} value={label}>
                                    {label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <span style={{ fontSize: 'small' }}>Resultados por página</span>
                    </div>
                </form>
                <Tabs value={tab} onChange={(e, newValue) => {
                    reset();
                    setLimits({ limits: [], pagination: {} });
                    setTab(newValue);
                }}>
                    <Tab label="Pedidos" sx={{ fontSize: '12px' }} />
                    <Tab label="Limites" sx={{ fontSize: '12px' }} />
                </Tabs>
                {components[tab]}
            </Box>
        </>
    );
}

export default Limits
