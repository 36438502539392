import { Badge } from 'reactstrap';
import React from 'react';
import formatMoney from '../../utils/formatMoneyBRL';
import { Box, Divider, Typography } from '@mui/material';
import { formatTaxaCoban } from '../../utils';

export const renderTaxas = (taxa, financiamento) => {
  var comparation = financiamento.length === 0;
  if (taxa && financiamento) {
    const taxas = [
      // { label: 'CC', value: comparation ? "0" :`${taxa[3].VALOR}%` },
      // { label: 'CD', value: comparation ? "0" :`${taxa[4].VALOR}%` },
      // { label: 'ANT', value: comparation ? "0" :`${taxa[1].VALOR}%` },

      { label: 'SAQUE', value: `R$${taxa[2].VALOR}` },
      { label: 'BOLETO', value: `R$${formatMoney(taxa[0].VALOR)}` },
      { label: 'QR CODE', value: `${taxa[5].VALOR} %` },
      { label: 'JUROS EMPRÉSTIMO', value: `${formatMoney(taxa[6].VALOR)} %` },
      //{ label: 'PRAZO EMPRÉSTIMO', value: `${taxa[7].VALOR} dias` },
      // { label: '1 a 3', value: comparation ? "0" :`${financiamento[0].TAXA}%` },
      // { label: '4 a 7', value: comparation ? "0": `${financiamento[3].TAXA}%` },
      // { label: '8 a 12', value: comparation ? "0" :`${financiamento[7].TAXA}%` }
    ];
    var x = 0;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {taxas.map((x, indice) => (
          // indice % 3 != 0 ? (
          <Badge style={{ padding: '0.2em', margin: '0.2em' }} color="success">
            {x.label}: {x.value}
          </Badge>
        ))}
      </div>
    );
  }
};

export const renderTaxasUsuario = (taxa, financiamento, beneficios) => {
  const formatBeneficios = (beneficios) => {
    return beneficios && beneficios.length > 0 ? (
      beneficios.map((beneficio) => {
        switch (+beneficio.FK_TIPO_TAXA) {
          case 24:
            return (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Saques</Typography>
                <Typography sx={{ color: 'green' }}>{beneficio.QUANTIDADE_ATUAL}</Typography>
              </Box>
            );
          case 25:
            return (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Recarga de Benefício</Typography>
                <Typography sx={{ color: 'green' }}>{beneficio.QUANTIDADE_ATUAL}</Typography>
              </Box>
            );
          case 1:
            return (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Geração de boleto</Typography>
                <Typography sx={{ color: 'green' }}>{beneficio.QUANTIDADE_ATUAL}</Typography>
              </Box>
            );
          case 3:
            return (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Transferência para outro banco</Typography>
                <Typography sx={{ color: 'green' }}>{beneficio.QUANTIDADE_ATUAL}</Typography>
              </Box>
            );
          case 7:
            return (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Pagamento de boleto</Typography>
                <Typography sx={{ color: 'green' }}>{beneficio.QUANTIDADE_ATUAL}</Typography>
              </Box>
            );
        }
      })
    ) : (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography>Não existem Benefícios</Typography>
      </Box>
    );
  };
const taxas = [
  { label: 'Emissão do cartão', value: 'R$ 0,00' },
  { label: 'Anuidade do cartão', value: 'R$ 0,00' },
  { label: 'Reemissão e envio do cartão', value: 'R$ 0,00' },
  { label: 'Transferência entre contas', value: 'R$ 0,00' },
  {
    label: `Transferência bancária TED / DOC`,
    value: taxa && taxa[2] ? formatTaxaCoban(taxa[2].VALOR, +taxa[2].ID_TIPO_VALOR) : 'Não definida',
  },
  {
    label: `Saque Banco 24 horas`,
    value: taxa && taxa[13] ? formatTaxaCoban(taxa[13].VALOR, +taxa[13].ID_TIPO_VALOR) : 'Não definida',
  },
  { label: 'Saque lotérica', value: 'R$ 0,00' },
  { label: 'Recarga lotérica', value: 'R$ 0,00' },
  { label: 'Saque no varejo (convênio)', value: 'R$ 0,00' },
  {
    label: `Emissão de boleto (recarga boleto)`,
    value: taxa && taxa[0] ? formatTaxaCoban(taxa[0].VALOR, +taxa[0].ID_TIPO_VALOR) : 'Não definida',
  },
  {
    label: `Antecipação`,
    value: taxa && taxa[1] ? formatTaxaCoban(taxa[1].VALOR, +taxa[1].ID_TIPO_VALOR) : 'Não definida',
  },
  {
    label: `Processamento CRÉDITO`,
    value: taxa && taxa[3] ? formatTaxaCoban(taxa[3].VALOR, +taxa[3].ID_TIPO_VALOR) : 'Não definida',
  },
  {
    label: `Processamento DÉBITO`,
    value: taxa && taxa[4] ? formatTaxaCoban(taxa[4].VALOR, +taxa[4].ID_TIPO_VALOR) : 'Não definida',
  },
  { label: 'IOF sobre a compra internacional', value: 'R$ 0,00' },
  {
    label: `Pagamento`,
    value: taxa && taxa[5] ? formatTaxaCoban(taxa[5].VALOR, +taxa[5].ID_TIPO_VALOR) : 'Não definida',
  },
  {
    label: `Pagamento de Boleto`,
    value: taxa && taxa[6] ? formatTaxaCoban(taxa[6].VALOR, +taxa[6].ID_TIPO_VALOR) : 'Não definida',
  },
  {
    label: `Juros Empréstimo`,
    value: taxa && taxa[7] ? formatTaxaCoban(taxa[7].VALOR, +taxa[7].ID_TIPO_VALOR) : 'Não definida',
  },
  {
    label: `Recarga Benefício`,
    value: taxa && taxa[14] ? formatTaxaCoban(taxa[14].VALOR, +taxa[14].ID_TIPO_VALOR) : 'Não definida',
  },
];
  return (
    <Box sx={{ width: '50%' }}>
      <Box>
        <Box sx={{ margin: '1rem 0' }}>
          <Typography sx={{ color: 'gray' }} variant="h5">
            Benefícios
          </Typography>
          <Divider sx={{ color: 'gray' }} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>{formatBeneficios(beneficios)}</Box>
      </Box>
      <Box>
        <Box sx={{ margin: '1rem 0' }}>
          <Typography sx={{ color: 'gray' }} variant="h5">
            Tarifas
          </Typography>
          <Divider sx={{ color: 'gray' }} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {taxas && taxas.map((taxa, indice) => (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>{taxa.label}</Typography>
              <Typography>{taxa.value}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
