import styled from 'styled-components';

export const DualField = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: ${(props) => (props.noline ? '' : 'solid 1px #cacaca')};
  width: 100%;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;

  span {
    margin-bottom: 0.5rem;
  }
`;

export const LabelTitle = styled.label`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  white-space: nowrap;
  font-weight: bold;
  padding-right: 1rem;
`;

export const Value = styled.span`
  margin: 0.1rem 0 0.2rem 0;
  font-size: 12px;
  color: #262424;
  word-break: break-word;
  /*  width: 100%; */
  /* white-space: nowrap; */
`;
