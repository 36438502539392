import { RESPONSE_CONFIG, CONFIG_ADD_STATE } from './types';

const INITIAL_STATE = {
  load_config: false,
  listConfigurations: [],
  TAXA: [],
  FINANCIAMENTO: [],
  mensagemTaxa: '',
  erro_config: false,
  erro_message_config: null,
  mensagemConfig: '',
  mensagemComValor: false,
  color: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESPONSE_CONFIG:
      return { ...state, ...action.payload };
    case CONFIG_ADD_STATE:
      return { ...state, [action.key]: action.payload };
    default:
      return state;
  }
};
