import styled from 'styled-components';

export const CustomBadge = styled.span`
  padding: 0.2rem 0.7rem;
  font-size: 0.7rem;
  text-align: center;
  background-color: #ddeaff;
  margin: 0.3rem;
  border-radius: 4px;
  font-weight: bold;
`;

export const CustomBadgeLink = styled.span`
  padding: 0.2rem 0.7rem;
  font-size: 0.7rem;
  text-align: center;
  background-color: #ddeaff;
  margin: 0.3rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
`;
