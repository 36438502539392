import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';

export const ContainerStyled = styled.div`
  width: 100%;
`;

export const Title = styled.h3`
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.389vw;
  line-height: 2.083vw;
  color: #32325d;
  margin-bottom: 1vw;
`;

export const Bar = styled(Divider)`
  width: 100%;
`;

export const ParagraphName = styled.div`
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  font-style: normal;
  line-height: 1.667vw;
  color: #32325d;
  margin-top: 0.764vw;
  height: 1.667vw;
`;

export const ParagraphValue = styled(ParagraphName)`
  font-weight: 400;
  color: #32325d;
`;

export const RowContainer = styled.div`
  border-bottom: 1.5px solid #9091941a;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0.694vw;
  width: 100%;

  &:last-child {
    border: none;
  }
`;

export const ImagesContainer = styled.div`
  h4 {
    text-align: center;
    margin-bottom: 0.5vw;
  }
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
`;
