import React from 'react';

import { Badge, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Loading from '../../../../../../components/Loader/Loading';
import { MainContainer } from './styles';
import { formatDateBR } from '../../../../../../utils/dateKyc';
import { formatCNPJ, formatCPF, isCpf } from '../../../../../../utils';

const styles = {
  metade: { width: '49%' },
  cheio: { width: '100%' },
};

export default function ModalChavePix({ toggle, open, loading, chavePix }) {
  return (
    <>
      <Modal isOpen={open} toggle={toggle} size="lg" style={{ maxWidth: '600px' }}>
        <ModalHeader toggle={toggle}>
          <h2>Detalhes da chave</h2>
        </ModalHeader>
        {chavePix ? (
          <ModalBody
            style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', wordBreak: 'break-word' }}
          >
            <Row style={styles.metade}>
              <Col>
                <h5>CPF/CNPJ</h5>
                <p>
                  {chavePix.CPFCNPJ
                    ? isCpf(chavePix.CPFCNPJ)
                      ? formatCPF(chavePix.CPFCNPJ)
                      : formatCNPJ(chavePix.CPFCNPJ)
                    : null}
                </p>
              </Col>
            </Row>
            <Row style={styles.metade}>
              <Col>
                <h5>Status chave</h5>
                <p>
                  {chavePix.ATIVO === 'S' ? (
                    <Badge style={{ fontSize: '.7rem' }} color="success">
                      {'ATIVA'}
                    </Badge>
                  ) : (
                    <Badge style={{ fontSize: '.7rem' }} color="danger">
                      {'INATIVA'}
                    </Badge>
                  )}
                </p>
              </Col>
            </Row>

            <Row style={styles.metade}>
              <Col>
                <h5>Agencia</h5>
                <p>{chavePix.AGENCIA}</p>
              </Col>
            </Row>
            <Row style={styles.metade}>
              <Col>
                <h5>Conta</h5>
                <p>{chavePix.CONTA}</p>
              </Col>
            </Row>

            <Row style={styles.metade}>
              <Col>
                <h5>Data de abertura da conta</h5>
                <p>{formatDateBR(chavePix.DATA_ABERTURA)}</p>
              </Col>
            </Row>
            <Row style={styles.metade}>
              <Col>
                <h5>Tipo Conta</h5>
                <p>{chavePix.TIPO_CONTA}</p>
              </Col>
            </Row>

            <Row style={styles.metade}>
              <Col>
                <h5>Nome</h5>
                <p>{chavePix.NOME}</p>
              </Col>
            </Row>
            <Row style={styles.metade}>
              <Col>
                <h5>Nome fantasia</h5>
                <p>{chavePix.NOME_FANTASIA ? chavePix.NOME_FANTASIA : 'N/A'}</p>
              </Col>
            </Row>

            <Row style={styles.metade}>
              <Col>
                <h5>Chave</h5>
                <p>{chavePix.CHAVE_PIX}</p>
              </Col>
            </Row>
            <Row style={styles.metade}>
              <Col>
                <h5>Tipo chave</h5>
                <p>
                  {' '}
                  <Badge style={{ fontSize: '.7rem', minWidth: '3rem' }} color="info">
                    {chavePix.TIPO_CHAVE}
                  </Badge>
                </p>
              </Col>
            </Row>

            <Row style={styles.metade}>
              <Col>
                <h5>Data chave</h5>
                <p>{formatDateBR(chavePix.DATA_CHAVE)}</p>
              </Col>
            </Row>
          </ModalBody>
        ) : (
          <Loading color="blue" />
        )}
      </Modal>
    </>
  );
}
