import React from 'react';
import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap';

export default function ModalMessageGeneric({ retorno, open, toggle }) {
  return (
    <Modal isOpen={open} toggle={toggle}>
      {retorno && (
        <>
          <ModalHeader>{retorno === 'sucesso' ? <h5>Sucesso!</h5> : <h5>Algo deu errado !</h5>}</ModalHeader>
          <ModalFooter>
            <Button color={retorno === 'sucesso' ? 'success' : 'danger'} onClick={toggle}>
              Ok
            </Button>{' '}
          </ModalFooter>
        </>
      )}
    </Modal>
  );
}
