import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import JoditEditor from 'jodit-react';
import { MainContainer } from './styles';
import Loading from '../../components/Loader/Loading';
import api from '../../service/api';

export default function ModalTermoPDF({ toggle, open, callback, idContract }) {
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);

  function getPdfContract() {
    setLoading(true);
    api
      .get(`contrato/termo?id_contract=${idContract}&pdf=true`)
      .then((res) => setData(res.data.data))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (idContract) {
      getPdfContract();
    }
  }, [open]);

  return (
    <>
      <Modal isOpen={open} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}></ModalHeader>
        {!loading ? (
          <ModalBody>
            <MainContainer>
              {data && (
                <object
                  data={`data:application/pdf;base64,${data}`}
                  type="application/pdf"
                  width="100%"
                  height="600px"
                ></object>
              )}
            </MainContainer>
          </ModalBody>
        ) : (
          <Loading color="blue" />
        )}
      </Modal>
    </>
  );
}
