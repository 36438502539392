import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import * as Styled from '../../../ModalInfo.styles';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Button, Col, Row } from 'reactstrap';
import { formatDocument } from '../../../../../../utils/format';
import { useDispatch, useSelector } from 'react-redux';
import { listaUserDetalhes } from '../../../../../../store/user/actions';
import ModalEditarRepresentanteLegal from '../../../../../../views/User/components/modals/ModalEditarRepresentanteLegal';
import { unformatDateKyc } from '../../../../../../utils/dateKyc';
import { ExpandMore } from '@material-ui/icons';
import ContratosConsentimentoTableRepresentative from '../../../../../ContratosConsentimentoTable/ContratosConsentimentoTableRepresentative';
import AccordionRepresentativesDocuments from '../TabDocumentos/AccordionRepresentativesDocuments';
import AccordionDocumentos from '../TabDocumentos/AccordionDocumentos';

const AccordionRepresentantes = ({ REPRESENTANTES, setModalCreateRepresentative, isRepresentante = false, contracts,getDetailedContract, loading }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [modalUpdateRepresentative, setModalUpdateRepresentative] = useState({ open: false, representante: [] });

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5" sx={{ width: '80%', fontWeight: '600', fontSize: '18px', flexShrink: 0 }}>
            Representantes
          </Typography>
          {!isRepresentante && (
            <Button color="info" size="sm" onClick={() => setModalCreateRepresentative(true)}>
              Criar representante
            </Button>
          )}
        </AccordionSummary>
        <AccordionDetails sx={{ gap: '1rem' }}>
          {REPRESENTANTES && REPRESENTANTES.length > 0 ? (
            REPRESENTANTES.map((REPRESENTANTE) => (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant="h5" sx={{ fontSize: '18px', flexShrink: 0 }}>
                      <b>{REPRESENTANTE.NOME}</b> - {formatDocument(REPRESENTANTE.CPF)}
                    </Typography>
                    {!isRepresentante && (
                      <Button
                        color="info"
                        size="sm"
                        style={{ marginRight: '4.5rem' }}
                        onClick={() => setModalUpdateRepresentative({ open: true, representante: REPRESENTANTE })}
                      >
                        Editar Representante
                      </Button>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Row>
                    <Col>
                      <Styled.ParagraphName>
                        CPF/CNPJ:{' '}
                        <Typography variant="p" {...styles.paragraph}>
                          {formatDocument(REPRESENTANTE.CPF)}
                        </Typography>
                      </Styled.ParagraphName>
                    </Col>
                    <Col>
                      <Styled.ParagraphName>
                        Nome:{' '}
                        <Typography variant="p" {...styles.paragraph}>
                          {REPRESENTANTE.NOME}
                        </Typography>
                      </Styled.ParagraphName>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Styled.ParagraphName>
                        Email:{' '}
                        <Typography variant="p" {...styles.paragraph}>
                          {REPRESENTANTE.EMAIL}
                        </Typography>
                      </Styled.ParagraphName>
                    </Col>
                    <Col>
                      <Styled.ParagraphName>
                        Data de Nascimento:{' '}
                        <Typography variant="p" {...styles.paragraph}>
                          {unformatDateKyc(REPRESENTANTE.DATA_FUND_NASC)}
                        </Typography>
                      </Styled.ParagraphName>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Styled.ParagraphName>
                        Telefone:{' '}
                        <Typography variant="p" {...styles.paragraph}>
                          {REPRESENTANTE.CELULAR}
                        </Typography>
                      </Styled.ParagraphName>
                    </Col>
                    <Col>
                      <Styled.ParagraphName>
                        CEP:{' '}
                        <Typography variant="p" {...styles.paragraph}>
                          {REPRESENTANTE.CEP}
                        </Typography>
                      </Styled.ParagraphName>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Styled.ParagraphName>
                        Endereço:{' '}
                        <Typography variant="p" {...styles.paragraph}>
                          {REPRESENTANTE.ENDERECO}
                        </Typography>
                      </Styled.ParagraphName>
                    </Col>
                    <Col>
                      <Styled.ParagraphName>
                        Bairro:{' '}
                        <Typography variant="p" {...styles.paragraph}>
                          {REPRESENTANTE.BAIRRO}
                        </Typography>
                      </Styled.ParagraphName>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Styled.ParagraphName>
                        Complemento:{' '}
                        <Typography variant="p" {...styles.paragraph}>
                          {REPRESENTANTE.COMPLEMENTO}
                        </Typography>
                      </Styled.ParagraphName>
                    </Col>
                    <Col>
                      <Styled.ParagraphName>
                        Cidade:{' '}
                        <Typography variant="p" {...styles.paragraph}>
                          {REPRESENTANTE.CIDADE}
                        </Typography>
                      </Styled.ParagraphName>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Styled.ParagraphName>
                        UF:{' '}
                        <Typography variant="p" {...styles.paragraph}>
                          {REPRESENTANTE.UF}
                        </Typography>
                      </Styled.ParagraphName>
                    </Col>
                  </Row>
                  
                 
                </AccordionDetails>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography variant="h5" sx={{ width: '80%', fontWeight: '600', fontSize: '18px', flexShrink: 0 }}>
                        Contratos
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ContratosConsentimentoTableRepresentative
                        representantive={REPRESENTANTE}
                        contracts={contracts}
                        loading={loading} 
                        getDetailedContract={getDetailedContract}/>
                    </AccordionDetails>
                  </Accordion>
                  <AccordionDocumentos expanded={true} title="Documentos" DOCUMENTOS={REPRESENTANTE.DOCUMENTOS} />
                {REPRESENTANTE.REPRESENTANTES && REPRESENTANTE.REPRESENTANTES.length > 0 && (
                  <>
                    <AccordionRepresentantes
                      REPRESENTANTES={REPRESENTANTE.REPRESENTANTES}
                      setModalCreateRepresentative={setModalCreateRepresentative}
                      isRepresentante={true}
                    />
                    ,
                  </>
                )}
              </Accordion>
            ))
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="p">Não existem representantes</Typography>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
     
      {user && (
        <ModalEditarRepresentanteLegal
          user={user}
          modal={modalUpdateRepresentative.open}
          representante={modalUpdateRepresentative.representante}
          callBack={() => {
            dispatch(listaUserDetalhes(user.UUID));
            setModalUpdateRepresentative({ open: false, representante: [] });
          }}
          toggle={() => setModalUpdateRepresentative({ open: false, representante: [] })}
        />
      )}
    </>
  );
};

const styles = {
  paragraph: { fontWeight: 400, color: '#32325d' },
};

export default AccordionRepresentantes;
