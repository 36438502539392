import React, { useState } from 'react';
import Modal from '../Modal/Modal';
import Button from '../Buttons/Button';
import { useForm } from 'react-hook-form';
import { MdErrorOutline } from 'react-icons/md';
import TextInput from '../TextInput/TextInput';
import * as Styled from './RenovacaoJwt.styles';
import { useDispatch } from 'react-redux';
import { renovationJwtAction } from '../../store/auth/actions';
import ModalMensagemJWT from '../../views/Transfer/component/ModalMensagemJWT';

const RenovacaoJwt = ({ open, close, setView }) => {
  const { handleSubmit: handleUserPassword, control: controlPassword } = useForm({});
  const [data, setData] = useState('');
  const [modalMensagem, setModalMensagem] = useState(false);
  const dispatch = useDispatch();

  const validatePassword = (data) => {
    data.CPFCNPJ = localStorage.getItem('CPFCNPJ');
    dispatch(
      renovationJwtAction(data, (res) => {
        res && close();
        setData(res);
        res && res.icon === 'error' && setModalMensagem(true);
      }),
    );
  };
  return (
    <>
      <Modal open={open} width={'32%'}>
        <Styled.SmallerModal>
          <MdErrorOutline color={'#F29305'} size={'6.944vw'} />
          <h3>Inserir Senha</h3>
          <h5>Sua sessão será encerrada em 1 minuto. Digite sua senha para permanecer logado</h5>
          <form onSubmit={handleUserPassword(validatePassword)}>
            <h4>Senha</h4>
            <TextInput
              margin={'0'}
              variant={'outlined'}
              name={'SENHA'}
              width={'100%'}
              required={true}
              type={'password'}
              control={controlPassword}
            />
            <Styled.ButtonContainer>
              <Button
                backgroundColor={'#F8F8F8'}
                width={'13.792vw'}
                height={'3.125vw'}
                hoverBGColor={'#F8F8F8'}
                hoverColor={'#262424'}
                color={'#262424'}
                onClick={() => close()}
              >
                <span>Voltar</span>
              </Button>
              <Button
                backgroundColor={'#303545'}
                width={'13.792vw'}
                height={'3.125vw'}
                hoverBGColor={'#EC5C21'}
                hoverColor={'#F8F8F8'}
                color={'#F8F8F8'}
                type="submit"
                roleName={'button-confirmar-negar'}
              >
                <span>Confirmar</span>
              </Button>
            </Styled.ButtonContainer>
          </form>
        </Styled.SmallerModal>
      </Modal>
      <ModalMensagemJWT
        modal={modalMensagem}
        mensagem={data.title}
        sucesso={false}
        toggle={() => {
          setModalMensagem(false);
          setView();
        }}
      />
    </>
  );
};

export default RenovacaoJwt;
