import React, { useEffect } from 'react';

import { useState } from 'react';
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from 'react-icons/ai';
import ReactInputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ModalGeneric from '../../../components/Modais/ModalGeneric';
import { criarUsuarioPortalRH, listarNiveisAcesso } from '../../../store/coban/actions';
import validacaoEmail from '../../../utils/isValidEmail';
import { Container, ContainerVerifyStyle, VerifyIconStyle, VerifyStyle, VerifyTitle } from '../style';
import { unformatDateKyc } from '../../../utils/dateKyc';
import api from '../../../service/api';
import removeMask from '../../../utils/removeMask';
import DotLoader from '../../../components/DotLoader/DotLoader';
import ModalMensagemSemReloadNew from '../../Transaction/ModalMensagemSemReloadNew';

export default function ModalCreateUser({ open, toggle, user, getUsersRH }) {
  const { CONVENED_RESPONSE, NIVEL_ACESSO } = useSelector((state) => state.coban);
  const [nestedModal, setNestedModal] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [validEmail, setIsValidEmail] = useState(true);
  const [blockedFields, setBlockedFields] = useState(true);
  const [userData, setUserData] = useState(false);

  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [message, setMessage] = useState(false);
  const [profiles, setProfiles] = useState([]);

  const [form, setForm] = useState({
    NOME: '',
    CPFCNPJ_USUARIO: '',
    EMAIL: '',
    SENHA: '',
    CPFCNPJ_EMPRESA: '',
    NIVEL_ACESSO: 1,
    TELEFONE: '',
  });
  const [validatePassword, setValidatePassword] = useState({
    mai: false,
    min: false,
    num: false,
    tam: false,
    car: false,
    allTrue: false,
  });

  const onDismiss = () => setMessage(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setForm({
      NOME: '',
      CPFCNPJ_USUARIO: '',
      EMAIL: '',
      SENHA: '',
      NIVEL_ACESSO: 1,
      TELEFONE: '',
    });
    dispatch(listarNiveisAcesso());
  }, []);

  function handleCheck(e) {
    if (!e.target.checked) {
      setProfiles(profiles.filter((profile) => profile !== e.target.value));
    } else {
      setProfiles([...profiles, e.target.value]);
    }
  }

  useEffect(() => {
    setForm({ ...form, CPFCNPJ_EMPRESA: user.CPFCNPJ });
  }, [form]);

  useEffect(() => {
    form && disableButton();
  }, [form]);

  useEffect(() => {
    if (form.CPFCNPJ_USUARIO.length !== 14) {
      setUserData(false);
      setBlockedFields(true);
      setMessage(false);
      setForm({ ...form, NOME: '', EMAIL: '', SENHA: '', NIVEL_ACESSO: 1, TELEFONE: '' });
    }
  }, [form.CPFCNPJ_USUARIO]);

  function disableButton() {
    if (form.NOME.length === 0) return setDisabledButton(true);
    if (form.CPFCNPJ_USUARIO.length === 0) return setDisabledButton(true);
    if (form.EMAIL.length === 0) return setDisabledButton(true);
    if (form.SENHA.length === 0) return setDisabledButton(true);
    if (form.TELEFONE.length === 0) return setDisabledButton(true);

    if (!validEmail) return setDisabledButton(true);
    if (!validatePassword.allTrue) return setDisabledButton(true);

    return setDisabledButton(false);
  }

  function createUser() {
    setLoading(true);
    const dados = {
      ID: user.ID,
      NOME: user.NOME,
      CPFCNPJ_CLIENTE: removeMask(user.CPFCNPJ),
      DATA: unformatDateKyc(user.NASCIMENTO),
      EMAIL: user.EMAIL,
      FONECEL: user.FONECEL,
      CONVENIADA: 'S',
      CEP: user.CEP,
      ENDERECO: user.ENDERECO,
      NUMERO: user.NUMERO,
      COMPLEMENTO: user.COMPLEMENTO,
      BAIRRO: user.BAIRRO,
      CIDADE: user.CIDADE,
      UF: user.UF,
      CONTA: user.CONTA,
      AGENCIA: user.AGENCIA,
      UUID: user.UUID,
    };

    /*  dispatch(criarUsuarioPortalRH({ ...form, conveniada: dados })); */
    api
      .post('/conveniada/rh/user', {
        ...form,
        CPFCNPJ_USUARIO: removeMask(form.CPFCNPJ_USUARIO),
        TELEFONE: removeMask(form.TELEFONE),
        PERFIS: profiles,
        conveniada: dados,
      })
      .then((res) => {
        if ((res.data.message = 'User created!')) {
          setFeedback(true);
        } else {
          if (res.data.message) {
            setMessage(res.data.message);
          } else {
            setMessage('Algo inesperado aconteceu');
          }
        }
      })
      .catch(() => {
        setMessage('Algo inesperado aconteceu');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function checkEmail(e) {
    setIsValidEmail(validacaoEmail(e));
    setForm({ ...form, EMAIL: e });
  }

  function closeNestedModal() {
    getUsersRH();
    setForm({
      NOME: '',
      CPFCNPJ_USUARIO: '',
      EMAIL: '',
      SENHA: '',
      NIVEL_ACESSO: 1,
      TELEFONE: '',
    });

    setNestedModal(false);
    setUserData(false);
    setBlockedFields(true);
    toggle();
  }

  function toggleModal() {
    setForm({
      NOME: '',
      CPFCNPJ_USUARIO: '',
      EMAIL: '',
      SENHA: '',
      NIVEL_ACESSO: 1,
      TELEFONE: '',
    });
    setValidatePassword({
      mai: false,
      min: false,
      num: false,
      tam: false,
      car: false,
      allTrue: false,
    });
    toggle();
  }

  const VerifyPassword = (password) => {
    //const hasUpper = (password) => /[A-Z]/.test(password);
    const hasEspecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasUpper = /[A-Z]/.test(password);
    const hasLow = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);

    const hasLength = password.length >= 8;
    // eslint-disable-next-line
    const allTrue = (hasEspecial, hasUpper, hasLow, hasNumber, hasLength);

    return {
      mai: hasUpper,
      min: hasLow,
      num: hasNumber,
      tam: hasLength,
      car: hasEspecial,
      allTrue,
    };
  };

  const onChangePassword = (e) => {
    setForm({ ...form, SENHA: e.target.value });
    setValidatePassword(VerifyPassword(e.target.value));
  };

  function checkUserPortal() {
    setLoading(true);
    const cpf = removeMask(form.CPFCNPJ_USUARIO);
    api
      .get(`conveniada/rh/check-user?cpf=${cpf}&company_uuid=${user.UUID}`)
      .then((res) => {
        if (res.data) {
          if (res.data.message == 'User not found!') {
            setBlockedFields(false);
          } else if (res.data.message == 'User available in this company!') {
            /*     const { name, phone_number, email } = res.data.data; */
            setUserData(res.data.data);
          } else {
            setMessage('Usuário já cadastrado nessa empresa');
          }
        }
      })
      .catch(() => {
        setMessage('Usuário já cadastrado nessa empresa');
      })
      .finally(() => setLoading(false));
  }

  function addUserPortal() {
    setLoading(true);
    const cpf = removeMask(form.CPFCNPJ_USUARIO);
    const data = {
      cpf: cpf,
      company_uuid: user.UUID,
      PERFIS: profiles,
    };
    api
      .post(`/conveniada/rh/add-user`, { ...data })
      .then((res) => {
        if (res.data) {
          if (res.data.message == 'Success!') {
            setFeedback(true);
          } else {
            setMessage('Algo inesperado aconteceu');
          }
        }
      })
      .catch(() => {
        setMessage('Algo inesperado aconteceu');
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (userData) {
      setForm({
        ...form,
        NOME: userData.name,
        EMAIL: userData.email,
        SENHA: '*******',
        TELEFONE: userData.phone_number,
      });
    }
  }, [userData]);

  useEffect(() => {
    if (form.CPFCNPJ_USUARIO.length === 14) {
      checkUserPortal();
    }
  }, [form.CPFCNPJ_USUARIO]);
  return (
    <Modal className="modal-lg" isOpen={open} toggle={() => toggleModal()}>
      <ModalHeader toggle={() => toggleModal()}>Criar Usuário</ModalHeader>

      <ModalBody>
        <Alert color="danger" isOpen={message} toggle={() => onDismiss()}>
          {message}
        </Alert>
        {CONVENED_RESPONSE && (
          <ModalGeneric
            open={nestedModal}
            close={() => closeNestedModal()}
            message={CONVENED_RESPONSE === 'User created!' ? 'Usuario criado com sucesso' : CONVENED_RESPONSE}
            condition={CONVENED_RESPONSE === 'User created!'}
          ></ModalGeneric>
        )}

        <ModalMensagemSemReloadNew
          open={feedback}
          close={() => {
            setFeedback(false);
          }}
          callback={() => {
            setFeedback(false);
            closeNestedModal();
          }}
          sucesso={true}
          mensagem={'Usuário cadastrado com sucesso'}
          title={'Sucesso'}
        />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Container>
            <label>
              CPF
              <Input
                tag={ReactInputMask}
                maskChar=""
                mask={'999.999.999-99'}
                type="text"
                value={form.CPFCNPJ_USUARIO}
                onChange={(e) => setForm({ ...form, CPFCNPJ_USUARIO: e.target.value })}
              ></Input>
            </label>
            <label>
              Nome
              <Input
                disabled={blockedFields ? true : false}
                style={{ backgroundColor: blockedFields ? '#dcdcdc' : '' }}
                type="text"
                value={form.NOME}
                onChange={(e) => setForm({ ...form, NOME: e.target.value })}
              ></Input>
            </label>
            <label>
              Email
              <Input
                error
                className={!validEmail && 'invalid-email'}
                type="email"
                pattern="[^ @]*@[^ @]*"
                size="30"
                value={form.EMAIL}
                helperText="Incorrect entry."
                disabled={blockedFields ? true : false}
                style={{ backgroundColor: blockedFields ? '#dcdcdc' : '' }}
                onChange={(e) => checkEmail(e.target.value)}
              ></Input>
            </label>

            <label>
              Telefone
              <Input
                type="text"
                tag={ReactInputMask}
                value={form.TELEFONE}
                mask={form.TELEFONE.length === 14 || form.TELEFONE.length === 11 ? '(99)99999-9999' : '(99)9999-99999'}
                maskChar=""
                disabled={blockedFields ? true : false}
                style={{ backgroundColor: blockedFields ? '#dcdcdc' : '' }}
                onChange={(e) => setForm({ ...form, TELEFONE: e.target.value })}
              ></Input>
            </label>
          </Container>
          <Container>
            {/*       {NIVEL_ACESSO && (
              <label>
                Nível de Acesso
                <Input type="select" name="select" onChange={(e) => setForm({ ...form, NIVEL_ACESSO: e.target.value })}>
                  <option value="">Selecione</option>
                  {NIVEL_ACESSO.map((nivel) => (
                    <option value={nivel.id}>{nivel.access_level_name}</option>
                  ))}
                </Input>
              </label>
            )} */}
            <label>
              Senha
              <Input
                inputProps={{
                  minLength: 8,
                  title: 'A senha deve conter no mínimo 8 caracters',
                }}
                value={form.SENHA}
                type="password"
                disabled={blockedFields ? true : false}
                style={{ backgroundColor: blockedFields ? '#dcdcdc' : '' }}
                onChange={(e) => onChangePassword(e)}
              ></Input>
            </label>

            <ContainerVerifyStyle>
              <VerifyTitle>Por segurança, a senha deve seguir os critérios abaixo:</VerifyTitle>

              <VerifyStyle verify={validatePassword.tam}>
                <VerifyIconStyle>
                  {validatePassword.tam ? <AiOutlineCheckCircle /> : <AiOutlineExclamationCircle />}
                </VerifyIconStyle>
                Mínimo 8 caracteres
              </VerifyStyle>

              <VerifyStyle verify={validatePassword.min}>
                <VerifyIconStyle>
                  {validatePassword.min ? <AiOutlineCheckCircle /> : <AiOutlineExclamationCircle />}
                </VerifyIconStyle>
                Pelo menos uma letra minúscula
              </VerifyStyle>

              <VerifyStyle verify={validatePassword.mai}>
                <VerifyIconStyle>
                  {validatePassword.mai ? <AiOutlineCheckCircle /> : <AiOutlineExclamationCircle />}
                </VerifyIconStyle>
                Pelo menos uma letra maiúscula
              </VerifyStyle>

              <VerifyStyle verify={validatePassword.num}>
                <VerifyIconStyle>
                  {validatePassword.num ? <AiOutlineCheckCircle /> : <AiOutlineExclamationCircle />}
                </VerifyIconStyle>
                Pelo menos um número
              </VerifyStyle>

              <VerifyStyle verify={validatePassword.car}>
                <VerifyIconStyle>
                  {validatePassword.car ? <AiOutlineCheckCircle /> : <AiOutlineExclamationCircle />}
                </VerifyIconStyle>
                Pelo menos um caractere especial (Ex: !@#$%¨&*)
              </VerifyStyle>
            </ContainerVerifyStyle>

            <VerifyTitle>Perfis do usuario:</VerifyTitle>
            <div style={{ padding: '0 1.5rem', display: 'flex', flexDirection: 'column' }}>
              {user.TIPOS_CONTA.some((item) => item == '1') && (
                <Label check>
                  <Input
                    type="checkbox"
                    onClick={(e) => handleCheck(e)}
                    value="1"
                    name="radio1"
                    checked={profiles.some((item) => item == '1')}
                  />
                  Cartão despesa
                </Label>
              )}

              {user.TIPOS_CONTA.some((item) => item == '2') && (
                <Label check>
                  <Input
                    type="checkbox"
                    onClick={(e) => handleCheck(e)}
                    name="radio1"
                    value="2"
                    checked={profiles.some((item) => item == '2')}
                  />
                  Cartão benefício
                </Label>
              )}

              {user.TIPOS_CONTA.some((item) => item == '3') && (
                <Label check>
                  <Input
                    type="checkbox"
                    onClick={(e) => handleCheck(e)}
                    name="radio1"
                    value="3"
                    checked={profiles.some((item) => item == '3')}
                  />
                  Cartão social
                </Label>
              )}
            </div>
          </Container>
        </div>
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <Button disabled={disabledButton} color="success" onClick={() => createUser()}>
            <div
              style={{ width: '60px', height: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <DotLoader white={true} />
            </div>
          </Button>
        ) : (
          <>
            {userData ? (
              <Button onClick={() => addUserPortal()} color="success">
                Adicionar
              </Button>
            ) : (
              <Button disabled={disabledButton} color="success" onClick={() => createUser()}>
                Criar
              </Button>
            )}
          </>
        )}
        <Button color="danger" onClick={() => toggleModal()}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
