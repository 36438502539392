import React, { useEffect } from 'react';
import Table from '../Table/Table';

import valueEmpty from '../../utils/valueEmpty';
import Loading from '../Loader/Loading';
import StatusContainer from '../StatusContainer/StatusContainer';
import { formatDateBR } from '../../utils/dateKyc';
import BadgeTipoConta from '../BadgeTipoConta/BadgeTipoConta';
import formatMoney from '../../utils/formatMoneyBRL';
import { cpfMask } from '../../utils/functions';

import { Tooltip } from 'reactstrap';
import { BuildCircle, LocalAtm, ReceiptLong } from '@mui/icons-material';
import GenericButton from '../Button/Button.style';
import CustomDataTable from '../DataTable/DataTable';

function SegundaViaRHDataTable({
  requests,
  loading,
  requestPagination,
  page,
  setFilter,
  filter,
  chooseBadge,
  setModalDetails,
  setStatus,
  setId,
  setModalStatus,
}) {
  const tablePagination = async (type = 'atual', pageClick) => {
    switch (type) {
      case 'prev':
        page = requestPagination.prevPage;
        break;
      case 'next':
        page = requestPagination.nextPage;
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }
    await setFilter({ ...filter, page: page });
  };

  const headers = [
    {
      name: 'ID',
      selector: (row) => +row.ID,
      style: { textAlign: 'start', maxWidth: '10px' },
      sortable: true,
    },
    {
      name: 'Data',
      selector: (row) => formatDateBR(row.DATA_INSERT),
      style: { justifyContent: 'center' },
      sortable: true,
    },
    {
      name: 'Empresa',
      selector: (row) => row.EMPRESA,
      style: { justifyContent: 'center' },
      sortable: true,
    },
    {
      name: 'Colaborador',
      selector: (row) => row.COLABORADOR,
      style: { justifyContent: 'center' },
      sortable: true,
    },
    {
      name: 'Cpf colaborador',
      selector: (row) => cpfMask(row.CPF_COLABORADOR),
      style: { justifyContent: 'center' },
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => valueEmpty(row.FK_STATUS_SEGUNDA_VIA && chooseBadge(row.FK_STATUS_SEGUNDA_VIA)),
      style: { justifyContent: 'center !important' },
    },
    {
      name: 'Ações',
      selector: (row) => (
        <div style={{ display: 'flex', width: '100px' }}>
          <GenericButton
            title="Histórico"
            width={'32px'}
            backgroundColor="#f0a24a"
            borderColor="#f0a24a"
            size="m"
            onClick={() => {
              setModalDetails(true);
              setId(row.ID);
            }}
          >
            <ReceiptLong />
          </GenericButton>

          <GenericButton
            title="Alterar status"
            width={'32px'}
            backgroundColor="#2405f2"
            borderColor="#2405f2"
            size="m"
            onClick={() => {
              setId(row.ID);
              setStatus(row.FK_STATUS_SEGUNDA_VIA);
              setModalStatus(true);
            }}
          >
            <BuildCircle />
          </GenericButton>
        </div>
      ),
      style: { justifyContent: 'center' },
    },
  ];

  return (
    <>
      <CustomDataTable
        loading={loading}
        pagination={requestPagination}
        previousPage={{ onClick: () => tablePagination('prev') }}
        nowPage={{ page, onClick: tablePagination }}
        nextPage={{ onClick: () => tablePagination('next') }}
        data={requests}
        headers={headers}
      />
    </>
  );
}

export default SegundaViaRHDataTable;
