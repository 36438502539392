import {
  RESPONSE_RISK,
  GET_RISK_ANALYSIS,
  GET_RISK_ANALYSIS_SUCCESS,
  GET_RISK_ANALYSIS_FAIL,
  CREATE_RISK_ANALYSIS,
} from './types';

const INITIAL_STATE = {
  loading_risk: false,
  erro_risk: false,
  erro_message_risk: null,
  loading_analysis: false,
  erro_analysis: false,
  error_analysis_message: null,
  analysis_type: '0',
  filtro: { status: 1, limit: 10, page: 1 },
  pagination: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESPONSE_RISK:
      return { ...state, ...action.payload };
    case GET_RISK_ANALYSIS:
      return {
        ...state,
        riskAnalysis: [],
        analysis_type: action.analysisType,
        loading_analysis: true,
        erro_analysis: false,
        error_analysis_message: null,
      };
    case GET_RISK_ANALYSIS_SUCCESS:
      return {
        ...state,
        riskAnalysis: action.payload,
        analysis_type: action.analysisType,
        pagination: action.pagination,
        loading_analysis: false,
        erro_analysis: false,
        error_analysis_message: null,
      };
    case GET_RISK_ANALYSIS_FAIL:
      return {
        ...state,
        riskAnalysis: action.payload,
        analysis_type: action.analysisType,
        loading_analysis: false,
        erro_analysis: true,
        error_analysis_message: action.error,
      };
    case CREATE_RISK_ANALYSIS:
      return {
        ...state,
        loading_risk: true,
        erro_risk: false,
        erro_message_risk: null,
      };
    case CREATE_RISK_ANALYSIS:
      return {
        ...state,
        loading_risk: false,
        erro_risk: false,
        erro_message_risk: null,
      };
    case CREATE_RISK_ANALYSIS:
      return {
        ...state,
        loading_risk: true,
        erro_risk: true,
        erro_message_risk: action.error,
      };
    default:
      return state;
  }
};
