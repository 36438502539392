import React from 'react';
import { Card, CardHeader, Row, FormGroup, Collapse, CardBody, Label, Input, Col } from 'reactstrap';
import { formatMoney } from '../../utils/formatMoneyBRL';
import Table from '../../components/Table/Table';

function selecionaCheckbox(selecionados, ID) {
  const encontra = selecionados.find((elemento) => elemento == ID);

  if (encontra == undefined) {
    selecionados.push(ID);
  } else {
    var posicao = selecionados.indexOf(encontra);
    selecionados.splice(posicao);
  }
}

const CardTableAntecipation = (props) => {
  const {
    MODOPAGAMENTO,
    VENDADIGITADA,
    DATA,
    PARCELAS,
    VALORPARCELADO,
    ID,
    VALORECEBER,
    VALORDESCONTADO,
    STATUS,
    TID,
  } = props.sale;

  //const arrayTable = props.sale

  var arrayTable = [];
  arrayTable.push(props.sale);

  const { toggle, isOpen, index, selecionados } = props;

  return (
    <Card>
      <Table
        headers={['', 'DATA', 'VENDA DIGITADA', 'FORMA DE PAGAMENTO']}
        data={arrayTable.map((a) => {
          return {
            data: [
              <Label check>
                <Input type="checkbox" onClick={() => selecionaCheckbox(selecionados, ID)} /> Antecipar esta venda
              </Label>,
              a.DATA,
              a.VENDADIGITADA,
              a.MODOPAGAMENTO,
            ],
          };
        })}
      />
    </Card>
    // <Card className='mb-2' >
    //     <CardHeader style={{ textAlign: 'left', padding: 0 }} >
    //         <Row xs="3" style={{ justifyContent: 'space-between', padding: '1.25rem 1.5rem' }}>
    //             <Col>
    //                 <FormGroup check style={{}}>
    //                     <Label check>
    //                         <Input type="checkbox" onClick={() => selecionaCheckbox(selecionados, ID)} />{' '}
    //                         Antecipar esta venda
    //                     </Label>

    //                 </FormGroup>

    /* <Table>
                            <thead>
                                <tr>

                                    <th>Venda digitada</th>
                                    <th>Data</th>
                           
                                    <th>Código da transação</th>
                                    <th>A receber</th>
                                    <th>Taxas</th>
                                    <th>Status</th>
                                    <th>TID</th>
                                
                                </tr>

                            </thead>
                            <tbody>
                                <tr>

                                    <td>{VENDADIGITADA}</td>
                                    <td>{DATA}</td>
                                  
                                    <td>{DATA}</td>
                                    <td>{DATA}</td>
                                    <td>{DATA}</td>
                                    <td>{DATA}</td>
                                    <td>{DATA}</td>
                                </tr>

                            </tbody>

                        </Table> */
    //         </Col>

    //         <Col className="text-right" onClick={toggle} style={{ cursor: 'pointer' }}>
    //             <span> VENDA {index + 1}  </span>
    //         </Col>

    //     </Row>

    // </CardHeader>

    /* <Collapse isOpen={isOpen} >
                <CardBody>
                    <Table striped>
                        <tbody>
                            <tr>
                                <td>Método de Pagamento</td><td>{MODOPAGAMENTO}</td>
                            </tr>
                            <tr>
                                <td>Venda Digitada</td><td>R$ {formatMoney(VENDADIGITADA)}</td>
                            </tr>
                            <tr>
                                <td>Data</td><td>{DATA}</td></tr>
                            <tr>
                                <td>Forma de Pagamento</td><td>{`${PARCELAS} X R$ ${formatMoney(VALORPARCELADO)}`}</td>
                            </tr>
                            <tr>
                                <td>Código da Transação</td><td>{ID}</td>
                            </tr>
                            <tr>
                                <td>A Receber</td><td>R$ {formatMoney(VALORECEBER)}</td>
                            </tr>
                            <tr>
                                <td>Taxas</td><td>R$ {formatMoney(VALORDESCONTADO)}</td>
                            </tr>
                            <tr>
                                <td>Status</td><td>{STATUS}</td>
                            </tr>
                            <tr>
                                <td>TID</td><td>{TID}</td>
                            </tr>
                        </tbody>
                    </Table>
                </CardBody>
            </Collapse> */
    //</Card>
  );
};

const styles = {
  StyleBorder: { border: 'none', boxShadow: 'none' },
};

export default CardTableAntecipation;
