import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatCNPJ, formatCPF, valueEmpty } from '../../utils';

// reactstrap components
import { Row } from 'reactstrap';
import CustomContainer from '../../components/Custom/CustomContainer.jsx';
import CustomDataTable from '../../components/DataTable/DataTable.jsx';
import FilterWithdrawals from '../../components/Filter/FilterWithdrawals';
import StatusContainerTed from '../../components/StatusContainer/StatusContainerTed.js';
import StatusContainerValue from '../../components/StatusContainer/StatusContainerValue.js';
import api from '../../service/api.js';
import { addSTATE } from '../../store/globals.js';
import {
  getTransactionsType,
  getWithdrawalTransactions
} from '../../store/transactions/actions.js';
import { getCookie, setCookie } from '../../utils/cookies.js';
import { removeSpecialCharacters, valueSort } from '../../utils/functions.js';
import isCpf from '../../utils/isCpf.js';
import ModalInfoWithdrawals from './ModalInfoTransfer.jsx';

export default function Withdrawals() {
  const dispatch = useDispatch();
  const [modal, setModal] = useState({ open: false, id: '' });
  const [saleInfos, setSaleInfos] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(false);
  const [withDraws, setWithDraws] = useState(false);
  const [filter, setFilter] = useState({
    limit: getCookie('limit') ? getCookie('limit') : 10,
    page: 1,
    search: getCookie('search') ? getCookie('search') : '',
    status: getCookie('status') ? getCookie('status') : 0,
    dataFinal:
      getCookie('dataFinal') && getCookie('dataFinal') != 'undefined'
        ? getCookie('dataFinal')
        : moment().format('YYYY-MM-DD'),
    dataInicial: getCookie('dataInicial') && getCookie('dataInicial') != 'undefined' ? getCookie('dataInicial') : '',
    tipoTransacao:
      getCookie('tipoTransacao') && getCookie('tipoTransacao') != 'undefined' ? getCookie('tipoTransacao') : '',
    idParceiro: getCookie('idParceiro') && getCookie('idParceiro') !== 'undefined' ? getCookie('idParceiro') : '',
    aprovacaoAuto: '',
  });

  function resetFilters() {
    setCookie('limit', 10, 1);
    setCookie('search', '', 1);
    setCookie('status', 0, 1);
    setCookie('dataFinal', '', 1);
    setCookie('dataInicial', '', 1);
    setCookie('tipoTransacao', '', 1);
    setCookie('idParceiro', '', 1);

    setFilter({
      limit: 10,
      page: 1,
      search: '',
      status: 0,
      dataFinal: '',
      dataInicial: '',
      idParceiro: '',
      tipoTransacao: '',
      aprovacaoAuto: '',
    });
  }

  const { filtro } = useSelector((state) => state.transactions);

  function getWithdrawals() {
    setLoading(true);
    api
      .post('transacao/listar/saque', { ...filter })
      .then((res) => {
        setWithDraws(res.data.RETORNO || []);
        setPagination(res.data.PAGINATION);
      })
      .finally(() => setLoading(false));
  }

  function getWithDraws() {

    setLoading(true);
    api
      .post('transacao/listar/saque', { ...filter })
      .then((res) => {
        setWithDraws(res.data.RETORNO || []);
        setPagination(res.data.PAGINATION);
      })
      .finally(() => setLoading(false));
  }

  function changeCookies() {
    setCookie('limit', filter.limit, 1440);
    setCookie('search', filter.search, 1440);
    setCookie('status', filter.status, 1440);
    setCookie('dataFinal', filter.dataFinal, 1440);
    setCookie('dataInicial', filter.dataInicial, 1440);
    setCookie('tipoTransacao', filter.tipoTransacao, 1440);
    setCookie('idParceiro', filter.idParceiro, 1440);
  }

  useEffect(() => {
    getWithdrawals();
    dispatch(getTransactionsType());
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    name === 'search'
      ? setFilter({ ...filter, [name]: removeSpecialCharacters(value) })
      : setFilter({ ...filter, [name]: value });
  };

  const filtrar = async () => {
    setFilter({ ...filter, page: 1 });

    dispatch(
      addSTATE('TRANSACTION_RESPONSE', '', {
        filtro: filter,
      }),
    );

    /*   dispatch(getWithdrawalTransactions({ ...filter })); */
    getWithdrawals();
    changeCookies();
  };

  const resetPage = () => {
    setFilter({...filter, page: 1});
    getWithDraws();
  }

  const paginationRender = async (type = 'atual', pageClick) => {
    let page;
    switch (type) {
      case 'prev':
        page = pagination.prevPage;
        break;
      case 'next':
        page = pagination.nextPage;
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }
    
    await setFilter({ ...filter, page });
    getWithDraws()
  };

  const headers = [
    {
      name: 'Data/Hora',
      style: {
        whiteSpace: 'normal',
        textOverflow: 'unset', // ou 'clip'
        textAlign: 'center',
      },
      selector: (row) => valueEmpty(row.DATA),

      cell: (row) => <div>{row.DATA}</div>,
      sortable: true,
      onClick: (row) => setModal({ open: true, id: row.ID }),
    },
    {
      name: 'STATUS',
      wrap: false,
      width: 'auto',

      style: {
        whiteSpace: 'nowrap',
      },

      header: <div>STATUS</div>,
      cell: (row) => (
        <div style={{ whiteSpace: 'nowrap' }} onClick={() => setModal({ open: true, id: row.ID })}>
          {valueEmpty(row.DESCRICAOSTATUS) ? (
            <StatusContainerTed code={row.STATUSTRANSACAO} description={row.DESCRICAOSTATUS} />
          ) : null}
        </div>
      ),
      onClick: (row) => setModal({ open: true, id: row.ID }),
      sortable: true,
    },
    {
      name: 'Parceiro',
      width: 'auto',
      style: {
        whiteSpace: 'nowrap',
      },

      sortable: true,
      cell: (row) =>
        row.TIPO_USUARIO ? (
          <div
            style={{
              style: {
                whiteSpace: 'nowrap',
              },
            }}
            onClick={() => setModal({ open: true, id: row.ID })}
          >
            <StatusContainerTed code={row.TIPO_USUARIO.toUpperCase()} description={row.TIPO_USUARIO} />
          </div>
        ) : (
          '-----'
        ),
    },
    {
      name: 'Valor',
      style: { textAlign: 'center' },
      sortable: true,
      sortFunction: valueSort,

      cell: (row) => (
        <div onClick={() => setModal({ open: true, id: row.ID })} style={{ whiteSpace: 'nowrap', width: 'auto' }}>
          {valueEmpty(row.VALOR) && <StatusContainerValue code={row.TIPO_TRANSACAO} description={row.VALOR} />}
        </div>
      ),
    },
    {
      name: 'CPF/CNPJ',
      width: '120px',
      selector: (row) =>
        isCpf(row.CPFCNPJ) ? formatCPF(valueEmpty(row.CPFCNPJ)) : formatCNPJ(valueEmpty(row.CPFCNPJ)),
      sortable: true,
    },
    {
      name: 'Nome',

      selector: (row) => valueEmpty(row.NOME),
      sortable: true,
      wrap: true,
    },
    {
      name: 'Banco',
      wrap: true,
      selector: (row) => valueEmpty(row.BANCO),
      sortable: true,
    },
    {
      name: 'Ag',
      style: { textAlign: 'center' },
      width: '70px',
      selector: (row) => valueEmpty(row.AGENCIA),
    },
    {
      name: 'Conta',

      selector: (row) => valueEmpty(row.CONTA),
    },
    {
      name: 'Tipo Pagamento',
      width: '160px',
      selector: (row) => valueEmpty(row.DESCRICAO),
      wrap: true,
    },
  ];

  const customStyles = {
    cells: {
      style: {
        cursor: 'pointer',
        padding: '.5rem',
        maxWidth: '300px',
      },
      headCells: {
        style: {
          display: 'flex',
          overflow: 'visible',
          justifyContent: 'center',
          textAlign: 'center',
        },
      },
    },
  };

  return (
    <div>
      <CustomContainer>
        <Row>
          <div className="col">
            <FilterWithdrawals
              filtrar={filtrar}
              handleChange={handleChange}
              setFilter={setFilter}
              filter={filter}
              resetFilters={resetFilters}
              identificador={'saque'}
              reset={(el) => resetPage(el)}
            />

            <CustomDataTable
              loading={loading}
              pagination={pagination}
              previousPage={{ onClick: () => paginationRender('prev') }}
              nowPage={{ page: filter.page, onClick: paginationRender }}
              nextPage={{ onClick: () => paginationRender('next') }}
              headers={headers}
              data={withDraws}
              action={(row) => setModal({ open: true, id: row.ID })}
              customStyles={customStyles}
            />
          </div>
        </Row>
        <ModalInfoWithdrawals
          filtrar={filtrar}
          toggle={() => setModal({ open: false, id: '' })}
          modal={modal.open}
          id_ted={modal.id}
          title="Dados da Transferência"
          callback={() => {
            setModal({open: false, id: ''});
            getWithdrawals();
          }}
        />
      </CustomContainer>
    </div>
  );
}