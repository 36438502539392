import styled from 'styled-components';

export const CardContainer = styled.div`
  width: 16rem;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  align-items: flex-start;
  flex-direction: column;
  border: ${(props) => (props.selected ? 'solid 3px #27B8E7' : '')};
  cursor: pointer;
`;

export const AccountInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

export const BalanceInfo = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  width: 100%;
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TrackingHeadContainer = styled.div`
  padding: 0.5rem 0;

  span {
    margin-bottom: 0.2rem;
  }
`;
