import React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loading from '../../../../../../components/Loader/Loading';
import { MainContainer } from './styles';

export default function ModalConfirm({ toggle, open, loading, callback, texto, errorBtn, textBtn }) {
  return (
    <>
      <Modal isOpen={open} toggle={toggle} size="lg" style={{ maxWidth: '600px' }}>
        <ModalHeader toggle={toggle}>
          <h2>Confirmar ação</h2>
        </ModalHeader>
        {!loading ? (
          <ModalBody>
            <MainContainer>{texto}</MainContainer>
          </ModalBody>
        ) : (
          <Loading color="blue" />
        )}
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancelar
          </Button>
          <Button color={errorBtn ? 'danger' : 'success'} onClick={() => callback()}>
            {textBtn ? textBtn : 'Confirmar'}
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </>
  );
}
