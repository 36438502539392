import { Alert, NativeSelect } from '@mui/material';
import React from 'react';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loading from '../../../components/Loader/Loading';
import {
  listarProdutosPorEmpresaByUUID,
  modificarStatus,
  removerProdutoEmpresa,
  vincularProdutoPorEmpresa,
} from '../../../store/coban/actions';

import { useEffect } from 'react';
import { listarProdutosCartaoVerificarMCCVinculacao } from '../../../store/swap/actions';
import NestedModalDeleteProduct from './NestedModalDeleteProduct';
import NestedModalLinkProduct from './NestedModalLinkProduct';

/* eslint-disable */
export default function ModalLinkProduct({ open, toggle, user, alertError, setAlertError }) {
  const [selectedProduct, setSelectedProduct] = useState('');
  const [modalDeleteProduct, setModalDeleteProduct] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [productName, setProductName] = useState('');
  const [product, setProduct] = useState(false);
  const { selectAddProduct } = useSelector((seg) => seg.swap);
  const { PRODUTOS_EMPRESA, loading_products_company } = useSelector((state) => state.coban);
  const [products, setProducts] = useState(false);

  const dispatch = useDispatch();

  function checkMcc(product) {
    if (product.length > 0) {
      dispatch(
        listarProdutosCartaoVerificarMCCVinculacao(user.CPFCNPJ, product, (res) => {
          if (res.retorno === 'erro') {
            setAlertError(res.mensagem);
          } else {
            setAlertError('');
          }
        }),
      );
    }
    if (product.length === 0) setAlertError('');
  }

  function handleClick() {
    dispatch(vincularProdutoPorEmpresa(user.UUID, selectedProduct, user.CPFCNPJ));
    /*     dispatch(adicionarConveniadaProdutoEmLote(user.CPFCNPJ, selectedProduct)); */
    setNestedModal(true);
  }

  useEffect(() => {
    setProducts(PRODUTOS_EMPRESA);
  }, [PRODUTOS_EMPRESA]);

  useEffect(() => {
    checkMcc(selectedProduct);
  }, [selectedProduct]);

  function closeModal() {
    dispatch(listarProdutosPorEmpresaByUUID(user.UUID));
    setNestedModal(false);
  }

  function toggleProduct(produto) {
    const response = products.map((item) => {
      if (produto.ID === item.ID) {
        if (produto.ATIVO === 'S') {
          item.ATIVO = 'N';
        } else {
          item.ATIVO = 'S';
        }
      }
      return item;
    });
    setProducts(response);
  }

  function handleActive(produto) {
    toggleProduct(produto);
    dispatch(modificarStatus(user.CPFCNPJ, produto.FK_PRODUTO));
  }

  function deleteProduct() {
    const response = products.filter((item) => item.ID !== product.ID);
    setProducts(response);
    dispatch(removerProdutoEmpresa(product.ID));
    setModalDeleteProduct(false);
  }

  /*   function handleDelete(produto) {
    setProduct(produto);
    setProductName(produto.DESCRICAO);
    setModalDeleteProduct(true);
  } */

  function handleChange(e) {

    setSelectedProduct(e);
  }



  return (
    <Modal className="modal-lg" isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>
        {alertError && alertError.length > 1 && <Alert severity="error">{alertError}</Alert>}
        <div className="choose-container">
          {' '}
          <NativeSelect
            onChange={(e) => handleChange(e.target.value)}
            name="lista"
            id="lista-conteudos"
            defaultValue={null}
            inputProps={{
              name: 'age',
              id: 'uncontrolled-native',
            }}
          >
            <option value={''}>Escolha produto</option>
            {selectAddProduct &&
              selectAddProduct.map((produto, index) => (
                <option key={index} value={produto.ID}>
                  {produto.DESCRICAO}
                </option>
              ))}
          </NativeSelect>
          <Button
            className="button-product-card"
            disabled={(selectedProduct === '' || user.CONVENIADA !== 'S' || (alertError && alertError.length)) && true}
            onClick={() => handleClick()}
          >
            Vincular
          </Button>{' '}
        </div>
        <h2 style={{ marginTop: '1rem' }}>Produtos Vinculados</h2>
        <div className="card-product-company-container">
          <div className="product-modal-body">
            {loading_products_company && <Loading color="blue" />}
            {user.CONVENIADA !== 'S' && <h2> Não é possivel víncular produtos a empresas não Conveniadas</h2>}
            {PRODUTOS_EMPRESA && PRODUTOS_EMPRESA.length === 0 && user.CONVENIADA === 'S' && (
              <h2> Não foram encontrados produtos</h2>
            )}
            {!loading_products_company &&
              products &&
              user.CONVENIADA === 'S' &&
              products.map((produto, index) => (
                <div key={index} className="card-product-company">
                  <div>
                    <div className="card-product-company-header">
                      <h2>{produto.DESCRICAO}</h2>
                    </div>
                  </div>
                  <div>
                    {/*  <div className="card-product-company-body"></div> */}
                    <div className="card-product-company-footer">
                      <div className="card-product-company-body">
                        <span className="active-span">{produto.ATIVO === 'S' ? 'Ativo' : 'Inativo'}</span>
                        <div class="form-check form-switch">
                          <label className="custom-toggle custom-toggle-card">
                            <input type="checkbox" checked={produto.ATIVO === 'S' ? true : false} />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              onClick={() => handleActive(produto)}
                            ></span>
                          </label>
                        </div>
                      </div>
                      {/*  <i class="fa fa-trash" aria-hidden="true" onClick={() => handleDelete(produto)}></i> */}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <NestedModalLinkProduct isOpen={nestedModal} toggle={() => closeModal()} />
        <NestedModalDeleteProduct
          isOpen={modalDeleteProduct}
          name={productName}
          toggle={() => setModalDeleteProduct(false)}
          deleteProduct={deleteProduct}
        />
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
}
