import styled from 'styled-components';

export const CardContainer = styled.div`
  width: 16rem;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  align-items: flex-start;
  flex-direction: column;
  border: ${(props) => (props.selected ? 'solid 3px #27B8E7' : '')};
  cursor: pointer;
`;

export const AccountInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

export const BalanceInfo = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  width: 100%;
`;

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const ModalCloser = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  padding: 1rem;
  z-index: 999;
  position: relative;
  background-color: transparent;

  button {
    outline: none;
    border: none;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.8rem;
    text-align: center;
    color: black;
    background-color: #ebe8f1;
    cursor: pointer;
  }
`;

export const PreviewContainer = styled.div`
  display: flex;
  background-color: #ffffff;
  min-height: 90vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 0 2rem 0;
`;

export const PreviewPreHeader = styled.div`
  border-radius: 1rem 1rem 0 0;
  width: 100%;
  height: 12%;
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  justify-content: space-between;
`;

export const PreviewButtonSendDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;
`;

export const PostButton = styled.button`
  border: none;
  outline: none;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: rgb(85, 108, 214);
  //box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  width: 217px;
  margin-top: 3rem;

  :hover {
    background-color: rgb(30, 50, 134);
  }
`;
