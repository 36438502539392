const formatCNPJ = (string) => {
  try {
    if (typeof string != 'string') {
      string = string + '';
    }
    const rawNumber = string.replace('/', '').replace('-', '').replace(/\./g, '');
    if (rawNumber.length !== 11) {
      const firstDigits = rawNumber.substr(0, 2);
      const secondDigits = rawNumber.substr(2, 3);
      const thirdDigits = rawNumber.substr(5, 3);
      const fourthDigits = rawNumber.substr(8, 4);
      const lastDigits = rawNumber.substr(12, 2);
      const maskedString =
        `${firstDigits}` +
        (secondDigits && `.${secondDigits}`) +
        (thirdDigits && `.${thirdDigits}`) +
        (fourthDigits && `/${fourthDigits}`) +
        (lastDigits && `-${lastDigits}`);
      return maskedString;
    } else {
      return '';
    }
  } catch (e) {
    return '';
  }
};

export default formatCNPJ;
