export const RESPONSE_RISK = 'RESPONSE_RISK';

//ANÁLISE DE RISCO
export const GET_RISK_ANALYSIS = 'GET_RISK_ANALYSIS';
export const GET_RISK_ANALYSIS_SUCCESS = 'GET_RISK_ANALYSIS_SUCCESS';
export const GET_RISK_ANALYSIS_FAIL = 'GET_RISK_ANALYSIS_FAIL';

export const CREATE_RISK_ANALYSIS = 'CREATE_RISK_ANALYSIS';
export const CREATE_RISK_ANALYSIS_SUCCESS = 'CREATE_RISK_ANALYSIS_SUCCESS';
export const CREATE_RISK_ANALYSIS_FAIL = 'GCREATE_RISK_ANALYSIS_FAIL';
