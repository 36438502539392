import { COLORS } from '../assets/EnvStyles';
import comparePasswords from './comparePasswords';
import isValidEmail from './isValidEmail';
import isValidPhoneNumber from './isValidPhoneNumber';

export const removeSpecialCharacters = (string) => {
  if (typeof string != 'string') return '';
  return string.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
};

export function detectar_mobile() {
  var check = false; //wrapper no check
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export function formatInfos(data, campos = [], labels = []) {
  var newValues = [];

  campos.map((campo, index) => {
    if (data[campo]) newValues.push({ name: labels[index] || campos, value: data[campo] });
  });

  return newValues;
}

export function colorStatus(status) {
  var color = '#525f7f';
  switch ((status || '').toString()) {
    case '1':
      color = COLORS.yellow;
      break;
    case '2':
      color = COLORS.green;
      break;
    case '3':
      color = COLORS.red;
      break;
    case '4':
      color = COLORS.darkRed;
      break;
  }

  return { color };
}

export function validateCoban(json) {
  const fields = ['CPFCNPJ', 'NOME', 'EMAIL', 'FONECEL', 'SENHA', 'CONFIRMSENHA', 'NIVEL_ACESSO'];

  let obj = {
    sucesso: true,
    mensagem: '',
  };

  for (let field of fields) {
    if (field == 'CPFCNPJ') {
      if (!json.CPFCNPJ) {
        return (obj = {
          sucesso: false,
          mensagem: 'Campo CPFCNPJ inválido!',
        });
      }
    }
    if (field == 'NOME') {
      if (!json.NOME)
        return (obj = {
          sucesso: false,
          mensagem: 'Por favor preencha o nome!',
        });
    }
    if (field === 'SENHA') {
      if (comparePasswords(json.SENHA, json.CONFIRMSENHA)) continue;
      else
        return (obj = {
          sucesso: false,
          mensagem: 'As senhas não coincidem ou é muito pequena. Senha com no mínimo 4 caracteres!',
        });
    }
    if (field === 'EMAIL') {
      if (isValidEmail(json.EMAIL)) {
        continue;
      } else
        return (obj = {
          sucesso: false,
          mensagem: 'E-mail inválido!',
        });
    }
    if (field === 'FONECEL') {
      if (isValidPhoneNumber(json.FONECEL)) {
        continue;
      } else
        return (obj = {
          sucesso: false,
          mensagem: 'Telefone inválido!',
        });
    }
    if (field == 'NIVEL_ACESSO') {
      if (!json.NIVEL_ACESSO) {
        return (obj = {
          sucesso: false,
          mensagem: 'Por favor selecione um nível de acesso!',
        });
      }
    }
  }

  return obj;
}

export function shortenString(string) {
  let shortString = string.length <= 10 ? string : string.substr(0, 7) + '...';
  return shortString;
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function getPercentage(num, withoutPercentage = false) {
  const value = num.replace(',', '.');
  if (withoutPercentage) return parseFloat(value);
  return parseFloat(value) + '%';
}

export function chooseColor(value) {
  if (value < 60) return 'red';
  if (value < 80) return 'orange';
  return 'green';
}

export function chooseColorString(str, val) {
  if (str.includes(val) === true) {
    return 'red';
  }
  return 'green';
}

export function chooseColorPld(str) {
  if (str === 'BAIXO') return 'green';
  if (str === 'MEDIO') return 'orange';
  if (str === 'ALTO') return 'red';
}

export const cpfCnpjMask = (valor) => {
  const valorLimpo = valor.replace(/[^\d]/g, '');
  const valorLimitado = valorLimpo.slice(0, 14);
  if (valorLimitado.length === 11) {
    return valorLimitado.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } else if (valorLimitado.length === 14) {
    return valorLimitado.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  } else {
    return valor;
  }
};

export const cpfMask = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const dateMask = (value) => {
  let str = value.split('-');

  return str[2] + '/' + str[1] + '/' + str[0];
};

export const moneyMask = (value) => {
  return parseFloat(value).toLocaleString('pt-BR', {
    currency: 'BRL',
    style: 'currency',
  });
};

export const valueSort = (rowA, rowB) => {
  let a;
  let b;
  if (rowA.TIPO_TRANSACAO === 'DÉBITO') {
    a = parseFloat(-rowA.VALOR).toFixed(2);
  } else {
    a = parseFloat(rowA.VALOR).toFixed(2);
  }

  if (rowB.TIPO_TRANSACAO === 'DÉBITO') {
    b = parseFloat(-rowB.VALOR);
  } else {
    b = parseFloat(rowB.VALOR);
  }

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const brazilianPhoneMask = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(^\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4,5})(\d{4}$)/, '$1-$2');
};

export const accontAndDigitMask = (numero) => {
  const numeroLimpo = numero.replace(/[^\d]/g, '');
  const numeroLimitado = numeroLimpo.slice(0, 21);
  if (numeroLimitado.length <= 4) {
    return numeroLimitado;
  }
  const conta = numeroLimitado.slice(0, -1);
  const digito = numeroLimitado.slice(-1);
  const primeiraParte = conta.slice(0, -1);
  const segundaParte = conta.slice(-1);
  return `${primeiraParte}${segundaParte}-${digito}`;
};
