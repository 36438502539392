import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 0.3rem;
`;

export const SaldoContainer = styled.div`
  display: flex;
`;

export const SaldoStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  font-size: 0.8rem;
  line-height: 112%;
  align-items: flex-end;
`;

export const SaldoColor = styled.div`
  font-weight: 600;
  color: #32325d;
  font-size: 1rem;
`;

export const SaldoUtilizadoColor = styled.div`
  color: #dc2d06;
  font-weight: 600;
  font-size: 1rem;
`;

export const SaldoDisponivelColor = styled.div`
  color: #1ba305;
  font-weight: 600;
  font-size: 1rem;
`;

export const ReloadButtonStyle = styled.button`
  border: none;
  background-color: white;
  color: black;
  cursor: pointer;
  :hover {
    color: #0092d4;
  }
`;

export const ButonContainer = styled.div`
  display: flex;
`;
