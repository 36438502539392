import React from 'react';
import * as Styled from '../styles/Filter.style';

const InputSelectAmountResults = ({ options, setValue, value, width, column }) => {
  return (
    <>
      {column ? (
        <Styled.InputSelectAmountResultsColumn>
          <Styled.InputSelectAmountResultsLabel size="sm" style={{ width: width }}>
            Resultados por página
          </Styled.InputSelectAmountResultsLabel>
          <Styled.InputSelectM
            name="limit"
            id="limit"
            type="select"
            size="sm"
            value={value}
            onChange={(event) => setValue(event.target.value)}
          >
            {options &&
              options.map((option, index) => {
                return <option key={index}>{option}</option>;
              })}
          </Styled.InputSelectM>
        </Styled.InputSelectAmountResultsColumn>
      ) : (
        <Styled.InputSelectAmountResults>
          <Styled.InputSelect
            name="limit"
            id="limit"
            type="select"
            size="sm"
            value={value}
            onChange={(event) => setValue(event.target.value)}
          >
            {options &&
              options.map((option, index) => {
                return <option key={index}>{option}</option>;
              })}
          </Styled.InputSelect>
          <Styled.InputSelectAmountResultsLabel size="sm" style={{ width: width }}>
            Resultados por página
          </Styled.InputSelectAmountResultsLabel>
        </Styled.InputSelectAmountResults>
      )}
    </>
  );
};

export default InputSelectAmountResults;
