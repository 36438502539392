import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="none" viewBox="0 0 26 26">
      <path
        fill="#4ECB71"
        d="M13 0C5.8 0 0 5.8 0 13s5.8 13 13 13 13-5.8 13-13c0-1.4-.188-2.794-.688-4.094L23.688 10.5c.2.8.313 1.6.313 2.5 0 6.1-4.9 11-11 11S2 19.1 2 13 6.9 2 13 2c3 0 5.694 1.194 7.594 3.094L22 3.688C19.7 1.388 16.5 0 13 0zm11.28 4.28L13 15.563l-4.28-4.28-1.44 1.437 5 5 .72.686.72-.687 12-12-1.44-1.44v.001z"
      ></path>
    </svg>
  );
}

export default Icon;
