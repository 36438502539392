import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './TextInput.styles';
import { Controller } from 'react-hook-form';

export default function TextInput({
  label,
  name,
  width,
  height,
  variant = 'standard',
  multiline,
  control,
  margin,
  type,
  inputProps,
  maxRows,
  minRows,
  required = false,
  onKeyPress,
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <Styled.Text
          {...field}
          type={type ? type : 'text'}
          label={label}
          width={width}
          height={height}
          variant={variant}
          multiline={multiline}
          maxRows={maxRows}
          minRows={minRows}
          // onChange={onChange}
          required={required}
          margin={margin}
          inputProps={inputProps}
          onKeyPress={onKeyPress}
        />
      )}
    ></Controller>
  );
}

TextInput.propTypes = {
  setFilter: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  filter: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  variant: PropTypes.string,
  multiline: PropTypes.bool,
  control: PropTypes.any,
  margin: PropTypes.string,
  type: PropTypes.string,
  inputProps: PropTypes.object,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};
