import React from 'react';

// reactstrap components
import { CardFooter, Pagination, PaginationItem, PaginationLink, Table, Input, Col, Row, Label } from 'reactstrap';
import { Spring } from 'react-spring/renderprops';

class CustomTable extends React.Component {
  state = { filtro: { limit: 10, search: '' } };
  render() {
    const { headers, data, previousPage, nowPage, nextPage, pagination } = this.props;
    return (
      <>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>{headers && headers.length ? headers.map((header) => <th scope="col">{header}</th>) : null}</tr>
          </thead>
          <tbody>
            {data && data.length
              ? data.map((row) =>
                  row.length ? (
                    <tr className="table-tr">{row && row.length ? row.map((collum) => <td>{collum}</td>) : null}</tr>
                  ) : (
                    <tr
                      className="table-tr"
                      onClick={() => (row.onClick ? row.onClick(row.data) : null)}
                      style={{ cursor: row.onClick ? 'pointer' : 'unset' }}
                    >
                      {row.data && row.data.length ? row.data.map((collum) => <td>{collum}</td>) : null}
                    </tr>
                  ),
                )
              : null}
          </tbody>
        </Table>
        {previousPage || nextPage ? (
          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
                style={{ flexWrap: 'wrap' }}
              >
                {nowPage.page != 1 ? (
                  <PaginationItem>
                    <PaginationLink onClick={(e) => previousPage.onClick()} tabIndex="-1">
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Anterior</span>
                    </PaginationLink>
                  </PaginationItem>
                ) : null}

                {nowPage ? (
                  <PaginationItem>
                    <PaginationLink onClick={(e) => nowPage.onClick()}>{nowPage.page}</PaginationLink>
                  </PaginationItem>
                ) : null}

                {nowPage.page != pagination.totalPages && pagination.totalPages ? (
                  <PaginationItem>
                    <PaginationLink onClick={(e) => nextPage.onClick()}>
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Próximo</span>
                    </PaginationLink>
                  </PaginationItem>
                ) : null}
              </Pagination>
            </nav>
          </CardFooter>
        ) : null}
      </>
    );
  }
}

const styles = {
  form: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'baseline',
  },
};
export default CustomTable;
