import { useTimer } from 'react-timer-hook';
import React, { useState, useEffect } from 'react';
import Modal from '../Modal/Modal';
import Button from '../Buttons/Button';
import RenovacaoJwt from './RenovacaoJwt';

const Timer = ({ expiryTimestamp }) => {
  const [logout, setLogout] = useState(false);
  const [renovation, setRenovation] = useState(false);
  const [trueSeconds, setTrueSeconds] = useState(0);
  const [trueMinutes, setTrueMinutes] = useState(0);
  const [trueHrs, setTrueHrs] = useState(0);

  const timeRefreshToken = process.env.REACT_APP_TIMER_REFRESH_TOKEN;
  let splitTimeRefreshToken = [];
  if (timeRefreshToken) {
    splitTimeRefreshToken = timeRefreshToken.split('-');
  } else {
    splitTimeRefreshToken = ['00', '00', '59'];
  }
  // Options useTimer
  // seconds, minutes, hours, days, isRunning, start, pause, resume, restart
  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      setRenovation(false);
      setLogout(true);
    },
  });

  useEffect(() => (seconds < 10 ? setTrueSeconds('0' + seconds) : setTrueSeconds(seconds)), [seconds]);

  useEffect(() => {
    hours == 0 && minutes == 0 && seconds == 59 && setRenovation(true);

    minutes < 10 ? setTrueMinutes('0' + minutes) : setTrueMinutes(minutes);
    hours < 10 ? setTrueHrs('0' + hours) : setTrueHrs(hours);
  }, [minutes]);

  return (
    <div style={{ textAlign: 'center' }}>
      <span style={{ color: 'white' }}>{trueHrs + ':' + trueMinutes + ':' + trueSeconds}</span>
      <RenovacaoJwt open={renovation} close={() => setRenovation(false)} setView={() => setRenovation(true)} />
      <Modal open={logout} noClose={true} close={(e) => setLogout(true)}>
        <>
          <div style={{ padding: '1rem' }}>
            <h4>{'SUA SESSÃO FOI EXPIRADA'}</h4>
          </div>
          <div style={{ padding: '1rem' }}>
            <Button
              backgroundColor={'rgba(204, 33, 10, 1)'}
              width={'13.792vw'}
              height={'3.125vw'}
              hoverBGColor={'rgba(204, 33, 10, 1)'}
              hoverColor={'#F8F8F8'}
              color={'#F8F8F8'}
              onClick={() => {
                window.location.replace('/login');
                localStorage.clear();
              }}
            >
              <span>OK</span>
            </Button>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default Timer;
