import React from 'react';
import { chooseColorPld } from '../../../../utils/functions';
import './styles.css';

function RiskPLD({ risco }) {
  return (
    <>
      <div className="risk-container">
        <div className="risk-badge">
          <h3>Produto</h3>
          <span>{risco.channel}</span>
          <div className="score-pld">
            <p>{risco.channelPoints}</p>
          </div>
          <h3 style={{ marginTop: '1rem' }}>SCORE</h3>
        </div>
        <div className="risk-badge">
          <h3>C. Distribuição</h3>
          <span>{risco.product}</span>
          <div className="score-pld">
            <p>{risco.productPoints}</p>
          </div>
          <h3 style={{ marginTop: '1rem' }}>SCORE</h3>
        </div>
        {risco.PEP && (
          <div className="risk-badge">
            <h3>Perfil co cliente</h3>
            <span>PEP</span>
            <div className="score-pld">
              <p>{risco.pepPoints}</p>
            </div>
            <h3 style={{ marginTop: '1rem' }}>SCORE</h3>
          </div>
        )}

        <div className="risk-badge">
          <h3>Risco PLD</h3>
          <span>{risco.riscoPld}</span>
          <div className="score-pld">
            <p className={chooseColorPld(risco.riscoPld)}>{risco.total}</p>
          </div>
          <h3 style={{ marginTop: '1rem' }}>SCORE</h3>
        </div>
      </div>
    </>
  );
}

export default RiskPLD;
