import React from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

class AlertModal extends React.Component {
  render() {
    const { alertModal, toggle, mensagem } = this.props;

    return (
      <Modal
        // className={(color ? "modal-"+color : '') + " modal-dialog-centered"}
        // contentClassName={color ? "bg-gradient-"+color : 'default'}
        isOpen={alertModal}
        toggle={toggle}
      >
        <ModalHeader>Alerta</ModalHeader>
        <ModalBody>{mensagem}</ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={toggle} block>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AlertModal;
