import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../Table/Table';
import { listarTransacaoDia } from '../../store/accounting/action';
import formatMoney from '../../utils/formatMoneyBRL';

export default function MovimentacaoDiaTable() {
  const dispatch = useDispatch();
  const {
    movimentacaoDia: { listMovimentacaoDia, pagination },
  } = useSelector((state) => state.accounting);

  let controle = false;

  const fetchMoreData = () => {
    const flagDispatch = false;
    const filtro = { limit: 20, page: pagination.nextPage };
    dispatch(listarTransacaoDia(filtro, flagDispatch));
  };

  if (pagination.totalResult <= listMovimentacaoDia.length) {
    controle = false;
  }

  return (
    <Card sx={{ maxWidth: '50vw', height: '100%' }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mb={2}>
          <Typography fontSize={14}>Movimentações do dia</Typography>
          <Typography fontSize={14}>Quantidade: {listMovimentacaoDia && listMovimentacaoDia.length}</Typography>
        </Box>
        <Box>
          <InfiniteScroll
            dataLength={listMovimentacaoDia.length}
            next={() => fetchMoreData()}
            hasMore={controle}
            height='50vh'
            loader={<h4>loading...</h4>}
          >
            <Table
              headers={[
                {
                  info: 'Hora',
                  style: { textAlign: 'center' },
                },
                {
                  info: 'Operação',
                  style: { textAlign: 'left' },
                },
                {
                  info: 'Valor',
                  style: { textAlign: 'right' },
                },
                {
                  info: 'Status',
                  style: { textAlign: 'center' },
                },
              ]}
              data={listMovimentacaoDia.map((movimentacao) => {
                return {
                  data: [
                    {
                      info: movimentacao.HORA,
                      style: { textAlign: 'center' },
                    },
                    {
                      info: movimentacao.OPERACAO,
                      style: { textAlign: 'left' },
                    },
                    {
                      info: 'R$ ' + formatMoney(movimentacao.VALOR),
                      style: { textAlign: 'right' },
                    },
                    {
                      info:
                        movimentacao.STATUS == 2 ? (
                          <i class="fa fa-arrow-up" style={{ color: '#29ff54' }}></i>
                        ) : (
                          <i class="fa fa-arrow-down" style={{ color: 'red' }}></i>
                        ),
                      style: { textAlign: 'center' },
                    },
                  ],
                };
              })}
            />
            {listMovimentacaoDia.length == 0 ? (
              <h3 style={{ textAlign: 'center' }}>Nenhuma operação registrada</h3>
            ) : null}
          </InfiniteScroll>
        </Box>
      </CardContent>
    </Card>
  );
}
