import React from 'react';
import { Input, Label } from 'reactstrap';
import formatTextCamelCase from '../../../utils/formatTextCamelCase';

const InputStatus = ({ pendingUsers, setValue, options, codeValue, width, inputName = '', value, disabled = false }) => {
  return (
    <div>
      <Label size="sm">{inputName ? inputName : 'Status'}</Label>
      <Input
        onChange={(event) => setValue(event.target.value)}
        type="select"
        name="status"
        id="status"
        size="sm"
        style={width && { width }}
        value={value}
        disabled={disabled}
      >
        {options &&
          options.map((option, index) => {
            return (
              <option
                value={codeValue[index]}
                selected={pendingUsers && pendingUsers[1] && option === 'Aguardando' ? true : false}
              >
                {formatTextCamelCase(option)}
              </option>
            );
          })}
      </Input>
    </div>
  );
};

export default InputStatus;
