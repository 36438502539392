import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Label, FormGroup, Form } from 'reactstrap';
import { getTransactionsType, getTotalReport, getReportsExcel } from '../../../store/transactions/actions.js';
import AlertaCustomizado from '../../Alertas/AlertaCustomizado.jsx';
import formatTextCamelCase from '../../../utils/formatTextCamelCase.js';
import { SearchInput, DefaultInput, DefaultLabel, MaskInputMoney } from '../styles/FilterReport.styles.js';
import GenericButton from '../../Buttons/Button.js';
import Icon from '../../Icons/IconsButton.js';
import { Controller, useForm } from 'react-hook-form';
import { ColContainer, RowContainer } from './FilterReport.style.js';
import formatMoney from '../../../utils/formatMoneyBRL.js';

const FilterReport = () => {
  const dispatch = useDispatch();

  const {
    getValues,
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
  } = useForm();
  const { tiposTransacoes, modoPagamento, parceiro, relatorio_parametrizado: { loading } } = useSelector((state) => state.transactions);

  function currencyFormatter(value) {
    if (!Number(value)) return '';
    const amount = new Intl.NumberFormat('pt-br', {
      style: 'currency',
      currency: 'BRL',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(value / 100);

    return `${amount}`;
  }

  function cleanCurrencyValues (value) {
    return +value.substr(3).replaceAll('.', '').replace(',', '.');
  }

  function handleGetReport(data) {
    dispatch(
      getTotalReport({
        ...data,
        valorMin: data.valorMin ? cleanCurrencyValues(data.valorMin) : undefined,
        valorMax: data.valorMax ? cleanCurrencyValues(data.valorMax) : undefined,
        page: 1,
      }),
    );
  }

  return (
    <Form className="form" onSubmit={handleSubmit(handleGetReport)}>
      <FormGroup className="mt-3">
        {errors.valorMin && errors.valorMin.message.length > 0 && (
          <AlertaCustomizado
            isOpen={errors.valorMin && errors.valorMin.message.length > 0}
            toggle={() => clearErrors('valorMin')}
            mensagem={errors.valorMin && errors.valorMin.message}
          />
        )}
        <>
          <RowContainer>
            <ColContainer>
              <DefaultLabel>Pesquisar</DefaultLabel>
              <Controller
                control={control}
                name="CPFCNPJ_CLIENT"
                render={({ field: { ref, ...field } }) => (
                  <SearchInput {...field} type="text" placeholder="Nome ou CPF/CNPJ" />
                )}
              />
            </ColContainer>
            <ColContainer>
              <DefaultLabel>Data Inicial</DefaultLabel>
              <Controller
                control={control}
                name="DATAINICIAL"
                render={({ field: { ref, ...field } }) => (
                  <DefaultInput {...field} type="date" max="9999-12-31" size="sm" />
                )}
              />
            </ColContainer>
            <ColContainer>
              <DefaultLabel>Data Final</DefaultLabel>
              <Controller
                control={control}
                name="DATAFINAL"
                render={({ field: { ref, ...field } }) => (
                  <DefaultInput {...field} name="DATAFINAL" type="date" max="9999-12-31" size="sm" />
                )}
              />
            </ColContainer>

            <ColContainer>
              <DefaultLabel>Tipo transação</DefaultLabel>
              <Controller
                control={control}
                name="tipoTransacao"
                render={({ field: { ref, ...field } }) => (
                  <Input {...field} type="select" size="sm" style={{ width: '12vw' }}>
                    <option value={undefined}>Todas</option>
                    {tiposTransacoes &&
                      tiposTransacoes.map((transaction, index) => (
                        <option key={index} value={transaction.CODTIPO}>
                          {formatTextCamelCase(transaction.NOMECLATURA)}
                        </option>
                      ))}
                  </Input>
                )}
              />
            </ColContainer>
            <ColContainer>
              <DefaultLabel style={{ width: '10vw' }}>Tipo modo de pagamento</DefaultLabel>
              <Controller
                control={control}
                name="modoPagamento"
                render={({ field: { ref, ...field } }) => (
                  <Input {...field} type="select" size="sm" style={{ width: '12vw' }}>
                    <option value={undefined}>Todas</option>
                    {modoPagamento &&
                      modoPagamento.map((transaction, index) => (
                        <option key={index} value={transaction.CODTIPO}>
                          {formatTextCamelCase(transaction.NOME_EXIBICAO)}
                        </option>
                      ))}
                  </Input>
                )}
              />
            </ColContainer>
            <ColContainer>
              <DefaultLabel>Valor mínimo (R$)</DefaultLabel>
              <Controller
                control={control}
                name="valorMin"
                rules={{
                  validate: (value, formValues) =>
                    value && formValues.valorMax
                      ? value < formValues.valorMax || 'O valor mínimo deve ser menor do que o valor máximo!'
                      : true,
                }}
                render={({ field: { ref, ...field } }) => (
                  <MaskInputMoney
                    {...field}
                    decimalSeparator=","
                    thousandsSeparator="."
                    fixedDecimalScale={true}
                    format={currencyFormatter}
                  />
                )}
              />
            </ColContainer>
            <ColContainer>
              <DefaultLabel size="sm">Valor máximo (R$)</DefaultLabel>
              <Controller
                control={control}
                name="valorMax"
                render={({ field: { ref, ...field } }) => (
                  <MaskInputMoney
                    {...field}
                    decimalSeparator=","
                    thousandsSeparator="."
                    fixedDecimalScale={true}
                    format={currencyFormatter}
                  />
                )}
              />
            </ColContainer>
            <ColContainer>
              <DefaultLabel>Parceiro</DefaultLabel>
              <Controller
                control={control}
                name="tipoUsuario"
                render={({ field: { ref, ...field } }) => (
                  <Input type="select" size="sm" style={{ width: '12vw' }}>
                    <option value={undefined}>Todos</option>
                    {parceiro &&
                      parceiro.map((transaction, index) => (
                        <option key={index} value={transaction.ID}>
                          {transaction.DESCRICAO}
                        </option>
                      ))}
                  </Input>
                )}
              />
            </ColContainer>

            <ColContainer>
              <DefaultLabel>Status</DefaultLabel>
              <Controller
                name="STATUS"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Input {...field} type="select" size="sm" style={{ width: '12vw' }}>
                    <option value={undefined}>Todas</option>
                    <option value={1}>Aguardando</option>
                    <option value={2}>Aprovado</option>
                    <option value={3}>Cancelado</option>
                    <option value={4}>Vencido</option>
                  </Input>
                )}
              />
            </ColContainer>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <GenericButton
                disabled={loading}
                marginTop={'25px !important'}
                width={'140px'}
                heigth={'35px'}
                type="submit"
              >
                <Icon className="fas fa-search" />
                Buscar
              </GenericButton>
              <GenericButton
                disabled={loading}
                onClick={() => {
                  dispatch(getReportsExcel(getValues()));
                }}
                marginTop={'25px !important'}
                backgroundColor={'#ffffff'}
                borderColor={'#2405F2'}
                width={'140px'}
                heigth={'35px'}
                marginLeft={'10px'}
                color={'#2405F2'}
              >
                <Icon className="fas fa-file-export" />
                Exportar
              </GenericButton>
            </div>
          </RowContainer>
          <div style={{ display: 'flex' }}>
            <Label size="sm">Resultados por página</Label>
            <Controller
              name="limit"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Input {...field} type="select" style={{ maxWidth: 80, marginLeft: 10 }} size="sm">
                  <option>10</option>
                  <option>100</option>
                  <option>250</option>
                  <option>500</option>
                  <option>1000</option>
                  <option>5000</option>
                  <option>10000</option>
                </Input>
              )}
            />
          </div>
        </>
      </FormGroup>
    </Form>
  );
};

export default FilterReport;
