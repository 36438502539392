import { Box, Chip, Typography } from '@material-ui/core';
import { Reply } from '@mui/icons-material';
import { OutlinedInput, Select, TextareaAutosize } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

function ModalVisualizarReprovacao({ open, toggle, handleModalBaasAccount, details }) {
  const { user, detalheUserNegado } = useSelector((state) => state.user);

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader>
        Detalhes da Negação{' '}
        {user && +user.ID_TIPO_USUARIO !== 1 && (
          <Reply style={{ cursor: 'pointer' }} onClick={handleModalBaasAccount} />
        )}
      </ModalHeader>
      <ModalBody>
        {details && (
          <Box>
            {details.USUARIO && (
              <Box>
                {details.USUARIO.INFORMACOES_PESSOAIS && details.USUARIO.DOCS && (
                  <Box>
                    <Typography variant="h5">Necessário de:</Typography>
                    <Box mb={1}>
                      <Typography>Informações Pessoais</Typography>
                      <Select
                        multiple
                        readOnly
                        value={
                          details.USUARIO.INFORMACOES_PESSOAIS &&
                          details.USUARIO.INFORMACOES_PESSOAIS.filter((info) => +info.TIPO_REPROVACAO.ID === 1)
                        }
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value, index) => (
                              <Chip key={index} label={value.INFORMACOES_PESSOAIS.DESCRICAO} />
                            ))}
                          </Box>
                        )}
                      />
                    </Box>
                    <Box mb={1} mt={1}>
                      <Typography>Documentos</Typography>
                      <Select
                        multiple
                        readOnly
                        value={
                          details.USUARIO.DOCS && details.USUARIO.DOCS.filter((info) => +info.TIPO_REPROVACAO.ID === 1)
                        }
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value, index) => (
                              <Chip key={index} label={value.DOCS.DESCRICAO} />
                            ))}
                          </Box>
                        )}
                      />
                    </Box>
                  </Box>
                )}
                {details.USUARIO.INFORMACOES_PESSOAIS && details.USUARIO.DOCS && (
                  <Box>
                    <Typography variant="h5">Errado em:</Typography>
                    <Box mb={1} mt={1}>
                      <Typography>Informações Pessoais</Typography>
                      <Select
                        multiple
                        readOnly
                        value={
                          details.USUARIO.INFORMACOES_PESSOAIS &&
                          details.USUARIO.INFORMACOES_PESSOAIS.filter((info) => +info.TIPO_REPROVACAO.ID === 2)
                        }
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value, index) => (
                              <Chip key={index} label={value.INFORMACOES_PESSOAIS.DESCRICAO} />
                            ))}
                          </Box>
                        )}
                      />
                    </Box>
                    <Box mb={1} mt={1}>
                      <Typography>Documentos</Typography>
                      <Select
                        multiple
                        readOnly
                        value={
                          details.USUARIO.DOCS && details.USUARIO.DOCS.filter((info) => +info.TIPO_REPROVACAO.ID === 2)
                        }
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value, index) => (
                              <Chip key={index} label={value.DOCS.DESCRICAO} />
                            ))}
                          </Box>
                        )}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            )}
            <Box>
              <Typography variant="h5">Observação</Typography>
              <TextareaAutosize style={{ width: '100%' }} minRows={3} readOnly defaultValue={details.MOTIVO} />
            </Box>
          </Box>
        )}
      </ModalBody>
    </Modal>
  );
}

export default ModalVisualizarReprovacao;
