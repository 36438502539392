import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { Button, ButtonGroup, Card, CardBody, Col, Row } from 'reactstrap';
import moment from 'moment';
import { useEffect } from 'react';
import api from '../../../../service/api';
import Loading from '../../../../components/Loader/Loading';
import formatMoney from '../../../../utils/formatMoneyBRL';
import ResponsiveDialog from '../../../../components/Modais/Confirmar/ModalConfirmar';

import ModalMensagemSemReloadNew from '../../../Transaction/ModalMensagemSemReloadNew';
import ModalSaldoContaCartao from '../../../../components/Modais/Cliente/ModalSaldoContaCartao';
import { ReloadButtonStyle } from '../DigitalAccountBalaceContainer/style';
import { FiRefreshCw } from 'react-icons/fi';
import { useSelector } from 'react-redux';

export default function AccordionInfoCardAccount({
  informacoesPessoais,
  setReload,
  reload,
  getContaCartao,
  cardAccount,
  setCardAccount,
  loadingAccount,
  setLoadingAccount,
}) {
  const [expanded, setExpanded] = useState(true);

  const [createAccountModal, setCreateAccountModal] = useState(false);
  const [modalMensagem, setModalMensagem] = useState(false);
  const [mensagem, setMensagem] = useState(false);
  const [modalSaldo, setModalSaldo] = useState(false);
  const [modalMensagemRecarga, setModalMensagemRecarga] = useState(false);
  const [mensagemRecarga, setMensagemRecarga] = useState(false);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    getContaCartao();
  }, [informacoesPessoais]);

  function createCardAccount() {
    setCreateAccountModal(false);
    const data = {
      UUID: user.UUID,
    };

    setLoadingAccount(true);
    api
      .post('/admin/conta-cartao/gerar', data)
      .then(({ data }) => {
        setCreateAccountModal(false);
        if (data.retorno && data.retorno === 'sucesso') {
          setMensagem('Conta criada com sucesso!');
          setModalMensagem(true);
        } else {
          setModalMensagem(true);
        }
        setLoadingAccount(false);
      })

      .catch(() => {
        setModalMensagem(true);
        setLoadingAccount(false);
      });
  }

  function closeModalMensagem() {
    setModalMensagem(false);
    setMensagem(false);
    getContaCartao();
  }

  function closeModalMensagemRecarga() {
    setModalMensagemRecarga(false);
    setMensagemRecarga(false);
    getContaCartao();
  }

  /* useEffect(() => {}); */

  return (
    <Accordion expanded={expanded} /* onChange={() => setExpanded(!expanded)} */>
      <AccordionSummary>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
            Informações
          </Typography>

          <ButtonGroup size="sm" style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
            {!loadingAccount && cardAccount.length == 0 && (
              <Button
                color="primary"
                style={{ borderRadius: '5px' }}
                onClick={() => {
                  setCreateAccountModal(true);
                }}
              >
                Criar conta cartão
              </Button>
            )}

            <Button
              color="primary"
              style={{ borderRadius: '5px' }}
              onClick={() => {
                setModalSaldo(true);
              }}
            >
              Operar Saldo Cliente
            </Button>
          </ButtonGroup>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!loadingAccount ? (
          cardAccount && cardAccount.length > 0 ? (
            cardAccount.map((account) => (
              <>
                <Card>
                  <CardBody>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ width: '100%' }}>
                        <Row>
                          <Col xs={5}>
                            <Box mb={2} sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography sx={styles.textForm}>
                                <b>Conta: </b>{' '}
                              </Typography>
                              <Typography>{account.conta}</Typography>
                            </Box>
                          </Col>

                          <Col xs={5}>
                            <Box mb={2} sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography sx={styles.textForm}>
                                <b>Saldo:</b>
                              </Typography>
                              <Typography>
                                <b>R$</b>
                                {formatMoney(account.saldo)}
                                {/*    {informacoesPessoais.data_abertura
                            ? moment(informacoesPessoais.data_abertura).format('DD/MM/YYYY')
                            : '-----'} */}
                              </Typography>
                            </Box>
                          </Col>
                          {/*   <Col xs={5}>
                      <Box mb={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={styles.textForm}>
                          <b>Data de Encerramento:</b>
                        </Typography>
                        <Typography>
                          {informacoesPessoais.data_encerramento
                            ? moment(informacoesPessoais.data_encerramento).format('DD/MM/YYYY')
                            : '-----'}
                        </Typography>
                      </Box>
                    </Col> */}
                        </Row>
                      </div>
                      <div>
                        <ReloadButtonStyle onClick={() => getContaCartao()}>
                          <FiRefreshCw />
                        </ReloadButtonStyle>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </>
            ))
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography sx={styles.textForm}>
                <b>Conta não encontrada </b>{' '}
              </Typography>
            </div>
          )
        ) : (
          <Loading color="blue" />
        )}
      </AccordionDetails>
      <ResponsiveDialog
        open={createAccountModal}
        title={'Criar conta cartão?'}
        buttonTexts={[
          { name: 'confirmar', color: '#2DCE89' },
          { name: 'Cancelar', color: '#F5365C' },
        ]}
        callFunction={() => createCardAccount()}
        close={() => setCreateAccountModal(false)}
      />
      <ModalMensagemSemReloadNew
        open={modalMensagem}
        close={() => closeModalMensagem()}
        callback={() => closeModalMensagem()}
        sucesso={mensagem && true}
        mensagem={mensagem}
        title={mensagem && 'Sucesso'}
      />

      <ModalMensagemSemReloadNew
        open={modalMensagemRecarga}
        close={() => closeModalMensagemRecarga()}
        callback={() => closeModalMensagemRecarga()}
        sucesso={mensagemRecarga && true}
        mensagem={mensagemRecarga}
        title={mensagemRecarga && 'Sucesso'}
      />

      <ModalSaldoContaCartao
        toggle={() => {
          setModalSaldo(false);
        }}
        modal={modalSaldo}
        dadoCPFCNPJ={informacoesPessoais.cpf_cnpj}
        cardAccount={cardAccount}
        setReload={setReload}
        reload={reload}
        setModalMensagemRecarga={setModalMensagemRecarga}
        setMensagemRecarga={setMensagemRecarga}
      />
    </Accordion>
  );
}

const styles = {
  textForm: {
    fontSize: '0.9em',
    color: '#8898aa',
    marginRight: '10px',
    marginBottom: '0',
  },
};
