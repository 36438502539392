import styled from 'styled-components'

export const ButtonToggle = styled.button`
transition: all 1s ease ;
background: #0092D4;
width: 1.5rem;
height: 1.5rem;
display: flex;
justify-content: center;
align-items: center;
color: white;
z-index: 999;
left: ${props => props.drawerToogle ? '240px' : '65px'};
top: 5rem;
cursor: pointer;
border-radius: 50%;
border: none;
position: fixed;
  @media screen and (max-width: 768px) {
    & {
      display: none;
    }
  }
`
