import { Box, Button, Icon, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { Modal } from "reactstrap";
import { ButtonTransparent } from "../ModalEdit/styled";
import api from "../../../../service/api";
import Table from '../../../../components/Table/Table'
import GenericButton from "../../../../components/Buttons/Button";
import ModalUsersToAdmin from "../ModalUserToAdmin/ModalUserToAdmin";
import ModalConfirmGeneric from "../../../../components/Modais/Confirmar/ModalConfirmGeneric";
import ModalMessageGeneric from "../../../../components/Modais/Mensagem/ModalMessageGeneric";
import { cpfCnpjMask } from "../../../../utils/functions";
import InputSearch from "../../../../components/Filter/components/InputSearch";
import Loading from "../../../../components/Loader/Loading";

export default function ModalAdmins ({open, toggle}){ 

  const [userAdm, setUserAdm] = useState([]); 
  const [openUserToAdmin, setOpenUserToAdmin] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [UUID, setUUID] = useState();
  const [reset, setReset ]  = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false)
  const [deleteResponse, setDeleteResponse ] = useState(false)
  const [inputValue, setInputValue ] = useState("")
  const [loading, setLoading ] = useState(false)


  const onDeleteConfirm = async ( ) => {
    try {
      if(UUID){
       const res = await api.delete(`/telas-pj/usuarios-admin/${UUID}`)
       setDeleteResponse(res.data.retorno)
      }
    } catch (error) {
      setDeleteResponse("error")
    } finally {
      setModalSuccess(true)
      setReset(!reset)
    }
  }

  const close = ( ) => { 
    setModalSuccess(false)
    setOpenDelete(!openDelete)
  }

  const openDeleteModal = (data) => { 
    setUUID(data)
    setOpenDelete(true)
  }


  const getUserAdm =  async () => {
   setLoading(true)

    try {
      const res = await api.get(`/telas-pj/usuarios-admin?search=${inputValue}`)
      setInputValue('')
      setUserAdm(res.data.data); 
    } catch (error) {
    }finally{
      setLoading(false)
    }
  }


  useEffect(()=> { 
    if(open){ 
      getUserAdm()
    }
  },[open, reset ])

    return (
        <Modal className="modal-lg" isOpen={open} toggle={toggle}  > 
              <Box bgcolor={"rgb(25, 118, 210)"} width={"100%"} height={"4rem"} borderRadius={"6px 6px 0 0"} pl={"2rem"} pr={"2rem"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Box mt={"0.2rem"}>
                  <span style={{ fontSize: '1rem', color:"#ffffff", lineHeight:"0.1" }}>Usuários administradores</span>
                </Box>
                <Box>
                  <ButtonTransparent type="button" onClick={()=> toggle()}><IoClose /></ButtonTransparent>
                </Box>
              </Box>

              <Box display={"flex"} flexDirection={"column-reverse"}  alignItems={"flex-start"} justifyContent={"space-between"} gap={"1rem"} width={"100%"}  p={"1.5rem 1rem 0 1rem"} >

                  <Box height={"100%"} width={"350px"} display={"flex"} gap={"1rem"} alignItems={"flex-end"} marginTop={"-30px"}>

                    <InputSearch
                      width="100%"
                      value={inputValue}
                      setValue={(value) => setInputValue(value)}
                    />

                    <GenericButton
                      width={'140px'}
                      height={'31px !important'}
                      type="submit"
                      size="sm"
                      onClick={() => getUserAdm()}
                    >
                      <Icon className="fas fa-search"></Icon>Buscar
                    </GenericButton>


                  </Box>

                  <Box width={"100%"} display={"flex"} justifyContent={"flex-end"} alignItems={"flex-end"}>
                    <GenericButton  style={{ width: 'max-content', height: '31px', marginRight: "0" }}  onClick={()=> setOpenUserToAdmin(true)} >
                      Adicionar novo administrador
                    </GenericButton>
                  </Box>

              </Box>

              {!loading?(<Box width={"100%"}  p={"1.5rem 1rem 1.5rem 1rem"}>
                { userAdm &&
                  <Table headers={[
                      {
                        info: 'Nome',
                        style: { textAlign: 'left', width: '45%' },
                      },
                      {
                        info: 'CPF/CNPJ',
                        style: { textAlign: 'center', width: '25%' },
                      },
                      {
                        info: 'AÇÕES',
                        style: { textAlign: 'center' },
                      }
                    ]} 
                    
                    data={userAdm.map((user) => {
                      return{
                        data: [
                          {
                            info: user.NOME,
                            style: { textAlign: 'left', width: '45%' },
                          },                      
                          {
                            info: cpfCnpjMask(user.CPFCNPJ),
                            style: { textAlign: 'center', width: '25%' },
                          },                      
                          {
                            info: <Box display={"flex"} width={"100%"} justifyContent={'center'} gap={"1rem"}>
                                    <Button size="small" variant='outlined' color="error" onClick={()=>openDeleteModal(user.UUID)}>REMOVER</Button>
                                  </Box>,
                            style: { textAlign: 'center' },
                          },                      
                        ]
                      }
                    })} />
                }

                {!userAdm[0] &&<Box display={"flex"} justifyContent={"center"} alignItems={"center"}><p>Nenhum usuario administrador encontrado <span style={{cursor:"pointer", color: "#2405f2", textDecoration: "underline"}} onClick={()=>setOpenUserToAdmin(true)}>click aqui para adicionar</span></p></Box>}
            </Box>): <Loading color={"#1976d2"} />}

                
            <ModalConfirmGeneric open={openDelete} toggle={()=>setOpenDelete(!openDelete)} message={"Após a confirmação, o usuário perde seu status de administrador."} title={"Tem certeza que deseja continuar?"} action={()=>onDeleteConfirm()} headerBgColor={"#d63b3b"} />

            <ModalUsersToAdmin open={openUserToAdmin} toggle={()=>setOpenUserToAdmin(!openUserToAdmin)}  reset={()=>setReset(!reset)} /> 

            <ModalMessageGeneric open={modalSuccess} toggle={() => close()} retorno={deleteResponse}/>
        </Modal>
    )

}