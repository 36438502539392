import * as yup from 'yup';

const operateBalanceValidation = yup.object().shape({
  VALOR: yup
    .string()
    .required()
    .test((value) => value !== 'R$ 0,00'),
  DESCRICAO: yup.string(),
});

export default operateBalanceValidation;
