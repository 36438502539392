import React from 'react';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import * as Styled from '../../../ModalInfo.styles';
import formatCPF from '../../../../../../utils/formatCPF';
import formatCNPJ from '../../../../../../utils/formatCNPJ';
import formatPhoneNumber from '../../../../../../utils/formatPhoneNumber';
import formatCEP from '../../../../../../utils/formatCEP';
import { Col, Row } from 'reactstrap';
import isCpf from '../../../../../../utils/isCpf';

function AccordionInformacoesPessoais({
  user: {
    CPFCNPJ,
    NOME,
    EMAIL,
    NASCIMENTO,
    TELEFONE,
    CEP,
    ENDERECO,
    BAIRRO,
    COMPLEMENTO,
    CIDADE,
    UF,
    SALARIO,
    MATRICULA,
    EMPRESATRABALHA,
    TIPO_USUARIO,
  },
}) {
  return (
    <Accordion expanded={true}>
      <AccordionSummary>
        <Typography variant="h5" sx={{ width: '80%', fontWeight: '600', fontSize: '18px', flexShrink: 0 }}>
          Informações
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Row>
            <Col>
              <Styled.ParagraphName>
                CPF:{' '}
                <Typography variant="p" {...styles.paragraph}>
                  {isCpf(CPFCNPJ) ? formatCPF(CPFCNPJ) : formatCNPJ(CPFCNPJ)}
                </Typography>
              </Styled.ParagraphName>
            </Col>
            <Col>
              <Styled.ParagraphName>
                Nome:{' '}
                <Typography variant="p" {...styles.paragraph}>
                  {NOME}
                </Typography>
              </Styled.ParagraphName>
            </Col>
          </Row>
          <Row>
            <Col>
              <Styled.ParagraphName>
                E-mail:{' '}
                <Typography variant="p" {...styles.paragraph}>
                  {EMAIL}
                </Typography>
              </Styled.ParagraphName>
            </Col>
            <Col>
              <Styled.ParagraphName>
                Data de Nascimento:{' '}
                <Typography variant="p" {...styles.paragraph}>
                  {NASCIMENTO}
                </Typography>
              </Styled.ParagraphName>
            </Col>
          </Row>
          <Row>
            <Col>
              <Styled.ParagraphName>
                Telefone:{' '}
                <Typography variant="p" {...styles.paragraph}>
                  {formatPhoneNumber(TELEFONE)}
                </Typography>
              </Styled.ParagraphName>
            </Col>
            <Col>
              <Styled.ParagraphName>
                CEP:{' '}
                <Typography variant="p" {...styles.paragraph}>
                  {formatCEP(CEP)}
                </Typography>
              </Styled.ParagraphName>
            </Col>
          </Row>
          <Row>
            <Col>
              <Styled.ParagraphName>
                Endereço:{' '}
                <Typography variant="p" {...styles.paragraph}>
                  {ENDERECO}
                </Typography>
              </Styled.ParagraphName>
            </Col>
            <Col>
              <Styled.ParagraphName>
                Bairro:{' '}
                <Typography variant="p" {...styles.paragraph}>
                  {BAIRRO}
                </Typography>
              </Styled.ParagraphName>
            </Col>
          </Row>
          <Row>
            <Col>
              <Styled.ParagraphName>
                Complemento:{' '}
                <Typography variant="p" {...styles.paragraph}>
                  {COMPLEMENTO}
                </Typography>
              </Styled.ParagraphName>
            </Col>
            <Col>
              <Styled.ParagraphName>
                Cidade:{' '}
                <Typography variant="p" {...styles.paragraph}>
                  {CIDADE}
                </Typography>
              </Styled.ParagraphName>
            </Col>
          </Row>
          <Row>
            <Col>
              <Styled.ParagraphName>
                UF:{' '}
                <Typography variant="p" {...styles.paragraph}>
                  {UF}
                </Typography>
              </Styled.ParagraphName>
            </Col>
            <Col>
              <Styled.ParagraphName>
                Renda mensal:{' '}
                <Typography variant="p" {...styles.paragraph}>
                  {SALARIO.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </Typography>
              </Styled.ParagraphName>
            </Col>
          </Row>
          <Row>
            <Col>
              <Styled.ParagraphName>
                Matrícula:{' '}
                <Typography variant="p" {...styles.paragraph}>
                  {MATRICULA ? MATRICULA : '---'}
                </Typography>
              </Styled.ParagraphName>
            </Col>
            <Col>
              <Styled.ParagraphName>
                Empresa:{' '}
                <Typography variant="p" {...styles.paragraph}>
                  {EMPRESATRABALHA ? EMPRESATRABALHA : '---'}
                </Typography>
              </Styled.ParagraphName>
            </Col>
          </Row>
          <Row>
            <Col>
              <Styled.ParagraphName>
                Parceiro:{' '}
                <Typography variant="p" {...styles.paragraph}>
                  {TIPO_USUARIO ? TIPO_USUARIO : '---'}
                </Typography>
              </Styled.ParagraphName>
            </Col>
          </Row>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

const styles = {
  paragraph: { fontWeight: 400, color: '#32325d' },
};

export default AccordionInformacoesPessoais;
