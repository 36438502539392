import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const ModalInfoCardUser = ({ open, toggle, title, message }) => {
  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        {message}
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle} color="info" >Fechar</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalInfoCardUser