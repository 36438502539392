import { Icon } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Badge } from 'reactstrap';
import GenericButton from '../../../components/Buttons/Button';
import CustomDataTable from '../../../components/DataTable/DataTable';
import InputDate from '../../../components/Filter/components/InputDate';
import InputSearch from '../../../components/Filter/components/InputSearch';
import InputSelectAmountResults from '../../../components/Filter/components/InputSelectAmountResults';
import api from '../../../service/api';
import { getTransactionsType } from '../../../store/transactions/actions';
import { formatCNPJ, formatCPF } from '../../../utils';
import { formatDateBR } from '../../../utils/dateKyc';
import isCpf from '../../../utils/isCpf';
import ModalChavePix from '../../User/components/modals/components/ModalChavePix/ModalChavePix';
import InputStatus from '../../../components/Filter/components/InputStatus';

export default function Chaves() {
  const [keys, setKeys] = useState(false);
  const [pagination, setPagination] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detailKey, setDetailKey] = useState(false);
  const [modalKey, setModalKey] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTransactionsType());
  }, []);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    cpfcnpj: '',
    nome: '',
    chave_pix: '',
    data_inicial: '',
    data_final: '',
  });

  function getValues(filter) {
    let queryParams = '';
    for (const [key, value] of Object.entries(filter)) {
      queryParams += `&${key}=${value}`;
    }
    return queryParams.replace('&', '?');
  }

  useEffect(() => {
    search();
  }, []);

  useEffect(() => {
    search(filter);
  }, [filter.page]);

  function search() {
    getKeys(filter);
  }

  let { page } = filter;

  const tablePagination = async (type = 'atual', pageClick) => {
    switch (type) {
      case 'prev':
        page = pagination.prevPage;
        break;
      case 'next':
        page = pagination.nextPage;
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }
    setFilter({ ...filter, page });
  };

  function getKeys() {
    setLoading(true);
    let params = getValues(filter);
    api
      .get(`/chaves-pix${params}`)
      .then(({ data }) => {
        if (data.retorno === 'sucesso') {
          setKeys(data.data);
          setPagination(data.pagination);
        }
      })
      .finally(() => setLoading(false));
  }

  const headers = [
    {
      name: 'Data',
      selector: (row) => formatDateBR(row.DATA_INSERT),
      style: { justifyContent: 'start' },
      sortable: true,
    },
    {
      name: 'CPF/CNPJ',
      selector: (row) => (row.CPFCNPJ ? (isCpf(row.CPFCNPJ) ? formatCPF(row.CPFCNPJ) : formatCNPJ(row.CPFCNPJ)) : null),
      style: { justifyContent: 'start' },
      sortable: true,
    },
    {
      name: 'TIPO CHAVE',
      selector: (row) => (
        <Badge style={{ fontSize: '.7rem', minWidth: '3rem' }} color="info">
          {row.TIPO_CHAVE}
        </Badge>
      ),
      style: { justifyContent: 'start' },
      sortable: true,
    },
    {
      name: 'NOME',
      selector: (row) => row.NOME,
      style: { justifyContent: 'start' },
      sortable: true,
    },
    {
      name: 'CONTA',
      selector: (row) => row.CONTA,
      style: { justifyContent: 'start' },
      sortable: true,
    },
    {
      name: 'Status Chave',
      selector: (row) => (
        <div>
          {row.ATIVO === 'S' ? (
            <Badge style={{ minWidth: '4rem', fontSize: '.7rem' }} color="success">
              {'ATIVA'}
            </Badge>
          ) : (
            <Badge style={{ minWidth: '4rem', fontSize: '.7rem' }} color="danger">
              {'INATIVA'}
            </Badge>
          )}
        </div>
      ),

      style: { justifyContent: 'start' },
      sortable: true,
    },
    {
      cell: (row) => <div>{row.CHAVE_PIX}</div>,
      name: 'CHAVE',
      selector: (row) => row.CHAVE_PIX,
      style: { justifyContent: 'start' },
      sortable: true,
    },
  ];

  const customStyles = {
    cells: {
      style: {
        cursor: 'pointer',
      },
      headCells: {
        style: {
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
        },
      },
    },
  };

  const openKeyModal = (key) => {
    setDetailKey(key);
    setModalKey(true);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          alignItems: 'flex-end',
          gap: '1rem',
          marginTop: '1rem',
        }}
      >
        {detailKey && (
          <ModalChavePix
            toggle={() => {
              setDetailKey(false);
              setModalKey(false);
            }}
            open={modalKey}
            chavePix={detailKey}
          />
        )}

        <InputDate
          label="Data Inicial"
          name="DATA INICIO"
          value={filter.data_inicial}
          setValue={(value) => setFilter({ ...filter, data_inicial: value })}
        />

        <InputDate
          label="Data Final"
          name="DATA FINAL"
          value={filter.data_final}
          setValue={(value) => setFilter({ ...filter, data_final: value })}
        />

        <InputSearch
          label={'Pesquisa'}
          placeholder="Nome ou CPF/CNPJ"
          width="10rem"
          value={filter.cpf}
          setValue={(value) => setFilter({ ...filter, cpfcnpj: value })}
        />

        <InputSearch
          label={'Conta'}
          placeholder="Conta"
          width="10rem"
          value={filter.conta}
          setValue={(value) => setFilter({ ...filter, conta: value })}
        />

        <InputSearch
          label={'Chave'}
          placeholder="Chave"
          width="10rem"
          value={filter.chave_pix}
          setValue={(value) => setFilter({ ...filter, chave_pix: value })}
        />

        <InputStatus
          inputName={'Status'}
          setValue={(value) => setFilter({ ...filter, status: value })}
          options={['Todos', 'Ativa', 'Inativa']}
          codeValue={['', 'S', 'N']}
        />

        <GenericButton onClick={(e) => search()} height="30px" className="mt-3" size="sm" type="submit">
          <Icon style={{ marginRight: '.4rem', fontSize: '1rem' }} className="fas fa-search"></Icon>Buscar
        </GenericButton>

        {/*     <ReactHTMLTableToExcel
          id="test-table-xls-button-accounts"
          className="export-to-excel-button button-height-30px"
          table="table-to-xls"
          filename="accounts"
          sheet="tablexls"
          buttonText="Export"
        /> */}
      </div>

      <div style={{ display: 'block', marginTop: '1rem' }}>
        <InputSelectAmountResults
          options={['5', '10', '25', '50', '100', '1000']}
          column={true}
          value={filter.limit}
          setValue={(value) => setFilter({ ...filter, limit: value })}
        />
      </div>

      <div style={{ marginTop: '1rem' }}>
        <CustomDataTable
          loading={loading}
          pagination={pagination}
          previousPage={{ onClick: () => tablePagination('prev') }}
          nowPage={{ page, onClick: tablePagination }}
          nextPage={{ onClick: () => tablePagination('next') }}
          data={keys}
          headers={headers}
          action={(row) => openKeyModal(row)}
          customStyles={customStyles}
        />
      </div>
    </div>
  );
}
