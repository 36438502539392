import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="none" viewBox="0 0 22 22">
      <path
        stroke="#F24E1E"
        strokeWidth="2"
        d="M11 21c5.523 0 10-4.477 10-10S16.523 1 11 1 1 5.477 1 11s4.477 10 10 10z"
      ></path>
      <path stroke="#F24E1E" strokeLinecap="round" strokeWidth="2" d="M11 15.5v.5m0-10v6-6z"></path>
    </svg>
  );
}

export default Icon;
