import {
  RESPONSE_CANCELLATION,
  GET_CANCELLATION,
  GET_CANCELLATION_SUCCESS,
  GET_CANCELLATION_FAIL,
  GET_INFO_CANCELLATION,
  GET_INFO_CANCELLATION_SUCCESS,
  GET_INFO_CANCELLATION_FAIL,
} from './types';

const INITIAL_STATE = {
  loading_cancellation: false,
  erro_cancellation: false,
  erro_message_cancellation: null,

  loading_info_cancellation: false,
  erro_info_cancellation: false,
  erro_message_info_cancellation: null,

  cancellation_list: [],
  cancellation_infos: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESPONSE_CANCELLATION:
      return { ...state, ...action.payload };
    case GET_CANCELLATION:
      return {
        ...state,
        cancellation_list: [],
        loading_cancellation: true,
        erro_cancellation: false,
        erro_message_cancellation: null,
      };
    case GET_CANCELLATION_SUCCESS:
      return {
        ...state,
        cancellation_list: action.payload,
        loading_cancellation: false,
        erro_cancellation: false,
        erro_message_cancellation: null,
      };
    case GET_CANCELLATION_FAIL:
      return {
        ...state,
        cancellation_list: action.payload,
        loading_cancellation: false,
        erro_cancellation: true,
        erro_message_cancellation: action.error,
      };
    case GET_INFO_CANCELLATION:
      return {
        ...state,
        cancellation_infos: [],
        loading_info_cancellation: true,
        erro_info_cancellation: false,
        erro_message_info_cancellation: null,
      };
    case GET_INFO_CANCELLATION_SUCCESS:
      return {
        ...state,
        cancellation_infos: action.payload,
        loading_info_cancellation: false,
        erro_info_cancellation: false,
        erro_message_info_cancellation: null,
      };
    case GET_INFO_CANCELLATION_FAIL:
      return {
        ...state,
        cancellation_infos: action.payload,
        loading_info_cancellation: false,
        erro_info_cancellation: true,
        erro_message_info_cancellation: action.error,
      };
    default:
      return state;
  }
};
