import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form } from 'reactstrap';
import GenericButton from '../../components/Button/Button.style';
import CustomContainer from '../../components/Custom/CustomContainer';
import InputSearch from '../../components/Filter/components/InputSearch';
import InputSelectAmountResults from '../../components/Filter/components/InputSelectAmountResults';
import Filter from '../../components/Filter/Filter';
import { InputsContainer } from '../../components/Filter/styles/Filter.style';
import Icon from '../../components/Icon/Icon.styles';
import Loading from '../../components/Loader/Loading';

import ProductTable from '../../components/ProductTable/ProductTable';
import { listarProdutosESegmentos } from '../../store/swap/actions';
import { AdicionarProduto } from '../User/components/modals';
import EditSingleProduct from '../User/components/modals/EditSingleProduct';
import * as Styled from './../User/GerenciarClientes.styles';

export default function Products() {
  const dispatch = useDispatch();
  const [modalAddProduto, setModalAddProduto] = useState(false);
  const [modalEditProduto, setModalEditProduto] = useState(false);
  const [edit, setEdit] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [reload, setReload] = useState(false);

  const [limit, setLimit] = useState(10);

  const { listagemProdutoSegmentos, loadingProduct } = useSelector((seg) => seg.swap);

  useEffect(() => {
    dispatch(listarProdutosESegmentos({ page, limit, search }));
  }, [page, reload]);

  function handleSearch() {
    dispatch(listarProdutosESegmentos({ page, limit, search }));
  }

  return (
    <div>
      <CustomContainer>
        <Button
          style={{ position: 'absolute', right: '17px', backgroundColor: '#085CA6', boder: 'none', color: '#fff' }}
          size="sm"
          onClick={() => setModalAddProduto(true)}
        >
          Adicionar
        </Button>
        <Filter>
          <Form className="form" onSubmit={(e) => e.preventDefault()}>
            <InputsContainer>
              <InputSearch placeholder="Nome do benefício" width="20vw" value={search} setValue={(v) => setSearch(v)} />
              {/*               <InputActive
                inputName="Usuário Ativo"
                options={['Todos', 'SIM', 'NAO']}
                codeValue={['', 1, 2]}
                setActiveUser={setActiveUser}
              /> */}
              <GenericButton
                type="submit"
                marginTop={'35px !important'}
                marginLeft={'15px'}
                width={'140px'}
                heigth={'35px'}
                onClick={() => handleSearch()}
              >
                <Icon className="fas fa-search"></Icon>Buscar
              </GenericButton>
            </InputsContainer>
          </Form>
          {listagemProdutoSegmentos &&
            listagemProdutoSegmentos.pagination &&
            listagemProdutoSegmentos.pagination.totalResult && (
              <Styled.infoInput
                value={'Total de Usuários ' + listagemProdutoSegmentos.pagination.totalResult}
              ></Styled.infoInput>
            )}
          <div>
            <InputSelectAmountResults
              options={['10', '25', '50', '100', '1000']}
              value={limit}
              setValue={(value) => setLimit(value)}
            />
          </div>
        </Filter>
        {listagemProdutoSegmentos &&
          (loadingProduct ? (
            <Loading color="blue" />
          ) : (
            <ProductTable
              products={listagemProdutoSegmentos.dados}
              pagination={listagemProdutoSegmentos.pagination}
              setModalEditProduto={setModalEditProduto}
              page={page}
              setPage={setPage}
              setEdit={setEdit}
            />
          ))}

        <AdicionarProduto
          reload={reload}
          setReload={setReload}
          open={modalAddProduto}
          close={(e) => setModalAddProduto(false)}
        />
        {listagemProdutoSegmentos && (
          <EditSingleProduct
            open={modalEditProduto}
            close={(e) => setModalEditProduto(false)}
            edit={edit}
            listagemProdutoSegmentos={listagemProdutoSegmentos.dados}
            reload={reload}
            setReload={setReload}
          />
        )}
      </CustomContainer>
    </div>
  );
}
