import { Box, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { DocumentFuncional as ModalDocument } from '../../../../../views/User/components/modals';

function ModalDocs({ open, toggle, documents }) {
  const [imagem, setImagem] = useState({
    openDocument: false,
    documentLabel: '',
    urlImage: '',
  });

  return (
    <>
      <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <Typography>Documentos</Typography>
        </ModalHeader>
        <ModalBody>
          {documents && documents.length > 0 ? (
            documents.map((document, index) => (
              <Button
                key={index}
                color="secondary"
                outline
                size="sm"
                onClick={() =>
                  setImagem({
                    openDocument: true,
                    documentLabel: document.DESCRICAO_CATEGORIA,
                    urlImage: document.CAMINHO_ARQUIVO,
                  })
                }
              >
                {document.DESCRICAO_CATEGORIA}
              </Button>
            ))
          ) : (
            <Typography variant="p">Não existe documentos</Typography>
          )}
        </ModalBody>
      </Modal>
      <ModalDocument state={imagem} toggle={() => setImagem({ openDocument: false })} />
    </>
  );
}

export default ModalDocs;
