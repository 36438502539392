import React, { useState } from 'react';
import { AccountBalance } from '@material-ui/icons';
import { PriceChange } from '@mui/icons-material/';
import { Box, Tab, Tabs } from '@mui/material';
import CustomContainer from '../../components/Custom/CustomContainer';

import Payment from './Payment';
import Charge from './Charge';

export default function Boleto() {
    const components = [<Payment />, <Charge />];

    const [tabNumber, setTabNumber] = useState(0);

    return (
        <Box>
            <CustomContainer style={{ top: 20 }}>
                <Tabs sx={{ marginTop: -12 }} value={tabNumber} onChange={(event, number) => setTabNumber(number)}>
                    <Tab
                        icon={<PriceChange />}
                        sx={{
                            bgcolor: 'white',
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                        }}
                        label="Pagamentos"
                    />
                    <Tab
                        icon={<AccountBalance />}
                        sx={{
                            bgcolor: 'white',
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                        }}
                        label="Cobranças"
                    />
                </Tabs>
                {components[tabNumber]}
            </CustomContainer>
        </Box>
    );
}
