import React from 'react';
import { Alert } from 'reactstrap';

const AlertConfig = (props) => {
  const { isOpen, toggle, color, mensagem } = props;

  // const close = window.setTimeout(function(){ toggle }, 3000);

  return (
    <div>
      <Alert color={color} isOpen={isOpen} toggle={toggle}>
        {mensagem}
      </Alert>
    </div>
  );
};

export default AlertConfig;
