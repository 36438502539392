import { Box, Modal, Typography } from '@material-ui/core';
import React from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 500,
  bgcolor: 'background.paper',
  border: '1px solid #00000068',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
  overflowX: 'hidden',
};

export default function ModalProcesses({ isOpen, setModalProcesses, processes }) {
  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => setModalProcesses(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h1>Processos </h1>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {processes.map((process) => (
              <p>{process}</p>
            ))}
          </Typography>
        </Box>
      </Modal>{' '}
    </>
  );
}
