import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import { Spring } from 'react-spring/renderprops';
import moment from 'moment';

import FormUserNew from './FormUserNew';
import Loading from '../../components/Loader/Loading';
import { query } from '../../utils/query';
import { listaUserDetalhes } from '../../store/user/actions.js';
import { getExtract } from '../../store/extract/actions';

import { validationPassword } from '../../store/coban/actions';

const ViewUser = (props) => {
  const params = query(props.location);
  const [reloadCards, setReloadCards] = useState(false);
  const [typeTransaction, setTypeTransactions] = useState(1);

  const [filterCards, setFilterCards] = useState({
    limit: 5,
    search: '',
    page: 1,
    status: 0,
    dataInicial: '',
    dataFinal: moment().format('YYYY-MM-DD'),
    numCartao: '',
  });

  const [filter, setFilter] = useState({
    UUID: params.user,
    LIMIT: '',
    DATA_INICIO: moment().add(-7, 'days').format('YYYY-MM-DD'),
    DATA_FIM: moment().format('YYYY-MM-DD'),
    PAGE: 1,
    SIZE: 10,
  });

  function forceReload() {
    setReloadCards(!reloadCards);
  }

  useEffect(() => {
    const UUID = params.user;
    props.listaUserDetalhes(UUID);
  }, []);

  return props.user.loading_user ? (
    <Loading color="#2405f2" />
  ) : props.user.user == null ? (
    <h3>Usuário não encontrado</h3>
  ) : (
    <Spring from={{ opacity: -31 }} to={{ opacity: 1 }}>
      {(props) => (
        <div style={props}>
          <div style={{ position: 'relative', top: -100 }}>
            <div className="header">
              <Container fluid></Container>
            </div>
            <FormUserNew
              user={props.user && props.user.user}
              saldo={props.user && props.user.saldo}
              reloadCards={reloadCards}
              filterCards={filterCards}
              setFilterCards={setFilterCards}
              forceReload={forceReload}
            />
          </div>
        </div>
      )}
    </Spring>
  );
};

const mapStateToProps = ({ user, extract }) => {
  return { user, extract };
};

export default connect(mapStateToProps, {
  listaUserDetalhes,
  getExtract,
  validationPassword,
})(ViewUser);
