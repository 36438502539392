import React from 'react';

export default function MasterCardSvg() {
  return (
    <div>
      <svg width="30" height="19" viewBox="0 0 60 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1220_5408)">
          <path
            d="M59.4284 29.7165V29.0042H59.2409L59.024 29.493L58.8083 29.0042H58.6202V29.7165H58.7534V29.1799L58.9562 29.6429H59.0942L59.297 29.1787V29.7165H59.429H59.4284ZM58.2389 29.7165V29.1261H58.4789V29.0059H57.8657V29.1261H58.1057V29.7165H58.2377H58.2389Z"
            fill="#F79410"
          />
          <path d="M38.1162 32.7712H21.8904V3.92188H38.1165L38.1162 32.7712Z" fill="#FF5F00" />
          <path
            d="M22.9195 18.3475C22.9195 12.4955 25.6891 7.28239 30.0019 3.92287C26.7373 1.37614 22.6996 -0.00605738 18.5422 1.99555e-05C8.30111 1.99555e-05 -0.000488281 8.21436 -0.000488281 18.3475C-0.000488281 28.4807 8.30111 36.695 18.5422 36.695C22.6997 36.7012 26.7376 35.319 30.0022 32.7722C25.6897 29.4133 22.9195 24.1999 22.9195 18.3475Z"
            fill="#EB001B"
          />
          <path
            d="M60.0074 18.3475C60.0074 28.4807 51.7058 36.695 41.4647 36.695C37.3068 36.7011 33.2684 35.3189 30.0032 32.7722C34.3172 29.4127 37.0868 24.1999 37.0868 18.3475C37.0868 12.4952 34.3172 7.28239 30.0032 3.92287C33.2684 1.37622 37.3066 -0.00595085 41.4644 1.92594e-05C51.7055 1.92594e-05 60.0071 8.21436 60.0071 18.3475"
            fill="#F79E1B"
          />
        </g>
        <defs>
          <clipPath id="clip0_1220_5408">
            <rect width="60" height="39" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
