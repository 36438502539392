import React from 'react';
import AccordionVerifications from '../../../../../../views/User/components/AccordionVerifications/AccordionVerifications';
import AccordionKYC from './AccordionKYC';

function TabVerificacoes({ uniqueColumn, user }) {
  return (
    <>
      <AccordionVerifications uniqueColumn={uniqueColumn} user={user} />
      <AccordionKYC />
    </>
  );
}

export default TabVerificacoes;
