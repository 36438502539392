import {
  ACESSO_DISPOSITIVO_RESPONSE,
  CONTADIGITAL_RESPONSE,
  DEFAULT_TYPE,
  EDITAR_REPRESENTANTES_RESPONSE,
  GET_ACESSOBIO_ERROR,
  GET_STATUS_USUARIO,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  KYC,
  SOLICITACAO_CARTAO_RESPONSE,
  SOLICITAR_TROCA_SENHA_RESPONSE,
  TOGGLE_MODAL_SOLICITAR_CARTAO_NOMINAL,
  USERS_RESPONSE,
  USER_RESPONSE_DATATRUST,
} from './types';

import api, { apiKYC } from '../../service/api';
import dayjs from 'dayjs';

// Busca todos usuários a partir do type espera, aprovado, negado
export const getUsers = (type, params = { page: 1, limit: 10 }, callback) => {
  return (dispatch) => {
    dispatch({ type: GET_USERS, userType: type });
    if (!type) type = 'espera';
    api
      .post(`usuario/listar/${type}`, params)
      .then((res) => {
        dispatch({
          type: GET_USERS_SUCCESS,
          payload: res.data.DATA || [],
          pagination: res.data.PAGINATION || {},
          nivelPrioridade: res.data.NIVEL_PRIORIDADE,
        });
        if (callback) callback(true, res);
      })
      .catch((err) => {
        dispatch({ type: GET_USERS_FAIL, payload: err, userType: type });
        if (callback) callback(false, err);
      });
  };
};

export const listaUser = (cpfcnpj) => {
  return async (dispatch) => {
    dispatch({
      type: USERS_RESPONSE,
      payload: { loading_user: true, loading_user_list: true },
    });
    const { status, data } = await api.get(`usuario/listar?CPFCNPJ_CLIENTE=${cpfcnpj}&SCORE=true`);
    if (status === 200)
      dispatch({
        type: USERS_RESPONSE,
        payload: { user: data, loading_user: false, loading_user_list: false },
      });
    else
      dispatch({
        type: USERS_RESPONSE,
        payload: { user: null, loading_user: false, loading_user_list: false },
      });
  };
};

export const listaUserDetalhes = (UUID) => {
  return async (dispatch) => {
    dispatch({
      type: USERS_RESPONSE,
      payload: { loading_user: true, loading_user_list: true },
    });
    const { status, data } = await api.get(`usuario/listar/detalhes?UUID=${UUID}&SCORE=true`);
    if (status === 200)
      dispatch({
        type: USERS_RESPONSE,
        payload: { user: data, loading_user: false, loading_user_list: false },
      });
    else
      dispatch({
        type: USERS_RESPONSE,
        payload: { user: null, loading_user: false, loading_user_list: false },
      });
  };
};

export const listaUserDetalhesWithoutLoading = (UUID) => {
  return async (dispatch) => {
    const { status, data } = await api.get(`usuario/listar/detalhes?UUID=${UUID}&SCORE=true`);
    if (status === 200)
      dispatch({
        type: USERS_RESPONSE,
        payload: { user: data },
      });
    else
      dispatch({
        type: USERS_RESPONSE,
        payload: { user: null },
      });
  };
};

export const certifyTaxe = () => {
  return async (dispatch) => {
    dispatch({ type: USERS_RESPONSE, payload: { loading_user: true } });
    const { status, data } = await api.get(`usuario/taxa/certificar`);
    if (status === 200)
      dispatch({
        type: USERS_RESPONSE,
        payload: { user: data, loading_user: false },
      });
    else
      dispatch({
        type: USERS_RESPONSE,
        payload: { user: null, loading_user: false },
      });
  };
};
export const consultDataTrust = (CPFCNPJ_CLIENTE, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: USERS_RESPONSE, payload: { loading_user: true } });
    try {
      const { status, data } = await api.post('score/datatrust', {
        CPFCNPJ_CLIENTE,
      });
      const { score, avaliacoes, informacoes } = data.payload;
      dispatch({
        type: USER_RESPONSE_DATATRUST,
        payload: { score, avaliacoes, informacoes },
      });
      callback(true);
    } catch (error) {
      dispatch({ type: USERS_RESPONSE, payload: { loading_user: false } });
      alert(error.message);
      callback(false);
    }
  };
};

export const approval = (type, params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: USERS_RESPONSE, payload: { loading_user: true } });
    try {
      const { status, data } = await api.post(`usuario/${type}`, params);
      dispatch({ type: USERS_RESPONSE, payload: { loading_user: false } });
      callback(data);
    } catch (error) {
      const { payload } = error.response.data;
      dispatch({ type: USERS_RESPONSE, payload: { loading_user: false } });
      callback(error.response.data);
    }
  };
};

export const blockUser = (params, callback = () => null) => {
  return async (dispatch) => {
    try {
      const { data } = await api.post(`usuario/bloquear`, params);
      dispatch({
        type: USERS_RESPONSE,
        payload: { mensagem: data.mensagem },
      });
      callback(data);
    } catch (error) {
      const { payload } = error.response.data;
      callback(error.response.data);
    }
  };
};

export const blockUserByUUID = (uuid, params, callback = () => null) => {
  return async (dispatch) => {
    try {
      const { data } = await api.post(`usuario/bloquear/${uuid}`, params);
      dispatch({
        type: USERS_RESPONSE,
        payload: { mensagem: data.mensagem },
      });
      callback(data);
    } catch (error) {
      const { payload } = error.response.data;
      callback(error.response.data);
    }
  };
};

export const unlock = (params, callback = () => null) => {
  return async (dispatch) => {
    try {
      const { data } = await api.post(`usuario/desbloquear`, params);
      dispatch({
        type: USERS_RESPONSE,
        payload: { mensagem: data.mensagem },
      });
      callback(data);
    } catch (error) {
      const { payload } = error.response.data;
      callback(error.response.data);
    }
  };
};

export const unlockByUUID = (uuid, params, callback = () => null) => {
  return async (dispatch) => {
    try {
      const { data } = await api.post(`usuario/desbloquear/${uuid}`, params);
      dispatch({
        type: USERS_RESPONSE,
        payload: { mensagem: data.mensagem },
      });
      callback(data);
    } catch (error) {
      const { payload } = error.response.data;
      callback(error.response.data);
    }
  };
};

export const desblockUserCard = (params, callback = () => null) => {
  return async (dispatch) => {
    try {
      const { data } = await api.post(`usuario/desbloquearUserCartao`, params);
      dispatch({
        type: USERS_RESPONSE,
        payload: { mensagem: data.mensagem },
      });
      callback(data);
    } catch (error) {
      const { payload } = error.response.data;
      callback(error.response.data);
    }
  };
};

// Busca histórico de saldo do usuário
export const getBalanceHistory = (props, callback) => {
  return (dispatch) => {
    dispatch({ type: DEFAULT_TYPE, payload: { loading: true } });
    api
      .post(`usuario/historico/saldo`, props)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { loading: false, saldo: res.data },
        });
        if (callback) callback(true, res);
      })
      .catch((err) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { loading: false, erro: true, erro_message: err },
        });
        if (callback) callback(false, err);
      });
  };
};

export const getBalanceHistoryByUUID = (uuid, props, callback) => {
  return (dispatch) => {
    dispatch({ type: DEFAULT_TYPE, payload: { loading: true } });
    api
      .post(`usuario/historico/saldo/${uuid}`, props)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { loading: false, saldo: res.data.data },
        });
        if (callback) callback(true, res);
      })
      .catch((err) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { loading: false, erro: true, erro_message: err },
        });
        if (callback) callback(false, err);
      });
  };
};

//busca todos os usuarios
export const getBasicUsers = (callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: USERS_RESPONSE, payload: {} });
    try {
      const { data } = await api.get(`usuario/listar/dadosbasicos`);
      dispatch({ type: USERS_RESPONSE, payload: { userTotal: data } });
    } catch (error) {
      dispatch({ type: USERS_RESPONSE, payload: {} });
    }
  };
};

export const getUserPdv = (callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: USERS_RESPONSE, payload: {} });
    try {
      const { data } = await api.get(`usuario/pdv`);
      dispatch({ type: USERS_RESPONSE, payload: { userPdv: data } });
    } catch (error) {
      dispatch({ type: USERS_RESPONSE, payload: {} });
    }
  };
};

export const getFlagCard = (callback = () => null) => {
  return async (dispatch) => {
    try {
      const { data } = await api.get(`cartao/buscarBandeiras`);
      dispatch({ type: DEFAULT_TYPE, payload: { typeFlag: data } });
    } catch (error) {
      dispatch({ type: DEFAULT_TYPE, payload: error });
    }
  };
};

export const getTypeCard = (callback = () => null) => {
  return async (dispatch) => {
    try {
      const { data } = await api.get(`cartao/tiposCartao`);
      dispatch({ type: DEFAULT_TYPE, payload: { typeCard: data } });
    } catch (error) {
      dispatch({ type: DEFAULT_TYPE, payload: error });
    }
  };
};

export const createUserCard = (params, callback) => {
  return (dispatch) => {
    api
      .post(`cartao/inserir`, params)
      .then((res) => {
        dispatch({ type: GET_USERS_SUCCESS, payload: res.data });
        if (callback) callback(true, res);
      })
      .catch((err) => {
        dispatch({ type: GET_USERS_FAIL, payload: err });
        if (callback) callback(false, err);
      });
  };
};

export const blockUserCard = (params, callback = () => null) => {
  return async (dispatch) => {
    try {
      const response = await api.put(`cartao/changeStatus`, params);
      dispatch({
        type: GET_USERS_SUCCESS,
        payload: response,
      });
      callback(response);
    } catch (error) {
      dispatch({
        type: GET_USERS_FAIL,
        payload: error.response,
      });
      callback(error.response);
    }
  };
};
export const blockUserCardByUUID = (uuid, params, callback = () => null) => {
  return async (dispatch) => {
    try {
      const response = await api.put(`cartao/changeStatus/${uuid}`, params);
      dispatch({
        type: GET_USERS_SUCCESS,
        payload: response,
      });
      callback(response);
    } catch (error) {
      dispatch({
        type: GET_USERS_FAIL,
        payload: error.response,
      });
      callback(error.response);
    }
  };
};

export const blockUserDigitalCard = (params, callback) => {
  return async (dispatch) => {
    try {
      const { data } = await api.put(`cartao/bloquear_cartao`, params);
      dispatch({
        type: USERS_RESPONSE,
        payload: {
          mensagem: data.mensagem,
        },
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: DEFAULT_TYPE,
        payload: { erro: true, mensagem: error.response.data.mensagem },
      });
      callback(error);
    }
  };
};
export const getAcessobio = (params, callback) => {
  return (dispatch) => {
    dispatch({ type: DEFAULT_TYPE, payload: { loading_acessobio: true } });
    api
      .post(`usuario/listar/acessobio`, params)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: {
            ACESSOBIO: res.data.VALORES,
            ConflictSubject: res.data.VALORES.conflito && res.data.VALORES.conflito.ConflictSubject,
            loading_acessobio: false,
            mensagem: res.data.MENSAGEM,
          },
        });
        if (callback) callback(true, res);
      })
      .catch((err) => {
        dispatch({ type: GET_ACESSOBIO_ERROR, loading_acessobio: false });
        if (callback) callback(false, err);
      });
  };
};

export const updateSolicitation = (props, callback) => {
  return (dispatch) => {
    dispatch({ type: DEFAULT_TYPE });
    api
      .post(`solicitacao/atualizar`, props)

      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { messageSolicitation: res.data.mensagem },
        });
        if (callback) callback(true, res);
      })
      .catch((err) => {
        dispatch({ type: DEFAULT_TYPE, payload: { erro: true } });
        if (callback) callback(false, err);
      });
  };
};

export const updateAccountUser = (props, callback) => {
  return (dispatch) => {
    api
      .post(`usuario/contaBancaria-editar`, props)
      .then((res) => {
        dispatch({ type: DEFAULT_TYPE, payload: res.data });
        if (callback) callback(res.data);
      })
      .catch((err) => {
        dispatch({ type: DEFAULT_TYPE, payload: { erro: true } });
        if (callback) callback(err.response.data);
      });
  };
};

export const buscaBancos = (callback) => {
  return (dispatch) => {
    api
      .get(`bancos/buscar`)
      .then((res) => {
        dispatch({ type: DEFAULT_TYPE, payload: { banks: res.data } });
        if (callback) callback(true, res);
      })
      .catch((err) => {
        dispatch({ type: DEFAULT_TYPE, payload: { erro: true } });
        if (callback) callback(false, err);
      });
  };
};

export const newAccount = (params, callback) => {
  return (dispatch) => {
    api
      .post(`usuario/nova-contaBancaria`, params)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { mensagem: res.data.mensagem },
        });
        if (callback) callback(res.data);
      })
      .catch((err) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { mensagem: err.response.data.mensagem, erro: true },
        });
        if (callback) callback(err.response.data);
      });
  };
};

export const deleteAccounting = (param1, param2, callback) => {
  const objParam = {
    CPFCNPJ_USUARIO: param1,
    ID: param2,
  };

  return (dispatch) => {
    api
      .post(`contabancaria/deletar`, objParam)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { mensagem: res.data.mensagem },
        });
        if (callback) callback(res.data);
      })
      .catch((err) => {
        dispatch({ type: DEFAULT_TYPE, payload: { erro: true } });
        if (callback) callback(err.response.data);
      });
  };
};

export const deleteIdDispositivo = (cpfUser, callback) => {
  const objParam = {
    CPFCNPJ_CLIENTE: cpfUser,
  };

  return (dispatch) => {
    api
      .post(`usuario/atualizarID`, objParam)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { mensagem: res.data.mensagem },
        });
        if (callback) callback(res.data);
      })
      .catch((err) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { erro: true, mensagem: err.response.data.mensagem },
        });
        if (callback) callback(err.response.data);
      });
  };
};

export const alteraCobanUsuario = (dados, callback) => {
  return (dispatch) => {
    api
      .put(`usuario/atualizar/perfil`, dados)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { mensagem: res.data.mensagem },
        });
        if (callback) callback(res.data);
      })
      .catch((err) => {
        dispatch({ type: DEFAULT_TYPE, payload: { erro: true } });
        if (callback) callback(err.response.data);
      });
  };
};

export const alteraCobanUsuarioByUUID = (uuid, dados, callback) => {
  return (dispatch) => {
    api
      .put(`usuario/atualizar/perfil/${uuid}`, dados)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { mensagem: res.data.mensagem },
        });
        if (callback) callback(res.data);
      })
      .catch((err) => {
        dispatch({ type: DEFAULT_TYPE, payload: { erro: true } });
        if (callback) callback(err.response.data);
      });
  };
};

export const alteraUsuario = (dados, callback) => {
  return (dispatch) => {
    api
      .put(`usuario/adm/editar`, dados)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { mensagem: res.data.MENSAGEM },
        });
        if (callback) callback(res.data);
      })
      .catch((err) => {
        dispatch({ type: DEFAULT_TYPE, payload: { erro: true } });
        if (callback) callback(err.response.data);
      });
  };
};

export const alteraUsuarioByUUID = (uuid_user, dados, callback) => {
  return (dispatch) => {
    api
      .put(`usuario/adm/editar/${uuid_user}`, dados)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { mensagem: res.data.MENSAGEM },
        });
        if (callback) callback(res.data);
      })
      .catch((err) => {
        dispatch({ type: DEFAULT_TYPE, payload: { erro: true } });
        if (callback) callback(err.response.data);
      });
  };
};

export const pegarRiscoUsuario = (callback) => {
  return (dispatch) => {
    api
      .get(`analise/risco-usuario`)
      .then(({ data }) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: {
            risco: data,
          },
        });
        if (callback) callback(data);
      })
      .catch((err) => {
        dispatch({ type: DEFAULT_TYPE, payload: { erro: true } });
        if (callback) callback(err.response.data);
      });
  };
};

export const alterarRiscoUsuario = (dados, CPFCNPJ, callback) => {
  return (dispatch) => {
    api
      .put(`analise/risco-usuario?CPFCNPJ_CLIENT=${CPFCNPJ}`, dados)
      .then(({ data }) => {
        dispatch({
          type: DEFAULT_TYPE,
        });
        if (callback) callback(data);
      })
      .catch((err) => {
        dispatch({ type: DEFAULT_TYPE, payload: { erro: true } });
        if (callback) callback(err.response.data);
      });
  };
};

export const alteraRepresentante = (dados, callback) => {
  return (dispatch) => {
    api
      .post(`usuario/representante_atualizar`, dados)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { mensagem: res.data.MENSAGEM },
        });
        if (callback) callback(res.data);
      })
      .catch((err) => {
        dispatch({ type: DEFAULT_TYPE, payload: { erro: true } });
        if (callback) callback(err.response.data);
      });
  };
};

export const buscaCep = (cep, callback) => {
  const CEP = cep;

  return (dispatch) => {
    api
      .get(`/cep/buscar?CEP=` + CEP)
      .then((res) => {
        dispatch({ type: DEFAULT_TYPE, payload: { cep: res.data } });
        if (callback) callback(true, res);
      })
      .catch((err) => {
        dispatch({ type: DEFAULT_TYPE, payload: { dado: {} } });
        // if (callback)
        //   callback(false, err)
      });
  };
};

export const vincularCartaoUser = (dados, callback) => {
  return (dispatch) => {
    api
      .post(`usuario/vincular_cartao`, dados)
      .then((res) => {
        dispatch({
          type: USERS_RESPONSE,
          payload: { mensagem: res.data.MENSAGEM },
        });
        if (callback) callback(res);
      })
      .catch((err) => {
        if (err.response.status != 200) {
          dispatch({
            type: USERS_RESPONSE,
            payload: { mensagem: err.response.data.mensagem, sucesso: false },
          });
        }
        const res = err.response ? err.response.data : { mensagem: err.response.data.mensagem };
        callback(res);
      });
  };
};

export const revertUser = (cpfcnpjUser, callback) => {
  return (dispatch) => {
    api
      .post('/usuario/pendente', cpfcnpjUser)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { mensagem: res.data.mensagem },
        });
        if (callback) callback(true, res);
        return;
      })
      .catch((err) => {
        callback({
          mensagem: err.response.data.mensagem,
          retorno: 'erro',
          erro: true,
        });
        return;
      });
  };
};

export const listarDocumentos = (callback) => {
  return (dispatch) => {
    api
      .get(`coban/listar/docs`)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { mensagem: res.data.MENSAGEM, typeDoc: res.data.data },
        });
        if (callback) callback(true, res);
      })
      .catch((err) => {
        if (err.response.status != 200) {
          dispatch({
            type: DEFAULT_TYPE,
            payload: { mensagem: err.response.data.mensagem, sucesso: false },
          });
        }
        const res = err.response ? err.response.data : { mensagem: err.response.data.mensagem };
        callback(false, res);
      });
  };
};

export const enviarDocumento = (dados, cpf_cnpj, id_tipo_usuario, callback) => {
  var arrayDoc = {
    CPFCNPJ_USUARIO: cpf_cnpj,
    ID_TIPO_USUARIO: +id_tipo_usuario,
    DOCUMENTOS: [dados],
  };

  return (dispatch) => {
    api
      .post(`coban/inserir/documento`, arrayDoc)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { mensagem: res.data.MENSAGEM },
        });
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        if (err.response.status != 200) {
          dispatch({
            type: DEFAULT_TYPE,
            payload: { mensagem: err.response.data.mensagem, sucesso: false },
          });
        }
        const res = err.response ? err.response.data : { mensagem: err.response.data.mensagem };
        callback(res);
      });
  };
};

export const enviarDocumentoByUUID = (dados, uuid, callback) => {
  var arrayDoc = {
    DOCUMENTOS: [dados],
  };

  return (dispatch) => {
    api
      .post(`coban/inserir/documento/${uuid}`, arrayDoc)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { mensagem: res.data.MENSAGEM },
        });
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        if (err.response.status != 200) {
          dispatch({
            type: DEFAULT_TYPE,
            payload: { mensagem: err.response.data.mensagem, sucesso: false },
          });
        }
        const res = err.response ? err.response.data : { mensagem: err.response.data.mensagem };
        callback(res);
      });
  };
};
export const recuperaSenhaUsuario = (dados, callback) => {
  return (dispatch) => {
    dispatch({ type: DEFAULT_TYPE, payload: { loadingMensage: true } });
    api
      .post('/usuario/recuperar/senha', dados)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: {
            mensageModal: res.data.mensagem,
            loadingMensage: false,
            successModal: true,
          },
        });
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: {
            mensageModal: err.response.data.mensagem,
            sucesso: false,
            loadingMensage: false,
            successModal: false,
          },
        });

        if (callback) {
          callback(err.response.data);
        }
      });
  };
};

export const recuperaSenhaUsuarioByUUID = (uuid, callback) => {
  return (dispatch) => {
    dispatch({ type: DEFAULT_TYPE, payload: { loadingMensage: true } });
    api
      .post(`/usuario/recuperar/senha/${uuid}`)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: {
            mensageModal: res.data.mensagem,
            loadingMensage: false,
            successModal: true,
          },
        });
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: {
            mensageModal: err.response.data.mensagem,
            sucesso: false,
            loadingMensage: false,
            successModal: false,
          },
        });

        if (callback) {
          callback(err.response.data);
        }
      });
  };
};

export const taxaCertificar = (dados, callback) => {
  return (dispatch) => {
    api
      .post(`/usuario/taxa/taxa_qrcode_usuario`, dados)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { mensagem: res.data.MENSAGEM },
        });
        if (callback) {
          callback(true, res);
        }
      })
      .catch((err) => {
        if (err.response.status != 200) {
          dispatch({
            type: DEFAULT_TYPE,
            payload: { mensagem: err.response.data.mensagem, sucesso: false },
          });
        }
      });
  };
};

export const taxaExibir = (cpfcnpj, callback) => {
  return (dispatch) => {
    api
      .get(`/usuario/taxa/selecionar_taxa_qrcode?CPFCNPJUSUARIO=${cpfcnpj}`)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { taxa_qr_code: res.data.taxa_qrcode },
        });
        if (callback) {
          callback(true, res);
        }
      })
      .catch((err) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { taxa_qr_code: err.response.data.taxa_qr_code },
        });
        if (callback) {
          callback(false, err.response.data);
        }
      });
  };
};

export const taxaAtualizar = (cpfcnpj, valor, callback) => {
  const obj = {
    CPFCNPJUSUARIO: cpfcnpj,
    VALOR: valor,
  };

  return (dispatch) => {
    api
      .post('/usuario/taxa/atualizar_taxa_qrcode_usuario', obj)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { taxa_qr_code: res.data.taxa_qrcode },
        });
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { taxa_qr_code: err.response.data.taxa_qr_code },
        });
        if (callback) {
          callback(false, err.response.data);
        }
      });
  };
};

export const detalhesUsuarioNegadoByUUID = (UUID) => {
  return async (dispatch) => {
    try {
      const { data } = await api.get(`usuario/detalhes_negado/${UUID}`);
      dispatch({
        type: USERS_RESPONSE,
        payload: {
          mensagem: data.mensagem,
          detalheUserNegado: data.reprovacao,
        },
      });
    } catch (error) {
      dispatch({
        type: DEFAULT_TYPE,
        payload: { erro: true, mensagem: error.response.data.mensagem },
      });
    }
  };
};

export const detalhesUsuarioNegado = (cpfcnpj) => {
  const params = {
    CPFCNPJ_USUARIO: cpfcnpj,
  };
  return async (dispatch) => {
    try {
      const { data } = await api.post(`usuario/detalhes_negado`, params);
      dispatch({
        type: USERS_RESPONSE,
        payload: {
          mensagem: data.mensagem,
          detalheUserNegado: data.detalhes_negado,
        },
      });
    } catch (error) {
      dispatch({
        type: DEFAULT_TYPE,
        payload: { erro: true, mensagem: error.response.data.mensagem },
      });
    }
  };
};
export const detalhesUsuarioBloqueado = (cpfcnpj) => {
  const params = {
    CPFCNPJ_USUARIO: cpfcnpj,
  };
  return async (dispatch) => {
    try {
      const { data } = await api.post(`usuario/detalhes_bloqueado `, params);
      dispatch({
        type: USERS_RESPONSE,
        payload: {
          mensagem: data.mensagem,
          detalheUserBloqueado: data.detalhes_bloqueado,
        },
      });
    } catch (error) {
      dispatch({
        type: DEFAULT_TYPE,
        payload: { erro: true, mensagem: error.response.data.mensagem },
      });
    }
  };
};

export const detalhesUsuarioBloqueadoByUUID = (uuid) => {
  return async (dispatch) => {
    try {
      const { data } = await api.post(`usuario/detalhes_bloqueado/${uuid}`);
      dispatch({
        type: USERS_RESPONSE,
        payload: {
          mensagem: data.mensagem,
          detalheUserBloqueado: data.detalhes_bloqueado,
        },
      });
    } catch (error) {
      dispatch({
        type: DEFAULT_TYPE,
        payload: { erro: true, mensagem: error.response.data.mensagem },
      });
    }
  };
};
export const detalhesSolicitacaoDigital = (cpfcnpj) => {
  const params = {
    CPFCNPJ_USUARIO: cpfcnpj,
  };
  return async (dispatch) => {
    try {
      const { data } = await api.post(`usuario/detalhes/solicitacao_contadigital_usuario`, params);
      dispatch({
        type: USERS_RESPONSE,
        payload: {
          mensagem: data.mensagem,
          solicitacaoContaDigital: data.detalhes_solicitacao,
        },
      });
    } catch (error) {
      dispatch({
        type: DEFAULT_TYPE,
        payload: { erro: true, mensagem: error.response.data.mensagem },
      });
    }
  };
};

export const segundaViaPerdaCartao = (params, callback) => {
  return async (dispatch) => {
    try {
      const { data } = await api.post(`/cartao/segunda_via/perda`, params);
      dispatch({
        type: USERS_RESPONSE,
        payload: {
          mensagem: data.mensagem,
        },
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: DEFAULT_TYPE,
        payload: { erro: true, mensagem: error.response.data.mensagem },
      });
      callback(error);
    }
  };
};

export const segundaViaCartaoExpirado = (params, callback) => {
  return async (dispatch) => {
    try {
      const { data } = await api.post(`/cartao/segunda_via/expirado`, params);

      dispatch({
        type: USERS_RESPONSE,
        payload: {
          mensagem: data.mensagem,
        },
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: DEFAULT_TYPE,
        payload: { erro: true, mensagem: error.response.data.mensagem },
      });
      callback(error);
    }
  };
};

export const criarContaDigital = (cpfcnpjUser, callback) => {
  return (dispatch) => {
    api
      .post('banco-parceiro/criar/conta', cpfcnpjUser)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { mensagem: res.data.mensagem },
        });
        if (callback) callback(true, res);
        return;
      })
      .catch((err) => {
        callback({
          mensagem: err.response.data.mensagem,
          retorno: 'erro',
          erro: true,
        });
        return;
      });
  };
};

export const solicitarCartaoSwap = (params, callback) => {
  return (dispatch) => {
    api
      .post('/cartao/criar/cartao', params)
      .then((res) => {
        dispatch({
          type: DEFAULT_TYPE,
          payload: { mensagem: res.data.mensagem },
        });

        if (callback) callback(res.data);
        return;
      })
      .catch((err) => {
        callback({
          mensagem: err.response.data.mensagem,
          retorno: 'erro',
          erro: true,
        });
        return;
      });
  };
};

export const setStatusContaDigital = (id) => {
  return (dispatch) => {
    dispatch({
      type: DEFAULT_TYPE,
      payload: { idStatusContaDigital: id },
    });
  };
};

export const solicitarCartaoUsuario = (params, callback = () => null) => {
  return async (dispatch) => {
    try {
      const { data } = await api.post(`cartao/criar/cartao`, params);
      dispatch({
        type: USERS_RESPONSE,
        payload: { mensagem: data.mensagem },
      });
      callback(data);
    } catch (error) {
      const { payload } = error.response.data;
      callback(error.response.data);
    }
  };
};

export const finishUser = (params, callback = () => null) => {
  return async (dispatch) => {
    try {
      const { data } = await api.post(`/cliente/encerrar-conta-digital`, params);
      dispatch({
        type: USERS_RESPONSE,
        payload: { mensagem: data.mensagem },
      });
      callback(data);
    } catch (error) {
      const { payload } = error.response.data;
      callback(error.response.data);
    }
  };
};

export const getContractZip = (UUID_CONTRATO, CPFCNPJ) => {
  return async () => {
    api
      .get(`contrato/assinatura/baixar?UUID=${UUID_CONTRATO}&DOWNLOAD=S`, { responseType: 'blob' })
      .then(function (response) {
        let fileName = `contrato-${CPFCNPJ}-${dayjs().format('DD/MM/YYYY')}.zip`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data], {
              type: 'octet/stream',
            }),
            fileName,
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: 'octet/stream',
            }),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        }
      });
  };
};

export const getUsersExcel = (params) => {
  return async () => {
    api.post(`usuario/exportar`, params, { responseType: 'blob' }).then(function (response) {
      let fileName = `relatorio_usuarios${dayjs().format('DD/MM/YYYY')}.xls`;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(
          new Blob([response.data], {
            type: 'application/vnd.ms-excel',
          }),
          fileName,
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/vnd.ms-excel',
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }
    });
  };
};

export const solicitarContaDigital = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: CONTADIGITAL_RESPONSE,
      loadingContaDigital: true,
    });
    try {
      const response = await api.post('/usuario/aprovar-conta-digital', params);

      dispatch({
        type: CONTADIGITAL_RESPONSE,
        payload: response.data,
      });
      callback(response.data);
    } catch (error) {
      dispatch({
        type: CONTADIGITAL_RESPONSE,
        payload: error.response.data,
      });
      callback(error.response.data);
    }
  };
};

export const solicitarContaDigitalByUUID = (uuid, params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: CONTADIGITAL_RESPONSE,
      loadingContaDigital: true,
    });
    try {
      const response = await api.post(`/usuario/aprovar-conta-digital/${uuid}`, params);

      dispatch({
        type: CONTADIGITAL_RESPONSE,
        payload: response.data,
      });
      callback(response.data);
    } catch (error) {
      dispatch({
        type: CONTADIGITAL_RESPONSE,
        payload: error.response.data,
      });
      callback(error.response.data);
    }
  };
};

export const atualizaSolicitacaoCartao = (uuid, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: SOLICITACAO_CARTAO_RESPONSE,
      loadingSolicitacaoCartao: true,
    });
    try {
      const response = await api.get(`/cartao/recuperar_dados_cartao/${uuid}`);

      dispatch({
        type: SOLICITACAO_CARTAO_RESPONSE,
        payload: response.data,
        loadingSolicitacaoCartao: false,
      });
      callback(response.data);
    } catch (error) {
      dispatch({
        type: SOLICITACAO_CARTAO_RESPONSE,
        payload: error.response.data,
        loadingSolicitacaoCartao: false,
      });
      callback(error.response.data);
    }
  };
};

export const solicitarTrocaSenha = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: SOLICITAR_TROCA_SENHA_RESPONSE,
      loadingSolicitarTrocaSenha: true,
    });
    try {
      const response = await api.post(`/cartao/alterar_senha_cartao`, params);

      dispatch({
        type: SOLICITAR_TROCA_SENHA_RESPONSE,
        payload: response.data,
        loadingSolicitarTrocaSenha: false,
      });
      callback(response.data);
    } catch (error) {
      dispatch({
        type: SOLICITAR_TROCA_SENHA_RESPONSE,
        payload: error.response.data,
        loadingSolicitarTrocaSenha: false,
      });
      callback(error.response.data);
    }
  };
};

export const solicitarTrocaSenhaByUUID = (uuid, params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: SOLICITAR_TROCA_SENHA_RESPONSE,
      loadingSolicitarTrocaSenha: true,
    });
    try {
      const response = await api.post(`/cartao/alterar_senha_cartao/${uuid}`, params);

      dispatch({
        type: SOLICITAR_TROCA_SENHA_RESPONSE,
        payload: response.data,
        loadingSolicitarTrocaSenha: false,
      });
      callback(response.data);
    } catch (error) {
      dispatch({
        type: SOLICITAR_TROCA_SENHA_RESPONSE,
        payload: error.response.data,
        loadingSolicitarTrocaSenha: false,
      });
      callback(error.response.data);
    }
  };
};

export const adicionarConveniadaProduto = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: DEFAULT_TYPE });
    try {
      const { data } = await api.post(`conveniada/vincula-produto`, {
        PRODUTOS: params,
      });
      callback(data);
    } catch (err) {
      callback(err);
    }
  };
};

export const adicionarConveniadaProdutoEmLote = (CPFCNPJ, CODPRODUTO, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: DEFAULT_TYPE });
    try {
      const { data } = await api.post(`conveniada/vincula-produto/lote`, {
        CPFCNPJ_CLIENTE: CPFCNPJ,
        CODPRODUTO,
      });
      callback(data);
    } catch (err) {
      callback(err);
    }
  };
};

export const kycUserLog = (UUID, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: DEFAULT_TYPE });
    try {
      const { data } = await api.get(`usuario/kyc/${UUID}`);
      callback(data);
    } catch (err) {
      callback(err);
    }
  };
};

export const msBaasAccount = (body, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: DEFAULT_TYPE });
    try {
      const { data } = await api.post(`ms-baas/account`, body);
      callback(data);
    } catch (err) {
      callback(err);
    }
  };
};

export const msBaasReceiverBoleto = (body, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: DEFAULT_TYPE });
    try {
      const { data } = await api.post(`/ms-baas/boleto`, body);
      callback(data);
    } catch (err) {
      callback(err);
    }
  };
};

export const msBaasStatusAccount = ({ UUID }, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: DEFAULT_TYPE });
    try {
      const { data } = await api.put(`ms-baas/status_account/${UUID}`);
      callback(data);
    } catch (err) {
      callback(err);
    }
  };
};

export const msBaasApproveCardUser = (body, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: DEFAULT_TYPE });
    try {
      const { data } = await api.post('ms-baas/approve_account', body);
      callback(data);
    } catch (err) {
      callback(err);
    }
  };
};

export const editRepresentantes = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: EDITAR_REPRESENTANTES_RESPONSE,
      loadingEditRepresentantes: true,
    });
    try {
      const response = await api.post(`webpj/usuario/representante/atualizar`, params);

      dispatch({
        type: EDITAR_REPRESENTANTES_RESPONSE,
        payload: response.data,
        loadingEditRepresentantes: false,
      });
      callback(response.data);
    } catch (error) {
      dispatch({
        type: EDITAR_REPRESENTANTES_RESPONSE,
        payload: error.response.data,
        loadingEditRepresentantes: false,
      });
      callback(error.response.data);
    }
  };
};

export const liberarAcessoDispositivo = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: ACESSO_DISPOSITIVO_RESPONSE,
      loadingAcessoDispositivo: true,
    });
    try {
      const response = await api.post(`usuario/atualizarID`, params);

      dispatch({
        type: ACESSO_DISPOSITIVO_RESPONSE,
        payload: response.data,
        loadingAcessoDispositivo: false,
      });
      callback(response.data);
    } catch (error) {
      dispatch({
        type: ACESSO_DISPOSITIVO_RESPONSE,
        payload: error.response.data,
        loadingAcessoDispositivo: false,
      });
      callback(error.response.data);
    }
  };
};

export const liberarAcessoDispositivoByUUID = (uuid, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: ACESSO_DISPOSITIVO_RESPONSE,
      loadingAcessoDispositivo: true,
    });
    try {
      const response = await api.post(`usuario/atualizarID/${uuid}`);

      dispatch({
        type: ACESSO_DISPOSITIVO_RESPONSE,
        payload: response.data,
        loadingAcessoDispositivo: false,
      });
      callback(response.data);
    } catch (error) {
      dispatch({
        type: ACESSO_DISPOSITIVO_RESPONSE,
        payload: error.response.data,
        loadingAcessoDispositivo: false,
      });
      callback(error.response.data);
    }
  };
};

export const updateStatusUsuario = ({ status, motivo, cpfcnpj }, callback = () => null) => {
  return async (dispatch) => {
    api
      .put(
        '/usuario/adm/editar/status',
        {
          STATUS: status,
          MOTIVO: motivo,
          CPFCNPJ_CLIENTE: cpfcnpj,
        },
        {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      )
      .then(({ data }) => {
        if (data.retorno == 'sucesso') {
          callback(true, data.mensagem);
        } else {
          callback(false, data.mensagem);
        }
      });
  };
};

export const updateStatusUsuarioByUUID = (uuid, { status, motivo }, callback = () => null) => {
  return async (dispatch) => {
    api
      .put(
        `/usuario/adm/editar/status/${uuid}`,
        {
          STATUS: status,
          MOTIVO: motivo,
        },
        {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      )
      .then(({ data }) => {
        if (data.retorno == 'sucesso') {
          callback(true, data.mensagem);
        } else {
          callback(false, data.mensagem);
        }
      });
  };
};

export const statusUsuario = (callback = () => null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_STATUS_USUARIO,
        loadingStatusUsuario: true,
      });

      api.get('/usuario/status_usuario').then(({ data }) => {
        dispatch({
          type: GET_STATUS_USUARIO,
          payload: {
            status_usuario: data.status_usuario,
          },
          loadingStatusUsuario: false,
        });
      });
    } catch (e) {
      dispatch({
        type: GET_STATUS_USUARIO,
        loadingStatusUsuario: false,
      });
    }
  };
};

export const LogStatusUsuario = (cpfcnpj, callback = () => null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_STATUS_USUARIO,
        loadingStatusUsuario: true,
      });

      api.get(`/usuario/buscar/status-usuario?CPFCNPJ_CLIENTE=${cpfcnpj}`).then(({ data }) => {
        dispatch({
          type: GET_STATUS_USUARIO,
          payload: {
            motivo: data.data.motivo,
          },
          loadingStatusUsuario: false,
        });
      });
    } catch (e) {
      dispatch({
        type: GET_STATUS_USUARIO,
        loadingStatusUsuario: false,
      });
    }
  };
};

export const exportarExcelCartoesProduto = (CPFCNPJ, CODPRODUTO, callback = () => null) => {
  return async () => {
    api
      .get(`/conveniada/exportar_csv_produto?CPFCNPJ_CLIENTE=${CPFCNPJ}&CODPRODUTO=${CODPRODUTO}`, {
        responseType: 'blob',
      })
      .then(function (response) {
        callback(response.data);

        let fileName = `Preenchimento-de-carga-${dayjs().format('DD/MM/YYYY')}.xls`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            }),
            fileName,
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            }),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        }
      });
  };
};

export const exportarExcelCartoesProdutoByUUID = (UUID, CODPRODUTO, callback = () => null) => {
  return async () => {
    api
      .get(`/conveniada/exportar_csv_produto/${UUID}?CODPRODUTO=${CODPRODUTO}`, {
        responseType: 'blob',
      })
      .then(function (response) {
        callback(response.data);

        let fileName = `Preenchimento-de-carga-${dayjs().format('DD/MM/YYYY')}.xls`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            }),
            fileName,
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            }),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        }
      });
  };
};

export const getKyc = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: KYC,
      payload: { loading_kyc: true },
    });

    try {
      const userKyc = {
        cpfcnpj_usuario: process.env.REACT_APP_KYC_LOGIN,
        senha: process.env.REACT_APP_KYC_SENHA,
      };

      const auth = await apiKYC.post('/kyc/login', JSON.stringify(userKyc));

      const response = await apiKYC.post('/kyc/packages/validate', JSON.stringify(params), {
        headers: {
          Authorization: 'Bearer ' + auth.data.token,
        },
      });

      const { data } = response;
      dispatch({
        type: KYC,
        payload: { consultas: data.consultas, riscoPLD: data.riscoPLD, loading_kyc: false },
      });
      callback(response.data);
    } catch (error) {
      dispatch({
        type: KYC,
        payload: 'Error',
        payload: { loading_kyc: false },
      });

      callback(error);
    }
  };
};

export const toggleModalSolicitarCartaoNominal = () => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_MODAL_SOLICITAR_CARTAO_NOMINAL,
      payload: null,
    });
  };
};

export function listarContasSaldo(DATA, callback = () => null) {
  return async (dispatch) => {
    dispatch({
      type: DEFAULT_TYPE,
      payload: {
        loading_user: true,
      },
    });
    try {
      const response = await api.get(`/contabil/saldo/usuario?DATA=${DATA}`);
      const { data } = response;

      if (data.retorno == 'sucesso') {
        dispatch({
          type: DEFAULT_TYPE,
          payload: {
            loading_user: false,
          },
        });
        callback(data.data);
      }
    } catch (error) {
      dispatch({
        type: DEFAULT_TYPE,
        payload: {
          loading_user: false,
        },
      });

      callback(error);
    }
  };
}
