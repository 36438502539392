import React, { useState } from 'react';

import { Icon } from '@mui/material';
import { useEffect } from 'react';
import { Form } from 'reactstrap';
import GenericButton from '../../components/Buttons/Button';
import CustomContainer from '../../components/Custom/CustomContainer';
import InputSearch from '../../components/Filter/components/InputSearch';
import UsuariosRHTable from '../../components/UsuariosRHTable/UsuariosRHTable';
import api from '../../service/api';
import './styles.css';
import { Box } from '@material-ui/core';
function UsuariosRH(props) {
  const [users, setUsers] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    name: '',
    email: '',
    company_name: '',
  });

  function getUsersRH() {
    setLoading(true);
    api
      .get(`/conveniada/users-portal-rh?name=${filter.name}&email=${filter.email}&company_name=${filter.company_name}`)
      .then(({ data }) => {
        setUsers(data.rh_response.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }

  useEffect(() => {
    getUsersRH();
  }, []);

  function submitForm(e) {
    e.preventDefault();
    getUsersRH();
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'flex-end',
        gap: '1rem',
        marginTop: '1rem',
      }}
    >
      <Form className="form" onSubmit={(e) => submitForm(e)}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
          <div style={{ marginRight: '1rem' }}>
            {' '}
            <InputSearch
              setValue={(value) => setFilter({ ...filter, name: value })}
              value={filter.name}
              placeholder="Nome colaborador"
              label={'Nome colaborador'}
              width="15rem"
            />
          </div>
          <div style={{ marginRight: '1rem' }}>
            <InputSearch
              setValue={(value) => setFilter({ ...filter, email: value })}
              value={filter.email}
              placeholder="E-mail colaborador"
              label={'E-mail colaborador'}
              width="15rem"
            />
          </div>
          <div style={{ marginRight: '1rem' }}>
            <InputSearch
              setValue={(value) => setFilter({ ...filter, company_name: value })}
              value={filter.company_name}
              placeholder="Nome da empresa"
              label={'Nome da empresa'}
              width="15rem"
            />
          </div>
          <div style={{ alignSelf: 'flex-end' }}>
            <GenericButton onClick={() => getUsersRH()} height="30px" className="mt-3" size="sm" type="submit">
              <Icon style={{ marginRight: '.4rem', fontSize: '1rem' }} className="fas fa-search"></Icon>Buscar
            </GenericButton>
          </div>
        </div>
      </Form>

      <UsuariosRHTable users={users} loading={loading} getUsersRH={getUsersRH} />
    </Box>
  );
}

export default UsuariosRH;
