import React from 'react';
import { Component } from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter, Input, Row, Col, UncontrolledAlert } from 'reactstrap';
import { connect } from 'react-redux';
import { updateAntecipation } from '../../../store/transactions/actions';
import Table from '../../../components/Table/Table';
import ReactCSSTransitionGroup from 'react-transition-group';

class ModalAntecipacao extends Component {
  state = {
    IDANTECIPACAO: this.props.IDANTECIPACAO,
    APROVAR: this.props.selecionados,
    REPROVAR: [],
  };

  handle(e, index) {
    this.props.naoSelecionados[index]['MOTIVO'] = e.target.value;
    this.setState({ REPROVAR: this.props.naoSelecionados });
  }

  clickButton() {
    const obj = {
      IDANTECIPACAO: this.props.IDANTECIPACAO,
      APROVAR: this.props.selecionados,
      REPROVAR: this.state.REPROVAR,
    };
    this.props.updateAntecipation(obj, (response, sucesso) => {
      alert(response.APROVADO.message, response.REPROVADO.message);
      window.location.reload();
    });
  }

  close() {
    this.state.modal == true ? this.setState({ modal: false }) : this.setState({ modal: true });
  }

  render() {
    const { modal, toggle, selecionados, naoSelecionados, IDANTECIPACAO, fechar } = this.props;

    const inputReprovados = () => {
      const inputs =
        naoSelecionados &&
        naoSelecionados.map((item, i) => (
          <Row>
            <p className="ml-2"> Porque não escolheu a venda {item.IDVENDA}?</p>
            <Input
              style={{ marginBottom: 10 }}
              id={item.IDVENDA}
              onChange={(e) => this.handle(e, i)}
              placeholder={'Motivo: '}
            ></Input>
          </Row>
        ));

      return inputs;
    };

    return (
      <Modal
        isOpen={modal}
        toggle={fechar}
        selecionados={selecionados}
        naoSelecionados={naoSelecionados}
        IDANTECIPACAO={IDANTECIPACAO}
      >
        <ModalHeader style={styles.Card} toggle={fechar}>
          <h3>Confirmação</h3>
        </ModalHeader>

        <ModalBody
          style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', wordBreak: 'break-word' }}
        >
          <h4 style={{ marginBottom: 10 }}>
            Você escolheu {selecionados && selecionados.length} das opções de vendas.
          </h4>
          {inputReprovados()}
        </ModalBody>

        <ModalFooter style={{ display: 'flex', alignItems: 'baseline' }}>
          <Button
            onClick={() => {
              this.clickButton();
              fechar();
            }}
            color="danger"
            size="sm"
            block
          >
            Confirmar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const styles = {
  Card: { border: '1px solid #f0f0f0', borderRadius: 10, marginBottom: 15 },

  metade: { width: '49%' },
  cheio: { width: '100%', marginTop: 15 },
};

const mapStateToProps = ({ user, riskInfos }) => {
  return { user, riskInfos };
};

export default connect(mapStateToProps, { updateAntecipation })(ModalAntecipacao);
