import React from 'react';
import { FaDollarSign, FaRegMoneyBillAlt, FaUser } from 'react-icons/fa';
import {
  PreviewAsideButtons,
  PreviewSecundaryButton,
  PreviewCircleIcon,
  PreviewTextDiv,
  PreviewBody,
  PreviewCardBalance,
  PreviewCardHeader,
  PreviewCardText,
  PreviewCardsAndTableDiv,
  PreviewCardsDiv,
  PreviewComponent,
  PreviewHeader,
  PreviewIcon,
  PreviewIconBalance,
  PreviewPreHeader,
  PreviewTables,
  TableHead,
  TextPreview,
  PreviewPostButton,
} from './PeviewWhiteLabelStyled';
import LogoSvg from '../LogoSvg/LogoSvg';
import { BsCreditCard, BsPencilSquare, BsPeople } from 'react-icons/bs';

import { ImFileText2 } from 'react-icons/im';
import { TfiHome } from 'react-icons/tfi';
import { IoIdCardOutline } from 'react-icons/io5';

export const PreviewWhiteLabel = ({ colors, logo }) => {
  return (
    <PreviewComponent>
      <PreviewPreHeader>
        {/*   {logo ? <img height={'50px'} width={'50px'} src={logo} /> : <LogoSvg color={'blue'} />} */}
      </PreviewPreHeader>
      <PreviewHeader {...colors}>
        <PreviewCardHeader {...colors}>
          <PreviewIcon {...colors}>
            <TfiHome />
          </PreviewIcon>
          <TextPreview {...colors}>Dasboard</TextPreview>
        </PreviewCardHeader>

        <PreviewCardHeader {...colors}>
          <PreviewIcon {...colors}>
            <BsPeople />
          </PreviewIcon>
          <TextPreview {...colors}>Colaboradores</TextPreview>
        </PreviewCardHeader>

        <PreviewCardHeader {...colors}>
          <PreviewIcon {...colors}>
            <BsPencilSquare />
          </PreviewIcon>
          <TextPreview {...colors}>Pedidos</TextPreview>
        </PreviewCardHeader>

        <PreviewCardHeader {...colors}>
          <PreviewIcon {...colors}>
            <IoIdCardOutline />
          </PreviewIcon>
          <TextPreview {...colors}>Benefícios</TextPreview>
        </PreviewCardHeader>

        <PreviewCardHeader {...colors}>
          <PreviewIcon {...colors}>
            <BsCreditCard />
          </PreviewIcon>
          <TextPreview {...colors}>Cartões</TextPreview>
        </PreviewCardHeader>

        <PreviewCardHeader {...colors}>
          <PreviewIcon {...colors}>
            <ImFileText2 />
          </PreviewIcon>
          <TextPreview {...colors}>Extrato</TextPreview>
        </PreviewCardHeader>

        <PreviewCardHeader {...colors}>
          <PreviewIcon {...colors}>
            <FaDollarSign />
          </PreviewIcon>
          <TextPreview {...colors}>Consignado</TextPreview>
        </PreviewCardHeader>
      </PreviewHeader>
      <PreviewBody>
        <PreviewCardsAndTableDiv>
          <PreviewCardsDiv>
            <PreviewCardBalance {...colors}>
              <PreviewCardText>
                <PreviewTextDiv {...colors}>
                  <h3>Saldo Vinculado</h3>
                  <span>R$ 97.666,99</span>
                </PreviewTextDiv>
                <PreviewTextDiv {...colors}>
                  <h3>Saldo Total</h3>
                  <span>R$ 97.666,99</span>
                </PreviewTextDiv>
              </PreviewCardText>

              <PreviewIconBalance {...colors}>
                <PreviewCircleIcon {...colors}>
                  <FaRegMoneyBillAlt />
                </PreviewCircleIcon>
              </PreviewIconBalance>
            </PreviewCardBalance>

            <PreviewCardBalance>
              <PreviewCardText {...colors}>
                <PreviewTextDiv {...colors}>
                  <h3>Colaboradores ativos</h3>
                  <span>49</span>
                </PreviewTextDiv>
              </PreviewCardText>

              <PreviewIconBalance {...colors}>
                <PreviewCircleIcon {...colors}>
                  <FaUser />
                </PreviewCircleIcon>
              </PreviewIconBalance>
            </PreviewCardBalance>
          </PreviewCardsDiv>

          <PreviewTables {...colors}>
            <table>
              <TableHead {...colors}>
                <tr>
                  <th>Nome</th>
                  <th>CPF</th>
                  <th>Departamento</th>
                </tr>
              </TableHead>
              <tbody>
                <tr>
                  <th>José Melo</th>
                  <th>167.782.852-81</th>
                  <th>Entregas</th>
                </tr>
                <tr>
                  <th>José Melo</th>
                  <th>167.782.852-81</th>
                  <th>Entregas</th>
                </tr>
                <tr>
                  <th>José Melo</th>
                  <th>167.782.852-81</th>
                  <th>Entregas</th>
                </tr>
              </tbody>
            </table>
          </PreviewTables>
        </PreviewCardsAndTableDiv>

        <PreviewAsideButtons {...colors}>
          <h4>Botão primário</h4>
          <PreviewPostButton {...colors}>Enviar</PreviewPostButton>
          <h4>Botão secundário</h4>
          <PreviewSecundaryButton {...colors}>Exportar</PreviewSecundaryButton>
        </PreviewAsideButtons>
      </PreviewBody>
    </PreviewComponent>
  );
};
