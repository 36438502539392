import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loading from '../../../../components/Loader/Loading';
import ModalMessageGeneric from '../../../../components/Modais/Mensagem/ModalMessageGeneric';
import api from '../../../../service/api';
import { ButtonTransparent, DivCheckbox, DivTitleContent, SpanTitle } from './styled';
import { IOSSwitch } from './toggle';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { unformatDateKyc } from '../../../../utils/dateKyc';
import { useDispatch, useSelector } from 'react-redux';
import { modificarConveniada } from '../../../../store/coban/actions';
import { IoClose } from 'react-icons/io5';

export function ModalEdit({ open, toggle, user, setReload, reload }) {
  const [loading, setLoading] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [response, setResponse] = useState(false);
  const [checkedBoleto, setCheckedBoleto] = useState(true);
  const [checkedPartner, setCheckedPartner] = useState(false);
  const [perfilLogicHandler, setPerfilLogicHandler] = React.useState({
    RH: true,
    Frota: false,
  });
  const dispatch = useDispatch();
  const [perfil, setPerfil] = useState('1');
  const [tipoConta, setTipoConta] = useState([]);
  const { CONVENED_RESPONSE } = useSelector((state) => state.coban);
  const [stateHandleChecked, setStateHandleChecked] = useState(false);
  const [stateHandleConvenied, setStateHandleConvenied] = useState(false);
  const [stateHandleBoleto, setStateHandleBoleto] = useState(false);
  const [stateHandlePerfil, setStateHandlePerfil] = useState(false);

  useEffect(() => {
    user && setCheckedPartner(user.CONVENIADA == 'S' ? true : false);
    user && setCheckedBoleto(user.PAGAMENTO_BOLETO == 'N' ? false : true);
    user && setTipoConta(user.TIPOS_CONTA);
    // falta o tipo conta
  }, [user]);

  const handleChangeCheckedBoleto = (event) => {
    setStateHandleBoleto(true);
    setCheckedBoleto(event.target.checked);
  };

  const handleChangeCheckedPartner = (event) => {
    setCheckedPartner(event.target.checked);
    setStateHandleConvenied(true);
  };

  const handleChangePerfil = (event) => {
    if (event.target.name == 'Frota') {
      setPerfilLogicHandler({
        RH: false,
        Frota: true,
      });

      setPerfil('2');
      setStateHandlePerfil(true);
    }

    if (event.target.name == 'RH') {
      setPerfilLogicHandler({
        RH: true,
        Frota: false,
      });
      setPerfil('1');
      setStateHandlePerfil(true);
    }
  };

  function handleCheck(e) {
    if (!e.target.checked) {
      setTipoConta(tipoConta.filter((profile) => profile !== e.target.value));
    } else {
      setTipoConta([...tipoConta, e.target.value]);
    }

    setStateHandleChecked(true);
  }

  useEffect(() => {
    stateHandleBoleto && saveBoleto();
  }, [stateHandleBoleto]);

  function saveBoleto() {
    setStateHandleBoleto(false);
    setLoading(true);
    const boleto = checkedBoleto ? 'S' : 'N';
    const data = {
      PAGAMENTO_BOLETO: boleto,
      UUID: user.UUID,
    };
  
    api
      .put('/conveniada/pagamento-boleto', data)
      .then((res) => {
        setLoading(false);
        setResponse(res.data.retorno);
      
        setModalSuccess(true);
      })
      .catch((ERROR) => {
        setLoading(false);
       
        setResponse('Algo deu errado');
        setModalSuccess(true);
        setCheckedBoleto((an) => !an);
      });
  }

  useEffect(() => {
    stateHandleConvenied && saveConvenied();
  }, [stateHandleConvenied]);

  function saveConvenied() {
    setLoading(true);
    setStateHandleConvenied(false);
    const convenied = checkedPartner ? 'S' : 'N';
    const dados = {
      ID: user.ID,
      NOME: user.NOME,
      CPFCNPJ_CLIENTE: user.CPFCNPJ,
      DATA: unformatDateKyc(user.NASCIMENTO),
      EMAIL: user.EMAIL,
      FONECEL: user.FONECEL,
      CONVENIADA: convenied,
      CEP: user.CEP,
      ENDERECO: user.ENDERECO,
      NUMERO: user.NUMERO,
      COMPLEMENTO: user.COMPLEMENTO,
      BAIRRO: user.BAIRRO,
      CIDADE: user.CIDADE,
      UF: user.UF,
      CONTA: user.CONTA,
      AGENCIA: user.AGENCIA,
      UUID: user.UUID,
    };

    dispatch(modificarConveniada(dados)).then(() => {
      setResponse(CONVENED_RESPONSE);
     
      setLoading(false);
      setModalSuccess(true);
      !CONVENED_RESPONSE && CONVENED_RESPONSE != undefined && setCheckedPartner((an) => !an);
      /// verificar se esta soluçao nao vei quebrar -------------------------------------------------------
    });
  }

  useEffect(() => {
    stateHandlePerfil && saveTypePerfil();
  }, [stateHandlePerfil]);
  ////////////////////////////////////////////////////////

  function saveTypePerfil() {
    setStateHandlePerfil(false);
    setLoading(true);
    const dados = {
      ID: user.ID,
      NOME: user.NOME,
      CPFCNPJ_CLIENTE: user.CPFCNPJ,
      DATA: unformatDateKyc(user.NASCIMENTO),
      EMAIL: user.EMAIL,
      FONECEL: user.FONECEL,
      CONVENIADA: 'S', // verificar isto com cabelinho
      CEP: user.CEP,
      ENDERECO: user.ENDERECO,
      NUMERO: user.NUMERO,
      COMPLEMENTO: user.COMPLEMENTO,
      BAIRRO: user.BAIRRO,
      CIDADE: user.CIDADE,
      UF: user.UF,
      CONTA: user.CONTA,
      AGENCIA: user.AGENCIA,
      UUID: user.UUID,
      TIPO_EMPRESA: perfil, // esta chave nao existe no usuario
    };
    api
      .put('/conveniada/perfil', dados)
      .then(({ data }) => {
        if (data) {
          if (data.retorno) {
            setResponse(data.retorno);
            setLoading(false);
            setModalSuccess(true);
          } else setModalSuccess(true);
          setLoading(false);
        }
      })
      .catch(() => {
        setResponse('error');
        setModalSuccess(true);
        setLoading(false);
        setPerfil((an) => (an == '1' ? '2' : '1'));
        setPerfilLogicHandler((an) =>
          an.RH == true
            ? {
                RH: false,
                Frota: true,
              }
            : {
                RH: true,
                Frota: false,
              },
        );
      });
  }

  function close() {
    setReload(!reload);
    toggle();
    setModalSuccess(false);
  }

  function close() {
    setReload(!reload);
    setModalSuccess(false);
  }

  useEffect(() => {
    stateHandleChecked && saveAccountType(tipoConta);
  }, [stateHandleChecked]);

  function saveAccountType(tipo) {
    setStateHandleChecked(false);
    setLoading(true);
    const dados = {
      UUID: user.UUID,
      TIPOS: tipo,
    };
    api
      .post('/conveniada/alterar-tipo-conta', dados)
      .then(({ data }) => {
        if (data) {
          if (data.retorno) {
            setResponse(data.retorno);
            setLoading(false);
            setModalSuccess(true);
          } else {
            setModalSuccess(false);
            setLoading(false);
            setResponse('error');
            setModalSuccess(true);
          }
        }
      })
      .catch(() => {
        setResponse('error');
        setLoading(false);
        setModalSuccess(true);
        user && setTipoConta(user.TIPOS_CONTA);
      });
  }

  return (
    <Modal className="modal-lg" isOpen={open} toggle={toggle}>
      <Box
        bgcolor={'rgb(25, 118, 210)'}
        width={'100%'}
        height={'4rem'}
        borderRadius={'6px 6px 0 0'}
        pl={'2rem'}
        pr={'2rem'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box mt={'0.2rem'}>
          <span style={{ fontSize: '1rem', color: '#ffffff', lineHeight: '0.1' }}>
            Editar informaçoes de {user.NOME}
          </span>
        </Box>
        <Box>
          <ButtonTransparent type="button" onClick={() => toggle()}>
            <IoClose />
          </ButtonTransparent>
        </Box>
      </Box>

      {loading ? (
        <Loading color="blue" />
      ) : (
        <ModalBody>
          {CONVENED_RESPONSE ? (
            <ModalMessageGeneric open={modalSuccess} toggle={() => close()} retorno={CONVENED_RESPONSE} />
          ) : (
            <ModalMessageGeneric open={modalSuccess} toggle={() => close()} retorno={response} />
          )}
          <DivTitleContent>
            <Box display={'flex'} width={'100%'} pb={'0.5rem'}>
              <Box display={'flex'} flexDirection={'column'} width={'50%'}>
                <SpanTitle>Alterar conveniada</SpanTitle>
                <IOSSwitch sx={{ m: 1 }} checked={checkedPartner} onChange={handleChangeCheckedPartner} />
              </Box>
              <Box display={'flex'} flexDirection={'column'} width={'50%'}>
                <SpanTitle>Autorizar pagamento de boleto PWA</SpanTitle>
                <IOSSwitch sx={{ m: 1 }} checked={checkedBoleto} onChange={handleChangeCheckedBoleto} />
              </Box>
            </Box>
          </DivTitleContent>
          <DivTitleContent>
            <SpanTitle>Alterar tipo perfil </SpanTitle>
            <DivCheckbox>
              <FormControlLabel
                control={<Checkbox checked={perfilLogicHandler.RH} onChange={handleChangePerfil} name="RH" />}
                label="RH"
              />
              <FormControlLabel
                control={<Checkbox checked={perfilLogicHandler.Frota} onChange={handleChangePerfil} name="Frota" />}
                label="Frota"
              />
            </DivCheckbox>
          </DivTitleContent>

          <DivTitleContent>
            <SpanTitle>Alterar tipo conta</SpanTitle>
            <DivCheckbox>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tipoConta && tipoConta.some((item) => item == '1')}
                    value="1"
                    onChange={(e) => handleCheck(e)}
                    name="despesa"
                  />
                }
                label="Cartão despesa"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tipoConta && tipoConta.some((item) => item == '2')}
                    value="2"
                    onChange={(e) => handleCheck(e)}
                    name="beneficio"
                  />
                }
                label="Cartão benefício"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={tipoConta && tipoConta.some((item) => item == '3')}
                    value="3"
                    onChange={(e) => handleCheck(e)}
                    name="social"
                  />
                }
                label="Cartão social"
              />
            </DivCheckbox>
          </DivTitleContent>
        </ModalBody>
      )}
      <ModalFooter></ModalFooter>
    </Modal>
  );
}
