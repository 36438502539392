export function getCookie(cookieKey) {
  var cookies = ' ' + document.cookie;
  var key = ' ' + cookieKey + '=';
  var start = cookies.indexOf(key);

  if (start === -1) return null;

  var pos = start + key.length;
  var last = cookies.indexOf(';', pos);

  if (last !== -1) return cookies.substring(pos, last);

  return cookies.substring(pos);
}

export function setCookie(cookieKey, cookieValue, expira, path) {
  if (!path) path = '/';

  var d = new Date();
  d.setTime(d.getTime() + expira * 1000);

  document.cookie =
    encodeURIComponent(cookieKey) +
    '=' +
    encodeURIComponent(cookieValue) +
    '; expires=' +
    d.toUTCString() +
    '; path=' +
    path;
}
