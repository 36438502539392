import React, { useState } from 'react';
import { Input, FormGroup, Label, Form } from 'reactstrap';
import * as allReducers from '../../store';
import { connect } from 'react-redux';
import TextInputMask from 'react-masked-text';
import { addSTATE } from '../../store/globals';
import removeAllCharacters from '../../utils/removeAllCharacters';

const CustomForm = (props) => {
  const { data, dataLevels } = props;

  const [cpfCnpj, setCpfCnpj] = useState('');
  const [telefone, setTelefone] = useState('');

  const getTypeInput = (campo, index) => {
    const { name, type, required, maxlength, max } = campo;

    const changeValueCustomized = (value, state) => {
      const { variable, reducer, typeReducer } = props;

      if (reducer == undefined || !typeReducer) return '';
      if (variable) {
        var newVariable = props[reducer][variable];
        newVariable[state] = value;
        props.addSTATE(typeReducer, state, newVariable);
      } else props.addSTATE(typeReducer, state, removeAllCharacters(value));

      if (state === 'FONECEL') {
        setTelefone(value);
        return;
      }
      setCpfCnpj(value);
    };

    const validateKeyDown = (name, e) => {
      if (name === 'FONECEL' || name === 'CPFCNPJ') {
        const response = e;
        if (!response) {
          e.preventDefault();
        }
      }
    };

    if (name === 'FONECEL') {
      let foneCel_t = telefone;
      foneCel_t = foneCel_t.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '');

      let mask_telefone;

      const completeValue = [];

      if (foneCel_t.length <= 8) {
        mask_telefone = '9999-99999';
        completeValue.push(foneCel_t);
      } else {
        const myValue = [...completeValue];
        const completeMyValue = myValue + foneCel_t;
        foneCel_t = completeMyValue;
        mask_telefone = '(99)99999-9999';
      }

      return (
        <div>
          <TextInputMask
            kind={'only-numbers'}
            kind={'custom'}
            value={foneCel_t}
            options={{ mask: mask_telefone }}
            className="form-control"
            onChangeText={(text) => changeValueCustomized(text, 'FONECEL')}
            style={{ height: '31px', border: '1px solid #ced4da' }}
          />
        </div>
      );
    }

    if (name === 'CPFCNPJ') {
      let cpfcnpj_t = cpfCnpj;
      cpfcnpj_t = cpfcnpj_t.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');

      let mask_cpfcnpj;

      const completeValue = [];

      if (cpfcnpj_t.length < 12) {
        mask_cpfcnpj = '999.999.999-999';
        completeValue.push(cpfcnpj_t);
      } else {
        const myValue = [...completeValue];
        const completeMyValue = myValue + cpfcnpj_t;
        cpfcnpj_t = completeMyValue;
        mask_cpfcnpj = '99.999.999/9999-99';
      }

      return (
        <div>
          <TextInputMask
            kind={'only-numbers'}
            kind={'custom'}
            value={cpfcnpj_t}
            options={{ mask: mask_cpfcnpj }}
            className="form-control"
            onChangeText={(text) => changeValueCustomized(text, 'CPFCNPJ')}
            style={{ height: '31px', border: '1px solid #ced4da' }}
          />
        </div>
      );
    }

    if (name != 'NIVEL_ACESSO') {
      return (
        <Input
          {...{ name, type }}
          autoFocus={true}
          key={index}
          size="sm"
          max={max}
          maxLength={maxlength}
          style={{ border: '1px solid #ced4da' }}
          value={getValue(name)}
          onChange={({ target }) => changeValue(target)}
          required={required !== undefined ? required : true}
          onKeyDown={(value) => validateKeyDown(name, value)}
        />
      );
    }

    return (
      <div>
        <Input
          {...{ name }}
          key={index}
          value={getValue(name)}
          size="sm"
          type="select"
          style={{ border: '1px solid #ced4da' }}
          onChange={({ target }) => changeValue(target)}
          required={required !== undefined ? required : true}
        >
          <option>Selecione...</option>
          {dataLevels &&
            dataLevels.map((level, index) => {
              return <option value={level.ID}>{level.PERFIL}</option>;
            })}
        </Input>
      </div>
    );
  };

  const getValue = (campo) => {
    const { variable, reducer } = props;
    if (reducer == undefined) return '';
    var value = variable ? props[reducer][variable][campo] : props[reducer][campo];
    return value || '';
  };

  const changeValue = (target) => {
    const { variable, reducer, typeReducer } = props;
    const { name, value } = target;

    if (reducer == undefined || !typeReducer) return '';
    if (variable) {
      var newVariable = props[reducer][variable];
      newVariable[name] = value;
      props.addSTATE(typeReducer, name, newVariable);
    } else props.addSTATE(typeReducer, name, value);
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      {data &&
        data.map((form, i) => {
          return (
            <div style={{ width: '100%' }} key={'divForm' + i}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4>{form.name}</h4>
                <span
                  style={{
                    display: 'block',
                    cursor: 'pointer',
                    color: 'rgba(0, 0, 0, 0.6)',
                    fontSize: '1.25rem',
                  }}
                  onClick={() => props.toggle()}
                >
                  ×
                </span>
              </div>
              <FormGroup style={{ display: 'flex', flexWrap: 'wrap' }}>
                {form.campos.map((campo, index) => {
                  return (
                    <div
                      key={'divInput' + index}
                      className="mb-2"
                      style={{ width: campo.width || '100%' }}
                      placeholder={campo.placeholder}
                    >
                      <Label>{campo.label}</Label>
                      {getTypeInput(campo, index)}
                    </div>
                  );
                })}
              </FormGroup>
            </div>
          );
        })}
    </Form>
  );
};

const mapStateToProps = ({ ...allReducers }) => {
  return { ...allReducers };
};

export default connect(mapStateToProps, { addSTATE })(CustomForm);
