export default function removeAllCharacters(e) {
  const { keyCode } = e;

  if (keyCode !== 16) {
    const arrayCodes = [];

    var i = 48;
    for (i; i <= 57; i++) {
      arrayCodes.push(i);
    }

    const values = arrayCodes
      .map((item) => {
        if (keyCode === item || keyCode === 8) {
          return true;
        }
        return false;
      })
      .filter((i) => i !== false);

    return values[0];
  }
}
