import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const GenericModal = (props) => {
  const { open, close, header, body, footer } = props;

  return (
    <Modal isOpen={open} toggle={() => close()}>
      {header && <ModalHeader toggle={() => close()}>{header}</ModalHeader>}
      {body && <ModalBody>{body}</ModalBody>}
      {footer && footer}
    </Modal>
  );
};

export default GenericModal;
