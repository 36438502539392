import React from 'react';
import { Input, Label } from 'reactstrap';
import * as Styled from '../styles/Filter.style';

const InputDate = ({ label, setValue, value = '', name, style = '', disabled = false }) => {
  return (
    <div style={style.div}>
      {label && <Label size="sm">{label}</Label>}
      <Input
        type="date"
        name={name}
        size="sm"
        max="9999-12-31"
        value={value}
        onChange={(event) => setValue(event.target.value)}
        style={style.input}
        disabled={disabled}
      />
    </div>
  );
};

export default InputDate;
