import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Card, Row } from 'reactstrap';
import { Accordion, AccordionDetails, AccordionSummary, Box, Tooltip, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';
import { CardFooter } from 'reactstrap';
import { ExpandMore, ReceiptLong } from '@mui/icons-material';

import { formatParams } from '../../../../utils/query';
import { formatMoney } from '../../../../utils/formatMoneyBRL';
import Table from '../../../../components/Table/Table';
import NoRegisterFound from '../../../../components/Texts/NoRegisterFound.jsx';
import valueEmpty from '../../../../utils/valueEmpty.js';
import InputDate from '../../../../components/Filter/components/InputDate';
import * as Styled from '../../../../components/Filter/styles/Filter.style';
import Button from '../../../../components/Button/Button.style';
import Icon from '../../../../components/Icon/Icon.styles';
import Loading from '../../../../components/Loader/Loading';
import InputSearch from '../../../../components/Filter/components/InputSearch';
import InputSelectAmountResults from '../../../../components/Filter/components/InputSelectAmountResults.js';
import api from '../../../../service/api';
import { dateTimeFormat } from '../../../../utils/dateKyc';
import StatusContainer from '../../../../components/StatusContainer/StatusContainer';
import GenericButton from '../../../../components/Buttons/Button';
import ModalBoletoDetails from '../modals/components/ModalBoletoDetails/ModalBoletoDetails';

export default function ExtractAccountCard({ cardAccount, reload }) {
  const [expanded, setExpanded] = useState(true);
  const [loading, setLoading] = useState(false);
  const [extract, setExtract] = useState(false);
  const [modalBoletoDetails, setModalBoletoDetails] = useState(false);
  const [boletoId, setBoletoId] = useState(false);

  const [filter, setFilter] = useState({
    DATA_INICIO: moment().add(-7, 'days').format('YYYY-MM-DD'),
    DATA_FIM: moment().format('YYYY-MM-DD'),
    SEARCH: '',
    PAGE: 1,
    LIMIT: 10,
  });
  const [senha, setSenha] = useState({
    SENHA: '',
    STATUS: false,
    ERRO: false,
  });

  useEffect(() => {
    search();
  }, [reload]);

  const search = () => {
    setLoading(true);

    const data = formatParams(filter);
    const UUID = cardAccount && cardAccount.length > 0 && cardAccount[0].uuid;

    if (UUID) {
      api
        .get(`/admin/conta-cartao/transacao/${UUID}?` + data)
        .then(({ data }) => {
          if (data) {
            setExtract(data.data);
            setLoading(false);
          }
        })
        .catch(() => setLoading(false));
    } else {
      setLoading(false);
    }
  };

  const renderTable = () => (loading ? true : false);

  const renderFilter = () => {
    return (
      <div style={{ padding: '30px 0px 2.5px 0px' }}>
        {/* retirado para aguardar o desenvilvimento de senhas
        {localStorage.getItem('nivelAcesso') != 1 && senha.STATUS === false && (
          <>
            <h4>Digite a senha de permissão para visualizar a ficha gráfica</h4>
            <Styled.InputsContainer className="Senha Acesso">
              <InputPassword
                label="Senha"
                name="SENHA"
                value={senha.SENHA}
                setValue={(value) => setSenha({ ...senha, SENHA: value })}
                erro={senha.ERRO}
                erroMenssagem={'Senha incorreta'}
              />
              <div>
                <Button
                  width={'110px'}
                  marginTop={'34px !important'}
                  marginLeft={'15px'}
                  onClick={() => clickButton()}
                >
                  Confirmar
                </Button>
              </div>
            </Styled.InputsContainer>
          </>
        )} */}

        {localStorage.getItem('nivelAcesso') != 1 && senha.STATUS == false ? (
          <Styled.InputsContainer>
            <div>
              <Skeleton animation={false} width={60} />
              <Skeleton animation={false} width={100} />
            </div>
            <div>
              <Skeleton animation={false} width={60} />
              <Skeleton animation={false} width={100} />
            </div>
            <Skeleton animation={false} width={100} />
          </Styled.InputsContainer>
        ) : localStorage.getItem('nivelAcesso') == 1 || senha.STATUS === true ? (
          <Styled.FilterContainer>
            <Styled.InputsContainer>
              {/* <InputSearch
    width="30vw"
    value={params.user}
    setValue={(value) => setFilter({ ...filter, SEARCH: value })}
  /> */}
              {/*         <InputSelectAmountResults
                options={['10', '25', '50', '100', '1000']}
                value={filter.LIMIT}
                setValue={(value) => setFilter({ ...filter, LIMIT: value })}
                width={'25rem'}
              /> */}
              <InputSearch
                width="30vw"
                name="SEARCH"
                value={filter.SEARCH}
                setValue={(value) => setFilter({ ...filter, SEARCH: value })}
              />

              <InputDate
                label="Data Inicial"
                name="DATA_INICIO"
                value={filter.DATA_INICIO}
                setValue={(value) => setFilter({ ...filter, DATA_INICIO: value })}
              />
              <InputDate
                label="Data Final"
                name="DATA_FIM"
                value={filter.DATA_FIM}
                setValue={(value) => setFilter({ ...filter, DATA_FIM: value })}
              />

              <div style={{ alignSelf: 'flex-end' }}>
                <Button width={'110px'} marginTop={'34px !important'} marginLeft={'15px'} onClick={() => search()}>
                  <Icon className="fas fa-search"></Icon>Buscar
                </Button>
              </div>
            </Styled.InputsContainer>

            <InputSelectAmountResults
              options={['10', '25', '50', '100', '1000']}
              value={filter.LIMIT}
              setValue={(value) => setFilter({ ...filter, LIMIT: value })}
              width={'25rem'}
            />
          </Styled.FilterContainer>
        ) : null}
        <></>
      </div>
    );
  };

  return (
    <>
      <Accordion expanded={expanded}>
        <AccordionSummary
          onClick={() => setExpanded(!expanded)}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-content"
        >
          <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
            Extrato
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Card style={{ overflow: 'hidden' }}>
              <Row style={{ marginBottom: '20px', overflow: 'auto' }}>
                <div>
                  <div className="col" style={{ marginLeft: '25px', marginRight: '25px' }}>
                    {renderFilter()}
                    {renderTable() ? (
                      <Loading color="#2405f2" />
                    ) : (
                      <div>
                        {extract && extract.length > 0 ? (
                          localStorage.getItem('nivelAcesso') != 1 && senha.STATUS == false ? (
                            <>
                              <Skeleton animation={false} fullWidht height={60} />
                              <Styled.InputsContainer
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Skeleton animation={false} width={150} />
                                <Skeleton animation={false} width={150} />
                                <Skeleton animation={false} width={150} />
                                <Skeleton animation={false} width={150} />
                                <Skeleton animation={false} width={150} />
                                <Skeleton animation={false} width={150} />
                              </Styled.InputsContainer>
                            </>
                          ) : localStorage.getItem('nivelAcesso') == 1 || senha.STATUS == true ? (
                            <Table
                              style={{
                                textAlign: 'center',
                              }}
                              headers={[
                                {
                                  info: 'Data/Hora',
                                  style: { textAlign: 'center', fontSize: '9px' },
                                },
                                {
                                  info: 'Número Cartão',
                                  style: { textAlign: 'center', fontSize: '9px' },
                                },
                                {
                                  info: 'Descrição',
                                  style: { textAlign: 'center', fontSize: '9px', whiteSpace: 'nowrap' },
                                },
                                {
                                  info: 'Status',
                                  style: { textAlign: 'center', fontSize: '9px' },
                                },
                                {
                                  info: 'Tipo',
                                  style: { textAlign: 'center', fontSize: '9px' },
                                },
                                {
                                  info: 'Valor',
                                  style: { textAlign: 'center', fontSize: '9px' },
                                },
                                {
                                  info: 'Taxa',
                                  style: { textAlign: 'center', fontSize: '9px' },
                                },
                                {
                                  info: 'Valor Total',
                                  style: { textAlign: 'center', fontSize: '9px' },
                                },
                                {
                                  info: 'Saldo Anterior',
                                  style: { textAlign: 'center', fontSize: '9px' },
                                },

                                {
                                  info: 'Saldo',
                                  style: { textAlign: 'right', fontSize: '9px' },
                                },
                                {
                                  info: 'Ações',
                                  style: { textAlign: 'center', fontSize: '9px' },
                                },
                              ]}
                            ></Table>
                          ) : null
                        ) : localStorage.getItem('nivelAcesso') != 1 && senha.STATUS == false ? (
                          <Skeleton animation={false} fullWidht height={60} />
                        ) : (
                          <NoRegisterFound />
                        )}{' '}
                        <Table
                          style={{ textAlign: 'center' }}
                          data={
                            extract
                              ? localStorage.getItem('nivelAcesso') == 1 || senha.STATUS == true
                                ? extract.map((log, index) => {
                                    return {
                                      data: [
                                        {
                                          info: dateTimeFormat(log.DATA_INSERT),
                                          style: { textAlign: 'center' },
                                        },
                                        {
                                          info: log.PAN_MASKED ? log.PAN_MASKED : '----',
                                          style: { textAlign: 'center' },
                                        },
                                        {
                                          info: log.DESCRICAO,
                                          style: { textAlign: 'left' },
                                        },
                                        {
                                          info: valueEmpty(
                                            log.STATUS_TRANSACAO ? (
                                              <StatusContainer
                                                code={log.FK_STATUS_TRANSACAO}
                                                description={log.STATUS_TRANSACAO}
                                              />
                                            ) : null,
                                          ),
                                          style: { textAlign: 'center' },
                                        },
                                        {
                                          info: log.FK_TIPO_TRANSACAO == 2 ? 'Crédito' : 'Débito',
                                          style: {
                                            textAlign: 'center',
                                            /* color: log.FK_TIPO_TRANSACAO == 2 ? 'green' : 'red', */
                                          },
                                        },
                                        {
                                          info: valueEmpty(
                                            log.VALOR && log.FK_TIPO_TRANSACAO == 2
                                              ? `+ R$${formatMoney(log.VALOR)}`
                                              : `- R$${formatMoney(log.VALOR)}`,
                                          ),
                                          style: {
                                            textAlign: 'center',
                                            whiteSpace: 'nowrap',
                                            color: log.FK_TIPO_TRANSACAO == 2 ? 'green' : 'red',
                                          },
                                        },

                                        {
                                          info: valueEmpty(log.TAXA && `R$${formatMoney(log.TAXA)}`),
                                          style: {
                                            textAlign: 'center',
                                            whiteSpace: 'nowrap',
                                            color: 'red',
                                          },
                                        },
                                        {
                                          info: valueEmpty(
                                            log.VALOR && log.TAXA && log.FK_TIPO_TRANSACAO == 2
                                              ? `+ R$${formatMoney(+log.VALOR + +log.TAXA)}`
                                              : `- R$${formatMoney(+log.VALOR + +log.TAXA)}`,
                                          ),
                                          style: {
                                            textAlign: 'center',
                                            whiteSpace: 'nowrap',
                                            color: log.FK_TIPO_TRANSACAO == 2 ? 'green' : 'red',
                                          },
                                        },

                                        {
                                          info: log.SALDO_ANTERIOR ? `R$${formatMoney(log.SALDO_ANTERIOR)}` : '-',
                                          style: { textAlign: 'center' },
                                        },
                                        {
                                          info: log.SALDO_ATUAL ? `R$${formatMoney(log.SALDO_ATUAL)}` : '-',
                                          style: { textAlign: 'right' },
                                        },
                                        {
                                          info: log.FK_BOLETO_TRANSACAO ? (
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                              <GenericButton
                                                width={'40px'}
                                                backgroundColor="#f0a24a"
                                                borderColor="#f0a24a"
                                                size="m"
                                                onClick={() => {
                                                  setBoletoId(log.FK_BOLETO_TRANSACAO);
                                                  setModalBoletoDetails(true);
                                                }}
                                              >
                                                <Tooltip title="Detalhes pagamento">
                                                  <ReceiptLong />
                                                </Tooltip>
                                              </GenericButton>
                                            </div>
                                          ) : (
                                            '----'
                                          ),
                                          style: { textAlign: 'center' },
                                        },
                                      ],
                                    };
                                  })
                                : null
                              : null
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Row>
            </Card>
            <Typography>
              <CardFooter></CardFooter>
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>

      <ModalBoletoDetails
        open={modalBoletoDetails}
        boletoId={boletoId}
        toggle={() => {
          setModalBoletoDetails(false);
          setBoletoId(false);
        }}
      />
    </>
  );
}
