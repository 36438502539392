import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  GET_QRCODE_LOGIN,
  GET_QRCODE_LOGIN_SUCCESSS,
  GET_QRCODE_LOGIN_FAIL,
  LOGOUT,
  RESPONSE_AUTH,
  RENOVATION_FAIL,
  RENOVATION_SUCCESS,
  RENOVATION_LOADING,
} from './types';
import api from '../../service/api';
import removeSpecialChars from '../../utils/removeSpecialChars';

export const login = (props, callback) => {
  return (dispatch) => {
    dispatch({ type: GET_QRCODE_LOGIN });

    api
      .post('coban/autenticacao/enviar', props)
      .then((res) => {
        dispatch({ type: GET_QRCODE_LOGIN_SUCCESSS });
        if (callback) callback(true, res);
      })
      .catch((err) => {
        dispatch({ type: GET_QRCODE_LOGIN_FAIL, payload: err });
        if (callback) callback(false, err);
      });
  };
};

export const validateLogin = (props, callback) => {
  return (dispatch) => {
    dispatch({ type: LOGIN });

    api
      .post('coban/novaAutenticacao/validar', props)
      .then((res) => {
        dispatch({ type: LOGIN_SUCCESS, payload: res.data });
        if (callback) callback(true, res);
      })
      .catch((err) => {
        dispatch({ type: LOGIN_FAIL, payload: err });
        if (callback) callback(false, err);
      });
  };
};

export const logout = (callback) => {
  return (dispatch) => {
    dispatch({ type: LOGOUT });
    callback();
  };
};

export const renovationJwtAction = (params, callback = () => undefined) => {
  const CPFCNPJ = removeSpecialChars(['.', '-', '/'], params.CPFCNPJ);

  let dataObject = {
    CPFCNPJ,
    SENHA: params.SENHA,
  };
  return async (dispatch) => {
    dispatch({ type: RENOVATION_LOADING });

    try {
      const response = await api.put(`/admin/refresh/session`, dataObject);
      dispatch({
        type: RENOVATION_SUCCESS,
        payload: response.data,
      });

      localStorage.setItem('is_authenticate', response.data.jwt);

      document.location.reload();
    } catch (error) {
      dispatch({
        type: RENOVATION_FAIL,
        payload: '',
      });

      callback({
        title: error.response.data.mensagem,
        text: 'Digite novamente sua senha.',
        icon: 'error',
      });
    }
  };
};
