import React, { useState } from 'react';
import { ButtonGroup, Button } from 'reactstrap';

const ButtonGroupCliente = (props) => {
  const [hoverButton, setHoverButton] = useState({});

  function handleHoverButton(id, bool) {
    setHoverButton({ ...hoverButton, [id]: bool });
  }

  function handleClick(operation) {
    props.setModalSenhaUnica(true);
    props.setOperation(operation);
  }

  return (
    <ButtonGroup size="sm" style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', marginLeft: '-1rem' }}>
      
      <Button
        color="primary"
        onMouseEnter={(e) => handleHoverButton(e.target.id, true)}
        onMouseLeave={(e) => handleHoverButton(e.target.id, false)}
        id="6"
        style={{ borderRadius: '5px', transform: hoverButton[6] ? 'translateY(-.1rem)' : '' }}
        onClick={() => handleClick('recuperarSenha')}
      >
        Recuperar Senha
      </Button>

      <Button
        color="primary"
        onMouseEnter={(e) => handleHoverButton(e.target.id, true)}
        onMouseLeave={(e) => handleHoverButton(e.target.id, false)}
        id="7"
        style={{ borderRadius: '5px', transform: hoverButton[7] ? 'translateY(-.1rem)' : '' }}
        onClick={() => handleClick('liberarAcessoDispositivo')}
      >
        Liberar Acesso Dispositivo
      </Button>

      {props.user.STATUS == 2 ||
      props.user.STATUS == 4 ||
      props.user.STATUS == 5 ||
      props.user.STATUS == 6 ||
      props.user.STATUS == 7 ? (
        <Button
          color="primary"
          onMouseEnter={(e) => handleHoverButton(e.target.id, true)}
          onMouseLeave={(e) => handleHoverButton(e.target.id, false)}
          style={{ borderRadius: '5px', transform: hoverButton[9] ? 'translateY(-.1rem)' : '' }}
          id="9"
          onClick={() => handleClick('block')}
        >
          {props.user.STATUS == 2 || props.user.STATUS == 5 || props.user.STATUS == 6 || props.user.STATUS == 7
            ? 'Bloquear Usuário'
            : props.user.STATUS == 4
            ? 'Desbloquear Usuário'
            : null}
        </Button>
      ) : null}
      <Button
        color="primary"
        onMouseEnter={(e) => handleHoverButton(e.target.id, true)}
        onMouseLeave={(e) => handleHoverButton(e.target.id, false)}
        style={{ borderRadius: '5px', transform: hoverButton[10] ? 'translateY(-.1rem)' : '' }}
        id="10"
        onClick={() => handleClick('statusUsuario')}
      >
        Alterar Status do Usuário
      </Button>
    </ButtonGroup>
  );
};

export default ButtonGroupCliente;
