import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Card, Row } from 'reactstrap';
import { Box, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useSelector } from 'react-redux';
import { CardFooter } from 'reactstrap';

import Table from '../../../../components/Table/Table';
import NoRegisterFound from '../../../../components/Texts/NoRegisterFound.jsx';
import StatusContainerValue from '../../../../components/StatusContainer/StatusContainerValue.js';
import valueEmpty from '../../../../utils/valueEmpty.js';
import InputDate from '../../../../components/Filter/components/InputDate';
import * as Styled from '../../../../components/Filter/styles/Filter.style';
import Button from '../../../../components/Button/Button.style';
import Icon from '../../../../components/Icon/Icon.styles';
import Loading from '../../../../components/Loader/Loading';
import ModalBoletoDetails from '../modals/components/ModalBoletoDetails/ModalBoletoDetails';

export default function ExtractCCThirdparty({ accountVisible, filter, setFilter, searchWithFilter, user }) {
  const { saldo, loading } = useSelector((state) => state.user);
  const [modalBoletoDetails, setModalBoletoDetails] = useState(false);
  const [boletoId, setBoletoId] = useState(false);
  const [senha, setSenha] = useState({
    SENHA: '',
    STATUS: false,
    ERRO: false,
  });

  
  const fichaGrafica = saldo && saldo.HISTORICO;
  
  const renderTable = () => (loading ? true : false);

  useEffect(() => {
    searchWithFilter('SINQIA');
  }, [accountVisible && accountVisible.CONTA]);

  const renderFilter = () => {
    return (
      <div style={{ padding: '30px 0px 2.5px 0px' }}>

        {localStorage.getItem('nivelAcesso') != 1 && senha.STATUS == false ? (
          <Styled.InputsContainer>
            <div>
              <Skeleton animation={false} width={60} />
              <Skeleton animation={false} width={100} />
            </div>
            <div>
              <Skeleton animation={false} width={60} />
              <Skeleton animation={false} width={100} />
            </div>
            <Skeleton animation={false} width={100} />
          </Styled.InputsContainer>
        ) : localStorage.getItem('nivelAcesso') == 1 || senha.STATUS === true ? (
          <Styled.FilterContainer>
            <Styled.InputsContainer>
              <InputDate
                label="Data Inicial"
                name="DATA_INICIO"
                value={filter.DATA_INICIO}
                setValue={(value) => setFilter({ ...filter, DATA_INICIO: value })}
              />
              <InputDate
                label="Data Final"
                name="DATA_FIM"
                value={filter.DATA_FIM}
                setValue={(value) => setFilter({ ...filter, DATA_FIM: value })}
              />

              <div style={{ alignSelf: 'flex-end' }}>
                <Button
                  width={'110px'}
                  marginTop={'34px !important'}
                  marginLeft={'15px'}
                  onClick={() => searchWithFilter('SINQIA')}
                >
                  <Icon className="fas fa-search"></Icon>Buscar
                </Button>
              </div>
            </Styled.InputsContainer>
          </Styled.FilterContainer>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <Box>
        <div>
          <Card>
            <Row style={{ marginBottom: '20px' }}>
              <div className="col" style={{ marginLeft: '25px', marginRight: '25px' }}>
                {renderFilter()}
                {renderTable() ? (
                  <Loading color="#2405f2" />
                ) : (
                  <div>
                    {fichaGrafica && fichaGrafica.length > 0 ? (
                      localStorage.getItem('nivelAcesso') != 1 && senha.STATUS == false ? (
                        <>
                          <Skeleton animation={false} fullWidht height={60} />
                          <Styled.InputsContainer
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Skeleton animation={false} width={150} />
                            <Skeleton animation={false} width={150} />
                            <Skeleton animation={false} width={150} />
                            <Skeleton animation={false} width={150} />
                            <Skeleton animation={false} width={150} />
                            <Skeleton animation={false} width={150} />
                          </Styled.InputsContainer>
                        </>
                      ) : localStorage.getItem('nivelAcesso') == 1 || senha.STATUS == true ? (
                        <Table
                          style={{
                            textAlign: 'center',
                            tableLayout: 'fixed',
                          }}
                          headers={[
                            {
                              info: 'Data/Hora',
                              style: { textAlign: 'center' },
                            },
                            {
                              info: 'Tipo',
                              style: { textAlign: 'center' },
                            },
                            {
                              info: 'Descrição',
                              style: { textAlign: 'left' },
                            },
                            {
                              info: 'Nome Pagador',
                              style: { textAlign: 'left' },
                            },
                            {
                              info: 'Nome Recebedor',
                              style: { textAlign: 'left' },
                            },
                            {
                              info: 'Valor',
                              style: { textAlign: 'center' },
                            }
                          ]}
                        ></Table>
                      ) : null
                    ) : localStorage.getItem('nivelAcesso') != 1 && senha.STATUS == false ? (
                      <Skeleton animation={false} fullWidht height={60} />
                    ) : (
                      <NoRegisterFound />
                    )}

                    <InfiniteScroll
                      dataLength={fichaGrafica && fichaGrafica.length}
                      hasMore={true}
                      height={user && user.saldo && user.saldo.HISTORICO.length}
                    >
                      <Table
                        style={{ textAlign: 'center', tableLayout: 'fixed' }}
                        data={
                          fichaGrafica
                            ? localStorage.getItem('nivelAcesso') == 1 || senha.STATUS == true
                              ? fichaGrafica.map((log) => {
                                const tipo = log.entry_type === "C" ? 'CRÉDITO' : 'DÉBITO';
                                const recebedor = tipo === 'CRÉDITO' ? log.person_name : log.counterparty_name;
                                const pagador = tipo === 'CRÉDITO' ? log.counterparty_name : log.person_name;
                                return {
                                  data: [
                                    {
                                      info: `${log.inclusion_system_date} ${log.inclusion_system_time}`,
                                      style: { textAlign: 'center' },
                                    },
                                    {
                                      info: tipo,
                                      style: { textAlign: 'center' },
                                    },
                                    {
                                      info: log.transfer_complete_description,
                                      style: { textAlign: 'left' },
                                    },
                                    {
                                      info: recebedor ? recebedor : 'Não Informado',
                                      style: { textAlign: 'left' },
                                    },
                                    {
                                      info: pagador ? pagador : 'Não Informado',
                                      style: { textAlign: 'left' },
                                    },
                                    {
                                      info: valueEmpty(
                                        log.entry_value ? (
                                          <StatusContainerValue code={tipo} description={log.entry_value} />
                                        ) : null,
                                      ),
                                      style: {
                                        textAlign: 'center',
                                        whiteSpace: 'nowrap',
                                      },
                                    }
                                  ],
                                };
                              })
                              : null
                            : null
                        }
                      />
                    </InfiniteScroll>
                  </div>
                )}
              </div>
            </Row>
          </Card>
        </div>
        <Typography>
          <CardFooter></CardFooter>
        </Typography>
      </Box>
      <ModalBoletoDetails
        open={modalBoletoDetails}
        boletoId={boletoId}
        toggle={() => {
          setModalBoletoDetails(false);
          setBoletoId(false);
        }}
      />
    </>
  );
}
