import * as React from 'react';
import * as Styled from './Styles/styleModal';
import DialogActions from '@mui/material/DialogActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function ResponsiveDialog(props) {
  const { open, close, buttonTexts, callFunction, title } = props;

  return (
    <div>
      <Styled.DialogMessage open={open} aria-labelledby="responsive-dialog-title">
        <Styled.Title id="responsive-dialog-title">{title}</Styled.Title>
        <DialogActions>
          {buttonTexts &&
            buttonTexts.map(({ name, color }, index) => {
              return (
                <Styled.ButtonMessage onClick={index === 0 ? callFunction : close} index={index} colorButton={color}>
                  {name}
                </Styled.ButtonMessage>
              );
            })}
        </DialogActions>
      </Styled.DialogMessage>
    </div>
  );
}
