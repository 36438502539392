import styled from 'styled-components';

export const CardCheckContainer = styled.p`
font-size: 1.2rem;
margin-top: .3rem;

`

export const CardsContainer = styled.div`
display: flex;
margin: 1rem 0;
align-items: center;

`

export const Container = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
`