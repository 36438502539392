import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';

const InputWithSearchWithValue = ({ setValue, options, removeLabel, values, notUnique }) => {
  const [selectOptions, setSelectOptions] = useState(false);

  useEffect(() => {
    if (options) {
      const uniqueOption = [...new Set(options)];

      let search = [];
      if (notUnique) {
        search = options;
      } else {
        search = uniqueOption;
      }
      const res = search.map((option, index) => {
        let obj;

        if (option == 'Todos') {
          obj = { label: 'Todos', value: '' };
        } else {
          obj = { label: option, value: values[index] };
        }
        return obj;
      });

      setSelectOptions(res);
    }
  }, [options]);

  return (
    <div>
      {!removeLabel && <Label size="sm">Cartões</Label>}

      {selectOptions && (
        <Select
          className="my-custom-select"
          onChange={({ value }) => setValue(value)}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              width: '15rem',
              minHeight: '31px',
              height: '31px',
            }),
          }}
          options={selectOptions}
        />
      )}
    </div>
  );
};

export default InputWithSearchWithValue;
