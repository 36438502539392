import styled, { css } from 'styled-components';
import { TextField } from '@material-ui/core';

export const DateField = styled(TextField)`
  width: ${(props) => props.width || '17.778vw'};
  background: rgba(51, 51, 51, 0.05);
  border-radius: 4px 4px 0px 0px;
  margin: ${(props) => props.margin || '3.333vw'};

  .MuiInputBase-input {
    margin-left: 1.389vw;
    margin-right: 1vw;
  }
  .MuiInputBase-root {
    background-color: transparent;
  }
  .MuiFormLabel-root {
    margin-left: 1.389vw;
    padding-top: 0.2vw;
    height: 3.333vw;
  }
`;
