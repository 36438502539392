import React, { useEffect, useState } from "react";
import api from "../../../service/api";
import { Badge, Modal, ModalBody, ModalHeader } from "reactstrap";
import Loading from "../../../components/Loader/Loading";
import { Grid } from "@mui/material";
import { formatCNPJ, formatCPF, formatDateBR, isCpf } from "../../../utils";
import StatusContainer from "../../../components/StatusContainer/StatusContainer";

export default function ModalDetailedCard({ modal, toggle, title, code }) {
    const [card, setCard] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (modal) {
            api
                .get(`/admin/cartao/${code}`)
                .then(({ data: { data } }) => {
                    console.log(data);
                    setCard(data);
                    setLoading(false);
                });
        }
    }, [modal]);

    return (
        <Modal style={{ maxWidth: 800 }} isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <h3>{title}</h3>
            </ModalHeader>
            {
                loading && card ? <Loading color="blue" /> : (
                    <ModalBody>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h5>Proprietário</h5>
                                <p>{card.NOME}</p>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <h5>Documento do Proprietário</h5>
                                <p>{isCpf(card.CPFCNPJ) ? formatCPF(card.CPFCNPJ) : formatCNPJ(card.CPFCNPJ)}</p>
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <h5>Parceiro</h5>
                                <StatusContainer code={card.PARCEIRO} description={card.PARCEIRO}></StatusContainer>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <h5>Data de Expedição</h5>
                                <p>{formatDateBR(card.ISSUANCE_DATE)}</p>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <h5>Data de Validade</h5>
                                <p>{card.EXPIRY_DATE}</p>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <h5>Código do Cartão</h5>
                                <p>{card.UUID}</p>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <h5>Código do Grupo de Conta Bancária(BAG)</h5>
                                <p>{card.BAG_ID}</p>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <h5>Pan Masked</h5>
                                <p>{card.PAN_MASKED}</p>
                            </Grid>
                            <Grid item xs={12} sm={6} alignItems={'center'}>
                                <h5>Status</h5>
                                <p>
                                    {card.STATUS === 'active' && <Badge color="success">{card.STATUS}</Badge>}
                                    {card.STATUS === 'blocked' && <Badge color="danger">{card.STATUS}</Badge>}
                                </p>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <h5>ID Cartão</h5>
                                <p>{card.CARD_ID}</p>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <h5>Pagamento por Aproximação</h5>
                                <p>{
                                    card.CONTACTLESS_PERMITTED ? <Badge color="success">ATIVO</Badge> : <Badge color="danger">INATIVO</Badge>
                                }</p>
                            </Grid>
                        </Grid>
                    </ModalBody>
                )
            }
        </Modal>
    );
}