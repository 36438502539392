import React from 'react';
import CustomTable from '../../Table/Table';
import { formatCNPJ, formatCPF } from '../../../utils';
import formatMoney from '../../../utils/formatMoneyBRL';

function TableRechargeCard({ data }) {
  return (
    <CustomTable
      style={{ marginTop: '.5rem', width: '100%' }}
      size="sm"
      headers={[
        {
          info: 'CPF colaborador',
          style: { textAlign: 'center' },
        },
        {
          info: 'Nome colaborador',
          style: { textAlign: 'center' },
        },
        {
          info: 'Produto',
          style: { textAlign: 'center' },
        },
        {
          info: 'Cartão',
          style: { textAlign: 'center' },
        },
        {
          info: 'Valor',
          style: { textAlign: 'center' },
        },
      ]}
      data={data.map((item, index) => {
        return {
          data: [
            {
              info:
                item.CPFCNPJ_COLABORADOR && item.CPFCNPJ_COLABORADOR.length == 11
                  ? formatCPF(item.CPFCNPJ_COLABORADOR)
                  : formatCNPJ(item.CPFCNPJ_COLABORADOR),
              style: { textAlign: 'center' },
            },
            {
              info: item.NOME_COLABORADOR,
              style: { textAlign: 'center' },
            },
            {
              info: item.DESCRICAO_PRODUTO,
              style: { textAlign: 'center' },
            },
            {
              info: item.PAN_MASKED,
              style: { textAlign: 'center' },
            },
            {
              info: 'R$' + formatMoney(item.VALOR),
              style: { textAlign: 'center' },
            },
          ],
        };
      })}
    />
  );
}

export default TableRechargeCard;
