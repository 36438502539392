import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, ModalHeader, ModalBody, Button, Input, InputGroup, Alert } from 'reactstrap';
import {
  listarUmCartaoByUUID,
  listarSaldoGeral,
  salvarSaldoProduto,
  listarSaldoGeralByUUID,
} from '../../../../../../store/coban/actions';
import formatMoney from '../../../../../../utils/formatMoneyBRL';
import {
  Table,
  TableContent,
  ButtonGroup,
  InfoCard,
  ProductContent,
  ProductTitleAndBalance,
  ProductCardsType,
  SaldoColor,
  SaldoContainer,
  SaldoDisponivelColor,
  SaldoStyle,
  SaldoUtilizadoColor,
} from './ModalCardUser.styles';
import api from '../../../../../../service/api';
import ModalDifferenceValue from '../ModalDifferenceValue/ModalDifferenceValue';
import { ReloadButtonStyle } from '../../../DigitalAccountBalaceContainer/style';
import { FiRefreshCw } from 'react-icons/fi';
import Loading from '../../../../../../components/Loader/Loading';
import { listarProdutosCartaoByUUID } from '../../../../../../store/swap/actions';
import ModalMensagemSemReload from '../../../../../Transaction/ModalMensagemSemReload';

export default function ModalCardUser(props) {
  const dispatch = useDispatch();

  const { CARTAO_PRODUTOS } = useSelector((state) => state.swap);
  const { SALDO, SALDO_CONTA_CARTAO } = useSelector((state) => state.coban);
  const { user } = useSelector((state) => state.user);
  const { open, cartao } = props.info;

  const [operateBalance, setOperateBalance] = useState(false);
  const [modalMensagem, setModalMensagem] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('');
  const [produtos, setProdutos] = useState([]);

  const [stateCard, setStateCard] = useState({});
  const [validateProduct, setValidateProduct] = useState({});
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [modalDifferenceValue, setModalDifferenceValue] = useState({
    open: false,
    message: [],
  });

  useEffect(() => {
    dispatch(listarProdutosCartaoByUUID(user.UUID, cartao.CODCARTAO));
  }, [open]);

  useEffect(() => {
    setProdutos(CARTAO_PRODUTOS);
    setStateCard({ ...cartao, PRODUTOS: CARTAO_PRODUTOS });
  }, [CARTAO_PRODUTOS]);

  useMemo(() => {
    dispatch(listarProdutosCartaoByUUID(user.UUID, cartao.CODCARTAO));
    setStateCard((prev) => ({ ...prev, PRODUTOS: CARTAO_PRODUTOS }));
    dispatch(listarSaldoGeralByUUID(user.UUID));
  }, [props.reloadProdutos]);

  async function listCard() {
    setLoadingRequest(true);
    await dispatch(listarUmCartaoByUUID(user.UUID, cartao.CODCARTAO, setStateCard));
    setLoadingRequest(false);
  }

  useEffect(() => {
    listCard();
  }, [open, operateBalance]);

  function alterValueCallback({ target: { value, name } }) {
    const valueNumber = +value;
    const getState = { ...stateCard };
    const alterValue = getState.PRODUTOS.map((product) => {
      if (product.ID == name) {
        if (value != '') {
          product.NOVO_SALDO = +product.SALDO + valueNumber;
          product.VALOR = +valueNumber;
          if (product.NOVO_SALDO < 0) {
            delete product.NOVO_SALDO;
            delete product.VALOR;
          }
        } else {
          delete product.NOVO_SALDO;
          delete product.VALOR;
        }
        return product;
      } else {
        return product;
      }
    });

    setStateCard((prev) => ({ ...prev, PRODUTOS: alterValue }));
  }

  function saveSaldo() {
    const filter = stateCard.PRODUTOS.filter((produto) => produto.hasOwnProperty('NOVO_SALDO'));
    const produtos = filter.map((produtos) => {
      return {
        ID_VINCULO: produtos.ID,
        SALDO: produtos.NOVO_SALDO,
      };
    });

    dispatch(
      salvarSaldoProduto(produtos, (res) => {
        setModalMensagem(true);
        setMensagem(res.mensagem);
        res.retorno === 'sucesso' ? setSucesso(true) : setSucesso(false);
      }),
    );
  }

  async function salvarSaldo() {
    setLoadingRequest(true);
    const { status, data } = await api.get(
      `usuario/listar/cartao/${user.UUID}?CODCARTAO=${stateCard.CODCARTAO}&page=1&limit=1`,
    );
    if (data) {
      const card = data.CARTOES[0];
      const filter = stateCard.PRODUTOS.filter((produto) => produto.hasOwnProperty('NOVO_SALDO'));
      let differenceValueMessage = [];

      filter.forEach((filterProd) => {
        const produto = card.PRODUTOS.find((product) => filterProd.ID == product.ID);
        if (produto.SALDO !== filterProd.SALDO) {
          differenceValueMessage.push(
            `
              valor diferente no item ${filterProd.DESCRICAO_PRODUTO}, valor anterior: ${filterProd.SALDO}, novo valor:
              ${produto.SALDO}
            `,
          );
        }
      });
      props.forceReload();
      setLoadingRequest(false);
      if (differenceValueMessage.length > 0) {
        setModalDifferenceValue({
          open: true,
          message: differenceValueMessage,
        });
      } else {
        props.forceReload();
        saveSaldo();
      }
    }
    setLoadingRequest(false);
  }

  function buttonCancelAlterValue() {
    const getState = { ...stateCard };
    const alterValue = getState.PRODUTOS.map((product) => {
      if (product.NOVO_SALDO) {
        delete product.NOVO_SALDO;
      }
      return product;
    });

    setStateCard((prev) => ({ ...prev, PRODUTOS: alterValue }));
  }

  function reduceNegativo() {
    if (cartao.TIPO_VINCULO == 2) {
      const negativo =
        SALDO_CONTA_CARTAO.SALDO_DISPONIVEL +
        stateCard.PRODUTOS.reduce(
          (acc, curr) =>
            (acc += curr.hasOwnProperty('NOVO_SALDO')
              ? parseFloat(curr.SALDO).toFixed(2) - parseFloat(curr.NOVO_SALDO).toFixed(2)
              : 0),
          0,
        );

      return negativo;
    } else {
      const negativo =
        SALDO.SALDO_DISPONIVEL +
        +stateCard.PRODUTOS.reduce(
          (acc, curr) =>
            (acc += curr.hasOwnProperty('NOVO_SALDO')
              ? parseFloat(curr.SALDO).toFixed(2) - parseFloat(curr.NOVO_SALDO).toFixed(2)
              : 0),
          0,
        );

      return negativo;
    }
  }

  async function refreshProduct() {
    setLoadingRequest(true);
    const { status, data } = await api.get(
      `usuario/listar/cartao/${user.UUID}?CODCARTAO=${stateCard.CODCARTAO}&page=1&limit=1`,
    );
    props.forceReload();
    setStateCard(data.CARTOES[0]);
    setLoadingRequest(false);
  }

  function closeModalMessage() {
    dispatch(listarSaldoGeralByUUID(user.UUID));
    setOperateBalance(!operateBalance);
    refreshProduct();
    setModalMensagem(false);
  }

  return (
    <>
      <Modal
        isOpen={open}
        toggle={!operateBalance && props.toggle}
        size="lg"
        style={{ maxWidth: '1600px', width: '80%' }}
      >
        <ModalHeader toggle={!operateBalance && props.toggle}>
          <h2>Cartão</h2>
        </ModalHeader>
        {}
        <ModalBody>
          {props.isConvened && (
            <>
              {stateCard && stateCard.PRODUTOS && stateCard.PRODUTOS.length && (
                <Alert color="danger" isOpen={reduceNegativo() < 0}>
                  <i className="fa fa-exclamation-circle" aria-hidden="true" style={{ marginRight: '1rem' }}></i>
                  Não é possível salvar negativado
                </Alert>
              )}
            </>
          )}

          <InfoCard>
            <Table>
              <TableContent>
                <label>TIPO</label>
                <label>{stateCard && stateCard.TIPO}</label>
              </TableContent>
              <TableContent>
                <label>NÚMERO</label>
                <label>{stateCard && stateCard.NUMERO}</label>
              </TableContent>
            </Table>

            {!operateBalance ? (
              <ButtonGroup>
                {props.isConvened && (
                  <>
                    {stateCard && stateCard.PRODUTOS && (
                      <Button
                        size="sm"
                        color="primary"
                        style={{
                          alignContent: 'flex-end',
                        }}
                        onClick={() => {
                          setOperateBalance(!operateBalance);
                        }}
                        name="operar"
                        /* onClick={() => {
                setModalVincularCartaoSwap(true);
                setIdSwap(cartao.CODCARTAO);
              }} */
                      >
                        Operar Saldo
                      </Button>
                    )}
                    <Button
                      size="sm"
                      color="success"
                      style={{
                        alignContent: 'flex-end',
                      }}
                      name="cancelar"
                      onClick={(e) => {
                        props.setModalVincularProduto({
                          open: true,
                          PRODUTOS: cartao.PRODUTOS,
                        });
                        props.setIdSwap(stateCard.CODCARTAO);
                        props.setIdCartao(stateCard.CODCARTAO);
                        /* setModalVincularCartaoSwap(true);
                setIdSwap(cartao.CODCARTAO);*/
                      }}
                    >
                      Vincular
                    </Button>
                  </>
                )}

                {localStorage.getItem('nivelAcesso') == 1 && (
                  <Button
                    size="sm"
                    color="danger"
                    style={{
                      alignContent: 'flex-end',
                    }}
                    name="cancelar"
                    onClick={(e) => {
                      props.setModalSenha(true);
                      props.setIdSwap(stateCard.CODCARTAO);
                      /*setModalSenha(true);
                setIdSwap(cartao.CODCARTAO);*/
                    }}
                  >
                    Trocar Senha
                  </Button>
                )}

                {stateCard && stateCard.BLOQUEADO == 1 ? (
                  <Button
                    size="sm"
                    name="0"
                    color="primary"
                    style={{
                      alignContent: 'flex-end',
                    }}
                    onClick={() => {
                      props.setCartao({
                        BLOQUEADO: 0,
                        ID: stateCard.CODCARTAO,
                        PARCEIRO: stateCard.PARCEIRO,
                        DATA_VENCIMENTO: stateCard.DATA_VENCIMENTO,
                      });
                      props.setModalConfirmacao(!props.modalConfirmacao);
                      /* props.setCartao({
                    BLOQUEADO: 0,
                    ID: cartao.CODCARTAO,
                    PARCEIRO: cartao.PARCEIRO,
                    DATA_VENCIMENTO: cartao.DATA_VENCIMENTO,
                  });
                  props.setModalConfirmacao(!props.modalConfirmacao); */
                    }}
                  >
                    Desbloquear
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    name="1"
                    color="warning"
                    style={{
                      alignContent: 'flex-end',
                    }}
                    onClick={() => {
                      props.setCartao({
                        BLOQUEADO: 1,
                        ID: stateCard.CODCARTAO,
                        PARCEIRO: stateCard.PARCEIRO,
                        DATA_VENCIMENTO: stateCard.DATA_VENCIMENTO,
                      });
                      props.setModalConfirmacao(!props.modalConfirmacao);
                      /*setCartao({
                    BLOQUEADO: 1,
                    ID: cartao.CODCARTAO,
                    PARCEIRO: cartao.PARCEIRO,
                    DATA_VENCIMENTO: cartao.DATA_VENCIMENTO,
                  });
                  setModalConfirmacao(!modalConfirmacao);*/
                    }}
                  >
                    Bloquear
                  </Button>
                )}
              </ButtonGroup>
            ) : (
              <div style={{ display: 'flex', gap: '1rem' }}>
                <SaldoStyle>
                  Saldo disponível:{' '}
                  <SaldoDisponivelColor color={reduceNegativo() >= 0 ? 'positivo' : 'negativo'}>
                    {formatMoney(reduceNegativo())}
                  </SaldoDisponivelColor>
                </SaldoStyle>

                <ButtonGroup>
                  <Button
                    color="primary"
                    size="sm"
                    disabled={
                      !stateCard.PRODUTOS.some((product) => product.hasOwnProperty('NOVO_SALDO')) ||
                      reduceNegativo() < 0
                    }
                    onClick={() => salvarSaldo()}
                  >
                    Salvar
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => {
                      setOperateBalance(!operateBalance);
                      buttonCancelAlterValue();
                    }}
                  >
                    Cancelar
                  </Button>
                </ButtonGroup>
              </div>
            )}
          </InfoCard>
          <ProductContent>
            {props.isConvened ? (
              <>
                {loadingRequest ? (
                  <Loading color="#2405f2" />
                ) : (
                  <div>
                    <ProductTitleAndBalance>
                      <div>
                        Produtos
                        <ReloadButtonStyle onClick={() => refreshProduct()}>
                          <FiRefreshCw />
                        </ReloadButtonStyle>
                      </div>
                      {SALDO &&
                        (cartao.TIPO_VINCULO == 1 ? (
                          <SaldoContainer>
                            <SaldoStyle>
                              Saldo Conta Digital: R$ <SaldoColor> {formatMoney(SALDO.SALDO_ATUAL)}</SaldoColor>
                            </SaldoStyle>
                            <SaldoStyle>
                              Saldo utilizado: R$
                              <SaldoUtilizadoColor> {formatMoney(SALDO.SALDO_UTILIZADO)}</SaldoUtilizadoColor>
                            </SaldoStyle>
                            {!operateBalance && (
                              <SaldoStyle>
                                Saldo disponível: R$
                                <SaldoDisponivelColor color={SALDO.SALDO_DISPONIVEL != '0' ? 'positivo' : ''}>
                                  {formatMoney(SALDO.SALDO_DISPONIVEL)}
                                </SaldoDisponivelColor>
                              </SaldoStyle>
                            )}
                          </SaldoContainer>
                        ) : (
                          <SaldoContainer>
                            <SaldoStyle>
                              Saldo Conta Cartão: R${' '}
                              <SaldoColor> {formatMoney(SALDO_CONTA_CARTAO.SALDO_ATUAL)}</SaldoColor>
                            </SaldoStyle>
                            <SaldoStyle>
                              Saldo utilizado: R$
                              <SaldoUtilizadoColor>
                                {' '}
                                {formatMoney(SALDO_CONTA_CARTAO.SALDO_UTILIZADO)}
                              </SaldoUtilizadoColor>
                            </SaldoStyle>
                            {!operateBalance && (
                              <SaldoStyle>
                                Saldo disponível: R$
                                <SaldoDisponivelColor
                                  color={SALDO_CONTA_CARTAO.SALDO_DISPONIVEL != '0' ? 'positivo' : ''}
                                >
                                  {formatMoney(SALDO_CONTA_CARTAO.SALDO_DISPONIVEL)}
                                </SaldoDisponivelColor>
                              </SaldoStyle>
                            )}
                          </SaldoContainer>
                        ))}
                    </ProductTitleAndBalance>
                    <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                      {stateCard && stateCard.PRODUTOS && stateCard.PRODUTOS.length ? (
                        stateCard.PRODUTOS.map((product) => (
                          <ProductCardsType>
                            <div id="cardType__title">{product.DESCRICAO_PRODUTO}</div>
                            <div id="cardType__balance">
                              Saldo atual:
                              <label id="cardType__balanceTitle">
                                <b>R$</b>
                                {formatMoney(product.SALDO)}
                              </label>
                            </div>
                            {operateBalance && (
                              <div id="cardType__novoSaldo">
                                {/* <label>Novo saldo:</label> */}
                                <label>Adicionar/Remover:</label>

                                <label style={{ display: 'flex', gap: '0.5rem' }}>
                                  <b>R$</b>
                                  <InputGroup size="sm">
                                    <Input
                                      type="number"
                                      name={product.ID}
                                      /* placeholder="Digite o novo saldo" */

                                      id="cardType__balanceTitle__input"
                                      onChange={(e) => alterValueCallback(e)}
                                      disabled={!operateBalance}
                                    />
                                  </InputGroup>
                                </label>
                              </div>
                            )}
                            {product.hasOwnProperty('NOVO_SALDO') && product.NOVO_SALDO >= 0 && (
                              <label>
                                Total:{' '}
                                <label
                                /* style={{
                              color: +product.NOVO_SALDO < +product.SALDO ? 'red' : 'green',
                            }} */
                                >
                                  R$ {formatMoney(product.NOVO_SALDO)}
                                </label>
                              </label>
                            )}
                          </ProductCardsType>
                        ))
                      ) : (
                        <div
                          style={{
                            width: '100%',
                            textAlign: 'center',
                            color: '#1C1C1CB5',
                            fontWeight: 600,
                            fontSize: 14,
                          }}
                        >
                          Cliente não possui produtos vinculados a esse cartão
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <ProductTitleAndBalance>
                  <p>Produtos</p>
                </ProductTitleAndBalance>
                <div style={{ textAlign: 'center', color: '#1C1C1CB5', fontWeight: 600, fontSize: 14 }}>
                  Cliente não conveniado
                </div>
              </>
            )}
          </ProductContent>
        </ModalBody>
      </Modal>
      <ModalMensagemSemReload
        modal={modalMensagem}
        mensagem={mensagem}
        sucesso={sucesso}
        toggle={(e) => {
          closeModalMessage();
        }}
      />

      <ModalDifferenceValue
        open={modalDifferenceValue.open}
        toggle={(e) => {
          setModalDifferenceValue({
            open: false,
            message: [],
          });
          refreshProduct();
        }}
        body={modalDifferenceValue.message}
      />
    </>
  );
}
