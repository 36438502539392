import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import api from '../../../service/api';
import {
  Box,
  Chip,
  Divider,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { AddCircleOutline, HighlightOff } from '@mui/icons-material';
import ModalMensagem from '../../../views/Transaction/ModalMensagem';
import Loading from '../../Loader/Loading';
import { isCnpj } from '../../../utils/isCpf';

function ModalReprovacao({ open, toggle }) {
  const [documents, setDocuments] = useState({});
  const [modalMensagem, setModalMensagem] = useState({ open: false, sucesso: true, mensagem: '' });
  const [loading, setLoading] = useState(false);
  const [personalInformations, setPersonalInformations] = useState({});
  const { user } = useSelector((state) => state.user);

  const [form, setForm] = useState({
    reason: '',
    personalInformations: {
      personal: {
        wrongIn: [],
        needTo: [],
      },
      documents: {
        wrongIn: [],
        needTo: [],
      },
    },
    representatives: [],
  });

  useEffect(() => {
    api.get('/coban/listar/docs').then(({ data }) => setDocuments(data.data));
    api.get('/coban/listar/informacoes_pessoais').then(({ data }) => setPersonalInformations(data.data));
  }, []);

  const handleToggle = () => {
    toggle();
    setForm({
      reason: '',
      personalInformations: {
        personal: {
          wrongIn: [],
          needTo: [],
        },
        documents: {
          wrongIn: [],
          needTo: [],
        },
      },
      representatives: [],
    });
  };

  const handleClick = () => {
    setLoading(true);
    const representativeMap = form.representatives.map((representative) => {
      return {
        MOTIVO_REPROVACAO_INFORMACOES_PESSOAIS: {
          NEED_TO: representative.personalInformations.personal.needTo,
          WRONG_IN: representative.personalInformations.personal.wrongIn,
        },
        MOTIVO_REPROVACAO_DOCUMENTOS: {
          NEED_TO: representative.personalInformations.documents.needTo,
          WRONG_IN: representative.personalInformations.documents.wrongIn,
        },
      };
    });

    api
      .post(`usuario/recusar/${user.UUID}`, {
        MOTIVO: form.reason,
        USUARIO: {
          MOTIVO_REPROVACAO_INFORMACOES_PESSOAIS: {
            NEED_TO: form.personalInformations.personal.needTo,
            WRONG_IN: form.personalInformations.personal.wrongIn,
          },
          MOTIVO_REPROVACAO_DOCUMENTOS: {
            NEED_TO: form.personalInformations.documents.needTo,
            WRONG_IN: form.personalInformations.documents.wrongIn,
          },
        },
        REPRESENTANTES: representativeMap,
      })
      .then(({ data }) => {
        if (data.retorno === 'sucesso') {
          setModalMensagem({ open: true, sucesso: true, mensagem: data.mensagem });
        } else {
          setModalMensagem({ open: true, sucesso: false, mensagem: data.mensagem });
        }
        setLoading(false);
      });
  };

  const renderRepresentatives = () => {
    return (
      <>
        {form.representatives.length > 0 && (
          <>
            <Typography variant="h4">Representantes</Typography>
            {form.representatives.map((representative, index) => (
              <Box mt={2}>
                <Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h5">Representante</Typography>
                    <IconButton
                      color="danger"
                      onClick={() => {
                        let representativesArray = form.representatives;
                        representativesArray.splice(index, 1);
                        setForm({ ...form, representatives: representativesArray });
                      }}
                    >
                      <HighlightOff />
                    </IconButton>
                  </Box>
                  <Select
                    onChange={({ target: { value } }) => {
                      let representativesArray = form.representatives;
                      representativesArray[index].id = value;
                      setForm({
                        ...form,
                        representatives: representativesArray,
                      });
                    }}
                  >
                    {user.REPRESENTANTES &&
                      user.REPRESENTANTES.length > 0 &&
                      user.REPRESENTANTES.map((representante) => (
                        <MenuItem key={representante.ID} value={representante.ID}>
                          {representante.NOME}
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
                <Box mt={1}>
                  <Typography variant="h5">Errado em:</Typography>
                  <Box mt={1}>
                    <Typography>Selecione informações pessoais</Typography>
                    <Select
                      multiple
                      fullWidth
                      value={representative.personal.wrongIn}
                      label="Selecione informações pessoais"
                      placeholder="Selecione informações pessoais"
                      onChange={({ target: { value } }) => {
                        let representativesArray = form.representatives;
                        representativesArray[index].personal.wrongIn = value;
                        setForm({
                          ...form,
                          representatives: representativesArray,
                        });
                      }}
                      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={personalInformations.find(({ ID }) => ID === value).DESCRICAO} />
                          ))}
                        </Box>
                      )}
                    >
                      {personalInformations &&
                        personalInformations.length > 0 &&
                        personalInformations.map((personalInformation) => (
                          <MenuItem key={personalInformation.ID} value={personalInformation.ID}>
                            {personalInformation.DESCRICAO}
                          </MenuItem>
                        ))}
                    </Select>
                  </Box>
                  <Box mt={1}>
                    <Typography>Selecione documentos pessoais</Typography>
                    <Select
                      multiple
                      fullWidth
                      value={representative.documents.wrongIn}
                      label="Selecione documentos pessoais"
                      placeholder="Selecione documentos pessoais"
                      onChange={({ target: { value } }) => {
                        let representativeArray = form.representatives;
                        representativeArray[index].documents.wrongIn = value;
                        setForm({
                          ...form,
                          representatives: representativeArray,
                        });
                      }}
                      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={documents.find(({ COD }) => COD === value).DESCRICAO} />
                          ))}
                        </Box>
                      )}
                    >
                      {documents &&
                        documents.length > 0 &&
                        documents.map((personalInformation) => (
                          <MenuItem key={personalInformation.COD} value={personalInformation.COD}>
                            {personalInformation.DESCRICAO}
                          </MenuItem>
                        ))}
                    </Select>
                  </Box>
                </Box>
                <Divider sx={{ margin: '1rem 0' }} />
                <Box mt={1}>
                  <Typography variant="h5">Necessita de:</Typography>
                  <Box mt={1}>
                    <Typography>Selecione informações pessoais</Typography>
                    <Select
                      multiple
                      fullWidth
                      value={representative.personal.needTo}
                      label="Selecione informações pessoais"
                      placeholder="Selecione informações pessoais"
                      onChange={({ target: { value } }) => {
                        let representativeArray = form.representatives;
                        representativeArray[index].personal.needTo = value;
                        setForm({
                          ...form,
                          representatives: representativeArray,
                        });
                      }}
                      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={personalInformations.find(({ ID }) => ID === value).DESCRICAO} />
                          ))}
                        </Box>
                      )}
                    >
                      {personalInformations &&
                        personalInformations.length > 0 &&
                        personalInformations.map((personalInformation) => (
                          <MenuItem key={personalInformation.ID} value={personalInformation.ID}>
                            {personalInformation.DESCRICAO}
                          </MenuItem>
                        ))}
                    </Select>
                  </Box>
                  <Box mt={1}>
                    <Typography>Selecione documentos pessoais</Typography>
                    <Select
                      multiple
                      fullWidth
                      value={representative.documents.needTo}
                      label="Selecione documentos pessoais"
                      placeholder="Selecione documentos pessoais"
                      onChange={({ target: { value } }) => {
                        let representativeArray = form.representatives;
                        representativeArray[index].documents.needTo = value;
                        setForm({
                          ...form,
                          representatives: representativeArray,
                        });
                      }}
                      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={documents.find(({ COD }) => COD === value).DESCRICAO} />
                          ))}
                        </Box>
                      )}
                    >
                      {documents &&
                        documents.length > 0 &&
                        documents.map((personalInformation) => (
                          <MenuItem key={personalInformation.COD} value={personalInformation.COD}>
                            {personalInformation.DESCRICAO}
                          </MenuItem>
                        ))}
                    </Select>
                  </Box>
                </Box>
              </Box>
            ))}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Modal isOpen={open} toggle={handleToggle}>
        <ModalHeader toggle={handleToggle}>Observações</ModalHeader>
        <ModalBody>
          {loading ? (
            <Loading color="blue" />
          ) : (
            <>
              <Box mt={1}>
                <Typography variant="h5">Errado em:</Typography>
                <Box mt={1}>
                  <Typography>Selecione informações pessoais</Typography>
                  <Select
                    multiple
                    fullWidth
                    value={form.personalInformations.personal.wrongIn}
                    label="Selecione informações pessoais"
                    placeholder="Selecione informações pessoais"
                    onChange={({ target: { value } }) =>
                      setForm({
                        ...form,
                        personalInformations: {
                          personal: { ...form.personalInformations.personal, wrongIn: value },
                          documents: { ...form.personalInformations.documents },
                        },
                      })
                    }
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={personalInformations.find(({ ID }) => ID === value).DESCRICAO} />
                        ))}
                      </Box>
                    )}
                  >
                    {personalInformations &&
                      personalInformations.length > 0 &&
                      personalInformations.map((personalInformation) => (
                        <MenuItem key={personalInformation.ID} value={personalInformation.ID}>
                          {personalInformation.DESCRICAO}
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
                <Box mt={1}>
                  <Typography>Selecione documentos pessoais</Typography>
                  <Select
                    multiple
                    fullWidth
                    value={form.personalInformations.documents.wrongIn}
                    label="Selecione documentos pessoais"
                    placeholder="Selecione documentos pessoais"
                    onChange={({ target: { value } }) =>
                      setForm({
                        ...form,
                        personalInformations: {
                          personal: { ...form.personalInformations.personal },
                          documents: { ...form.personalInformations.documents, wrongIn: value },
                        },
                      })
                    }
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={documents.find(({ COD }) => COD === value).DESCRICAO} />
                        ))}
                      </Box>
                    )}
                  >
                    {documents &&
                      documents.length > 0 &&
                      documents.map((personalInformation) => (
                        <MenuItem key={personalInformation.COD} value={personalInformation.COD}>
                          {personalInformation.DESCRICAO}
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
              </Box>
              <Divider sx={{ margin: '1rem 0' }} />
              <Box mt={1}>
                <Typography variant="h5">Necessita de:</Typography>
                <Box>
                  <Typography>Selecione informações pessoais</Typography>
                  <Select
                    multiple
                    fullWidth
                    value={form.personalInformations.personal.needTo}
                    label="Selecione informações pessoais"
                    placeholder="Selecione informações pessoais"
                    onChange={({ target: { value } }) =>
                      setForm({
                        ...form,
                        personalInformations: {
                          personal: { ...form.personalInformations.personal, needTo: value },
                          documents: { ...form.personalInformations.documents },
                        },
                      })
                    }
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={personalInformations.find(({ ID }) => ID === value).DESCRICAO} />
                        ))}
                      </Box>
                    )}
                  >
                    {personalInformations &&
                      personalInformations.length > 0 &&
                      personalInformations.map((personalInformation) => (
                        <MenuItem key={personalInformation.ID} value={personalInformation.ID}>
                          {personalInformation.DESCRICAO}
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
                <Box mt={1}>
                  <Typography>Selecione documentos pessoais</Typography>
                  <Select
                    multiple
                    fullWidth
                    value={form.personalInformations.documents.needTo}
                    label="Selecione documentos pessoais"
                    placeholder="Selecione documentos pessoais"
                    onChange={({ target: { value } }) =>
                      setForm({
                        ...form,
                        personalInformations: {
                          personal: { ...form.personalInformations.personal },
                          documents: { ...form.personalInformations.documents, needTo: value },
                        },
                      })
                    }
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={documents.find(({ COD }) => COD === value).DESCRICAO} />
                        ))}
                      </Box>
                    )}
                  >
                    {documents &&
                      documents.length > 0 &&
                      documents.map((personalInformation) => (
                        <MenuItem key={personalInformation.COD} value={personalInformation.COD}>
                          {personalInformation.DESCRICAO}
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
                {user && isCnpj(user.CPFCNPJ) && (
                  <>
                    {renderRepresentatives()}
                    <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                      <IconButton
                        color="success"
                        onClick={() => {
                          let representatives = form.representatives;
                          representatives.push({
                            id: '',
                            personal: {
                              wrongIn: [],
                              needTo: [],
                            },
                            documents: {
                              wrongIn: [],
                              needTo: [],
                            },
                          });
                          setForm({ ...form, representatives });
                        }}
                      >
                        <AddCircleOutline />
                      </IconButton>
                    </Box>
                  </>
                )}
              </Box>
              <Box>
                <Typography variant="h5">Observação</Typography>
                <TextareaAutosize
                  style={{ width: '100%' }}
                  value={form.reason}
                  onChange={({ target: { value } }) => setForm({ ...form, reason: value })}
                />
              </Box>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {!loading && (
            <>
              <Button color="danger" onClick={handleToggle}>
                Cancelar
              </Button>
              <Button color="success" onClick={handleClick}>
                Enviar
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
      <ModalMensagem
        modal={modalMensagem.open}
        toggle={() => setModalMensagem({ open: false, sucesso: true, mensagem: '' })}
        sucesso={modalMensagem.sucesso}
        mensagem={modalMensagem.mensagem}
      />
    </>
  );
}

export default ModalReprovacao;
