import React, { useEffect, useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, MenuItem, Select, Typography } from '@mui/material';

import RendimentosPDF from '../RendimentosPDF/RendimentosPDF';

export default function UserReports(props) {
  const [incomeExpanded, setIncomeExpanded] = useState(true);
  const [choosenAccount, setChoosenAccount] = useState(false);
  const [choosenYear, setChoosenYear] = useState(false);
  const [yearsSelect, setYearsSelect] = useState(false);

  const styles = {
    textForm: {
      fontSize: '0.9em',
      color: '#8898aa',
      marginRight: '10px',
      marginBottom: '0',
    },
  };

  function getYears() {
    const date = new Date();
    let year = date.getFullYear();
    setChoosenYear(+year - 1);
    const years = [];

    while (+year > 2013) {
      year = year - 1;
      years.push(year);
    }
    setYearsSelect(years);
  }

  useEffect(() => {
    getYears();
    if (props.user) {
      if (props.user.CONTA_BACEN && props.user.CONTA_BACEN.length) {
        setChoosenAccount(props.user.CONTA_BACEN[0].CONTA_DIGITAL_ID);
      }
    }
  }, [props.user]);

  return (
    <>
      <Accordion expanded={incomeExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMore onClick={() => setIncomeExpanded(!incomeExpanded)} />}
          aria-controls="panel2a-content"
          id="panel2a-content"
        >
          <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
            Rendimentos
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {props.user && props.user.CONTA_BACEN && props.user.CONTA_BACEN.length ? (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
                  <Typography sx={styles.textForm}>
                    <b>Conta: </b>{' '}
                  </Typography>
                  {props.user && (
                    <>
                      <Select value={choosenAccount} onChange={(e) => setChoosenAccount(e.target.value)}>
                        {props.user.CONTA_BACEN &&
                          props.user.CONTA_BACEN.length > 0 &&
                          props.user.CONTA_BACEN.map((account) => (
                            <MenuItem
                              key={account.CONTA_DIGITAL_ID}
                              value={account.CONTA_DIGITAL_ID}
                            >{`${account.CONTA}-${account.DIGITO}`}</MenuItem>
                          ))}
                      </Select>
                    </>
                  )}
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={styles.textForm}>
                    <b>Ano: </b>{' '}
                  </Typography>
                  {yearsSelect && choosenYear && (
                    <>
                      <Select
                        defaultValue={parseInt(new Date().getFullYear()) - 1}
                        value={choosenYear}
                        onChange={(e) => setChoosenYear(e.target.value)}
                      >
                        {yearsSelect.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </div>
              </div>

              <div style={{ height: '800px', marginTop: '1rem' }}>
                <RendimentosPDF UUID={props.user.UUID} ANO_REFERENCIA={choosenYear} CONTA_DIGITAL_ID={choosenAccount} />
              </div>
            </>
          ) : (
            <h3>Usuário não possui conta bancária</h3>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
