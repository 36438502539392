import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'reactstrap';
import { logout } from '../../store/auth/actions';
import ModalConfirmation from '../../components/Modais/Confirmar/ModalConfirmar';
import Timer from '../Timer/Timer';

const AdminNavbar = (props) => {
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [title, setTitle] = useState('');

  const logoutSystem = () => {
    props.logout(() => props.history.push('/login'));
  };

  const logoutConfirmation = () => {
    setTitle('Deseja mesmo sair?');
    setModalConfirmation(true);
  };

  const validateJwts =
    props.history.location.pathname != '/' &&
    props.history.location.pathname != '/login' &&
    JSON.parse(window.atob(localStorage.getItem('is_authenticate').split('.')[1])).exp;

    const jwtDateTime = new Date(validateJwts * 1000);
    jwtDateTime.setSeconds(jwtDateTime.getSeconds());  

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main" style={{ top: 70 }}>
        <ModalConfirmation
          open={modalConfirmation}
          close={() => setModalConfirmation(false)}
          title={title}
          buttonTexts={[
            { name: 'Sair', color: '#1171ef' },
            { name: 'Voltar', color: '#C0C0C0' },
          ]}
          callFunction={() => logoutSystem()}
        />

        <Container fluid>
          <Link className="h1 mb-0 text-white text-capitalize d-none d-lg-inline-block" to="/">
            {props.brandText}
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <div> {props.history.location.pathname != '/login' && <Timer expiryTimestamp={jwtDateTime} />}</div>

            <div style={{ color: 'white', justifyItems: 'center' }}>
              <button
                style={{ backgroundColor: 'transparent', border: 0, color: 'white', justifyItems: 'center' }}
                onClick={() => logoutConfirmation()}
              >
                <i className="ni ni-user-run" />
                <span style={{ cursor: 'pointer' }}> Sair</span>
              </button>
            </div>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default connect(mapStateToProps, {
  logout,
})(AdminNavbar);
