import React from 'react';
import formatMoney from '../../../../utils/formatMoneyBRL';
import { CardContainer, CardNãoPossuiStyle, CardStyle, ButtonMoreCards } from './style';
import { BsChevronCompactRight } from 'react-icons/bs';

export default function ProductCardsCard({ allCards, setInfoCard, cartao, saldoGeral }) {
  function showFourCardsMax(cards) {
    if (cards.length > 0) {
      const slicedArray = cards.slice(0, 4);
      const result = slicedArray.map((card) => (
        <CardStyle>
          <div>{card.DESCRICAO_PRODUTO}</div>
          <div>R$ {formatMoney(card.SALDO)}</div>
        </CardStyle>
      ));
      if (cards.length > 4) {
        result.push(
          <ButtonMoreCards onClick={() => setInfoCard({ open: true, cartao: cartao, SALDO: saldoGeral })}>
            <BsChevronCompactRight />
          </ButtonMoreCards>,
        );
      }

      return result;
    }
  }

  return (
    <>
      {allCards && allCards.length > 0 ? (
        <CardContainer>{showFourCardsMax(allCards)}</CardContainer>
      ) : (
        <CardContainer>
          <CardNãoPossuiStyle>Não possui</CardNãoPossuiStyle>
        </CardContainer>
      )}
    </>
  );
}
