import React, { useState, useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';

import { Card, CardHeader, CardBody, Container, Row, Col, Badge } from 'reactstrap';
import ModalCliente from '../../components/Modais/Cliente/ModalCliente.jsx';
import { formatMoney } from '../../utils/formatMoneyBRL.js';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  listInformations,
  listMovimentacao,
  getSaques,
  getAllUsers,
  movimentacaoDashboard,
} from '../../store/dashboard/actions';
import { listarCoban } from '../../store/coban/actions';
import { listaUserDetalhes } from '../../store/user/actions.js';

import Table from '../../components/Table/Table';
import moment from 'moment';

const Dashboard = (props) => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    var flagDispatch = true;
    const filtro = { page: 1, limit: 20 };
    props.listMovimentacao(filtro, flagDispatch);
    props.getAllUsers(filtro, flagDispatch);
    props.listarCoban();
    props.getSaques();
    props.movimentacaoDashboard();
  }, []);

  var { userPagination, listUsers } = props.dashboard;
  listUsers = listUsers == null ? [] : listUsers;

  const movimentacaoTable = () => {
    var { listMovimentacaoDia, pagination } = props.dashboard;
    var controle = true;

    const fetchMoreData = () => {
      const flagDispatch = false;
      const filtro = { limit: 20, page: pagination.nextPage };
      props.listMovimentacao(filtro, flagDispatch);
    };

    if (pagination.totalResult <= listMovimentacaoDia.length) {
      controle = false;
    }

    return (
      <Card className="mt-2 mb-2">
        <CardBody className="mb-2">
          <InfiniteScroll
            dataLength={props.dashboard.listMovimentacaoDia.length}
            next={() => fetchMoreData()}
            hasMore={controle}
            height={240}
            loader={<h4>loading...</h4>}
          >
            <Table
              headers={[
                {
                  info: 'hora',
                  style: styles.centralizaMensagem,
                },
                {
                  info: 'operação',
                  style: { textAlign: 'left' },
                },
                {
                  info: 'valor',
                  style: { textAlign: 'right' },
                },
                {
                  info: 'status',
                  style: styles.centralizaMensagem,
                },
              ]}
              data={props.dashboard.listMovimentacaoDia.map((a) => {
                return {
                  data: [
                    {
                      info: a.HORA,
                      style: styles.centralizaMensagem,
                    },
                    {
                      info: a.OPERACAO,
                      style: { textAlign: 'left' },
                    },
                    {
                      info: 'R$ ' + formatMoney(a.VALOR),
                      style: { textAlign: 'right' },
                    },
                    {
                      info:
                        a.STATUS == 2 ? (
                          <i class="fa fa-arrow-up" style={{ color: '#29ff54' }}></i>
                        ) : (
                          <i class="fa fa-arrow-down" style={{ color: 'red' }}></i>
                        ),
                      style: { textAlign: 'center' },
                    },
                  ],
                };
              })}
            />
            {listMovimentacaoDia.length == 0 ? (
              <h3 style={styles.centralizaMensagem}>Nenhuma operação registrada</h3>
            ) : null}
          </InfiniteScroll>
        </CardBody>
      </Card>
    );
  };

  const tableUsers = () => {
    var controle = true;

    const fetchMoreData = () => {
      const flagDispatch = false;
      const filtro = { limit: 20, page: userPagination.nextPage };
      props.getAllUsers(filtro, flagDispatch);
    };
    if (userPagination && listUsers && userPagination.totalResult <= listUsers.length) {
      controle = false;
    }

    const checkStatus = (status, cpf, uuid) => {
      if (status == 'APROVADO' || status == 'BLOQUEADO') {
        window.location.href = 'gerenciar-usuarios/view?user=' + uuid;
      } else {
        props.listaUserDetalhes(uuid);
        setModal(!modal);
      }
    };

    return (
      <Card style={{ marginBottom: 10 }}>
        <CardBody>
          <InfiniteScroll
            dataLength={listUsers && listUsers.length}
            next={() => fetchMoreData()}
            hasMore={controle}
            height={'40vh'}
            loader={''}
          >
            <Table
              headers={[
                {
                  info: 'DATA DE ENTRADA',
                  style: { textAlign: 'center' },
                },
                {
                  info: 'NOME',
                  style: { textAlign: 'left' },
                },
                {
                  info: 'STATUS',
                  style: styles.centralizaMensagem,
                },
              ]}
              data={
                listUsers &&
                listUsers.map((a) => {
                  return {
                    data: [
                      {
                        info: a.DATA,
                        style: styles.centralizaMensagem,
                      },
                      {
                        info: a.NOME,
                        style: { textAlign: 'left' },
                      },
                      {
                        info:
                          a.CODSTATUS == 1 ? (
                            <Badge color="info">{a.STATUS}</Badge>
                          ) : a.CODSTATUS == 2 ? (
                            <Badge color="success">{a.STATUS}</Badge>
                          ) : a.CODSTATUS == 3 ? (
                            <Badge color="danger">{a.STATUS}</Badge>
                          ) : a.CODSTATUS == 4 ? (
                            <Badge color="warning">{a.STATUS}</Badge>
                          ) : a.CODSTATUS == 5 ? (
                            <Badge color="primary">{a.STATUS}</Badge>
                          ) : null,
                        style: styles.centralizaMensagem,
                      },
                    ],
                    onClick: () => checkStatus(a.STATUS, a.CPFCNPJ, a.UUID),
                  };
                })
              }
            />
            {listUsers == null ? <h3 style={styles.centralizaMensagem}>Nenhum usuário registrado</h3> : null}
          </InfiniteScroll>
        </CardBody>
      </Card>
    );
  };

  const backgroundColor1 = '#2405f2';
  const backgroundColor2 = '#ffa300';

  const formatDatas = (datas) => {
    var dataFormatada = [];
    dataFormatada = datas.map((num) => {
      var value = [];
      value = moment(num).isValid() ? moment(num).format('DD/MM/YYYY') : num;
      return value;
    });

    return dataFormatada;
  };

  //funçao para formatar as datas vindo da tabela 'entrada de clientes'
  const formatTableData = (data) => {
    var valor = data.substring(0, 6) + '20' + data.substring(6, 8);
    return valor;
  };

  const listUsersCondition = listUsers && listUsers[0];

  const verifyDateExists = () => {
    if (listUsersCondition && listUsers[0].DATA) return true;
    else {
      return false;
    }
  };

  const { transacaoDebito, transacaoCredito } = props.dashboard;

  var datas = Object.keys(transacaoDebito);

  var ultimosSeteDias = formatDatas(datas);

  let usuariosPendentes = [];
  let usuariosAprovados = [];
  let usuariosTelenet = [];
  let usuariosPendentesChart = [];
  let usuariosAprovadosChart = [];
  let usuariosTelenetChart = [];

  // utilizado quando a api nao retorna nenhum valor (undefined)
  let dadosDefaultChart = [0, 0, 0, 0, 0, 0, 0];
  if (verifyDateExists) {
    listUsers &&
      listUsers.length > 0 &&
      listUsers.map((user) => {
        if (user.CODSTATUS === '2') usuariosPendentes.push(formatTableData(user.DATA));
        if (user.CODSTATUS === '1') usuariosAprovados.push(formatTableData(user.DATA));
        if (user.CODSTATUS === '5') usuariosTelenet.push(formatTableData(user.DATA));
      });

    ultimosSeteDias.forEach((dia) => {
      const verificarClientePertence = (dataCliente) => dataCliente === dia;

      usuariosAprovadosChart.push(usuariosAprovados.filter(verificarClientePertence).length);
      usuariosPendentesChart.push(usuariosPendentes.filter(verificarClientePertence).length);
      usuariosTelenetChart.push(usuariosTelenet.filter(verificarClientePertence).length);
    });
  }

  return (
    <>
      <Container className="mt--7" fluid>
        <Row className="mt-5 justify-content-center">
          <Col className="mb-5 mb-xl-0" xl="6">
            <Card className="shadow" style={{ height: '100%' }}>
              <CardHeader style={styles.tituloGrafico}>
                <h2>Entrada de clientes </h2>
                <h6 style={styles.subtituloGrafico}>(Últimos 7 dias)</h6>
              </CardHeader>
              <CardBody>
                <Card style={{ height: '40vh' }}>
                  <Line
                    data={{
                      labels: ultimosSeteDias,
                      datasets: [
                        {
                          label: 'Aprovados',
                          fill: false,
                          data: usuariosAprovadosChart,
                          borderColor: backgroundColor1,
                          backgroundColor: backgroundColor1,
                        },
                        {
                          label: 'Pendentes',
                          fill: false,
                          data: usuariosPendentesChart,
                          borderColor: backgroundColor2,
                          backgroundColor: backgroundColor2,
                        },
                        {
                          label: 'Aprovados Cartão Telenet',
                          fill: false,
                          data: usuariosTelenetChart,
                          borderColor: 'green',
                          backgroundColor: 'green',
                        },
                      ],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          ticks: {
                            precision: 0,
                          },
                        },
                      },
                      plugins: {
                        legend: {
                          display: true,
                          position: 'bottom',

                          labels: {
                            usePointStyle: true,
                          },
                        },
                      },
                    }}
                  />
                </Card>
              </CardBody>
            </Card>
          </Col>
          <Col className="mb-5 mb-xl-0" xl="6">
            <Card className="shadow" style={{ height: '100%' }}>
              <CardHeader style={styles.tituloGrafico}>
                <h2>Transações </h2>
                <h6 style={styles.subtituloGrafico}>(Últimos 7 dias)</h6>
              </CardHeader>
              <CardBody>
                <Card style={{ height: '40vh' }}>
                  <Bar
                    data={{
                      labels: ultimosSeteDias,
                      datasets: [
                        {
                          label: 'Crédito',
                          data: transacaoCredito != undefined ? Object.values(transacaoCredito) : dadosDefaultChart,
                          backgroundColor: '#0CB42A',
                          borderColor: '#0CB42A',
                        },
                        {
                          label: 'Débito',
                          data: transacaoDebito != undefined ? Object.values(transacaoDebito) : dadosDefaultChart,
                          backgroundColor: '#EA6852',
                          borderColor: '#EA6852',
                        },
                      ],
                    }}
                    options={{
                      scales: {
                        y: {
                          ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value) {
                              return 'R$ ' + formatMoney(value);
                            },
                          },
                        },
                      },
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          display: true,
                          position: 'bottom',

                          labels: {
                            usePointStyle: true,
                          },
                        },
                        tooltip: {
                          callbacks: {
                            label: function (context) {
                              var currentDataValue = context.raw;
                              return 'R$ ' + formatMoney(currentDataValue);
                            },
                          },
                        },
                      },
                    }}
                  />
                </Card>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5 justify-content-center">
          <Col className="mb-5 mb-xl-0" xl="6">
            <Card className="shadow" style={{ height: '100%' }}>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Movimentações do dia</h3>
                  </div>
                  <div className="col text-right">
                    <h6 className="text-uppercase text-dark ls-1 mb-1">
                      Quantidade: {props.dashboard.listMovimentacaoDia.length}
                    </h6>
                  </div>
                </Row>
              </CardHeader>
              {movimentacaoTable()}
            </Card>
          </Col>
          <Col xl="6">
            <Card className="shadow" style={{ height: '100%' }}>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Entrada de clientes</h3>
                  </div>
                  <div className="col text-right">
                    <h6 className="text-uppercase text-dark ls-1 mb-1">
                      Quantidade: {props.dashboard && props.dashboard.listUsers && props.dashboard.listUsers.length}
                    </h6>
                  </div>
                </Row>
              </CardHeader>
              {tableUsers()}
            </Card>
          </Col>
        </Row>
      </Container>
      <ModalCliente modal={modal} toggle={() => setModal(!modal)} />
    </>
  );
};

const styles = {
  centralizaMensagem: { textAlign: 'center' },

  tituloGrafico: {
    display: 'flex',
    alignItems: 'center',
  },
  subtituloGrafico: { textTransform: 'uppercase', margin: '0 0 0.20vw 0.4vw' },
};

const mapStateToProps = ({ auth, dashboard }) => {
  return { auth, dashboard };
};

export default connect(mapStateToProps, {
  listInformations,
  listaUserDetalhes,
  listMovimentacao,
  movimentacaoDashboard,
  getSaques,
  getAllUsers,
  listarCoban,
})(Dashboard);
