import React from 'react';
import { renderTaxasUsuario } from '../../../../components/Taxes/renderTaxas';
import { Box, Typography } from '@mui/material';
import { Button } from 'reactstrap';

function Taxs({ setModalSenhaUnica, setOperation, user, user: { NOMECOBAN, TAXAS, FINANCIAMENTO, BENEFICIOS } }) {
  function handleClick(operation) {
    setModalSenhaUnica(true);
    setOperation(operation);
  }

  return (
    <Box mt={2}>
      {(localStorage.getItem('nivelAcesso') == '6' && user.ID_TIPO_USUARIO == '2') ||
        (localStorage.getItem('nivelAcesso') != '6' && (
          <Button
            color="primary"
            size="sm"
            style={{ borderRadius: '5px' }}
            onClick={() => handleClick('changeProfile')}
          >
            Alterar Perfil
          </Button>
        ))}
      <Box sx={{ mt: 2 }}>
        <p
          style={{
            display: 'block',
            marginBottom: '1rem',
            fontSize: '0.9em',
            color: '#8898aa',
            fontWeight: 'bold',
          }}
        >
          Perfil: <span style={{ fontWeight: '100', color: 'black' }}>{NOMECOBAN} </span>
        </p>

        {TAXAS && renderTaxasUsuario(TAXAS, FINANCIAMENTO, BENEFICIOS)}
      </Box>
    </Box>
  );
}

export default Taxs;
