import styled from 'styled-components';
import TextField from '@mui/material/TextField';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const LeftSideStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 440px;
`;

export const RightSideStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 440px;
  margin-top: 13px;
  margin-left: 30px;
`;

export const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
`;

export const CloseStyle = styled.div`
  color: #a9a9a9;
  cursor: pointer;
`;

export const HeaderStyle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
`;

export const TitleStyle = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 600;
`;

export const SelectMain = styled.div`
  margin-top: 10px;
`;

export const PostButton = styled.button`
  height: 2.0833333333333335vw;
  width: 120px;
  font-size: 14px;
  border-radius: 2px;
  background-color: #2196f3;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    transform: translateY(-1px);
  }
`;

export const ButtonMain = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 22px;
  margin-bottom: -1vh;
`;

export const ContainerVerifyStyle = styled.div`
  margin-top: 23px;
  font-size: 10px;
  font-weight: 400;
  /*   margin-bottom: 35px; */
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /*  justify-content: space-between; */
  width: 49%;
`;

export const VerifyTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 4px;
`;

export const VerifyStyle = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => (props.verify ? '#32A54A' : '#D72700')};
`;

export const VerifyIconStyle = styled.div`
  margin-right: 4px;
  font-size: 12px;
`;

export const InputMaterial = styled(TextField)`
  border: 1px solid #ced4da;
  border-radius: 6px;
  width: 407px;
  height: 54px;
  border: 1px solid #828282;
`;

export const DivPassword = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`;

export const IconStyle = styled.i`
  position: absolute;
  right: 80px;
  cursor: pointer;
  font-size: 25px;
  color: #777777;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 100%;
`;

export const SaldoContainer = styled.div`
  display: flex;
`;

export const SaldoStyle = styled.div`
  margin-top: 0.2rem;
  display: flex;
  flex-direction: column;
  width: 140px;
  font-size: 0.8rem;
  line-height: 112%;
`;

export const SaldoStyleEnd = styled(SaldoStyle)`
  align-items: flex-end;

  text-align: end;
`;

export const SaldoColor = styled.div`
  margin-top: 0.2rem;
  font-weight: 600;
  color: #32325d;
  font-size: 1rem;
`;

export const SaldoUtilizadoColor = styled.div`
  margin-top: 0.2rem;
  color: #dc2d06;
  font-weight: 600;
  font-size: 1rem;
`;

export const SaldoDisponivelColor = styled.div`
  margin-top: 0.2rem;
  color: #1ba305;
  font-weight: 600;
  font-size: 1rem;
`;
