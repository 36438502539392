import styled from 'styled-components';

export const ContainerStatus = styled.div`
  background-color: ${(props) => props.background};
  border-radius: 5px;
  padding: ${(props) => (props.padding ? props.padding : '2px 0.833vw')};

  display: inline-flex;
  color: ${(props) => props.color};
  text-transform: uppercase;
`;
