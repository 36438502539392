import LoadingOverlay from 'react-loading-overlay';
import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SyncLoader from 'react-spinners/SyncLoader';
import './AnimetedLoading.css';

const AnimetedLoading = (props) => {
  return (
    <Modal isOpen={props.isActive} fullscreen>
      <LoadingOverlay
        active={props.isActive}
        spinner={
          <SyncLoader
            size={30}
            css={{
              paddingBottom: '2rem',
              display: 'flex',
              width: '50%',
              justifyContent: 'space-around',
              marginLeft: '7rem',
            }}
          />
        }
        text={<h1>Sua requisição está sendo processada...</h1>}
      ></LoadingOverlay>
    </Modal>
  );
};

export default AnimetedLoading;
