import React from 'react';

function Icon(parans) {
  return (
    <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.83329 12.7499L14.9166 7.66659L16.6666 9.41658L11.5833 14.4999H9.83329V12.7499ZM16.8333 6.41659L17.9166 7.49992C18.0833 7.66659 18.0833 7.91659 17.9166 8.08325L17.0833 8.91658L15.3333 7.24992L16.1666 6.41659C16.25 6.33325 16.3333 6.24992 16.5 6.24992C16.6666 6.24992 16.75 6.33325 16.8333 6.41659ZM8.16663 11.9999H2.33329V6.99992H13.25L17.3333 2.91659V1.99992C17.3333 1.08325 16.5833 0.333252 15.6666 0.333252H2.33329C1.41663 0.333252 0.666626 1.08325 0.666626 1.99992V11.9999C0.666626 12.9166 1.41663 13.6666 2.33329 13.6666H8.16663V11.9999ZM2.33329 1.99992H15.6666V3.66659H2.33329V1.99992Z"
        fill="black"
      />
    </svg>
  );
}

export default Icon;
