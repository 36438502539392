import React, { useEffect } from 'react';
import * as Styled from './Steps.styles';
import { useDispatch, useSelector } from 'react-redux';
import { listarProduto } from '../../../../../store/swap/actions';

const StepThree = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listarProduto());
  }, []);

  const { listagemProduto, loadingProduct } = useSelector((seg) => seg.swap);

  const listNameP = (val) => {
    let listProduct;
    listProduct = listagemProduto.filter((e) => e.ID === val);
    return listProduct[0].DESCRICAO;
  };

  return (
    <Styled.Detail>
      <Styled.InformationId>
        <h3>ID Cartão Swap: {props.dataId}</h3>
      </Styled.InformationId>
      {props.dataProd.map((val) => (
        <>
          <Styled.Line />
          <Styled.Container>
            <Styled.Information>
              <h6>ID Cartão Telenet:</h6>
              <span>{val.ID}</span>
            </Styled.Information>
            <Styled.Information>
              <h6>Produtos:</h6>
              <span>{listNameP(val.SELECT)}</span>
              {/* <span>{val.SELECT.join('')}</span> */}
            </Styled.Information>
          </Styled.Container>
          <Styled.Line />
        </>
      ))}
    </Styled.Detail>
  );
};

export default StepThree;
