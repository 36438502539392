export const SWAP_ADD_PRODUCT = 'SWAP_ADD_PRODUCT';
export const SWAP_ADD_PRODUCT_FAIL = 'SWAP_ADD_PRODUCT_FAIL';
export const SWAP_ADD_PRODUCT_SUCCESS = 'SWAP_ADD_PRODUCT_SUCCESS';
export const SWAP_EDIT_PRODUCT = 'SWAP_EDIT_PRODUCT';
export const SWAP_EDIT_PRODUCT_FAIL = 'SWAP_EDIT_PRODUCT_FAIL';
export const SWAP_EDIT_PRODUCT_SUCCESS = 'SWAP_EDIT_PRODUCT_SUCCESS';
export const SWAP_ADD_SEGMENTS_PRODUCT = 'SWAP_ADD_SEGMENTS_PRODUCT';
export const SWAP_ADD_SEGMENTS_PRODUCT_FAIL = 'SWAP_ADD_SEGMENTS_PRODUCT_FAIL';
export const SWAP_ADD_SEGMENTS_PRODUCT_SUCCESS = 'SWAP_ADD_SEGMENTS_PRODUCT_SUCCESS';
export const SWAP_LIST_SEGMENTS = 'SWAP_LIST_SEGMENTS';
export const SWAP_LIST_SEGMENTS_FAIL = 'SWAP_LIST_SEGMENTS_FAIL';
export const SWAP_LIST_SEGMENTS_SUCCESS = 'SWAP_LIST_SEGMENTS_SUCCESS';
export const SWAP_LIST_PRODUCT = 'SWAP_LIST_PRODUCT';
export const SWAP_LIST_PRODUCT_SEGMENT = 'SWAP_LIST_PRODUCT_SEGMENT';
export const SWAP_LIST_PRODUCT_FAIL = 'SWAP_LIST_PRODUCT_FAIL';
export const SWAP_LIST_PRODUCT_SUCCESS = 'SWAP_LIST_PRODUCT_SUCCESS';
export const SWAP_LIST_PRODUCT_SEGMENT_SUCCESS = 'SWAP_LIST_PRODUCT_SEGMENT_SUCCESS';
export const SWAP_LIST = 'SWAP_LIST';
export const SWAP_LIST_FAIL = 'SWAP_LIST_FAIL';
export const SWAP_LIST_SUCCESS = 'SWAP_LIST_SUCCESS';

export const DEFAULT_TYPE = 'DEFAULT_TYPE';
