import React, { useEffect, useState } from 'react';

import { Icon } from '@mui/material';
import { Form, Input, Label } from 'reactstrap';
import GenericButton from '../../components/Buttons/Button';
import CustomContainer from '../../components/Custom/CustomContainer';
import InputSelectAmountResults from '../../components/Filter/components/InputSelectAmountResults';
import api from '../../service/api';
import './styles.css';

import moment from 'moment';
import InputDate from '../../components/Filter/components/InputDate';
import InputSearch from '../../components/Filter/components/InputSearch';
import InputWithSearchWithValue from '../../components/Filter/components/inputWithSearchWithValue';
import SegundaViaRHDataTable from '../../components/SegundaViaRHDataTable/SegundaViaRHDataTable';
import ModalMensagemSemReloadNew from '../Transaction/ModalMensagemSemReloadNew';
import ModalHistorico from './ModalHistorico';
import ModalStatus from './ModalStatus';
import { FailBadge, InfoBadge, SuccessBadge, WarningBadge } from './style';

function SegundaViaRH(props) {
  const [requests, setRequests] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalDetails, setModalDetails] = useState(false);
  const [pagination, setPagination] = useState(false);
  const [id, setId] = useState(false);
  const [status, setStatus] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [modalStatus, setModalStatus] = useState(false);
  const [select2, setSelect2] = useState(false);
  const [filter, setFilter] = useState({
    id: '',
    status: '',
    id_usuario: '',
    data_inicial: moment().add(-30, 'days').format('YYYY-MM-DD'),
    data_final: moment().format('YYYY-MM-DD'),
    colaborador: '',
    cpf_colaborador: '',
    page: 1,
    limit: 10,
  });

  function getSecondCopysRequests(params) {
    setLoading(true);
    api
      .get(`/solicitacoes/segunda-via-cartao`, {
        params: { ...params },
      })
      .then((res) => {
        if (res.data) {
          setRequests(res.data.data);
          setPagination(res.data.pagination);
        }
      })
      .finally(() => setLoading(false));
  }

  function getSelectCompanies() {
    api.get(`/solicitacoes/segunda-via-cartao/select`).then(({ data }) => {
      if (data) {
        setSelect2(data.data);
      }
    });
  }

  function search() {
    getSecondCopysRequests({ ...filter, page: 1 });
  }

  useEffect(() => {
    getSecondCopysRequests(filter);
    getSelectCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSecondCopysRequests(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.page]);

  const tablePagination = async (type = 'atual', pageClick) => {
    let page = filter.page;
    switch (type) {
      case 'prev':
        page = pagination.prevPage;
        break;
      case 'next':
        page = pagination.nextPage;
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }
    await setFilter({ ...filter, page: page });
  };

  function chooseBadge(status) {
    if (status == '1') {
      return <WarningBadge width="100%">Aguardando</WarningBadge>;
    }
    if (status == '2') {
      return <InfoBadge width="100%">Enviado</InfoBadge>;
    }
    if (status == '3') {
      return <SuccessBadge>Entregue</SuccessBadge>;
    }
    if (status == '4') {
      return (
        <FailBadge width="100%" bg="red">
          Cancelado
        </FailBadge>
      );
    }

    if (status == '5') {
      return <InfoBadge width="100%">Em trânsito</InfoBadge>;
    }
  }

  return (
    <>
      <Form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
          search();
        }}
      >
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
          <div style={{ marginRight: '1rem' }}>
            <InputDate
              label="Data Inicial"
              name="DATA_INICIO"
              value={filter.data_inicial}
              setValue={(value) => setFilter({ ...filter, data_inicial: value })}
            />
          </div>
          <div style={{ marginRight: '1rem' }}>
            <InputDate
              label="Data Final"
              name="DATA_FIM"
              value={filter.data_final}
              setValue={(value) => setFilter({ ...filter, data_final: value })}
            />
          </div>

          <div style={{ marginRight: '1rem' }}>
            <InputSearch
              label={'Colaborador'}
              placeholder="Nome ou CPF"
              width="10rem"
              value={filter.colaborador}
              setValue={(value) => setFilter({ ...filter, colaborador: value })}
            />
          </div>

          <div style={{ marginRight: '1rem', width: '15rem' }}>
            <Label size="sm">Empresa</Label>

            <InputWithSearchWithValue
              removeLabel={true}
              setValue={(value) => setFilter({ ...filter, id_usuario: value })}
              options={select2 && ['Todos', ...select2.map((empresa) => empresa.NOME)]}
              values={select2 && ['', ...select2.map((empresa) => empresa.ID)]}
              notUnique={true}
            />
          </div>

          <div style={{ marginRight: '1rem', width: '10rem' }}>
            <Label size="sm">Status</Label>
            <Input
              onChange={(e) => setFilter({ ...filter, status: e.target.value })}
              type="select"
              name="status"
              id="status"
              size="sm"
              value={filter.status}
            >
              <option value={''}>Todos</option>
              <option key={'1'} value={'1'}>
                Aguardando
              </option>
              <option key={'2'} value={'2'}>
                Enviado
              </option>
              <option key={'3'} value={'3'}>
                Entregue
              </option>
              <option key={'4'} value={'4'}>
                Cancelado
              </option>
              <option key={'5'} value={'5'}>
                Em trãnsito
              </option>
            </Input>
          </div>
          <div style={{ alignSelf: 'flex-end' }}>
            <GenericButton onClick={(e) => search(e)} height="30px" className="mt-3" size="sm" type="submit">
              <Icon style={{ marginRight: '.4rem', fontSize: '1rem' }} className="fas fa-search"></Icon>Buscar
            </GenericButton>
          </div>
        </div>
        <div
          style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', flexWrap: 'wrap', marginTop: '1rem' }}
        >
          <InputSelectAmountResults
            options={['10', '25', '50', '100', '1000']}
            value={filter.limit}
            setValue={(value) => setFilter({ ...filter, limit: value })}
            width={'10rem'}
          />
        </div>
      </Form>

      <ModalStatus
        setFeedbackMessage={setFeedbackMessage}
        setFeedback={setFeedback}
        open={modalStatus}
        id={id}
        close={() => {
          setModalStatus(false);
          setStatus(false);
        }}
        status={status}
      />

      <ModalMensagemSemReloadNew
        open={feedback}
        close={() => {
          setFeedback(false);
          setId(false);
          setModalStatus(false);
          setStatus(false);
          setFeedbackMessage('');
        }}
        callback={() => {
          setFeedback(false);
          setId(false);
          setModalStatus(false);
          setStatus(false);
          setFeedbackMessage('');
          getSecondCopysRequests({
            id: '',
            status: '',
            id_usuario: '',
            data_inicial: moment().add(-30, 'days').format('YYYY-MM-DD'),
            data_final: moment().format('YYYY-MM-DD'),
            colaborador: '',
            cpf_colaborador: '',
            page: 1,
            limit: 10,
          });
        }}
        sucesso={feedbackMessage === 'Sucesso!'}
        mensagem={feedbackMessage}
        title={feedbackMessage === 'Sucesso!' ? 'Sucesso' : 'Erro'}
      />

      <ModalHistorico
        id={id}
        chooseBadge={chooseBadge}
        open={modalDetails}
        close={() => {
          setModalDetails(false);
          setId(false);
        }}
      />

      <SegundaViaRHDataTable
        requestPagination={pagination}
        requests={requests}
        loading={loading}
        page={filter.page}
        chooseBadge={chooseBadge}
        setFilter={setFilter}
        filter={filter}
        setModalDetails={setModalDetails}
        setId={setId}
        setModalStatus={setModalStatus}
        setStatus={setStatus}
      />
    </>
  );
}

export default SegundaViaRH;
