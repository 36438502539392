import React from 'react';
import Table from '../Table/Table';

import valueEmpty from '../../utils/valueEmpty';
import Loading from '../Loader/Loading';
import StatusContainer from '../StatusContainer/StatusContainer';
import { formatDateBR } from '../../utils/dateKyc';
import BadgeTipoConta from '../BadgeTipoConta/BadgeTipoConta';
import formatMoney from '../../utils/formatMoneyBRL';

function PedidosRHTable({ orders, loading, openModalOrder, orderPagination, page, setFilter, filter, tipoConta }) {
  const tablePagination = async (type = 'atual', pageClick) => {
    switch (type) {
      case 'prev':
        page = orderPagination.prevPage;
        break;
      case 'next':
        page = orderPagination.nextPage;
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }
    await setFilter({ ...filter, page: page });
  };

  return (
    <>
      {!loading ? (
        <>
          {orders && orders.length > 0 ? (
            <Table
              style={{ marginTop: '3rem' }}
              size="sm"
              headers={[
                {
                  info: 'ID',
                  style: { textAlign: 'start' },
                },
                {
                  info: 'Data',
                  style: { textAlign: 'start' },
                },
                {
                  info: 'Empresa',
                  style: { textAlign: 'center' },
                },
                {
                  info: 'Forma de pagamento',
                  style: { textAlign: 'center' },
                },
                {
                  info: 'Tipo conta',
                  style: { textAlign: 'center' },
                },
                {
                  info: 'Valor do Pedido',
                  style: { textAlign: 'center' },
                },
                {
                  info: 'Status',
                  style: { textAlign: 'center' },
                },
              ]}
              data={
                orders &&
                orders.length > 0 &&
                orders.map((order, index) => {
                  return {
                    data: [
                      {
                        info: order.ID,
                        style: { textAlign: 'start' },
                      },
                      {
                        info: formatDateBR(order.DATA_INSERT),
                        style: { textAlign: 'start' },
                      },
                      {
                        info: order.NOME,
                        style: { textAlign: 'center' },
                      },
                      {
                        info: order.FORMA_PAGAMENTO,
                        style: { textAlign: 'center' },
                      },
                      {
                        info: (
                          <BadgeTipoConta tipoConta={order.FK_SWAP_CARTAO_TIPO_CONTA} styles={{ fontSize: '0.6rem' }} />
                        ),
                        style: { textAlign: 'center' },
                      },
                      {
                        info: 'R$' + formatMoney(order.VALOR_SOMA),
                        style: { textAlign: 'center' },
                      },
                      {
                        info: valueEmpty(
                          order.FK_STATUS_TRANSACAO ? (
                            <StatusContainer code={order.FK_STATUS_TRANSACAO} description={order.STATUS} />
                          ) : null,
                        ),
                        style: { textAlign: 'center' },
                      },
                    ],
                    onClick: () => openModalOrder(order),
                  };
                })
              }
              pagination={orderPagination}
              previousPage={{ onClick: () => tablePagination('prev') }}
              nowPage={{ page, onClick: tablePagination }}
              nextPage={{ onClick: () => tablePagination('next') }}
            />
          ) : (
            <>
              <Table
                style={{ marginTop: '3rem' }}
                size="sm"
                headers={[
                  {
                    info: 'ID',
                    style: { textAlign: 'start' },
                  },
                  {
                    info: 'Data',
                    style: { textAlign: 'start' },
                  },
                  {
                    info: 'Empresa',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'Forma de pagamento',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'Valor do Pedido',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'Status',
                    style: { textAlign: 'center' },
                  },
                ]}
              />
              <h3 style={{ textAlign: 'center', marginTop: '1rem' }}>Nenhum registro encontrado</h3>
            </>
          )}
        </>
      ) : (
        <>
          <Loading color="blue" />
        </>
      )}
    </>
  );
}

export default PedidosRHTable;
