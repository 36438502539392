import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import Cards from '../Cards/Cards';
import { saldoAporteCartao, saldoAportePix } from '../../store/transactions/actions';
import { useDispatch } from 'react-redux';

export default function Header() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(saldoAporteCartao());
    dispatch(saldoAportePix());
  }, []);

  return (
    <>
      <div className={`header bg-gradient-warning pb-8 pt-5 pt-md-7`} />
      <Container fluid>
        <div className="header-body" style={{ transform: 'translateY(-67px) ' }}>
          {window.location.pathname == '/admin/dashboard' && <Cards style={{ marginTop: '10px' }}></Cards>}
        </div>
      </Container>
    </>
  );
}
