import React from 'react';
import './Loading.css';

function Loading(props) {
  return (
    <div className="loader" style={{ color: props.color ? props.color : 'white', ...props.style }}>
      Loading...
    </div>
  );
}

export default Loading;
