import styled from 'styled-components';

export const PreviewContainer = styled.div`
  display: flex;
  background-color: #ffffff;
  min-height: 90vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 0 2rem 0;
  margin-top: -0.8rem;
  z-index: 1;
`;

export const PreviewComponent = styled.div`
  background-color: #f8f8f8;
  width: 95%;
  height: 95%;
  border-radius: 1rem;
  border: 1px solid #c6d4e9;
`;

export const PreviewPreHeader = styled.div`
  border-radius: 1rem 1rem 0 0;
  width: 100%;
  height: 14%;
  background-color: #f8f8f8;
  padding: 0.2rem;
  display: flex;
  align-items: center;
`;

export const PreviewHeader = styled.header.attrs((props) => ({ style: { background: props.primary || '#c6d4e9' } }))`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center;
  padding: 2rem;
`;

export const PreviewCardHeader = styled.div`
  width: 4.5rem;
  height: 4rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.1rem;
  padding-top: 0.5rem;
`;

export const PreviewIcon = styled.div.attrs((props) => ({
  style: { background: props.icon_menu || '#c6d4e9', color: props.icon_menu_text || '#7fc8e9' },
}))`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
`;

export const TextPreview = styled.p`
  color: #7a7c9c;
  font-size: 0.6rem;
  font-weight: 700;
`;

export const PreviewBody = styled.section`
  width: 100%;
  height: 66%;
  display: flex;
  border-radius: 0 0 1rem 1rem;
`;

export const PreviewCardsAndTableDiv = styled.div`
  display: flex;
  height: 100%;
  width: 60%;
  border-radius: 0 0 0 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PreviewCardsDiv = styled.div`
  padding: 2rem;
  background-color: #e8e8f0;
  width: 100%;
  height: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const PreviewCardBalance = styled.div`
  background-color: rgb(255, 255, 255);
  padding: 0.8rem;
  width: 47%;
  height: 6rem;
  border-radius: 0.3rem;
  display: flex;
`;
export const PreviewCardText = styled.div`
  height: 100%;
  width: 80%;
`;
export const PreviewTextDiv = styled.div`
  h3 {
    font-size: 0.6rem;
    margin-bottom: 0;
  }

  span {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }
`;

export const PreviewIconBalance = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: transparent;
  border-radius: 0 1rem 0 0;
`;
export const PreviewCircleIcon = styled.div.attrs((props) => ({
  style: { background: props.icon || '#7fc8e9', color: props.icon_text || 'white' },
}))`
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableHead = styled.thead.attrs((props) => ({
  style: { background: props.table || '#878dac', color: props.table_text || '#e8e8f0' },
}))`
  text-align: center;
`;

export const PreviewTables = styled.div`
  background-color: #f8f8f8;
  width: 100%;
  height: 95%;
  padding: 1rem 2rem;

  border-radius: 0 0 0 1rem;
  table {
    width: 100%;
    font-size: 0.7rem;
  }

  tbody {
    text-align: center;

    tr:nth-child(odd) {
      background-color: #ededed;
    }
  }

  tr {
  }

  th {
  }
`;

export const PreviewAsideButtons = styled.aside`
  width: 40%;
  background-color: rgb(255, 255, 255);
  height: 100%;
  border-radius: 0 0 1rem 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    margin-top: 2rem;
  }
`;

export const PreviewPostButton = styled.button.attrs((props) => ({
  style: { background: props.primary || '#7fc8e9', color: props.primary_text || 'white' },
}))`
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  width: 217px;
`;

export const PreviewSecundaryButton = styled.button.attrs((props) => ({
  style: {
    background: props.secondary || 'rgb(255, 255, 255)',
    color: props.secondary_text || 'rgb(85, 108, 214)',
    '&:hover': {
      background: props.secondary_text || 'rgb(255, 255, 255)',
      color: props.secondary || 'rgb(85, 108, 214)',
    },
  },
}))`
  align-items: center;
  border: 0;
  border-radius: 0;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px 0 #00000024, 0 1px 5px 0 #0000001f;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.8125rem;
  font-weight: 500;
  height: 36px;
  justify-content: center;
  letter-spacing: 0.02857em;
  line-height: 1.75;
  margin: 0 0 0 1rem;
  min-height: 36px;
  min-width: 64px;
  outline: 0;
  padding: 4px 10px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 142px;
`;
