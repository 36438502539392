/* eslint-disable */
export function getHighestMatch(data) {
  let result = data[0];

  data.map((rate) => {
    if (parseFloat(rate.match_rate) > parseFloat(result.match_rate)) {
      result = rate;
    }
  });

  return result;
}

export function getAddress(data) {
  /*   if (!data[0]) return false; */

  let result = data[0];

  data.map((rate) => {
    if (parseFloat(rate.CEP.match_rate) > parseFloat(result.CEP.match_rate)) {
      result = rate;
    }
  });

  return result;
}

export function thereIsSanctions(data) {
  if (!data) return false;
  return data.some(({ match_rate }) => match_rate === '100,00');
}
