import React from 'react';
import { Component } from 'react';
import { Modal, ModalBody, Form, FormGroup, CustomInput, Input, Button, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import Loading from '../../components/Loader/Loading';
import { remessa } from '../../store/transactions/actions';
import { listarDocumentos, enviarDocumentoByUUID } from '../../store/user/actions';
import ModalMensagem from '../../views/Transaction/ModalMensagem';

const INITIAL_FORM = { dataInicio: '', dataFim: '', file: '' };
class ShippingFormModal extends Component {
  state = {
    form: INITIAL_FORM,
    tipo: 1,
    tipoErro: false,
    modal: false,
    document: '',
    docCod: 0,
    modalMensagem: false,
    mensagem: '',
    sucesso: false,
  };

  componentDidMount() {
    this.props.listarDocumentos();
  }

  changeState(e) {
    switch (e.target.name) {
      case 'tipo':
        this.setState({ tipo: e.target.value });
        break;
      default:
        break;
    }
  }

  onChange = async (e) => {
    const { name, value, files, type } = e.target;
    var form = this.state.form;

    switch (type) {
      case 'file':
        form[name] = files[0];
        document.querySelector('#titleFormFile .custom-file-label').textContent = form[name].name;
        break;
      default:
        form[name] = value;
        break;
    }

    await this.setState({ document: form.file });
  };

  onChangeDoc = async (target) => {
    await this.setState({ [target.name]: target.value });
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async validateFieldsSelecteds() {
    if (this.state.document && this.state.docCod) {
      let base64 = await this.toBase64(this.state.document);
      base64 = base64.split('base64,')[1];
      const obj = {
        TIPO: parseInt(this.state.docCod),
        IMAGEM: base64,
      };

      await this.props.enviarDocumentoByUUID(obj, this.props.user.user.UUID, (res) => {
        const { mensagem, retorno } = res;

        this.setState({
          mensagem: mensagem,
          sucesso: retorno == 'sucesso' ? true : false,
          modalMensagem: true,
        });
      });
      return;
    }

    this.setState({
      mensagem: 'Por favor envie um documento e o seu tipo!',
      sucesso: false,
      modalMensagem: true,
    });
  }

  render() {
    const { modal, close } = this.props;
    const { load_transaction } = this.props.transactions;
    const { typeDoc } = this.props.user;

    return (
      <Modal isOpen={modal} size="sm">
        <ModalHeader modal={this.state.modal} toggle={close}>
          <h3>Enviar documento</h3>
        </ModalHeader>
        <ModalBody>
          <Form id="formExtratoTititulos">
            <FormGroup id="titleFormFile">
              <div>
                <CustomInput
                  label="Escolher arquivo"
                  onChange={this.onChange}
                  accept="image/*, .pdf"
                  type="file"
                  id="file"
                  name="file"
                  required
                  style={{ marginTop: '0px' }}
                />
                <h3 style={{ marginBottom: '24px', marginTop: '16px' }}>Tipo de documento:</h3>
                <Input type="select" name="docCod" onChange={(e) => this.onChangeDoc(e.target)}>
                  <option defaultValue selected value={''}>
                    Selecione...
                  </option>
                  {typeDoc &&
                    typeDoc.map((doc) => (
                      <option value={doc.COD}>
                        {doc.COD} {doc.DESCRICAO}
                      </option>
                    ))}
                </Input>
              </div>
            </FormGroup>
            {load_transaction ? (
              <Loading color="#11cdef" style={{ fontSize: '20px' }} />
            ) : (
              <Button
                size="sm"
                style={{ float: 'right' }}
                color="success"
                block
                onClick={() => this.validateFieldsSelecteds()}
              >
                Enviar
              </Button>
            )}
          </Form>
          <ModalMensagem
            modal={this.state.modalMensagem}
            toggle={() => this.setState({ modalMensagem: false })}
            sucesso={this.state.sucesso}
            mensagem={this.state.mensagem}
          />
        </ModalBody>
        <style>
          {`
                    #titleFormFile .custom-file-input:lang(en) ~ .custom-file-label::after{
                        color: white!important;
                        background-color: #007bff!important;
                        content: 'Escolher'!important;
                    }
                    `}
        </style>
      </Modal>
    );
  }
}

const mapStateToProps = ({ auth, transactions, user }) => {
  return { auth, transactions, user };
};

export default connect(mapStateToProps, {
  remessa,
  listarDocumentos,
  enviarDocumentoByUUID,
})(ShippingFormModal);
