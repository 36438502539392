import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';

const InputWithSearch = ({ setValue, options, removeLabel, label }) => {
  const [selectOptions, setSelectOptions] = useState(false);

  useEffect(() => {
    if (options) {
      const uniqueOption = [...new Set(options)];
      const res = uniqueOption.map((option) => {
        let obj;

        if (option === 'Todos') {
          obj = { label: 'Todos', value: '' };
        } else {
          obj = { label: option, value: option };
        }
        return obj;
      });

      setSelectOptions(res);
    }
  }, [options]);

  return (
    <div>
      {!removeLabel && <Label size="sm">Cartões</Label>}
      {label && <Label size="sm">{label}</Label>}
      {selectOptions && (
        <Select
          className="my-custom-select"
          onChange={({ value }) => setValue(value)}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              width: '15rem',
              minHeight: '31px',
              height: '31px',
            }),
          }}
          options={selectOptions}
        />
      )}
    </div>
  );
};

export default InputWithSearch;
