import React from 'react';
import { Button, Row } from 'reactstrap';
import Table from '../../../components/Table/Table';

const CartoesContaDigital = (props) => {
  return (
    <div id="toggler" className="mt-3">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <h3>Cartões Conta Digital</h3>
      </div>
      <Table
        size="sm"
        headers={[
          {
            info: 'Tipo',
            style: { textAlign: 'left' },
          },
          {
            info: 'Número',
            style: { textAlign: 'left' },
          },
          {
            info: '',
            style: { textAlign: 'right' },
          },
        ]}
        data={
          props.cartaoContaDigital &&
          props.cartaoContaDigital.map(() => {
            return {
              data: [
                {
                  info: 'CARTAO DE DÉBITO',
                  style: { textAlign: 'left' },
                },
                {
                  info: props.userContaDigitalCartao.NUM_CARTAO,
                  style: { textAlign: 'left' },
                },
                {
                  info: (
                    <Row
                      className="mb-2"
                      style={{
                        justifyContent: 'flex-end',
                        marginRight: 10,
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        {props.userContaDigitalCartao.STATUS === '1' ? (
                          <Button
                            size="sm"
                            name="0"
                            style={{
                              alignContent: 'flex-end',
                            }}
                            onClick={() => {
                              props.openModalPasswordBlockDigitalCardCallback();
                            }}
                          >
                            Desbloquear
                          </Button>
                        ) : (
                          <Button
                            size="sm"
                            name="1"
                            color="warning"
                            style={{
                              alignContent: 'flex-end',
                            }}
                            onClick={() => {
                              props.openModalPasswordBlockDigitalCardCallback();
                            }}
                            // name="0"
                          >
                            Bloquear
                          </Button>
                        )}
                        <Button
                          size="sm"
                          color="info"
                          style={{
                            alignContent: 'flex-end',
                          }}
                          onClick={() => {
                            props.openModalSegundaViaCallback();
                          }}
                          name="2ª Via do cartão"
                        >
                          2ª Via do cartão
                        </Button>
                      </div>
                    </Row>
                  ),
                  style: { alignSelf: 'center' },
                },
              ],
            };
          })
        }
      />
    </div>
  );
};

export default CartoesContaDigital;
