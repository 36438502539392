import { Alert, FormControl, NativeSelect } from '@mui/material';
import React from 'react';
import { Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import OperarSaldoProdutoLote from '../OperarSaldoProdutoLote/OperarSaldoProdutoLote';
import Checkbox from '@material-ui/core/Checkbox';
import { CardsContainer, CardCheckContainer, Container } from './styles';
import Loading from '../../../../../../components/Loader/Loading';

const ModalLote = (props) => {
  const { setCheckedCards, checkedCards} =  props ;
  async function handleChange(e) {
    if (props.checkMcc && e.target.value.length > 0) {
      props.checkMcc(e.target.value);
    }
    props.setSelectValue(e.target.value);
    if (e.target.value.length === 0) {
      props.setAlertError('');
    }
  }

  function handleCheck(codCard) {
  
    if(checkedCards.find((card) => card == codCard)){
      setCheckedCards(checkedCards.filter((card) => card != codCard));
    } else {
     
     setCheckedCards((prev) => [...prev, codCard]);
    }
  }


  return (
    <>
      <Modal
        isOpen={props.openPL}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={() => {
          props.closePL();
        }}
      >
        <ModalHeader
          toggle={() => {
            props.closePL();
          }}
        >
          <h2>{props.title}</h2>
        </ModalHeader>
        <FormGroup>
          <ModalBody>
            {props.alertError && props.alertError.length > 1 && <Alert severity="error">{props.alertError}</Alert>}
            <FormControl fullWidth>
              {props.listagemProduto && props.listagemProduto.length > 0 ? (
                <NativeSelect
                  onChange={(e) => handleChange(e)}
                  name="lista"
                  id="lista-conteudos"
                  defaultValue={null}
                  inputProps={{
                    name: 'age',
                    id: 'uncontrolled-native',
                  }}
                >
                  <option value={''}>Escolha produto</option>
                  {props.listagemProduto &&
                    props.listagemProduto.map((produto) => <option value={produto.ID}>{produto.DESCRICAO}</option>)}
                </NativeSelect>
              ) : (
                <h3> O cliente não possui cartões/benefícios vinculados </h3>
              )}
            </FormControl>
            {props.loadingAvailableCards ? (
              <Loading color="blue" />
            ) : (
              <div>
                {props.availableCards &&
                  props.availableCards.map((card, index) => (
                    <CardsContainer key={index}>
                      <Checkbox
                        checked={checkedCards && checkedCards.find((e) => e == card.CODCARTAO)}
                        onChange={(e) => {
                          handleCheck(e.target.value);
                        }}
                        value={card.CODCARTAO || ''}
                        style={{ marginRight: '.5rem' }}
                        color='primary'
                      
                      /*   iconStyle={{ fill: 'blue' }} */
                  /*       inputStyle={{ color: 'blue' }} */
                      />
                      <Container>
                        <CardCheckContainer style={{ marginRight: '1rem' }}>
                          <span style={{ fontWeight: 600 }}>ID: </span>
                          <span>{card.CODCARTAO}</span>
                        </CardCheckContainer>
                        <CardCheckContainer>
                          <span style={{ fontWeight: 600 }}>Número: </span>
                          <span>{card.NUMERO}</span>
                        </CardCheckContainer>
                      </Container>
                    </CardsContainer>
                  ))}
              </div>
            )}
          </ModalBody>
        </FormGroup>
        <ModalFooter>
          <Button
            color="primary"
            variant="contained"
            sx={{ marginBottom: '1rem' }}
            onClick={() => props.handleClick()}
            style={{ marginLeft: '1%' }}
            disabled={
              (props.alertError && props.alertError.length) ||
              !props.selectValue.length ||
              (checkedCards && checkedCards.length == 0)
            }
          >
            {props.buttonName}
          </Button>
        </ModalFooter>
      </Modal>
      <OperarSaldoProdutoLote
        searchCardWithFilter={props.searchCardWithFilter}
        allCards={props.allCards}
        saldoGeral={props.saldoGeral}
        selectValue={props.selectValue}
        open={props.modalOperateBalanceProductBatch}
        close={() => props.closeModalOperateBalance()}
        setReloadProdutos={props.setReloadProdutos}
        reloadProdutos={props.reloadProdutos}
        forceReload={props.forceReload}
      />
    </>
  );
};

export default ModalLote;
