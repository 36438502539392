import React from 'react';
import { connect } from 'react-redux';
import Table from '../../components/Table/Table.jsx';
import CreateIcon from '@material-ui/icons/Create';
import RemoveIcon from '../../assets/img/icons/common/delete_white.svg';
import TapAndPlayIcon from '@material-ui/icons/TapAndPlay';
import Lock from '@material-ui/icons/Lock';
import dashboard from '../../assets/img/icons/common/dashboard.svg';
import Tooltip from '@material-ui/core/Tooltip';
import StatusContainer from '../../components/StatusContainer/StatusContainer';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Row, Button, UncontrolledAlert, Col, CardBody, Label, Input } from 'reactstrap';
import {
  listarCoban,
  listarCobanID,
  updateCoban,
  updatePerfil,
  insertCoban,
  insertPerfil,
  listarTaxaID,
  listLevels,
} from '../../store/coban/actions.js';
import Loading from '../../components/Loader/Loading.jsx';
import ModalForm from '../../components/Modais/ModalForm.jsx';
import DocumentsForm from '../../components/Form/DocumentsForm.jsx';
import { addSTATE } from '../../store/globals.js';
import { validateCoban } from '../../utils/functions.js';
import formatCNPJ from '../../utils/formatCNPJ';
import formatCPF from '../../utils/formatCPF';
import CustomContainer from '../../components/Custom/CustomContainer';
import ModalTaxas from '../../components/Modais/Taxas/ModalTaxas';
import ModalTaxasDigital from '../../components/Modais/Taxas/ModalTaxasDigital';
import ModalMensagem from '../../views/Transaction/ModalMensagem.jsx';
import ModalSenha from '../../components/Modais/Confirmar/ModalSenha';
import ModalServicosDigitais from './components/ModalServicosDigitais.jsx';
import ModalMenus from './components/ModalMenus.jsx';
import Icon from '../../components/Icons/IconsButton.js';
import GenericButton from '../../components/Buttons/Button';
import { isCnpj } from '../../utils/isCpf.js';
import ModalAllFees from '../../components/Modais/Taxas/ModalAllFees.jsx';

const FORMCOBAN = require('./Form.json');
const FORMPERFIL = require('./FormPerfil.json');
class Cobans extends React.Component {
  state = {
    modalForm: false,
    retorno_1: [],
    filtro_usado: false,
    alertActive: false,
    alertMessage: {},
    dadosModal: [],
    dadosModalDigital: [],
    modal: false,
    modalSenha: false,
    tipoUser: '1',
    modalMensagem: false,
    mensagem: '',
    sucesso: false,
    status: false,
    retorno: false,
    dados: {},
    editavel: false,
    dadosUser: null,
    nextFunction: '',
    operation: '',
    modalServicosDigitais: false,
    ID: 0,
    modalMenus: false,
    changePermissionPassword: false,
    valueSearch: '',
    filteredUsers: '',
    modalAbono: false,
    modalTaxas: {
      open: false,
      partner: '',
      profile: '',
      idCoban: ''
    },
  };

  componentDidMount() {
    this.props.listarCoban();
    this.setState({ tipoUser: '1' });
    this.props.listLevels();
  }

  editCoban = (coban = {}, type = '') => {
    const { ID } = coban;

    this.setState({ dadosUser: coban });

    switch (type) {
      case 'add':
        this.toggleModal();
        this.setState({ editavel: true });
        // this.props.addSTATE('COBAN_ALL', '', { modoExibe: false });
        break;
      case 'addContaDigital':
        this.toggleModal();
        this.setState({ editavel: true });
        // this.props.addSTATE('COBAN_ALL', '', { modoExibe: false });
        break;
      case 'edit':
        this.setState({ editavel: false });
        // this.props.addSTATE('COBAN_ALL', '', { modoExibe: true });
        this.props.listarCobanID(ID, (res) => {
          if (res) this.toggleModal();
        });
        break;
      case 'editPermissionPassword':
        this.props.listarCobanID(ID, (res) => {
          if (res) {
            this.setState({ changePermissionPassword: true });
            this.toggleModal();
          }
        });
        break;
      default:
        this.toggleModal();
        this.setState({ changePermissionPassword: true });
        if (Object.keys(this.props.coban.coban).length > 0) this.props.addSTATE('COBAN_ALL', '', { coban: {} });
        break;
    }
  };

  toggleModal = () => this.setState({ modalForm: !this.state.modalForm });
  toggleModal2 = () => this.setState({ modal: !this.state.modal });
  toggleModalDigital = () => this.setState({ modalDigital: !this.state.modalDigital });
  toggleModalServicosDigitais = () => this.setState({ modalServicosDigitais: !this.state.modalServicosDigitais });
  toggleModalMenus = () => this.setState({ modalMenus: !this.state.modalMenus });
  togglePersmissionPassword = () => this.setState({ changePermissionPassword: !this.state.changePermissionPassword });

  submitForm = () => {
    const { coban } = this.props.coban;
    const { mensagem, sucesso } = validateCoban(coban);

    this.setState({
      modalMensagem: true,
      mensagem: mensagem,
      sucesso: sucesso,
    });
    if (!validateCoban(coban).sucesso) {
      return (
        <ModalMensagem
          isOpen={this.state.modalMensagem}
          toggle={() => this.setState({ modalMensagem: false })}
          mensagem={this.state.mensagem}
          sucesso={this.state.sucesso}
        />
      );
    }

    if (coban.ID) this.props.updateCoban(coban, (data, res) => this.trataRetornoForm(data, res));
    else this.props.insertCoban(coban, (data, res) => this.trataRetornoForm(data, res));
  };

  submitFormPerfil = () => {
    const { coban } = this.props.coban;

    if (coban.ID)
      this.props.updatePerfil({ NOME: coban.NOME, ID: coban.ID }, (data, res) => this.trataRetornoForm(data, res));
    else this.props.insertPerfil({ NOME: coban.NOME }, (data, res) => this.trataRetornoForm(data, res));
  };

  editTaxas = (coban = {}, type = 'add') => {
    const { ID } = coban;
    if (ID)
      this.props.listarTaxaID(ID, (res) => {
        if (res)
          this.setState({
            dadosModal: this.props.coban.coban.TAXAS,
            modal: !this.state.modal,
          });
        else alert('Erro ao listar coban!');
      });
    else {
      this.setState({ modalForm: !this.state.modalForm });
      if (Object.keys(this.props.coban.coban).length > 0) this.props.addSTATE('COBAN_ALL', '', { coban: {} });
    }
  };

  editTaxasDigitais = (coban = {}, type = 'addContaDigital') => {
    const { ID } = coban;
    if (ID)
      this.props.listarTaxaID(ID, (res) => {
        if (res)
          this.setState({
            dadosModalDigital: this.props.coban.coban.TAXAS,
            modalDigital: !this.state.modal.modalDigital,
          });
        else alert('Erro ao listar coban!');
      });
    else {
      this.setState({ modalForm: !this.state.modalForm });
      if (Object.keys(this.props.coban.coban).length > 0) this.props.addSTATE('COBAN_ALL', '', { coban: {} });
    }
  };

  editarServicosDigitais = (coban = {}, type = 'add') => {
    const { ID } = coban;
    this.setState({
      modalServicosDigitais: !this.state.modalServicosDigitais,
      ID: ID,
    });
  };
  editarMenus = (coban = {}) => {
    const { ID } = coban;
    this.setState({
      modalMenus: !this.state.modalMenus,
      ID: ID,
    });
  };

  trataRetornoForm = (data, res) => {
    const { mensagem, erro, retorno } = data;

    this.setState({ mensagem: mensagem });

    if (erro == undefined) this.setState({ sucesso: retorno == 'sucesso' ? true : false });

    if (erro != undefined) this.setState({ sucesso: !erro });

    this.setState({ modalMensagem: true });

    this.toggleModal();
  };

  alertActive() {
    const { retorno, mensagem } = this.state.alertMessage;
    var color = '';
    if (retorno == 'sucesso') {
      color = 'success';
      return;
    }

    this.setState({ alertActive: true });
    color = 'danger';

    if (this.state.alertActive == true) {
      setTimeout(() => this.setState({ alertActive: false }), 5500);
      return <UncontrolledAlert color={color}>{mensagem}</UncontrolledAlert>;
    }
  }

  renderUserCoban() {
    const { list, loading_coban } = this.props.coban;
    const filteredList = list
      .filter((tipo) => tipo.PERFIL == '1')
      .filter((item) => item.NOME.toLowerCase().includes(this.state.filteredUsers.toLowerCase()));
    return (
      <div>
        <Row>
          <div className="col">
            {loading_coban ? (
              <Loading color="#11cdef" />
            ) : filteredList.length > 0 ? (
              <Table
                headers={[
                  {
                    info: 'Nome',
                    style: { textAlign: 'left', width: '33%' },
                  },
                  {
                    info: 'Parceiro',
                    style: { textAlign: 'center', width: '45%' },
                  },
                  {
                    info: 'AÇÕES',
                    style: { textAlign: 'center' },
                  },
                ]}
                data={filteredList.map((coban) => {
                  return {
                    data: [
                      {
                        info: coban.NOME,
                        style: { textAlign: 'left', width: '33%' },
                      },
                      {
                        info: coban.PARCEIRO ? (
                          <StatusContainer code={coban.PARCEIRO} description={coban.PARCEIRO}></StatusContainer>
                        ) : (
                          '-----'
                        ),
                        style: { textAlign: 'center', width: '45%' },
                      },
                      {
                        info: (
                          <Row
                            className="mb-2"
                            style={{
                              justifyContent: 'center',
                            }}
                          >
                            <div style={{ display: 'flex' }}>
                              <Button
                                style={{
                                  alignContent: 'flex-start',
                                  backgroundColor: '#4D24FA',
                                  borderColor: '#4D24FA',
                                }}
                                onClick={() => {
                                  this.editarMenus(coban, 'edit');
                                }}
                                color="primary"
                                size="sm"
                              >
                                <Tooltip title="Menus ativos">
                                  <img src={dashboard}></img>
                                </Tooltip>
                              </Button>
                              <Button
                                style={{
                                  alignContent: 'flex-start',
                                  backgroundColor: 'darkblue',
                                  borderColor: 'darkblue',
                                }}
                                onClick={() => {
                                  this.editarServicosDigitais(coban, 'edit');
                                }}
                                color="primary"
                                size="sm"
                              >
                                <Tooltip title="Serviços digitais">
                                  <TapAndPlayIcon />
                                </Tooltip>
                              </Button>

                              <Button
                                style={{ alignContent: 'flex-start' }}
                                onClick={() => this.setState({modalTaxas: {
                                  open: true, profile: coban.NOME, partner: coban.PARCEIRO, idCoban: coban.ID
                                }})}
                                color="primary"
                                size="sm"
                              >
                                <Tooltip title="Taxas">
                                  <ReceiptLongIcon />
                                </Tooltip>
                              </Button>

                              <Button
                                style={{ alignContent: 'flex-end' }}
                                onClick={() => {
                                  this.editCoban(coban, 'edit');
                                }}
                                color="info"
                                size="sm"
                              >
                                <Tooltip title="Editar">
                                  <CreateIcon />
                                </Tooltip>
                              </Button>

                              <Button
                                style={{ alignContent: 'flex-end' }}
                                onClick={() => {
                                  this.setState({
                                    dados: {
                                      NOME: coban.NOME,
                                      ID: coban.ID,
                                      STATUS: true,
                                    },
                                    modalSenha: true,
                                    operation: 'removerPerfil',
                                  });
                                }}
                                color="danger"
                                size="sm"
                              >
                                <Tooltip title="Remover">
                                  <img src={RemoveIcon}></img>
                                </Tooltip>
                              </Button>
                            </div>
                          </Row>
                        ),
                        style: { textAlign: 'center' },
                      },
                    ],
                  };
                })}
              />
            ) : (
              <>
                <Table
                  headers={[
                    {
                      info: 'Nome',
                      style: { textAlign: 'left', width: '33%' },
                    },
                    {
                      info: 'Parceiro',
                      style: { textAlign: 'center', width: '45%' },
                    },
                    {
                      info: 'AÇÕES',
                      style: { textAlign: 'center' },
                    },
                  ]}
                />
                <h3 style={{ textAlign: 'center', marginTop: '1rem' }}>Nenhum registro encontrado</h3>
              </>
            )}
          </div>
        </Row>

        <ModalSenha
          modal={this.state.modalSenha}
          close={() => this.setState({ modalSenha: !this.state.modalSenha })}
          operation={this.state.operation}
          dados={this.state.dados}
        />

        <ModalForm
          toggle={this.editCoban}
          modal={this.state.modalForm}
          form={FORMPERFIL}
          variable="coban"
          reducer="coban"
          typeReducer="COBAN_ADD_STATE"
          load={this.props.coban.loading_coban_crud}
          onSubmit={this.submitFormPerfil}
          editavel={this.state.editavel}
          tipoUser={this.state.tipoUser}
        >
          {Object.keys(this.props.coban.coban).length > 0 && <DocumentsForm />}
        </ModalForm>

        <ModalAllFees
          modal={this.state.modalTaxas.open}
          toggle={() => this.setState({modalTaxas: false})}
          profile={this.state.modalTaxas.profile}
          partner={this.state.modalTaxas.partner}
          idCoban={this.state.modalTaxas.idCoban}
        />

        <ModalMenus modal={this.state.modalMenus} toggle={this.toggleModalMenus} id={this.state.ID} />

        <ModalServicosDigitais
          modal={this.state.modalServicosDigitais}
          toggle={this.toggleModalServicosDigitais}
          id={this.state.ID}
        />
      </div>
    );
  }

  renderUserNormal() {
    const { list, loading_coban } = this.props.coban;
    const filteredList = list
      .filter((tipo) => tipo.PERFIL != '1')
      .filter((item) => item.NOME.toLowerCase().includes(this.state.filteredUsers.toLowerCase()));
    return (
      <div>
        <Row>
          <div className="col">
            {loading_coban ? (
              <Loading color="#11cdef" />
            ) : filteredList.length > 0 ? (
              <Table
                headers={[
                  {
                    info: 'Nome',
                    style: { textAlign: 'left' },
                  },
                  {
                    info: 'CPF/CNPJ Responsável',
                    style: { textAlign: 'left' },
                  },
                  {
                    info: 'AÇÕES',
                    style: { textAlign: 'center', width: '10%' },
                  },
                ]}
                data={filteredList.map((coban, index) => {
                  return {
                    data: [
                      {
                        info: coban.NOME,
                        style: { textAlign: 'left' },
                      },
                      {
                        info:
                          coban.CPFCNPJ && isCnpj(coban.CPFCNPJ) ? formatCNPJ(coban.CPFCNPJ) : formatCPF(coban.CPFCNPJ),
                        style: { textAlign: 'left' },
                      },
                      {
                        info: (
                          <Row
                            className="mb-2"
                            style={{
                              justifyContent: 'center',
                            }}
                          >
                            <div style={{ display: 'flex' }}>
                              <Button
                                style={{ alignContent: 'flex-end' }}
                                onClick={() => this.editCoban(coban, 'editPermissionPassword')}
                                color="warning"
                                size="sm"
                              >
                                <Tooltip title="Editar senha permissão">
                                  <Lock />
                                </Tooltip>
                              </Button>
                              <Button
                                style={{ alignContent: 'flex-end' }}
                                onClick={() => this.editCoban(coban, 'edit')}
                                color="info "
                                size="sm"
                              >
                                <Tooltip title="Editar">
                                  <CreateIcon />
                                </Tooltip>
                              </Button>
                              <Button
                                style={{ alignContent: 'flex-end' }}
                                onClick={() => {
                                  this.setState({
                                    dados: { ...coban, STATUS: true },
                                    modalSenha: true,
                                    operation: 'removerCoban',
                                  });
                                }}
                                color="danger"
                                size="sm"
                              >
                                <Tooltip title="Remover">
                                  <img src={RemoveIcon}></img>
                                </Tooltip>
                              </Button>
                            </div>
                          </Row>
                        ),
                        style: { textAlign: 'center', width: '10%' },
                      },
                    ],
                  };
                })}
              />
            ) : (
              <>
                <Table
                  headers={[
                    {
                      info: 'Nome',
                      style: { textAlign: 'left', width: '33%' },
                    },
                    {
                      info: 'Parceiro',
                      style: { textAlign: 'center', width: '45%' },
                    },
                    {
                      info: 'AÇÕES',
                      style: { textAlign: 'center' },
                    },
                  ]}
                />
                <h3 style={{ textAlign: 'center', marginTop: '1rem' }}>Nenhum registro encontrado</h3>
              </>
            )}
          </div>
        </Row>

        <ModalSenha
          modal={this.state.modalSenha}
          close={() => this.setState({ modalSenha: !this.state.modalSenha })}
          operation={this.state.operation}
          dados={this.state.dados}
        />

        <ModalForm
          toggle={this.editCoban}
          modal={this.state.modalForm}
          form={FORMCOBAN}
          variable="coban"
          reducer="coban"
          typeReducer="COBAN_ADD_STATE"
          /* onSubmit={this.submitForm} */
          load={this.props.coban.loading_coban_crud}
          dadosUser={this.state.dadosUser}
          tipoUser={this.state.tipoUser}
          changePermissionPassword={this.state.changePermissionPassword}
          togglePersmissionPassword={this.togglePersmissionPassword}
        >
          {Object.keys(this.props.coban.coban).length > 0 && <DocumentsForm />}
        </ModalForm>
        {
          <ModalTaxasDigital
            modal={this.state.modalDigital}
            toggle={this.toggleModalDigital}
            dadosModal={this.state.dadosModalDigital}
            dados={this.state.dados}
          />
        }
        {<ModalTaxas modal={this.state.modal} toggle={this.toggleModal2} dadosModal={this.state.dadosModal} />}
        {<ModalMenus modal={this.state.modalMenus} toggle={this.toggleModalMenus} />}
        {
          <ModalServicosDigitais
            modal={this.state.modalServicosDigitais}
            toggle={this.state.toggleModalServicosDigitais}
            id={this.state.ID}
          />
        }
      </div>
    );
  }

  renderTypeScreen() {
    switch (this.state.tipoUser) {
      case '1':
        return this.renderUserCoban();
      case '2':
        return this.renderUserNormal();
    }
  }
  findByName = (event) => {
    this.setState({ valueSearch: event.target.value });
    if (event.key === 'Enter') {
      this.setState({ filteredUsers: this.state.valueSearch });
    }
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.setState({ filteredUsers: this.state.valueSearch });
    }
  };
  onSubmit = (event) => {
    event.preventDefault();
  };
  render() {
    return (
      <div onKeyDown={this.handleKeyDown}>
        <CustomContainer>
          <Row className="mb-2" style={{ justifyContent: 'flex-end', marginRight: '25px' }}>
            <Button
              onClick={() => this.editCoban({}, 'add')}
              color="success"
              size="sm"
              style={{ marginRight: '3px', backgroundColor: '#085CA6', border: 'none' }}
            >
              {this.state.tipoUser === '1' ? '+ Criar Perfil' : '+ Criar Usuário'}
            </Button>
          </Row>
          <Row
            className="clearfix"
            style={{
              position: 'relative',
              minHeight: '1px',
              paddingRight: '25px',
              paddingLeft: '25px',
            }}
          >
            <Col>
              <Button
                block
                onClick={() => this.setState({ tipoUser: '1', filteredUsers: '', valueSearch: '' })}
                color="secondary"
                outline
                style={{
                  borderBottomColor: this.state.tipoUser == '1' ? '#11cdef' : 'transparent',
                  borderRadius: 0,
                }}
              >
                Perfil
              </Button>
            </Col>
            <Col>
              <Button
                block
                onClick={() => this.setState({ tipoUser: '2', filteredUsers: '', valueSearch: '' })}
                color="secondary"
                outline
                style={{
                  borderBottomColor: this.state.tipoUser === '2' ? '#11cdef' : 'transparent',
                  borderRadius: 0,
                }}
              >
                Usuários
              </Button>
            </Col>
          </Row>

          <Row style={{ alignItems: 'flex-end', justifyContent: 'space-between', marginTop: '1em', marginLeft: '1%' }}>
            {/* <Col style={styles.form}>
              <Input
                onChange={({ target }) =>
                  this.setState({
                    filtro: { ...this.state.filtro, [target.name]: target.value },
                  })
                }
                type="select"
                name="limit"
                id="limit"
                style={{ maxWidth: 80, marginRight: 10 }}
                size="sm"
              >
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
              </Input>
              <Label for="exampleSelect">Resultados por página</Label>
            </Col> */}
            <Col style={styles.form}>
              <Label for="search">Pesquisar</Label>
              <Input
                autoFocus
                onChange={this.findByName}
                placeholder="Nome"
                type="text"
                name="search"
                id="search"
                value={this.state.valueSearch}
                style={{ marginLeft: 11 }}
                size="sm"
              />
            </Col>

            <Col style={{ marginBottom: '5px' }}>
              <GenericButton
                onClick={() => this.setState({ filteredUsers: this.state.valueSearch })}
                style={{ marginTop: '25px !important', width: '140px', heigth: '35px' }}
              >
                <Icon className="fas fa-search" /> Buscar
              </GenericButton>
            </Col>
          </Row>

          <CardBody>{this.props.coban.loading_coban ? <Loading color="#11cdef" /> : this.renderTypeScreen()}</CardBody>
        </CustomContainer>
        <ModalMensagem
          modal={this.state.modalMensagem}
          toggle={() => this.setState({ modalMensagem: false })}
          mensagem={this.state.mensagem}
          sucesso={this.state.sucesso}
        />
      </div>
    );
  }
}

const styles = {
  form: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'baseline',
  },
};
const mapStateToProps = ({ auth, coban }) => {
  return { auth, coban };
};

export default connect(mapStateToProps, {
  listarCoban,
  listarCobanID,
  addSTATE,
  updateCoban,
  updatePerfil,
  insertCoban,
  insertPerfil,
  listarTaxaID,
  listLevels,
})(Cobans);
