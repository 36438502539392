import React, {  useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { buscaCep, listaUserDetalhes } from '../../store/user/actions';
import { addSTATE } from '../../store/globals';

import AccordionInfoUser from './components/AccordionInfoUser/AccordionInfoUser';
import AccordionDocumentsUser from './components/AccordionDocumentsUser/AccordionDocumentsUser';
import AccordionVerifications from './components/AccordionVerifications/AccordionVerifications';
import debounce from '../../utils/debounce';
import { Alert, Snackbar } from '@mui/material';
import AccordionRepresentantes from '../../components/Modais/Cliente/components/Tabs/TabInformacoesPessoais/AccordionRepresentantes';
import ModalCadastrarRepresentanteLegal from './components/modals/ModalCadastrarRepresentanteLegal';
import { isCnpj } from '../../utils/isCpf';
import AccordionContracts from './components/AccordionContracts/AccordionContracts';
import api from '../../service/api';
import ModalContratoConsentimento from '../ModalContratoConsentimento/ModalContratoConsentimento';

export default function PersonalInformationsUser(props) {
  const [modalCreateRepresentative, setModalCreateRepresentative] = useState(false);

  const [contracts, setContracts] = useState(false);
  const [detailedContract, setDetailedContract] = useState(false);
  const [detailedModal, setDetailedModal] = useState(false);
  const [loadingDetailedContract, setLoadingDetailedContract] = useState(false);
  const [loading, setLoading] = useState(false);


  function handleClick(operation) {
    props.setModalSenhaUnica(true);
    props.setOperation(operation);
  }

  function getContracts() {
    setLoading(true);
    api
      .get(`contrato/consentimento?document=${props.user.CPFCNPJ}&id_company=${props.user.ID_TIPO_USUARIO}`)
      .then(({ data }) => {
        setContracts(data.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }

  function getDetailedContract(uuid) {
    setDetailedModal(true);
    setLoadingDetailedContract(true);
    api
      .get(`contrato/consentimento/${uuid}`)
      .then(({ data }) => {
        setDetailedContract(data.data);

        setLoadingDetailedContract(false);
      })
      .catch(() => setLoadingDetailedContract(false));
  }

  useEffect(() => {
    getContracts();
  }, []);

  const dispatch = useDispatch();

  function alterValue(e) {
    const { name } = e.target;
    const valor = e.target.value;
    const value = valor.toUpperCase();

    switch (name) {
      case 'Nome/Razão Social':
        props.setInformacoesPessoais((prev) => ({ ...prev, nome: value }));
        break;

      case 'CPF/CNPJ':
        props.setInformacoesPessoais((prev) => ({ ...prev, cpf_cnpj: value }));
        break;

      case 'Nascimento/Fundação':
        props.setInformacoesPessoais((prev) => ({ ...prev, nasc_fund: value }));
        break;

      case 'EMAIL':
        props.setInformacoesPessoais((prev) => ({ ...prev, email: value }));
        break;

      case 'TELEFONE':
        props.setInformacoesPessoais((prev) => ({ ...prev, telefone: value }));
        break;

      case 'ENDERECO':
        props.setInformacoesPessoais((prev) => ({ ...prev, endereco: value }));
        break;

      case 'BAIRRO':
        props.setInformacoesPessoais((prev) => ({ ...prev, bairro: value }));
        break;

      case 'CIDADE':
        props.setInformacoesPessoais((prev) => ({ ...prev, cidade: value }));
        break;

      case 'NUMERO':
        props.setInformacoesPessoais((prev) => ({ ...prev, numero: value }));
        break;

      case 'UF':
        props.setInformacoesPessoais((prev) => ({ ...prev, uf: value }));
        break;

      case 'CEP':
        props.setInformacoesPessoais((prev) => ({ ...prev, cep: value }));
        break;
      case 'TIPOCONTA':
        props.setInformacoesPessoais((prev) => ({ ...prev, tipo_conta: value }));
        break;

      case 'Conveniada':
        props.setInformacoesPessoais((prev) => ({ ...prev, conveniada: value }));

        if (valor.length >= 8) {
          debounce(() => {
            dispatch(
              buscaCep(props.informacoesPessoais.cep, (res) => {
                if (res == true) {
                  const { cep } = props.user;
                  props.setInformacoesPessoais((prev) => ({
                    ...prev,
                    endereco: cep.rua != '' ? cep.rua : this.state.endereco,
                    bairro: cep.bairro != '' ? cep.bairro : this.state.bairro,
                    cidade: cep.cidade != '' ? cep.cidade : this.state.cidade,
                    uf: cep.uf != '' ? cep.uf : this.state.uf,
                  }));
                }
              }),
            );
          }, 2000);
        }
        break;

      case 'COMPLEMENTO':
        props.setInformacoesPessoais((prev) => ({ ...prev, complemento: value }));
        break;

      case 'CPF_MATRIZ':
        props.setInformacoesPessoais((prev) => ({ ...prev, cpf_cnpj_matriz: value }));
        break;

      case 'CNPJ_MATRIZ':
        props.setInformacoesPessoais((prev) => ({ ...prev, digits_variable: value }));
        break;
      case 'MATRICULA':
        props.setInformacoesPessoais((prev) => ({ ...prev, matricula: value }));
        break;

      case 'EMPRESA':
        props.setInformacoesPessoais((prev) => ({ ...prev, empresa: value }));
        break;
      case 'NOME_COBAN':
        props.setInformacoesPessoais((prev) => ({ ...prev, nome_coban: value }));
        break;
      case 'CONTA':
        props.setInformacoesPessoais((prev) => ({ ...prev, conta: value }));
        break;
      case 'AGENCIA':
        props.setInformacoesPessoais((prev) => ({ ...prev, agencia: value }));
        break;
      case 'RiscoPLD':
        props.setInformacoesPessoais((prev) => ({ ...prev, riscoPLD: value.includes('-') ? null : value }));
        break;
      case 'Conveniada':
        props.setInformacoesPessoais((prev) => ({ ...prev, conveniada: value }));
        break;
      case 'TIPOCONTA':
        props.setInformacoesPessoais((prev) => ({ ...prev, tipo_conta: value }));
        break;
      case 'DATA_ABERTURA':
        props.setInformacoesPessoais((prev) => ({ ...prev, data_abertura: value }));
        break;
      case 'DATA_ENCERRAMENTO':
        props.setInformacoesPessoais((prev) => ({ ...prev, data_encerramento: value }));
        break;
      case 'NOME_MAE':
        props.setInformacoesPessoais((prev) => ({ ...prev, nome_mae: value }));
      case 'STATUS_USUARIO':
        props.setInformacoesPessoais((prev) => ({ ...prev, status_usuario: value }));
        break;
      default:
        break;
    }
  }

  async function validate() {
    const campos = [
      'nome',
      'nome_mae',
      'email',
      'telefone',
      'nasc_fund',
      'uf',
      'bairro',
      'cidade',
      'endereco',
      'cpf_cnpj',
      'numero',
      'cep',
      'matricula',
      'empresa',
    ];

    for (const campo of campos) {
      if (props.informacoesPessoais[campo] === '' || props.informacoesPessoais[campo] === null) {
        if (campo === 'complemento') continue;
        if (campo === 'matricula') continue;
        if (campo === 'empresa') continue;
        if (campo === 'nome_mae') continue;

        props.setAbreAlerta(true);
        return;
      }

      const allFields = {
        NOME: props.informacoesPessoais.nome,
        CPFCNPJ_CLIENTE: props.informacoesPessoais.cpf_cnpj,
        NOME_MAE: props.informacoesPessoais.nome_mae,
        NUMERO: props.informacoesPessoais.numero,
        ENDERECO: props.informacoesPessoais.endereco,
        BAIRRO: props.informacoesPessoais.bairro,
        CIDADE: props.informacoesPessoais.cidade,
        UF: props.informacoesPessoais.uf,
        EMAIL: props.informacoesPessoais.email,
        NASCIMENTO: props.informacoesPessoais.nasc_fund,
        TIPO_CONTA: props.informacoesPessoais.tipo_conta,
        TELEFONE: props.informacoesPessoais.telefone,
        CEP: props.informacoesPessoais.cep,
        COMPLEMENTO: props.informacoesPessoais.complemento,
        MATRICULA: props.informacoesPessoais.matricula,
        EMPRESA: props.informacoesPessoais.empresa,
        CPFCNPJMATRIZ:
          props.user.CNPJMATRIZ && props.user.CNPJMATRIZ.length == 14
            ? props.informacoesPessoais.cpf_cnpj_matriz + props.informacoesPessoais.digits_variable
            : props.informacoesPessoais.cpf_cnpj_matriz,

        CONVENIADA: props.informacoesPessoais.conveniada,
      };

      dispatch(
        addSTATE('DEFAULT_TYPE', '', {
          allFields: allFields,
        }),
      );
    }
    props.setModalSenhaUnica(!props.modalSenhaUnica);
    props.setOperation('alterarCadastro');

    return;
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={props.abreAlerta}
        autoHideDuration={6000}
        onClose={() => props.setAbreAlerta(false)}
      >
        <Alert onClose={() => props.setAbreAlerta(false)} severity="error" sx={{ marginBottom: '10px' }}>
          Não é possível salvar com campos vazios. Por favor preencha-os!
        </Alert>
      </Snackbar>
      <AccordionInfoUser
        informacoesPessoais={props.informacoesPessoais}
        isConvened={props.user.CONVENIADA}
        validate={validate}
        risco={props.risco}
        alterAlert={props.alterAlert}
        infoPessoaisRedux={props.infoPessoaisRedux}
        renderTaxasUsuario={props.renderTaxasUsuario}
        setInformacoesPessoais={props.setInformacoesPessoais}
        alterValue={alterValue}
        setOpenAccordionInfo={props.setOpenAccordionInfo}
        openAccordionInfo={props.openAccordionInfo}
        setModalSenhaUnica={props.setModalSenhaUnica}
        setOperation={props.setOperation}
      />

      <AccordionDocumentsUser
        setOpenDocument={props.setOpenDocument}
        openDocument={props.openDocument}
        user={props.user}
        accept={props.accept}
        handleClick={handleClick}
      />

    <AccordionContracts
      contracts={contracts} 
      getDetailedContract={getDetailedContract}
      loading={loading}
    /> 

      {isCnpj(props.user.CPFCNPJ) && (
        <AccordionRepresentantes
          REPRESENTANTES={props.user.REPRESENTANTES}
          setModalCreateRepresentative={setModalCreateRepresentative}
          contracts={contracts} 
          getDetailedContract={getDetailedContract}
          loading={loading}
        />
      )}

      <AccordionVerifications informacoesPessoais={props.informacoesPessoais} user={props.user} />

      {props.user && (
        <ModalCadastrarRepresentanteLegal
          user={props.user}
          modal={modalCreateRepresentative}
          callBack={() => {
            dispatch(listaUserDetalhes(props.user.UUID));
            setModalCreateRepresentative(false);
          }}
          toggle={() => setModalCreateRepresentative(false)}
        />
      )}

      <ModalContratoConsentimento
        toggle={() => setDetailedModal(!detailedModal)}
        modal={detailedModal}
        contract={detailedContract}
        loading={loadingDetailedContract}
      />
    </>
  );
}
