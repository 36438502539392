const removeSpecialChars = (arrayChars, data) => {
  var newData = data;

  for (let i = 0; i < arrayChars.length; i++) {
    newData = newData.replaceAll(arrayChars[i], '');
  }

  return newData;
};

export const removerAcentos = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export default removeSpecialChars;
