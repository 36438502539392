import React, { useEffect, useState } from "react";
import CustomDataTable from "../../../components/DataTable/DataTable";
import { formatCNPJ, formatCPF, formatDateBR, formatMoneyBRL, isCpf } from "../../../utils";
import StatusContainer from "../../../components/StatusContainer/StatusContainer";
import StatusContainerTed from "../../../components/StatusContainer/StatusContainerTed";
import { useSelector } from "react-redux";
import Loading from "../../../components/Loader/Loading";

export default function Completed({ search, getValues, handleClick }) {
    const {
        pagamentos, pagination, statusTransacao,
        loading_transaction
    } = useSelector((state) => state.transactions);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (pagination && pagination.page && pagamentos) {
            setLoading(false);
        }
    }, [pagination]);

    return (
        <>
            {loading ? (
                <Loading color="blue" />
            ) : (
                <CustomDataTable
                    loading={loading_transaction}
                    pagination={pagination}
                    action={(row) => handleClick(row.ID)}
                    previousPage={{ onClick: () => search({ ...getValues(), PAGE: pagination.prevPage }) }}
                    nowPage={{ page: pagination.page, onClick: () => search({ ...getValues(), PAGE: pagination.page }) }}
                    nextPage={{ onClick: () => search({ ...getValues(), PAGE: pagination.nextPage }) }}
                    data={pagamentos ? pagamentos : []}
                    headers={[
                        {
                            name: 'DATA/HORA',
                            selector: (row) => formatDateBR(row.DATA_TRANSACAO),
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'CPF/CNPJ',
                            selector: (row) =>
                                row.CPFCNPJ && (isCpf(row.CPFCNPJ) ? formatCPF(row.CPFCNPJ) : formatCNPJ(row.CPFCNPJ)),
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'NOME',
                            selector: (row) => row.NOME,
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'FORMA PAGAMENTO',
                            selector: (row) => row.TIPO_PAGAMENTO,
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'DESCRIÇÃO',
                            selector: (row) => row.DESCRICAO,
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'PARCEIRO',
                            selector: (row) => (
                                <StatusContainer code={row.TIPO_USUARIO.toUpperCase()} description={row.TIPO_USUARIO} />
                            ),
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'VALOR PAGO',
                            selector: (row) => (+row.VALOR_PAGO).toLocaleString("pt-BR", {style:"currency", currency:"BRL"}),
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'STATUS',
                            selector: (row) => (
                                <StatusContainerTed code={row.STATUSTRANSACAO} description={statusTransacao ? statusTransacao.find((status) => status.ID === row.STATUSTRANSACAO).DESCRICAO : ''} />
                            ),
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                    ]}
                    customStyles={{
                        cells: {
                            style: {
                                cursor: 'pointer',
                            },
                            headCells: {
                                style: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                },
                            },
                        },
                    }}
                />
            )}
        </>
    );
}