import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import JoditEditor from 'jodit-react';
import { MainContainer } from './styles';
import Loading from '../../components/Loader/Loading';
import api from '../../service/api';

export default function RendimentosPDF({ toggle, open, callback, UUID, ANO_REFERENCIA, CONTA_DIGITAL_ID }) {
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);

  function getPdfContract() {
    setLoading(true);
    api
      .get(`contrato/rendimentos?UUID=${UUID}&ANO_REFERENCIA=${ANO_REFERENCIA}&CONTA_DIGITAL_ID=${CONTA_DIGITAL_ID}`)
      .then((res) => {
        setData(res.data.data);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (ANO_REFERENCIA && CONTA_DIGITAL_ID) {
      getPdfContract();
    }
  }, [ANO_REFERENCIA, CONTA_DIGITAL_ID]);

  return (
    <>
      <div size="lg">
        {!loading ? (
          <MainContainer>
            {data && (
              <object
                data={`data:application/pdf;base64,${data}`}
                type="application/pdf"
                width="100%"
                height="800px"
              ></object>
            )}
          </MainContainer>
        ) : (
          <Loading color="blue" />
        )}
      </div>
    </>
  );
}
