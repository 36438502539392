import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Typography } from '@mui/material';
import { getContractZip } from '../../../../../../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { formatCPF, formatCNPJ, isCpf } from '../../../../../../../utils';
import ModalContratoAssinaturaAssinantes from '../../../AccordionContratoAssinatura/modal/ModalContratoAssinaturaAssinantes';
import ModalMensagemSemReload from '../../../../../../../views/Transaction/ModalMensagemSemReload';
import { Download, ExpandMore } from '@mui/icons-material';
import { Button } from 'reactstrap';
import api from '../../../../../../../service/api';

function AccordionContratos({ dispatchContract, parceiro }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [modalAssinantes, setModalAssinantes] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [contratos, setContratos] = useState({});
  const [contrato, setContrato] = useState({});

  useEffect(() => {
    if (user) {
      handleGetContract(user);
    }
  }, []);

  function reenviarContrato(uuidContrato, cpfCnpjRepresentante = null) {
    let url = 'contrato/assinatura/';
    if (!cpfCnpjRepresentante) {
      url += `reenviar/`;
    } else {
      url += 'signatario/reenviar/';
    }
    url += `${uuidContrato}`;

    api
      .post(url, { CPFCNPJ_SIGNATARIO: cpfCnpjRepresentante })
      .then((res) => {
        if (res.retorno == 'erro') {
          setModalError(true);
          setMessageError(res.mensagem);
        }
      })
      .catch(({ response }) => {
        if (response && response.data) {
          setModalError(true);
          setMessageError(response.data.mensagem);
        }
      });
  }

  function handleGetContract(data) {
    api
      .get(`/contrato/assinatura?CPFCNPJ_CLIENTE=${data.CPFCNPJ}&ID_TIPO_USUARIO=${+data.ID_TIPO_USUARIO}`)
      .then(({ data }) => setContratos(data.data));
  }

  async function handleClickDispatchSignatureContract() {
    await api
      .post(`/contrato/assinatura`, {
        CPFCNPJ_CLIENTE: user.CPFCNPJ,
        ID_TIPO_USUARIO: +user.ID_TIPO_USUARIO,
      })
      .then((res) => {
        handleGetContract(user);
      });
  }

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
            Contratos
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {contratos && contratos.length > 0 ? (
            contratos.map((contrato) => (
              <>
                <Box>
                  <Box sx={{ display: 'flex' }}>
                    <Typography fontSize={20} fontWeight={'bold'} mr={1}>
                      Documento
                    </Typography>
                    {contrato.STATUS.includes('Finalizado') && (
                      <Download
                        sx={{ cursor: 'pointer' }}
                        onClick={() => dispatch(getContractZip(contrato.UUID, contrato.CPFCNPJ))}
                      />
                    )}
                  </Box>
                  <Card>
                    <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box>
                        <Typography>
                          <b>Documento:</b>{' '}
                          {contrato.CPFCNPJ && isCpf(contrato.CPFCNPJ)
                            ? formatCPF(contrato.CPFCNPJ)
                            : formatCNPJ(contrato.CPFCNPJ)}
                        </Typography>
                        <Typography>
                          <b>Tag:</b> {contrato.TAG.replaceAll('_', ' ')}
                        </Typography>
                        <Typography>
                          <b>Criado:</b> {contrato.CREATED_AT}
                        </Typography>
                        <Typography>
                          <b>Validade:</b> {contrato.VALIDADE}
                        </Typography>
                        <Typography>
                          <b>Status:</b> {contrato.STATUS}
                        </Typography>
                      </Box>

                      <Box>
                        <Button color="primary" size="sm" onClick={() => reenviarContrato(contrato.UUID)}>
                          Reenviar Contrato
                        </Button>

                        <Button
                          color="primary"
                          size="sm"
                          onClick={() => {
                            setModalAssinantes(true);
                            setContrato(contrato);
                          }}
                        >
                          Assinantes
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </>
            ))
          ) : (
            <Box>
              <Button color="primary" onClick={() => handleClickDispatchSignatureContract()}>
                Disparar contrato
              </Button>

              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography>Não foi disparado contrato</Typography>
              </Box>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
      <ModalContratoAssinaturaAssinantes
        contrato={contrato}
        modal={modalAssinantes}
        reenviarContrato={reenviarContrato}
        toggle={() => {
          setModalAssinantes(false);
          setContrato({});
        }}
      />
      <ModalMensagemSemReload
        modal={modalError}
        toggle={() => {
          setModalError(false);
          setMessageError('');
        }}
        mensagem={messageError}
        sucesso={false}
      />
    </>
  );
}

export default AccordionContratos;
