import React from 'react';
import { ColorCard, ColorsContainer } from './styles';

export const PreviewSelectorColor = ({ colors, setColors }) => {
  return (
    <ColorsContainer>
      <h2 style={{ marginBottom: '1rem' }}>Cores</h2>

      <ColorCard>
        <input
          id="cor_primaria"
          className="inputColor"
          type="color"
          value={colors.primary}
          onChange={(e) => setColors({ ...colors, primary: e.target.value })}
        />
        <span>Primária</span>
      </ColorCard>

      <ColorCard>
        <input
          id="cor_primaria"
          className="inputColor"
          type="color"
          value={colors.primary_text}
          onChange={(e) => setColors({ ...colors, primary_text: e.target.value })}
        />
        <span>Primária texto</span>
      </ColorCard>

      <ColorCard>
        <input
          id="cor_primaria"
          className="inputColor"
          type="color"
          value={colors.secondary}
          onChange={(e) => setColors({ ...colors, secondary: e.target.value })}
        />
        <span>Secundária</span>
      </ColorCard>

      <ColorCard>
        <input
          id="cor_primaria"
          className="inputColor"
          type="color"
          value={colors.secondary_text}
          onChange={(e) => setColors({ ...colors, secondary_text: e.target.value })}
        />
        <span>Secundária texto</span>
      </ColorCard>

      <ColorCard>
        <input
          id="cor_primaria"
          className="inputColor"
          type="color"
          value={colors.icon_menu}
          onChange={(e) => setColors({ ...colors, icon_menu: e.target.value })}
        />
        <span>Ícones menu</span>
      </ColorCard>

      <ColorCard>
        <input
          id="cor_primaria"
          className="inputColor"
          type="color"
          value={colors.icon_menu_text}
          onChange={(e) => setColors({ ...colors, icon_menu_text: e.target.value })}
        />
        <span>Ícones menu texto</span>
      </ColorCard>

      <ColorCard>
        <input
          id="cor_primaria"
          className="inputColor"
          type="color"
          value={colors.icon}
          onChange={(e) => setColors({ ...colors, icon: e.target.value })}
        />
        <span>Ícones</span>
      </ColorCard>

      <ColorCard>
        <input
          id="cor_primaria"
          className="inputColor"
          type="color"
          value={colors.icon_text}
          onChange={(e) => setColors({ ...colors, icon_text: e.target.value })}
        />
        <span>Ícones texto</span>
      </ColorCard>

      <ColorCard>
        <input
          id="cor_primaria"
          className="inputColor"
          type="color"
          value={colors.table}
          onChange={(e) => setColors({ ...colors, table: e.target.value })}
        />
        <span>Tabelas</span>
      </ColorCard>

      <ColorCard>
        <input
          id="cor_primaria"
          className="inputColor"
          type="color"
          value={colors.table_text}
          onChange={(e) => setColors({ ...colors, table_text: e.target.value })}
        />
        <span>Tabelas texto</span>
      </ColorCard>
    </ColorsContainer>
  );
};
