import React, { useEffect, useState } from 'react';
import { Box, Paper, Skeleton, Typography } from '@mui/material';
import InputDate from '../Filter/components/InputDate';
import {
  MdOutlineAccountBalance,
  MdOutlineAccountBalanceWallet,
  MdOutlineAddCard,
  MdOutlineLock,
  MdOutlineLockClock,
} from 'react-icons/md';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const CardBalance = ({
  title,
  date,
  setDate,
  balance = '----',
  type = 'totalBalance',
  colors = ['red', 'green', 'blue'],
  loading = false,
  description,
}) => {
  const [responsiveSize, setResponsiveSize] = useState(1);

  const [red, green, blue] = colors;
  const icon = {
    totalBalance: (
      <MdOutlineAccountBalanceWallet
        style={{ color: `rgb(${red}, ${green}, ${blue})`, width: '24px', height: '24px' }}
      />
    ),
    blockedBalance: (
      <MdOutlineLock style={{ color: `rgb(${red}, ${green}, ${blue})`, width: '24px', height: '24px' }} />
    ),
    celcoinBalance: (
      <MdOutlineAccountBalanceWallet
        style={{ color: `rgb(${red}, ${green}, ${blue})`, width: '24px', height: '24px' }}
      />
    ),
    totalPendente: (
      <MdOutlineLockClock style={{ color: `rgb(${red}, ${green}, ${blue})`, width: '24px', height: '24px' }} />
    ),
    totalPagamentosDia: (
      <MdOutlineAccountBalance style={{ color: `rgb(${red}, ${green}, ${blue})`, width: '24px', height: '24px' }} />
    ),
    saldoPrePago: (
      <MdOutlineAccountBalanceWallet
        style={{ color: `rgb(${red}, ${green}, ${blue})`, width: '24px', height: '24px' }}
      />
    ),
    saldoPosPago: (
      <MdOutlineAddCard style={{ color: `rgb(${red}, ${green}, ${blue})`, width: '24px', height: '24px' }} />
    ),
    credito: <ArrowDownwardIcon style={{ color: `rgb(${red}, ${green}, ${blue})`, width: '24px', height: '24px' }} />,
    debito: <ArrowUpwardIcon style={{ color: `rgb(${red}, ${green}, ${blue})`, width: '24px', height: '24px' }} />,
  };

  const updateStyle = () => {
    const vw = window.innerWidth * 0.0095;
    const vh = window.innerHeight * 0.0095;
    setResponsiveSize(vh + vw);
  };

  useEffect(() => {
    updateStyle();
    window.addEventListener('resize', updateStyle);
    return () => window.removeEventListener('resize', updateStyle);
  }, []);

  return (
    <Paper
      sx={{
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-evenly',
        height: '7rem',
        padding: '1rem',
        borderLeft: `10px solid rgb(${red}, ${green}, ${blue})`,
        minWidth: '150px',
      }}
    >
      <Box display={'flex'} flexDirection={'row'} alignItems={'start'} justifyContent={'space-between'} width={'100%'}>
        <Box>
          <Typography fontWeight={'200'} fontSize={`${0.5 * responsiveSize}px`}>
            {title}
          </Typography>
          {loading ? (
            <Skeleton animation="wave" width={'100%'} sx={{ fontSize: `${responsiveSize}px` }} />
          ) : (
            <Typography fontWeight={'700'} fontSize={`${responsiveSize}px`}>
              {balance}
            </Typography>
          )}
          {description && <Box>{description}</Box>}
        </Box>
        <Box>
          <Box
            width={'40px'}
            height={'40px'}
            borderRadius={'50%'}
            textAlign={'center'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
              backgroundColor: `rgba(${red}, ${green}, ${blue}, 0.1)`,
            }}
          >
            {icon[type]}
          </Box>
        </Box>
      </Box>
      {date && setDate && (
        <Box display={'flex'} flexDirection={'row'} justifyContent={'end'} alignItems={'center'}>
          <InputDate
            label="Dia:"
            name="data_filtro_saldo"
            value={date}
            setValue={(value) => setDate(value)}
            style={{
              div: {
                display: 'flex',
                flexFlow: 'row',
                border: `1px rgba(${red}, ${green}, ${blue}, 0.1)`,
                borderStyle: 'none none solid none',
              },
              input: {
                fontWeight: 'bold',
                border: 'none',
              },
            }}
          />
        </Box>
      )}
    </Paper>
  );
};

export default CardBalance;
