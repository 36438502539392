import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { ExpandMore } from '@material-ui/icons'
import ContratosConsentimentoTable from '../../../../components/ContratosConsentimentoTable/ContratosConsentimentoTable'
import ModalContratoConsentimento from '../../../ModalContratoConsentimento/ModalContratoConsentimento'

const AccordionContracts = ({getDetailedContract, loading, contracts}) => {
  return (
    <>
    <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Typography variant="h5" sx={{ width: '80%', fontWeight: '600', fontSize: '18px', flexShrink: 0 }}>
            Contratos
          </Typography>
        </AccordionSummary>
        
            <AccordionDetails>
                <ContratosConsentimentoTable
                getDetailedContract={getDetailedContract}
                loading={loading}
                contracts={contracts}
              />
            </AccordionDetails>
         
          </Accordion>
      </>
  )
}

export default AccordionContracts