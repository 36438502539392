import React, { useState } from 'react';
import { Box } from '@mui/material';
import * as Styled from '../../../ModalInfo.styles';
import { DocumentFuncional as ModalDocument } from '../../../../../../views/User/components/modals';
import { listaUserDetalhes } from '../../../../../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { addSTATE } from '../../../../../../store/globals';
import StatusContainer from '../../../../../StatusContainer/StatusContainer';
import ModalRepresentatives from '../../ModalRepresentatives/ModalRepresentatives';
import ModalCadastrarRepresentanteLegal from '../../../../../../views/User/components/modals/ModalCadastrarRepresentanteLegal';
import AccordionInformacoesPessoais from './AccordionInformacoesPessoais';
import AccordionRepresentantes from './AccordionRepresentantes';
import { isCnpj } from '../../../../../../utils/isCpf';

function TabInformacoesPessoais({ user, user: { CPFCNPJ, DOCUMENTOS, ID_TIPO_USUARIO, REPRESENTANTES } }) {
  const dispatch = useDispatch();
  const {
    coban,
    user: { loading_user },
  } = useSelector((state) => state);

  const [modalCreateRepresentative, setModalCreateRepresentative] = useState(false);
  const [modalRepresentatives, setModalRepresentatives] = useState(false);
  const [imagem, setImagem] = useState({
    openDocument: false,
    documentLabel: '',
    urlImage: '',
  });

  const handleClick = ({ target }) => {
    const { name, value } = target;
    let newUser = user;
    switch (name) {
      case 'coban':
        newUser.ID_COBAN = value;
        dispatch(addSTATE('USER_ADD_STATE', 'user', newUser));
        break;
      case 'riscoPLD':
        newUser.RISCO_PLD = value;
        dispatch(addSTATE('USER_ADD_STATE', 'user', newUser));
        break;
    }
  };

  let PARCEIRO = ID_TIPO_USUARIO;
  if (PARCEIRO == 1) {
    PARCEIRO = <StatusContainer code={'CDC'} description={'CDC'} />;
  }

  return (
    <Box>
      {!loading_user && (
        <>
          <AccordionInformacoesPessoais user={user} />
          {isCnpj(CPFCNPJ) && (
            <Box>
              <AccordionRepresentantes
                REPRESENTANTES={user.REPRESENTANTES}
                setModalCreateRepresentative={setModalCreateRepresentative}
              />
            </Box>
          )}
          <Styled.ImagesContainer>
            {user.STATUS === '1' && (
              <>
                {localStorage.getItem('nivelAcesso') == '1' || localStorage.getItem('nivelAcesso') == '5' ? (
                  <>
                    <div>
                      {user.nivelPrioridade != 100 && (
                        <>
                          <h4>Estabelecimento/Correspondente</h4>
                          <div style={{ border: '1px solid #f0f0f0', borderRadius: 10, marginBottom: 15 }}>
                            <div style={{ marginLeft: 10, padding: 10 }}>
                              <div className="form-group coban-select-container">
                                <label for="coban-select">Selecione o Correspondente Bancário:</label>
                                <select
                                  onChange={(e) => {
                                    handleClick(e);
                                  }}
                                  name="coban"
                                  style={{ width: '100%' }}
                                  id="coban-select"
                                  className="coban-select-2"
                                >
                                  <option selected value={0}>
                                    Selecione o Correspondente Bancário:
                                  </option>
                                  {coban.list
                                    .filter((tipo) => tipo.PERFIL == '1')
                                    .map((a, index) => {
                                      return <option value={a.ID}> {a.NOME}</option>;
                                    })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <Styled.ImagesContainer>
                    <h4>Para aprovar esse usuário logue com uma conta {PARCEIRO}</h4>
                  </Styled.ImagesContainer>
                )}
              </>
            )}
          </Styled.ImagesContainer>
        </>
      )}

      <ModalDocument state={imagem} toggle={() => setImagem({ openDocument: false })} />
      <ModalRepresentatives
        open={modalRepresentatives}
        toggle={() => setModalRepresentatives(false)}
        representatives={REPRESENTANTES}
      />

      {user && (
        <ModalCadastrarRepresentanteLegal
          user={user}
          modal={modalCreateRepresentative}
          callBack={() => {
            dispatch(listaUserDetalhes(user.UUID));
            setModalCreateRepresentative(false);
          }}
          toggle={() => setModalCreateRepresentative(false)}
        />
      )}
    </Box>
  );
}

const styles = {
  foto: { height: '50%', width: '90%' },
};

export default TabInformacoesPessoais;
