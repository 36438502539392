import {
  DASHBOARD_RESPONSE,
  DASHBOARD_RESPONSE_CARDS,
  DASHBOARD_MOVIMENTACAO,
  DASHBOARD_USERS,
  DASHBOARD_USERS_CONVENIED,
} from './types';
import api from '../../service/api';
import { removeSpecialCharacters } from '../../utils/functions';

export const listInformations = (filtros = {}) => {
  var queryString = '';
  return async (dispatch) => {
    dispatch({
      type: DASHBOARD_RESPONSE,
      payload: { loading_dashboard: true },
    });

    if (Object.keys(filtros).length > 0) {
      // cria query string, enviando os parametros
      queryString = '?FILTER=FILTER';
      for (let f in filtros) if (filtros[f]) queryString += `&${f}=${filtros[f]}`;
    }

    try {
      const response = await api.get(`analise/listar/informacao${queryString}`);
      const { status, data } = response;
      dispatch({
        type: DASHBOARD_RESPONSE,
        payload: { loading_dashboard: false, infos: data },
      });
    } catch (error) {
      dispatch({
        type: DASHBOARD_RESPONSE,
        payload: { loading_dashboard: false },
      });
    }
  };
};

export const drawerToogle = (isOpen) => {
  return async (dispatch) => {
    dispatch({
      type: DASHBOARD_RESPONSE,
      payload: { drawerToogle: isOpen },
    });
  };
};

export const listMovimentacao = (filtro = { limit: 20, page: 1 }, flagDispatch) => {
  return async (dispatch) => {
    dispatch({
      type: DASHBOARD_RESPONSE,
      payload: { loading_dashboard: true },
    });

    try {
      const response = await api.post(`transacao/dia`, filtro);
      const { status, data } = response;
      dispatch({
        type: flagDispatch ? DASHBOARD_RESPONSE : DASHBOARD_MOVIMENTACAO,
        payload: {
          loading_dashboard: false,
          listMovimentacaoDia: data.TRANSACOES || [],
          moviventacoesDia: data,
          pagination: data.PAGINATION || {},
        },
      });
    } catch (error) {
      dispatch({
        type: DASHBOARD_RESPONSE,
        payload: { loading_dashboard: false },
      });
    }
  };
};

export const getSaques = () => {
  return async (dispatch) => {
    dispatch({
      type: DASHBOARD_RESPONSE,
      payload: { loading_dashboard: true },
    });

    try {
      const response = await api.get(`/analise/range-data`);
      const { status, data } = response;
      dispatch({
        type: DASHBOARD_RESPONSE,
        payload: {
          loading_dashboard: false,
          dadoSaques: data,
          transacaoDebito: data.debitos,
          transacaoCredito: data.creditos,
        },
      });
    } catch (error) {
      dispatch({
        type: DASHBOARD_RESPONSE,
        payload: { loading_dashboard: false },
      });
    }
  };
};

export const getAllUsersConvenied = (filtro = { limit: 10, page: 1 }, flagDispatch, pendingUsers = false) => {
  return async (dispatch) => {
    dispatch({
      type: DASHBOARD_RESPONSE,
      payload: { loading_dashboard: true },
    });
    filtro.search = removeSpecialCharacters(filtro.search);
    try {
      const response = await api.post(`/conveniada/listar-clientes`, filtro);
      const { status, data } = response;

      dispatch({
        type: flagDispatch ? DASHBOARD_RESPONSE : DASHBOARD_USERS_CONVENIED,
        payload: {
          pendingUsers,
          loading_dashboard: false,
          listUsersConvenied: data.USUARIOS,
          userPagination: data.PAGINATION || {},
        },
      });
    } catch (error) {
      dispatch({
        type: DASHBOARD_USERS_CONVENIED,
        payload: { listUsersConvenied: [], loading_dashboard: false },
      });
    }
  };
};

export const getAllUsers = (filtro = { limit: 10, page: 1 }, flagDispatch, pendingUsers = false) => {
  return async (dispatch) => {
    dispatch({
      type: DASHBOARD_RESPONSE,
      payload: { loading_dashboard: true },
    });
    filtro.search = removeSpecialCharacters(filtro.search);
    try {
      const response = await api.post(`/usuario/listar/todos`, filtro);
      const { status, data } = response;

      dispatch({
        type: flagDispatch ? DASHBOARD_RESPONSE : DASHBOARD_USERS,
        payload: {
          pendingUsers,
          loading_dashboard: false,
          listUsers: data.USUARIOS,
          userPagination: data.PAGINATION || {},
        },
      });
    } catch (error) {
      dispatch({
        type: DASHBOARD_USERS,
        payload: { listUsers: [], loading_dashboard: false },
      });
    }
  };
};

// export const getSaldoBanco = (callback = () => null) => {
//   return async (dispatch) => {
//     dispatch({
//       type: DASHBOARD_RESPONSE,
//       payload: { loading_dashboard: true },
//     });
//     try {
//       const { status, data } = await api.get(
//         `banco-parceiro/get/dados_conta_mae`
//       );
//       dispatch({
//         type: DASHBOARD_RESPONSE,
//         payload: { loading_dashboard: false, saldoBanco: data.data.saldo },
//       });
//       if (callback) callback(data);
//     } catch (error) {
//       dispatch({
//         type: DASHBOARD_RESPONSE,
//         payload: {
//           loading_dashboard: false,
//           /*messageSaldo:error.response.data.MENSAGEM, retorno:'Erro ao consultar saldo!'*/
//         },
//       });
//       if (callback) callback({});
//     }
//   };
// };

export const getSaldoContaDigital = (res) => {
  return async (dispatch) => {
    dispatch({
      type: DASHBOARD_RESPONSE,
      payload: { loading_dashboard: true },
    });
    try {
      const response = await api.get(`conta-digital/saldo/dia?DATA=${res}`);
      const { status, data } = response;

      dispatch({
        type: DASHBOARD_RESPONSE,
        payload: {
          loading_dashboard: false,
          saldoContaDigital: data.saldo,
        },
      });
    } catch (error) {
      dispatch({
        type: DASHBOARD_RESPONSE,
        payload: { loading_dashboard: false },
      });
    }
  };
};

export const movimentacaoDashboard = () => {
  return async (dispatch) => {
    dispatch({
      type: DASHBOARD_RESPONSE_CARDS,
      payload: { loading_dashboard_cards: true },
    });

    try {
      const response = await api.get(`/dashboard`);
      const { status, data } = response;
      dispatch({
        type: DASHBOARD_RESPONSE_CARDS,
        payload: {
          loading_dashboard_cards: false,
          movimentacaoDashboard: data,
        },
      });
    } catch (error) {
      dispatch({
        type: DASHBOARD_RESPONSE_CARDS,
        payload: { loading_dashboard_cards: false },
      });
    }
  };
};
