import { LOADING, SUCCESS, ERROR } from './types';
import api from '../../service/api';

export const getExtract = (params) => {
  const { SEARCH, DATA_INICIO, DATA_FIM, PAGE, SIZE } = params;

  return (dispatch) => {
    dispatch({ type: LOADING });
    api
      .get(
        `banco-parceiro/get/transacoes?CPFCNPJ_USUARIO=${SEARCH}&CARD_ONLY=0&DATA_INICIO=${DATA_INICIO}&DATA_FIM=${DATA_FIM}&PAGE=${PAGE}&SIZE=${SIZE}`,
      )
      .then((response) => {
        dispatch({ type: SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: ERROR, payload: error.response });
      });
  };
};
